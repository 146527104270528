var vendorAddressId = null;
var oldLat = null;
var oldLong = null;
export default class vendor_manage_address {
  constructor() {
    this.bindEvents();
  }

  bindEvents = () => {
    console.log("vendor_manage_address Bind");

    this.GetAddress();
  };

  GetAddress = () => {
    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/GetAddress",
      headers: { Authorization: X_API_KEY },

      success: this.doDisplayAddress,
    });
  };
  doDisplayAddress = (res) => {
    console.log(res);

    $("#VendorAccountAddress").empty();

    var item = ` <div class="card mb-2 ml-3 mt-1" style="background: rgba(244, 148, 49);">
    <div class="card-body text-white">
        <h5 class="card-title">ADDRESS</h5>
        <p class="card-text">Manage your Address</p>
    </div>
</div>


<div class="row">
    <div class="col-sm-6">
        <div class="card ml-3 border border-3 mb-1">
            <div class="card-body">
                <h5 class="card-title">Current Location</h5>
                <p class="card-text">${res.Address_Line_1}</p>


                <div class="row d-none" id="EditDeleteBtnDesktop">
                    <div class="col d-flex justify-content-start "> <button class=" btn-sm btn btn-warning bg-orange" type="button" style="color: #f49431;">Edit</button>
                    </div>
                    <div class="col  d-flex justify-content-end"> <button class="btn btn-sm" type="button" style="color: #f49431;">Delete</button>
                    </div>
                </div>

                <div class="col d-flex justify-content-start"> <button class="btn btn-sm customerAccountAddressEdit locationModalAccountEdit btn-warning bg-orange" type="button" data-bs-toggle="modal" data-bs-target="#locationModalAccountCallEdit">Edit
                <input type="hidden" name="customerAddressIdAccount" value="${res.Vendor_Address_ID}" />
            </button>
        </div>
            </div>
        </div>
    </div>
    
</div>`;

    $("#VendorAccountAddress").append(item);

    // $(".inputAddressNameChangeAccountEdit").val(res.Address_Name);
    $(".inputAddressChangeAccountEdit").val(res.Address_Line_1);
    $(".inputAddressshortChangeAccountEdit").val(res.Landmark);
    $(".inputCityChangeAccountEdit").val(res.City);
    $(".inputStateChangeAccountEdit").val(res.State);
    $(".inputPincodeChangeAccountEdit").val(res.Pincode);
    oldLat = res.lat;
    oldLong = res.lon;

    $(".locationModalAccountEdit").on("click", this.editAddress);
    $(".saveAddressBtn").on("click", this.saveNewAddress);
  };

  //To save new address and make old address is deleted 1
  saveNewAddress = () => {
    var self = this;
    $.ajax({
      type: "POST",
      url: baseURL + "api/vendor/AddNewAddress",
      data: {
        Address_Line_1:
          document.getElementsByClassName("inputAddress")[0].value,
        Landmark: document.getElementsByClassName(
          "inputAddressshortChangeAccountEdit"
        )[0].value,
        City: document.getElementsByClassName("inputCity")[0].value,
        State: document.getElementsByClassName("inputState")[0].value,
        Pincode: document.getElementsByClassName("inputPincode")[0].value,
        lat: $(".inputLat").val(),
        lon: $(".inputLon").val(),
      },
      headers: { Authorization: X_API_KEY },
      // success: function (res) {
      //   console.log(res);
      //   error:
      // },
    })
      .done(function (res) {
        console.log("Success: " + res);
        self.GetAddress();
        // location.reload();
      })
      .fail(function (res) {
        if (res["status"] === 404) {
          console.error("Page not found");
        } else {
          console.error("Error: " + res["responseJSON"]["Msg"]);
          alert(res["responseJSON"]["Msg"]);
        }
        // getElementById("statusChangeErrorMsg").innerHTML =
        //   res["responseJSON"]["Msg"];

        // var statusOptionsMessage = ` ${res["responseJSON"]["Msg"]}`;
        // $("#updatedStatusMessage").empty();
        // $("#updatedStatusMessage").append(statusOptionsMessage);
        // $("#updatedStatusMessage").empty();
        // document.getElementById("orderStatusMsgHeading").innerHTML = "ALERT";
      });
  };

  //Start of Google Maps Javascript
  editAddress = () => {
    var id = `locationModalAccountEdit`;
    var lat = oldLat;
    var lon = oldLong;
    //this.autoDetectModal.show();

    // var lat1 = marker.latLng.lat();
    // var lng1 = marker.latLng.lng();

    console.log(lat);
    console.log(lon);

    console.log("inside manage address page");

    var location = new google.maps.LatLng(lat, lon);
    console.log(location);

    var mapProperty = {
      center: location,
      zoom: 18,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    console.log(mapProperty);
    console.log(document.getElementById(id));
    var map = new google.maps.Map(
      document.getElementById(`${id}`),
      mapProperty
    );

    //Places API
    var marker;
    var places;
    var searchBox = new google.maps.places.SearchBox(
      document.getElementById("pac-input")
    );
    // map.controls[google.maps.ControlPosition.TOP_CENTER].push(
    //   document.getElementById("pac-input")
    // );

    console.log(searchBox);

    google.maps.event.addListener(searchBox, "places_changed", () => {
      searchBox.set("map", null);

      places = searchBox.getPlaces();
      console.log(places);
      console.log(places[0].geometry.location);
      console.log(places[0].geometry.location.lat());
      console.log(places[0].geometry.location.lng());

      marker.setMap(null);
      marker = new google.maps.Marker({
        //position: { lat: 45245, lng: 452435 },
        map: map,
        draggable: true,
        animation: google.maps.Animation.DROP,
        position: places[0].geometry.location,
      });

      console.log(marker.getPosition());

      this.geocodePosition(marker.getPosition());

      google.maps.event.addListener(marker, "dragend", () => {
        map.setCenter(marker.getPosition());
        this.geocodePosition(marker.getPosition());
        $(".inputLatChangeAccountEdit").val(marker.getPosition().lat());
        $(".inputLonChangeAccountEdit").val(marker.getPosition().lng());
      });

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          let pos = {
            lat: places[0].geometry.location.lat(),
            lng: places[0].geometry.location.lng(),
          };

          $(".inputLatChangeAccountEdit").val(pos.lat);
          $(".inputLonChangeAccountEdit").val(pos.lng);

          //console.log(marker);

          marker.setPosition(pos);

          console.log("geolocation");
          console.log(pos);
          console.log(pos.lat);

          map.setCenter(marker.getPosition());
          this.geocodePosition(marker.getPosition());
        });
      }
    });

    //    var bounds = new google.maps.LatLngBounds();
    //    console.log(bounds);

    //    var i, place;
    //    for (i = 0; place = places[i]; i++) {
    //      (function(place) {
    //        var marker = new google.maps.Marker({

    //          position: place.geometry.location
    //        });
    //        console.log(marker);
    //        marker.bindTo('map', searchBox, 'map');
    //        google.maps.event.addListener(marker, 'map_changed', function() {
    //          if (!this.getMap()) {
    //            this.unbindAll();
    //          }
    //        });
    //        bounds.extend(place.geometry.location);

    //      }(place));

    //    }
    //    map.fitBounds(bounds);
    //    searchBox.set('map', map);
    //    map.setZoom(Math.min(map.getZoom(),18));

    //  });

    //Places API ends

    console.log(location);
    console.log(this.map);
    marker = new google.maps.Marker({
      //position: { lat: 45245, lng: 452435 },
      map: map,
      draggable: true,
      animation: google.maps.Animation.DROP,
      position: location,
    });
    console.log(marker.getPosition());

    this.geocodePosition(marker.getPosition());

    google.maps.event.addListener(marker, "dragend", () => {
      map.setCenter(marker.getPosition());
      this.geocodePosition(marker.getPosition());
      $(".inputLatChangeAccountEdit").val(marker.getPosition().lat());
      $(".inputLonChangeAccountEdit").val(marker.getPosition().lng());
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let pos = {
          lat: lat,
          lng: lon,
        };

        $(".inputLatChangeAccountEdit").val(pos.lat);
        $(".inputLonChangeAccountEdit").val(pos.lng);

        //console.log(marker);

        marker.setPosition(pos);

        console.log("geolocation");
        console.log(pos);

        map.setCenter(marker.getPosition());
        this.geocodePosition(marker.getPosition());
      });
    }
    //this.initialize();
  };

  geocodePosition = (pos) => {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        latLng: pos,
      },
      function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          console.log(results);

          var address = {};
          var component = results[0].address_components;
          for (var i = 0; i < component.length; i++) {
            // if (component[i].types.includes("locality")) {
            //   var locality = component[i].long_name;
            // }

            // if (component[i].types.includes("administrative_area_level_1")) {
            //   var arealevel1 = component[i].long_name;
            // }

            // if (component[i].types.includes("postal_code")) {
            //   var postal_code = component[i].long_name;
            // }

            //Oysturn Code
            address[`${component[i].types[0]}`] = component[i].long_name;
            //Oysturn Code Ends
          }

          //$("#address-label").html(results[0].formatted_address);

          var ownShortAddress;
          console.log(address);
          console.log(address);

          $(".inputAddress").val(results[0].formatted_address);
          // $(".inputAddressshort").val(null);
          $(".inputCity").val(address.locality);
          $(".inputState").val(address.administrative_area_level_1);
          $(".inputPincode").val(address.postal_code);
        } else {
          $("#address-label").html("Cannot determine address at this location");
        }
      }
    );
  };

  //confirmation before editing customer address
  editAddressDetailsAccountConfirm = async (event) => {
    console.log("edit account HERE");
    console.log(event.target.attributes[3]["nodeValue"]);

    // vendorAddressId = $(event.currentTarget)
    //   .find("input[name='customerAddressIdAccount']")
    //   .val();

    vendorAddressId = event.target.attributes[3]["nodeValue"];
    console.log(vendorAddressId);

    $.ajax({
      url: baseURL + "api/customer/Account/getAddressViaCustomerAddressId",
      type: "POST",
      async: false,
      data: {
        Customer_Address_ID: vendorAddressId,
      },
      success: (result) => {
        console.log(result);
        $(".inputAddressNameChangeAccountEdit").val(result.Address_Name);
        $(".inputAddressChangeAccountEdit").val(result.Address_Line_1);
        $(".inputAddressshortChangeAccountEdit").val(result.Landmark);
        $(".inputCityChangeAccountEdit").val(result.City);
        $(".inputStateChangeAccountEdit").val(result.State);
        $(".inputPincodeChangeAccountEdit").val(result.Pincode);
        oldLat = result.lat;
        oldLong = result.lon;
      },
      error: (result) => {
        console.log(result);
      },
    });
    console.log(oldLat);

    //googles maps
    var classList = event.target.className.split(" ");
    var idOfGoogleMapsDiv = classList[classList.length - 1];

    console.log(idOfGoogleMapsDiv);

    addressDetails = await GoogleMapsApiObject.editAddress(
      idOfGoogleMapsDiv,
      oldLat,
      oldLong
    );
    //google maps
  };
}
