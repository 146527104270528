var counter = 1;
var minOrderFlag = true;
var minOrderStatus = false;
var minOrderValue;
var delivery_data = [];
var availability_data = [];

import "@dmuy/timepicker/dist/mdtimepicker.css";
import mdtimepicker from "@dmuy/timepicker";

export default class vendor_gst_delivery_details {
  constructor() {
    this.formstep1 = $("#formstep1");
    this.formstep2 = $("#formstep2");
    this.formstep3 = $("#formstep3");
    this.formstep4 = $("#formstep4");
    this.formstep5 = $("#formstep5");

    this.inputGSTRegistered = $("#inputGSTRegistered");
    this.inputCompositGSTRegistered = $("#inputCompositGSTRegistered");
    this.inputGSTNumber = $("#inputGSTNumber");
    this.inputPANNumber = $("#inputPANNumber");
    this.vendorHasGST = $(".vendorHasGST");
    this.vendorHasGSTdiv = $(".vendorHasGSTdiv");
    this.showGSTReg = $(".showGSTReg");
    this.vendorSpecialitiesBox = $(".vendorSpecialitiesBox");
    this.gstCertificateDiv = $("#gstCertificateDiv"); //required?

    //   this.inputAddress = $("#inputAddress");
    //   this.inputLandmark = $("#inputLandmark");
    //   this.inputCity = $("#inputCity");
    //   this.inputState = $("#inputState");

    // this.gstPrevBtn = $("#gstPrevBtn");
    this.stem2submitBtn = $("#stem2submitBtn");

    this.inputGSTRegisteredError = $("#inputGSTRegisteredError");
    this.inputCompositGSTRegisteredError = $(
      "#inputCompositGSTRegisteredError"
    );
    this.inputGSTNumberError = $("#inputGSTNumberError");
    this.inputPANNumberError = $("#inputPANNumberError");
    //   this.passwordError = $("#passwordError");

    this.gst_Deliery_OPHrsForm = $("#gst_Deliery_OPHrsForm");
    //   this.inputLandmarkError = $("#inputLandmarkError");
    //   this.inputCityError = $("#inputCityError");
    //   this.inputStateError = $("#inputStateError");
    //   this.inputpincodeError = $("#inputpincodeError");
    // this.contactPersonNameError = $("#contactPersonNameError");
    // this.contactPersonNameError = $("#contactPersonNameError");

    this.Products_Services_page4 = $(".Products-Services-page4");
    this.GSTDetails_page = $(".GSTDetails-page");

    this.showGSTPercent = $("#showGSTPercent");

    this.productServicesListingPage = $(".product-services-listing-page");

    // this.loginSuccess = 0;

    this.clockPicker1 = $(".clockpicker");

    this.timePicker1 = $(".timepicker");

    //this.clockMinBtn = $("#timepicker");//undo

    // this.mondayEndInput = $("#monday-end-input");
    // this.inputOperatingHrMondayEndError = $("#monday-end-input-error");

    this.monStart = $("#monday-start");
    this.monEnd = $("#monday-end");
    this.monEndError = $("#monday-end-error");
    this.monClosedCheck = $("#monday-closed-checkbox");

    this.tuesStart = $("#tuesday-start");
    this.tuesEnd = $("#tuesday-end");
    this.tuesEndError = $("#tuesday-error");
    this.tusClosedCheck = $("#tuesday-closed-check");

    this.wedStart = $("#wednesday-start");
    this.wedEnd = $("#wednesday-end");
    this.wedEndError = $("#wednesday-error");
    this.wedClosedCheck = $("#wednesday-closed-check");

    this.thursStart = $("#thursday-start");
    this.thursEnd = $("#thursday-end");
    this.thursEndError = $("#thursday-error");
    this.thuClosedCheck = $("#thursday-closed-check");

    this.friStart = $("#friday-start");
    this.friEnd = $("#friday-end");
    this.friEndError = $("#friday-error");
    this.friClosedCheck = $("#friday-closed-check");

    this.satStart = $("#saturday-start");
    this.satEnd = $("#saturday-end");
    this.satEndError = $("#saturday-error");
    this.satClosedCheck = $("#saturday-closed-check");

    this.sunStart = $("#sunday-start");
    this.sunEnd = $("#sunday-end");
    this.sunEndError = $("#sunday-error");
    this.sunClosedCheck = $("#sunday-closed-check");

    this.inputFreeDeliveryKms = $("#inputFreeDeliveryKms");
    this.FreeDeliveryCheckBox = $("#FreeDeliveryCheckBox");
    this.selfPickUpCheckBox = $("#selfPickUpCheckBox");

    this.inputPaidDeliveryKms1 = $("#inputPaidDeliveryKms1");
    this.inputPaidDeliveryRs1 = $("#inputPaidDeliveryRs1");
    this.paidDeliveryCheckBox1 = $("#paidDeliveryCheckBox1");

    this.inputPaidDeliveryKms2 = $("#inputPaidDeliveryKms2");
    this.inputPaidDeliveryRs2 = $("#inputPaidDeliveryRs2");
    this.paidDeliveryCheckBox2 = $("#paidDeliveryCheckBox2");

    this.inputPaidDeliveryKms3 = $("#inputPaidDeliveryKms3");
    this.inputPaidDeliveryRs3 = $("#inputPaidDeliveryRs3");
    this.paidDeliveryCheckBox3 = $("#paidDeliveryCheckBox3");

    this.inputPaidDeliveryKms4 = $("#inputPaidDeliveryKms4");
    this.inputPaidDeliveryRs4 = $("#inputPaidDeliveryRs4");
    this.paidDeliveryCheckBox4 = $("#paidDeliveryCheckBox4");

    this.inputPaidDeliveryKms5 = $("#inputPaidDeliveryKms5");
    this.inputPaidDeliveryRs5 = $("#inputPaidDeliveryRs5");
    this.paidDeliveryCheckBox5 = $("#paidDeliveryCheckBox5");

    this.inputPaidKm = $(".inputPaidKm");

    this.paid2 = $("#paid2");
    this.paid3 = $("#paid3");
    this.paid4 = $("#paid4");
    this.paid5 = $("#paid5");

    this.inputPackagingCharge = $("#inputPackagingCharge");
    this.inputMinOrder = $("#inputMinOrder");
    this.inputFreeDeliveryKmsError = $("#inputFreeDeliveryKmsError");

    this.inputPaidDeliveryKmsError1 = $("#inputPaidDeliveryKmsError1");
    this.inputPaidDeliveryRsError1 = $("#inputPaidDeliveryRsError1");

    this.inputPaidDeliveryKmsError2 = $("#inputPaidDeliveryKmsError2");
    this.inputPaidDeliveryRsError2 = $("#inputPaidDeliveryRsError2");

    this.inputPaidDeliveryKmsError3 = $("#inputPaidDeliveryKmsError3");
    this.inputPaidDeliveryRsError3 = $("#inputPaidDeliveryRsError3");

    this.inputPaidDeliveryKmsError4 = $("#inputPaidDeliveryKmsError4");
    this.inputPaidDeliveryRsError4 = $("#inputPaidDeliveryRsError4");

    this.inputPaidDeliveryKmsError5 = $("#inputPaidDeliveryKmsError5");
    this.inputPaidDeliveryRsError5 = $("#inputPaidDeliveryRsError5");

    this.inputPackagingChargeError = $("#inputPackagingChargeError");
    this.inputMinOrderError = $("#inputMinOrderError");
    this.PaidDeliveryKmError = $("#PaidDeliveryKmError");
    this.PaidDeliveryRsError = $("#PaidDeliveryRsError");

    this.addPaidDeliveryBtn = $("#add-Paid-Delivery-Btn");
    this.paidDeliveryDiv = $("#paid-Delivery-Div");
    this.MoreThan5Error = $("#MoreThan5Error");
    this.noDeliverySelectedError = $("#noDeliverySelectedError");
    this.duplicatePaidDeliveryError = $("#duplicatePaidDeliveryError");
    this.PaidCheckBox = $(".PaidCheckBox");
    this.paidKmError = $(".paidKmError");
    this.paidRsError = $(".paidRsError");

    this.inputPaidDeliveryKms = $(".inputPaidKm");
    this.errorPaidDeliveryKms = $(".paidKmError");
    this.inputPaidDeliveryRs = $(".inputPaidRs");
    this.errorPaidDeliveryRs = $(".paidRsError");
    this.paidDeliveryCheckBox = $(".PaidCheckBox");
    // Registering Events
    this.bindEvents();
  }

  bindEvents = () => {
    console.log("in bindEvents");
    console.log(baseURL);
    console.log(X_API_KEY);

    // this.gstPrevBtn.on("click", this.doPrevAction);
    this.stem2submitBtn.on("click", this.doFormAction);
    this.inputGSTRegistered.on("click", this.GST_checkbox_chkd);
    this.inputCompositGSTRegistered.on("click", this.CompositGST_checkbox_chkd);

    this.monClosedCheck.on("click", this.mon_closed_checkbox_chkd);

    //this.clockPicker1.clockpicker();

    mdtimepicker(".timepicker", { format: "hh:mm", theme: "orange" }); //comment out
    this.addPaidDeliveryBtn.on("click", this.doAddDelivery);
    this.Min_order_Validate();
    //to check if vendor signup is pending and then integrate
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    var vendorStatus = urlParams.get("status");
    console.log(vendorStatus);

    globalThis.userCreated = false;

    if (vendorStatus == "Pending") {
      // vendorStatusFlag = true;
      this.displayDeliveryOptions();
      this.show_vendor_availability();
    }

    this.inputFreeDeliveryKms.on("input", this.checkInputPaidArrays);
    this.inputPaidDeliveryKms.on("input", this.checkInputPaidArrays);
    this.inputPaidDeliveryRs.on("input", this.checkInputPaidArrays);

    this.gst_Deliery_OPHrsForm.on("focus", "input[type=number]", function (e) {
      $(this).on("wheel.disableScroll", function (e) {
        // console.log("inhere");
        e.preventDefault();
      });
    });
  };

  doAddDelivery = () => {
    counter++;
    console.log("inside add");
    console.log(counter);
    // console.log(counter);
    //     var item = ` <div class="d-flex bd-highlight">
    //     <div class="p-2 flex-grow-1 bd-highlight">
    //         <input type="text" class="form-control" id="inputPaidDeliveryKms${counter}" name="Contact_Person" placeholder="KMs">
    //         <span class='text-danger' id="inputPaidDeliveryKmsError${counter}"></span>

    //     </div>
    //     <div class="p-2 flex-grow-1 bd-highlight">
    //         <input type="text" class="form-control" id="inputPaidDeliveryRs${counter}" name="Contact_Person"  placeholder="Rs">
    //         <span class='text-danger' id="inputPaidDeliveryRsError${counter}"></span>
    //     </div>
    //     <div class="p-2 pl-4 pt-3 bd-highlight d-flex justify-content-end ">
    //         <input class="form-check-input align-content-center checkbox-size    " type="checkbox" value="" id="paidDeliveryCheckBox${counter}">
    //     </div>
    // </div>`;
    //   $("#paid-Delivery-Div").append(item);
    // counter++;
    if (counter == 2) {
      this.paid2.removeClass("d-none");
    }
    if (counter == 3) {
      this.paid3.removeClass("d-none");
    }
    if (counter == 4) {
      this.paid4.removeClass("d-none");
    }
    if (counter == 5) {
      this.paid5.removeClass("d-none");
      this.addPaidDeliveryBtn.addClass("d-none");
    }
  };

  doFormAction = (e) => {
    var isPaidDelivValid = this.checkInputPaidArraysValidation();
    console.log("isPaidDelivValid: " + isPaidDelivValid);

    e.preventDefault();
    console.log("in doFormAction");
    console.log(this.PaidCheckBox);
    console.log(this.paidKmError);
    console.log(this.paidRsError);
    let isDeliveryValidated = true;
    if (
      !this.FreeDeliveryCheckBox.is(":checked") &&
      !this.selfPickUpCheckBox.is(":checked") &&
      !this.paidDeliveryCheckBox1.is(":checked") &&
      !this.paidDeliveryCheckBox2.is(":checked") &&
      !this.paidDeliveryCheckBox3.is(":checked") &&
      !this.paidDeliveryCheckBox4.is(":checked") &&
      !this.paidDeliveryCheckBox5.is(":checked")
    ) {
      console.log("inside delivery collapse");
      // Show collapse element on button click
      $("#collapseTwo-2").addClass("show");

      this.noDeliverySelectedError.html(
        "Please select atleast one delivery option."
      );
      isDeliveryValidated = false;
    } else {
      this.noDeliverySelectedError.html(" ");
      console.log("Delivery is selected");
    }

    //checking if free delivery is selected
    let isFreeDeliveryValidated = true;

    if (this.FreeDeliveryCheckBox.is(":checked")) {
      isFreeDeliveryValidated = this.Free_del_Validate();
      console.log("free checked");

      if (isFreeDeliveryValidated) {
        this.inputFreeDeliveryKmsError.html("");
      }
    } else {
      this.inputFreeDeliveryKmsError.html("");
    }

    // let isPaidDeliveryKmInOrder = this.Paid_del_Km_In_Order_Validate();
    // if (isPaidDeliveryKmInOrder) {
    //   this.PaidDeliveryKmError.html("");

    // }
    //checking if paid delivery is selected
    let isPaidDeliveryValidated1 = true;
    let isPaidDeliveryValidated2 = true;
    let isPaidDeliveryValidated3 = true;
    let isPaidDeliveryValidated4 = true;
    let isPaidDeliveryValidated5 = true;

    if (this.paidDeliveryCheckBox1.is(":checked")) {
      console.log("inside paid 1 validation");
      isPaidDeliveryValidated1 = this.Paid_delivery_Validate1();
      if (isPaidDeliveryValidated1) {
        console.log("Paid Delivery 1 is VALID");
        this.inputPaidDeliveryKmsError1.html("");
      }
    } else {
      // this.inputPaidDeliveryKmsError1.html("");
      // this.inputPaidDeliveryRsError1.html("");
    }

    if (this.paidDeliveryCheckBox2.is(":checked")) {
      isPaidDeliveryValidated2 = this.Paid_delivery_Validate2();
      if (isPaidDeliveryValidated2) {
        console.log("Paid Delivery 2 is VALID");
        this.inputPaidDeliveryKmsError2.html("");
      }
    } else {
      // this.inputPaidDeliveryKmsError2.html("");
      // this.inputPaidDeliveryRsError2.html("");
    }

    if (this.paidDeliveryCheckBox3.is(":checked")) {
      isPaidDeliveryValidated3 = this.Paid_delivery_Validate3();
      if (isPaidDeliveryValidated3) {
        console.log("Paid Delivery 3 is VALID");
        this.inputPaidDeliveryKmsError3.html("");
      }
    } else {
      // this.inputPaidDeliveryKmsError3.html("");
      // this.inputPaidDeliveryRsError3.html("");
    }

    if (this.paidDeliveryCheckBox4.is(":checked")) {
      isPaidDeliveryValidated4 = this.Paid_delivery_Validate4();
      if (isPaidDeliveryValidated4) {
        console.log("Paid Delivery 4 is VALID");
        this.inputPaidDeliveryKmsError4.html("");
      }
    } else {
      // this.inputPaidDeliveryKmsError4.html("");
      // this.inputPaidDeliveryRsError4.html("");
    }

    if (this.paidDeliveryCheckBox5.is(":checked")) {
      isPaidDeliveryValidated5 = this.Paid_delivery_Validate5();
      if (isPaidDeliveryValidated5) {
        console.log("Paid Delivery 5 is VALID");
        this.inputPaidDeliveryKmsError5.html("");
      }
    } else {
      // this.inputPaidDeliveryKmsError5.html("");
      // this.inputPaidDeliveryRsError5.html("");
    }

    // console.log("isPaidDeliveryValidated:");
    // console.log(isPaidDeliveryValidated);

    //checking if packaging charge is validated

    let isPackagingChargeValidated = this.Packaging_charge_Validate();
    if (isPackagingChargeValidated) {
      console.log("Packaging charge is VALID");
      this.inputPackagingChargeError.html("");
    }

    //checking if min order is validated
    let isMinOrderValidated = this.Validate_min_order();
    if (isMinOrderValidated) {
      console.log("min enquiry is VALID");
      this.inputMinOrderError.html("");
    }

    // $(".PaidCheckBox").forEach((element) => {
    //    if(element.is(":checked")){
    //     console.log(`Element checked is ${element}`);
    //    }
    // });

    // this.PaidCheckBox.keys().forEach(key => {
    //   console.log(key);
    // });

    // Checking if Vendor is GST Registered
    let isGSTapplicable = this.GST_applicable();
    if (isGSTapplicable) {
      // Checking if Vendor,s  GST Number is valid with a regex
      let isGSTNumberValidated = this.GSTNumber_validate();
      if (isGSTNumberValidated) {
        console.log("GST Number is VALID");
        this.inputGSTNumberError.html("");
      }

      // Checking if Vendor,s  PAN Number is valid with a regex
      let isPANNumberValidated = this.PANNumber_validate();
      if (isPANNumberValidated) {
        console.log("PAN Number is VALID");
        this.inputPANNumberError.html("");
      }

      let operatingHourValidated = this.operatingHour_validate();

      // If GST Registered -> making sure GST Number and PAN number are both entered with validation complete

      if (
        isGSTNumberValidated &&
        isPANNumberValidated &&
        operatingHourValidated &&
        isFreeDeliveryValidated &&
        isPaidDeliveryValidated1 &&
        isPaidDeliveryValidated2 &&
        isPaidDeliveryValidated3 &&
        isPaidDeliveryValidated4 &&
        isPaidDeliveryValidated5 &&
        isPackagingChargeValidated &&
        isMinOrderValidated &&
        isDeliveryValidated &&
        isPaidDelivValid
        // &&
        // isPaidDeliveryKmInOrder
      ) {
        console.log("inside if");
        console.log(
          isGSTNumberValidated && isPANNumberValidated && operatingHourValidated
        );
        this.Submit_Form();
      }
    }
    // Checking if operating hours is validated
    let operatingHourValidated = this.operatingHour_validate();
    console.log(operatingHourValidated);
    if (operatingHourValidated) {
      console.log("Operating hour is VALID");
      //this.monEndError.html("");
    }

    //If Not GST Registered -> submitting form without checking GST & PAN Number
    if (!isGSTapplicable) {
      this.inputGSTRegistered.val(function (val) {
        return 0;
      });

      console.log("Inside gst not appli");
      console.log(isFreeDeliveryValidated);
      console.log(isPaidDeliveryValidated1);
      console.log(isPaidDeliveryValidated2);
      console.log(isPaidDeliveryValidated3);
      console.log(isPaidDeliveryValidated4);
      console.log(isPaidDeliveryValidated5);
      console.log(isPackagingChargeValidated);
      console.log(isDeliveryValidated);
      console.log("isMinOrderValidated");
      console.log(isMinOrderValidated);

      if (
        isFreeDeliveryValidated &&
        isPaidDeliveryValidated1 &&
        isPaidDeliveryValidated2 &&
        isPaidDeliveryValidated3 &&
        isPaidDeliveryValidated4 &&
        isPaidDeliveryValidated5 &&
        isPackagingChargeValidated &&
        isMinOrderValidated &&
        isDeliveryValidated &&
        operatingHourValidated &&
        isPaidDelivValid
        // && isPaidDeliveryKmInOrder
      ) {
        this.Submit_Form();
      }
    }

    // console.log(this.inputGSTRegistered.val());
    // console.log(this.inputCompositGSTRegistered.val());
    // console.log(this.inputGSTNumber.val());
    // console.log(this.inputPANNumber.val());

    // let isCityValidated = this.City_validate();
    // if (isCityValidated) {
    //   console.log("city VALID");
    //   this.inputCityError.html("");
    // }

    // let isPincodeValidated = this.Pincode_validate();
    // if (isPincodeValidated) {
    //   console.log("pincode VALID");
    //   this.inputpincodeError.html("");
    // }

    // let isBusinessNameValidated = this.BusinessName_validate();
    // if (isBusinessNameValidated) {
    //   console.log("BusinessNameValidated VALID");
    //   this.businessNameError.html("");
    // }

    // let isContactPersonNameValidated = this.ContactPersonName_validate();
    // if (isContactPersonNameValidated) {
    //   console.log("ContactPersonNameValidated VALID");
    //   this.contactPersonNameError.html("");
    // }

    // let isPhValidated = this.Phno_validate();
    // if (isPhValidated) {
    //   console.log(" ph VALID");
    //   this.phoneNumberError.html("");
    // } else {
    //   console.log("ERROR");
    // }

    // let isEmailValidated = this.Email_validate();
    // if (isEmailValidated) {
    //   console.log("EmailValidated VALID");
    //   this.emailError.html("");
    // }

    // let isPasswordValidated = this.Password_validate();
    // if (isPasswordValidated) {
    //   console.log("PasswordValidated VALID");
    //   this.passwordError.html("");
    // }

    // if (
    //   isBusinessNameValidated &&
    //   isContactPersonNameValidated &&
    //   isPhValidated &&
    //   isEmailValidated &&
    //   isPasswordValidated
    // ) {
    //   this.Submit_Form();
    //   console.log("ALL FIELDS VALID...VALIDATION OVER");
    // }
  };

  //to check if free delivery KMs is valid
  Free_del_Validate = () => {
    let flag = true;
    console.log("in free del validate");

    if (
      !this.inputFreeDeliveryKms.val() ||
      this.inputFreeDeliveryKms.val() <= 0 ||
      this.inputFreeDeliveryKms.val() > 100
    ) {
      this.inputFreeDeliveryKmsError.html("Kms must be min 1 and max 100.");

      flag = false;
    }

    return flag;
  };

  //to check if paid delivery 1 is valid
  Paid_delivery_Validate1 = () => {
    let flag = true;
    console.log("in paid del validate 1");
    if (
      !this.inputPaidDeliveryKms1.val() ||
      this.inputPaidDeliveryKms1.val() <= 0
    ) {
      this.inputPaidDeliveryKmsError1.html("Kms must be min 1.");

      flag = false;
    }

    if (this.FreeDeliveryCheckBox.is(":checked")) {
      var diff =
        this.inputPaidDeliveryKms1.val() - this.inputFreeDeliveryKms.val();
      console.log(diff);
      if (diff <= 0) {
        console.log("inside paid 1");
        console.log(this.inputFreeDeliveryKms.val());
        console.log(this.inputPaidDeliveryKms1.val());
        this.inputPaidDeliveryKmsError1.html(
          "Paid Kms must be more than Free Kms"
        );

        flag = false;
      }
    }

    if (
      !this.inputPaidDeliveryRs1.val() ||
      this.inputPaidDeliveryRs1.val() <= 0 ||
      this.inputPaidDeliveryRs1.val() > 5000
    ) {
      this.inputPaidDeliveryRsError1.html("Rs must be min 1 max 5000.");

      flag = false;
    } else {
      this.inputPaidDeliveryRsError1.html("");
    }
    return flag;
  };

  //to check if paid delivery is valid
  Paid_delivery_Validate2 = () => {
    let flag = true;
    console.log("in paid del validate");
    if (
      !this.inputPaidDeliveryKms2.val() ||
      this.inputPaidDeliveryKms2.val() <= 0
    ) {
      this.inputPaidDeliveryKmsError2.html("Kms must be min 1.");

      flag = false;
    }

    if (this.FreeDeliveryCheckBox.is(":checked")) {
      var diff =
        this.inputPaidDeliveryKms2.val() - this.inputFreeDeliveryKms.val();
      console.log(diff);
      if (diff <= 0) {
        console.log("inside paid 2");
        console.log(this.inputFreeDeliveryKms.val());
        console.log(this.inputPaidDeliveryKms2.val());
        this.inputPaidDeliveryKmsError2.html(
          "Paid Kms must be more than Free Kms"
        );

        flag = false;
      }
    }

    if (
      !this.inputPaidDeliveryRs2.val() ||
      this.inputPaidDeliveryRs2.val() <= 0 ||
      this.inputPaidDeliveryRs2.val() > 5000
    ) {
      this.inputPaidDeliveryRsError2.html("Rs must be min 1 max 5000.");

      flag = false;
    } else {
      this.inputPaidDeliveryRsError2.html("");
    }

    return flag;
  };

  Paid_delivery_Validate3 = () => {
    let flag = true;
    console.log("in paid del validate");
    if (
      !this.inputPaidDeliveryKms3.val() ||
      this.inputPaidDeliveryKms3.val() <= 0
    ) {
      this.inputPaidDeliveryKmsError3.html("Kms must be min 1.");

      flag = false;
    }

    if (this.FreeDeliveryCheckBox.is(":checked")) {
      var diff =
        this.inputPaidDeliveryKms3.val() - this.inputFreeDeliveryKms.val();
      console.log(diff);
      if (diff <= 0) {
        console.log("inside paid 3");
        console.log(this.inputFreeDeliveryKms.val());
        console.log(this.inputPaidDeliveryKms3.val());
        this.inputPaidDeliveryKmsError3.html(
          "Paid Kms must be more than Free Kms"
        );

        flag = false;
      }
    }

    if (
      !this.inputPaidDeliveryRs3.val() ||
      this.inputPaidDeliveryRs3.val() <= 0 ||
      this.inputPaidDeliveryRs3.val() > 5000
    ) {
      this.inputPaidDeliveryRsError3.html("Rs must be min 1 max 5000.");

      flag = false;
    } else {
      this.inputPaidDeliveryRsError3.html("");
    }

    return flag;
  };

  Paid_delivery_Validate4 = () => {
    let flag = true;
    console.log("in paid del validate");
    if (
      !this.inputPaidDeliveryKms4.val() ||
      this.inputPaidDeliveryKms4.val() <= 0
    ) {
      this.inputPaidDeliveryKmsError4.html("Kms must be min 1.");

      flag = false;
    }

    if (this.FreeDeliveryCheckBox.is(":checked")) {
      var diff =
        this.inputPaidDeliveryKms4.val() - this.inputFreeDeliveryKms.val();
      console.log(diff);
      if (diff <= 0) {
        console.log("inside paid 4");
        console.log(this.inputFreeDeliveryKms.val());
        console.log(this.inputPaidDeliveryKms4.val());
        this.inputPaidDeliveryKmsError4.html(
          "Paid Kms must be more than Free Kms"
        );

        flag = false;
      }
    }
    if (
      !this.inputPaidDeliveryRs4.val() ||
      this.inputPaidDeliveryRs4.val() <= 0 ||
      this.inputPaidDeliveryRs4.val() > 5000
    ) {
      this.inputPaidDeliveryRsError4.html("Rs must be min 1 max 5000.");

      flag = false;
    } else {
      this.inputPaidDeliveryRsError4.html("");
    }

    return flag;
  };

  Paid_delivery_Validate5 = () => {
    let flag = true;
    console.log("in paid del validate");
    if (
      !this.inputPaidDeliveryKms5.val() ||
      this.inputPaidDeliveryKms5.val() <= 0
    ) {
      this.inputPaidDeliveryKmsError5.html("Kms must be min 1.");

      flag = false;
    }

    if (this.FreeDeliveryCheckBox.is(":checked")) {
      var diff =
        this.inputPaidDeliveryKms5.val() - this.inputFreeDeliveryKms.val();
      console.log(diff);
      if (diff <= 0) {
        console.log("inside paid 5");
        console.log(this.inputFreeDeliveryKms.val());
        console.log(this.inputPaidDeliveryKms5.val());
        this.inputPaidDeliveryKmsError5.html(
          "Paid Kms must be more than Free Kms"
        );

        flag = false;
      }
    }

    if (
      !this.inputPaidDeliveryRs5.val() ||
      this.inputPaidDeliveryRs5.val() <= 0 ||
      this.inputPaidDeliveryRs5.val() > 5000
    ) {
      this.inputPaidDeliveryRsError5.html("Rs must be min 1 max 5000.");

      flag = false;
    } else {
      this.inputPaidDeliveryRsError5.html("");
    }

    return flag;
  };

  // To check if GST Registered checkbox is checked
  GST_checkbox_chkd = () => {
    if (this.inputGSTRegistered.is(":checked")) {
      // this.inputGSTRegisteredError.html("Gst is Checked ");
      this.inputGSTRegistered.val(function (val) {
        return 1;
      });
      // this.vendorHasGST.prop("disabled", true);
      this.vendorHasGSTdiv.removeClass("d-none");
      this.showGSTReg.removeClass("d-none");
      this.gstCertificateDiv.removeClass("d-none");
    } else {
      // this.inputGSTRegisteredError.html("Gst is UnChecked ");
      this.inputGSTRegistered.val(function (val) {
        return 0;
      });

      //Resetting the values of CompositGST, GSTNumber, PANNumber
      this.inputCompositGSTRegistered.val(function (val) {
        return null;
      });
      this.inputGSTNumber.val(function (val) {
        return null;
      });
      this.inputPANNumber.val(function (val) {
        return null;
      });
      // this.vendorHasGST.prop("disabled", false);
      this.vendorHasGSTdiv.addClass("d-none");
      this.showGSTReg.addClass("d-none");
      this.gstCertificateDiv.addClass("d-none");
    }
  };

  // To check if Composit GST Registered checkbox is checked
  CompositGST_checkbox_chkd = () => {
    if (this.inputCompositGSTRegistered.is(":checked")) {
      // this.inputCompositGSTRegisteredError.html("Composit Gst is Checked ");
      this.inputCompositGSTRegistered.val(function (val) {
        return 1;
      });
    } else {
      // this.inputCompositGSTRegisteredError.html("Composit Gst is UnChecked ");
      this.inputCompositGSTRegistered.val(function (val) {
        return 0;
      });
    }
  };

  //validate function

  // BusinessName_validate = () => {
  //   console.log("in Business Name validate");
  //   let flag = true;

  //   if (
  //     !(
  //       this.inputBusinessName.val().length >= 2 &&
  //       this.inputBusinessName.val().length <= 100
  //     )
  //   ) {
  //     this.businessNameError.html("Business Name must be 2-100 characters!");

  //     flag = false;
  //   }

  //   return flag;
  // };

  // Validation of GST Number with a regex
  GSTNumber_validate = () => {
    console.log("in GSTNumber_validate validate");
    let flag = true;
    var GSTNumber = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (
      !(
        this.inputGSTNumber.val().length >= 14 &&
        this.inputGSTNumber.val().length <= 16
      ) ||
      !this.inputGSTNumber.val().match(GSTNumber)
    ) {
      $("#collapseOne-2").addClass("show");

      this.inputGSTNumberError.html("Please enter correct GST Number.");

      flag = false;
    } else {
      this.inputGSTNumberError.html("");
    }

    return flag;
  };

  // Validation of PAN Number with a regex
  PANNumber_validate = () => {
    console.log("in PANNumber_validate validate");
    let flag = true;
    var PANNumber = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    //PAN number is a 10dig number
    if (
      !(
        this.inputPANNumber.val().length >= 5 &&
        this.inputPANNumber.val().length <= 15
      ) ||
      !this.inputPANNumber.val().match(PANNumber)
    ) {
      $("#collapseOne-2").addClass("show");

      this.inputPANNumberError.html("Please enter correct PAN Number.");

      flag = false;
    } else {
      this.inputPANNumberError.html("");
    }

    return flag;
  };

  // Checking if GST is applicable
  GST_applicable = () => {
    if (this.inputGSTRegistered.val() == 1) {
      return true;
    } else {
      this.inputGSTRegistered.val(function (val) {
        return 0;
      });
      return false;
    }
  };

  operatingHour_validate = () => {
    let flag = true;

    let tuesStartTime = this.tuesStart.val();
    let tuesEndTime = this.tuesEnd.val();

    console.log("inside operating validate");
    console.log(tuesStartTime);
    console.log(tuesEndTime);
    console.log($.type(tuesEndTime));
    console.log(tuesStartTime.replace(":", "."));
    console.log(tuesEndTime.replace(":", "."));
    let tuesStartTimeInt = parseInt(tuesStartTime);
    let tuesEndTimeInt = parseInt(tuesEndTime);
    console.log($.type(tuesStartTimeInt));
    let tuesDifference = tuesEndTimeInt - tuesStartTimeInt;
    console.log($.type(tuesDifference));
    console.log(tuesDifference);

    let MonStartTime = this.monStart.val();
    let MonEndTime = this.monEnd.val();
    console.log(MonStartTime);
    console.log(MonEndTime);
    console.log($.type(MonStartTime));
    console.log(MonStartTime.replace(":", "."));
    console.log(MonEndTime.replace(":", "."));
    let MonStartTimeInt = parseInt(MonStartTime);
    let MonEndTimeInt = parseInt(MonEndTime);
    console.log($.type(MonStartTimeInt));
    let MonDifference = MonEndTimeInt - MonStartTimeInt;
    console.log(MonDifference);

    let wedStartTime = this.wedStart.val();
    let wedEndTime = this.wedEnd.val();
    console.log(wedStartTime);
    console.log(wedEndTime);
    console.log($.type(wedEndTime));
    console.log(wedStartTime.replace(":", "."));
    console.log(wedEndTime.replace(":", "."));
    let wedStartTimeInt = parseInt(wedStartTime);
    let wedEndTimeInt = parseInt(wedEndTime);
    let wedDifference = wedEndTimeInt - wedStartTimeInt;

    let thursStartTime = this.thursStart.val();
    let thursEndTime = this.thursEnd.val();
    console.log(thursStartTime);
    console.log(thursEndTime);
    console.log($.type(thursEndTime));
    console.log(thursStartTime.replace(":", "."));
    console.log(thursEndTime.replace(":", "."));
    let thursStartTimeInt = parseInt(thursStartTime);
    let thursEndTimeInt = parseInt(thursEndTime);
    let thursDifference = thursEndTimeInt - thursStartTimeInt;

    let friStartTime = this.friStart.val();
    let friEndTime = this.friEnd.val();
    console.log(friStartTime);
    console.log(friEndTime);
    console.log($.type(friEndTime));
    console.log(friStartTime.replace(":", "."));
    console.log(friEndTime.replace(":", "."));
    let friStartTimeInt = parseInt(friStartTime);
    let friEndTimeInt = parseInt(friEndTime);
    let friDifference = friEndTimeInt - friStartTimeInt;

    let satStartTime = this.satStart.val();
    let satEndTime = this.satEnd.val();
    console.log(satStartTime);
    console.log(satEndTime);
    console.log($.type(satEndTime));
    console.log(satStartTime.replace(":", "."));
    console.log(satEndTime.replace(":", "."));
    let satStartTimeInt = parseInt(satStartTime);
    let satEndTimeInt = parseInt(satEndTime);
    let satDifference = satEndTimeInt - satStartTimeInt;

    let sunStartTime = this.sunStart.val();
    let sunEndTime = this.sunEnd.val();
    console.log(sunStartTime);
    console.log(sunEndTime);
    console.log($.type(sunEndTime));
    console.log(sunStartTime.replace(":", "."));
    console.log(sunEndTime.replace(":", "."));
    let sunStartTimeInt = parseInt(sunStartTime);
    let sunEndTimeInt = parseInt(sunEndTime);
    let sunDifference = sunEndTimeInt - sunStartTimeInt;

    // if(this.tuesEnd.val().length == 0){
    //   console.log("Show empty error");
    //   this.tuesEndError.html("Please enter a closing time");
    // }
    // else{
    //   console.log("Show no empty error");
    //   this.tuesEndError.html("");
    // }

    if (MonDifference <= 0) {
      $("#collapseThree-1").addClass("show");

      console.log("Show mon error");
      this.monEndError.html("Please choose a correct closing time");
      flag = false;
    } else {
      console.log(" dont show mon error");
      this.monEndError.html("");
    }

    if (tuesDifference <= 0) {
      $("#collapseThree-1").addClass("show");

      console.log("Show tues error");
      this.tuesEndError.html("Please choose a correct closing time");
      flag = false;
    } else {
      console.log(" dont show tues error");
      this.tuesEndError.html("");
    }

    if (wedDifference <= 0) {
      $("#collapseThree-1").addClass("show");

      console.log("Show wed error");
      this.wedEndError.html("Please choose a correct closing time");
      flag = false;
    } else {
      console.log(" dont show wed error");
      this.wedEndError.html("");
    }

    if (thursDifference <= 0) {
      $("#collapseThree-1").addClass("show");

      console.log("Show thurs error");
      this.thursEndError.html("Please choose a correct closing time");
      flag = false;
    } else {
      console.log(" dont show thurs error");
      this.thursEndError.html("");
    }

    if (friDifference <= 0) {
      $("#collapseThree-1").addClass("show");

      console.log("Show fri error");
      this.friEndError.html("Please choose a correct closing time");
      flag = false;
    } else {
      console.log(" dont show fri error");
      this.friEndError.html("");
    }

    if (satDifference <= 0) {
      $("#collapseThree-1").addClass("show");

      console.log("Show sat error");
      this.satEndError.html("Please choose a correct closing time");
      flag = false;
    } else {
      console.log(" dont show sat error");
      this.satEndError.html("");
    }

    if (sunDifference <= 0) {
      $("#collapseThree-1").addClass("show");

      console.log("Show sun error");
      this.sunEndError.html("Please choose a correct closing time");
      flag = false;
    } else {
      console.log(" dont show sun  error");
      this.sunEndError.html("");
    }

    return flag;
  };

  // Phno_validate = () => {
  //   console.log("in Phone number validate");
  //   let flag = true;
  //   var phoneno = /^\d{10}$/;
  //   if (!this.inputPhoneNumber.val().match(phoneno)) {
  //     this.phoneNumberError.html(
  //       "Please enter only 10 integer numbers for mobile number!"
  //     );

  //     flag = false;
  //   }

  //   return flag;
  // };

  // Email_validate = () => {
  //   console.log("in Email validate");
  //   let flag = true;
  //   var emailexample =
  //     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //   if (!this.inputEmail1.val().match(emailexample)) {
  //     this.emailError.html("Please enter a valid email");

  //     flag = false;
  //   }

  //   return flag;
  // };

  // Password_validate = () => {
  //   console.log("in Password validate");
  //   let flag = true;
  //   var PasswordExample =
  //     /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

  //   if (
  //     !(
  //       this.inputPassword.val().length >= 6 &&
  //       this.inputPassword.val().length <= 15
  //     ) ||
  //     !this.inputPassword.val().match(PasswordExample)
  //   ) {
  //     this.passwordError.html(
  //       "Please use Minimum six and Maximum 15 characters, at least one letter, one number, one capital character and one special character"
  //     );

  //     flag = false;
  //   }

  //   return flag;
  // };

  // Address_validate = () => {
  //   console.log("in address validate");
  //   let flag = true;

  //   if (this.inputAddress.val().length < 5) {
  //     this.inputAddressError.html(
  //       "Address should be minimum 5 characters long!"
  //     );

  //     flag = false;
  //   }

  //   return flag;
  // };

  // City_validate = () => {
  //   console.log("in city validate");
  //   let flag = true;

  //   var cityExample = /^[A-Za-z0-9 ]+$/;

  //   if (
  //     !this.inputCity.val().match(cityExample) ||
  //     this.inputCity.val().length < 3
  //   ) {
  //     this.inputCityError.html(
  //       "City should be minimum 3 characters long and should not contain special characters!"
  //     );

  //     flag = false;
  //   }

  //   return flag;
  // };

  // Pincode_validate = () => {
  //   console.log("in pincode validate");
  //   let flag = true;

  //   var pincodeExample = /^[0-9]*$/;

  //   if (
  //     !this.inputPincode.val().match(pincodeExample) ||
  //     this.inputPincode.val().length < 6
  //   ) {
  //     this.inputpincodeError.html(
  //       "Pincode should be minimum 6 characters long and should contain only numbers!"
  //     );

  //     flag = false;
  //   }

  //   return flag;
  // };

  // Validate_Email = () => {
  //   let userEmail = this.inputEmail1.val();
  //   $.ajax({
  //     type: "POST",
  //     url: baseURL + "api/ZeroBounce/update",
  //     data: {
  //       basicEmail: userEmail,
  //     },
  //     success: function (res) {
  //       console.log(res);
  //     },
  //   });
  // };

  // phoneExists = (res) => {
  //   let retCode = res.Code;
  //   console.log("Inside phoneExists Fn");
  //   console.log(res.Code);

  //   // console.log(res.code);

  //   if (retCode == 6) {
  //     // this.phoneExists();
  //     console.log("Inside phoneExists Fn Error 6");

  //     this.phoneNumberError.html("Phone Number ALREADY EXISTS!");
  //   } else if (retCode == 7) {
  //     // this.phoneExists();
  //     this.emailError.html("Email ALREADY EXISTS!");
  //   } else {
  //     // console.log("Inside submit flow");
  //     console.log("Inside phoneExists ELSE Fn");
  //     this.Validate_Email();

  //     this.phoneNumberError.html("");
  //     this.emailError.html("");

  //     // document.location = "vendor_login";
  //   }
  // };

  Packaging_charge_Validate = () => {
    let flag = true;
    console.log("in packaging charge validate");

    if (
      this.inputPackagingCharge.val() > 5000 ||
      this.inputPackagingCharge.val() < 0
    ) {
      this.inputPackagingChargeError.html(
        "Packaging charge must be min 0 and max 5000."
      );

      flag = false;
    }

    return flag;
  };

  Min_order_Validate = () => {
    minOrderFlag = true;
    console.log("in Min_order_Validate ");
    minOrderStatus = false;

    if (true) {
      $.ajax({
        type: "GET",
        url: baseURL + "api/Vendor/GetMinOrderValue",
        success: this.Validate_Min,
      });
    }
    console.log("returning from Min_order_Validate");
    return true;
  };

  Validate_Min = (res) => {
    res.forEach((element) => {
      minOrderValue = element.Value;
    });

    return true;
  };

  Validate_min_order = () => {
    let flag = true;
    console.log("in packaging charge validate");
    console.log(minOrderValue);
    var minOrderdiff = this.inputMinOrder.val() - minOrderValue;
    if (
      this.inputMinOrder.length < 1 ||
      minOrderdiff < 0 ||
      this.inputMinOrder.val() > 5000
    ) {
      $("#collapseTwo-2").addClass("show");
      this.inputMinOrderError.html(
        `Minimum enquiry charge must be min ${minOrderValue} and max 5000.`
      );

      flag = false;
    }

    return flag;
  };

  // Checking if GST is applicable
  GST_applicable = () => {
    if (this.inputGSTRegistered.val() == 1) {
      return true;
    } else {
      this.inputGSTRegistered.val(function (val) {
        return 0;
      });
      return false;
    }
  };

  fillDeliveryData = () => {
    delivery_data = [];

    if (this.FreeDeliveryCheckBox.is(":checked")) {
      var free_delivery_data = {
        Picklist_ID: "67bf6dfa-4497-11e9-b548-778fd1e3dff0",
        Distance_kms: this.inputFreeDeliveryKms.val(),
        Delivery_Type: "KM",
        Delivery_Name: "Free Delivery",
        Price: "0",
        IsAvailable: "1",
      };
      delivery_data.push(free_delivery_data);
      console.log("delivery_data checked:");
      console.log(delivery_data);
    }
    if (!this.FreeDeliveryCheckBox.is(":checked")) {
      var free_delivery_data = {
        Picklist_ID: "67bf6dfa-4497-11e9-b548-778fd1e3dff0",
        Distance_kms: 0,
        Delivery_Type: "KM",
        Delivery_Name: "Free Delivery",
        Price: "0",
        IsAvailable: null,
      };
      delivery_data.push(free_delivery_data);
    }

    if (this.selfPickUpCheckBox.is(":checked")) {
      var self_delivery_data = {
        Picklist_ID: "67bf6dff-4497-11e9-b548-778fd1e3dff0",
        Distance_kms: 0,
        Delivery_Type: "None",
        Delivery_Name: "Self Pick-up",
        Price: 0,
        IsAvailable: "1",
      };
      delivery_data.push(self_delivery_data);
      console.log("delivery_data:");
      console.log(delivery_data);
    }
    if (!this.selfPickUpCheckBox.is(":checked")) {
      var self_delivery_data = {
        Picklist_ID: "67bf6dff-4497-11e9-b548-778fd1e3dff0",
        Distance_kms: 0,
        Delivery_Type: "None",
        Delivery_Name: "Self Pick-up",
        Price: 0,
        IsAvailable: null,
      };
      delivery_data.push(self_delivery_data);
    }

    if (this.paidDeliveryCheckBox1.is(":checked")) {
      var Paid1_delivery_data = {
        Picklist_ID: "67bf6dfe-4497-11e9-b548-778fd1e3dff0",
        Distance_kms: this.inputPaidDeliveryKms1.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery1",
        Price: this.inputPaidDeliveryRs1.val(),
        IsAvailable: "1",
      };
      delivery_data.push(Paid1_delivery_data);
    }
    if (!this.paidDeliveryCheckBox1.is(":checked")) {
      var Paid1_delivery_data = {
        Picklist_ID: "67bf6dfe-4497-11e9-b548-778fd1e3dff0",
        Distance_kms: this.inputPaidDeliveryKms1.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery1",
        Price: this.inputPaidDeliveryRs1.val(),
        IsAvailable: null,
      };
      delivery_data.push(Paid1_delivery_data);
    }

    if (this.paidDeliveryCheckBox2.is(":checked")) {
      var Paid2_delivery_data = {
        Picklist_ID: "4927024c-49f2-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms2.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery2",
        Price: this.inputPaidDeliveryRs2.val(),
        IsAvailable: "1",
      };
      delivery_data.push(Paid2_delivery_data);
    }
    if (!this.paidDeliveryCheckBox2.is(":checked")) {
      var Paid2_delivery_data = {
        Picklist_ID: "4927024c-49f2-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms2.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery2",
        Price: this.inputPaidDeliveryRs2.val(),
        IsAvailable: null,
      };
      delivery_data.push(Paid2_delivery_data);
    }
    if (this.paidDeliveryCheckBox3.is(":checked")) {
      var Paid3_delivery_data = {
        Picklist_ID: "532f81a8-49f3-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms3.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery",
        Price: this.inputPaidDeliveryRs3.val(),
        IsAvailable: "1",
      };
      delivery_data.push(Paid3_delivery_data);
    }
    if (!this.paidDeliveryCheckBox3.is(":checked")) {
      var Paid3_delivery_data = {
        Picklist_ID: "532f81a8-49f3-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms3.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery",
        Price: this.inputPaidDeliveryRs3.val(),
        IsAvailable: null,
      };
      delivery_data.push(Paid3_delivery_data);
    }
    if (this.paidDeliveryCheckBox4.is(":checked")) {
      var Paid4_delivery_data = {
        Picklist_ID: "b2c9bc3f-49f3-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms4.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery",
        Price: this.inputPaidDeliveryRs4.val(),
        IsAvailable: "1",
      };
      delivery_data.push(Paid4_delivery_data);
    }

    if (!this.paidDeliveryCheckBox4.is(":checked")) {
      var Paid4_delivery_data = {
        Picklist_ID: "b2c9bc3f-49f3-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms4.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery",
        Price: this.inputPaidDeliveryRs4.val(),
        IsAvailable: null,
      };
      delivery_data.push(Paid4_delivery_data);
    }
    if (this.paidDeliveryCheckBox5.is(":checked")) {
      var Paid5_delivery_data = {
        Picklist_ID: "f7b18514-49f3-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms5.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery",
        Price: this.inputPaidDeliveryRs5.val(),
        IsAvailable: "1",
      };
      delivery_data.push(Paid5_delivery_data);
    }
    if (!this.paidDeliveryCheckBox5.is(":checked")) {
      var Paid5_delivery_data = {
        Picklist_ID: "f7b18514-49f3-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms5.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery",
        Price: this.inputPaidDeliveryRs5.val(),
        IsAvailable: null,
      };
      delivery_data.push(Paid5_delivery_data);
    }
  };

  // fillAvailabilityData = () => {
  //   var availability_day1 = {
  //     Day: "Monday",
  //     Priority: 1,
  //     Start_time: this.monStart.val(),
  //     End_time: this.monEnd.val(),
  //     Available: this.monClosedCheck.is(":checked") ? "0" : "1",
  //   };
  //   var availability_day2 = {
  //     Day: "Tuesday",
  //     Priority: 2,
  //     Start_time: this.tuesStart.val(),
  //     End_time: this.tuesEnd.val(),
  //     Available: this.tusClosedCheck.is(":checked") ? "0" : "1",
  //   };
  //   var availability_day3 = {
  //     Day: "Wednesday",
  //     Priority: 3,
  //     Start_time: this.wedStart.val(),
  //     End_time: this.wedEnd.val(),
  //     Available: this.wedClosedCheck.is(":checked") ? "0" : "1",
  //   };
  //   var availability_day4 = {
  //     Day: "Thursday",
  //     Priority: 4,
  //     Start_time: this.thursStart.val(),
  //     End_time: this.thursEnd.val(),
  //     Available: this.thuClosedCheck.is(":checked") ? "0" : "1",
  //   };
  //   var availability_day5 = {
  //     Day: "Friday",
  //     Priority: 5,
  //     Start_time: this.friStart.val(),
  //     End_time: this.friEnd.val(),
  //     Available: this.friClosedCheck.is(":checked") ? "0" : "1",
  //   };
  //   var availability_day6 = {
  //     Day: "Saturday",
  //     Priority: 6,
  //     Start_time: this.satStart.val(),
  //     End_time: this.satEnd.val(),
  //     Available: this.satClosedCheck.is(":checked") ? "0" : "1",
  //   };
  //   var availability_day7 = {
  //     Day: "Sunday",
  //     Priority: 7,
  //     Start_time: this.sunStart.val(),
  //     End_time: this.sunEnd.val(),
  //     Available: this.sunClosedCheck.is(":checked") ? "0" : "1",
  //   };
  //   availability_data = [];
  //   availability_data.push(availability_day1);
  //   availability_data.push(availability_day2);
  //   availability_data.push(availability_day3);
  //   availability_data.push(availability_day4);
  //   availability_data.push(availability_day5);
  //   availability_data.push(availability_day6);
  //   availability_data.push(availability_day7);

  //   console.log("Making ajax call to AddUpdateAvailabilities");
  //   $.ajax({
  //     type: "POST",

  //     url: baseURL + "api/Vendor/AddUpdateAvailabilities",
  //     headers: { Authorization: X_API_KEY },

  //     data: {
  //       availability_data: availability_data,
  //     },

  //     success: function (res) {
  //       console.log(res);
  //     },
  //   });
  // };

  // Form Submit Flow
  Submit_Form = () => {
    console.log("Inside submit flow");
    console.log(this.gst_Deliery_OPHrsForm.serialize());
    this.fillDeliveryData();
    this.fillAvailabilityData();

    $.ajax({
      type: "POST",

      url: baseURL + "api/Vendor/AddUpdateGST",

      headers: { Authorization: X_API_KEY },

      data: this.gst_Deliery_OPHrsForm.serialize(),

      success: function (res) {
        console.log(res);
      },
    });

    $.ajax({
      type: "POST",

      url: baseURL + "api/Vendor/AddUpdateDeliveryOptions",
      headers: { Authorization: X_API_KEY },

      data: { delivery_data: delivery_data },

      success: function (res) {
        console.log(res);
      },
    });

    $.ajax({
      type: "POST",

      url: baseURL + "api/Vendor/PackagingMinValue",
      headers: { Authorization: X_API_KEY },

      data: {
        // 'Picklist_ID' :"67bf6dfe-4497-11e9-b548-778fd1e3dff0",
        Packaging_charge: this.inputPackagingCharge.val(),
        Minimum_Order: this.inputMinOrder.val(),
      },

      success: function (res) {
        console.log(res);
      },
    });

    if (firstProductAdded == false) {
      this.Products_Services_page4.removeClass("d-none");
    } else {
      this.productServicesListingPage.removeClass("d-none");
    }

    this.GSTDetails_page.addClass("d-none");
    // this.formstep1.addClass("current-item");
    // this.formstep2.addClass("current-item");
    this.formstep3.removeClass("current-item");
    this.formstep4.addClass("current-item");
  };

  displayDeliveryOptions = () => {
    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/GetDeliveryOptions",
      headers: { Authorization: X_API_KEY },
      async: false,
      success: this.integrateDeliveryOptions,
    });

    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/getVendorDetails",
      headers: { Authorization: X_API_KEY },
      async: false,
      success: (result) => {
        document.getElementById("inputPackagingCharge").value =
          result.Packaging_charge;
        document.getElementById("inputMinOrder").value = result.Minimum_Order;
      },
    });
  };

  integrateDeliveryOptions = (res) => {
    console.log("inside integrateDeliveryOptions");
    console.log(res);
    // for(var i=0;i<7;i++){
    //     if (res["Delivery_Type"] == "KM") {
    //             document.getElementById("inputFreeDeliveryKms").value =
    //               res["Distance_kms"];
    //           }
    // }
    res.forEach((element) => {
      //FREE DELIVERY
      if (element.Delivery_Type == "KM") {
        if (element.IsAvailable == true) {
          document.getElementById("FreeDeliveryCheckBox").checked = true;
        }
        document.getElementById("inputFreeDeliveryKms").value =
          element.Distance_kms;
      }

      //SELF PICKUP
      if (element.Delivery_Type == "None" && element.IsAvailable == true) {
        document.getElementById("selfPickUpCheckBox").checked = true;
      }
      //PAID DELIVERY
      if (element.Delivery_Type == "RSKM") {
        var paidNo = element.Delivery_Name.substring(13, 15);
        if (element.IsAvailable == true) {
          document.getElementById("paidDeliveryCheckBox1").checked = true;
        }

        console.log(paidNo);
        var item = `
        <div class="" id="paid${paidNo}">
         <div class="d-flex bd-highlight">
            <div class="p-2 flex-grow-1 bd-highlight">
                <input type="number" class="form-control inputPaidKm" id="inputPaidDeliveryKms${paidNo}" name="inputPaidDeliveryKms" placeholder="KMs">
                <span class='text-danger paidKmError' id="inputPaidDeliveryKmsError${paidNo}"></span>
            </div>
            <div class="p-2 flex-grow-1 bd-highlight">
                <input type="number" class="form-control" id="inputPaidDeliveryRs${paidNo}" name="inputPaidDeliveryRs" placeholder="Rs">
                <span class='text-danger paidRsError' id="inputPaidDeliveryRsError${paidNo}"></span>
            </div>
            <div class="p-2 pl-4 pt-3 bd-highlight d-flex justify-content-end ">
                <input class="form-check-input align-content-center checkbox-size      PaidCheckBox" type="checkbox" value="" id="paidDeliveryCheckBox${paidNo}">
            </div>
         </div>
        </div>`;
        // $("#paid-Delivery-Div").append(item);
        if (paidNo == "1") {
          document.getElementById("inputPaidDeliveryKms1").value =
            element.Distance_kms;
          document.getElementById("inputPaidDeliveryRs1").value = element.Price;
        }
        if (paidNo == "2") {
          if (element.IsAvailable == "1") {
            document.getElementById("paidDeliveryCheckBox2").checked = true;
          }

          document.getElementById("inputPaidDeliveryKms2").value =
            element.Distance_kms == "0" ? null : element.Distance_kms;
          document.getElementById("inputPaidDeliveryRs2").value =
            element.Distance_kms == "0" ? null : element.Price;
        }
        if (paidNo == "3") {
          if (element.IsAvailable == "1") {
            document.getElementById("paidDeliveryCheckBox3").checked = true;
          }
          document.getElementById("inputPaidDeliveryKms3").value =
            element.Distance_kms == "0" ? null : element.Distance_kms;
          document.getElementById("inputPaidDeliveryRs3").value =
            element.Distance_kms == "0" ? null : element.Price;
        }
        if (paidNo == "4") {
          if (element.IsAvailable == "1") {
            document.getElementById("paidDeliveryCheckBox4").checked = true;
          }
          document.getElementById("inputPaidDeliveryKms4").value =
            element.Distance_kms == "0" ? null : element.Distance_kms;
          document.getElementById("inputPaidDeliveryRs4").value =
            element.Distance_kms == "0" ? null : element.Price;
        }
        if (paidNo == "5") {
          if (element.IsAvailable == "1") {
            document.getElementById("paidDeliveryCheckBox5").checked = true;
          }
          document.getElementById("inputPaidDeliveryKms5").value =
            element.Distance_kms == "0" ? null : element.Distance_kms;
          document.getElementById("inputPaidDeliveryRs5").value =
            element.Distance_kms == "0" ? null : element.Price;
        }

        if (element.Distance_kms != "0" && paidNo != "1") {
          this.doAddDelivery();
        }
      }
    });
  };

  show_vendor_availability = () => {
    console.log("in Vendor calender js");
    $.ajax({
      type: "GET",

      url: baseURL + "api/Vendor/GetAvailabilities",
      headers: { Authorization: X_API_KEY },

      success: this.showOperatingHours,
    });
    // mdtimepicker(".timepicker", { format: "hh:mm", theme: "orange" });
    // this.continueBtn.on("click", this.fillAvailabilityData);
  };

  // To Display Operating Hours of a Vendor
  showOperatingHours = (res) => {
    console.log("Loading the vendor availability");
    $(".CurrentOperatingHours").empty();
    mdtimepicker("#time", { format: "hh:mm", theme: "orange" });
    res.forEach((element) => {
      var operatingHours = `
      
      <div class="col">
      <label for="${element.Day.toLowerCase()}-start-input" class="form-label">&ensp;&nbsp;${
        element.Day
      }</label>
  </div>
  <div class="col text-right">
      <label for="inputBusinessName" class="form-label ml-4">Closed</label>
  </div>
  <!-- </div> -->
  <div class="d-flex bd-highlight">
      <div class="p-2  me-lg-3 flex-grow-1 bd-highlight">
      <div class="p-2  me-3 ">
          <input type="text" class="form-control timepicker" id="${element.Day.toLowerCase()}-start" value="${
        element.Start_time
      }" name="" aria-describedby="contactPersonHelp" placeholder="">
          <!-- <div id="${element.Day.toLowerCase()}-start-error"></div> -->
  
          </div>
      </div>
      <div class="p-2  me-lg-3 flex-grow-1 bd-highlight">
      <div class="p-2  me-3">

          <input type="text" class="form-control timepicker" id="${element.Day.toLowerCase()}-end" value="${
        element.End_time
      }" name="" aria-describedby="contactPersonHelp" placeholder="">
          <div id="${element.Day.toLowerCase()}-end-error" class='text-danger'></div>
      </div>
      </div>

      <div class="p-2 pl-4 pt-3 mt-1 flex-grow-0 bd-highlight d-flex justify-content-end ">
          <input ${
            element.Available == true ? "" : "checked"
          }  class="form-check-input align-content-center checkbox-size      " type="checkbox" value="" id="${element.Day.toLowerCase()}-closed-check">
      </div>
  </div>
  <span class='text-danger' id="inputAddressError"></span>
  
                              `;
      $(".CurrentOperatingHours").append(operatingHours);
      mdtimepicker(".timepicker", { format: "hh:mm", theme: "orange" });
    });
  };

  fillAvailabilityData = () => {
    this.monStart = $("#monday-start");
    this.monEnd = $("#monday-end");
    this.monEndError = $("#monday-end-error");
    this.monClosedCheck = $("#monday-closed-check");

    this.tuesStart = $("#tuesday-start");
    this.tuesEnd = $("#tuesday-end");
    this.tuesEndError = $("#tuesday-error");
    this.tusClosedCheck = $("#tuesday-closed-check");

    this.wedStart = $("#wednesday-start");
    this.wedEnd = $("#wednesday-end");
    this.wedEndError = $("#wednesday-error");
    this.wedClosedCheck = $("#wednesday-closed-check");

    this.thursStart = $("#thursday-start");
    this.thursEnd = $("#thursday-end");
    this.thursEndError = $("#thursday-error");
    this.thuClosedCheck = $("#thursday-closed-check");

    this.friStart = $("#friday-start");
    this.friEnd = $("#friday-end");
    this.friEndError = $("#friday-error");
    this.friClosedCheck = $("#friday-closed-check");

    this.satStart = $("#saturday-start");
    this.satEnd = $("#saturday-end");
    this.satEndError = $("#saturday-error");
    this.satClosedCheck = $("#saturday-closed-check");

    this.sunStart = $("#sunday-start");
    this.sunEnd = $("#sunday-end");
    this.sunEndError = $("#sunday-error");
    this.sunClosedCheck = $("#sunday-closed-check");
    var availability_day1 = {
      Day: "Monday",
      Start_time: this.monStart.val(),
      End_time: this.monEnd.val(),
      Available: this.monClosedCheck.is(":checked") ? "0" : "1",
    };
    var availability_day2 = {
      Day: "Tuesday",
      Start_time: this.tuesStart.val(),
      End_time: this.tuesEnd.val(),
      Available: this.tusClosedCheck.is(":checked") ? "0" : "1",
    };
    var availability_day3 = {
      Day: "Wednesday",
      Start_time: this.wedStart.val(),
      End_time: this.wedEnd.val(),
      Available: this.wedClosedCheck.is(":checked") ? "0" : "1",
    };
    var availability_day4 = {
      Day: "Thursday",
      Start_time: this.thursStart.val(),
      End_time: this.thursEnd.val(),
      Available: this.thuClosedCheck.is(":checked") ? "0" : "1",
    };
    var availability_day5 = {
      Day: "Friday",
      Start_time: this.friStart.val(),
      End_time: this.friEnd.val(),
      Available: this.friClosedCheck.is(":checked") ? "0" : "1",
    };
    var availability_day6 = {
      Day: "Saturday",
      Start_time: this.satStart.val(),
      End_time: this.satEnd.val(),
      Available: this.satClosedCheck.is(":checked") ? "0" : "1",
    };
    var availability_day7 = {
      Day: "Sunday",
      Start_time: this.sunStart.val(),
      End_time: this.sunEnd.val(),
      Available: this.sunClosedCheck.is(":checked") ? "0" : "1",
    };

    availability_data.push(availability_day1);
    availability_data.push(availability_day2);
    availability_data.push(availability_day3);
    availability_data.push(availability_day4);
    availability_data.push(availability_day5);
    availability_data.push(availability_day6);
    availability_data.push(availability_day7);

    console.log("Making ajax call to AddUpdateAvailabilities");
    console.log(availability_data);
    $.ajax({
      type: "POST",

      url: baseURL + "api/Vendor/AddUpdateAvailabilities",
      headers: { Authorization: X_API_KEY },

      data: {
        availability_data: availability_data,
      },

      success: function (res) {
        console.log(res);
      },
    });
  };

  validateInputPaidKm = (e) => {
    var inputNumber = e.target.id.charAt(e.target.id.length - 1);
    var otherInput = "";
    var currentVal = parseInt(e.target.value);
    console.log(e.target.value);
    console.log(currentVal);
    console.log(e.target.value == "");

    for (var i = 1; i <= this.inputPaidDeliveryKms.length; i++) {
      otherInput = document.getElementById("inputPaidDeliveryKms" + i);
      var otherValue = parseInt(otherInput.value);

      if (inputNumber == i) {
        console.log("Break");
        break;
      }

      if (inputNumber == i || isNaN(otherValue)) {
        console.log("Continue");
        continue;
      }
      const InputError = document.getElementById(
        "inputPaidDeliveryKmsError" + inputNumber
      );
      if (otherValue < currentVal) {
        console.log("Input Is Valid");
        InputError.innerHTML = "";
        InputError.value = 0;
      } else {
        console.error("Input Is Not Valid");
        InputError.value = 1;
        InputError.innerHTML = "Km should be greater than " + otherValue;
      }
    }

    for (var i = this.inputPaidDeliveryKms.length; i > 0; i--) {
      otherInput = document.getElementById("inputPaidDeliveryKms" + i);
      var otherValue = parseInt(otherInput.value);
      if (inputNumber == i) {
        break;
      }
      if (inputNumber == i || isNaN(otherValue)) {
        continue;
      }
      const InputError = document.getElementById(
        "inputPaidDeliveryKmsError" + inputNumber
      );
      if (otherValue > currentVal) {
        if (inputNumber == 1) {
          InputError.innerHTML = "";
          InputError.value = 0;
        }
      } else {
        console.error("Input Is Not Valid");
        InputError.value = 1;
        InputError.innerHTML = "Km should be lesser than " + otherValue;
      }
    }

    for (var i = 1; i <= this.inputPaidDeliveryKms.length; i++) {
      console.log(this.errorPaidDeliveryRs[i - 1].value);
    }
  };

  validateInputPaidRs = (e) => {
    var inputNumber = e.target.id.charAt(e.target.id.length - 1);
    var otherInput = "";
    const kmRsInput = document.getElementById(
      "inputPaidDeliveryRs" + inputNumber
    );
    var currentVal = parseInt(kmRsInput.value);
    console.log(e.target.value);
    console.log(currentVal);
    console.log(e.target.value == "");

    for (var i = 1; i <= this.inputPaidDeliveryRs.length; i++) {
      otherInput = document.getElementById("inputPaidDeliveryRs" + i);
      var otherValue = parseInt(otherInput.value);

      if (inputNumber == i) {
        console.log("Break");
        break;
      }

      if (inputNumber == i || isNaN(otherValue)) {
        console.log("Continue");
        continue;
      }
      const InputError = document.getElementById(
        "inputPaidDeliveryRsError" + inputNumber
      );
      if (otherValue < currentVal) {
        console.log("Input Is Valid");
        InputError.innerHTML = "";
        InputError.value = 0;
      } else {
        console.error("Input Is Not Valid");
        InputError.value = 1;
        InputError.innerHTML = "Km should be greater than " + otherValue;
      }
    }

    for (var i = this.inputPaidDeliveryRs.length; i > 0; i--) {
      otherInput = document.getElementById("inputPaidDeliveryRs" + i);
      var otherValue = parseInt(otherInput.value);
      if (inputNumber == i) {
        break;
      }
      if (inputNumber == i || isNaN(otherValue)) {
        continue;
      }
      const InputError = document.getElementById(
        "inputPaidDeliveryRsError" + inputNumber
      );
      if (otherValue > currentVal) {
        if (inputNumber == 1) {
          InputError.innerHTML = "";
          InputError.value = 0;
        }
      } else {
        console.error("Input Is Not Valid");
        InputError.value = 1;
        InputError.innerHTML = "Km should be lesser than " + otherValue;
      }
    }

    for (var i = 1; i <= this.inputPaidDeliveryRs.length; i++) {
      console.log(this.errorPaidDeliveryRs[i - 1].value);
    }
  };

  checkInputPaidArrays = () => {
    //To Create Paid Delivery Km Array
    var PaidKmArray = [];
    for (let i = 0; i < this.inputPaidDeliveryKms.length; i++) {
      var elementKm = this.inputPaidDeliveryKms[i].value;
      PaidKmArray.push(elementKm);
    }
    console.log(PaidKmArray);
    PaidKmArray = this.removeEmptyArrayEle(PaidKmArray);

    //To Create Paid Delivery Rs Array
    var PaidRsArray = [];
    for (let i = 0; i < this.inputPaidDeliveryRs.length; i++) {
      var elementRs = this.inputPaidDeliveryRs[i].value;
      PaidRsArray.push(elementRs);
    }
    console.log(PaidRsArray);
    PaidRsArray = this.removeEmptyArrayEle(PaidRsArray);

    //To fix Error if Any of the array in empty
    var PaidKmArrayLength = 0;
    var PaidRsArrayLength = 0;
    if (PaidKmArray) {
      console.log("NO LENGTH");
      PaidKmArrayLength = PaidKmArray.length;
    }
    if (PaidRsArray) {
      console.log("NO LENGTH");
      PaidRsArrayLength = PaidRsArray.length;
    }

    //To get Number of paid delivery input used
    var ArrLength =
      PaidKmArrayLength > PaidRsArrayLength
        ? PaidKmArrayLength
        : PaidRsArrayLength;
    console.log("ArrLength " + ArrLength);

    var counter = 0;
    var km = "";
    var kmNext = "";
    var kmError = "";
    var kmNextError = "";
    var Rs = "";
    var RsNext = "";
    var RsError = "";
    var RsNextError = "";
    for (let i = 0; i < this.inputPaidDeliveryKms.length; i++) {
      km = parseInt(this.inputPaidDeliveryKms[i].value);
      // kmNext = this.inputPaidDeliveryKms[i + 1].value;
      kmError = this.errorPaidDeliveryKms[i];
      // kmNextError = this.errorPaidDeliveryKms[i + 1];
      Rs = parseInt(this.inputPaidDeliveryRs[i].value);
      // RsNext = this.inputPaidDeliveryRs[i + 1].value;
      RsError = this.errorPaidDeliveryRs[i];
      // RsNextError = this.errorPaidDeliveryRs[i + 1];

      //For first paid Deliery input
      if (i == 0) {
        console.log(km + "  & " + Rs);
        if (!(km > 0)) {
          console.log("Empty");
          kmError.value = 1;
          kmError.innerHTML = "Enter value greater than 0";
        } else {
          kmError.value = 0;
          kmError.innerHTML = "";
        }
        if (!(Rs > 0)) {
          console.log("Empty");

          RsError.value = 1;
          RsError.innerHTML = "Enter value greater than 0";
        } else {
          RsError.value = 0;
          RsError.innerHTML = "";
        }
        var freeKm = parseInt(this.inputFreeDeliveryKms.val());
        console.log("inputFreeDeliveryKms");
        console.log("km: " + km + "inputFreeDeliveryKms: " + freeKm);
        if (km <= freeKm) {
          console.log("Empty");
          kmError.value = 1;
          kmError.innerHTML =
            "Enter value greater than Free Delivery Km " + freeKm;
        }
        if (ArrLength == 0) {
          kmError.value = 0;
          kmError.innerHTML = "";
          RsError.value = 0;
          RsError.innerHTML = "";
        }
      }
      // For remaining Used paid Delivery inputs
      else if (i > 0 && i < ArrLength) {
        console.log("Setting Pre Val");
        var kmPrev =
          this.inputPaidDeliveryKms[i - 1].value == ""
            ? 0
            : parseInt(this.inputPaidDeliveryKms[i - 1].value);
        var RsPrev =
          this.inputPaidDeliveryRs[i - 1].value == ""
            ? 0
            : parseInt(this.inputPaidDeliveryRs[i - 1].value);
        console.log("Done Setting Pre Val");
        if (!(km > 0) && !(Rs > 0)) {
          console.log("Empty");
          kmError.value = 1;
          kmError.innerHTML = "Enter value greater than " + kmPrev;
          RsError.value = 1;
          RsError.innerHTML = "Enter value greater than " + RsPrev;
        }
        if (Rs <= RsPrev || isNaN(Rs)) {
          console.log("Empty Rs");
          RsError.value = 1;
          RsError.innerHTML = "Enter value greater than " + RsPrev;
        } else if (Rs > RsPrev) {
          RsError.value = 0;
          RsError.innerHTML = "";
        }
        if (km <= kmPrev || isNaN(km)) {
          console.log("Empty km");
          kmError.value = 1;
          kmError.innerHTML = "Enter value greater than " + kmPrev;
        } else if (km > kmPrev) {
          kmError.value = 0;
          kmError.innerHTML = "";
        }
        if (km > 0 && Rs > 0 && km > kmPrev && Rs > RsPrev) {
          kmError.value = 0;
          kmError.innerHTML = "";
          RsError.value = 0;
          RsError.innerHTML = "";
          counter++;
        }
      }
      //To clear Unused Paid delivery inputs
      else if (i >= ArrLength) {
        console.log("CLEARING ERRORS");

        console.log("Empty");
        kmError.value = 0;
        kmError.innerHTML = "";
        RsError.value = 0;
        RsError.innerHTML = "";
      }
    }
  };

  removeEmptyArrayEle = (myArray) => {
    // let myArray = [1, 2, 3, 4, 5];
    console.log(myArray.length);
    for (let i = myArray.length - 1; i >= 0; i--) {
      if (myArray[i] == "") {
        myArray.splice(i, 1); // removes 1 element starting from index i
      } else if (myArray[i] != "") {
        console.log("After Deleting");
        console.log(myArray);
        return myArray;
      }
    }
    console.log(myArray);
  };

  checkInputPaidArraysValidation = () => {
    for (var i = 0; i < this.inputPaidDeliveryKms.length; i++) {
      var isKmsError = this.errorPaidDeliveryKms[i].value;
      console.log(isKmsError);
      if (isKmsError == 1) {
        return false;
      }
      var isRsError = this.errorPaidDeliveryRs[i].value;
      console.log(isRsError);
      if (isRsError == 1) {
        return false;
      }
    }
    return true;
  };
}
