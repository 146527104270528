export default class vendorPaymentsPage {
  constructor() {
    console.log("vendor payments page");
    $(".vendorPaymentPageSubmit").on("click", this.makeCashfreePayment);
    $(".vendorPaymentCheck").on("click", this.checkCashfreePayment);
  }

  makeCashfreePayment = () => {
    console.log("making payment");

    const dropConfig = {
      components: ["order-details", "card", "netbanking", "app", "upi"],
      onSuccess: this.cashfreePaymentSuccess,
      onFailure: this.cashfreePaymentFailure,
    };

    $.ajax({
      url: baseURL + "api/CashfreePayments/createOrder",
      type: "POST",
      async: false,
      data: {
        Vendor_ID: "67ccb451-4497-11e9-b548-778fd1e3dff0",
        Amount: "100",
        Payment_Type: "Sponsored Payment",
      },
      success: (result) => {
        result = JSON.parse(result);
        console.log(result);
        const paymentSessionId = result["payment_session_id"];
        const cf = new Cashfree(paymentSessionId, dropConfig);
        cf.redirect();
      },
      error: (result) => {
        result = JSON.parse(result);
        console.log(result);
      },
    });
  };

  cashfreePaymentSuccess = () => {
    console.log("payment success");
  };

  cashfreePaymentFailure = () => {
    console.log("payment failure");
  };

  checkCashfreePayment = () => {
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    var payment_order_id = urlParams.get("payment_order_id");
    console.log(payment_order_id);

    $.ajax({
      url: baseURL + "api/CashfreePayments/getOrder",
      type: "POST",
      async: false,
      data: {
        Payment_ID: payment_order_id,
      },
      success: (result) => {
        result = JSON.parse(result);
        console.log(result);
      },
      error: (result) => {
        result = JSON.parse(result);
        console.log(result);
      },
    });
  };
}
