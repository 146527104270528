export default class vendor_servicing_pricing {
  constructor() {
    this.BronzeDisplayPrice = $("#BronzeDisplayPrice");
    this.SilverDisplayPrice = $("#SilverDisplayPrice");

    this.GoldDisplayPrice = $("#GoldDisplayPrice");

    //   this.addRs = $("#addRs");
    //   this.addMoneyButton = $("#addMoneyButton");
    //   this.AddRsError = $("#AddRsError");

    this.bindEvents();
  }

  bindEvents = () => {
    console.log("in vendor_servicing_pricing");

    this.getActiveSubscription();
    this.getSubscriptionPricing();
    //   this.addMoneyButton.on("click", this.validateInputMoney);

    //New Functions for cashFree
    $(".SponsoredPay").on("click", this.makeCashfreePayment);

    //To check if a subscription is Expiring soon and send an Email to vendor
    this.checkIfSubscriptionExpiring();
  };

  getActiveSubscription = () => {
    console.log("in getActiveSubscription");

    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/GetActiveSubscription",

      headers: { Authorization: X_API_KEY },
      success: this.showActiveSubscription,
    });
  };

  showActiveSubscription = (res) => {
    console.log("SUBSCRIPTION");
    console.log(res);
    if (res != "false") {
      // res = res[0];
      var sponsorRow = ``;
      res.forEach((element) => {
        sponsorRow = `${sponsorRow}<div class="col-4 text-center"><label>Duration:<span class="subDuration">${element.Promotion_Duration}</span> months</label></div>
                          <div class="col-4 text-center"><span class="subStartDate">${element.Start_date}</span></div>
                          <div class="col-4 text-center"><span class="subEndDate">${element.End_date}</span></div>`;
      });
      console.log("inside if");
      var ActiveSubBox = document.getElementsByClassName("ActiveSubBox")[0];
      var item = `<div class="row">
    <div class="col-12">
        <h5>Current Subscriptions</h5>
    </div>
    <div class="col-4 text-center">Duration:</div>
    <div class="col-4 text-center">Start Date:</div>
    <div class="col-4 text-center">Expiry Date:</div>
    ${sponsorRow}
    </div>`;

      ActiveSubBox.innerHTML = item;
    } else {
      return;
    }
  };

  getSubscriptionPricing = () => {
    console.log("in getSubscriptionPricing");

    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/GetSubscriptionPricing",

      headers: { Authorization: X_API_KEY },
      success: this.showSubscriptionPricing,
    });
  };

  showSubscriptionPricing = (res) => {
    console.log("inside showSubscriptionPricing");

    this.BronzeDisplayPrice.html(res[0].Picklist_display_value);
    this.SilverDisplayPrice.html(res[1].Picklist_display_value);
    this.GoldDisplayPrice.html(res[2].Picklist_display_value);

    var payBtns = document.getElementsByClassName("SponsoredPay");
    for (var i = 0; i < 3; i++) {
      payBtns[i].value = res[i].extra_value;
      payBtns[i].name = res[i].Picklist_ID;
    }
    console.log(res);
  };

  makeCashfreePayment = (e) => {
    console.log("making payment");
    var PaymentAmount = e.target.value;
    var Picklist_ID = e.target.name;
    console.log(PaymentAmount);

    const dropConfig = {
      components: ["order-details", "card", "netbanking", "app", "upi"],
      onSuccess: this.cashfreePaymentSuccess,
      onFailure: this.cashfreePaymentFailure,
    };

    console.log();
    $.ajax({
      url: baseURL + "api/CashfreePayments/createOrder",
      type: "POST",
      async: false,
      data: {
        Vendor_ID: "null",
        Amount: PaymentAmount,
        Payment_Type: "Sponsored Payment",
        Picklist_ID: Picklist_ID,
      },
      success: (result) => {
        result = JSON.parse(result);
        console.log(result);
        const paymentSessionId = result["payment_session_id"];
        const cf = new Cashfree(paymentSessionId, dropConfig);
        cf.redirect();
      },
      error: (result) => {
        result = JSON.parse(result);
        console.log(result);
      },
    });
  };

  cashfreePaymentSuccess = () => {
    console.log("payment success");
  };

  cashfreePaymentFailure = () => {
    console.log("payment failure");
  };

  checkIfSubscriptionExpiring = (res) => {
    $.ajax({
      type: "POST",
      url: baseURL + "api/AmazonSesSmtp/sendVendorSubscriptionExpiringEmail",

      headers: { Authorization: X_API_KEY },
      success: (result) => {
        // result = JSON.parse(result);
        console.log(result);
      },
    });
  }
}
