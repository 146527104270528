import "@dmuy/timepicker/dist/mdtimepicker.css";
import mdtimepicker from "@dmuy/timepicker";
var availability_data = [];
export default class vendor_calender {
  constructor() {
    this.continueBtn = $(".vendorCalenderChangesBtn");
    this.bindEvents();
  }

  bindEvents = () => {
    console.log("in Vendor calender js");
    $.ajax({
      type: "GET",

      url: baseURL + "api/Vendor/GetAvailabilities",
      headers: { Authorization: X_API_KEY },

      success: this.showOperatingHours,
    });
    // mdtimepicker(".timepicker", { format: "hh:mm", theme: "orange" });
    this.continueBtn.on("click", this.fillAvailabilityData);
  };

  // To Display Operating Hours of a Vendor
  showOperatingHours = (res) => {
    $(".CurrentOperatingHours").empty();
    mdtimepicker("#time", { format: "hh:mm", theme: "orange" });
    res.forEach((element) => {
      var operatingHours = `
      
      <div class="col">
      <label for="${element.Day.toLowerCase()}-start-input" class="form-label">&ensp;&nbsp;${
        element.Day
      }</label>
  </div>
  <div class="col text-right">
      <label for="inputBusinessName" class="form-label ml-4">Closed</label>
  </div>
  <!-- </div> -->
  <div class="d-flex bd-highlight">
      <div class="p-2  me-lg-3 flex-grow-1 bd-highlight">
      <div class="p-2  me-3 ">
          <input type="text" class="form-control timepicker" id="${element.Day.toLowerCase()}-start" value="${
        element.Start_time
      }" name="" aria-describedby="contactPersonHelp" placeholder="">
          <!-- <div id="${element.Day.toLowerCase()}-start-error"></div> -->
  
          </div>
      </div>
      <div class="p-2  me-lg-3 flex-grow-1 bd-highlight">
      <div class="p-2  me-3">

          <input type="text" class="form-control timepicker" id="${element.Day.toLowerCase()}-end" value="${
        element.End_time
      }" name="" aria-describedby="contactPersonHelp" placeholder="">
          <div id="${element.Day.toLowerCase()}-end-error" class='text-danger'></div>
      </div>
      </div>

      <div class="p-2 pl-4 pt-3 mt-1 flex-grow-0 bd-highlight d-flex justify-content-end ">
          <input ${
            element.Available == true ? "" : "checked"
          }  class="form-check-input align-content-center checkbox-size  " type="checkbox" value="" id="${element.Day.toLowerCase()}-closed-check">
      </div>
  </div>
  <span class='text-danger' id="inputAddressError"></span>
  
                              `;
      $(".CurrentOperatingHours").append(operatingHours);
      mdtimepicker(".timepicker", { format: "hh:mm", theme: "orange" });
    });
  };

  fillAvailabilityData = () => {
    this.monStart = $("#monday-start");
    this.monEnd = $("#monday-end");
    this.monEndError = $("#monday-end-error");
    this.monClosedCheck = $("#monday-closed-check");

    this.tuesStart = $("#tuesday-start");
    this.tuesEnd = $("#tuesday-end");
    this.tuesEndError = $("#tuesday-error");
    this.tusClosedCheck = $("#tuesday-closed-check");

    this.wedStart = $("#wednesday-start");
    this.wedEnd = $("#wednesday-end");
    this.wedEndError = $("#wednesday-error");
    this.wedClosedCheck = $("#wednesday-closed-check");

    this.thursStart = $("#thursday-start");
    this.thursEnd = $("#thursday-end");
    this.thursEndError = $("#thursday-error");
    this.thuClosedCheck = $("#thursday-closed-check");

    this.friStart = $("#friday-start");
    this.friEnd = $("#friday-end");
    this.friEndError = $("#friday-error");
    this.friClosedCheck = $("#friday-closed-check");

    this.satStart = $("#saturday-start");
    this.satEnd = $("#saturday-end");
    this.satEndError = $("#saturday-error");
    this.satClosedCheck = $("#saturday-closed-check");

    this.sunStart = $("#sunday-start");
    this.sunEnd = $("#sunday-end");
    this.sunEndError = $("#sunday-error");
    this.sunClosedCheck = $("#sunday-closed-check");
    var availability_day1 = {
      Day: "Monday",
      Start_time: this.monStart.val(),
      End_time: this.monEnd.val(),
      Available: this.monClosedCheck.is(":checked") ? "0" : "1",
    };
    var availability_day2 = {
      Day: "Tuesday",
      Start_time: this.tuesStart.val(),
      End_time: this.tuesEnd.val(),
      Available: this.tusClosedCheck.is(":checked") ? "0" : "1",
    };
    var availability_day3 = {
      Day: "Wednesday",
      Start_time: this.wedStart.val(),
      End_time: this.wedEnd.val(),
      Available: this.wedClosedCheck.is(":checked") ? "0" : "1",
    };
    var availability_day4 = {
      Day: "Thursday",
      Start_time: this.thursStart.val(),
      End_time: this.thursEnd.val(),
      Available: this.thuClosedCheck.is(":checked") ? "0" : "1",
    };
    var availability_day5 = {
      Day: "Friday",
      Start_time: this.friStart.val(),
      End_time: this.friEnd.val(),
      Available: this.friClosedCheck.is(":checked") ? "0" : "1",
    };
    var availability_day6 = {
      Day: "Saturday",
      Start_time: this.satStart.val(),
      End_time: this.satEnd.val(),
      Available: this.satClosedCheck.is(":checked") ? "0" : "1",
    };
    var availability_day7 = {
      Day: "Sunday",
      Start_time: this.sunStart.val(),
      End_time: this.sunEnd.val(),
      Available: this.sunClosedCheck.is(":checked") ? "0" : "1",
    };

    availability_data.push(availability_day1);
    availability_data.push(availability_day2);
    availability_data.push(availability_day3);
    availability_data.push(availability_day4);
    availability_data.push(availability_day5);
    availability_data.push(availability_day6);
    availability_data.push(availability_day7);

    console.log("Making ajax call to AddUpdateAvailabilities");
    console.log(availability_data);
    $.ajax({
      type: "POST",

      url: baseURL + "api/Vendor/AddUpdateAvailabilities",
      headers: { Authorization: X_API_KEY },

      data: {
        availability_data: availability_data,
      },

      success: function (res) {
        console.log(res);
      },
    });
  };
}
