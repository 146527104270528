export default class vendorServicing_billing {
  constructor() {
    // this.vendor_billing_desktop = $("#vendor_billing_desktop");
    // this.vendor_billing_mobile = $("#vendor_billing_mobile");
    this.viewDetailsButton = $(".viewDetailsButton");

    this.noBills = $(".noBills");
    this.vendor_billing_desktopView = $(".vendor_billing_desktopView");
    this.vendor_billing_mobile = $(".vendor_billing_mobile");
    this.vendor_billing_tablet = $(".vendor_billing_tablet");


    this.bindEvents();
  }

  bindEvents = () => {
    //widthwise show and d-none for Past orders
    // for desktop - bigger and mobile and tab view - smaller
    var vendor_billing_screen = $(window).width();
    console.log(vendor_billing_screen);

    if (vendor_billing_screen < 821) {
      console.log("inside if");

      //   $(".vendor_billing_desktop").addClass("d-none");
      //   $(".vendor_billing_mobile").removeClass("d-none");
      // } else {
      //   console.log("inside else");

      //   $(".vendor_billing_mobile").addClass("d-none");
      //   $(".vendor_billing_desktop").removeClass("d-none");
    }

    this.LoadBilling(0);
  };

  LoadBilling = (offsetCount) => {
    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/GetBillList",
      headers: { Authorization: X_API_KEY },
      data: {
        Offset: offsetCount,
      },
      success: this.showBillList,
    });
  };

  showBillList = (res) => {
    console.log("inside showBillList");
    console.log(res);
    console.log(res.length);
    if (res.length == 0) {
      this.vendor_billing_desktopView.addClass("d-none");
      this.vendor_billing_mobile.addClass("d-none");
      this.vendor_billing_tablet.addClass("d-none");
      this.noBills.removeClass("d-none");
    } else {

      this.vendor_billing_desktopView.removeClass("d-none");
      this.vendor_billing_mobile.removeClass("d-none");
      this.vendor_billing_tablet.removeClass("d-none");
      this.noBills.addClass("d-none");
      
      res.forEach((element) => {
        var item = `<tr>
      <td scope="row">${element.Date_Time}</td>
      <td>${element.logical_id}</td>
      <td>${element.Final_Payment_Amount}</td>
      <td>${element.Vendor_Name}</td>
      <td>${element.Payment_Status}</td>
      
      <td>
          <button type="button" class="btn btn-outline-warning btn-sm viewDetailsButton" value="${element.Order_ID}">View Details</button>
      </td>

  </tr>
      
      `;
        $("#billTableBodyDesktop").append(item);
      });

      res.forEach((element) => {
        var item1 = `<div class="card mb-2 border-bottom mb-4" style="font-size:12px;">
      <div class="card-body">
          <div class="">

              <div class="row">
                  <div class="col">
                      <b>Date</b>
                  </div>
                  <div class="col">
                  ${element.Date_Time}
                  </div>
              </div>

              <div class="row">
                  <div class="col">
                      <b>Order Number</b>

                  </div>
                  <div class="col">
                  ${element.logical_id}
                  </div>
              </div>

              <div class="row">
                  <div class="col">
                      <b>Order Value</b>

                  </div>
                  <div class="col">
                  ${element.Final_Payment_Amount}
                  </div>
              </div>

              <div class="row">
                  <div class="col">
                      <b>Vendor Name</b>

                  </div>
                  <div class="col">
                  ${element.Vendor_Name}
                  </div>
              </div>

              <div class="row">
                  <div class="col">
                      <b>Status</b>

                  </div>
                  <div class="col">
                  ${element.Payment_Status}
                  </div>
              </div>


              <div class="row mt-4">
                 
                  <div class="col">
                      <button type="button" class="btn btn-outline-warning btn-sm viewDetailsButton" style="" value="${element.Order_ID}">View Details</button>
                  </div>
              </div>

          </div>
      </div>
  </div>
      
      `;
        $("#cardBodyMobile").append(item1);
      });

      res.forEach((element) => {
        var item2 = ` <div class="card mb-2 border-bottom mb-4" style="font-size:12px;">
      <div class="card-body">
          

          <div class="row">
              <div class="col">
                  <div class="row">
                      <div class="col"><b>Date</b></div>
                      <div class="col">${element.Date_Time}</div>
                  </div>
                  <div class="row">
                      <div class="col"><b>Order Number</b></div>
                      <div class="col">${element.logical_id}</div>
                  </div>
                  <div class="row">
                      <div class="col"><b>Order Value</b></div>
                      <div class="col">${element.Final_Payment_Amount}</div>
                  </div>
                  <div class="row">
                      <div class="col"><b>Vendor Name</b></div>
                      <div class="col">${element.Vendor_Name}</div>
                  </div>
                  <div class="row">
                      <div class="col"><b>Status</b></div>
                      <div class="col">${element.Payment_Status}</div>
                  </div>
              </div>
              <div class="col">
                  <div class="row">
                      
                  </div>
                  <div class="row">
                      <div class="col">
                          &nbsp;
                          &nbsp;
                      </div>
                  </div>
                  <div class="row">
                      <div class="col" align="center">
                          <button type="button" class="btn btn-outline-warning btn-sm viewDetailsButton" value="${element.Order_ID}">View Details &nbsp;</button>
                      </div>
                  </div>
              </div>
          </div>



          </div>
         </div>
      
      `;
        $("#cardBodyTablet").append(item2);
      });
      $(".viewDetailsButton").on("click", this.viewOrderDetails);
    }
  };

  viewOrderDetails = (e) => {
    console.log(e.target);
    console.log(e);
    console.log(e.target.value);

    e.preventDefault();
    console.log("inside vieworderdet");
    window.$("#viewDetailsModalForServicingOrdersPage").modal("show");

    var Order_ID = e.target.value;
    $.ajax({
      type: "POST",
      url: baseURL + "api/vendor/setVendorOredrID",
      headers: { Authorization: X_API_KEY },
      data: {
        Order_ID: Order_ID,
      },
      success: (res) => {
        console.log(res);
        $.ajax({
          type: "GET",
          url: baseURL + "api/vendor/order",
          headers: { Authorization: X_API_KEY },
          data: {
            Order_ID: e.target.value,
            // Order_ID: "916384bb-42be-46a3-836c-0624b4e49091",

            // Offset: offsetCount,
          },
          // success: function (res) {
          //   console.log(res);
          // },
          success: this.showBillingOrderDetails,
        });
      },
    });
  };

  showBillingOrderDetails = (res) => {
    console.log(res);
    console.log(res.Order.Order_Amount);
    document.getElementsByClassName(
      "order-details-itemsTotal-vendor"
    )[0].innerHTML = res.Order.Order_Amount;
    document.getElementsByClassName(
      "order-details-gstAmount-vendor"
    )[0].innerHTML = res.Order.GST;
    // document.getElementsByClassName("order-details-offersDiscount-vendor")[0].innerHTML = res.Order.Order_Amount;
    document.getElementsByClassName(
      "order-details-packagingCharges-vendor"
    )[0].innerHTML = res.Order.Packaging_charges;
    document.getElementsByClassName(
      "order-details-deliveryCharges-vendor"
    )[0].innerHTML = res.Order.Delivery_Charges;
    document.getElementsByClassName(
      "order-details-totalPrice-vendor"
    )[0].innerHTML = res.Order.Final_Bill_Amount;
  };
}
