var deviceType;

import s3Util from "../helpers/s3Util";
var s3Object = new s3Util();

//Loading and using Google maps helper functions
import GoogleMapsApi from "../helpers/GoogleMapsApi";
var GoogleMapsApiObjectVendor = new GoogleMapsApi();
export default class CustomerSearch {
  constructor() {
    //when typing text disappear
    this.onTypeText = $(".onTypeText");
    // Registering Events
    this.bindEvents();
  }

  bindEvents = () => {
    console.log("in bind");
    //call recent search function
    $(".searchBarBtn").on("click", this.SearchOptions);
    $(".searchBarBtnMain").on("click", this.SearchResults);

    //for desktop
    $(".textareaDesktop").on("input", () => {
      if (!$(".textareaDesktop").val()) {
        $(".recentSearchesDesktop").show();
      } else {
        $(".recentSearchesDesktop").hide();
      }
    });
    //for mobile
    $(".textareaMobile").on("input", () => {
      if (!$(".textareaMobile").val()) {
        $(".recentSearchesMobile").show();
      } else {
        $(".recentSearchesMobile").hide();
      }
    });

    //search on enter
    $(".searchBarBtn").keyup((event) => {
      if (event.which === 13) {
        this.SearchResults();
      }
    });
  };

  SearchOptions = () => {
    var inputDesktop = $(".searchBar").val();
    var inputMobile = $(".searchBarMobile").val();

    if (inputDesktop == "") {
      var input = inputMobile;
    } else {
      var input = inputDesktop;
    }
    console.log(input);

    $.ajax({
      url: baseURL + "api/customer/Search/SearchOptions",
      type: "GET",
      async: false,
      headers: { Authorization: X_API_KEY },
      success: (result) => {
        console.log(result);
        $(".recentSearchesDesktop").empty();
        $(".recentSearchesMobile").empty();

        //Putiing the Recent searches header manually
        $(".recentSearchesDesktop").append(
          `<p class="dropdown-header ">Recent searches</p>`
        );
        $(".recentSearchesMobile").append(
          `<p class="dropdown-header ">Recent searches</p>`
        );

        result.forEach((element) => {
          var item = `<a class="dropdown-item recentSearchesItems">${element.Search_Name}</a>`;
          $(".recentSearchesDesktop").append(item);
          $(".recentSearchesMobile").append(item);
        });
        $(".recentSearchesItems").on(
          "click",
          this.enterSearchValueInsideSearchBox
        );
      },
      error: (result) => {
        console.log(result);
      }
    });

    // var customerId = prompt("Add Customer ID");
    // document.cookie = `CUSTOMER_ID = ${customerId}`;
    // sessionStorage.setItem("CUSTOMER_ID", customerId);
  };

  enterSearchValueInsideSearchBox = (recentSearchItem) => {
    var recentSearchItem = recentSearchItem.target.text;
    $(".searchBar").val(recentSearchItem);
    $(".searchBarMobile").val(recentSearchItem);
  };

  SearchResults = () => {
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    var inputDesktop = $(".searchBar").val();
    var inputMobile = $(".searchBarMobile").val();

    console.log($(".textareaMobile"));
    console.log(inputDesktop);
    console.log(inputMobile);
    console.log(width);
    if (width <= 989) {
      var input = inputMobile;
      deviceType = 0; //for mobile
    } else {
      var input = inputDesktop;
      deviceType = 1; //desktop
    }
    console.log(input);
    console.log(deviceType);

    // var customerId = prompt("Add Customer ID");
    // document.cookie = `CUSTOMER_ID = ${customerId}`;
    // sessionStorage.setItem("CUSTOMER_ID", customerId);
    if (input == "") {
      $("#customerSearchError").html("please enter search name");
      console.log("search is empty");
    } else {
      $.ajax({
        url:
          baseURL +
          "api/customer/Search/SearchVendorOrProductWithMultipleCategories",
        type: "POST",
        async: false,
        data: {
          Search_Name: input
        },
        headers: { Authorization: X_API_KEY },
        success: this.showSearchResults,
        error: (result) => {
          console.log(result);
        }
      });
    }
  };

  showSearchResults = (result) => {
    console.log(result);
    var vendors = result.Vendors;
    var products = result.Products;

    //Vendors
    if (vendors.length == 0) {
      console.log("Vendors are Empty");

      $("div.vendorSearchResults").empty();
      $("div.vendorSearchResultsMobile").empty();
      $(".no-search-sellers").removeClass("d-none");
    } else {
      console.log("Vendors are not empty");
      //Removes the Previous Results
      $("div.vendorSearchResults").empty();
      $("div.vendorSearchResultsMobile").empty();
      $(".no-search-sellers").addClass("d-none");

      vendors.forEach((vendor) => {
        var sponsoredContent = "";
        var color = "";
        if (vendor.Vendor_Rating > 0) {
          color = "background: rgba(244, 148, 49);";
        }
        if (vendor.isSponsored > 0) {
          sponsoredContent = `<p class="carousel-caption text-white shadow rounded p-0 m-0" style="background: rgba(244, 148, 49);">Sponsored</p>`;
        }
        var vendorCategories = vendor.Categories;
        vendorCategories.forEach((category) => {
          var catPicklistIdLength = $(
            `.${category.Picklist_ID}-category`
          ).length;
          if (catPicklistIdLength > 0) {
            if (deviceType == 1) {
              var vendorSearchDetailsDesktop = `<a href="${baseURL}customer/productListing/${vendor.Vendor_URL}" class="list-group-item list-group-item-action list-group-item-light truncate text-capitalize ${vendor.Vendor_ID}">
            <div class="row d-flex">
            <div class="col-3">
            <img src=""  class="rounded-4 float-start ${vendor.Vendor_ID}-searchVendImg" alt="${vendor.Name}" width="100%" height="90%">
            ${sponsoredContent}</div>
            <div class="col">
            <div class="row ">
                      <div class="col d-flex justify-content-start">
                      <p class="truncate-one text-capitalize mb-0"><strong>${vendor.Name}</strong></p>
                      </div>
                      
                    </div>
  
                    <div class="row ">
                      <div class="col-12 d-flex justify-content-start">
                    <p class="truncate-one text-capitalize mb-0"><strong>${vendor.Address.City}</strong></p>
                    </div></div>
  
                    <div class="row ">
                      <div class="col-12 d-flex justify-content-start">
                      <p class="truncate-one text-capitalize mb-0">${category.Speciality}</p>
                      </div>
                      <div class="col pr-0 d-flex justify-content-start">
                      <p class="mb-0 pt-2">Min Order:₹${vendor.Minimum_Order}</p>
                      </div>
                      <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                        <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${vendor.Vendor_Rating} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                      </div>
                      
                    </div> 
            </div>
            </div> 
               </a>`;
              $(
                `div.vendorSearchResults div.${category.Picklist_ID}-category`
              ).append(vendorSearchDetailsDesktop);
            } else {
              var vendorSearchDetailsMobile = `<a href="${baseURL}customer/productListing/${vendor.Vendor_URL}" class="list-group-item list-group-item-action list-group-item-light truncate text-capitalize ${vendor.Vendor_ID}">
              <div class="row d-flex">
                 <div class="col-4">
                    <img src="" class="rounded-4 float-start ${vendor.Vendor_ID}-searchVendImg" alt="${vendor.Name}" width="100%" height="90%">
                    ${sponsoredContent}
                 </div>
                 <div class="col">
                    <div class="row ">
                       <div class="col d-flex justify-content-start">
                          <p class="truncate-one text-capitalize mb-0">${vendor.Name}</p>
                       </div>
                    </div>
                    <div class="row ">
                       <div class="col-12 d-flex justify-content-start">
                          <p class="truncate-one text-capitalize mb-0"><strong>${vendor.Address.City}</strong></p>
                       </div>
                    </div>
                    <div class="row ">
                       <div class="col-12 d-flex justify-content-start">
                          <p class="truncate-one text-capitalize mb-0">${category.Speciality}</p>
                       </div>
                       <div class="col-8 pr-0">
                          <p class="mb-0 pt-1">Min Order:₹${vendor.Minimum_Order}</p>
                       </div>
                       <div class="col mb-2 pl-0 d-flex justify-content-end">
                          <p class="ratingBorder text-white mb-0 pl-1 ml-1" style="${color}">${vendor.Vendor_Rating} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                       </div>
                    </div>
                 </div>
              </div>
           </a>`;
              $(
                `div.vendorSearchResultsMobile div.${category.Picklist_ID}-category`
              ).append(vendorSearchDetailsMobile);
            }
          } else {
            var vendorCategoryParentDiv = `<div class="list-group mb-3 ${category.Picklist_ID}-category">
          <h4>
              <p class="truncate text-capitalize">${category.Picklist_display_value}</p>
          </h4>
      </div>`;

            if (deviceType == 1) {
              $("div.vendorSearchResults").append(vendorCategoryParentDiv);
              var vendorSearchDetailsDesktop = `<a href="${baseURL}customer/productListing/${vendor.Vendor_URL}" class="list-group-item list-group-item-action list-group-item-light truncate text-capitalize ${vendor.Vendor_ID}">
            <div class="row d-flex">
            <div class="col-3">
            <img src=""  class="rounded-4 float-start ${vendor.Vendor_ID}-searchVendImg" alt="${vendor.Name}" width="100%" height="90%">
            ${sponsoredContent}</div>
            <div class="col">
  <div class="row ">
                      <div class="col-11 d-flex justify-content-start">
                      <p class="truncate-one text-capitalize mb-0"><strong>${vendor.Name}</strong></p>
                      </div>

                      <div class="col-1 p-0">
                        <button type="button" class="mapMarkerBtnClass-${vendor.Vendor_ID} btn p-0 m-0 h-100 w-100 " data-vendorid="${vendor.Vendor_ID}">
                          <i class="fas fa-map-marker-alt ml-1"></i>
                        </button>
                      </div>
                      
                    </div>
  
                    <div class="row ">
                      <div class="col-12 d-flex justify-content-start">
                    <p class="truncate-one text-capitalize mb-0"><strong>${vendor.Address.City}</strong></p>
                    </div></div>
  
                    <div class="row ">
                      <div class="col-12 d-flex justify-content-start">
                      <p class="truncate-one text-capitalize mb-0">${category.Speciality}</p>
                      </div>
                      <div class="col pr-0 d-flex justify-content-start">
                      <p class="mb-0 pt-2">Min Order:₹${vendor.Minimum_Order}</p>
                      </div>
                      <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                        <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${vendor.Vendor_Rating} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                      </div>
                      
                    </div> 
            </div>
            </div>                
  </a>`;
              $(
                `div.vendorSearchResults div.${category.Picklist_ID}-category`
              ).append(vendorSearchDetailsDesktop);
              $(`.mapMarkerBtnClass-${vendor.Vendor_ID}`).on("click", (e) => {
                GoogleMapsApiObjectVendor.openGMapModal(e);
              });
            } else {
              $("div.vendorSearchResultsMobile").append(
                vendorCategoryParentDiv
              );
              var vendorSearchDetailsMobile = `<a href="${baseURL}customer/productListing/${vendor.Vendor_URL}" class="list-group-item list-group-item-action list-group-item-light truncate text-capitalize ${vendor.Vendor_ID}">
              <div class="row d-flex">
                 <div class="col-4">
                    <img src="" class="rounded-4 float-start ${vendor.Vendor_ID}-searchVendImg" alt="${vendor.Name}" width="100%" height="90%">
                    ${sponsoredContent}
                 </div>
                 <div class="col">
                    <div class="row ">
                       <div class="col-11 d-flex justify-content-start">
                          <p class="truncate-one text-capitalize mb-0">${vendor.Name}</p>
                       </div>
                       <div class="col-1 p-0">
                        <button type="button" class="mapMarkerBtnClass-${vendor.Vendor_ID} btn p-0 m-0 h-100 w-100 " data-vendorid="${vendor.Vendor_ID}">
                          <i class="fas fa-map-marker-alt ml-1"></i>
                        </button>
                      </div>
                    </div>
                    <div class="row ">
                       <div class="col-12 d-flex justify-content-start">
                          <p class="truncate-one text-capitalize mb-0"><strong>${vendor.Address.City}</strong></p>
                       </div>
                    </div>
                    <div class="row ">
                       <div class="col-12 d-flex justify-content-start">
                          <p class="truncate-one text-capitalize mb-0">${category.Speciality}</p>
                       </div>
                       <div class="col-8 pr-0">
                          <p class="mb-0 pt-1">Min Order:₹${vendor.Minimum_Order}</p>
                       </div>
                       <div class="col mb-2 pl-0 d-flex justify-content-end">
                          <p class="ratingBorder text-white mb-0 pl-1 ml-1" style="${color}">${vendor.Vendor_Rating} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                       </div>
                    </div>
                 </div>
              </div>
           </a>`;
              $(
                `div.vendorSearchResultsMobile div.${category.Picklist_ID}-category`
              ).append(vendorSearchDetailsMobile);
              $(`.mapMarkerBtnClass-${vendor.Vendor_ID}`).on("click", (e) => {
                GoogleMapsApiObjectVendor.openGMapModal(e);
              });
            }
          }
        });
      });

      //Adding images to vendors
      vendors.forEach(async (vendor) => {
        var vendorImg = await s3Object.getObjectURL(
          "Vendor/" + vendor.Image_URL + "/Document Images/Business Image/1.png"
        );
        $(`.${vendor.Vendor_ID}-searchVendImg`).attr("src", vendorImg);
      });
    }

    //Products/Services
    if (products.length == 0) {
      console.log("Products are Empty");
      $("div.productServiceSearchResults").empty();
      $("div.productServiceSearchResultsMobile").empty();
      $(".no-search-products").removeClass("d-none");
    } else {
      console.log("Products are not empty");
      //Removes the Previous Results
      $("div.productServiceSearchResults").empty();
      $("div.productServiceSearchResultsMobile").empty();
      $(".no-search-products").addClass("d-none");
      //Gets all the Unique product Vendors
      products.forEach((product) => {
        var sponsoredContent = "";
        if (product.isSponsored > 0) {
          sponsoredContent = `<p class="carousel-caption text-white shadow rounded p-0 m-0" style="background: rgba(244, 148, 49);">Sponsored</p>`;
        }
        if ($(`.${product.Vendor_ID}-searchProducts`).length > 0) {
          if (product.Product_Review_Score > 0) {
            var color = "background: rgba(244, 148, 49);";
            var reviewScore = product.Product_Review_Score;
          }
          if (deviceType == 1) {
            //Adding this product
            var productServiceDetailsDesktop = `<a href="${baseURL}customer/productSummary/${product.product_URL}" class="list-group-item list-group-item-action list-group-item-light truncate ">
              <div class="row d-flex">
                 <div class="col-3">
                    <img src="" class="rounded-4 searchModalImg ${product.Product_ID}-searchProdImg" alt="${product.Product_Service_Name}">
                    ${sponsoredContent}
                 </div>
                 <div class="col">
                    <div class="row ">
                       <div class="col d-flex justify-content-start">
                          <p class="truncate text-capitalize mb-0">${product.Product_Service_Name}</p>
                       </div>
                    </div>
                    <div class="row ">
                       <div class="col-12 d-flex justify-content-start">
                          <p class="truncate-one text-capitalize mb-0"><strong>${product.Vendor_Address.City}</strong></p>
                       </div>
                    </div>
                    <div class="row ">
                       <div class="col pr-0 d-flex justify-content-start">
                          <p class="mb-0 pt-2">₹${product.Price}</p>
                       </div>
                       <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                          <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${product.Product_Review_Score} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                       </div>
                    </div>
                 </div>
              </div>
           </a>`;
            $(`.${product.Vendor_ID}-searchProducts`).append(
              productServiceDetailsDesktop
            );
          } else {
            var productServiceDetailsMobile = `<a href="${baseURL}customer/productSummary/${product.product_URL}" class="list-group-item list-group-item-action list-group-item-light truncate ">
            <div class="row d-flex">
               <div class="col-4">
               <img src="" class="rounded-4 searchModalImg ${product.Product_ID}-searchProdImg" alt="${product.Product_Service_Name}">
                  ${sponsoredContent}
               </div>
               <div class="col">
                  <div class="row ">
                     <div class="col d-flex justify-content-start">
                        <p class="truncate text-capitalize mb-0">${product.Product_Service_Name}</p>
                     </div>
                  </div>
                  <div class="row ">
                     <div class="col-12 d-flex justify-content-start">
                        <p class="truncate-one text-capitalize mb-0"><strong>${product.Vendor_Address.City}</strong></p>
                     </div>
                  </div>
                  <div class="row ">
                     <div class="col pr-0 d-flex justify-content-start">
                        <p class="mb-0 pt-2">₹${product.Price}</p>
                     </div>
                     <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                        <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${product.Product_Review_Score} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                     </div>
                  </div>
               </div>
            </div>
         </a>`;
            $(`.${product.Vendor_ID}-searchProducts`).append(
              productServiceDetailsMobile
            );
          }
        } else {
          if (product.Product_Review_Score > 0) {
            var color = "background: rgba(244, 148, 49);";
          }
          if (deviceType == 1) {
            //Adding Vendor Parent Div
            var vendorForSearchProductDesktop = `<div class="list-group mb-3 ${product.Vendor_ID}-searchVendor">
        <div class="row">
            <div class="col-9 mb-2">
                <h4>
                    <p class="truncate text-capitalize">${product.Vendor_Name}</p>
                </h4>
            </div>
            <div class="col-3 mb-2">
                <a href="${baseURL}customer/productListing/${product.Vendor_URL}" class="btn btn-md btn rounded-4 active ${product.Vendor_Name}-view-menu-link" style="color:rgba(244, 148, 49);" role="button" aria-pressed="true">View Menu</a>
        
            </div>
        </div>
        <span class="${product.Vendor_ID}-searchProducts"></span>
        </div>`;
            $("div.productServiceSearchResults").append(
              vendorForSearchProductDesktop
            );

            //Adding this product
            var productServiceDetailsDesktop = `<a href="${baseURL}customer/productSummary/${product.product_URL}" class="list-group-item list-group-item-action list-group-item-light truncate ">
            <div class="row d-flex">
               <div class="col-3">
               <img src="" class="rounded-4 searchModalImg ${product.Product_ID}-searchProdImg" alt="${product.Product_Service_Name}">
                  ${sponsoredContent}
               </div>
               <div class="col">
                  <div class="row ">
                     <div class="col d-flex justify-content-start">
                        <p class="truncate text-capitalize mb-0">${product.Product_Service_Name}</p>
                     </div>
                  </div>
                  <div class="row ">
                     <div class="col-12 d-flex justify-content-start">
                        <p class="truncate-one text-capitalize mb-0"><strong>${product.Vendor_Address.City}</strong></p>
                     </div>
                  </div>
                  <div class="row ">
                     <div class="col pr-0 d-flex justify-content-start">
                        <p class="mb-0 pt-2">₹${product.Price}</p>
                     </div>
                     <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                        <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${product.Product_Review_Score} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                     </div>
                  </div>
               </div>
            </div>
         </a>`;
            $(`.${product.Vendor_ID}-searchProducts`).append(
              productServiceDetailsDesktop
            );
          } else {
            var vendorForSearchProductMobile = `<div class="list-group mb-4 ${product.Vendor_ID}-searchVendor">
            <div class="row">
              <div class="col-7 mb-2">
                <h5>
                  <p class=" truncate text-capitalize">${product.Vendor_Name}</p>
                </h5>
              </div>
              <div class="col-5 mb-2">
                <a href="${baseURL}customer/productListing/${product.Vendor_URL}" class="btn btn-sm btn rounded-4 active ${product.Vendor_Name}-view-menu-link" style="color:rgba(244, 148, 49);" role="button" aria-pressed="true">View Menu</a>
    
              </div>
              <span class="${product.Vendor_ID}-searchProducts"></span>
            </div>`;
            $("div.productServiceSearchResultsMobile").append(
              vendorForSearchProductMobile
            );

            var productServiceDetailsMobile = `<a href="${baseURL}customer/productSummary/${product.product_URL}" class="list-group-item list-group-item-action list-group-item-light truncate ">
            <div class="row d-flex">
               <div class="col-4">
               <img src="" class="rounded-4 searchModalImg ${product.Product_ID}-searchProdImg" alt="${product.Product_Service_Name}">
                  ${sponsoredContent}
               </div>
               <div class="col">
                  <div class="row ">
                     <div class="col d-flex justify-content-start">
                        <p class="truncate text-capitalize mb-0">${product.Product_Service_Name}</p>
                     </div>
                  </div>
                  <div class="row ">
                     <div class="col-12 d-flex justify-content-start">
                        <p class="truncate-one text-capitalize mb-0"><strong>${product.Vendor_Address.City}</strong></p>
                     </div>
                  </div>
                  <div class="row ">
                     <div class="col pr-0 d-flex justify-content-start">
                        <p class="mb-0 pt-2">₹${product.Price}</p>
                     </div>
                     <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                        <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${product.Product_Review_Score} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                     </div>
                  </div>
               </div>
            </div>
         </a>`;
            $(`.${product.Vendor_ID}-searchProducts`).append(
              productServiceDetailsMobile
            );
          }
        }
      });

      //Adding vendors to the categories
      products.forEach(async (product) => {
        var productImg = await s3Object.getObjectURL(product.Image_URL); //${S3BaseURLProd}/${product.Image_URL}
        $(`.${product.Product_ID}-searchProdImg`).attr("src", productImg);
      });
    }
  };
}
