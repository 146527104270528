var vendorDocumentsArray = [];
var eachDocImageValid = [];
var mandatoryDocsId = [];
var documentIdArray = [];
var vendorAWSURL = "";

import s3Util from "../helpers/s3Util";
var s3Object = new s3Util();

export default class Vendor_upload_documents {
  constructor() {
    console.log("Bind Function of docs");
    this.productServicesListingPage = $(".product-services-listing-page");
    this.uploadPrevBtn = $("#uploadPrevBtn");
    this.uploadDocsSubmitBtn = $(".uploadDocsSubmitBtn");
    this.uploadDocuments_page5 = $(".vendorUploadDocuments");
    this.listingPageSmtBtn = $("#listingPageSmtBtn");
    this.Products_Services_page4 = $(".Products-Services-page4"); //not used ?

    // Registering Events
    this.bindEvents();
  }

  bindEvents = () => {
    this.get_Vendor_Summary();
    // console.log("in upload docs bind");
    this.listingPageSmtBtn.on("click", this.getRequiredVendorDocuments);
    this.uploadDocsSubmitBtn.on("click", this.doDocumentValidation);
    this.uploadPrevBtn.on("click", this.UploadDocPagePrevAction);
  };
  get_Vendor_Summary = () => {
    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/getVendorDetails",
      headers: { Authorization: X_API_KEY },
      success: (res) => {
        var docTempUrl = res.Image_URL

        // Method 1 old | res.Image_URL= Vendor/0001_default/Document Images/Business Image
        // docTempUrl = docTempUrl.slice(0, -14);

        // Method 2     | res.Image_URL= 0001_default
        docTempUrl = "Vendor/" + docTempUrl + "/Document Images";

        console.log("docTempUrl", docTempUrl);
        vendorAWSURL = docTempUrl;
        this.getRequiredVendorDocuments();
      },
    });
  };

  getRequiredVendorDocuments = () => {
    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/RequiredVendorDocuments",
      headers: { Authorization: X_API_KEY },

      success: this.showRequiredDocuments,
    });
  };
  // To go to Previous Page (Product Listing)
  UploadDocPagePrevAction = (e) => {
    e.preventDefault();
    // console.log("Going to product listing pg");
    this.productServicesListingPage.removeClass("d-none");
    this.uploadDocuments_page5.addClass("d-none");
    this.formstep5.removeClass("current-item");
    this.formstep4.addClass("current-item");
  };

  // To validate if all manditory document images are submitted
  doDocumentValidation = (e) => {
    // console.log(eachDocImageValid);
    // console.log(eachDocImageValid.length);
    // console.log(documentIdArray);
    // console.log(mandatoryDocsId);

    e.preventDefault();
    var mandatoryDocsImagealid = true;
    mandatoryDocsId.forEach((element) => {
      console.log(element);
      // console.log(eachDocImageValid[element]);

      const docFileInput = document.getElementById(element);
      console.log(docFileInput.files[0]);

      if (!(docFileInput.files[0] === undefined)) {
        if (eachDocImageValid[element] == false) {
          // console.log("this image is false");
          const docFileInputPrev = document.getElementsByClassName(element);
          docFileInputPrev[1].innerHTML = "Please select an image file";
          mandatoryDocsImagealid = false; //Removed for Servicing
        }
      }
    });

    if (mandatoryDocsImagealid) {
      // console.log("inside all okay");
      this.Submit_VendorDocuments();
      console.log("ALL Upload FIELDS VALID...VALIDATION OVER Submitted");
    }
  };

  // Vendor Documents submit function
  Submit_VendorDocuments = () => {

    // const key = 'Vendor/0001_default/Document Images/HelloWorld.jpg'; // Specify the desired key or path
    // const fileInputNew = document.getElementById('67bf6dd1-4897-11e9-d548-778fd1e3dff0'); // Assuming you have an input element with type="file"
    // const fileNew = fileInputNew.files[0];
    // s3Object.uploadImageToS3(fileNew, key)
    // var fileOne = '';
    documentIdArray.forEach((element) => {
      console.log(element);

      const docFileInputPrev = document.getElementsByClassName(element);
      // const docFileInput = document.getElementById(element)[0].files[0];
      var inputFileID = '#' + element;
      var files = $(inputFileID).get()[0].files[0];
      if (files != undefined) {

        var file = $(inputFileID).get()[0].files[0];
        console.log("fileDoc", file);
        // fileOne = file;
        // var documentData = {
        //   // documentFile: docFileInputPrev[0].src,
        //   documentFile: file,
        //   documentName: docFileInputPrev[0]["attributes"][6]["value"],
        // };
        // vendorDocumentsArray.push(documentData);
        const key = `${vendorAWSURL}/${docFileInputPrev[0]["attributes"][6]["value"]}.jpg`; // Specify the desired key or path
        const fileInputNew = document.getElementById(element); // Assuming you have an input element with type="file"
        const fileNew = fileInputNew.files[0];
        s3Object.uploadImageToS3(fileNew, key)
      }
      // if (docFileInputPrev[0].src.length > 100) {
      //   var documentData = {
      //     documentFile: docFileInputPrev[0].src,
      //     documentName: docFileInputPrev[0]["attributes"][6]["value"],
      //   };
      //   vendorDocumentsArray.push(documentData);
      // }
    });
    // console.log(vendorDocumentsArray);

    // // Create a FormData object and append the file to it
    // var formData = new FormData();
    // formData.append('file', fileOne);
    // console.log("fileOne", fileOne);

    // console.log("formData", formData);

    // var fileInput = $("#67bf6dd1-4897-11e9-d548-778fd1e3dff0")[0];
    // var fileBlob = new Blob([fileInput.files[0]], { type: fileInput.files[0].type });
    // var fileBlob = fileInput.files[0];


    // $.ajax({
    //   type: "POST",
    //   url: baseURL + "api/Vendor/documentUploadTemp",
    //   processData: false,
    //   contentType: false,
    //   // contentType: 'binary/octet-stream',
    //   data: {
    //     fileBlob: fileBlob,
    //   },
    //   success: function (res) {
    //     // console.log(res);
    //     //ImageURL = res;
    //   },
    // });
    // $.ajax({
    //   type: "GET",
    //   url: baseURL + "api/Vendor/getVendorSummary",

    //   headers: { Authorization: X_API_KEY },

    //   success: this.vendorDataID,
    // });
    // location.reload();
  };

  // To load Summary page using vendor Id
  vendorDataID = (res) => {
    // console.log(res);
    // var Vendor_ID = "67ccb451-4497-11e9-b548-778fd1e3dff0";
    var Vendor_ID = res.vendor["Vendor_ID"];
    // console.log(res.vendor["Vendor_ID"]);
    //window.location.replace(baseURL + "Vendor_summary/index/" + Vendor_ID);

    //TO GO TO NEXT PAGE
    //window.location.replace(baseURL + "Vendor_summary/index/" + Vendor_ID);
  };

  // To integrate the documents on the page
  showRequiredDocuments = (res) => {
    $("#vendorDocBox").empty();
    // console.log(res);
    var mandatoryDocsCount = 0;
    res.forEach((element) => {
      if (element.Mandatory_flag == 1) {
        mandatoryDocsId[mandatoryDocsCount] = element.Document_ID;
        mandatoryDocsCount++;
      }
      var mandatorylabel = element.Mandatory_flag == 1 ? "&nbsp*" : "";
      var nextDocument = `<div class="row mb-3">
                          <div><label class="form-label">${element.Picklist_display_value
        }</label><label class="text-danger">${mandatorylabel}</label></div>
                          <input type="file" class="form-control inputVendorDocument" id="${element.Document_ID
        }" accept="image/*" name="inpFile" aria-describedby="" value='${element.Mandatory_flag
        }'>
                          <br>
                          <div>
                          <img src="" alt="" id="" class="${element.Document_ID
        }" width="220px" height="auto" value='${element.Picklist_display_value}'>
                          <div class="docImageError text-danger ${element.Document_ID
        }"></div></div>
                          <br>
                          </div>`;
      $("#vendorDocBox").append(nextDocument);
      const docFileInput = document.getElementById(element.Document_ID);
      docFileInput.addEventListener("change", this.isDocumentImmage);
      eachDocImageValid[element.Document_ID] = false;
      documentIdArray.push([element.Document_ID]);
      this.insertDocImg(element.Document_ID, vendorAWSURL + "/" + element.Picklist_display_value + ".jpg");
      // this.insertDocImg(element.Document_ID, "Vendor/0001_default/Document Images/ID Proof.jpg");

    });
  };

  insertDocImg = async (imgTagClassName, imgURL) => {
    var documentImg = await s3Object.getObjectURL(imgURL);
    // $("#my_image").attr("src","second.jpg");
    document.getElementsByClassName(imgTagClassName)[0].src = documentImg;
  }

  // Validation to check if the Document is in image form
  isDocumentImmage = (e) => {
    // console.log(e.target.id);
    // console.log(e.target.files[0]);
    var docImagePreview = e.target.id;
    const docFileInputPrev = document.getElementsByClassName(docImagePreview);
    // console.log(docFileInputPrev[0]);
    // console.log(docFileInputPrev[1]);
    docFileInputPrev[0].src = "";
    eachDocImageValid[docImagePreview] = false;

    // console.log(docImagePreview);
    if (!e.target.files.length) {
      console.log("No File");
      return;
    }

    var imgExpression = /.*\.(jpg|jpeg|png|JPG|JPEG|PNG)$/;
    if (!e.target.files[0]) {
    } else {
      if (
        !e.target.files[0]["name"].match(imgExpression) ||
        !e.target.files[0]["name"]
      ) {
        docFileInputPrev[1].innerHTML = "Please select an image file";
      } else if (e.target.files[0]["size"] > 5 * 1024 * 1024) {
        docFileInputPrev[1].innerHTML = "Document should be less than 5mb";
      } else {
        // console.log("inside image validate");
        eachDocImageValid[docImagePreview] = true;

        docFileInputPrev[1].innerHTML = "";

        if (e.target.files[0]) {
          const reader = new FileReader();

          reader.addEventListener("load", function () {
            // Storing image in Preview
            docFileInputPrev[0].src = this.result;
          });

          reader.readAsDataURL(e.target.files[0]);
        }
      }
    }
  };
}
