import {
  productSummaryPageNestHubMaxView,
  productSummaryPageNestHubView,
  productSummaryPageMobileView,
  productSummaryPageTabView
} from "./pageVariables";
import cartUtil from "../helpers/cartUtil";
import Util from "../helpers/util";
var CartObject = new cartUtil();
var UtilObject = new Util();

var vendorProducts;
var vendor;
var CartDetails = {};
var itemExistsInCart = false,
  productInCart;
var vendorFromOrder;
var p = {};

var selected_value = 0;

import s3Util from "../helpers/s3Util";
var s3Object = new s3Util();
export default class ProductSummary {
  constructor() {
    var screenWidth = $(window).width();
    console.log(screenWidth);
    // if (screenWidth > 1023 && screenWidth < 1280) {
    //   $(".productSummaryPage").append(productSummaryPageNestHubView);
    // } else if (screenWidth >= 1280) {
    //   $(".productSummaryPage").append(productSummaryPageNestHubMaxView);
    // } else if (screenWidth > 819 && screenWidth <= 1023) {
    //   $(".productSummaryPage").append(productSummaryPageTabView);
    // } else {
    //   $(".productSummaryPage").append(productSummaryPageMobileView);
    // }

    if (screenWidth < 576) {
      //$(".displayDuringDesktopViewProductSummary").addClass("d-none");
      $(".displayDuringMobViewProductSummary").removeClass("d-none");
    } else {
      //$(".displayDuringMobViewProductSummary").addClass("d-none");
      $(".displayDuringDesktopViewProductSummary").removeClass("d-none");
    }

    this.inputReviewname = $(".inputReviewname");
    this.inputReviewdescription = $(".inputReviewdescription");
    this.inputReviewnameError = $(".inputReviewnameError");
    this.productNotPurchasedError = $(".productNotPurchasedError");
    this.inputReviewdescriptionError = $(".inputReviewdescriptionError");
    this.inputReviewStarRatingError = $(".ratingErrorMsg");
    this.productReviewForm = $("#productReviewForm");

    //+
    this.plusBtnId = $("#plusBtnId");
    //text-field
    this.qntyTextField = $(".qntyTextField");
    //-
    this.minusBtnId = $("#minusBtnId");
    //max error msg
    this.maxErrorMsg = $("#maxErrorMsg");

    //review modal
    this.submitreviewBtn = $(".submitreviewBtn");
    this.ratingbtn = $(".btnrating");
    this.commentReviewUserCheck = $(".commentReviewUserCheck");

    //productId
    this.Product_ID = $("div.productSummaryPage").attr("value");

    //Calculating the Number of products in the cart
    this.noOfProductsInCart = CartObject.cartCheck();
    vendorProducts = this.noOfProductsInCart.Products;
    vendorFromOrder = this.noOfProductsInCart.vendor;
    console.log(this.noOfProductsInCart.Products.length);
    $("span.cartCount").html(`${this.noOfProductsInCart.Products.length}`);
    //if product = [] then deleteCart - write code for this
    if (vendorFromOrder != null && vendorProducts.length == 0) {
      this.deleteEntireCart();
    }

    var allCookies = UtilObject.getAllCookies();
    console.log(allCookies);

    //Adding the cookies to CartDetails global
    CartDetails["ORDER_ID"] = UtilObject.getCookie("ORDER_ID");
    CartDetails["CUSTOMER_ID"] = UtilObject.getCookie("CUSTOMER_ID");
    $("a.continueShoppingBtn").on("click", this.maintainFlow);

    // Registering Events
    if (this.Product_ID == "") {
      $("div.emptyProductSummaryPage").removeClass("d-none");
    } else {
      $("div.productSummaryPage").removeClass("d-none");
      this.bindEvents();
    }
  }

  maintainFlow = () => {
    var allCookies = UtilObject.getAllCookies();
    console.log(allCookies);
    //window.location = allCookies.PREVIOUS_PAGE;
    history.go(-1);
  };

  bindEvents = () => {
    this.minusBtnId.on("click", this.minusItemFunction);
    this.plusBtnId.on("click", this.plusItemFunction);
    this.submitreviewBtn.on("click", this.submitreview);
    this.commentReviewUserCheck.on("click", this.commentReviewUserCheckx);
    this.ratingbtn.on("click", this.btnrating);
    $(".addToCartProductSummary").on("click", this.addNewitemToCart);

    //Calling product summary on load
    $(window).on("load", $.ready.then(this.getProductSummary));
    //$(window).on("load", $.ready.then(this.getProductImages));

    //Validations for Modalif Cart Items are different.
    $("button.btn.keepOldCart").on("click", () => {
      $("#showCartModal").hide();
      console.log("Keeping Old cart");
    });

    $(".reviewViewMoreBtn").on("click", () => {
      if ($(".reviewViewMoreBtn").html() == "View More") {
        $(".reviewViewMoreBtn").html("View Less");
      } else {
        $(".reviewViewMoreBtn").html("View More");
      }
    });

    $("button.btn.startNewCart").on("click", () => {
      $("#showCartModal").hide();
      this.deleteEntireCart();
      CartObject.cartCheck();
      console.log(p);
      this.addtoCart(p, "ADD");
      this.refreshCartCount();
      // setTimeout(() => {
      //   window.location.reload();
      // }, 100);
    });

    this.refreshCartCount();

    //button to close window if user has not purchased the item
    $("button.btn.productReviewClose").on("click", () => {
      $("#productNotPurchased").hide();
      //   setTimeout(()=>{
      //     window.location.reload();
      //  }, 50);
    });

    $(".reviewprodductModalClose").on("click", () => {
      $(".reviewprodductModal").hide();
      //   setTimeout(()=>{
      //     window.location.reload();
      //  }, 50);
    });

    $("#customerNotLoggedforReviewClose").on("click", () => {
      $("#customerNotLoggedforReview").hide();
      //   setTimeout(()=>{
      //     window.location.reload();
      //  }, 50);
    });

    $(".buyNow").on("click", this.buyProductNow);
  };

  //check if the user has made the purchase to review
  commentReviewUserCheckx = () => {
    console.log("hello");
    var customer = UtilObject.getAllCookies();

    $.ajax({
      url: baseURL + "api/customer/ProductSummary/checkUserPurchasedItem",
      type: "POST",
      async: false,
      data: {
        Customer_ID: customer.CUSTOMER_ID,
        Product_ID: this.Product_ID
      },
      headers: { Authorization: X_API_KEY },
      sucess: (res) => {
        console.log(res);
        $("#reviewModal").show();
      },
      error: (err) => {
        console.log(err);
        var code = err.responseJSON.code;
        console.log(code);
        if (code == 99) {
          $("#productNotPurchased").show();
        } else if (code == 70) {
          $("#reviewModal").show();
        } else if (code == 18) {
          $("#customerNotLoggedforReview").show();
        }
      }
    });
  };

  refreshCartCount = () => {
    this.noOfProductsInCart = CartObject.cartCheck();
    console.log(this.noOfProductsInCart.Products.length);
    $("span.cartCount").html(`${this.noOfProductsInCart.Products.length}`);
  };

  submitreview = (e) => {
    e.preventDefault();
    console.log("Review popup description");

    let isReviewnameValidated = this.Reviewname_validate();
    if (isReviewnameValidated) {
      console.log("Review Name VALID");
      this.inputReviewnameError.html("");
    }

    let isReviewdescriptionValidated = this.Reviewdescription_validate();
    if (isReviewdescriptionValidated) {
      console.log("Review Description VALID");
      this.inputReviewdescriptionError.html("");
    }

    let isReviewStarRatingValidated = this.ReviewRating_validate();
    if (isReviewStarRatingValidated) {
      console.log("Review star VALID");
      this.inputReviewStarRatingError.html("");
    }

    if (
      isReviewnameValidated &&
      isReviewdescriptionValidated &&
      isReviewStarRatingValidated
    ) {
      this.productReviewSubmit_Form();
      console.log("ALL FIELDS VALID...VALIDATION OVER");
    }
  };

  ReviewRating_validate = () => {
    let flag = true;

    if (selected_value == 0) {
      this.inputReviewStarRatingError.html("Select rating");
      flag = false;
    }

    return flag;
  };

  Reviewname_validate = () => {
    let flag = true;

    if (this.inputReviewname.val().length < 5) {
      this.inputReviewnameError.html(
        "Review Name should be minimum 5 characters long!"
      );

      flag = false;
    }

    return flag;
  };

  Reviewdescription_validate = () => {
    let flag = true;

    if (this.inputReviewdescription.val().length < 5) {
      this.inputReviewdescriptionError.html(
        "Review description should be minimum 5 characters long!"
      );

      flag = false;
    }

    return flag;
  };

  //submit button for review
  productReviewSubmit_Form = () => {
    var customer = UtilObject.getAllCookies();
    console.log("Inside submit flow");
    $.ajax({
      url: baseURL + "api/customer/ProductSummary/addProductReview",
      type: "POST",
      async: false,
      data: {
        Customer_ID: customer.CUSTOMER_ID,
        Product_ID: this.Product_ID,
        Review_Title: this.inputReviewname.val(),
        Review_Description: this.inputReviewdescription.val(),
        Review_Score: selected_value
      },
      headers: { Authorization: X_API_KEY },
      success: (res) => {
        $(".reviewprodductModal").hide();
        location = self.location;
      },
      error: (err) => {
        console.log(err);
        var code = err.responseJSON.code;
        console.log(code);
        if (code == 18) {
          alert("Customer Not logged In");
          location = self.location;
        } else if (code == 99) {
          //this.productNotPurchasedError.html("YOU HAVE NOT PURCHASED THE ITEM");
          // location = self.location;
          $(".reviewprodductModal").hide();
          $("#productNotPurchased").show();
        }
      }
    });
  };

  //plusItemFunction
  plusItemFunction = () => {
    var maxQuantity = $(".qntyTextField").attr("max");
    maxQuantity = parseInt(maxQuantity);
    var currentQuantity = $(".qntyTextField").val();
    currentQuantity = parseInt(currentQuantity);
    var newQuantity = currentQuantity + 1;
    console.log(currentQuantity);
    console.log(maxQuantity);
    // if (itemExistsInCart == true) {

    // } else {

    // }
    console.log("try for + item");

    // this.qntyTextField.val().disabled = true;

    //validation for + btn
    if (newQuantity > maxQuantity) {
      console.log("max reached");
      this.maxErrorMsg.html("Maximum Quantity Reached");
      $(".qntyTextField").attr("value", currentQuantity);
      $(".qntyTextField").html(currentQuantity);
      //this.qntyTextField.val() = currentQuantity;
    } else {
      this.maxErrorMsg.html("");
      if (itemExistsInCart == true) {
        p.Product_ID = this.Product_ID;
        p.Quantity = newQuantity;
        p.Total_Items = this.noOfProductsInCart.Products.length;
        console.log(p);
        this.addtoCart(p, "UPDATE_QTY");
        $(".qntyTextField").attr("value", newQuantity);
        $(".qntyTextField").html(newQuantity);
        //this.qntyTextField.val() = newQuantity;
      } else {
        $(".qntyTextField").attr("value", newQuantity);
        $(".qntyTextField").html(newQuantity);
        //this.qntyTextField.val() = newQuantity;
      }
    }
    this.refreshCartCount();
  };

  //miuusItemFunction
  minusItemFunction = () => {
    var p = {};
    var minQuantity = 1;
    //maxQuantity = parseInt(maxQuantity);
    var currentQuantity = $(".qntyTextField").val();
    currentQuantity = parseInt(currentQuantity);
    var newQuantity = currentQuantity - 1;
    console.log(currentQuantity);
    if (newQuantity < 1) {
      this.maxErrorMsg.html("Minimum Quantity Should be One");
      $(".qntyTextField").attr("value", 1);
      $(".qntyTextField").html(1);
    } else {
      this.maxErrorMsg.html("");
      if (itemExistsInCart == true) {
        p.Product_ID = this.Product_ID;
        p.Quantity = newQuantity;
        p.Total_Items = this.noOfProductsInCart.Products.length;
        console.log(p);
        this.addtoCart(p, "UPDATE_QTY");
        $(".qntyTextField").attr("value", newQuantity);
        $(".qntyTextField").html(newQuantity);
      } else {
        $(".qntyTextField").attr("value", newQuantity);
        $(".qntyTextField").html(newQuantity);
      }
    }
    this.refreshCartCount();
  };

  removeProductFromCart = () => {
    var p = {};

    p.Product_ID = this.Product_ID;
    p.Quantity = 0;
    p.Total_Items = this.noOfProductsInCart.Products.length;
    this.addtoCart(p, "UPDATE_QTY");
    this.refreshCartCount();

    $(".qntyTextField").attr("value", 1);
    $(".qntyTextField").html(1);

    $(`.removeFromCart`).replaceWith(
      `<button type="button" class="btn btn-primary btn-sm btn-block addToCartProductSummary" style="width:70%">Add To Cart</button>`
    );
    $(".addToCartProductSummary").off("click");
    $(".addToCartProductSummary").on("click", this.addNewitemToCart);

    //UtilObject.eraseCookie("ORDER_ID");

    $("button.buyNow").show();
  };

  buyProductNow = () => {
    var p = {};
    var currentQuantity = $(".qntyTextField").val();

    p.Product_ID = this.Product_ID;
    p.Quantity = currentQuantity;
    p.Total_Items = this.noOfProductsInCart.Products.length;

    console.log(p);
    if (itemExistsInCart == true) {
      this.addNewitemToCartBuyNow();
    } else {
      this.addNewitemToCartBuyNow();
    }

    //window.location = baseURL + "customer/cart";
  };

  btnrating = (event) => {
    // //validation for - btn
    console.log("inside rating");
    console.log(event);
    var previous_value = $("#selected_rating").val();

    console.log(previous_value);

    selected_value = $(event.currentTarget).attr("data-attr");

    console.log(selected_value);

    $("#selected_rating").val(selected_value);

    $(".selected-rating").empty();
    $(".selected-rating").html(selected_value);

    for (var i = 1; i <= selected_value; ++i) {
      $("#rating-star-" + i).toggleClass("btn-warning");
      $("#rating-star-" + i).toggleClass("btn-default");
    }

    for (var ix = 1; ix <= previous_value; ++ix) {
      $("#rating-star-" + ix).toggleClass("btn-warning");
      $("#rating-star-" + ix).toggleClass("btn-default");
    }
  };

  // product summary
  getProductSummary = () => {
    console.log(this.Product_ID);
    $.ajax({
      url: baseURL + "api/customer/ProductSummary/getProductSummary",
      type: "POST",
      async: false,
      data: {
        Product_ID: this.Product_ID
      },
      headers: { Authorization: X_API_KEY },
      success: this.showProductDetails,
      error: (result) => {
        console.log(result);
      }
    });
  };

  showProductDetails = async (res) => {
    var screenWidth = $(window).width();
    console.log(res);

    //for vendor on product summary page
    $.ajax({
      url:
        baseURL + "api/customer/ProductSummary/getVendorDetailsProductSummary",
      type: "POST",
      async: false,
      data: {
        Vendor_ID: res.Vendor_ID
      },
      headers: { Authorization: X_API_KEY },
      success: async (result) => {
        console.log(result);

        vendor = result.vendor;
        console.log(vendor);

        //Star based on rating for the vendor section
        let vendorRating = result.vendor.Vendor_Rating;
        vendorRating = Math.round(vendorRating * 2) / 2;
        let vendorOutput = [];

        // Append all the filled whole stars
        for (var i = vendorRating; i >= 1; i--)
          vendorOutput.push(
            `<i class="fa fa-star" aria-hidden="true" style="color: gold;"></i>`
          );
        // If there is a half a star, append it
        if (i == 0.5)
          vendorOutput.push(
            `<i class="fa fa-star-half-o" aria-hidden="true" style="color: gold;"></i>`
          );
        // Fill the empty stars
        for (let i = 5 - vendorRating; i >= 1; i--)
          vendorOutput.push(
            `<i class="fa fa-star-o" aria-hidden="true" style="color: gold;"></i>`
          );

        vendorOutput = vendorOutput.join("");

        //vendor details
        //     var pdetail = `  <div class="card-header">
        //     <h4><strong>Sold By</strong></h4>
        // </div>
        // <div class="card-body text-dark">
        //     <div class="row">
        //         <div class="col-6 d-flex justify-content-start">
        //         <img src="${baseURL}/assets/src/images/${
        //       result.vendor.Image_URL
        //     }" class="img-fluid rounded-start rounded-end" width="210" height="150" alt=" Responsive image">
        //         </div>

        //         <div class="col">

        //             <h4 class="card-title">
        //                 ${result.vendor.Business_Name}
        //             </h4>
        //             <h5 class="card-title">${
        //               result.vendor.Address.Address_Line_1 +
        //               ", " +
        //               result.vendor.Address.State
        //             }</h5>
        //             <h6 class="card-title">Minimum Order:${
        //               result.vendor.Minimum_Order
        //             }</h6>
        //             <h6 class="card-title">${result.vendor.Vendor_Rating} stars</h6>
        //             <h6 class="card-title">${vendorOutput}</h6>
        //         </div>
        //     </div>
        // </div> `;
        //     $(".productVendorDetails").append(pdetail);

        // //product Details

        // var vendorname = result.vendor.Business_Name;
        // $(".productvendorName").append(vendorname);

        //new product details
        var vendorImg = await s3Object.getObjectURL(
          "Vendor/" + vendor.Image_URL + "/Document Images/Business Image/1.png"
        );

        $(".soldByVendorImage").attr("src", `${vendorImg}`);

        $(".soldByVendorRatingStar").html(vendorOutput);
        $(".soldByVendorName").html(vendor.Business_Name);
        $(".soldByVendorAddress").html(
          `${vendor.Address.City + ", " + vendor.Address.State}`
        );
        $(".soldByMinimumOrder").html(vendor.Minimum_Order);
        $(".vendorPageRelocate").attr(
          "href",
          `${baseURL}customer/productListing/${vendor.Vendor_URL}`
        );
      },
      error: function (result) {
        console.log(result);
      }
    });

    //to show button if nonvegflag is set = 0 is veg and 1 is non-veg
    // var color = "text-primary";

    // if (res.hasOwnProperty("Veg_NonVeg_flag")) {
    //   if (res.Veg_NonVeg_flag == "Non-Veg") {
    //     color = "text-danger";
    //   } else {
    //     color = "text-success";
    //   }
    // }
    if (res.Veg_NonVeg_flag != false) {
      if (res.Veg_NonVeg_flag == 0) {
        var value = `<h6 class="card-title text-white p-1" style="background: #198754;">Veg</h6>`;
      } else if (res.Veg_NonVeg_flag == 1) {
        var value = `<h6 class="card-title text-white p-1" style="background: #dc3545;">Non-Veg</h6>`;
      }
      $(".vegOrNonVegSymbol").html(value);
    }

    //new peoduct rating
    //Star based on rating for the product section
    // let productRating = res.Product_Review_Score;
    // productRating = Math.round(productRating * 2) / 2;
    // let productRatingOutput = [];

    // // Append all the filled whole stars
    // for (var i = productRating; i >= 1; i--)
    //   productRatingOutput.push(
    //     `<i class="fa fa-star" aria-hidden="true" style="color: white;"></i>`
    //   );
    // // If there is a half a star, append it
    // if (i == 0.5)
    //   productRatingOutput.push(
    //     `<i class="fa fa-star-half-o" aria-hidden="true" style="color: white;"></i>`
    //   );
    // // Fill the empty stars
    // for (let i = 5 - productRating; i >= 1; i--)
    //   productRatingOutput.push(
    //     `<i class="fa fa-star-o" aria-hidden="true" style="color: white;"></i>`
    //   );

    // productRatingOutput = productRatingOutput.join("");
    // //productname
    // var pname = `<h3 class="text-white">${res.Product_Service_Name} </h3>
    // <h5 class="text-white">${res.Product_Speciality}</h5>
    // <br>
    // <h5 class="text-white productvendorName" style="color: grey;"> </h5>
    // <br>

    // <div class="container-fluid  p-0">
    //     <div class="row d-flex justify-content-around">
    //         <div class="col-3">
    //             <h5 class="fw-normal  text-white"><i class="fa fa-star" style="color: white;"></i>${res.Product_Review_Score}</h5>

    //         </div>
    //         <div class="col-3">
    //         <h5 class="fw-normal  text-white">₹ ${res.Price} </h5>

    //         </div>
    //        <!-- <div class="col-3">
    //         <h5 class="fw-normal  text-white"><i class="fa fa-truck" aria-hidden="true"></i>Free Delivery</h5>

    //         </div>-->
    //     </div>

    //     <div class="row  mt-2">
    //             <div class="col-4 w-100 d-md-block mt-4 d-flex justify-content-center">
    //             <button type="button" class="btn notaVegNonVeg ${vegNonvegBtnColor} btn ml-1 mt-4">${value}</button>
    //             <button type="button" class="btn btn-light btn ml-1 mt-4" data-bs-toggle="modal" data-bs-target="#viewProductPhotos">View photos</button>
    //             <a href="#review"><button type="button" class="btn btn-light btn ml-1 mt-4">View reviews</button></a>
    //             </div>
    //         </div>
    // </div>`;
    // $(".productSummaryDetails").append(pname);
    // // to hide button if nonvegflag is not set

    // //product description
    // var pdescription = `<p class="card-text">${res.Description}</p>`;
    // $(".productDescription").append(pdescription);

    //new product details
    $(".productName").html(res.Product_Service_Name);
    // $(".productRating").html(res.Product_Service_Name);
    $(".productPrice").html(res.Price);
    // $(".productRating").html(productRatingOutput);
    $(".productDescription").html(res.Description);

    if (res.Product_Review_Score > 0) {
      //for vendor review stars
      let productRating = res.Product_Review_Score;
      productRating = Math.round(productRating * 2) / 2;
      let productRatingOutput = [];

      // Append all the filled whole stars
      for (var i = productRating; i >= 1; i--)
        productRatingOutput.push(
          `<i class="fa fa-star" aria-hidden="true" style="color: white;"></i>`
        );
      // If there is a half a star, append it
      if (i == 0.5)
        productRatingOutput.push(
          `<i class="fa fa-star-half-o" aria-hidden="true" style="color: white;"></i>`
        );
      // Fill the empty stars
      for (let i = 5 - productRating; i >= 1; i--)
        productRatingOutput.push(
          `<i class="fa fa-star-o" aria-hidden="true" style="color: white;"></i>`
        );

      productRatingOutput = productRatingOutput.join("");
      if (screenWidth > 576) {
        $(".productRating").html(productRatingOutput);
      } else {
        $(".productRatingMobile").html(
          res.Product_Review_Score +
            '<i class="fa fa-star" aria-hidden="true" style="color: white;"></i>'
        );
      }
    } else {
      let vendorNoOutput = [];
      for (var stars = 5; stars >= 1; stars--)
        vendorNoOutput.push(
          `<i class="fa-regular fa-star" aria-hidden="true" style="color: white;"></i>`
        );
      vendorNoOutput = vendorNoOutput.join("");
      console.log(vendorNoOutput);
      if (screenWidth > 576) {
        $(".productRating").html(vendorNoOutput);
      } else {
        $(".productRatingMobile").html(vendorNoOutput);
      }
    }

    //product reviews
    //console.log(res.Reviews.length);
    if (res.hasOwnProperty("Reviews")) {
      if (res.Reviews.length == 0) {
        $(".reviewViewMoreBtn").hide();
        $(".reviewError").html("No reviews yet..");
      } else if (res.Reviews.length > 0) {
        if (res.Reviews.length < 3) {
          $(".reviewViewMoreBtn").hide();
        }
        var counter = 0;
        res.Reviews.forEach((element) => {
          //Getting Stars based on review rating for the review section
          let reviewRating = element.Review_Score;
          reviewRating = Math.round(reviewRating * 2) / 2;
          let reviewOutput = [];

          // Append all the filled whole stars
          for (var i = reviewRating; i >= 1; i--)
            reviewOutput.push(
              `<i class="fa fa-star" aria-hidden="true" style="color: gold;"></i>`
            );
          // If there is a half a star, append it
          if (i == 0.5)
            reviewOutput.push(
              `<i class="fa fa-star-half-o" aria-hidden="true" style="color: gold;"></i>`
            );
          // Fill the empty stars
          for (let i = 5 - reviewRating; i >= 1; i--)
            reviewOutput.push(
              `<i class="fa fa-star-o" aria-hidden="true" style="color: gold;"></i>`
            );
          //
          reviewOutput = reviewOutput.join("");

          //getting customer
          $.ajax({
            url: baseURL + "api/customer/Account/GetUserDetails",
            type: "POST",
            async: false,
            data: {
              Customer_ID: element.Customer_ID
            },
            headers: { Authorization: X_API_KEY },
            success: (result) => {
              console.log(result);

              //Review
              var item = `
          <div class="row">
          <div class="col">
          <h4 class="card-title">${result.Customer_Name}</h4>
          </div>
          <div class="col">
          <div class="ratings text-right"><span>${reviewOutput}</span>
          </div>
          </div>
          <h5 class="card-title">${element.Review_Title}</h5>
          <p class="card-text">${element.Review_Description}</p>
          </div>
          <hr style="width:auto;">`;
              if (counter < 3) {
                $(".productReviews").append(item);
              } else {
                $("#productReviewsMore").append(item);
              }
              counter += 1;
            },
            error: function (result) {
              console.log(result);
            }
          });
        });
      }
    } else {
      $(".reviewViewMoreBtn").hide();
      $(".reviewError").html("No reviews yet..");
    }

    //console.log($(".reviewViewMoreBtn").html());
    // if ($(".reviewViewMoreBtn").val()=="viewMore"){
    //   $(".reviewViewMoreBtn").html("View More")
    // }else{
    //   $(".reviewViewMoreBtn").html("View Less")
    // }

    //Check if product already exists in cart\
    console.log(vendorProducts);
    vendorProducts.forEach((product) => {
      if (product.Product_ID === res.Product_ID) {
        itemExistsInCart = true;
        productInCart = product;
        console.log("this is there in the cart");
      }
    });

    if (itemExistsInCart == true) {
      var cartItem = `<li class="page-item"><button class="page-link minusButton" value="${productInCart.Product_ID}">-
        </button>
    </li>

    <li class="page-item"><button min="0" max="${productInCart.Product_Details.Quantity}" name="${productInCart.Product_ID}" value="${productInCart.Quantity}" type="number" class="page-link quantityButton qntyTextField" disabled>${productInCart.Quantity}</button>
    </li>

    <li class="page-item"> <button class="page-link plusButton" value="${productInCart.Product_ID}">+

        </button>
    </li>`;
      $(".productDetailsInCart").html(cartItem);
      //$(".qntyTextField").val(product.Quantity);
      $(".minusButton").off("click");
      $(".minusButton").on("click", this.minusItemFunction);
      $(".plusButton").off("click");
      $(".plusButton").on("click", this.plusItemFunction);

      $(`.addToCartProductSummary`).replaceWith(
        `<button type="button" class="btn btn-sm btn-block text-white removeFromCart" style="background: rgba(244, 148, 49);width:70%">Remove from Cart</button>`
      );
      $("button.buyNow").hide();
      $(".removeFromCart").off("click");
      $(".removeFromCart").on("click", this.removeProductFromCart);
    } else {
      if (res.Quantity <= 0) {
        $(".productsAvailable").addClass("d-none");
        $(".productsNotAvailable").removeClass("d-none");
      } else {
        var cartItem = `<li class="page-item"><button class="page-link minusButton" value="${res.Product_ID}">-
        </button>
    </li>

    <li class="page-item"><button min="0" max="${res.Quantity}" name="${res.Product_ID}" value="1" type="number" class="page-link quantityButton qntyTextField" disabled>1</button>
    </li>

    <li class="page-item"> <button class="page-link plusButton" value="${res.Product_ID}">+

        </button>
    </li>`;
        $(".productDetailsInCart").html(cartItem);
        //$(".qntyTextField").val(product.Quantity);
        $(".minusButton").off("click");
        $(".minusButton").on("click", this.minusItemFunction);
        $(".plusButton").off("click");
        $(".plusButton").on("click", this.plusItemFunction);
      }
    }

    //product images
    var productS3Img = await s3Object.getObjectURL(res.Image_URL); //element.Image_URL
    $(".landingPageProductImage").attr("src", productS3Img);

    this.productImageCarousel(res.Image_URL);

    // //product image count
    // $(".imagesCountProduct").html(`+${result.length - 3}`);
    // if (result.length == 3) {
    //   $(".imagesCountProduct").hide();
    // }

    //for product image carousel
    //for first active image
    // var activeitem = `<div class="carousel-item active ">
    //     <img src="${productImageArrayy[1]}" class="d-block " alt="" width="100%">
    //     </div>`;

    // $(".productImageCarouselActiveItem").append(activeitem);

    //for remaining images
    // productImageArrayy.forEach((productImageArrayy) => {
    //   var item = `
    //     <div class="carousel-item">
    //         <img src="${productImageArrayy}" class="d-block " alt="" width="100%">
    //     </div>`;
    //   $(".productImageCarousel").append(item);
    // });

    //product iamge end
  };

  productImageCarousel = (ImageUrl) => {
    console.log(ImageUrl);
    var ImageUrlSplit = ImageUrl.split("/");
    var newUrl = "";
    for (let i = 0; i < ImageUrlSplit.length - 1; i++) {
      newUrl += ImageUrlSplit[i] + "/";
    }

    console.log(newUrl);
    //getting carousel of images of product
    $.ajax({
      url: baseURL + "api/customer/Account/imageCount",
      type: "POST",
      async: false,
      data: {
        Image_URL: newUrl
      },
      success: async (result) => {
        console.log("getting count");
        console.log(result);

        var screenWidth = $(window).width();
        if (screenWidth < 576) {
          $(".moreImagesMobile").removeClass("d-none");
        } else {
          $(".moreImagesDesktop").removeClass("d-none");
        }
        var productS3Img1 = await s3Object.getObjectURL(newUrl + "01.png");
        $(".productImagesCarousel").append(
          `<div class="carousel-item active" data-bs-interval="5000">
            <img src="${productS3Img1}" class="d-block w-100" alt="Vendor Images">
        </div>`
        );
        for (var image = 2; image <= result; image++) {
          var productS3Img1 = await s3Object.getObjectURL(
            newUrl + `0${image}.png`
          );
          $(".productImagesCarousel").append(
            `<div class="carousel-item" data-bs-interval="5000">
              <img src="${productS3Img1}" class="d-block w-100" alt="Vendor Images">
          </div>`
          );
        }

        // //vendor images s3 bucket
        // for (var j = 1; j <= result; j++) {
        //   businessImageArray[j - 1] =
        //     S3BaseURL +
        //     res.vendor.Image_URL +
        //     "/Document+Images/Business+Image" +
        //     j +
        //     ".png?" +
        //     Math.random();
        // }
        // console.log(businessImageArray);

        // //vendorimages
        // if (result == 1) {
        //   $(".vendorMainImage").attr("src", `${businessImageArray[0]}`);
        //   $(".vendorSecondImage").attr(
        //     "src",
        //     `${S3BaseURLProd + "/" + res.products[0].Image_URL}`
        //   );
        //   $(".vendorThirdImage").attr(
        //     "src",
        //     `${S3BaseURLProd + "/" + res.products[1].Image_URL}`
        //   );
        //   $(".imagesCount").hide();
        // } else if (result == 2) {
        //   $(".vendorMainImage").attr("src", `${businessImageArray[0]}`);
        //   $(".vendorSecondImage").attr("src", `${businessImageArray[1]}`);
        //   $(".vendorThirdImage").attr(
        //     "src",
        //     `${S3BaseURLProd + "/" + res.products[0].Image_URL}`
        //   );
        //   $(".imagesCount").hide();
        // } else {
        //   $(".vendorMainImage").attr("src", `${businessImageArray[0]}`);
        //   $(".vendorSecondImage").attr("src", `${businessImageArray[1]}`);
        //   $(".vendorThirdImage").attr("src", `${businessImageArray[2]}`);
        //   //image count
        //   // $(".imagesCount").html(`+${res.vendorImages.length - 3}`);
        // }

        // // //for vendor image carousel
        // // //for first active image
        // var activeitem = `<div class="carousel-item active ">
        //   <img src="${businessImageArray[1]}" class="d-block " alt="" width="100%">
        //   </div>`;

        // $(".vendorImageCarouselActiveItem").append(activeitem);

        // //for remaining images
        // businessImageArray.forEach((businessImageArray) => {
        //   var item = `
        //   <div class="carousel-item">
        //       <img src="${businessImageArray}" class="d-block " alt="" width="100%">
        //   </div>`;
        //   $(".vendorImageCarousel").append(item);
        // });

        // //image end
      },
      error: (result) => {
        console.log(result.message);
      }
    });

    //ending carousel of images of vendor
  };

  //this is to add/update cart in db
  addtoCart = (product, type) => {
    console.log(product);
    console.log(vendor);

    this.noOfProductsInCart = CartObject.cartCheck();

    //Check if ORDER_ID session cookie exists
    var checkOrderID = UtilObject.getAllCookies();
    console.log(checkOrderID);
    if (!checkOrderID.hasOwnProperty("ORDER_ID")) {
      $.ajax({
        url: baseURL + "api/customer/CustomerCookies/setRandomSessionId",
        type: "POST",
        async: false,
        data: {
          name: "ORDER_ID"
        },
        success: (result) => {
          console.log(result);
        },
        error: (err) => {
          console.log(err);
        }
      });
    }

    $.ajax({
      url: baseURL + "api/customer/Cart/AddtoCart",
      type: "POST",
      async: false,
      data: {
        Vendor_ID: vendor.Vendor_ID,
        Product_ID: product.Product_ID,
        Quantity: product.Quantity,
        Type: type,
        Total_Items: this.noOfProductsInCart.Products.length
      },
      headers: { Authorization: X_API_KEY },
      success: (result) => {
        console.log(result);
        $(`.addToCartProductSummary`).replaceWith(
          `<button type="button" class="btn btn-sm btn-block text-white removeFromCart" style="background: rgba(244, 148, 49);width:70%">Remove from Cart</button>`
        );
        $(".removeFromCart").off("click");
        $(".removeFromCart").on("click", this.removeProductFromCart);

        $("button.buyNow").hide();
        this.refreshCartCount();
        itemExistsInCart = true;
      },
      error: (err) => {
        console.log(err);
        var errorData = err.responseJSON;
        console.log(errorData);
        $(".addToCartErrorMessage").html(errorData.Msg);
        window.$("#addToCartError").modal("show");
        if (errorData.code == "12") {
          $(".productsAvailable").addClass("d-none");
          $(".productsNotAvailable").removeClass("d-none");
        }
        this.refreshCartCount();
      }
    });
    this.refreshCartCount();
  };

  addNewitemToCart = () => {
    var currentQuantity = $(".qntyTextField").val();
    currentQuantity = parseInt(currentQuantity);
    console.log(vendorFromOrder);
    console.log(vendor);
    if (vendorFromOrder != null) {
      if (vendorFromOrder.Vendor_ID != vendor.Vendor_ID) {
        p.Product_ID = this.Product_ID;
        p.Quantity = currentQuantity;
        p.Total_Items = this.noOfProductsInCart.Products.length;
        console.log(p);
        $("#showCartModal").show();
      } else {
        p.Product_ID = this.Product_ID;
        p.Quantity = currentQuantity;
        p.Total_Items = this.noOfProductsInCart.Products.length;
        console.log(p);
        this.addtoCart(p, "ADD");
      }
    } else {
      p.Product_ID = this.Product_ID;
      p.Quantity = currentQuantity;
      p.Total_Items = this.noOfProductsInCart.Products.length;
      console.log(p);
      this.addtoCart(p, "ADD");
    }
    this.refreshCartCount();
  };

  addNewitemToCartBuyNow = () => {
    var currentQuantity = $(".qntyTextField").val();
    currentQuantity = parseInt(currentQuantity);
    console.log(vendorFromOrder);
    console.log(vendor);
    if (vendorFromOrder != null) {
      if (vendorFromOrder.Vendor_ID != vendor.Vendor_ID) {
        p.Product_ID = this.Product_ID;
        p.Quantity = currentQuantity;
        p.Total_Items = this.noOfProductsInCart.Products.length;
        console.log(p);
        $("#showCartModal").show();
      } else {
        p.Product_ID = this.Product_ID;
        p.Quantity = currentQuantity;
        p.Total_Items = this.noOfProductsInCart.Products.length;
        console.log(p);
        this.addtoCartBuyNow(p, "ADD");
      }
    } else {
      p.Product_ID = this.Product_ID;
      p.Quantity = currentQuantity;
      p.Total_Items = this.noOfProductsInCart.Products.length;
      console.log(p);
      this.addtoCartBuyNow(p, "ADD");
    }
    this.refreshCartCount();
  };

  addtoCartBuyNow = (product, type) => {
    console.log(product);
    console.log(vendor);

    this.noOfProductsInCart = CartObject.cartCheck();

    //Check if ORDER_ID session cookie exists
    var checkOrderID = UtilObject.getAllCookies();
    console.log(checkOrderID);
    if (!checkOrderID.hasOwnProperty("ORDER_ID")) {
      $.ajax({
        url: baseURL + "api/customer/CustomerCookies/setRandomSessionId",
        type: "POST",
        async: false,
        data: {
          name: "ORDER_ID"
        },
        success: (result) => {
          console.log(result);
        },
        error: (err) => {
          console.log(err);
        }
      });
    }

    $.ajax({
      url: baseURL + "api/customer/Cart/AddtoCart",
      type: "POST",
      async: false,
      data: {
        Vendor_ID: vendor.Vendor_ID,
        Product_ID: product.Product_ID,
        Quantity: product.Quantity,
        Type: type,
        Total_Items: this.noOfProductsInCart.Products.length
      },
      headers: { Authorization: X_API_KEY },
      success: (result) => {
        console.log(result);
        $(`.addToCartProductSummary`).replaceWith(
          `<button type="button" class="btn btn-sm btn-block text-white removeFromCart" style="background: rgba(244, 148, 49);width:70%">Remove from Cart</button>`
        );
        $(".removeFromCart").off("click");
        $(".removeFromCart").on("click", this.removeProductFromCart);

        $("button.buyNow").hide();
        this.refreshCartCount();
        itemExistsInCart = true;
        window.location = baseURL + "customer/cart";
      },
      error: (err) => {
        console.log(err);
        var errorData = err.responseJSON;
        console.log(errorData);
        $(".addToCartErrorMessage").html(errorData.Msg);
        window.$("#addToCartError").modal("show");
        if (errorData.code == "12") {
          $(".productsAvailable").addClass("d-none");
          $(".productsNotAvailable").removeClass("d-none");
        }
        this.refreshCartCount();
      }
    });
    this.refreshCartCount();
  };

  deleteEntireCart = () => {
    console.log(CartDetails);
    console.log(CartDetails["ORDER_ID"]);
    $.ajax({
      url: baseURL + "api/customer/Cart/DeleteCart",
      type: "POST",
      async: false,
      data: {
        Order_ID: CartDetails["ORDER_ID"]
      },
      headers: { Authorization: X_API_KEY },
      success: (result) => {
        console.log(result);

        // //erasing the cookies
        // UtilObject.eraseCookie("ORDER_ID");
        // $.ajax({
        //   url: baseURL + "api/customer/CustomerCookies/unsetSessionValues",
        //   type: "POST",
        //   async: false,
        //   data: {
        //     unset: "ORDER_ID",
        //   },
        //   success: (result) => {
        //     console.log(result);
        //   },
        // });
      },
      error: (err) => {
        console.log(err);
      }
    });
    this.refreshCartCount();
  };
}
