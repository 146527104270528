//import '@googlemaps/js-api-loader';
// import "google-maps";

import Cropper from "cropperjs";

export default class vendor_image_cropperjs {
  constructor() {
    // Registering Events
    this.bindEvents();
    //this.geocodePosition();
    this.imagePreviewBOXID = "";
  }

  bindEvents = () => {
    cropperMain = this;
    // this.printCropperWorld();
    // this.doCroppingAction();
    console.log("Image cropper js file loaded");
    $("#cropSubmitbtn").on("click", this.cropperSubmit);
  };

  printCropperWorld = () => {
    console.log("printCropperWorld");
  };

  doCroppingAction = () => {
    console.log("doCroppingAction");

    var bs_modal = $("#modalCropper");
    var image = document.getElementById("image");
    var reader, file;
    var imagePreviewBoxId;

    // $("body").on("change", ".imageCropInput", (e) => {
    $(".imageCropInput").on("change", (e) => {
      console.log(e);
      imagePreviewBoxId = e.target.defaultValue;
      console.log(e.target.files);
      console.log(e.target.files.length);
      if (e.target.files.length == 0) {
        return;
      }

      //Check if the file is image fill. if not then stop executing the function
      var imgExpression = /.*\.(jpg|jpeg|png|JPG|JPEG|PNG)$/;
      if (!e.target.files[0]["name"].match(imgExpression)) {
        console.log("File to be cropped is not an immage");
        return;
      }

      var files = e.target.files;
      var done = (url) => {
        image.src = url;
        window.$("#modalCropper").modal("show");
      };

      if (files && files.length > 0) {
        file = files[0];
        if (file === undefined) {
          console.log("undefined file is tried to crop");
        }

        if (URL) {
          done(URL.createObjectURL(file));
        } else if (FileReader) {
          reader = new FileReader();
          reader.onload = (e) => {
            done(reader.result);
          };
          reader.readAsDataURL(file);
        }
      }
      //bs_modal.on("show",....)
      console.log("Displaying Content in cropper modal");
      cropper = new Cropper(image, {
        aspectRatio: 1,
        viewMode: 1,
        preview: $(".CropPreview"),
      });

      this.imagePreviewBOXID = imagePreviewBoxId;
      console.log("this.imagePreviewBOXID");
      console.log(this.imagePreviewBOXID);
    });

    // $("#cropSubmitbtn").on("click", () => {

    //   if (!imagePreviewBoxId) {
    //     console.log("No imagePreviewBoxId detected");
    //     cropper.destroy();
    //     // window.bs_modal.modal("hide");
    //     window.$("#modalCropper").modal("hide");

    //     return;
    //   }

    //   console.log(imagePreviewBoxId.length);
    //   if (imagePreviewBoxId.length > 2) {
    //     console.log("Performing crop button");
    //     console.log(cropper);
    //     var canvas = cropper.getCroppedCanvas({
    //       width: 426,
    //       height: 426,
    //     });

    //     canvas.toBlob((blob) => {
    //       var url = URL.createObjectURL(blob);
    //       var reader = new FileReader();
    //       reader.readAsDataURL(blob);
    //       reader.onloadend = () => {
    //         var base64data = reader.result;
    //         console.log(base64data);

    //         // $.ajax({
    //         //   type: "POST",
    //         //   url: baseURL + "api/Vendor/UploadVendorImage",
    //         //   data: {
    //         //     image: base64data,
    //         //   },
    //         //   success: (data) => {
    //         //     bs_modal.hide();
    //         //     // alert("success upload image");
    //         //     console.log(data);
    //         //     $.ajax({
    //         //       type: "POST",
    //         //       url: baseURL + "Vendor_details/upload",
    //         //       async: false,
    //         //       headers: { Authorization: X_API_KEY },
    //         //       data: { image: data },
    //         //       statusCode: {
    //         //         400: function () {
    //         //           alert("400 status code! user error");
    //         //         },
    //         //         500: function () {
    //         //           alert("500 status code! server error");
    //         //         },
    //         //       },
    //         //     });
    //         //   },
    //         // });
    //         cropper.destroy();
    //         // bs_modal.hide();
    //         // window.bs_modal.modal("hide");
    //         window.$("#modalCropper").modal("hide");
    //         // comment if above api is used
    //         // cropper = null;
    //         console.log(imagePreviewBoxId);
    //         document.getElementById(imagePreviewBoxId).src = base64data;
    //         document.getElementById(imagePreviewBoxId).value = 1;
    //         imagePreviewBoxId = "";
    //         // $(".image-preview-three").setAttribute("src", "base64data");
    //       };
    //     });
    //   }
    // });
  };
  cropperSubmit = async (res) => {
    if (!this.imagePreviewBOXID) {
      console.log("No this.imagePreviewBOXID detected");
      cropper.destroy();
      // window.bs_modal.modal("hide");
      window.$("#modalCropper").modal("hide");

      return;
    }

    console.log(this.imagePreviewBOXID.length);
    if (this.imagePreviewBOXID.length > 2) {
      console.log("Performing crop button");
      console.log(cropper);
      var canvas = cropper.getCroppedCanvas({
        width: 300,
        height: 300,
      });

      //To call compression function and obtain compressed image
      const base64dataCompressed = await this.compressAndGetDataUrl();
      console.log("base64dataCompressed", base64dataCompressed);

      console.log("blob");

      // console.log(blob);
      canvas.toBlob((blob) => {
        console.log("blob2");
        console.log(blob);

        var url = URL.createObjectURL(blob);
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          var base64data = reader.result;
          console.log("base64data", base64data);

          // $.ajax({
          //   type: "POST",
          //   url: baseURL + "api/Vendor/UploadVendorImage",
          //   data: {
          //     image: base64data,
          //   },
          //   success: (data) => {
          //     bs_modal.hide();
          //     // alert("success upload image");
          //     console.log(data);
          //     $.ajax({
          //       type: "POST",
          //       url: baseURL + "Vendor_details/upload",
          //       async: false,
          //       headers: { Authorization: X_API_KEY },
          //       data: { image: data },
          //       statusCode: {
          //         400: function () {
          //           alert("400 status code! user error");
          //         },
          //         500: function () {
          //           alert("500 status code! server error");
          //         },
          //       },
          //     });
          //   },
          // });
          cropper.destroy();

          // newImg.onload = () => {
          //   // no longer need to read the blob so it's revoked
          //   URL.revokeObjectURL(url);
          // };

          // bs_modal.hide();
          // window.bs_modal.modal("hide");
          window.$("#modalCropper").modal("hide");
          // comment if above api is used
          // cropper = null;
          console.log(this.imagePreviewBOXID);
          // document.getElementById(this.imagePreviewBOXID).src = base64data; // was used befor compression code was implemented
          document.getElementById(this.imagePreviewBOXID).src = base64dataCompressed;
          document.getElementById(this.imagePreviewBOXID).value = 1;
          this.imagePreviewBOXID = "";
          // $(".image-preview-three").setAttribute("src", "base64data");
        };
      });
    }
    // URL.revokeObjectURL(url);
    const context = canvas.getContext("2d");
    // Clear the canvas
    context.clearRect(0, 0, canvas.width, canvas.height);
    console.log("Performing crop button Exit");

  };

  // Function to compress and get data URL
  compressAndGetDataUrl = () => {
    const canvas = cropper.getCroppedCanvas({ width: 300, height: 300 }); // Set the desired width and height
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      }, 'image/jpeg', 0.8); // Set the desired image quality (0.8 means 80% quality)
    });
  }

}
