import s3Util from "../helpers/s3Util";
var s3ProfileObject = new s3Util();
export default class vendorServicing_navbar {
  constructor() {
    this.bindEvents();
    this.load_vendor_Details();

    // this.showAllPages();
  }

  bindEvents = () => {
    console.log("Vendor Navigation bar js new");

    var $homeIcon = $("#sidebarMenu");

    if (window.innerWidth <= 1000) $homeIcon.removeClass("show");
    else $homeIcon.addClass("show");

    $(".vendor-nav-option").on("click", this.sidebarFunctionVendor);
    this.sidebarFunctionVendor();

    //Function executed when window is resized 
    $(window).resize(() => {
      this.reAdjustSideBar();
    });
  };

  load_vendor_Details = () => {
    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/getVendorDetails",
      headers: { Authorization: X_API_KEY },
      success: this.show_vendor_nav_details,
    });
  };

  show_vendor_nav_details = async (res) => {
    // $("#inputBusinessName").attr(res.Business_Name);
    // document.getElementById("inpBusinessImg").fileLabel = "";

    // document.getElementById("vendor_nav_image").src =S3BaseURL + res.Image_URL + BusinessImageURL + Math.random();
    document.getElementById("vendor_nav_image").src = await s3ProfileObject.getObjectURL("Vendor/" + res.Image_URL + "/Document Images/Business Image/1.png");
    // document.getElementById("vendor_nav_name").value = res.Business_Name;
    var desktopVendorName = res.Business_Name;
    if (desktopVendorName.length > 10) {
      desktopVendorName = desktopVendorName.substring(0, 8) + "..";
    }
    // $(".accountNameDesktop").html(
    //   `<a href="${baseURL}customer/account" class="truncate-account-name-desktop" style="color:black;">${desktopName}</a>`
    // );
    $("#vendor_nav_name").html(`${desktopVendorName}`);

    // document.getElementById("inputBusinessName").value = res.Business_Name;
  };

  reAdjustSideBar = () => {
    //checking the size of the screen
    var screenWidthForLeftNav = $(window).width();
    console.log("screenWidthForLeftNav reAdjustSideBar", screenWidthForLeftNav);
    if (screenWidthForLeftNav <= 991) {
      //show mobile view
      $("#sidebarMenu").removeClass("show");
    } else {
      $("#sidebarMenu").addClass("show");
    }
  }

  sidebarFunctionVendor = (event) => {
    this.reAdjustSideBar();
    console.log("IS IT ON CLICK");
    console.log(event);
    //Got to dashboard if on some other URL after selecting an option in left nav
    const queryString = window.location.pathname;
    var queryStringURL = queryString;
    queryStringURL = queryStringURL.substring(queryStringURL.indexOf("/") + 1);
    queryStringURL = queryStringURL.substring(queryStringURL.indexOf("/") + 1);
    console.log("queryStringURL", queryStringURL);
    if (
      event != undefined &&
      queryStringURL != "Vendor_servicing_dashboard"
    ) {
      //Redirect to Dashboard
      console.log("Redirect to Dashboard");

      window.location.href = "Vendor_servicing_dashboard";
    }

    console.log(event);
    // var activeEvent = $("#sidebarMenu").find(".active");
    // console.log(activeEvent);
    // //add and remove active class from sidebar (causes darkening if the text)

    // activeEvent[0].classList.remove("active");

    //If page is loaded first time show Dashboard
    if (document.getElementsByClassName("currentlyActive").length == 0) {
      $(".VendorDashboardPage").addClass("currentlyActive");
      $(".VendorDashboardPage").removeClass("d-none");
      return;
    }

    //get the page to be shown
    var page = event.currentTarget.classList[1];
    console.log(page);

    if (page == "nav-account" || page == "collapsed") {
      event.currentTarget.classList.add("active");
    } else {
      event.currentTarget.classList.add("active");
      $(".currentlyActive").addClass("d-none");
      $(".currentlyActive").removeClass("currentlyActive");
      // $("#sidebarMenu").removeClass("show");
    }
    if (page == "vendor-Dashboard") {
      $(".VendorDashboardPage").addClass("currentlyActive");
      $(".VendorDashboardPage").removeClass("d-none");
    } else if (page == "vendor-orders") {
      $(".vendororders").addClass("currentlyActive");
      $(".vendororders").removeClass("d-none");
    } else if (page == "vendor-profile") {
      $(".vendor_name_edit_page").addClass("currentlyActive");
      $(".vendor_name_edit_page").removeClass("d-none");
    } else if (page == "vendor-address") {
      $(".VendorAddressDiv").addClass("currentlyActive");
      $(".VendorAddressDiv").removeClass("d-none");
    } else if (page == "vendor-security") {
      $(".VendorAccountSecurity").addClass("currentlyActive");
      $(".VendorAccountSecurity").removeClass("d-none");
    } else if (page == "vendor-document") {
      $(".vendorServicingDocuments").addClass("currentlyActive");
      $(".vendorServicingDocuments").removeClass("d-none");
    } else if (page == "vendor-billing") {
      $(".vendor_billing_desktop").addClass("currentlyActive");
      $(".vendor_billing_desktop").removeClass("d-none");
    } else if (page == "vendor-categories") {
      $(".Vendor_category_speciality_details_page").addClass("currentlyActive");
      $(".Vendor_category_speciality_details_page").removeClass("d-none");
    } else if (page == "vendor-inventory") {
      $(".vendor_servicing_product_listing").addClass("currentlyActive");
      $(".vendor_servicing_product_listing").removeClass("d-none");
    } else if (page == "vendor-delivery-options") {
      $(".deliveryDetailsPage").addClass("currentlyActive");
      $(".deliveryDetailsPage").removeClass("d-none");
    } else if (page == "vendor-gst") {
      $(".GSTDetails-page").addClass("currentlyActive");
      $(".GSTDetails-page").removeClass("d-none");
    } else if (page == "vendor-calender") {
      $(".vendorCalenderPage").addClass("currentlyActive");
      $(".vendorCalenderPage").removeClass("d-none");
    } else if (page == "vendor-help") {
      $(".vendor_Help_Page").addClass("currentlyActive");
      $(".vendor_Help_Page").removeClass("d-none");
    } else if (page == "vendor-payments") {
      $(".vendorPoints").addClass("currentlyActive");
      $(".vendorPoints").removeClass("d-none");
    } else if (page == "vendor-payment-history") {
      $(".vendor_servicing_payment").addClass("currentlyActive");
      $(".vendor_servicing_payment").removeClass("d-none");
    } else if (page == "vendor-email-reports") {
      $(".vendor_email_report").addClass("currentlyActive");
      $(".vendor_email_report").removeClass("d-none");
    } else if (page == "vendor-paid-promotion") {
      $(".vendor_servicing_pricing").addClass("currentlyActive");
      $(".vendor_servicing_pricing").removeClass("d-none");
    } else if (page == "vendor-logout") {
      this.redirectToLogInVendor();
    }
  };

  showAllPages = () => {
    $(".VendorDashboardPage").removeClass("d-none");
    $(".vendororders").removeClass("d-none");
    $(".vendor_name_edit_page").removeClass("d-none");
    $(".VendorAddressDiv").removeClass("d-none");
    $(".VendorAccountSecurity").removeClass("d-none");
    $(".vendorServicingDocuments").removeClass("d-none");
    $(".vendor_billing_desktop").removeClass("d-none");
    $(".Vendor_category_speciality_details_page").removeClass("d-none");
    $(".vendor_servicing_product_listing").removeClass("d-none");
    $(".deliveryDetailsPage").removeClass("d-none");
    $(".GSTDetails-page").removeClass("d-none");
    $(".vendorCalenderPage").removeClass("d-none");
    $(".vendor_Help_Page").removeClass("d-none");
    $(".vendorPoints").removeClass("d-none");
    $(".vendor_email_report").removeClass("d-none");
    $(".vendor_servicing_pricing").removeClass("d-none");
  };

  redirectToLogInVendor = () => {
    console.log("in redirectToLogInVendor");
    // UtilObject.eraseCookie("CUSTOMER_ID");
    $.ajax({
      url: baseURL + "api/vendor/unsetSessionValuesVendor",
      type: "POST",
      async: false,
      data: {
        unset: "Vendor_ID",
      },
      success: (result) => {
        console.log(result);
        window.location = baseURL + "Vendor_login";
      },
    });
  };
}
