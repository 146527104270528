import { timers } from "jquery";
import "owl.carousel";

import cartUtil from "../helpers/cartUtil";
import Util from "../helpers/util";
import favUtil from "../helpers/favutil";
var CartObject = new cartUtil();
var UtilObject = new Util();
var favObject = new favUtil();

var spacing = "mb-3";
let categories;
var customerFavList = 0;

//variable for type of the screen
var deviceTypeHomePage;

import s3Util from "../helpers/s3Util";
var s3Object = new s3Util();

//Loading and using Google maps helper functions
import GoogleMapsApi from "../helpers/GoogleMapsApi";
var GoogleMapsApiObjectVendor = new GoogleMapsApi();

export default class HomePage {
  constructor() {
    //HomePage Part
    this.grid = $(".homePage");
    this.filterBtn = $(".filterBtn");

    var allCookies = UtilObject.getAllCookies();
    console.log(allCookies);

    //checking the size of the screen
    var screenWidthHp = $(window).width();
    console.log(screenWidthHp);

    if (screenWidthHp < 768) {
      //show mobile view
      console.log("mobile width....");
      deviceTypeHomePage = "mobile";
      $(".homePageMobile").removeClass("d-none");
    } else {
      deviceTypeHomePage = "desktop";
      $(".homePageDesktop").removeClass("d-none");
    }

    //Registering Events
    this.bindEvents();
  }

  bindEvents = () => {
    // var w = $(window).width();
    // console.log(w);

    // if (w < 746) {
    //   this.offerwritten.removeClass("offerhover");
    // } else {
    //   this.offerwritten.addClass("offerhover");
    // }
    //Getting global from app.js
    console.log(baseURL);
    //console.log(LAT);
    //console.log(LNG);
    console.log(BUFFER);
    console.log(addressDetails);

    console.log("executing load functions");

    console.log("done executing load functions");

    console.log(addressDetails);

    $(window).on("load", $.ready.then(this.calOwlCarousel));
    $(window).on("load", $.ready.then(this.getOffers));
    $(window).on("load", $.ready.then(this.getCategories));
    $(window).on("load", $.ready.then(this.getVendorByRating));
    $(window).on("load", $.ready.then(this.getProductByRating));

    //onclick filter buttons
    $(".clear-Filter").on("click", this.clearAllFilter);
    this.filterBtn.on("click", this.filterOptions);

    //clear filter
    $(".clearFilterHomepage").on("click", this.clearHomepageFilter);
  };

  calOwlCarousel = () => {
    console.log("inside");
    $(".carousel_se_01_carousel").owlCarousel({
      nav: false,
      dots: false,
      mouseDrag: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 3,
          slideBy: 3
        },
        480: {
          items: 3,
          slideBy: 3
        },
        767: {
          items: 4,
          slideBy: 4
        },
        992: {
          items: 5,
          slideBy: 5
        },
        1200: {
          items: 6,
          slideBy: 6
        }
      }
    });

    $(".carousel_se_02_carousel").owlCarousel({
      nav: false,
      mouseDrag: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          slideBy: 1
        },
        480: {
          items: 1,
          slideBy: 1
        },
        767: {
          items: 2,
          slideBy: 2
        },
        992: {
          items: 3,
          slideBy: 3
        },
        1200: {
          items: 4,
          slideBy: 4
        }
      }
    });

    //For Offers
    $(".carousel_se_03_carousel").owlCarousel({
      nav: false,
      rewind: false,
      dots: false,
      autoplay: true,
      autoplayTimeout: 5000,
      smartSpeed: 1500,
      animateIn: "linear",
      animateOut: "linear",
      autoplayHoverPause: true,
      loop: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1.1,
          slideBy: 1
        },
        480: {
          items: 2,
          slideBy: 2
        },
        600: {
          items: 2,
          slideBy: 2,
          stagePadding: 50
        },
        767: {
          items: 3,
          slideBy: 3
        },
        992: {
          items: 3,
          slideBy: 3
        },
        1200: {
          items: 3,
          slideBy: 2
        }
      }
    });
  };

  getVendorByRating = () => {
    $.ajax({
      url: baseURL + "api/customer/Homepage/vendorByRating",
      type: "GET",
      async: false,
      headers: { Authorization: X_API_KEY },
      success: this.showVendorsOnHomepage,
      error: (result) => {
        console.log(result);
      }
    });
  };

  //name the parameters properly
  showVendorsOnHomepage = (result) => {
    console.log(result);
    if (deviceTypeHomePage == "desktop") {
      var len = $(".owl-carousel.top-vendors-items").find(".owl-item").length;
      console.log(len);
      for (var i = 0; i < len; i++) {
        $(".owl-carousel.top-vendors-items").owlCarousel("remove", 0);
      }
    }
    result.forEach(async (element) => {
      var vendorImg = await s3Object.getObjectURL(
        "Vendor/" + element.Image_URL + "/Document Images/Business Image/1.png"
      ); //element.Image_URL

      var item = `<div class="item" >
      <div class="col-md-12 wow fadeInUp delay-2 d-flex justify-content-center">
        <div class="text-center zoom">
          <a class="btn-light rounded-circle shadow" href="${baseURL}customer/productListing/${
        element.Vendor_URL
      }" style="margin-top:0.5rem;">
            <img class="rounded-4" src="${new URL(vendorImg)}" alt="${
        element.Business_Name
      }-Image" width="20px"> 
          </a>
          <h6 class="truncate pt-2">${element.Business_Name}</h6>
        </div>
      </div>
    </div>`;

      $(".owl-carousel.top-vendors-items")
        .owlCarousel("add", item)
        .owlCarousel("update");
    });
  };

  getCategories = () => {
    $.ajax({
      url: baseURL + "api/customer/Homepage/getAllCategory",
      type: "GET",
      async: false,
      headers: { Authorization: X_API_KEY },
      success: this.showCategoriesOnHomepage,
      error: (result) => {
        console.log(result);
      }
    });
  };

  //use query parameter to pass to php controller
  showCategoriesOnHomepage = (result) => {
    console.log("all categories to show", result);

    //fav check
    this.checkFavourites();

    console.log(result);
    categories = result;
    result.forEach(async (element) => {
      console.log("all categories to show", element);
      var categoryImg = await s3Object.getObjectURL(element.Icon_Url); //element.Icon_Url

      var item = `<div class="item" >
      <div class="col-md-12 wow fadeInUp delay-2 d-flex justify-content-center">
        <div class="text-center zoom">
          <a class="btn-light rounded-circle " href="${baseURL}customer/vendorListingPage/${
        element.Picklist_display_value
      }" rel="noopener " style="margin-top:0.5rem;">
            <img src="${new URL(
              categoryImg
            )}" class="img-fluid rounded-4" alt="${
        element.Picklist_display_value
      }-icon">
          </a>
          <h6 class="truncate pt-2">${element.Picklist_display_value}</h6>
        </div>
      </div>
    </div>`;

      $(".owl-carousel.top-categories-items")
        .owlCarousel("add", item)
        .owlCarousel("update");
    });
    //calling near you vendors on homepage
    console.log("near you from categories");
    this.nearYouCategoriesVendor();
  };

  nearYouCategoriesVendor = () => {
    console.log("running near you section");
    console.log($(".near-you-section"));
    $(".near-you-section").empty();
    //all-near-you-categories-vendor
    categories.forEach((category) => {
      $.ajax({
        url: baseURL + "api/customer/VendorsList/getVendorByCategoryLimit",
        type: "POST",
        async: false,
        data: {
          buffer: BUFFER,
          lat: LAT,
          lon: LNG,
          Picklist_ID: category.Picklist_ID
        },
        headers: { Authorization: X_API_KEY },
        success: (res) => {
          console.log(res);
          if (res.NearMeCount != 0) {
            var vendorIDs = [];
            var tempObj = {};
            res.NearMe.forEach((element) => {
              tempObj = {
                lat: element.lat,
                lng: element.lon,
                title: element.Business_Name
              };
              vendorIDs.push(tempObj);
            });

            // Convert JSON object array to string
            var vendorIDsString = vendorIDs
              .map((obj) => {
                return JSON.stringify(obj);
              })
              .join(",");

            if (deviceTypeHomePage == "desktop") {
              //near you
              var parentItem = `<section class="carousel_se_02 ${res.NearMe[0].Picklist_ID}-near-you pt-1">
                  <div class="container-fluid">
                      <div class="row">
                        <div class="row m-0">
                          <div class="col-10 p-0">
                            <h3 class="col homepage-header-text">
                            ${res.NearMe[0].Picklist_display_value}
                            </h3>
                          </div>
                          <div class="col-2 p-0">
                            <button type="button" class="mapMarkerBtnClass-${res.NearMe[0].Picklist_ID} btn btn-light p-0 m-0 h-100 w-100 " data-vendorsdata='${vendorIDsString}'>
                              View Map
                            </button>
                          </div>                        
                        </div>
                          <div class="col-md-12  pt-1">
                              <div class="owl-carousel owl-theme ${res.NearMe[0].Picklist_ID}-near-you-items">

                              </div>
                          </div>
                      </div>
                      <div class="row">
                      <div class="d-flex justify-content-center col">
                      <a class="text-warning" href="${baseURL}customer/vendorListingPage/${category.Picklist_display_value}">View more</a></div>
                      </div>
                  </div>
              </section>`;
              $(".near-you-section").append(parentItem);
              $(`.${res.NearMe[0].Picklist_ID}-near-you-items`).owlCarousel({
                nav: true,
                mouseDrag: true,
                responsiveClass: true,
                responsive: {
                  0: {
                    items: 1
                  },
                  480: {
                    items: 1
                  },
                  767: {
                    items: 2
                  },
                  992: {
                    items: 3
                  },
                  1200: {
                    items: 4
                  }
                }
              });

              res.NearMe.forEach(async (element) => {
                var vendorImg = await s3Object.getObjectURL(
                  "Vendor/" +
                    element.Image_URL +
                    "/Document Images/Business Image/1.png"
                ); //element.Image_URL

                var favItem = this.checkFavourites("check", element.Vendor_ID);

                var className = element.Picklist_ID;
                var sponsoredContent = "";

                //for vendor review stars
                if (element.Vendor_Rating > 0) {
                  var color = "background: rgba(244, 148, 49);";
                }

                if (element.isSponsored > 0) {
                  sponsoredContent = `<div class="mt-4 pt-1 fixed-top">
                                          <h5 class="text-white ml-3 w-50 shadow rounded" style="background: rgba(244, 148, 49);">Sponsored</h5>
                                      </div>`;
                }

                //Adding near you items vendorsDesktop

                var item = `<div class="item zoom d-flex justify-content-around h-100">
                              <a href="${baseURL}customer/productListing/${
                  element.Vendor_URL
                }" rel="noopener " style="margin-top:0.5rem;">
                              <div class=" wow fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;">
                                <div class="w-100 rounded text-center favourites-container">
                                <img src="${new URL(
                                  vendorImg
                                )}" class="rounded shadow-lg" alt="${
                  element.Business_Name
                }-Image">
                                </a>
                                ${sponsoredContent}
                                ${favItem}
                                </div>
                                <a href="${baseURL}customer/productListing/${
                  element.Vendor_URL
                }" rel="noopener " style="margin-top:0.5rem;">
                                <div class="stats mt-2">

                                  <div class="row d-flex justify-content-between ${spacing}">
                                    <div class="col-sm-12 truncate">
                                    <div class="row">
                                      <div class="col-11"><h6><strong>${
                                        element.Business_Name
                                      }</strong></h6></div>
                                      <div class="col-1 p-0">
                                        <button  type="button" class="mapMarkerBtnClass-${
                                          element.Vendor_ID
                                        } btn p-0 m-0 h-100 w-100 " data-vendorid="${
                  element.Vendor_ID
                }">
                                          <i class="fas fa-map-marker-alt ml-1"></i></button>
                                      </div>
                                    </div>
                                    <h6>${element.City}</h6>
                                    </div>
                                  </div>
                                  <div class="row d-flex justify-content-between">
                                    <div class="col-sm-12 truncate-one">
                                    ${element.Specialities}
                                    </div>
                                  </div>
                                  <div class="row ">
                                  <div class="col pr-0 d-flex justify-content-start">
                                          <p class="mb-0 pt-2">Min Order:₹${
                                            element.Minimum_Order
                                          }</p>
                                          </div>
                                          <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                                            <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${
                  element.Vendor_Rating
                } <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                                          </div>
                                  </div>
                                </div>
                              </div>
                              </a>
                            </div>`;

                $(`.${className}-near-you-items`)
                  .owlCarousel("add", item)
                  .owlCarousel("update");
                $(".likeBtnHomepage").off("click");
                $(".likeBtnHomepage").on("click", this.setFavourites);
                $(`.mapMarkerBtnClass-${element.Vendor_ID}`).on(
                  "click",
                  (e) => {
                    GoogleMapsApiObjectVendor.openGMapModal(e);
                  }
                );
              });
              $(`.mapMarkerBtnClass-${res.NearMe[0].Picklist_ID}`).on(
                "click",
                (e) => {
                  GoogleMapsApiObjectVendor.openGMapModal(e);
                }
              );
            } else {
              //mobile view
              var parentItem = `
                                <section class="${res.NearMe[0].Picklist_ID}-near-you-mobile pt-1 ">
                                  <div class="">
                                      <div class="row">
                                        <div class="row m-0">
                                          <div class="col-10 p-0">
                                            <h3 class="col mt-3 homepage-header-text">
                                            ${res.NearMe[0].Picklist_display_value}
                                            </h3>
                                          </div>
                                          <div class="col-2 p-0">
                                            <button type="button" class="mapMarkerBtnClass-${res.NearMe[0].Picklist_ID} btn btn-light p-0 m-0 h-100 w-100 " data-vendorsdata='${vendorIDsString}'>
                                              View Map
                                            </button>
                                          </div>
                                        </div>
                                          <div class="col-md-12  pt-1">
                                              <div class="${res.NearMe[0].Picklist_ID}-near-you-items-mobile">

                                              </div>
                                          </div>
                                      </div>
                                      <div class="row mt-3">
                                      <div class="d-flex justify-content-center col">
                                      <a class="text-warning" href="${baseURL}customer/vendorListingPage/${category.Picklist_display_value}">View more</a></div>
                                      </div>
                                  </div>
                              </section>`;
              $(".near-you-section").append(parentItem);

              res.NearMe.forEach(async (element) => {
                var favItem = this.checkFavourites("check", element.Vendor_ID);

                var className = element.Picklist_ID;
                var sponsoredContent = "";
                var color = "";

                //for vendor review stars
                if (element.Vendor_Rating > 0) {
                  color = "background: rgba(244, 148, 49);";
                }

                if (element.isSponsored > 0) {
                  sponsoredContent = `<p class="carousel-caption text-white shadow rounded p-0 m-0" style="background: rgba(244, 148, 49);">Sponsored</p>`;
                }

                var vendorImg = await s3Object.getObjectURL(
                  "Vendor/" +
                    element.Image_URL +
                    "/Document Images/Business Image/1.png"
                ); //element.Image_URL

                var vendRatingScore = "";
                if (element.Vendor_Rating == null) {
                  vendRatingScore = 0;
                } else {
                  vendRatingScore = element.Vendor_Rating;
                }

                //             var item = `<div class="card mt-1">
                //     <div class="card-body" style="height:125px;">
                //         <div class="row">
                //             <div class="col-4 favourites-container">
                //             <a href="${baseURL}customer/productListing/${
                //               element.Vendor_URL
                //             }" rel="noopener " style="margin-top:0.5rem;">
                //                 <img src="${new URL(vendorImg)}" class="img-load" alt="${
                //               element.Business_Name
                //             }-Image">
                //             </a>
                //                 ${favItem}
                //                 ${sponsoredContent}
                //             </div>
                //             <a href="${baseURL}customer/productListing/${
                //               element.Vendor_URL
                //             }" class="col" rel="noopener " style="margin-top:0.5rem;">
                //             <div class="col">

                //                 <div class="row">
                //                     <div class="col">
                //                         <p class="mb-0 truncate-newHomePage"><b>${
                //                           element.Business_Name
                //                         }</b></p>
                //                         <p class="mb-0 truncate-newHomePage"><b>${
                //                           element.City
                //                         }</b></p>
                //                         <p class="mb-0 truncate-newHomePage">${
                //                           element.Specialities
                //                         }</p>
                //                     </div>
                //                 </div>
                //                 <div class="row">
                //                 <div class="col-9 pr-0">
                //                 <p class="mb-0 pt-1">Min Order:₹${element.Minimum_Order}</p>
                //                 </div>
                //                 <div class="col mb-2 px-0 d-flex justify-content-end">
                //                 <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${
                //               element.Vendor_Rating
                //             } <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                //                 </div>
                //                 </div>

                //             </div>
                //         </div>

                //     </div>
                //     </a>

                // </div>`;

                //vendorsMobile
                var item = `<div class="card my-2">
                              <div class="row">
                                  <div class="col-4 py-2 ml-2 pr-0 favourites-container" style="width:30%;">
                                      ${favItem}
                                      <a href="${baseURL}customer/productListing/${
                  element.Vendor_URL
                }" rel="noopener">
                                          <img src="${new URL(
                                            vendorImg
                                          )}" class="rounded-3 card-img-top"></img>
                                          ${sponsoredContent}
                                      </a>
                                  </div>
                                  <div class="col pl-0">
                                  <a href="${baseURL}customer/productListing/${
                  element.Vendor_URL
                }" rel="noopener">
                                          <div class="card-body h-100">
                                              <div class="row h-100 d-flex justify-content-between">
                                                  <div class="col-11 pr-0">
                                                      <h5 class="card-title truncate-newHomePage">${
                                                        element.Business_Name
                                                      }</h5>
                                                  </div>
                                                  <div class="col-1 p-0">
                                                    <button type="button" class="mapMarkerBtnClass-${
                                                      element.Vendor_ID
                                                    } btn p-0 m-0 h-100 w-100 " data-vendorid="${
                  element.Vendor_ID
                }">
                                                      <i class="fas fa-map-marker-alt ml-1"></i>
                                                    </button>
                                                  </div>
                                                  <div class="col-12">
                                                      <p class="truncate-newHomePage">${
                                                        element.City
                                                      }</p>
                                                  </div>
                                                  <div class="col-12">
                                                      <p class="truncate-newHomePage">${
                                                        element.Specialities
                                                      }</p>
                                                  </div>
                                                  <div class="col-6 align-items-end mb-0">Min Order:₹${
                                                    element.Minimum_Order
                                                  }</div>
                                                  <div class="col-6">
                                                      <div class="row">
                                                          <div class="col d-flex justify-content-end">
                                                              <div class="ratingBorder align-items-center d-inline-flex text-white mb-0 pl-2" style="${color}">
                                                                  <p class="mb-0">${vendRatingScore}</p> 
                                                                  <i class="fa fa-star ml-1" aria-hidden="true" style="color: white;"></i>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                          </div>`;

                $(`.${className}-near-you-items-mobile`).append(item);
                //unbinds any previous event handlers
                $(".likeBtnHomepage").off("click");
                $(".likeBtnHomepage").on("click", this.setFavourites);
                $(`.mapMarkerBtnClass-${element.Vendor_ID}`).on(
                  "click",
                  (e) => {
                    GoogleMapsApiObjectVendor.openGMapModal(e);
                  }
                );
              });
              $(`.mapMarkerBtnClass-${res.NearMe[0].Picklist_ID}`).on(
                "click",
                (e) => {
                  GoogleMapsApiObjectVendor.openGMapModal(e);
                }
              );
            }
          }
        }
      });
    });
  };

  getOffers = () => {
    console.log(BUFFER);
    $.ajax({
      url: baseURL + "api/customer/Homepage/getNearByOffers",
      type: "POST",
      async: false,
      data: {
        buffer: BUFFER,
        lat: LAT,
        lon: LNG
      },
      headers: { Authorization: X_API_KEY },
      success: this.showOffersOnHomepage,
      error: (result) => {
        console.log(result);
        $(".owl-carousel.offers-items").hide();
      }
    });
  };

  showOffersOnHomepage = (element) => {
    //Removing any previous offers
    $(".owl-carousel.offers-items").show();
    var len = $(".owl-carousel.offers-items").find(".owl-item").length;
    console.log(len);
    for (var i = 0; i < len; i++) {
      $(".owl-carousel.offers-items").owlCarousel("remove", 0);
    }
    console.log(element);

    var offerImage = "";
    element.forEach(async (element) => {
      console.log("homepage-offers", element);
      // src="${S3BaseURLProd}/${element.Image_URL}"
      //redirecting page
      var offerType = element.Go_To_Page;
      var page = `${baseURL}customer/allOffers`;
      if (offerType == "vendor") {
        page = `${baseURL}customer/productListing/${element.data}`;
      } else if (offerType == "category") {
        page = `${baseURL}customer/vendorListingPage/${element.data}`;
      }
      offerImage = await s3Object.getObjectURL(element.Image_Url); //element.Image_Url
      var item = `<div class="item">
      <div class="wow fadeInUp mb-5" style="background-color:white;">
        <a href="${page}" rel="noopener" style="margin-top:0.5rem;" class="homepage-offers">
          <img class="d-block mt-2 mb-2 zoom shadow" src="${new URL(
            offerImage
          )}" alt = "${element.Offer_Name}-Image" style="border-radius: 20px;"/>
          <div class="carousel-caption mb-5 d-md-block">
          <h6 class="homepage-offers-text">${element.Offer_Name}</h6>
        <div class="position-absolute w-100 rounded-4" style="inset:0px;height:85%;z-index:-10;background-color:#f49431;opacity:85%;"></div>
      </div>
        </a>
      </div>
      </div>`;

      $(".owl-carousel.offers-items")
        .owlCarousel("add", item)
        .owlCarousel("update");
    });
  };

  //top products
  getProductByRating = () => {
    $.ajax({
      url: baseURL + "api/customer/Homepage/productByRatingWithLocation",
      type: "POST",
      async: false,
      data: {
        buffer: BUFFER,
        lat: LAT,
        lon: LNG
      },
      headers: { Authorization: X_API_KEY },
      success: this.showProductsOnHomepage,
      error: (result) => {
        console.log(result);
      }
    });
  };

  showProductsOnHomepage = (event) => {
    console.log(event);

    //checking for any previous items
    if (deviceTypeHomePage == "desktop") {
      var len = $(".owl-carousel.top-products-items").find(".owl-item").length;
      console.log(len);
      for (var i = 0; i < len; i++) {
        $(".owl-carousel.top-products-items").owlCarousel("remove", 0);
      }
    } else {
      $(".top-products-items-mobile").empty();
    }

    var uniqueProductCategories = [];

    event.forEach(async (element) => {
      if (!uniqueProductCategories.includes(element["Picklist_ID"])) {
        uniqueProductCategories.push(element["Picklist_ID"]);
        if (deviceTypeHomePage == "desktop") {
          var sponsoredContent = "";
          // // servicename
          var prodServiceName = element.Product_Service_Name.toString();

          var prodServiceNameLength = prodServiceName.length;
          var addingSpaces = "";
          if (prodServiceNameLength <= 39) {
            for (var i = prodServiceNameLength; i <= 60; i++) {
              addingSpaces += "&nbsp";
            }
          }
          console.log(
            prodServiceName,
            prodServiceNameLength,
            addingSpaces.length
          );

          var prodBusinessName = element.Business_Name.toString();
          var prodBusinessCity = element.City.toString();
          var displayAddress = `${prodBusinessName}, ${prodBusinessCity}`;

          var prodAddressNameLength = displayAddress.length;
          var addingSpacesAddress = "";
          if (prodAddressNameLength <= 39) {
            for (var i = prodAddressNameLength; i <= 60; i++) {
              addingSpacesAddress += "&nbsp";
            }
          }
          console.log(
            displayAddress,
            prodAddressNameLength,
            addingSpacesAddress.length
          );

          //adding rating stars
          let productRating = element.Product_Review_Score;

          if (element.Product_Review_Score > 0) {
            var color = "background: rgba(244, 148, 49);";
          }

          if (element.isSponsored > 0) {
            sponsoredContent = `<div class="mt-4 pt-1 fixed-top">
          <h5 class="text-white ml-3 pl-2 w-50 shadow rounded" style="background: rgba(244, 148, 49);">Sponsored</h5>
      </div>`;
          }

          // // Product Item Code Change
          //     var item = `<div class="item zoom d-flex justify-content-around">
          //   <a href="${baseURL}customer/productSummary/${
          //       element.product_URL
          //     }" rel="noopener " style="margin-top:0.5rem;">
          //   <div class="wow productImageHp fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;" >
          //   ${sponsoredContent}
          //     <div class="rounded text-center"><img src="${new URL(
          //       productImgOnHome
          //     )}" class="rounded shadow-lg" alt="">
          //     </div>
          //     <div class="stats mt-2">
          //       <div class="row d-flex justify-content-between ${spacing}">
          //         <div class="col-sm-12 truncate">
          //         <h6><strong>${element.Product_Service_Name}</strong></h6>
          //         </div>
          //       </div>
          //       <div class="row d-flex justify-content-between">
          //         <div class="col-sm-12 truncate-one">
          //         ${element.Business_Name}
          //         </div>
          //       </div>
          //       <div class="row">
          //         <div class="col-sm-12 truncate-one">
          //           ${element.City}
          //         </div>
          //       </div>
          //       <div class="row">
          //       <div class="col pr-0 d-flex justify-content-start">
          //         <p class="mb-0 pt-2">₹${element.Price}</p>
          //         </div>
          //         <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
          //           <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${
          //       element.Product_Review_Score
          //     } <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          //   </a>
          // </div>`;

          //productsDesktop

          //Show 1 product per category

          var item = `<div class="item zoom d-flex justify-content-around h-100">
    <a href="${baseURL}customer/productSummary/${element.product_URL}" rel="noopener " style="margin-top:0.5rem;">
    <div class=" wow fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;">
      <div class="w-100 rounded text-center favourites-container">
      <img src="" class="rounded shadow-lg ${element.Product_ID}-topProdImg" alt="">
      </a>
      ${sponsoredContent}
      </div>
      <a href="${baseURL}customer/productSummary/${element.product_URL}" rel="noopener " style="margin-top:0.5rem;">
      <div class="stats mt-2">

        <div class="row d-flex justify-content-between">
          <div class="col-sm-12 truncate-three">
          <h6 class="truncate-two"><strong>${prodServiceName} ${addingSpaces}</strong></h6>
          <h6 class="truncate-two">${displayAddress} ${addingSpacesAddress}</h6>
          </div>
        </div>
        <div class="row ">
        <div class="col pr-0 d-flex justify-content-start">
                <p class="mb-0 pt-2">₹${element.Price}</p>
                </div>
                <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                  <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${element.Product_Review_Score} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                </div>
        </div>
      </div>
    </div>
    </a>
  </div>`;

          $(".owl-carousel.top-products-items")
            .owlCarousel("add", item)
            .owlCarousel("update");
        } else {
          var sponsoredContent = "";
          //adding rating stars
          if (element.Product_Review_Score > 0) {
            var color = "background: rgba(244, 148, 49);";
          }
          if (element.isSponsored > 0) {
            sponsoredContent = `<p class="carousel-caption text-white shadow rounded p-0 m-0" style="background: rgba(244, 148, 49);">Sponsored</p>`;
          }

          //mobile view for top products
          //old card before dev changes
          // var item = `<div class="card mt-1">
          //     <a href="${baseURL}customer/productSummary/${element.product_URL}" rel="noopener " style="margin-top:0.5rem;">
          //     <div class="card-body" style="height:125px;">
          //         <div class="row">
          //             <div class="col-4">
          //                 <img src="" class="img-load ${element.Product_ID}-topProdImg">
          //                 ${sponsoredContent}
          //             </div>
          //             <div class="col">

          //                 <div class="row">
          //                     <div class="col">
          //                         <p class="truncate-newHomePage"><b>${element.Product_Service_Name}</b></p>
          //                         <p class="mb-0 truncate-newHomePage">${element.Business_Name}, ${element.City}</p>
          //                     </div>
          //                 </div>
          //                 <div class="row">
          //                 <div class="col-8 pr-0">
          //                 <p class="mb-0 pt-1">₹${element.Price}</p>
          //                 </div>
          //                 <div class="col mb-2 pl-0 d-flex justify-content-end">
          // <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${element.Product_Review_Score} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
          //                 </div>
          //                 </div>
          //             </div>
          //         </div>
          //     </div>
          //     </a>
          // </div>`;

          //productsMobile

          var item = `<div class="card my-2">
        <a href="${baseURL}customer/productSummary/${element.product_URL}" rel="noopener">
            <div class="row">
                <div class="col-4 py-2 ml-2 pr-0" style="width:30%;">
                    <img src=""
                        class="${element.Product_ID}-topProdImg rounded-3 card-img-top"></img>
          ${sponsoredContent}
                </div>
                <div class="col pl-0">
                    <div class="card-body h-100">
                        <div class="row h-100 d-flex justify-content-between">
                            <div class="col-12">
                                <h5 class="card-title truncate-one-cart">
                                    ${element.Product_Service_Name}</h5>
                            </div>
                            <div class="col-12">
                                <p class="truncate-one-cart">${element.Business_Name},
                                    ${element.City}</p>
                            </div>
                            <div class="col-6">₹${element.Price}</div>
                            <div class="col-6">
                                <div class="row">
                                <div class="col d-flex justify-content-end">
                                    <div class="ratingBorder align-items-center d-inline-flex text-white mb-0 pl-2"
                                        style="${color}"><p class="mb-0">${element.Product_Review_Score}</p>
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            style="color: white;"></i>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>`;
          $(".top-products-items-mobile").append(item);
        }
      }
    });

    event.forEach(async (element) => {
      var productImgOnHome = await s3Object.getObjectURL(element.Image_URL); //element.Image_URL
      $(`.${element.Product_ID}-topProdImg`).attr("src", productImgOnHome);
    });
  };

  //ASC or DESC
  filterOptions = (event) => {
    //Changing Color
    $(".filterBtnToggle").removeClass("filterBtnToggle");
    $(".clearFilterHomepage").removeClass("d-none");
    //
    $(".top-categories").hide();
    var element = event.target.className;
    var classNames = element.split(" ").slice(-1);
    var filterOptions = classNames[0].split("-");

    //fav check
    this.checkFavourites();

    if (filterOptions[0] == "price") {
      $(".no-filter-option").addClass("d-none");
      var order = filterOptions[1];
      console.log("order of filter options on homepage: ", order);

      if (order == "ASC") {
        $(".price-ASC").addClass("filterBtnToggle");
      } else {
        $(".price-DESC").addClass("filterBtnToggle");
      }

      //filter products by price
      $.ajax({
        url: baseURL + "api/customer/Homepage/productByPrice",
        type: "POST",
        async: false,
        data: {
          order: order
        },
        headers: { Authorization: X_API_KEY },
        success: (result) => {
          $(".top-vendors").hide();
          $(".top-products").show();
          //checking for any previous items
          if (deviceTypeHomePage == "desktop") {
            var len = $(".owl-carousel.top-products-items").find(
              ".owl-item"
            ).length;
            console.log(len);
            for (var i = 0; i < len; i++) {
              $(".owl-carousel.top-products-items").owlCarousel("remove", 0);
            }
          } else {
            $(".top-products-items-mobile").empty();
          }
          var uniqueProductCategories = [];
          result.forEach((element) => {
            if (!uniqueProductCategories.includes(element["Picklist_ID"])) {
              uniqueProductCategories.push(element["Picklist_ID"]);
              if (deviceTypeHomePage == "desktop") {
                var sponsoredContent = "";
                // // servicename
                var prodServiceName = element.Product_Service_Name.toString();

                var prodServiceNameLength = prodServiceName.length;
                var addingSpaces = "";
                if (prodServiceNameLength <= 39) {
                  for (var i = prodServiceNameLength; i <= 60; i++) {
                    addingSpaces += "&nbsp";
                  }
                }
                console.log(
                  prodServiceName,
                  prodServiceNameLength,
                  addingSpaces.length
                );

                var prodBusinessName = element.Business_Name.toString();
                var prodBusinessCity = element.City.toString();
                var displayAddress = `${prodBusinessName}, ${prodBusinessCity}`;

                var prodAddressNameLength = displayAddress.length;
                var addingSpacesAddress = "";
                if (prodAddressNameLength <= 39) {
                  for (var i = prodAddressNameLength; i <= 60; i++) {
                    addingSpacesAddress += "&nbsp";
                  }
                }
                console.log(
                  displayAddress,
                  prodAddressNameLength,
                  addingSpacesAddress.length
                );

                //adding rating stars
                let productRating = element.Product_Review_Score;

                if (element.Product_Review_Score > 0) {
                  var color = "background: rgba(244, 148, 49);";
                }

                if (element.isSponsored > 0) {
                  sponsoredContent = `<div class="mt-4 pt-1 fixed-top">
          <h5 class="text-white ml-3 pl-2 w-50 shadow rounded" style="background: rgba(244, 148, 49);">Sponsored</h5>
      </div>`;
                }

                // // Product Item Code Change
                //     var item = `<div class="item zoom d-flex justify-content-around">
                //   <a href="${baseURL}customer/productSummary/${
                //       element.product_URL
                //     }" rel="noopener " style="margin-top:0.5rem;">
                //   <div class="wow productImageHp fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;" >
                //   ${sponsoredContent}
                //     <div class="rounded text-center"><img src="${new URL(
                //       productImgOnHome
                //     )}" class="rounded shadow-lg" alt="">
                //     </div>
                //     <div class="stats mt-2">
                //       <div class="row d-flex justify-content-between ${spacing}">
                //         <div class="col-sm-12 truncate">
                //         <h6><strong>${element.Product_Service_Name}</strong></h6>
                //         </div>
                //       </div>
                //       <div class="row d-flex justify-content-between">
                //         <div class="col-sm-12 truncate-one">
                //         ${element.Business_Name}
                //         </div>
                //       </div>
                //       <div class="row">
                //         <div class="col-sm-12 truncate-one">
                //           ${element.City}
                //         </div>
                //       </div>
                //       <div class="row">
                //       <div class="col pr-0 d-flex justify-content-start">
                //         <p class="mb-0 pt-2">₹${element.Price}</p>
                //         </div>
                //         <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                //           <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${
                //       element.Product_Review_Score
                //     } <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                //   </a>
                // </div>`;

                //productsDesktop

                //Show 1 product per category

                var item = `<div class="item zoom d-flex justify-content-around h-100">
    <a href="${baseURL}customer/productSummary/${element.product_URL}" rel="noopener " style="margin-top:0.5rem;">
    <div class=" wow fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;">
      <div class="w-100 rounded text-center favourites-container">
      <img src="" class="rounded shadow-lg ${element.Product_ID}-topProdImg" alt="">
      </a>
      ${sponsoredContent}
      </div>
      <a href="${baseURL}customer/productSummary/${element.product_URL}" rel="noopener " style="margin-top:0.5rem;">
      <div class="stats mt-2">

        <div class="row d-flex justify-content-between">
          <div class="col-sm-12 truncate-three">
          <h6 class="truncate-two"><strong>${prodServiceName} ${addingSpaces}</strong></h6>
          <h6 class="truncate-two">${displayAddress} ${addingSpacesAddress}</h6>
          </div>
        </div>
        <div class="row ">
        <div class="col pr-0 d-flex justify-content-start">
                <p class="mb-0 pt-2">₹${element.Price}</p>
                </div>
                <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                  <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${element.Product_Review_Score} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                </div>
        </div>
      </div>
    </div>
    </a>
  </div>`;

                $(".owl-carousel.top-products-items")
                  .owlCarousel("add", item)
                  .owlCarousel("update");
              } else {
                var sponsoredContent = "";

                //rating stars
                if (element.Product_Review_Score > 0) {
                  var color = "background: rgba(244, 148, 49);";
                }

                if (element.isSponsored > 0) {
                  sponsoredContent = `<p class="carousel-caption text-white shadow rounded p-0 m-0" style="background: rgba(244, 148, 49);">Sponsored</p>`;
                }

                //product items
                //         var item = `<div class="card mt-1">
                //     <a href="${baseURL}customer/productSummary/${element.product_URL}" rel="noopener " style="margin-top:0.5rem;">

                //     <div class="card-body" style="height:125px;">

                //         <div class="row">
                //             <div class="col-4">
                //                 <img src="" class="img-load ${element.Product_ID}-topProdImgMobile">
                //             </div>
                //             ${sponsoredContent}
                //             <div class="col">

                //                 <div class="row">
                //                     <div class="col">
                //                         <p class="mb-0 truncate-newHomePage"><b>${element.Product_Service_Name}</b></p>
                //                         <p class="mb-0 truncate-newHomePage">${element.Business_Name}</p>
                //                         <p class="mb-0 truncate-newHomePage">${element.City}</p>
                //                     </div>
                //                 </div>
                //                 <div class="row">
                //                 <div class="col-8 pr-0">
                //                 <p class="mb-0 pt-1">₹${element.Price}</p>
                //                 </div>
                //                 <div class="col mb-2 pl-0 d-flex justify-content-end">
                //                 <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${element.Product_Review_Score} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                //                 </div>
                //                 </div>

                //             </div>
                //         </div>

                //     </div>
                //     </a>

                // </div>`;

                //productsMobile

                var item = `<div class="card my-2">
        <a href="${baseURL}customer/productSummary/${element.product_URL}" rel="noopener">
            <div class="row">
                <div class="col-4 py-2 ml-2 pr-0" style="width:30%;">
                    <img src=""
                        class="${element.Product_ID}-topProdImgMobile rounded-3 card-img-top"></img>
          ${sponsoredContent}
                </div>
                <div class="col pl-0">
                    <div class="card-body h-100">
                        <div class="row h-100 d-flex justify-content-between">
                            <div class="col-12">
                                <h5 class="card-title truncate-one-cart">
                                    ${element.Product_Service_Name}</h5>
                            </div>
                            <div class="col-12">
                                <p class="truncate-one-cart">${element.Business_Name},
                                    ${element.City}</p>
                            </div>
                            <div class="col-6">₹${element.Price}</div>
                            <div class="col-6">
                                <div class="row">
                                <div class="col d-flex justify-content-end">
                                    <div class="ratingBorder align-items-center d-inline-flex text-white mb-0 pl-2"
                                        style="${color}"><p class="mb-0">${element.Product_Review_Score}</p>
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            style="color: white;"></i>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>`;

                $(".top-products-items-mobile").append(item);
              }
            }
          });

          //for product images with filter
          result.forEach(async (element) => {
            var productImgOnHomeByPrice = await s3Object.getObjectURL(
              element.Image_URL
            ); //element.Image_URL
            if (deviceTypeHomePage == "desktop") {
              $(`.${element.Product_ID}-topProdImg`).attr(
                "src",
                productImgOnHomeByPrice
              );
            } else {
              $(`.${element.Product_ID}-topProdImgMobile`).attr(
                "src",
                productImgOnHomeByPrice
              );
            }
          });
        }
      });

      //filter vendors in each category by price
      categories.forEach((cat) => {
        $.ajax({
          url:
            baseURL + "api/customer/VendorsList/getVendorByCategoryWithFilter",
          type: "POST",
          async: false,
          data: {
            buffer: BUFFER,
            lat: LAT,
            lon: LNG,
            Picklist_ID: cat.Picklist_ID,
            filter_Optn: 1,
            order: order
          },
          headers: { Authorization: X_API_KEY },
          success: (result) => {
            if (deviceTypeHomePage == "desktop") {
              if (result.NearMeCount == 0) {
                if ($(`.${cat.Picklist_ID}-near-you`).is(":visible")) {
                  $(`.${cat.Picklist_ID}-near-you`).hide();
                }
              } else {
                if ($(`.${cat.Picklist_ID}-near-you`).is(":hidden")) {
                  $(`.${cat.Picklist_ID}-near-you`).show();
                }
              }
              var len = $(
                `.owl-carousel.${cat.Picklist_ID}-near-you-items`
              ).find(".owl-item").length;
              for (var i = 0; i < len; i++) {
                $(
                  `.owl-carousel.${cat.Picklist_ID}-near-you-items`
                ).owlCarousel("remove", 0);
              }
              result.NearMe.forEach((element) => {
                var favItem = this.checkFavourites("check", element.Vendor_ID);
                var className = element.Picklist_ID;
                if (element.Business_Name.length > 28) {
                  spacing = "mb-0";
                } else {
                  spacing = "mb-3";
                }
                //for vendor review stars
                if (element.Vendor_Rating > 0) {
                  var color = "background: rgba(244, 148, 49);";
                }

                //vendorsDesktop

                var item = `<div class="item zoom d-flex justify-content-around">
                <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
                <div class=" wow fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;">
                  <div class="w-100 rounded text-center favourites-container">
                  <img src="" class="rounded shadow-lg ${element.Vendor_ID}-nearYouVendImg" alt="${element.Business_Name}-Image">
                  </a>
                  ${favItem}
                  </div>
                  <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
                  <div class="stats mt-2">
  
                    <div class="row d-flex justify-content-between ${spacing}">
                      <div class="col-sm-12 truncate">
                      <h6><strong>${element.Business_Name}</strong></h6>
                      <h6>${element.City}</h6>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-between">
                      <div class="col-sm-12 truncate-one">
                      ${element.Specialities}
                      </div>
                    </div>
                    <div class="row ">
                    <div class="col pr-0 d-flex justify-content-start">
                    <p class="mb-0 pt-2">Min Order:₹${element.Minimum_Order}</p>
                    </div>
                    <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                      <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${element.Vendor_Rating} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                    </div>
                    </div>
                  </div>
                </div>
                </a>
              </div>`;

                $(`.${className}-near-you-items`)
                  .owlCarousel("add", item)
                  .owlCarousel("update");
              });
              //unbinds any previous event handlers
              $(".likeBtnHomepage").off("click");
              $(".likeBtnHomepage").on("click", this.setFavourites);
              //for images
              result.NearMe.forEach(async (element) => {
                var vendorNearYouImg = await s3Object.getObjectURL(
                  "Vendor/" +
                    element.Image_URL +
                    "/Document Images/Business Image/1.png"
                ); //element.Image_URL
                $(`.${element.Vendor_ID}-nearYouVendImg`).attr(
                  "src",
                  vendorNearYouImg
                );
              });
            } else {
              if (result.NearMeCount == 0) {
                if ($(`.${cat.Picklist_ID}-near-you-mobile`).is(":visible")) {
                  $(`.${cat.Picklist_ID}-near-you-mobile`).hide();
                }
              } else {
                if ($(`.${cat.Picklist_ID}-near-you-mobile`).is(":hidden")) {
                  $(`.${cat.Picklist_ID}-near-you-mobile`).show();
                }
              }

              //empty inside
              $(`.${cat.Picklist_ID}-near-you-items-mobile`).empty();

              result.NearMe.forEach((element) => {
                var favItem = this.checkFavourites("check", element.Vendor_ID);

                var className = element.Picklist_ID;
                var sponsoredContent = "";

                //for vendor review stars
                if (element.Vendor_Rating > 0) {
                  var color = "background: rgba(244, 148, 49);";
                }

                if (element.isSponsored > 0) {
                  sponsoredContent = `<p class="carousel-caption text-white shadow rounded p-0 m-0" style="background: rgba(244, 148, 49);">Sponsored</p>`;
                }

                var vendRatingScore = "";
                if (element.Vendor_Rating == null) {
                  vendRatingScore = 0;
                } else {
                  vendRatingScore = element.Vendor_Rating;
                }

                //vendorsMobile
                var item = `<div class="card my-2">
                <div class="row">
                    <div class="col-4 py-2 ml-2 pr-0 favourites-container" style="width:30%;">
                        ${favItem}
                        <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
                            <img src=" " class="${element.Vendor_ID}-nearYouVendImgMobile rounded-3 card-img-top"></img>
                            ${sponsoredContent}
                        </a>
                    </div>
                    <div class="col pl-0">
                        <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
                            <div class="card-body h-100">
                                <div class="row h-100 d-flex justify-content-between">
                                    <div class="col-12">
                                        <h5 class="card-title truncate-newHomePage">${element.Business_Name}</h5>
                                    </div>
                                    <div class="col-12">
                                        <p class="truncate-newHomePage">${element.City}</p>
                                    </div>
                                    <div class="col-12">
                                        <p class="truncate-newHomePage mb-0">${element.Specialities}</p>
                                    </div>
                                    <div class="col-6 align-items-end mb-0">Min Order:₹${element.Minimum_Order}</div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col d-flex justify-content-end">
                                                <div class="ratingBorder align-items-center d-inline-flex text-white mb-0 pl-2" style="${color}">
                                                    <p class"mb-0">${vendRatingScore}</p>
                                                    <i class="fa fa-star ml-1" aria-hidden="true" style="color: white;"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>`;

                $(`.${className}-near-you-items-mobile`).append(item);
              });
              //unbinds any previous event handlers
              $(".likeBtnHomepage").off("click");
              $(".likeBtnHomepage").on("click", this.setFavourites);
              //for images
              result.NearMe.forEach(async (element) => {
                var vendorNearYouImg = await s3Object.getObjectURL(
                  "Vendor/" +
                    element.Image_URL +
                    "/Document Images/Business Image/1.png"
                ); //element.Image_URL
                $(`.${element.Vendor_ID}-nearYouVendImgMobile`).attr(
                  "src",
                  vendorNearYouImg
                );
              });
            }
          }
        });
      });
    } else if (filterOptions[0] == "delivery") {
      $(".no-filter-option").addClass("d-none");
      var showErrorDeliveryMessage = true;
      $(".top-products").hide();
      $(".top-vendors").hide();

      //changing color
      $(".delivery-10").addClass("filterBtnToggle");

      //For top Brands
      $.ajax({
        url: baseURL + "api/customer/VendorsList/getNearByVendors",
        type: "POST",
        async: false,
        data: {
          buffer: 10,
          lat: LAT,
          lon: LNG
        },
        headers: { Authorization: X_API_KEY },
        success: (result) => {
          if (!result.hasOwnProperty("code")) {
            showErrorDeliveryMessage = false;
            $(".top-vendors").show();
            var len = $(".owl-carousel.top-vendors-items").find(
              ".owl-item"
            ).length;
            for (var i = 0; i < len; i++) {
              $(".owl-carousel.top-vendors-items").owlCarousel("remove", 0);
            }
            result.forEach((element) => {
              var items = `<div class="item" >
              <div class="col-md-12 wow fadeInUp delay-2 d-flex justify-content-center">
                <div class="text-center zoom">
                  <a class="btn-light rounded-circle shadow" href="${baseURL}customer/productListing/${element.Vendor_URL}" style="margin-top:0.5rem;">
                    <img class="rounded-circle ${element.Vendor_ID}-topVenImg" src="" alt="${element.Business_Name}-Image" width="20px">
                  </a>
                  <p class="truncate">${element.Business_Name}</p>
                </div>
              </div>
            </div>`;
              $(".owl-carousel.top-vendors-items")
                .owlCarousel("add", items)
                .owlCarousel("update");
            });

            result.forEach(async (element) => {
              var vendorByCatImg = await s3Object.getObjectURL(
                "Vendor/" +
                  element.Image_URL +
                  "/Document Images/Business Image/1.png"
              ); //element.Image_URL
              $(`.${element.Vendor_ID}-topVenImg`).attr("src", vendorByCatImg);
            });
          }
        },
        error: (result) => {
          console.log(result);
        }
      });

      //Near You Categories
      categories.forEach((cat) => {
        $.ajax({
          url:
            baseURL + "api/customer/VendorsList/getVendorByCategoryWithFilter",
          type: "POST",
          async: false,
          data: {
            buffer: 10,
            lat: LAT,
            lon: LNG,
            Picklist_ID: cat.Picklist_ID
          },
          headers: { Authorization: X_API_KEY },
          success: (result) => {
            if (deviceTypeHomePage == "desktop") {
              if (result.NearMeCount == 0) {
                if ($(`.${cat.Picklist_ID}-near-you`).is(":visible")) {
                  $(`.${cat.Picklist_ID}-near-you`).hide();
                }
              } else {
                if ($(`.${cat.Picklist_ID}-near-you`).is(":hidden")) {
                  $(`.${cat.Picklist_ID}-near-you`).show();
                }
                showErrorDeliveryMessage = false;
              }
              var len = $(
                `.owl-carousel.${cat.Picklist_ID}-near-you-items`
              ).find(".owl-item").length;
              for (var i = 0; i < len; i++) {
                $(
                  `.owl-carousel.${cat.Picklist_ID}-near-you-items`
                ).owlCarousel("remove", 0);
              }
              result.NearMe.forEach((element) => {
                var favItem = this.checkFavourites("check", element.Vendor_ID);
                var className = element.Picklist_ID;
                if (element.Business_Name.length > 28) {
                  spacing = "mb-0";
                } else {
                  spacing = "mb-3";
                }

                //for vendor review stars
                if (element.Vendor_Rating > 0) {
                  var color = "background: rgba(244, 148, 49);";
                }

                //vendorsDesktop
                var item = `<div class="item zoom d-flex justify-content-around">
                <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
                <div class=" wow fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;">
                  <div class="w-100 rounded text-center favourites-container">
                  <img src="" class="rounded shadow-lg ${element.Vendor_ID}-nearYouVendImg" alt="${element.Business_Name}-Image">
                  </a>
                  ${favItem}
                  </div>
                  <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
                  <div class="stats mt-2">
  
                    <div class="row d-flex justify-content-between ${spacing}">
                      <div class="col-sm-12 truncate">
                      <h6><strong>${element.Business_Name}</strong></h6>
                      <h6>${element.City}</h6>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-between">
                      <div class="col-sm-12 truncate-one">
                      ${element.Specialities}
                      </div>
                    </div>
                    <div class="row ">
                    <div class="col pr-0 d-flex justify-content-start">
                    <p class="mb-0 pt-2">Min Order:₹${element.Minimum_Order}</p>
                    </div>
                    <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                      <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${element.Vendor_Rating} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                    </div>
                    </div>
                  </div>
                </div>
                </a>
              </div>`;

                $(`.${className}-near-you-items`)
                  .owlCarousel("add", item)
                  .owlCarousel("update");
              });
              //unbinds any previous event handlers
              $(".likeBtnHomepage").off("click");
              $(".likeBtnHomepage").on("click", this.setFavourites);
              //for images
              result.NearMe.forEach(async (element) => {
                var vendorNearYouImg = await s3Object.getObjectURL(
                  "Vendor/" +
                    element.Image_URL +
                    "/Document Images/Business Image/1.png"
                ); //element.Image_URL
                $(`.${element.Vendor_ID}-nearYouVendImg`).attr(
                  "src",
                  vendorNearYouImg
                );
              });
            } else {
              if (result.NearMeCount == 0) {
                if ($(`.${cat.Picklist_ID}-near-you-mobile`).is(":visible")) {
                  $(`.${cat.Picklist_ID}-near-you-mobile`).hide();
                }
              } else {
                if ($(`.${cat.Picklist_ID}-near-you-mobile`).is(":hidden")) {
                  $(`.${cat.Picklist_ID}-near-you-mobile`).show();
                }
                showErrorDeliveryMessage = false;
              }

              //empty inside
              $(`.${cat.Picklist_ID}-near-you-items-mobile`).empty();

              result.NearMe.forEach((element) => {
                var favItem = this.checkFavourites("check", element.Vendor_ID);

                var className = element.Picklist_ID;
                var sponsoredContent = "";

                //for vendor review stars
                if (element.Vendor_Rating > 0) {
                  var color = "background: rgba(244, 148, 49);";
                }

                if (element.isSponsored > 0) {
                  sponsoredContent = `<p class="carousel-caption text-white shadow rounded p-0 m-0" style="background: rgba(244, 148, 49);">Sponsored</p>`;
                }

                var vendRatingScore = "";
                if (element.Vendor_Rating == null) {
                  vendRatingScore = 0;
                } else {
                  vendRatingScore = element.Vendor_Rating;
                }

                //vendorsMobile
                var item = `<div class="card my-2">
                <div class="row">
                    <div class="col-4 py-2 ml-2 pr-0 favourites-container" style="width:30%;">
                        ${favItem}
                        <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
                            <img src="" class="${element.Vendor_ID}-nearYouVendImgMobile rounded-3 card-img-top"></img>
                            ${sponsoredContent}
                        </a>
                    </div>
                    <div class="col pl-0">
                        <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
                            <div class="card-body h-100">
                                <div class="row h-100 d-flex justify-content-between">
                                    <div class="col-12">
                                        <h5 class="card-title truncate-newHomePage">${element.Business_Name}</h5>
                                    </div>
                                    <div class="col-12">
                                        <p class="truncate-newHomePage">${element.City}</p>
                                    </div>
                                    <div class="col-12">
                                        <p class="truncate-newHomePage mb-0">${element.Specialities}</p>
                                    </div>
                                    <div class="col-6 align-items-end mb-0">Min Order:₹${element.Minimum_Order}</div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col d-flex justify-content-end">
                                                <div class="ratingBorder align-items-center d-inline-flex text-white mb-0 pl-2" style="${color}">
                                                    <p class="mb-0">${vendRatingScore}</p>
                                                    <i class="fa fa-star ml-1" aria-hidden="true" style="color: white;"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>`;

                $(`.${className}-near-you-items-mobile`).append(item);
              });
              //unbinds any previous event handlers
              $(".likeBtnHomepage").off("click");
              $(".likeBtnHomepage").on("click", this.setFavourites);
              //for images
              result.NearMe.forEach(async (element) => {
                var vendorNearYouImg = await s3Object.getObjectURL(
                  "Vendor/" +
                    element.Image_URL +
                    "/Document Images/Business Image/1.png"
                ); //element.Image_URL
                $(`.${element.Vendor_ID}-nearYouVendImgMobile`).attr(
                  "src",
                  vendorNearYouImg
                );
              });
            }
          },
          error: (error) => {
            console.log(error);
          }
        });
      });

      if (showErrorDeliveryMessage === true) {
        $(".no-filter-option").removeClass("d-none");
      }
    } else if (filterOptions[0] == "rating") {
      $(".top-vendors").hide();
      $(".top-products").hide();
      $(".no-filter-option").addClass("d-none");

      //changing color
      $(".rating-4").addClass("filterBtnToggle");
      //For Vendors
      $.ajax({
        url: baseURL + "api/customer/Homepage/vendorByRating",
        type: "GET",
        async: false,
        headers: { Authorization: X_API_KEY },
        success: (result) => {
          $(".top-vendors").show();
          var len = $(".owl-carousel.top-vendors-items").find(
            ".owl-item"
          ).length;
          for (var i = 0; i < len; i++) {
            $(".owl-carousel.top-vendors-items").owlCarousel("remove", 0);
          }
          result.forEach((element) => {
            if (element.Vendor_Rating >= 4) {
              var items = `<div class="item" >
              <div class="col-md-12 wow fadeInUp delay-2 d-flex justify-content-center">
                <div class="text-center zoom">
                  <a class="btn-light rounded-circle shadow" href="${baseURL}customer/productListing/${element.Vendor_URL}" style="margin-top:0.5rem;">
                    <img class="rounded-circle ${element.Vendor_ID}-topVenImg" src="" alt="${element.Business_Name}-Image" width="20px">
                  </a>
                  <p class="truncate">${element.Business_Name}</p>
                </div>
              </div>
            </div>`;
              $(".owl-carousel.top-vendors-items")
                .owlCarousel("add", items)
                .owlCarousel("update");
            }
          });
          result.forEach(async (element) => {
            var vendorByCatImg = await s3Object.getObjectURL(
              "Vendor/" +
                element.Image_URL +
                "/Document Images/Business Image/1.png"
            ); //element.Image_URL
            $(`.${element.Vendor_ID}-topVenImg`).attr("src", vendorByCatImg);
          });
        },
        error: (result) => {
          console.log(result);
        }
      });

      //For Products
      $.ajax({
        url: baseURL + "api/customer/Homepage/productByRating",
        type: "GET",
        async: false,
        headers: { Authorization: X_API_KEY },
        success: (result) => {
          $(".top-products").show();
          //checking for any previous items
          if (deviceTypeHomePage == "desktop") {
            var len = $(".owl-carousel.top-products-items").find(
              ".owl-item"
            ).length;
            console.log(len);
            for (var i = 0; i < len; i++) {
              $(".owl-carousel.top-products-items").owlCarousel("remove", 0);
            }
          } else {
            $(".top-products-items-mobile").empty();
          }
          var uniqueProductCategories = [];
          result.forEach((element) => {
            if (!uniqueProductCategories.includes(element["Picklist_ID"])) {
              uniqueProductCategories.push(element["Picklist_ID"]);
              if (deviceTypeHomePage == "desktop") {
                var sponsoredContent = "";
                // // servicename
                var prodServiceName = element.Product_Service_Name.toString();

                var prodServiceNameLength = prodServiceName.length;
                var addingSpaces = "";
                if (prodServiceNameLength <= 39) {
                  for (var i = prodServiceNameLength; i <= 60; i++) {
                    addingSpaces += "&nbsp";
                  }
                }
                console.log(
                  prodServiceName,
                  prodServiceNameLength,
                  addingSpaces.length
                );

                var prodBusinessName = element.Business_Name.toString();
                var prodBusinessCity = element.City.toString();
                var displayAddress = `${prodBusinessName}, ${prodBusinessCity}`;

                var prodAddressNameLength = displayAddress.length;
                var addingSpacesAddress = "";
                if (prodAddressNameLength <= 39) {
                  for (var i = prodAddressNameLength; i <= 60; i++) {
                    addingSpacesAddress += "&nbsp";
                  }
                }
                console.log(
                  displayAddress,
                  prodAddressNameLength,
                  addingSpacesAddress.length
                );

                //adding rating stars
                let productRating = element.Product_Review_Score;

                if (element.Product_Review_Score > 0) {
                  var color = "background: rgba(244, 148, 49);";
                }

                if (element.isSponsored > 0) {
                  sponsoredContent = `<div class="mt-4 pt-1 fixed-top">
          <h5 class="text-white ml-3 pl-2 w-50 shadow rounded" style="background: rgba(244, 148, 49);">Sponsored</h5>
      </div>`;
                }

                // // Product Item Code Change
                //     var item = `<div class="item zoom d-flex justify-content-around">
                //   <a href="${baseURL}customer/productSummary/${
                //       element.product_URL
                //     }" rel="noopener " style="margin-top:0.5rem;">
                //   <div class="wow productImageHp fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;" >
                //   ${sponsoredContent}
                //     <div class="rounded text-center"><img src="${new URL(
                //       productImgOnHome
                //     )}" class="rounded shadow-lg" alt="">
                //     </div>
                //     <div class="stats mt-2">
                //       <div class="row d-flex justify-content-between ${spacing}">
                //         <div class="col-sm-12 truncate">
                //         <h6><strong>${element.Product_Service_Name}</strong></h6>
                //         </div>
                //       </div>
                //       <div class="row d-flex justify-content-between">
                //         <div class="col-sm-12 truncate-one">
                //         ${element.Business_Name}
                //         </div>
                //       </div>
                //       <div class="row">
                //         <div class="col-sm-12 truncate-one">
                //           ${element.City}
                //         </div>
                //       </div>
                //       <div class="row">
                //       <div class="col pr-0 d-flex justify-content-start">
                //         <p class="mb-0 pt-2">₹${element.Price}</p>
                //         </div>
                //         <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                //           <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${
                //       element.Product_Review_Score
                //     } <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                //   </a>
                // </div>`;

                //productsDesktop

                //Show 1 product per category

                var item = `<div class="item zoom d-flex justify-content-around h-100">
    <a href="${baseURL}customer/productSummary/${element.product_URL}" rel="noopener " style="margin-top:0.5rem;">
    <div class=" wow fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;">
      <div class="w-100 rounded text-center favourites-container">
      <img src="" class="rounded shadow-lg ${element.Product_ID}-topProdImg" alt="">
      </a>
      ${sponsoredContent}
      </div>
      <a href="${baseURL}customer/productSummary/${element.product_URL}" rel="noopener " style="margin-top:0.5rem;">
      <div class="stats mt-2">

        <div class="row d-flex justify-content-between">
          <div class="col-sm-12 truncate-three">
          <h6 class="truncate-two"><strong>${prodServiceName} ${addingSpaces}</strong></h6>
          <h6 class="truncate-two">${displayAddress} ${addingSpacesAddress}</h6>
          </div>
        </div>
        <div class="row ">
        <div class="col pr-0 d-flex justify-content-start">
                <p class="mb-0 pt-2">₹${element.Price}</p>
                </div>
                <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                  <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${element.Product_Review_Score} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                </div>
        </div>
      </div>
    </div>
    </a>
  </div>`;

                $(".owl-carousel.top-products-items")
                  .owlCarousel("add", item)
                  .owlCarousel("update");
              } else {
                var sponsoredContent = "";

                //rating stars
                if (element.Product_Review_Score > 0) {
                  var color = "background: rgba(244, 148, 49);";
                }

                if (element.isSponsored > 0) {
                  sponsoredContent = `<p class="carousel-caption text-white shadow rounded p-0 m-0" style="background: rgba(244, 148, 49);">Sponsored</p>`;
                }

                //product items
                //         var item = `<div class="card mt-1">
                //     <a href="${baseURL}customer/productSummary/${element.product_URL}" rel="noopener " style="margin-top:0.5rem;">

                //     <div class="card-body" style="height:125px;">

                //         <div class="row">
                //             <div class="col-4">
                //                 <img src="" class="img-load ${element.Product_ID}-topProdImgMobile">
                //             </div>
                //             ${sponsoredContent}
                //             <div class="col">

                //                 <div class="row">
                //                     <div class="col">
                //                         <p class="mb-0 truncate-newHomePage"><b>${element.Product_Service_Name}</b></p>
                //                         <p class="mb-0 truncate-newHomePage">${element.Business_Name}</p>
                //                         <p class="mb-0 truncate-newHomePage">${element.City}</p>
                //                     </div>
                //                 </div>
                //                 <div class="row">
                //                 <div class="col-8 pr-0">
                //                 <p class="mb-0 pt-1">₹${element.Price}</p>
                //                 </div>
                //                 <div class="col mb-2 pl-0 d-flex justify-content-end">
                //                 <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${element.Product_Review_Score} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                //                 </div>
                //                 </div>

                //             </div>
                //         </div>

                //     </div>
                //     </a>

                // </div>`;

                //productsMobile

                var item = `<div class="card my-2">
        <a href="${baseURL}customer/productSummary/${element.product_URL}" rel="noopener">
            <div class="row">
                <div class="col-4 py-2 ml-2 pr-0" style="width:30%;">
                    <img src=""
                        class="${element.Product_ID}-topProdImgMobile rounded-3 card-img-top"></img>
          ${sponsoredContent}
                </div>
                <div class="col pl-0">
                    <div class="card-body h-100">
                        <div class="row h-100 d-flex justify-content-between">
                            <div class="col-12">
                                <h5 class="card-title truncate-one-cart">
                                    ${element.Product_Service_Name}</h5>
                            </div>
                            <div class="col-12">
                                <p class="truncate-one-cart">${element.Business_Name},
                                    ${element.City}</p>
                            </div>
                            <div class="col-6">₹${element.Price}</div>
                            <div class="col-6">
                                <div class="row">
                                <div class="col d-flex justify-content-end">
                                    <div class="ratingBorder align-items-center d-inline-flex text-white mb-0 pl-2"
                                        style="${color}"><p class="mb-0">${element.Product_Review_Score}</p>
                                        <i class="fa fa-star ml-1" aria-hidden="true"
                                            style="color: white;"></i>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>`;

                $(".top-products-items-mobile").append(item);
              }
            }
          });

          //for product images with filter
          result.forEach(async (element) => {
            var productImgOnHomeByPrice = await s3Object.getObjectURL(
              element.Image_URL
            ); //element.Image_URL
            if (deviceTypeHomePage == "desktop") {
              $(`.${element.Product_ID}-topProdImg`).attr(
                "src",
                productImgOnHomeByPrice
              );
            } else {
              $(`.${element.Product_ID}-topProdImgMobile`).attr(
                "src",
                productImgOnHomeByPrice
              );
            }
          });
        },
        error: (result) => {
          console.log(result);
        }
      });

      //For Near You Vendors
      categories.forEach((cat) => {
        $.ajax({
          url:
            baseURL + "api/customer/VendorsList/getVendorByCategoryWithFilter",
          type: "POST",
          async: false,
          data: {
            buffer: BUFFER,
            lat: LAT,
            lon: LNG,
            Picklist_ID: cat.Picklist_ID,
            filter_Optn: 3
          },
          headers: { Authorization: X_API_KEY },
          success: (result) => {
            if (deviceTypeHomePage == "desktop") {
              if (result.NearMeCount == 0) {
                if ($(`.${cat.Picklist_ID}-near-you`).is(":visible")) {
                  $(`.${cat.Picklist_ID}-near-you`).hide();
                }
              } else {
                if ($(`.${cat.Picklist_ID}-near-you`).is(":hidden")) {
                  $(`.${cat.Picklist_ID}-near-you`).show();
                }
              }
              var len = $(
                `.owl-carousel.${cat.Picklist_ID}-near-you-items`
              ).find(".owl-item").length;
              for (var i = 0; i < len; i++) {
                $(
                  `.owl-carousel.${cat.Picklist_ID}-near-you-items`
                ).owlCarousel("remove", 0);
              }
              result.NearMe.forEach((element) => {
                var favItem = this.checkFavourites("check", element.Vendor_ID);
                var className = element.Picklist_ID;
                if (element.Business_Name.length > 28) {
                  spacing = "mb-0";
                } else {
                  spacing = "mb-3";
                }

                //for vendor review stars
                if (element.Vendor_Rating > 0) {
                  var color = "background: rgba(244, 148, 49);";
                }

                //vendorsDesktop

                var item = `<div class="item zoom d-flex justify-content-around">
                <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
                <div class=" wow fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;">
                  <div class="w-100 rounded text-center favourites-container">
                  <img src="" class="rounded shadow-lg ${element.Vendor_ID}-nearYouVendImg" alt="${element.Business_Name}-Image">
                  </a>
                  ${favItem}
                  </div>
                  <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
                  <div class="stats mt-2">
  
                    <div class="row d-flex justify-content-between ${spacing}">
                      <div class="col-sm-12 truncate">
                      <h6><strong>${element.Business_Name}</strong></h6>
                      <h6>${element.City}</h6>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-between">
                      <div class="col-sm-12 truncate-one">
                      ${element.Specialities}
                      </div>
                    </div>
                    <div class="row ">
                    <div class="col pr-0 d-flex justify-content-start">
                    <p class="mb-0 pt-2">Min Order:₹${element.Minimum_Order}</p>
                    </div>
                    <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                      <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${element.Vendor_Rating} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                    </div>
                    </div>
                  </div>
                </div>
                </a>
              </div>`;

                $(`.${className}-near-you-items`)
                  .owlCarousel("add", item)
                  .owlCarousel("update");
              });
              //unbinds any previous event handlers
              $(".likeBtnHomepage").off("click");
              $(".likeBtnHomepage").on("click", this.setFavourites);
              //for images
              result.NearMe.forEach(async (element) => {
                var vendorNearYouImg = await s3Object.getObjectURL(
                  "Vendor/" +
                    element.Image_URL +
                    "/Document Images/Business Image/1.png"
                ); //element.Image_URL
                $(`.${element.Vendor_ID}-nearYouVendImg`).attr(
                  "src",
                  vendorNearYouImg
                );
              });
            } else {
              if (result.NearMeCount == 0) {
                if ($(`.${cat.Picklist_ID}-near-you-mobile`).is(":visible")) {
                  $(`.${cat.Picklist_ID}-near-you-mobile`).hide();
                }
              } else {
                if ($(`.${cat.Picklist_ID}-near-you-mobile`).is(":hidden")) {
                  $(`.${cat.Picklist_ID}-near-you-mobile`).show();
                }
              }

              //empty inside
              $(`.${cat.Picklist_ID}-near-you-items-mobile`).empty();

              result.NearMe.forEach((element) => {
                var favItem = this.checkFavourites("check", element.Vendor_ID);

                var className = element.Picklist_ID;

                //for vendor review stars
                if (element.Vendor_Rating > 0) {
                  var color = "background: rgba(244, 148, 49);";
                }

                var vendRatingScore = "";
                if (element.Vendor_Rating == null) {
                  vendRatingScore = 0;
                } else {
                  vendRatingScore = element.Vendor_Rating;
                }

                //vendorsMobile
                var item = `<div class="card my-2">
                <div class="row">
                    <div class="col-4 py-2 ml-2 pr-0 favourites-container" style="width:30%;">
                        ${favItem}
                        <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
                            <img src="" class="${element.Vendor_ID}-nearYouVendImgMobile rounded-3 card-img-top"></img>
                        </a>
                    </div>
                    <div class="col pl-0">
                        <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
                            <div class="card-body h-100">
                                <div class="row h-100 d-flex justify-content-between">
                                    <div class="col-12">
                                        <h5 class="card-title truncate-newHomePage">${element.Business_Name}</h5>
                                    </div>
                                    <div class="col-12">
                                        <p class="truncate-newHomePage">${element.City}</p>
                                    </div>
                                    <div class="col-12">
                                        <p class="truncate-newHomePage mb-0">${element.Specialities}</p>
                                    </div>
                                    <div class="col-6 align-items-end mb-0">Min Order:₹${element.Minimum_Order}</div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col d-flex justify-content-end">
                                                <div class="ratingBorder align-items-center d-inline-flex text-white mb-0 pl-2" style="${color}">
                                                    <p class="mb-0">${vendRatingScore}</p>
                                                    <i class="fa fa-star ml-1" aria-hidden="true" style="color: white;"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>`;

                $(`.${className}-near-you-items-mobile`).append(item);
              });
              //unbinds any previous event handlers
              $(".likeBtnHomepage").off("click");
              $(".likeBtnHomepage").on("click", this.setFavourites);
              //for images
              result.NearMe.forEach(async (element) => {
                var vendorNearYouImg = await s3Object.getObjectURL(
                  "Vendor/" +
                    element.Image_URL +
                    "/Document Images/Business Image/1.png"
                ); //element.Image_URL
                $(`.${element.Vendor_ID}-nearYouVendImgMobile`).attr(
                  "src",
                  vendorNearYouImg
                );
              });
            }
          },
          error: (error) => {
            console.log(error);
          }
        });
      });
    }
  };

  setFavourites = (event) => {
    console.log(event);
    var vendorID = event.currentTarget.value;
    var result, type;
    if (event.currentTarget.classList.contains("liked")) {
      console.log("liked and changing to not liked");
      result = favObject.favouritesToggle(vendorID, "false");
      type = 0;
    } else {
      console.log("not liked and changing to like");
      result = favObject.favouritesToggle(vendorID, "true");
      type = 1;
    }

    if (result == "YOU NEED TO LOG IN") {
      console.log("You need to log in");
      window.$("#favouritesError").modal("show");
    } else if (result == "ERROR OCCURED") {
      console.log("Error Occured");
    } else {
      console.log("Everything was good");
      if (type == 0) {
        $(`button.like-button[value='${vendorID}']`).toggleClass("liked");
        $(`button.like-button[value='${vendorID}']`).html(
          `<img src="https://img.icons8.com/material-outlined/48/null/filled-like.png"/>`
        );
      } else if (type == 1) {
        $(`button.like-button[value='${vendorID}']`).toggleClass("liked");
        $(`button.like-button[value='${vendorID}']`).html(
          `<img src="https://img.icons8.com/emoji/48/null/heart-suit.png"/>`
        );
      }
      this.checkFavourites();
    }
  };

  checkFavourites = (type = "get", id = "") => {
    if (type == "get") {
      // customerFavList = favObject.getCustomerFavouriteList();
      // console.log(customerFavList);
      var cookies = UtilObject.getAllCookies();
      if (cookies.hasOwnProperty("CUSTOMER_ID")) {
        $.ajax({
          url: baseURL + "api/customer/Account/getFavorites",
          type: "GET",
          async: false,
          headers: { Authorization: X_API_KEY },
          success: (result) => {
            console.log(result);
            customerFavList = result;
          },
          error: (result) => {
            console.log(result);
            customerFavList = 0;
          }
        });
      } else {
        customerFavList = 0;
      }
      // if (favList == "YOU NEED TO LOG IN") {
      // }
      // else if (favList == "ERROR OCCURED") {

      // }
      // else {

      // }
    } else {
      if (customerFavList != 0) {
        var ret = 0;
        customerFavList.forEach((favitem) => {
          if (favitem.Vendor_ID == id) {
            ret = 1;
          }
        });
        if (ret == 1) {
          ret = `<button class="like-button likeBtnHomepage liked zindex-popover" aria-label="Favourite" value="${id}">
          <img src="https://img.icons8.com/emoji/48/null/heart-suit.png"/>
</button>`;
          return ret;
        } else {
          ret = `<button class="like-button likeBtnHomepage zindex-popover" aria-label="Favourite" value="${id}">
          <img src="https://img.icons8.com/material-outlined/48/null/filled-like.png"/>
</button>`;
          return ret;
        }
      } else {
        ret = `<button class="like-button likeBtnHomepage zindex-popover" aria-label="Favourite" value="${id}">
          <img src="https://img.icons8.com/material-outlined/48/null/filled-like.png"/>
</button>`;
        return ret;
      }
    }
  };

  clearHomepageFilter = () => {
    //Changing Color
    $(".filterBtnToggle").removeClass("filterBtnToggle");
    $(".clearFilterHomepage").addClass("d-none");
    //Show top categories
    $(".top-categories").show();
    $(".top-products").show();

    //show top brands again
    this.getVendorByRating();

    //show products
    this.getProductByRating();

    //show near you and away from you vendors
    this.nearYouCategoriesVendor();
  };
}
