export default class vendorServicing_points {
  constructor() {
    this.currentPoints = $("#currentPoints");
    this.addRsTextField = $("#addRsTextField");
    this.addMoneyButton = $("#addMoneyButton");
    this.AddRsError = $("#AddRsError");
    this.ProceedtoAddAmount = $("#ProceedtoAddAmount");
    this.addAmount = $(".addAmount");
    this.ProceedtoAddAmountValue = $("#ProceedtoAddAmount")[0].innerHTML;

    this.vendorPointsFormID = $("#vendorPoints");

    this.bindEvents();
  }

  bindEvents = () => {
    console.log("vendor servicing points js");
    this.getCurrentPoints();
    this.addMoneyButton.on("click", this.validateInputMoney);
    this.addAmount.on("click", this.addAmountFunction);
    this.addRsTextField.on("input", this.changeAmountonInput);

    //New Functions for cashFree
    $("#addMoneyButton").on("click", this.makeCashfreePayment);
    $(".vendorPaymentCheck").on("click", this.checkCashfreePayment);

    this.vendorPointsFormID.on("focus", "input[type=number]", function (e) {
      $(this).on("wheel.disableScroll", function (e) {
        // console.log("inhere");
        e.preventDefault();
      });
    });
  };

  getCurrentPoints = () => {
    console.log("vendor servicing points getCurrentPoints");

    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/GetCurrentPoints",
      // data: {
      // },
      headers: { Authorization: X_API_KEY },
      success: this.showCurrentPoints,
    });
  };

  showCurrentPoints = (res) => {
    this.currentPoints.html(res[0].Vendor_Points);
    console.log(res[0].Vendor_Points);
  };
  changeAmountonInput = () => {
    console.log("change");
    let existingAmt = parseInt(this.addRsTextField.val());
    if (isNaN(existingAmt)) {
      console.log(existingAmt);
      console.log("in if existing amt is nan");
      this.addRsTextField.val(0);
    }
    let amountEntered = this.addRsTextField.val();
    this.ProceedtoAddAmount.html(amountEntered);
  };

  validateInputMoney = () => {
    console.log("in validateInputMoney");

    if (this.addRsTextField.val() <= 0 || !this.addRsTextField.val()) {
      this.AddRsError.html("Enter Rs more than 0.");
    } else {
      this.AddRsError.html("");
    }
  };

  addAmountFunction = (e) => {
    let total = parseInt(this.addRsTextField.val()) + parseInt(e.target.value);
    console.log("in add1");
    console.log(total);

    this.addRsTextField.val(total);
    console.log(e);

    console.log(e.target.value);
    console.log(e.target);
    this.changeAmountonInput();
  };

  makeCashfreePayment = () => {
    console.log("making payment");
    var PaymentAmount = this.ProceedtoAddAmount[0].innerHTML;

    console.log(this.ProceedtoAddAmount[0].innerHTML);
    const dropConfig = {
      components: ["order-details", "card", "netbanking", "app", "upi"],
      onSuccess: this.cashfreePaymentSuccess,
      onFailure: this.cashfreePaymentFailure,
    };

    console.log();
    $.ajax({
      url: baseURL + "api/CashfreePayments/createOrder",
      type: "POST",
      async: false,
      data: {
        Vendor_ID: "null",
        Amount: PaymentAmount,
        Payment_Type: "Vendor Points",
        Picklist_ID: null,
      },
      success: (result) => {
        result = JSON.parse(result);
        console.log(result);
        const paymentSessionId = result["payment_session_id"];
        const cf = new Cashfree(paymentSessionId, dropConfig);
        cf.redirect();
      },
      error: (result) => {
        result = JSON.parse(result);
        console.log(result);
      },
    });
  };

  cashfreePaymentSuccess = () => {
    console.log("payment success");
  };

  cashfreePaymentFailure = () => {
    console.log("payment failure");
  };

  checkCashfreePayment = () => {
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    var payment_order_id = urlParams.get("payment_order_id");
    console.log(payment_order_id);

    $.ajax({
      url: baseURL + "api/CashfreePayments/getOrder",
      type: "POST",
      async: false,
      data: {
        Payment_ID: payment_order_id,
      },
      success: (result) => {
        result = JSON.parse(result);
        console.log(result);
      },
      error: (result) => {
        result = JSON.parse(result);
        console.log(result);
      },
    });
  };
}
