import cartUtil from "../helpers/cartUtil";
import Util from "../helpers/util";
var CartObject = new cartUtil();
var UtilObject = new Util();

export default class headerServicing {
  constructor() {
    // this.inputAddress = $(".inputAddress");
    // this.inputAddressshort = $(".inputAddressshort");
    // this.inputLandmark = $(".inputLandmark");
    // this.inputCity = $(".inputCity");
    // this.inputState = $(".inputState");
    // this.inputPincode = $(".inputPincode");

    // this.inputAddressError = $(".inputAddressError");
    // this.inputAddressshortError = $(".inputAddressshortError");
    // this.inputLandmarkError = $(".inputLandmarkError");
    // this.inputCityError = $(".inputCityError");
    // this.inputStateError = $(".inputStateError");
    // this.inputpincodeError = $(".inputpincodeError");

    //Calculating the Number of products in the cart
    this.noOfProductsInCart = CartObject.cartCheck();
    //console.log(this.noOfProductsInCart.Products.length);
    //$("span.cartCount").html(`${this.noOfProductsInCart.Products.length}`);

    var allCookies = UtilObject.getAllCookies();
    console.log(allCookies);
    // //when typing text disappear
    // this.onTypeText = $(".onTypeText");
    // Registering Events
    console.log("IN CUSTOMER");
    this.bindEvents();
  }

  bindEvents = () => {
    console.log("in bind");

    // $(window).on("load", $.ready.then(this.currentAddressShow));

    $(".categoriesModal").on("click", this.getAllCategories);

    // $(".submitBtn").on("click", this.doFormAction);

    // //call recent search function
    // $(".searchBarBtn").on("click", this.SearchOptions);

    // //for desktop
    // $(".textareaDesktop").on("input", () => {
    //   if (!$(".textareaDesktop").val()) {
    //     $(".recentSearchesDesktop").show();
    //   } else {
    //     $(".recentSearchesDesktop").hide();
    //   }
    // });
    // //for mobile
    // $(".textareaMobile").on("input", () => {
    //   if (!$(".textareaMobile").val()) {
    //     $(".recentSearchesMobile").show();
    //   } else {
    //     $(".recentSearchesMobile").hide();
    //   }
    // });
  };

  //   doFormAction = (e) => {
  //     e.preventDefault();
  //     console.log("Basic Details");

  //     let isAddressValidated = this.Address_validate();
  //     if (isAddressValidated) {
  //       console.log("address VALID");
  //       this.inputAddressError.html("");
  //     }

  //     let isAddressshortValidated = this.Addressshort_validate();
  //     if (isAddressshortValidated) {
  //       console.log("address short VALID");
  //       this.inputAddressshortError.html("");
  //     }

  //     let isLandmarkValidated = this.Landmark_validate();
  //     if (isLandmarkValidated) {
  //       console.log("Landmark VALID");
  //       this.inputLandmarkError.html("");
  //     }

  //     let isCityValidated = this.City_validate();
  //     if (isCityValidated) {
  //       console.log("city VALID");
  //       this.inputCityError.html("");
  //     }

  //     let isStateValidated = this.State_validate();
  //     if (isStateValidated) {
  //       console.log("state VALID");
  //       this.inputStateError.html("");
  //     }

  //     let isPincodeValidated = this.Pincode_validate();
  //     if (isPincodeValidated) {
  //       console.log("pincode VALID");
  //       this.inputpincodeError.html("");
  //     }

  //     if (
  //       isAddressValidated &&
  //       isAddressshortValidated &&
  //       isCityValidated &&
  //       isLandmarkValidated &&
  //       isStateValidated &&
  //       isPincodeValidated
  //     ) {
  //       //this.Submit_Form;
  //       console.log("ALL FIELDS VALID...VALIDATION OVER");
  //     }
  //   };

  //validate function

  //   Address_validate = () => {
  //     console.log("in address validate");
  //     let flag = true;

  //     if (this.inputAddress.val().length < 5) {
  //       this.inputAddressError.html(
  //         "Address should be minimum 5 characters long!"
  //       );

  //       flag = false;
  //     }

  //     return flag;
  //   };

  //   Addressshort_validate = () => {
  //     console.log("in address short validate");
  //     let flag = true;

  //     console.log(this.inputAddressshort.val().length);

  //     if (this.inputAddressshort.val().length < 5) {
  //       this.inputAddressshortError.html(
  //         "Address should be minimum 5 characters long!"
  //       );

  //       flag = false;
  //     }

  //     return flag;
  //   };

  //   Landmark_validate = () => {
  //     console.log("in landmark validate");
  //     let flag = true;

  //     if (this.inputLandmark.val().length < 5) {
  //       this.inputLandmarkError.html(
  //         "landmark should be minimum 5 characters long!"
  //       );

  //       flag = false;
  //     }

  //     return flag;
  //   };

  //   City_validate = () => {
  //     console.log("in city validate");
  //     let flag = true;

  //     var cityExample = /^[A-Za-z0-9 ]+$/;

  //     if (
  //       !this.inputCity.val().match(cityExample) ||
  //       this.inputCity.val().length < 3
  //     ) {
  //       this.inputCityError.html(
  //         "City should be minimum 3 characters long and should not contain special characters!"
  //       );

  //       flag = false;
  //     }

  //     return flag;
  //   };

  //   State_validate = () => {
  //     console.log("in state validate");
  //     let flag = true;

  //     var stateExample = /^[A-Za-z0-9 ]+$/;

  //     if (
  //       !this.inputState.val().match(stateExample) ||
  //       this.inputState.val().length < 3
  //     ) {
  //       this.inputStateError.html(
  //         "State should be minimum 3 characters long and should not contain special characters!"
  //       );

  //       flag = false;
  //     }

  //     return flag;
  //   };

  //   Pincode_validate = () => {
  //     console.log("in pincode validate");
  //     let flag = true;

  //     var pincodeExample = /^[0-9]*$/;

  //     if (
  //       !this.inputPincode.val().match(pincodeExample) ||
  //       this.inputPincode.val().length < 6
  //     ) {
  //       this.inputpincodeError.html(
  //         "Pincode should be minimum 6 characters long and should contain only numbers!"
  //       );

  //       flag = false;
  //     }

  //     return flag;
  //   };

  //   Submit_Form = () => {
  //     console.log("Inside submit flow");

  //     $.ajax({
  //       type: "POST",
  //       url: "",

  //       data: this.basicDetailsAndAddressForm.serialize(),
  //       success: function (res) {
  //         console.log(res);
  //       },
  //     });
  //   };

  //getting all categories by clicking on hamburger menu
  getAllCategories = () => {
    $.ajax({
      url: baseURL + "api/customer/Homepage/getCategoryType",
      type: "GET",
      async: false,
      headers: { Authorization: X_API_KEY },
      success: function (result) {
        console.log(result);
        $(".categories-items").children().remove();
        result.forEach((element) => {
          // if (element.Picklist_display_value.length > 16) {
          //   var name = element.Picklist_display_value.substring(0, 16) + "....";
          // } else {
          //   var name = element.Picklist_display_value;
          // }
          var item = `
          <div class="col-4" >
            <div class="item mt-2" >
            <div class="row d-flex justify-content-between">
              <div class="col-sm-12 wow fadeInUp delay-2 justShow">
                <div class="rounded-4 text-center zoom">
                <a class="btn-light" href="${baseURL}customer/vendorListingPage/${element.Picklist_display_value}" rel="noopener "  style="margin-top:0.5rem;">
                    <img src="${baseURL}assets/src/images/${element.Icon_Url}" class="rounded-4 shadow-lg" style="width:6rem;" alt="${element.Picklist_display_value}-icon">
                    <p class="mt-2 truncate text-capitalize"><strong>${element.Picklist_display_value}</strong></p>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>`;

          $(".categories-items").append(item);
        });
      },
      error: function (result) {
        console.log(result);
      }
    });
  };

  //   currentAddressShow = () => {
  //     var customerID = (document.cookie.match(
  //       /^(?:.*;)?\s*CUSTOMER_ID\s*=\s*([^;]+)(?:.*)?$/
  //     ) || [, null])[1];
  // if (customerID == null) {
  //   var choice = confirm("Add Customer ID or be Anonymous");
  //   if (choice == true) {
  //     var customerId = prompt("Add Customer ID");
  //     document.cookie = `customerID = ${customerId}`;
  //     sessionStorage.setItem("CUSTOMER_ID", customerId);
  //   }
  // } else {
  //   var choice = confirm("Use same Customer ID or change");
  //   if (choice == false) {
  //     var customerId = prompt("Add Customer ID");
  //     document.cookie = `customerID = ${customerId}`;
  //     sessionStorage.setItem("CUSTOMER_ID", customerId);
  //   }
  // }

  //get customer addresses
  //     console.log(
  //       (customerID = (document.cookie.match(
  //         /^(?:.*;)?\s*CUSTOMER_ID\s*=\s*([^;]+)(?:.*)?$/
  //       ) || [, null])[1])
  //     );
  //     //console.log(sessionStorage.getItem("CUSTOMER_ID"));
  //     $.ajax({
  //       url: baseURL + "api/customer/Account/GetUserDetails",
  //       type: "POST",
  //       data: {
  //         Customer_ID: customerID,
  //       },
  //       success: function (result) {
  //         console.log(result);
  //         var desktopName = result.Customer_Name;
  //         if (desktopName.length > 10) {
  //           desktopName = desktopName.substring(0, 8) + "..";
  //         }
  //         //For Desktop Name Change
  //         $(".accountNameDesktop").html(
  //           `<a href="${baseURL}customer/account">${desktopName}</a>`
  //         );

  //         $(".accountNameMobile").html(
  //           `<a href="${baseURL}customer/account" class="truncate-account-name">${result.Customer_Name}</a>`
  //         );

  //         $(".customerProfileImage").html(
  //           `<img src="https://img.icons8.com/color/32/000000/circled-user-male-skin-type-5--v1.png"/>`
  //         );
  //         //For Mobile Name Change
  //         // $(".accountNameMobile").html(
  //         //   `<a href="${baseURL}customer/account">${result.Customer_Name}</a>`
  //         // );
  //         // $(".customerProfileImageMobile").html(
  //         //   `<img src="https://img.icons8.com/color/32/000000/circled-user-male-skin-type-5--v1.png"/>`
  //         // );
  //       },
  //       error: function (result) {
  //         console.log(result);
  //       },
  //     });

  //     $.ajax({
  //       url: baseURL + "api/customer/Account/getAddress",
  //       type: "POST",
  //       data: {
  //         Customer_ID: customerID,
  //       },
  //       success: function (result) {
  //         console.log("inside success");
  //         console.log(result);
  //         $(".address-container").prepend(`<div class="row saved-addresses">
  //                         <div class="col text-left"><b>SAVED ADDRESSES</b></div>
  //                       </div>`);
  //         result.forEach((element) => {
  //           var item = `<div class="row">
  //           <div class="col text-left">${element.Address_Name}</div>
  //           <p style="text-align: left;">${element.Address_Line_1}, ${element.Landmark}, ${element.City}, ${element.State}</p>
  //           <hr>
  //         </div>`;

  //           $(".saved-addresses").after(item);
  //         });
  //       },
  //       error: function (res) {
  //         console.log("inside error");
  //         console.log(res);
  //       },
  //     });

  //     console.log(addressDetails);
  //     $(".currentAddress").text(
  //       `${addressDetails.locality}, ${addressDetails.arealevel3}, ${addressDetails.arealevel3}`
  //     );
  //   };
}
