var vendorCategoriesForProd = [];
var vendorSpecialitiesForProd = [];
var ProductImages = [];
var img1flag;
var img2flag;
var img3flag;
var imageIsImage;
var imageIsImageTwo;
var imageIsImageThree;
var ImageURL;
var vendorProductNames = [];
var SpecialitiesArray = [];
var imagesNotChanges = false;
var currentEditedproductName;
var currentEditedproductId;
var VendorCropper = "";
var currentProductID = "";
var productKeyBaseURL = "";
var globalNewImageURL = "";

import s3Util from "../helpers/s3Util";
var s3Object = new s3Util();

// import Cropper from "cropperjs";
// import vendor_image_cropperjs from "./vendor_image_cropperjs";

export default class Vendor_product_service_listing {
  constructor() {
    this.formstep1 = $("#formstep1");
    this.formstep2 = $("#formstep2");
    this.formstep3 = $("#formstep3");
    this.formstep4 = $("#formstep4");
    this.formstep5 = $("#formstep5");

    this.inputGSTRegistered = $("#inputGSTRegistered");

    console.log("in constructor of Vendor_product_service_listing");
    this.listingPageSmtBtn = $("#listingPageSmtBtn");
    this.listingPagePrevBtn = $("#listingPagePrevBtn");
    this.productServicesListingPage = $(".product-services-listing-page");
    this.GSTDetails_page = $(".GSTDetails-page");
    this.Products_Services_page4 = $(".Products-Services-page4");
    this.uploadDocuments_page5 = $(".vendorUploadDocuments");
    this.inputProductServiceName = $("#inputProductServiceName");

    this.inputCategory = $("#inputCategory");
    this.NoProductsError = $("#NoProductsError");

    this.inputPrice = $("#inputPrice");
    this.inputQuantity = $("#inputQuantity");
    this.checkNonVeg = $("#checkNonVeg");
    this.checkreco = $("#checkreco");
    this.currentProductChanged = $("#currentProductChanged");

    this.addNewProductBtn = $("#addNewProductBtn");
    this.page4SubmitBtn = $("#page4SubmitBtn");
    this.productCards = $("#productCards");
    this.ProductsServicesForm4 = $("#Products-Services-Form4");

    this.AddNewProductsServicespage4 = $(".AddNew-Products-Services-page4");
    this.productSericeDetailsDiv = $("#productSericeDetailsDiv");

    this.inputProductServiceNameError = $("#inputProductServiceNameError");
    this.discriptionError = $("#discriptionError");
    this.categoryError1 = $("#categoryError1");
    this.GSTPercentError = $("#GSTPercentError");
    this.priceError = $("#priceError");
    this.quantityError = $("#quantityError");
    this.imageOneError = $(".image-1-error");
    this.imageTwoError = $(".image-2-error");
    this.imageThreeError = $(".image-3-error");

    this.specicalityError = $("#speciality-error");
    // Registering Events
    this.bindEvents();
  }
  bindEvents = () => {
    // console.log("in bind");
    this.listingPageSmtBtn.on("click", this.ValidateAtleastOneProduct);
    this.listingPagePrevBtn.on("click", this.ListingPagePrevAction);
    this.addNewProductBtn.on("click", this.addNewProduct);
    this.page4SubmitBtn.on("click", this.displayProducts);

    this.ProductsServicesForm4.on("focus", "input[type=number]", function (e) {
      $(this).on("wheel.disableScroll", function (e) {
        // console.log("inhere");
        e.preventDefault();
      });
    });
  };
  displayProducts = (e) => {
    this.NoProductsError.html("");
    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/GetProductsByCategory",

      headers: { Authorization: X_API_KEY },
      success: this.doDisplayProdsByCategory,
    });
  };
  doDeleteProduct = (e) => {
    e.preventDefault();
    console.log("in doDeleteProduct");
    console.log(e.currentTarget);
    console.log(e.target.classList[1]);

    console.log(e);
    var deletedProdServName = e.target.attributes[2]["nodeValue"];
    console.log(e.target.classList[2]);
    console.log(e.target.attributes[2]["nodeValue"]);

    $.ajax({
      type: "POST",
      url: baseURL + "api/vendor/deleteProductByName",
      headers: { Authorization: X_API_KEY },
      data: {
        productServiceName: deletedProdServName,
      },
      success: function (res) {
        console.log(res);
      },
    });
    $.ajax({
      type: "Post",
      url: baseURL + "api/Vendor/DeleteProduct",
      async: false,
      data: { Product_ID: e.target.classList[1] },
      headers: { Authorization: X_API_KEY },
      success: this.displayProducts,
    });
  };
  doEditProduct = (e) => {
    e.preventDefault();
    console.log("in doEditProduct");
    console.log(e.currentTarget);
    console.log(e.target.classList[1]);
    console.log(e);
    // this.inputProductServiceName.val(" ");
    // this.inputProductServiceName.val(e.target.classList[1]);
    this.currentProductChanged.prop("checked", true);

    currentProductId = e.target.classList[1];
    this.Products_Services_page4.removeClass("d-none");

    this.productServicesListingPage.addClass("d-none");

    $.ajax({
      type: "POST",
      url: baseURL + "api/Vendor/GetProduct",
      async: false,
      data: { Product_ID: e.target.classList[1] },
      headers: { Authorization: X_API_KEY },
      success: this.displayProductDetails,
    });
  };
  doDisplayProdsByCategory = (res) => {
    console.log("Products:");
    //  console.log(res);

    //To make the collapsable boxes function
    var collapsableBoxCounter = 444;
    //To gie unique ids to the speciality checkboxes
    var ProductCount = 1;

    //to avoid duplicates
    $("#ProductServiceListingDiv").empty();
    res.Categories.forEach((element) => {
      console.log(element);
      console.log(res[element]);

      //To load specialities of current Category
      var itemBody = ``;

      if (!res[element][0]) {
        return;
      }
      //to append specialities
      res[element].forEach((element) => {
        console.log(element);

        // element.Image_URL = "Vendor/0001_default/Product Images/default product one/01.png";
        var newImageURL = element.Image_URL.replace(/01.png$/, "");

        console.log(newImageURL);

        var ProdIconImg = element.Product_ID + "ProdIconImg";

        itemBody = `${itemBody}<div class="row border mt-3 mb-3">
        
        
        <!-- col 1 picture -->
        <div class="col-md-2 col-4 mt-3 mb-3 d-flex justify-content-center">
        <img class="img-load img-fluid my-auto ${ProdIconImg}" width="70%" onerror="this.src='assets/src/images/pancake.jpeg'" alt="Image">
        </div>

        <!-- col 2 written part -->
        <div class="col-md-8 col-8 mt-3 ">
            <h6 class="productListing-Truncate2">${element.Product_Service_Name}</h6>
            <span class="productListing-Truncate2">${element.Description}</span>
        </div>
        <!-- <div class="col-8 col-md-0"></div> -->
        <div class="col-md-2 col-12 mt-3 my-auto d-flex justify-content-end">
        <button type="button" class="editProduct ${element.Product_ID}" style="border: none; background-color: transparent;outline: none;">EDIT</button><button type="button" class="deleteProduct ${element.Product_ID} ${element.Product_Service_Name}" value="${element.Product_Service_Name}"style="margin-left:30px;border: none; background-color: transparent;outline: none;">DELETE</button>
        
        </div>


        </div>`;
        ProductCount++;

        //Load Product/Service Icon Image on listing page
        var productImageURL = element.Image_URL;
        s3Object.getS3Image(productImageURL, null, ProdIconImg);
        setTimeout(
          s3Object.getS3Image(productImageURL, null, ProdIconImg),
          10000
        );
      });

      var item1 = `<div id="accordion-${collapsableBoxCounter}" role="tablist" aria-multiselectable="true" class="o-accordion">
      <div class="card multi">
        <div class="card-header" role="tab" id="headingOne-${collapsableBoxCounter}">
            <h5 class="mb-0">
                <div class="row row justify-content-between">
                    <div class="col-11">
                        &nbsp;
                        <p>${element}</p>
                    </div>
                    <div class="col-1 d-flex justify-content-end align-items-center">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne-${collapsableBoxCounter}" aria-expanded="true" aria-controls="collapseOne-${collapsableBoxCounter}">
                            <img src="assets/src/images/arrow-down-circle.svg" width="20" height="20" class="mx-auto d-block" alt="^">
                        </a>
                    </div>
                </div>
            </h5>
        </div>

        <!-- Products COLLAPSABLE CARD -->
        <div id="collapseOne-${collapsableBoxCounter}" class="collapse show" role="tabpanel" aria-labelledby="headingOne-${collapsableBoxCounter}">
            <div class="card-block">
                <div class="container">
                    &nbsp;



                    <!-- Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop -->
                    <div class="row justify-content-center" id="SpecialitiesDisplay" >${itemBody} 
                    &nbsp;
                     </div></div></div></div></div></div>`;

      $("#ProductServiceListingDiv").append(item1);
      collapsableBoxCounter++;
    });

    // if (!res.code) {
    //   res.forEach((element) => {
    //     console.log(element);
    //     var item = `<div class="row border mt-3">

    //     <!-- col 1 picture -->
    //     <div class="col-md-2 col-4 mt-3 mb-3 d-flex justify-content-center">
    //         <img src="assets/src/images/pancake.jpeg" class="img-load img-fluid my-auto" width="70%">
    //     </div>

    //     <!-- col 2 written part -->
    //     <div class="col-md-8 col-8 mt-3 ">
    //         <h6 class="productListing-Truncate2">${element.Product_Service_Name}</h6>
    //         <span class="productListing-Truncate2">${element.Description}</span>
    //     </div>
    //     <!-- <div class="col-8 col-md-0"></div> -->
    //     <div class="col-md-2 col-12 mt-3 my-auto d-flex justify-content-end">
    //     <button type="button" class="editProduct ${element.Product_ID}" style="border: none; background-color: transparent;outline: none;">EDIT</button><button type="button" class="deleteProduct ${element.Product_ID}" style="margin-left:30px;border: none; background-color: transparent;outline: none;">DELETE</button>

    //     </div>

    // </div>`;
    //     $("#productCards").append(item);
    //     console.log($(".editProduct"));
    //   });
    $(".deleteProduct").on("click", this.doDeleteProduct);
    $(".editProduct").on("click", this.doEditProduct);
  };

  ListingPagePrevAction = (e) => {
    e.preventDefault();
    this.GSTDetails_page.removeClass("d-none");
    this.productServicesListingPage.addClass("d-none");
    this.formstep4.removeClass("current-item");
    this.formstep3.addClass("current-item");
  };

  showCategoryOptionsProd = (res) => {
    vendorCategoriesForProd = res;
  };

  showSpecialityOptionsProd = (res) => {
    vendorSpecialitiesForProd = res;
  };
  displayProductDetails = (res) => {
    currentProductID = res.Product_ID;
    var itemCategories = ``;
    var itemSpecialities = ``;
    var itemCategorySelected = null;
    var itemSpecialitySelected = null;
    var SpecialityCount = 100;

    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/GetVendorCategories",
      async: false,
      headers: { Authorization: X_API_KEY },

      success: this.showCategoryOptionsProd,
    });
    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/GetVendorSpecialities",
      async: false,
      headers: { Authorization: X_API_KEY },

      success: this.showSpecialityOptionsProd,
    });
    vendorCategoriesForProd.forEach((element) => {
      //to select the option
      if (element.Picklist_ID == res.Picklist_ID) {
        itemCategorySelected = "selected";
      } else {
        itemCategorySelected = null;
      }
      //   console.log(element.Picklist_display_value);
      //   console.log(element.Picklist_display_value);
      itemCategories = `${itemCategories} <option class="categoryOptions" value='{"picklistId":"${element.Picklist_ID}","ExtraValue":"${element.extra_value}"}' ${itemCategorySelected}>${element.Picklist_display_value}</option>`;

      //   this.itemCategories.append(item);
    });
    vendorSpecialitiesForProd.forEach((element) => {
      itemSpecialitySelected = null;
      res.Product_Speciality.forEach((ele) => {
        console.log(element.Picklist_ID);
        if (element.Picklist_ID == ele) {
          itemSpecialitySelected = "checked";
        } else {
          // itemSpecialitySelected = null;
        }
      });

      // if(element.Picklist_display_value==)
      // var item = `<option value="${element.Picklist_display_value}">${element.Picklist_display_value}</option>`;
      itemSpecialities = `${itemSpecialities}<div class="col-4 col-md-2 d-flex justify-content-center my-2 "><div class="custom-control custom-checkbox image-checkbox">
        <input type="checkbox" name="speciality-check" class="EditspecialityCheckBox custom-control-input justify-content-center" id="specialityck${SpecialityCount}" value="${element.Picklist_ID}" ${itemSpecialitySelected}>
        <label class="custom-control-label d-flex justify-content-center" for="specialityck${SpecialityCount}">
            <img src="assets/src/images/pancake.jpeg" alt="#" class="img-fluid rounded-circle specialityImage">
        </label>
        <label class="d-flex justify-content-center">
            <span class="fs-12 text-lowercase text-center">${element.Picklist_display_value}</span>
        </label>
        </div></div>`;
      SpecialityCount++;

      // categoryCount++;
    });
    console.log(vendorCategoriesForProd);
    var newImageURL = res.Image_URL.replace(/01.png$/, "");
    globalNewImageURL = newImageURL;

    console.log(newImageURL);
    var isRecommended = res.Recommended_flag ? `checked` : ``;
    var isNonVeg = res.Veg_NonVeg_flag ? `checked` : ``;

    console.log(res.Veg_NonVeg_flag);
    console.log(isNonVeg);

    console.log(isRecommended);
    console.log(res);
    currentEditedproductName = res.Product_Service_Name;
    currentEditedproductId = res.Product_ID;

    $("#productSericeDetailsDiv").empty();
    var item = ` <div id="accordion-2" role="tablist" aria-multiselectable="true" class="o-accordion">
    <div class="card multi">
        <!-- Product Services details CARD HEADER -->
        <div class="card-header" role="tab" id="headingOne-1">
            <h5 class="mb-0">
                <div class="row row justify-content-between">
                    <div class="col-11">
                        &nbsp;
                        <p>Products/Services listing</p>
                    </div>
                    <div class="col-1 d-flex justify-content-end align-items-center">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne-3" aria-expanded="true" aria-controls="collapseOne-3">
                            <img src="assets/src/images/arrow-down-circle.svg" width="20" height="20" class="mx-auto d-block" alt="^">
                        </a>
                    </div>
                </div>
            </h5>
        </div>
        <!-- Product Services details CARD -->
        <div id="collapseOne-3" class="collapse show" role="tabpanel" aria-labelledby="headingOne-3">
            <div class="card-block">
                <div class="container">
                    &nbsp;
                    <div class="mb-3">
                        <label for="inputProductServiceName" class="form-label">Product or Service Name</label>
                        <input type="text" class="form-control asdf" id="inputProductServiceName" data-prodID=${
                          res.Product_ID
                        } name="Product_Service_Name" aria-describedby="Product_Service_NameHelp" value="${
      res.Product_Service_Name
    }">
                        <span class='text-danger' id='inputProductServiceNameError' ></span>
                    </div>
                    <div class="mb-3">
                        <input id="x" type="hidden" name="content" value="${
                          res.Description
                        }">
                        <trix-editor input="x"></trix-editor>
                        <span class='text-danger' id="discriptionError"></span>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label for="inputState" class="form-label">Category</label>
                                <select class="form-select" aria-label="Default select example" id="inputCategory" name="Picklist_ID">
                                    <option class="categoryOptions"></option>${itemCategories}
                                </select>
                                <span class='text-danger' id="categoryError1"></span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="mb-3">
                                <label for="inputQuantity" class="form-label">Item Quantity </label>
                                <input type="number" class="form-control" id="inputQuantity" name="Quantity" value="${
                                  res.Quantity
                                }">
                                (For service please enter number of enquiries you can service (e.g.100))
                                <span class='text-danger' id="quantityError"></span>
                            </div>
                        </div>
                    </div>
                    &nbsp;
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label for="inputPrice" class="form-label">Price (without GST)</label>
                                <input type="number" class="form-control " name="Price" id="inputPrice" value="${
                                  res.Price
                                }">
                                <span class='text-danger' id="priceError"></span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="mb-3 showGSTReg d-none">
                                <label for="inputGSTPercent" class="form-label">GST %</label>
                                <input type="number" class="form-control" id="inputGSTPercent" name="GSTPercent_Address" aria-describedby="GSTPercent_AddressHelp" value="${
                                  res.GST
                                }">
                                <span class='text-danger' id="GSTPercentError"></span>
                            </div>  
                        </div>
                    </div>

                    &nbsp;
                    <div class="row mb-3 ">

                        <label for="inputBusinessName" class="form-label">Specialities for this item</label>
                        <div id='specicalityError' class="text-danger"></div> 
                        <p>
                        <div id="ProductSpecialityBox" class="container d-flex flex-wrap">
                        ${itemSpecialities}
                            <!-- INTEGRATION OF SPECIALITIES HERE -->
                            <!-- <div><label class="mx-1 "><input type="checkbox" name="speciality-check" value="1" class="specialityCheckBox"><span>Italian</span></label></div> -->
                            <!-- <div><label class="mx-1 "><input type="checkbox" name="speciality-check" value="2" class="specialityCheckBox"><span>Indian</span></label></div> -->
                        </div>
                        </p>
                        <!-- <div class="d-flex bd-highlight">
                        <div class="p-2 flex-grow-1 bd-highlight">
                            <input type="text" class="form-control" id="inputContactPersonName" name="Contact_Person" aria-describedby="contactPersonHelp" placeholder="Speciality 1">

                        </div>
                        <div class="p-2 flex-grow-1 bd-highlight">
                            <input type="text" class="form-control" id="inputContactPersonName" name="Contact_Person" aria-describedby="contactPersonHelp" placeholder="Speciality 2">

                        </div>
                        <div class="p-2 flex-grow-1 bd-highlight">
                            <input type="text" class="form-control" id="inputContactPersonName" name="Contact_Person" aria-describedby="contactPersonHelp" placeholder="Speciality 3">

                        </div>
                        <div class="p-2 flex-grow-1 bd-highlight">
                            <input type="text" class="form-control" id="inputContactPersonName" name="Contact_Person" aria-describedby="contactPersonHelp" placeholder="Speciality 4">

                        </div>
                    </div> -->

                        <!-- <span class='text-danger' id="inputAddressError"></span> -->
                        &nbsp;

                        <div class="Vendor_image_cropper_page d-none">
                            <div class="container">
                                <h5>Upload Images</h5>
                                <form method="post" id="S3ImageSubmissionForm">
                                    <input type="file" name="image" class="image">
                                    <input type="file" name="image" class="image">
                                    <input type="file" name="image" class="image">
                                </form>
                            </div>
                        </div>

                        <label for="inputBusinessName" class="form-label">Upload images for this item</label>
                        <div class="d-flex bd-highlight">
                            <!-- <div class="container"> -->
                            <div class="row">
                                <div class="col-md-3">
                                    <input type="file" class="form-control imageCropInput" id="inpFile1" name="inpFile1" value="imageCropInputPreviewForEdit1" aria-describedby="">
                                    <div class="image-1-error text-danger"></div>
                                    <br>
                                    <div class="img-preview" >
                                        <img src="${
                                          imageS3baseURL +
                                          newImageURL +
                                          "01.png?" +
                                          Math.random()
                                        }" alt="" class="image-preview-1 prodImgPreviewForEdit" id="imageCropInputPreviewForEdit1" width="220px" height="auto">                            
                                    </div>
                                </div>
                                <div class="col-md-3 ">
                                    <input type="file" class="form-control imageCropInput " id="inpFile2" name="inpFile2" value="imageCropInputPreviewForEdit2" aria-describedby="">
                                    <div class="image-2-error text-danger"></div>
                                    <br>
                                    <div class="img-preview-two " >
                                        <img src="${
                                          imageS3baseURL +
                                          newImageURL +
                                          "02.png?" +
                                          Math.random()
                                        }" alt="" class="image-preview-2 prodImgPreviewForEdit" id="imageCropInputPreviewForEdit2" width="220px" height="auto">
                                       
                                    </div>
                                </div>
                                <div class="col-md-3 ">
                                    <input type="file" class="form-control imageCropInput" id="inpFile3" name="inpFile3" value="imageCropInputPreviewForEdit3" aria-describedby="">
                                    <div class="image-3-error text-danger"></div>
                                    <br>
                                    <div class="img-preview-three">
                                        <img src="${
                                          imageS3baseURL +
                                          newImageURL +
                                          "03.png?" +
                                          Math.random()
                                        }" alt="" class="image-preview-3 prodImgPreviewForEdit" id="imageCropInputPreviewForEdit3" width="220px" height="auto">
                                        
                                    </div>
                                </div>
                            </div>
                            
                            <!-- </div> -->
                            <!-- <div>
                            <div class="p-2 flex-grow-1 bd-highlight">
                                <input type="file" class="form-control" id="inpFile" name="inpFile" aria-describedby="" >
                            </div>
                            <div class="img-preview" id="imagePreview">
                                <img src="" alt="Image Preview 1" class="image-preview">
                            </div>
                            
                        </div> -->

                            <!-- <div>
                            <div class="p-2 flex-grow-1 bd-highlight">
                                <input type="file" class="form-control" id="inpFileTwo" name="inpFileTwo" aria-describedby="" >
                            </div>
                            <div class="img-preview-two" id="imagePreviewTwo">
                                <img src="" alt="Image Preview 2" class="image-preview-two">
                            </div>
                            
                        </div> -->

                            <!-- <div>
                            <div class="p-2 flex-grow-1 bd-highlight">
                                <input type="file" class="form-control" id="inpFileThree" name="inpFileThree" aria-describedby="" >
                            </div>
                            <div class="img-preview-three" id="imagePreviewThree">
                                <img src="" alt="Image Preview 3" class="image-preview-three">
                            </div>
                            
                        </div> -->
                        </div>
                        <!-- <span class='text-danger' id="inputAddressError"></span> -->
                        &nbsp;

                        <div class="d-flex bd-highlight">
                            <!-- <label for="checkreco" class="form-label">Recommended</label> -->
                            <div class="p-2 pl-4 pt-3 bd-highlight d-flex justify-content-end ">
                                <label><input class="form-check-input align-content-center checkbox-size" type="checkbox" value="Recommended"  id="checkreco" ${isRecommended}>  <div class="ms-3 mt-2">Recommended</div></label>
                            </div>&emsp;&emsp;&emsp;
                            <div id="NonVegDiv">
                                <div class="p-2 pl-4 pt-3 bd-highlight d-flex justify-content-end ">
                                    <label><input class="form-check-input align-content-center checkbox-size" type="checkbox" value="Non Veg" id="checkNonVeg" ${isNonVeg}><div class="ms-3 mt-2">Non Veg</div></label>
                                </div>
                            </div>
                        </div>
                        <!-- <span class='text-danger' id="inputAddressError"></span> -->
                        &nbsp;

                    </div>

                </div>
            </div>
        </div>
    </div>
    &nbsp;
    &nbsp;
    </div>
    <div class="page_4_btns">
      <div class="row">
            <div class="col justify-content-start">
                  <button type="button" id="EditProductPrevBtn" class="btn btn-warning bg-orange basicDetailsBtn">PREVIOUS</button>
              </div>
              <div class="col d-flex justify-content-end ">
                  <button type="button" id="EditProductSubmitBtn" class="btn btn-warning text-uppercase bg-orange basicDetailsBtn">CONTINUE</button>
            </div>
       </div>
     </div>
      &nbsp;
     `;

    $("#productSericeDetailsDiv").append(item);

    //Load Product Images
    // imageCropInputPreviewForEdit1
    // s3Object.getS3Image(prodCategoryImageURL, null, prodCategoryImageTagClassName);

    var prodImageURL_1 = newImageURL + "01.png";
    s3Object.getS3Image(prodImageURL_1, "imageCropInputPreviewForEdit1", null);
    var prodImageURL_2 = newImageURL + "02.png";
    s3Object.getS3Image(prodImageURL_2, "imageCropInputPreviewForEdit2", null);
    var prodImageURL_3 = newImageURL + "03.png";
    s3Object.getS3Image(prodImageURL_3, "imageCropInputPreviewForEdit3", null);

    console.log(
      "Product images 3",
      prodImageURL_1,
      prodImageURL_2,
      prodImageURL_3
    );

    // new vendor_image_cropperjs();
    cropperMain.doCroppingAction();

    this.getAllVendorProductsNames();

    if (this.inputGSTRegistered.is(":checked")) {
      $(".showGSTReg").removeClass("d-none");
    } else {
      $(".showGSTReg").addClass("d-none");
    }

    imagesNotChanges = true;
    img1flag = true;
    img2flag = true;
    img3flag = true;
    const inpFileOne1 = document.getElementById("inpFile1");

    inpFileOne1.addEventListener("change", function () {
      console.log("Image changed Do validation");
      console.log(inpFileOne1);

      img1flag = true;
      var imgExpression = /.*\.(jpg|jpeg|png|JPG|JPEG|PNG)$/;
      if (!inpFileOne1.files[0]) {
        $("#imagePreview1").empty();
        if (globalNewImageURL) {
          var prodImageURL_1 = globalNewImageURL + "01.png";
          s3Object.getS3Image(
            prodImageURL_1,
            "imageCropInputPreviewForEdit1",
            null
          );
        }
      } else {
        if (!inpFileOne1.files[0]["name"].match(imgExpression)) {
          console.log("inside image validate if");
          img1flag = false;
          $("#imagePreview1").empty();
          $(".image-1-error").html("Please select an image file");
        } else {
          console.log("inside image validate else");

          $(".image-1-error").html("");

          if (inpFileOne1.files[0]) {
            const reader = new FileReader();

            // previewFileOne1.style.display = "block";
            $("#imagePreview1").empty();
            reader.addEventListener("load", function () {
              console.log(this);
              var image1 = {
                imageFile: this.result,
                imageNo: "01",
              };
              ProductImages.push(image1);
              // previewFileOne1.setAttribute("src", this.result);
              $("#imagePreview1").append(
                `<img src="${this.result}" alt="" class="image-preview-1 prodImgPreviewForEdit"  id="imageCropInputPreviewForEdit1" width="220px" height="auto"></img>`
              );
            });

            reader.readAsDataURL(inpFileOne1.files[0]);
          }
        }
      }
    });
    const inpFiletwo1 = document.getElementById("inpFile2");
    inpFiletwo1.addEventListener("change", function () {
      console.log("Image changed Do validation");
      console.log(inpFiletwo1);

      img2flag = true;

      var imgExpression = /.*\.(jpg|jpeg|png|JPG|JPEG|PNG)$/;
      if (!inpFiletwo1.files[0]) {
        $("#imagePreview2").empty();
        if (globalNewImageURL) {
          var prodImageURL_2 = globalNewImageURL + "02.png";
          s3Object.getS3Image(
            prodImageURL_2,
            "imageCropInputPreviewForEdit2",
            null
          );
        }
      } else {
        if (
          !inpFiletwo1.files[0]["name"].match(imgExpression) ||
          !inpFiletwo1.files[0]["name"]
        ) {
          console.log("inside image validate if");
          img2flag = false;
          $(".image-2-error").html("Please select an image file");
          $("#imagePreview2").empty();
        } else {
          console.log("inside image validate else");

          $(".image-2-error").html("");

          if (inpFiletwo1.files[0]) {
            const reader = new FileReader();

            // previewFileOne1.style.display = "block";
            $("#imagePreview2").empty();
            reader.addEventListener("load", function () {
              console.log(this);
              var image2 = {
                imageFile: this.result,
                imageNo: "02",
              };
              ProductImages.push(image2);
              // previewFileOne1.setAttribute("src", this.result);
              $("#imagePreview2").append(
                `<img src="${this.result}" alt="" class="image-preview-2 prodImgPreviewForEdit"  id="imageCropInputPreviewForEdit2" width="220px" height="auto"></img>`
              );
            });

            reader.readAsDataURL(inpFiletwo1.files[0]);
          }
        }
      }
    });

    const inpFileThree1 = document.getElementById("inpFile3");
    inpFileThree1.addEventListener("change", function () {
      console.log("Image changed Do validation");
      console.log(inpFileThree1);

      img3flag = true;
      var imgExpression = /.*\.(jpg|jpeg|png|JPG|JPEG|PNG)$/;
      if (!inpFileThree1.files[0]) {
        $("#imagePreview3").empty();
        if (globalNewImageURL) {
          var prodImageURL_3 = globalNewImageURL + "03.png";
          s3Object.getS3Image(
            prodImageURL_3,
            "imageCropInputPreviewForEdit3",
            null
          );
        }
      } else {
        if (
          !inpFileThree1.files[0]["name"].match(imgExpression) ||
          !inpFileThree1.files[0]["name"]
        ) {
          console.log("inside image validate if");
          img3flag = false;
          $(".image-3-error").html("Please select an image file");
          $("#imagePreview3").empty();
        } else {
          console.log("inside image validate else");

          $(".image-3-error").html("");

          if (inpFileThree1.files[0]) {
            const reader = new FileReader();

            // previewFileOne1.style.display = "block";
            $("#imagePreview3").empty();
            reader.addEventListener("load", function () {
              console.log(this);
              var image3 = {
                imageFile: this.result,
                imageNo: "03",
              };
              ProductImages.push(image3);
              // previewFileOne1.setAttribute("src", this.result);
              $("#imagePreview3").append(
                `<img src="${this.result}" alt="" class="image-preview-3 prodImgPreviewForEdit"  id="imageCropInputPreviewForEdit3" width="220px" height="auto"></img>`
              );
            });

            reader.readAsDataURL(inpFileThree1.files[0]);
          }
        }
      }
    });
    $("#EditProductPrevBtn").on("click", this.doEditPagePrev);
    $("#EditProductSubmitBtn").on("click", this.doEditProductValidation);

    $("#inputCategory").on("change", this.vegNonVegShow);
    $("#inputCategory").on("change", this.showSpecialityByCategory);

    this.vegNonVegShowInEdit();
  };
  doEditProductValidation = (e) => {
    e.preventDefault();
    console.log("in doEditProductValidation");

    const editProdFileInput2_File =
      document.getElementById("inpFile2").files[0];
    const editProdFileInput3_File =
      document.getElementById("inpFile3").files[0];

    if (editProdFileInput2_File === undefined) {
      $(".image-2-error").html("");
    }
    if (editProdFileInput3_File === undefined) {
      $(".image-3-error").html("");
    }

    let isProductServiceNameValidated = this.ProductServiceName_validate();
    if (isProductServiceNameValidated) {
      console.log("ProductServiceName VALID");
      $("#inputProductServiceNameError").html("");
    }

    let isDiscriptionValidated = this.Discription_validate();
    if (isDiscriptionValidated) {
      console.log("Discription VALID");
      $("#discriptionError").html("");
    }

    let isCategoryValidated = this.Category_validate();
    if (isCategoryValidated) {
      console.log("Category VALID");
      $("#categoryError1").html("");
    }

    let isPriceValidated = this.Price_validate();
    if (isPriceValidated) {
      console.log("Price VALID");
      $("#priceError").html("");
    }

    let isQuantityValidated = this.Quantity_validate();
    if (isQuantityValidated) {
      console.log("Quantity VALID");
      $("#quantityError").html("");
    }

    let isGSTPercentValidated = this.GSTPercent_validate();
    if (isGSTPercentValidated) {
      console.log("GSTPercent VALID");

      $("#GSTPercentError").html("");
    }

    let imageSelected = this.imageSelected_validate();
    if (imageSelected || imagesNotChanges) {
      console.log("image select VALID");
      $(".image-1-error").html("");
    }

    if (imageIsImage == false) {
      $(".image-1-error").html("Please select an image file");
    }

    if (imageIsImageTwo == false) {
      $(".image-2-error").html("Please select an image file");
    }

    if (imageIsImageThree == false) {
      $(".image-3-error").html("Please select an image file");
    }
    if (imagesNotChanges == true) {
      $(".image-1-error").html("");
      $(".image-2-error").html("");
      $(".image-3-error").html("");
    }
    let specialitySelected = this.editspecialitySelected_validate();
    if (specialitySelected) {
      console.log("speciality select VALID");
      $("#specicalityError").html(" ");
    }

    //////////////////////////////////////////////////////////
    //   FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS REQUIRED  //
    //////////////////////////////////////////////////////////
    // img2flag = true;
    // img3flag = true;
    //2//////////////////////////////////////////////////////////////////////////
    //   FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS MANDATORY. CAN ENTER ALL 3  //
    ////////////////////////////////////////////////////////////////////////////
    if (editProdFileInput2_File === undefined) {
      img2flag = true;
    }
    if (editProdFileInput3_File === undefined) {
      img3flag = true;
    }
    ////////////////////////////////////////////////////////////////////////////

    console.log(isProductServiceNameValidated);
    console.log(isDiscriptionValidated);
    console.log(isCategoryValidated);
    console.log(isPriceValidated);
    console.log(isQuantityValidated);
    console.log(isGSTPercentValidated);
    console.log(imageSelected || imagesNotChanges);
    console.log(specialitySelected);
    console.log(img1flag);
    console.log(img2flag);
    console.log(img3flag);

    if (
      isProductServiceNameValidated &&
      isDiscriptionValidated &&
      isCategoryValidated &&
      isPriceValidated &&
      isQuantityValidated &&
      isGSTPercentValidated &&
      (imageSelected || imagesNotChanges) &&
      specialitySelected &&
      img1flag &&
      img2flag &&
      img3flag
    ) {
      //Resetting imagesNotChanges flag value
      imagesNotChanges = false;
      this.EditProductSubmitForm();
      console.log("ALL FIELDS VALID...VALIDATION OVER");
    }
  };
  doEditPagePrev = () => {
    this.Products_Services_page4.addClass("d-none");
    this.productServicesListingPage.removeClass("d-none");
  };

  EditProductSubmitForm = () => {
    console.log("EditProductSubmitForm");
    this.Products_Services_page4.addClass("d-none");
    this.productServicesListingPage.removeClass("d-none");
    currentEditedproductName = null;
    var product_service_data = [];

    const selectedPicklistId = JSON.parse($("#inputCategory").val());

    SpecialitiesArray = [];

    for (let i = 0; i < $(".EditspecialityCheckBox:checked").length; i++) {
      console.log($(".EditspecialityCheckBox:checked")[i].defaultValue);
      SpecialitiesArray[i] = $(".EditspecialityCheckBox:checked")[
        i
      ].defaultValue;
    }

    var recoFlag;
    if ($("#checkreco").is(":checked")) {
      recoFlag = 1;
    } else {
      recoFlag = 0;
    }
    var NonVegFlag;
    if ($("#checkNonVeg").is(":checked")) {
      NonVegFlag = 1;
    } else {
      NonVegFlag = 0;
    }
    var newEditProductImages = [];

    var editProdFCounter = 0;
    var editimagePosition = [];

    //////////////////////////////////////////////////////////
    //   FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS REQUIRED  //
    //////////////////////////////////////////////////////////
    // for (var i = 0; i < 3; i++)
    //2//////////////////////////////////////////////////////////////////////////
    //   FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS MANDATORY. CAN ENTER ALL 3  //
    ////////////////////////////////////////////////////////////////////////////
    const editProdFileInput1_File =
      document.getElementById("inpFile1").files[0];
    const editProdFileInput2_File =
      document.getElementById("inpFile2").files[0];
    const editProdFileInput3_File =
      document.getElementById("inpFile3").files[0];
    if (editProdFileInput1_File !== undefined) {
      editProdFCounter++;
      editimagePosition.push(0);
    }
    if (editProdFileInput2_File !== undefined) {
      editProdFCounter++;
      editimagePosition.push(1);
    }
    if (editProdFileInput3_File !== undefined) {
      editProdFCounter++;
      editimagePosition.push(2);
    }
    ////////////////////////////////////////////////////////////////////////////

    for (var i = 0; i < editProdFCounter; i++) {
      var j = editimagePosition[i];

      console.log(String($(".prodImgPreviewForEdit")[j]["currentSrc"]).length);
      if (String($(".prodImgPreviewForEdit")[j]["currentSrc"]).length > 200) {
        var image = {
          imageFile: $(".prodImgPreviewForEdit")[j]["currentSrc"],
          imageNo: j + 1,
        };
        newEditProductImages.push(image);

        var prodImgFile = $(".prodImgPreviewForEdit")[j]["currentSrc"];
        var prodImgFileNo = j + 1;
        var prodImgFileName = $("#inputProductServiceName").val();

        ImageURL = this.UploadProductImages(
          prodImgFile,
          prodImgFileNo,
          prodImgFileName
        );
      }
    }
    console.log(newEditProductImages);
    // $.ajax({
    //   type: "POST",
    //   url: baseURL + "api/Vendor/ImageUploadApi",
    //   async: false,

    //   data: {
    //     ProductImages: newEditProductImages,
    //     productServiceName: $("#inputProductServiceName").val(),
    //   },
    //   success: function (res) {
    //     console.log(res);
    //     ImageURL = res;
    //   },
    // });

    var element = document.getElementById("inputProductServiceName");
    var curr_Product_ID = element.dataset.prodid;
    console.log("curr_Product_ID");

    console.log(curr_Product_ID);

    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/getVendorDetails",
      headers: { Authorization: X_API_KEY },
      async: false,
      success: (result) => {
        productKeyBaseURL = `Vendor/${result.Image_URL}/Product Images/`;
      },
    });

    var product_detail = {
      Product_ID: curr_Product_ID,
      // Product_ID: currentEditedproductId,
      Product_Service_Name: $("#inputProductServiceName").val(),
      Picklist_ID: selectedPicklistId.picklistId,
      Description: $("trix-editor").val(),
      GST: $("#inputGSTPercent").val(),
      Quantity: $("#inputQuantity").val(),
      Price: $("#inputPrice").val(),
      // Image_URL: ImageURL,
      Image_URL:
        productKeyBaseURL + $("#inputProductServiceName").val() + "/01.png",
      Specialities: SpecialitiesArray,
      Recommended_flag: recoFlag,
      Veg_NonVeg_flag: NonVegFlag,
      ImagesUpdated: true,
      Product_Availability_Flag: 1,
    };

    product_service_data.push(product_detail);

    $.ajax({
      type: "POST",

      url: baseURL + "api/Vendor/AddUpdateProducts",
      async: false,

      headers: { Authorization: X_API_KEY },

      // data: this.Products_Services_Form4.serialize(),
      data: {
        product_service_data: product_service_data,
      },

      success: console.log("Successfully"),
    });

    this.displayProducts();
  };

  addNewProduct = (e) => {
    var newProductCategory = ``;
    var newProductSpecility = ``;
    var SpecialityCount = ``;

    e.preventDefault();
    console.log("allVendorSpecialities:");
    console.log(allVendorSpecialities);
    console.log("allVendorCategories:");
    console.log(allVendorCategories);
    //To display All vendor Categories for New Product
    allVendorCategories.forEach((element) => {
      newProductCategory = ` ${newProductCategory}<option class="categoryOptions" value='{"picklistId":"${element.Picklist_ID}","ExtraValue":"${element.extra_value}"}'>${element.Picklist_display_value}</option>`;
    });
    //To display All vendor Speciality for New Product
    // allVendorSpecialities.forEach((element) => {
    //   newProductSpecility = `${newProductSpecility}<div class="col-4 col-md-2 d-flex justify-content-center my-2 "><div class="custom-control custom-checkbox image-checkbox">
    //     <input type="checkbox" class="custom-control-input specialityCheckBox justify-content-center" id="specialityck${SpecialityCount}" value="${element.Picklist_ID}">
    //     <label class="custom-control-label d-flex justify-content-center" for="specialityck${SpecialityCount}">
    //         <img src="assets/src/images/pancake.jpeg" alt="#" class="img-fluid rounded-circle specialityImage">
    //     </label>
    //     <label class="d-flex justify-content-center">
    //         <span class="fs-12 text-lowercase text-center">${element.Picklist_display_value}</span>
    //     </label>
    //     </div></div>`;
    //   SpecialityCount++;
    // });
    console.log("inside addNewProduct");
    this.productServicesListingPage.addClass("d-none");
    $(".Products-Services-page4").removeClass("d-none");
    $("#productSericeDetailsDiv").empty();
    var ele = ` <div id="accordion-2" role="tablist" aria-multiselectable="true" class="o-accordion">
    <div class="card multi">
        <!-- Product Services details CARD HEADER -->
        <div class="card-header" role="tab" id="headingOne-1">
            <h5 class="mb-0">
                <div class="row row justify-content-between">
                    <div class="col-11">
                        &nbsp;
                        <p>Products/Services listing</p>
                    </div>
                    <div class="col-1 d-flex justify-content-end align-items-center">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne-3" aria-expanded="true" aria-controls="collapseOne-3">
                            <img src="assets/src/images/arrow-down-circle.svg" width="20" height="20" class="mx-auto d-block" alt="^">
                        </a>
                    </div>
                </div>
            </h5>
        </div>
        <!-- Product Services details CARD -->
        <div id="collapseOne-3" class="collapse show" role="tabpanel" aria-labelledby="headingOne-3">
            <div class="card-block">
                <div class="container">
                    &nbsp;
                    <div class="mb-3">
                        <label for="inputProductServiceName" class="form-label">Product or Service Name</label>
                        <input type="text" class="form-control" id="inputProductServiceName" name="Product_Service_Name" aria-describedby="Product_Service_NameHelp">
                        <span class='text-danger' id="inputProductServiceNameError"></span>
                    </div>
                    <div class="mb-3">
                        <input id="x" type="hidden" name="content" value="">
                        <trix-editor input="x"></trix-editor>
                        <span class='text-danger' id="discriptionError"></span>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label for="inputState" class="form-label">Category</label>
                                <select class="form-select" aria-label="Default select example" id="inputCategory" name="Picklist_ID">
                                    <option class="categoryOptions"></option>${newProductCategory}
                                </select>
                                <span class='text-danger' id="categoryError1"></span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="mb-3">
                                <label for="inputQuantity" class="form-label">Item Quantity </label>
                                <input type="number" class="form-control" id="inputQuantity" name="Quantity">
                                (For service please enter number of enquiries you can service (e.g.100))
                                <span class='text-danger' id="quantityError"></span>
                            </div>
                        </div>
                    </div>
                    &nbsp;
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label for="inputPrice" class="form-label">Price (without GST)</label>
                                <input type="number" class="form-control " name="Price" id="inputPrice">
                                <span class='text-danger' id="priceError"></span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="mb-3 showGSTReg d-none">
                                <label for="inputGSTPercent" class="form-label">GST %</label>
                                <input type="number" class="form-control" id="inputGSTPercent" name="GSTPercent_Address" aria-describedby="GSTPercent_AddressHelp">
                                <span class='text-danger' id="GSTPercentError"></span>
                            </div>
                        </div>
                    </div>

                    &nbsp;
                    <div class="row mb-3 ">

                        <label for="inputBusinessName" class="form-label">Specialities for this item</label>
                        <div id=speciality-error class="text-danger"></div>
                        <p>
                        <div id="ProductSpecialityBox" class="container d-flex flex-wrap"></div>
                        </p>


                        <span class='text-danger' id="specicalityError"></span> 
                        &nbsp;

                        <div class="Vendor_image_cropper_page d-none">
                            <div class="container">
                                <h5>Upload Images</h5>
                                <form method="post" id="S3ImageSubmissionForm">
                                    <input type="file" name="image" class="image">
                                    <input type="file" name="image" class="image">
                                    <input type="file" name="image" class="image">
                                </form>
                            </div>
                        </div>

                        <label for="inputBusinessName" class="form-label">Upload images for this item</label>
                        <div class="d-flex bd-highlight">
                            <!-- <div class="container"> -->
                            <div class="row">
                                <div class="col-md-3">
                                    <input type="file" class="form-control imageCropInput" id="inpFile1" name="inpFile1" aria-describedby="" value="imageCropInputPreviewForNew1">
                                    <div class="image-1-error text-danger"></div>
                                    <br>
                                    <div class="img-preview" id="imagePreview1">
                                        <img src="" alt="" class="image-preview-1 prodImgPreviewForNew" id="imageCropInputPreviewForNew1" width="220px" height="auto">                            
                                    </div>
                                </div>
                                <div class="col-md-3 ">
                                    <input type="file" class="form-control imageCropInput" id="inpFile2" name="inpFile2" aria-describedby="" value="imageCropInputPreviewForNew2">
                                    <div class="image-2-error text-danger"></div>
                                    <br>
                                    <div class="img-preview-two" id="imagePreview2">
                                        <img src="" alt="" class="image-preview-2 prodImgPreviewForNew" id="imageCropInputPreviewForNew2" width="220px" height="auto">
                                       
                                    </div>
                                </div>
                                <div class="col-md-3 ">
                                    <input type="file" class="form-control imageCropInput" id="inpFile3" name="inpFile3" aria-describedby="" value="imageCropInputPreviewForNew3">
                                    <div class="image-3-error text-danger"></div>
                                    <br>
                                    <div class="img-preview-three" id="imagePreview3">
                                        <img src="" alt="" class="image-preview-3 prodImgPreviewForNew" id="imageCropInputPreviewForNew3" width="220px" height="auto">
                                        
                                    </div>
                                </div>
                            </div>
                            <!-- </div> -->
                            <!-- <div>
                            <div class="p-2 flex-grow-1 bd-highlight">
                                <input type="file" class="form-control" id="inpFile" name="inpFile" aria-describedby="" >
                            </div>
                            <div class="img-preview" id="imagePreview">
                                <img src="" alt="Image Preview 1" class="image-preview">
                            </div>
                            
                        </div> -->

                            <!-- <div>
                            <div class="p-2 flex-grow-1 bd-highlight">
                                <input type="file" class="form-control" id="inpFileTwo" name="inpFileTwo" aria-describedby="" >
                            </div>
                            <div class="img-preview-two" id="imagePreviewTwo">
                                <img src="" alt="Image Preview 2" class="image-preview-two">
                            </div>
                            
                        </div> -->

                            <!-- <div>
                            <div class="p-2 flex-grow-1 bd-highlight">
                                <input type="file" class="form-control" id="inpFileThree" name="inpFileThree" aria-describedby="" >
                            </div>
                            <div class="img-preview-three" id="imagePreviewThree">
                                <img src="" alt="Image Preview 3" class="image-preview-three">
                            </div>
                            
                        </div> -->
                        </div>
                        <!-- <span class='text-danger' id="inputAddressError"></span> -->
                        &nbsp;


                        <div class="d-flex bd-highlight">
                            <!-- <label for="checkreco" class="form-label">Recommended</label> -->
                            <div class="p-2 pl-4 pt-3 bd-highlight d-flex justify-content-end ">
                                <label><input class="form-check-input align-content-center checkbox-size " type="checkbox" value="Recommended" id="checkreco">  <div class="ms-1 mt-1">Recommended</div></label>
                            </div>&emsp;&emsp;&emsp;
                            <div id="NonVegDiv" class='d-none'>
                                <div class="p-2 pl-4 pt-3 bd-highlight d-flex justify-content-end ">
                                    <label><input class="form-check-input align-content-center checkbox-size " type="checkbox" value="Non Veg" id="checkNonVeg"><div class="ms-1 mt-1">Non Veg</div></label>
                                </div>
                            </div>
                        </div>
                        <!-- <span class='text-danger' id="inputAddressError"></span> -->
                        &nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div>
    &nbsp;
    &nbsp;
</div>
<!-- <div class="">
    <div class="row">
        <div class="col"></div>
        <div class="col d-flex justify-content-end">
            <button type="button" id="newProdSubmitBtn" class="btn btn-warning basicDetailsBtn text-uppercase bg-orange d-none">CONTINUE</button>
        </div>
    </div>
    <div class="row">
        <div class="col"></div>
        <div class="col d-flex justify-content-end">
            <button type="button" id="verify-OTP-details" class="btn basicDetailsBtn text-uppercase btn-warning bg-orange justify-content-end" data-bs-toggle="modal" data-bs-target="#verify-otp-modal">VERIFY DETAILS</button>
        </div>
    </div>
</div> -->
<div class="page_4_btns">
    <div class="row">
        <div class="col justify-content-start">
            <button type="button" id="addNewProductPrevBtn" class="btn btn-warning bg-orange basicDetailsBtn">PREVIOUS</button>
        </div>
        <div class="col d-flex justify-content-end ">
            <button type="button" id="addNewProductSubmitBtn" class="btn btn-warning text-uppercase bg-orange basicDetailsBtn">CONTINUE</button>
        </div>
    </div>
</div>
&nbsp;`;

    $("#productSericeDetailsDiv").append(ele);
    // new vendor_image_cropperjs();
    cropperMain.doCroppingAction();

    this.getAllVendorProductsNames();

    // $("#addNewProductSubmitBtn").on("click", this.displayProducts);

    if (this.inputGSTRegistered.is(":checked")) {
      $(".showGSTReg").removeClass("d-none");
    } else {
      $(".showGSTReg").addClass("d-none");
    }

    const inpFileOne1 = document.getElementById("inpFile1");

    inpFileOne1.addEventListener("change", function () {
      console.log("Image changed Do validation");
      console.log(inpFileOne1);

      img1flag = true;
      var imgExpression = /.*\.(jpg|jpeg|png|JPG|JPEG|PNG)$/;
      if (!inpFileOne1.files[0]) {
        $("#imagePreview1").empty();
      } else {
        if (!inpFileOne1.files[0]["name"].match(imgExpression)) {
          console.log("inside image validate if");
          img1flag = false;
          $("#imagePreview1").empty();
          $(".image-1-error").html("Please select an image file");
        } else {
          console.log("inside image validate else");

          $(".image-1-error").html("");

          if (inpFileOne1.files[0]) {
            const reader = new FileReader();

            // previewFileOne1.style.display = "block";
            $("#imagePreview1").empty();
            reader.addEventListener("load", function () {
              console.log(this);
              var image1 = {
                imageFile: this.result,
                imageNo: "01",
              };
              ProductImages.push(image1);
              // previewFileOne1.setAttribute("src", this.result);
              $("#imagePreview1").append(
                `<img src="${this.result}" alt="" class="image-preview-1 prodImgPreviewForNew"  id="imageCropInputPreviewForNew1" width="220px" height="auto"></img>`
              );
            });

            reader.readAsDataURL(inpFileOne1.files[0]);
          }
        }
      }
    });
    ////////////////////////////////////////////////////////
    // FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS REQUIRED  //
    ////////////////////////////////////////////////////////
    const inpFiletwo1 = document.getElementById("inpFile2");
    inpFiletwo1.addEventListener("change", function () {
      console.log("Image changed Do validation");
      console.log(inpFiletwo1);

      img2flag = true;

      var imgExpression = /.*\.(jpg|jpeg|png|JPG|JPEG|PNG)$/;
      if (!inpFiletwo1.files[0]) {
        $("#imagePreview2").empty();
      } else {
        if (
          !inpFiletwo1.files[0]["name"].match(imgExpression) ||
          !inpFiletwo1.files[0]["name"]
        ) {
          console.log("inside image validate if");
          img2flag = false;
          $(".image-2-error").html("Please select an image file");
          $("#imagePreview2").empty();
        } else {
          console.log("inside image validate else");

          $(".image-2-error").html("");

          if (inpFiletwo1.files[0]) {
            const reader = new FileReader();

            // previewFileOne1.style.display = "block";
            $("#imagePreview2").empty();
            reader.addEventListener("load", function () {
              console.log(this);
              var image2 = {
                imageFile: this.result,
                imageNo: "02",
              };
              ProductImages.push(image2);
              // previewFileOne1.setAttribute("src", this.result);
              $("#imagePreview2").append(
                `<img src="${this.result}" alt="" class="image-preview-2 prodImgPreviewForNew"  id="imageCropInputPreviewForNew2" width="220px" height="auto"></img>`
              );
            });

            reader.readAsDataURL(inpFiletwo1.files[0]);
          }
        }
      }
    });
    ////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////
    // FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS REQUIRED  //
    ////////////////////////////////////////////////////////
    const inpFileThree1 = document.getElementById("inpFile3");
    inpFileThree1.addEventListener("change", function () {
      console.log("Image changed Do validation");
      console.log(inpFileThree1);

      img3flag = true;
      var imgExpression = /.*\.(jpg|jpeg|png|JPG|JPEG|PNG)$/;
      if (!inpFileThree1.files[0]) {
        $("#imagePreview3").empty();
      } else {
        if (
          !inpFileThree1.files[0]["name"].match(imgExpression) ||
          !inpFileThree1.files[0]["name"]
        ) {
          console.log("inside image validate if");
          img3flag = false;
          $(".image-3-error").html("Please select an image file");
          $("#imagePreview3").empty();
        } else {
          console.log("inside image validate else");

          $(".image-3-error").html("");

          if (inpFileThree1.files[0]) {
            const reader = new FileReader();

            // previewFileOne1.style.display = "block";
            $("#imagePreview3").empty();
            reader.addEventListener("load", function () {
              console.log(this);
              var image3 = {
                imageFile: this.result,
                imageNo: "03",
              };
              ProductImages.push(image3);
              // previewFileOne1.setAttribute("src", this.result);
              $("#imagePreview3").append(
                `<img src="${this.result}" alt="" class="image-preview-3 prodImgPreviewForNew"  id="imageCropInputPreviewForNew3" width="220px" height="auto"></img>`
              );
            });
            reader.readAsDataURL(inpFileThree1.files[0]);
          }
        }
      }
    });
    ////////////////////////////////////////////////////////

    $("#addNewProductSubmitBtn").on("click", this.doNewProductValidation);
    $("#addNewProductPrevBtn").on("click", this.doNewPrevious);
    $("#inputCategory").on("change", this.vegNonVegShow);
    $("#inputCategory").on("change", this.showSpecialityByCategory);
    // $(".imageCropInput").on("change", cropperMain.doCroppingAction());
  };

  vegNonVegShow = () => {
    console.log("In veg non veg");

    var extraValue = $("#inputCategory").val();
    const extraValueArray = JSON.parse(extraValue);
    console.log(extraValueArray);
    console.log(extraValueArray.ExtraValue);
    $("#checkNonVeg").prop("checked", false);

    if (extraValueArray.ExtraValue == "FoodSeller") {
      $("#NonVegDiv").removeClass("d-none");
    } else {
      $("#NonVegDiv").addClass("d-none");
    }
  };
  vegNonVegShowInEdit = () => {
    console.log("In veg non veg");

    var extraValue = $("#inputCategory").val();
    const extraValueArray = JSON.parse(extraValue);
    console.log(extraValueArray);
    console.log(extraValueArray.ExtraValue);

    if (!(extraValueArray.ExtraValue == "FoodSeller")) {
      $("#NonVegDiv").addClass("d-none");
    }
    this.showSpecialityByCategory();
  };
  doNewPrevious = () => {
    $("#productSericeDetailsDiv").empty();
    this.productServicesListingPage.removeClass("d-none");
  };

  doNewProductValidation = (e) => {
    e.preventDefault();
    console.log("in doNewProductValidation");

    const newProdFileInput2_File = document.getElementById("inpFile2").files[0];
    const newProdFileInput3_File = document.getElementById("inpFile3").files[0];

    if (newProdFileInput2_File === undefined) {
      $(".image-2-error").html("");
    }
    if (newProdFileInput3_File === undefined) {
      $(".image-3-error").html("");
    }

    let isProductServiceNameValidated = this.ProductServiceName_validate();
    if (isProductServiceNameValidated) {
      console.log("ProductServiceName VALID");
      $("#inputProductServiceNameError").html("");
    }

    let isDiscriptionValidated = this.Discription_validate();
    if (isDiscriptionValidated) {
      console.log("Discription VALID");
      $("#discriptionError").html("");
    }

    let isCategoryValidated = this.Category_validate();
    if (isCategoryValidated) {
      console.log("Category VALID");
      $("#categoryError1").html("");
    }

    let isPriceValidated = this.Price_validate();
    if (isPriceValidated) {
      console.log("Price VALID");
      $("#priceError").html("");
    }

    let isQuantityValidated = this.Quantity_validate();
    if (isQuantityValidated) {
      console.log("Quantity VALID");
      $("#quantityError").html("");
    }

    let isGSTPercentValidated = this.GSTPercent_validate();
    if (isGSTPercentValidated) {
      console.log("GSTPercent VALID");

      $("#GSTPercentError").html("");
    }

    let imageSelected = this.imageSelected_validate();
    if (imageSelected) {
      console.log("image select VALID");
      $(".image-1-error").html("");
    }

    if (imageIsImage == false) {
      $(".image-1-error").html("Please select an image file");
    }

    if (imageIsImageTwo == false) {
      $(".image-2-error").html("Please select an image file");
    }

    if (imageIsImageThree == false) {
      $(".image-3-error").html("Please select an image file");
    }

    let specialitySelected = this.specialitySelected_validate();
    if (specialitySelected) {
      console.log("speciality select VALID");
      $("#specicalityError").html(" ");
    }

    //////////////////////////////////////////////////////////
    //   FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS REQUIRED  //
    //////////////////////////////////////////////////////////
    // img2flag = true;
    // img3flag = true;
    //2//////////////////////////////////////////////////////////////////////////
    //   FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS MANDATORY. CAN ENTER ALL 3  //
    ////////////////////////////////////////////////////////////////////////////
    if (newProdFileInput2_File === undefined) {
      img2flag = true;
    }
    if (newProdFileInput3_File === undefined) {
      img3flag = true;
    }
    ////////////////////////////////////////////////////////////////////////////

    console.log(isProductServiceNameValidated);
    console.log(isDiscriptionValidated);
    console.log(isCategoryValidated);
    console.log(isPriceValidated);
    console.log(isQuantityValidated);
    console.log(isGSTPercentValidated);
    console.log(imageSelected);
    console.log(specialitySelected);
    console.log(img1flag);
    console.log(img2flag);
    console.log(img3flag);

    if (
      isProductServiceNameValidated &&
      isDiscriptionValidated &&
      isCategoryValidated &&
      isPriceValidated &&
      isQuantityValidated &&
      isGSTPercentValidated &&
      imageSelected &&
      specialitySelected &&
      img1flag &&
      img2flag &&
      img3flag
    ) {
      this.Submit_Form();
      console.log("ALL FIELDS VALID...VALIDATION OVER");
    }
  };
  Submit_Form = () => {
    // VendorCropper = "";
    console.log("Submitting the form");

    var recoFlag;
    if ($("#checkreco").is(":checked")) {
      recoFlag = 1;
    } else {
      recoFlag = 0;
    }
    var NonVegFlag;
    if ($("#checkNonVeg").is(":checked")) {
      NonVegFlag = 1;
    } else {
      NonVegFlag = 0;
    }
    var newEditProductImages = [];

    var newProdFCounter = 0;
    var newProdimagePosition = [];
    //////////////////////////////////////////////////////////
    //   FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS REQUIRED  //
    //////////////////////////////////////////////////////////
    // for (var i = 0; i < 3; i++)
    //2//////////////////////////////////////////////////////////////////////////
    //   FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS MANDATORY. CAN ENTER ALL 3  //
    ////////////////////////////////////////////////////////////////////////////
    const newProdFileInput1_File = document.getElementById("inpFile1").files[0];
    const newProdFileInput2_File = document.getElementById("inpFile2").files[0];
    const newProdFileInput3_File = document.getElementById("inpFile3").files[0];
    if (newProdFileInput1_File !== undefined) {
      newProdFCounter++;
      newProdimagePosition.push(0);
    }
    if (newProdFileInput2_File !== undefined) {
      newProdFCounter++;
      newProdimagePosition.push(1);
    }
    if (newProdFileInput3_File !== undefined) {
      newProdFCounter++;
      newProdimagePosition.push(2);
    }
    ////////////////////////////////////////////////////////////////////////////

    for (var i = 0; i < newProdFCounter; i++) {
      var j = newProdimagePosition[i];
      var image = {
        imageFile: $(".prodImgPreviewForNew")[j]["currentSrc"],
        imageNo: i + 1,
      };
      newEditProductImages.push(image);

      var prodImgFile = $(".prodImgPreviewForNew")[j]["currentSrc"];
      var prodImgFileNo = i + 1;
      var prodImgFileName = $("#inputProductServiceName").val();

      ImageURL = this.UploadProductImages(
        prodImgFile,
        prodImgFileNo,
        prodImgFileName
      );
    }
    console.log(newEditProductImages);
    // $.ajax({
    //   type: "POST",
    //   url: baseURL + "api/Vendor/ImageUploadApi",
    //   async: false,

    //   data: {
    //     ProductImages: newEditProductImages,
    //     productServiceName: $("#inputProductServiceName").val(),
    //   },
    //   success: function (res) {
    //     console.log(res);
    //     ImageURL = res;
    //   },
    // });

    const selectedPicklistId = JSON.parse($("#inputCategory").val());
    console.log(selectedPicklistId);
    console.log(selectedPicklistId.picklistId);
    var product_service_data = [];
    //to create array of Product Specialities
    console.log($(".NewspecialityCheckBox").length);
    console.log($(".NewspecialityCheckBox:checked"));
    for (let i = 0; i < $(".NewspecialityCheckBox:checked").length; i++) {
      console.log($(".NewspecialityCheckBox:checked")[i].defaultValue);
      SpecialitiesArray[i] = $(".NewspecialityCheckBox:checked")[
        i
      ].defaultValue;
    }

    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/getVendorDetails",
      headers: { Authorization: X_API_KEY },
      async: false,
      success: (result) => {
        productKeyBaseURL = `Vendor/${result.Image_URL}/Product Images/`;
      },
    });

    var product_detail = {
      Product_Service_Name: $("#inputProductServiceName").val(),
      Picklist_ID: selectedPicklistId.picklistId,
      Description: $("trix-editor").val(),
      GST: $("#inputGSTPercent").val(),
      Quantity: $("#inputQuantity").val(),
      Price: $("#inputPrice").val(),
      Image_URL:
        productKeyBaseURL + $("#inputProductServiceName").val() + "/01.png",
      Specialities: SpecialitiesArray,
      Recommended_flag: recoFlag,

      Veg_NonVeg_flag: NonVegFlag,
      ImagesUpdated: true,
      Product_Availability_Flag: 1,
    };
    product_service_data.push(product_detail);
    console.log("add procuts ajax");
    $.ajax({
      type: "POST",

      url: baseURL + "api/Vendor/AddUpdateProducts",
      async: false,

      headers: { Authorization: X_API_KEY },

      // data: this.Products_Services_Form4.serialize(),
      data: {
        product_service_data: product_service_data,
      },

      success: console.log("Successfully"),
    });

    let base64data = $("#inpFile").val();
    console.log(base64data);

    console.log("Printing Immage array");
    console.log(ProductImages);
    this.Products_Services_page4.addClass("d-none");
    this.productServicesListingPage.removeClass("d-none");

    this.displayProducts();
  };
  specialitySelected_validate = () => {
    console.log("in specialitySelected_validate");
    var checkboxs = document.getElementsByClassName("NewspecialityCheckBox");
    console.log(checkboxs);
    console.log(document.getElementsByClassName("NewspecialityCheckBox"));

    var flag = false;
    for (var i = 0, l = checkboxs.length; i < l; i++) {
      if (checkboxs[i].checked) {
        flag = true;
        break;
      }
    }
    if (flag == false) {
      $("#specicalityError").html("Please select a speciality");
    } else {
      $("#specicalityError").html("");
    }
    return flag;
  };

  editspecialitySelected_validate = () => {
    console.log("in specialitySelected_validate");
    var checkboxs = document.getElementsByClassName("EditspecialityCheckBox");
    console.log(checkboxs);
    console.log(document.getElementsByClassName("EditspecialityCheckBox"));

    var flag = false;
    for (var i = 0, l = checkboxs.length; i < l; i++) {
      if (checkboxs[i].checked) {
        flag = true;
        break;
      }
    }
    if (flag == false) {
      $("#specicalityError").html("Please select a speciality");
    } else {
      $("#specicalityError").html("");
    }
    return flag;
  };

  // imageSelected_validate = () => {
  //   const inpFile = document.getElementById("inpFile");
  //   const inpFileTwo = document.getElementById("inpFileTwo");
  //   const inpFileThree = document.getElementById("inpFileThree");

  //   let flag = true;
  //   console.log(inpFile.files[0]);
  //   //let inputFileOneValue = inpFile.files[0];

  //   console.log(inpFile.files[0] === undefined);
  //   if (inpFile.files[0] === undefined) {
  //     this.imageOneError.html("Please select an image");
  //     flag = false;
  //   } else {
  //     this.imageOneError.html("");
  //   }

  //   if (inpFileTwo.files[0] === undefined) {
  //     this.imageTwoError.html("Please select an image");
  //     flag = false;
  //   } else {
  //     this.imageTwoError.html("");
  //   }

  //   if (inpFileThree.files[0] === undefined) {
  //     this.imageThreeError.html("Please select an image");
  //     flag = false;
  //   } else {
  //     this.imageThreeError.html("");
  //   }

  //   return flag;
  // };

  //upload image validation start

  imageSelected_validate = () => {
    const inpFile = document.getElementById("inpFile1");
    // const inpFileTwo = document.getElementById("inpFile2");
    // const inpFileThree = document.getElementById("inpFile3");

    let flag = true;
    console.log(inpFile.files[0]);
    //let inputFileOneValue = inpFile.files[0];

    console.log(inpFile.files[0] === undefined);
    if (inpFile.files[0] === undefined) {
      $(".image-1-error").html("Please select an image");
      flag = false;
      console.log("img 1 empty");
    } else {
      $(".image-1-error").html(" ");
    }

    //////////////////////////////////////////////////////////
    //   FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS REQUIRED  //
    //////////////////////////////////////////////////////////
    // if (inpFileTwo.files[0] === undefined) {
    //   $(".image-2-error").html("Please select an image");
    //   console.log("img 2 empty");

    //   flag = false;
    // } else {
    //   $(".image-2-error").html(" ");
    // }

    // if (inpFileThree.files[0] === undefined) {
    //   $(".image-3-error").html("Please select an image");
    //   console.log("img 3 empty");

    //   flag = false;
    // } else {
    //   $(".image-3-error").html(" ");
    // }
    //////////////////////////////////////////////////////////

    console.log("img flag:");
    console.log(flag);

    return flag;
  };

  //upload image validation end

  GSTPercent_validate = () => {
    if ($("#inputGSTRegistered").is(":checked")) {
      let flag = true;
      if ($("#inputGSTPercent").val() < 1) {
        $("#GSTPercentError").html("cannot be empty");

        flag = false;
      }

      return flag;
    } else {
      // this.inputGSTPercent.value = null;
      return true;
    }
  };

  ProductServiceName_validate = () => {
    console.log("in address validate");
    let flag = true;
    for (let i = 0; i < vendorProductNames.length; i++) {
      if ($("#inputProductServiceName").val() == vendorProductNames[i]) {
        $("#inputProductServiceNameError").html("Product already exists.");

        flag = false;
      }
      if ($("#inputProductServiceName").val() == currentEditedproductName) {
        $("#inputProductServiceNameError").html("");

        flag = true;
      }
    }
    if (
      $("#inputProductServiceName").val().length < 2 ||
      $("#inputProductServiceName").val().length > 50
    ) {
      $("#inputProductServiceNameError").html(
        "name should be min 2 and max 50 characters long"
      );

      flag = false;
    }

    return flag;
  };
  Discription_validate = () => {
    console.log("in Business Name validate");
    let flag = true;
    var element = document.querySelector("trix-editor");
    var trixdocument = element.editor.getDocument();
    var trixdocumentstr = trixdocument.toString(); // is a JavaScript string

    if (trixdocumentstr.length > 5000 || trixdocumentstr.length < 2) {
      $("#discriptionError").html("description must be max 5000 characters");

      flag = false;
    }

    return flag;
  };

  Category_validate = () => {
    let flag = true;

    if (!$("#inputCategory").val()) {
      console.log("Category not selected");
      $("#categoryError1").html("Select a Category");

      flag = false;
    }

    return flag;
  };

  Price_validate = () => {
    let flag = true;
    if ($("#inputPrice").val() > 1000000 || $("#inputPrice").val() < 1) {
      $("#priceError").html("max 10,00,000");

      flag = false;
    }

    return flag;
  };

  Quantity_validate = () => {
    let flag = true;
    if ($("#inputQuantity").val() < 1) {
      $("#quantityError").html("min 1");

      flag = false;
    }

    return flag;
  };

  ValidateAtleastOneProduct = () => {
    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/GetProducts",
      headers: { Authorization: X_API_KEY },
      success: this.SubmitListingPage,
    });
  };

  SubmitListingPage = (res) => {
    if (res.code == 10) {
      this.NoProductsError.html("Please add atleast one product");
    } else {
      this.uploadDocuments_page5.removeClass("d-none");
      this.productServicesListingPage.addClass("d-none");
      this.formstep4.removeClass("current-item");
      this.formstep5.addClass("current-item");
    }
  };
  // getProducts = (e) => {
  //   console.log("inside getProducts");
  //   $.ajax({
  //     type: "GET",
  //     url: baseURL + "api/Vendor/GetProducts",
  //     headers: { Authorization: X_API_KEY },
  //     success: this.doDisplayProds,
  //   });
  // };
  // doDisplayProds = (res) => {
  //   console.log("Products:");
  //  console.log(res);
  // };

  //getting all the Vendor product names to validate unique product names
  getAllVendorProductsNames = () => {
    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/GetVendorProductsNames",
      async: false,
      headers: { Authorization: X_API_KEY },

      success: this.useVendorProducts,
    });
  };

  useVendorProducts = (res) => {
    console.log("inside useVendorProducts");
    vendorProductNames = [];
    if (!res.code) {
      console.log(res);
      res.forEach((element) => {
        console.log(element.Product_Service_Name);
        vendorProductNames.push(element.Product_Service_Name);
      });
      console.log(vendorProductNames);
    }
  };

  showSpecialityByCategory = (e) => {
    const categoryOptions = document.getElementsByClassName("categoryOptions");
    console.log(categoryOptions);
    for (var i = 0; i < categoryOptions.length; i++) {
      console.log(categoryOptions[i]);
      console.log(categoryOptions[i].selected);
      if (categoryOptions[i].selected == true) {
        var picklistId = $("#inputCategory").val();
        const picklistIdArray = JSON.parse(picklistId);
        console.log(picklistIdArray);
        console.log(picklistIdArray.picklistId);

        $.ajax({
          type: "GET",
          url: baseURL + "api/Vendor/getSpecialitiesForCategory",
          async: false,
          data: {
            Picklist_ID: picklistIdArray.picklistId,
            Product_ID: currentProductID,
          },
          headers: { Authorization: X_API_KEY },

          success: this.showSpecialities,
        });
      }
    }
  };

  showSpecialities = (res) => {
    console.log("in showSpecialities");
    var SpecialityCount = 50;
    var newProductSpecility = ``;
    var checked = ``;

    res.forEach((element) => {
      console.log(element);
      var customSpecID = element.Picklist_ID + "s3";

      // newProductSpecility = `${newProductSpecility}<div><label class="mx-1 "><input type="checkbox" name="speciality-check" value="${element.Picklist_ID}" class="NewspecialityCheckBox"><span>${element.Picklist_display_value}</span></label></div>`;
      checked = element.Selected ? "checked" : "";
      newProductSpecility = `${newProductSpecility}<div class="col-4 col-md-2 d-flex justify-content-center my-2 "><div class="custom-control custom-checkbox image-checkbox">
        <input type="checkbox" class="custom-control-input specialityCheckBox NewspecialityCheckBox EditspecialityCheckBox justify-content-center" id="specialityck${SpecialityCount}" value="${element.Picklist_ID}" ${checked}>
        <label class="custom-control-label d-flex justify-content-center" for="specialityck${SpecialityCount}">
            <img src="" id=${customSpecID} class="img-fluid rounded-circle specialityImage" onerror="this.src='assets/src/images/pancake.jpeg'" alt="Image" loading="lazy">
        </label>
        <label class="d-flex justify-content-center">
            <span class="fs-12 text-lowercase text-center">${element.Picklist_display_value}</span>
        </label>
        </div></div>`;
      SpecialityCount++;
    });
    //Call to Function to load the S3 Category images in the respective image tags

    $("#ProductSpecialityBox").empty();
    $("#ProductSpecialityBox").append(newProductSpecility);
    //Call to Function to load the S3 Category images in the respective image tags
    this.loadSpecialityImages(res);
  };

  //To load the S3 Category images in the respective image tags
  loadSpecialityImages = (res) => {
    //For each Speciality in Category ABC
    res.forEach(async (elementS) => {
      var customSpecID = elementS.Picklist_ID + "s3";

      var specialityS3Image = await s3Object.getObjectURL(elementS.Icon_Url);
      document.getElementById(customSpecID).src = specialityS3Image;
    });
  };

  UploadProductImages = async (prodImgFile, prodImgFileNo, prodImgFileName) => {
    console.log("Vendor Product Images UPLOAD");

    var key = "";
    var keyOne = "";
    //Get Vendor Details
    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/getVendorDetails",
      headers: { Authorization: X_API_KEY },
      async: false,
      success: (result) => {
        key = `Vendor/${result.Image_URL}/Product Images/${prodImgFileName}/0${prodImgFileNo}.png`; // Specify the desired key or path
        keyOne = `Vendor/${result.Image_URL}/Product Images/${prodImgFileName}/01.png`;
      },
    });

    var Currentkey = key;
    await s3Object.uploadCropperImageToS3(prodImgFile, Currentkey);

    return keyOne;
  };
}
