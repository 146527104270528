// import cartUtil from "../helpers/cartUtil";
// import Util from "../helpers/util";

// var CartObject = new cartUtil();
// var UtilObject = new Util();
var customerID;
var oldPhone, oldEmail, OTP;
var deviceTypeCustSerOrdersPage;
export default class Vendor_security {
  constructor() {
    console.log("in security js constructor");
    // this.allCookies = UtilObject.getAllCookies();
    //id's of the fields
    this.fullNameDisplayDesk = $("#fullNameDisplayDeskCustomerServicing");
    this.genderDisplayDesk = $("#genderDisplayDeskCustomerServicing");
    this.dobDisplayDesk = $("#dobDisplayDeskCustomerServicing");
    this.mobNoDisplayDesk = $("#mobNoDisplayDeskCustomerServicing");
    this.emailIdDisplayDesk = $("#emailIdDisplayDeskCustomerServicing");
    this.passwordConfirmationPage = $("#passwordConfirmationPage");
    this.passwordConfirmationPageBtn = $("#passwordConfirmationPageBtn");

    this.mobileInputAccount = $("#mobileInputAccount");

    //OTP MODAL
    // this.verifyOtpModal = $("#verifyOtpModalAccount");
    this.otpInputVerify = $("#otpInputVerifyAccount");
    this.otpError = $("#otpErrorAccount");
    this.verifyOTPBtn = $("#verify-OTP-btn-Account");

    //errors
    this.fullNameDisplayDeskError = $(
      "#fullNameDisplayDeskErrorCustomerServicing"
    );

    this.mobNoDisplayDeskError = $("#mobNoDisplayDeskErrorCustomerServicing");

    this.emailIdDisplayDeskError = $(
      "#emailIdDisplayDeskErrorCustomerServicing"
    );

    this.genderDisplayDeskError = $("#genderDisplayDeskErrorCustomerServicing");

    this.dobDisplayDeskError = $("#dobDisplayDeskErrorCustomerServicing");

    this.passwordConfirmationPageError = $("#passwordConfirmationPageError");

    //button register profile
    this.ProfileUploadBtn = $("#ProfileUploadBtn");

    //security page stuff
    this.forgotpassModalForServicingSecurityPage = $(
      "#forgotpassModalForServicingSecurityPage"
    );
    //forgot password
    this.callForgetPasswordmodalAccountPageBtn = $(
      ".callForgetPasswordmodalAccountPageBtn"
    );
    //change password toggle
    this.toggleChangePasswordBtn = $("#toggleChangePasswordBtn");
    this.forgotBtn = $("#forgotBtnAccount");
    this.Current_Password = $("#Current_PasswordAccount");
    this.New_Password = $("#New_Password");
    this.Confirm_Password = $("#Confirm_PasswordAccount");
    //otp forgot password
    this.forgotPswdMobileNumberInput = $(".customerServicingUPhone");
    this.sendOTPForgotPasswordButton = $("#send-otp-ForgetPasswordAccount");
    this.OTPInputForgotPassword = $("#otp-inputForgetPasswordAccount");
    this.saveNewPasswordBtnForgotPassword = $(
      "#saveNewForgetPasswordAccountBtn"
    );
    this.saveNewPasswordInputForgotPassword = $("#newForgetPasswordAccount");
    this.resendOTPForgotPasswordButton = $("#resend-otp-ForgetPasswordAccount");
    this.verifyOTPBtnForgotPassword = $("#saveNewPasswordAccountVerifyBtn");
    this.OTPSuccessForgotPassword = $("#OTP-successAccount");
    this.mobileLabelForgotPassword = $("#mobile-labelForgetPasswordAccount");
    this.OTPLabelForgotPassword = $("#otp-labelForgetPasswordAccount");
    this.newPasswordLabelForgotPassword = $(
      "#new-password-labelForgetPasswordAccount"
    );
    this.successNewPasswordForgotPassword = $("#success-New-PasswordAccount");

    //show the fields once toggle btn is enabled
    this.showTheFieldsAfterToggle = $(".showTheFieldsAfterToggle");

    //submit btn for sec page
    this.uploadBtnforSecPage = $("#uploadBtnforSecPage");

    //error msgs
    this.Current_PasswordError = $("#Current_PasswordError");
    this.New_PasswordError = $("#New_PasswordError");
    this.Confirm_PasswordError = $("#Confirm_PasswordError");

    //all pages show and d-none id's -Orders
    this.vendorDesktopPastOrder = $("#vendorDesktopPastOrder");
    this.MobilePastOrder = $("#MobilePastOrder");

    //all pages show and d-none id's- favourites

    this.DesktopFavourites = $("#DesktopFavourites");
    this.MobileFavourites = $("#MobileFavourites");

    //all pages show and d-none id's- address
    this.EditDeleteBtnDesktop = $("#EditDeleteBtnDesktop");
    this.EditDeleteBtnMobile = $("#EditDeleteBtnMobile");

    //photo
    this.mobilePhoto = $("#mobilePhoto");
    this.desktopPhoto = $("#desktopPhoto");

    //desktop customer servicing haeder

    // this.custSerHeaderMobOne = $("#custSerHeaderMobOne");
    // this.custSerHeaderOne = $("#custSerHeaderOne");

    //Registering Events
    // this.confirmLogInOfCustomer();
    this.bindEvents();
  }


  bindEvents = () => {
    console.log("in security bind");
    this.ProfileUploadBtn.on("click", this.doFormAction);

    //toggle btn onclick function
    this.toggleChangePasswordBtn.on("click", this.showOtherFieldsAction);

    //submit btn for sec
    this.uploadBtnforSecPage.on("click", this.securityPageValidation);

    //upload profile
    // $(window).on("load", $.ready.then(this.getAccountDetails));
    this.passwordConfirmationPageBtn.on("click", this.submitPasswordCheck);

    //verify OTP
    this.verifyOTPBtn.on("click", this.verifyOtp);

    //forgot password
    this.sendOTPForgotPasswordButton.on("click", this.sendOtpForgotPassword);
    this.saveNewPasswordBtnForgotPassword.on(
      "click",
      this.doSaveNewForgotPassword
    );
    this.resendOTPForgotPasswordButton.on("click", this.sendOtpForgotPassword);
    this.verifyOTPBtnForgotPassword.on("click", this.doVerifyOTP);

    //widthwise show and d-none for Past orders
    // for desktop - bigger and mobile and tab view - smaller
    var w = $(window).width();
    console.log(w);

    if (w < 821) {
      this.vendorDesktopPastOrder.addClass("d-none");
      this.MobilePastOrder.removeClass("d-none");
    } else {
      this.MobilePastOrder.addClass("d-none");
      this.vendorDesktopPastOrder.removeClass("d-none");
    }

    // for desktop and tab view - bigger and mobile- smaller
    var width_fav = $(window).width();
    console.log(width_fav);

    if (width_fav < 573) {
      this.DesktopFavourites.addClass("d-none");
      this.MobileFavourites.removeClass("d-none");
    } else {
      this.MobileFavourites.addClass("d-none");
      this.DesktopFavourites.removeClass("d-none");
    }

    // address
    var width_address = $(window).width();
    console.log(width_address);

    if (width_address < 821) {
      this.EditDeleteBtnDesktop.addClass("d-none");
      this.EditDeleteBtnMobile.removeClass("d-none");
    } else {
      this.EditDeleteBtnMobile.addClass("d-none");
      this.EditDeleteBtnDesktop.removeClass("d-none");
    }

    // admin phtoto
    var width_photo = $(window).width();
    console.log(width_photo);

    if (width_photo < 573) {
      this.desktopPhoto.addClass("d-none");
      this.mobilePhoto.removeClass("d-none");
    } else {
      this.mobilePhoto.addClass("d-none");
      this.desktopPhoto.removeClass("d-none");
    }

    //checking the size of the screen
    var screenWidthOrders = $(window).width();
    console.log(screenWidthOrders);

    if (screenWidthOrders < 768) {
      //show mobile view
      console.log("mobile width....");
      deviceTypeCustSerOrdersPage = "mobile";
      $("#MobilePastOrder").removeClass("d-none");
    } else {
      deviceTypeCustSerOrdersPage = "desktop";
      $("#vendorDesktopPastOrder").removeClass("d-none");
    }

    if (screenWidthOrders < 991) {
      // $("#sidebarMenu").css("margin-top", "7rem");
    } else {
      // $("#sidebarMenu").css("margin-top", "5rem");
    }

    //Click events for sidebar
    $(".nav-orders").on("click", this.sidebarFunction);
    $(".nav-account").on("click", this.sidebarFunction);
    $(".nav-profile").on("click", this.sidebarFunction);
    $(".nav-security").on("click", this.sidebarFunction);
    $(".nav-address").on("click", this.sidebarFunction);
    $(".nav-favourites").on("click", this.sidebarFunction);
    $(".nav-offers").on("click", this.sidebarFunction);
    $(".nav-referral").on("click", this.sidebarFunction);
    $(".nav-help").on("click", this.sidebarFunction);
    $(".nav-logOut").on("click", this.logOutUser);
  };

  sidebarFunction = (event) => {
    console.log(event);
    var activeEvent = $("#sidebarMenu").find(".active");
    console.log(activeEvent);
    //add and remove active class from sidebar (causes darkening if the text)

    activeEvent[0].classList.remove("active");

    //get the page to be shown
    var page =
      event.currentTarget.classList[event.currentTarget.classList.length - 1];
    console.log(page);

    if (page == "nav-account" || page == "collapsed") {
      event.currentTarget.classList.add("active");
    } else {
      event.currentTarget.classList.add("active");
      $(".currentlyActive").addClass("d-none");
      $(".currentlyActive").removeClass("d-currentlyActive");
      $("#sidebarMenu").removeClass("show");
    }

    if (page == "nav-orders") {
      $(".customerServicingOrders").addClass("currentlyActive");
      $(".customerServicingOrders").removeClass("d-none");
    } else if (page == "nav-profile") {
      $(".customerAccountProfile").addClass("currentlyActive");
      $(".customerAccountProfile").removeClass("d-none");
    } else if (page == "nav-security") {
      $(".custAccountSecurity").addClass("currentlyActive");
      $(".custAccountSecurity").removeClass("d-none");
    } else if (page == "nav-address") {
      $(".custAccountAddress").addClass("currentlyActive");
      $(".custAccountAddress").removeClass("d-none");
    } else if (page == "nav-favourites") {
      $(".customerServicingFavorites").addClass("currentlyActive");
      $(".customerServicingFavorites").removeClass("d-none");
    } else if (page == "nav-offers") {
      $(".customerServicingOrders").addClass("currentlyActive");
      $(".customerServicingOrders").removeClass("d-none");
    } else if (page == "nav-referral") {
      $(".customerServicingOrders").addClass("currentlyActive");
      $(".customerServicingOrders").removeClass("d-none");
    } else if (page == "nav-help") {
      $(".vendorConfirmationHelpPageDesktop").addClass("currentlyActive");
      $(".vendorConfirmationHelpPageDesktop").removeClass("d-none");
    }
  };

  showOtherFieldsAction = (e) => {
    e.preventDefault();
    console.log("in showOtherFieldsAction");
    console.log(e);
    if (e.currentTarget.ariaPressed == "true") {
      this.showTheFieldsAfterToggle.addClass("d-none");
    } else if (e.currentTarget.ariaPressed == "false") {
      this.showTheFieldsAfterToggle.removeClass("d-none");
    }

    //this.showTheFieldsAfterToggle.removeClass("d-none");
  };

  //checking if entered details are valid or not
  doFormAction = (e) => {
    e.preventDefault();
    console.log("in doFormAction");

    //name
    let isNameValidated = this.Name_validate();
    if (isNameValidated) {
      console.log(" Name VALID");
      this.fullNameDisplayDeskError.html("");
    }

    //gender
    let isGenderValidated = this.Gender_validate();
    if (isGenderValidated) {
      console.log(" gender VALID");
      this.genderDisplayDeskError.html("");
    }

    //dob
    let isDobValidated = this.Dob_validate();
    if (isDobValidated) {
      console.log(" date of birth VALID");
      this.dobDisplayDeskError.html("");
    }

    //mobNo
    let isMobNoValidated = this.MobNo_validate();
    if (isMobNoValidated) {
      console.log(" mobile no  VALID");
      this.mobNoDisplayDeskError.html("");
    }

    //emailid
    let isEmailValidated = this.Email_validate();
    if (isEmailValidated) {
      console.log(" email VALID");
      this.emailIdDisplayDeskError.html("");
    }

    //checking if all fields are right
    if (
      isNameValidated &&
      isGenderValidated &&
      isDobValidated &&
      isMobNoValidated &&
      isEmailValidated
    ) {
      let phnoIsUnique = this.phnoIsUnique_validate();
      let emailIsUnique = this.emailIsUnique_validate();
      console.log("isPhNoEmailValidated VALID");

      if (phnoIsUnique && emailIsUnique) {
        this.emailIdDisplayDeskError.html("");
        this.mobNoDisplayDeskError.html("");

        window.$("#checkPasswordConfirmationPage").modal("show");

        console.log("ALL FIELDS VALID...VALIDATION OVER");
      }
    }
  };

  //validate function
  MobNo_validate = () => {
    console.log("in phone validate");
    let flag = true;
    var phoneno = /^\d{10}$/;
    if (!this.mobNoDisplayDesk.val().match(phoneno)) {
      this.mobNoDisplayDeskError.html(
        "Please enter only 10 digits for mobile number"
      );

      flag = false;
    }

    return flag;
  };

  Name_validate = () => {
    console.log("in Name validate");
    let flag = true;
    var name = /^([a-zA-Z ]){3,100}$/;

    console.log(this.fullNameDisplayDesk);
    console.log(this.fullNameDisplayDesk.val());
    if (
      !(
        this.fullNameDisplayDesk.val().match(name) &&
        this.fullNameDisplayDesk.val().length >= 3 &&
        this.fullNameDisplayDesk.val().length <= 100
      )
    ) {
      this.fullNameDisplayDeskError.html(
        "Name must be min 2 and max 100 characters"
      );

      flag = false;
    }

    console.log(flag);
    return flag;
  };

  // //gender validation
  Gender_validate = () => {
    console.log("in Email validate");
    let flag = true;

    console.log(this.genderDisplayDesk);
    console.log(this.genderDisplayDesk.val());
    if (this.genderDisplayDesk.val() == null) {
      this.genderDisplayDeskError.html("Please enter a valid gender");

      flag = false;
    }

    return flag;
  };

  //email id validation
  Email_validate = () => {
    console.log("in Email validate");
    let flag = true;
    var emailexample =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!this.emailIdDisplayDesk.val().match(emailexample)) {
      this.emailIdDisplayDeskError.html("Please enter a valid email");

      flag = false;
    }

    return flag;
  };

  //dob validation
  Dob_validate = () => {
    console.log("in Dob validate");
    let flag = true;

    console.log(this.dobDisplayDesk.val());
    var dobPattern = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;
    if (!this.dobDisplayDesk.val().match(dobPattern)) {
      this.dobDisplayDeskError.html("Please enter a valid birth date");

      flag = false;
    }

    return flag;
  };

  //call to get customer profile
  getAccountDetails = () => {
    console.log("inside userdetails");
    // Customer_ID: customerID,

    $.ajax({
      url: baseURL + "api/customer/Account/GetUserDetails",
      type: "POST",
      headers: { Authorization: X_API_KEY },
      data: {
        Customer_ID: customerID,
      },
      success: (result) => {
        $(".customerServicingName").val(result.Customer_Name);
        $(".customerServicingGender").val(result.Gender);
        $(".customerServicingDateOfBirth").val(result.Date_Of_Birth);
        $(".customerServicingUPhone").val(result.Phone_Number);
        $(".customerServicingUEmail").val(result.Email);

        //ruthvi's code for referal code api integration
        $(".referalCode").html(result.Referral_code);

        oldPhone = result.Phone_Number;
        oldEmail = result.Email;

        this.changeAccountDisplayOnHeader(result);
      },
      error: (result) => {
        console.log(result);
      },
    });
  };

  changeAccountDisplayOnHeader = (account) => {
    console.log(account);
    var desktopName = account.Customer_Name;
    if (desktopName.length > 10) {
      desktopName = desktopName.substring(0, 8) + "..";
    }
    //For Desktop Name Change
    $(".accountNameDesktop").html(
      `<a href="${baseURL}customer/account" class="truncate-account-name-desktop" style="color:black;">${desktopName}</a>`
    );
    // $(".accountNameMobile").html(
    //   `<a href="${baseURL}customer/account" class="truncate-account-name">${result.Customer_Name}</a>`
    // );
    $(".customerProfileImage").html(
      `<img src="https://img.icons8.com/color/32/null/circled-user-male-skin-type-7--v1.png"/>`
    );
  };

  //upload user information
  uploadUpdatedProfile = () => {
    console.log("Inside upload flow");

    // var allCookies = UtilObject.getAllCookies();
    // var customerID = allCookies.CUSTOMER_ID;
    // Customer_ID: customerID,

    $.ajax({
      url: baseURL + "api/customer/Account/UpdateCustomer",
      type: "POST",
      data: {
        Customer_ID: customerID,
        Customer_Name: this.fullNameDisplayDesk.val(),
        Gender: this.genderDisplayDesk.val(),
        Date_Of_Birth: this.dobDisplayDesk.val(),
        Phone_Number: this.mobNoDisplayDesk.val(),
        Email: this.emailIdDisplayDesk.val(),
      },
      success: (result) => {
        $("#passwordConfirmationPage").val("");
        $("#passwordConfirmationPageError").html("");
        window.$("#checkPasswordConfirmationPage").modal("hide");
        window.$("#profileUpdatedChangedSuccesfully").modal("show");
        this.getAccountDetails();
      },
      error: (result) => {
        console.log(result);
      },
    });
  };

  //check if user has entered a different number
  phnoIsUnique_validate = () => {
    console.log(this.mobNoDisplayDesk.val());
    console.log(oldPhone);

    console.log("in phone exist validate");
    let flag = true;

    if (this.mobNoDisplayDesk.val() == oldPhone) {
      flag = true;
    } else {
      var phone = false;

      //Check if phone is unique
      $.ajax({
        type: "POST",
        //check if phone number exists
        url: baseURL + "api/customer/Account/logInPhoneCustomer",
        async: false,

        //headers: { Authorization: X_API_KEY },

        data: {
          Phone_Number: this.mobNoDisplayDesk.val(),
        },
        success: () => {
          console.log("Phone is unique");
          phone = true;
        },
        error: () => {
          console.log("Phone is not unique");
          this.mobNoDisplayDeskError.html("Phone Number already Exists");
        },
      });

      console.log("Outside Ajax");

      if (phone) {
        flag = true;
      } else {
        flag = false;
      }
    }

    return flag;
  };

  emailIsUnique_validate = () => {
    console.log(this.emailIdDisplayDesk.val());
    console.log(oldEmail);

    console.log("in email exist validate");
    let flag = true;

    if (this.emailIdDisplayDesk.val() == oldEmail) {
      flag = true;
    } else {
      var email = false;

      //check if email is unique
      $.ajax({
        type: "POST",
        //check if phone number exists
        url: baseURL + "api/customer/Account/emailIsUnique",
        async: false,

        //headers: { Authorization: X_API_KEY },
        data: {
          cutomerEmail_Address: this.emailIdDisplayDesk.val(),
        },
        success: () => {
          console.log("Email is unique");
          email = true;
        },
        error: () => {
          console.log("Email is not unique");
          if ($("#emailIdDisplayDeskCustomerServicing").val() == "") {
            this.emailIdDisplayDeskError.html("Email Address is mandatory");
          } else {
            this.emailIdDisplayDeskError.html("Email Address already exists");
          }
        },
      });
      console.log("Outside Ajax");

      if (email) {
        flag = true;
      } else {
        flag = false;
      }
    }

    return flag;
  };

  //password check
  submitPasswordCheck = () => {
    // var allCookies = UtilObject.getAllCookies();
    // var customerID = allCookies.CUSTOMER_ID;

    // Customer_ID: customerID,

    $.ajax({
      type: "POST",
      url: baseURL + "api/customer/Account/userPasswordCheck",
      async: false,
      //headers: { Authorization: X_API_KEY },
      data: {
        Customer_ID: customerID,
        Password: this.passwordConfirmationPage.val(),
      },
      success: (res) => {
        console.log(res);
        var code = res.Code;
        console.log(code);
        if (code == 2) {
          this.passwordConfirmationPageError.html("Password is incorrect");
        } else {
          //check if number is changed
          if (this.mobNoDisplayDesk.val() == oldPhone) {
            this.uploadUpdatedProfile();
          } else {
            //number is changed
            window.$("#checkPasswordConfirmationPage").modal("hide");
            window.$("#verifyOtpModalAccount").modal("show");
            this.sendOtp();
          }
        }
      },
      error: (res) => {
        console.log(res);
      },
    });
  };

  sendOtp = () => {
    console.log(this.mobNoDisplayDesk.val());
    $.ajax({
      type: "POST",
      url: baseURL + "api/customer/Account/sendOTPSignUp",
      async: false,
      //headers: { Authorization: X_API_KEY },
      data: {
        Phone_Number: this.mobNoDisplayDesk.val(),
      },
      success: (data) => {
        console.log(data);
        OTP = data.OTP;
      },
      error: (err) => {
        console.log(err);
      },
    });
  };

  verifyOtp = () => {
    //OTP = 1234;
    var verifyOtp = /^\d{4}$/;
    if (!this.otpInputVerify.val().match(verifyOtp)) {
      console.log("OTP format error");
      this.otpError.html("Enter the Correct OTP format");
    } else {
      if (OTP == this.otpInputVerify.val()) {
        console.log("It works");
        this.otpError.html("OTP Verified");
        window.$("#verifyOtpModalAccount").modal("hide");
        $("#otpInputVerifyAccount").val("");
        $("#otpErrorAccount").val("");

        //Store Customer details changes in the database
        this.uploadUpdatedProfile();
      } else {
        this.otpError.html("Enter the Correct OTP");
      }
    }
  };

  //SECURITY PAGE

  securityPageValidation = (e) => {
    e.preventDefault();
    console.log("in securityPageValidation");
    console.log(this.toggleChangePasswordBtn);
    //3 validations
    // if (this.toggleChangePasswordBtn.is(":checked")) {
    //NewPassword
    // let isNewPasswordValidated = this.NewPassword_validate();
    // if (isNewPasswordValidated) {
    //   console.log(" NewPassword VALID");
    //   this.New_PasswordError.html("");
    // }

    //CurrentPassword
    let isCurrentPasswordValidated = this.CurrentPassword_validate();
    if (isCurrentPasswordValidated) {
      console.log(" CurrentPassword VALID");
      this.Current_PasswordError.html("");
    }

    //ConfirmPassword
    let isConfirmPasswordValidated = this.ConfirmPassword_validate();
    if (isConfirmPasswordValidated) {
      console.log(" ConfirmPassword VALID");
      this.Confirm_PasswordError.html("");
    }

    if (
      // isNewPasswordValidated &&
      isCurrentPasswordValidated &&
      isConfirmPasswordValidated
    ) {
      this.setNewPassword();
    }
  };

  //validate function
  CurrentPassword_validate = () => {
    console.log("in ConfirmPassword validate");
    let flag = true;

    if (
      !(
        this.Current_Password.val().length >= 6 &&
        this.Current_Password.val().length <= 15
      )
    ) {
      this.Current_PasswordError.html(
        "Password must be min 6 and max 15 characters"
      );

      flag = false;
    }

    return flag;
  };


  //validate function
  ConfirmPassword_validate = () => {
    console.log("in ConfirmPassword validate");
    let flag = true;

    if (
      !(
        this.Confirm_Password.val().length >= 6 &&
        this.Confirm_Password.val().length <= 15
      )
    ) {
      this.Confirm_PasswordError.html(
        "Password must be min 6 and max 15 characters"
      );

      flag = false;
    }

    return flag;
  };

  //password validation
  passwordvalidate1 = () => {
    console.log("in Password validate");
    let flag = true;
    if (
      !(
        this.saveNewPasswordInputForgotPassword.val().length >= 6 &&
        this.saveNewPasswordInputForgotPassword.val().length <= 15
      )
      // ||
      // !this.saveNewPasswordInput.val().match(PasswordExample)
    ) {
      this.successNewPasswordForgotPassword.addClass("text-danger");
      this.successNewPasswordForgotPassword.removeClass("text-success");
      this.successNewPasswordForgotPassword.html(
        "Please use min 6 and max 15 characters"
      );

      flag = false;
    }
    return flag;
  };

  //set new password
  setNewPassword = () => {
    console.log("Inside set new password");
    // var allCookies = UtilObject.getAllCookies();
    // var customerID = allCookies.CUSTOMER_ID;
    // Customer_ID: customerID,Customer_ID: `1e461723-6723-4772-bdcb-bdb0b9bc3eab`,
    $.ajax({
      type: "POST",
      url: baseURL + "api/vendor/SetPasswordNew",
      async: false,
      headers: { Authorization: X_API_KEY },
      data: {
        OldPassword: this.Current_Password.val(),
        Password: this.Confirm_Password.val(),
      },
      success: this.NewPasswordUpdate,
      error: this.NewPasswordError,
    });

  };

  NewPasswordUpdate = (res) => {
    // console.log("password changed successfully");
    window.$("#passwordChangedSuccesfully").modal("show");
    $("#Current_PasswordAccount").val("");
    $("#Confirm_PasswordAccount").val("");
    console.log(res);
  };

  NewPasswordError = (res) => {
    // $("#Current_PasswordAccount").html(res.Msg);

    this.Current_PasswordError.html(res.responseJSON.Msg);

    // console.log(res.responseJSON.Msg);
    // console.log(res.responseJSON["Msg"]);

    console.log(res);
  };

  sendOtpForgotPassword = (e) => {
    e.preventDefault();
    $.ajax({
      type: "POST",
      url: baseURL + "api/vendor/SendOTPForgotPassword",
      //headers: { Authorization: X_API_KEY },
      data: {
        Phone_Number: this.mobileInputAccount.val(),
      },
      success: (data) => {
        console.log(data);
        // this.phoneNotRegisteredError.html("OTP Sent Succesfully");
        OTP = data.otp;
        console.log(OTP);

        //removinf input for mobile number
        this.mobileLabelForgotPassword.addClass("d-none");
        this.forgotPswdMobileNumberInput.addClass("d-none");
        // this.phoneNotRegisteredError.addClass("d-none");
        this.sendOTPForgotPasswordButton.addClass("d-none");

        //adding input for otp
        this.OTPLabelForgotPassword.removeClass("d-none");
        this.OTPInputForgotPassword.removeClass("d-none");
        this.OTPSuccessForgotPassword.removeClass("d-none");
        this.resendOTPForgotPasswordButton.removeClass("d-none");
        this.verifyOTPBtnForgotPassword.removeClass("d-none");
      },
      error: (err) => {
        console.log(err);
        // var errMessage = err.responseJSON.Msg;
        // this.phoneNotRegisteredError.html(errMessage);
      },
    });
  };

  //validtaions
  doVerifyOTP = () => {
    console.log("in doVerifyOTP");
    let isOTPValidated = this.verifyOtpForgotPassword();
    if (isOTPValidated) {
      this.OTPSuccessForgotPassword.addClass("text-success");
      this.OTPSuccessForgotPassword.removeClass("text-danger");
      this.OTPLabelForgotPassword.addClass("d-none");
      this.OTPInputForgotPassword.addClass("d-none");
      this.resendOTPForgotPasswordButton.addClass("d-none");
      this.verifyOTPBtnForgotPassword.addClass("d-none");
      this.OTPSuccessForgotPassword.addClass("d-none");
      this.newPasswordLabelForgotPassword.removeClass("d-none");
      this.saveNewPasswordInputForgotPassword.removeClass("d-none");
      this.saveNewPasswordBtnForgotPassword.removeClass("d-none");
      $("#otp-labelForgetPasswordAccount").val("");
    }
  };

  verifyOtpForgotPassword = () => {
    console.log("inside verifyOTPFunction ");
    let flag = true;
    let OTPExample = /^\d{4}$/;
    if (!this.OTPInputForgotPassword.val().match(OTPExample)) {
      flag = false;
      this.OTPSuccessForgotPassword.addClass("text-danger");
      this.OTPSuccessForgotPassword.removeClass("text-success");
      this.OTPSuccessForgotPassword.html("Enter 4 digit number only ");
    } else {
      console.log(OTP);
      console.log(this.OTPInputForgotPassword.val());

      if (OTP == this.OTPInputForgotPassword.val()) {
        flag = true;
      } else {
        flag = false;
        this.OTPSuccessForgotPassword.addClass("text-danger");
        this.OTPSuccessForgotPassword.removeClass("text-success");
        this.OTPSuccessForgotPassword.html("Enter the Correct OTP");
      }
    }
    return flag;
  };

  doSaveNewForgotPassword = () => {
    let newPassword = this.saveNewPasswordInputForgotPassword.val();
    let newPassValidation = this.passwordvalidate1();

    if (newPassValidation) {
      $.ajax({
        type: "POST",
        url: baseURL + "api/vendor/UpdatePassword",
        headers: { Authorization: X_API_KEY },

        data: {
          Password: newPassword, //Password column name
          Phone_Number: this.mobileInputAccount.val(),
        },

        //headers: { Authorization: X_API_KEY },

        success: (res) => {
          console.log(res);
          $("#otp-labelForgetPasswordAccount").val("");
          $("#newForgetPasswordAccount").val("");
          $("#success-New-PasswordAccount").html("");
          $("#OTP-successAccount").html("");
          $("#otp-inputForgetPasswordAccount").val("");

          window.$("#forgotpassModalForServicingSecurityPage").modal("hide");
          window.$("#passwordChangedSuccesfully").modal("show");

          //reset forgot pssword to original
          this.sendOTPForgotPasswordButton.removeClass("d-none");
          this.forgotPswdMobileNumberInput.removeClass("d-none");
          this.mobileLabelForgotPassword.removeClass("d-none");
          this.saveNewPasswordBtnForgotPassword.addClass("d-none");
          this.newPasswordLabelForgotPassword.addClass("d-none");
          this.saveNewPasswordInputForgotPassword.addClass("d-none");
        },
      });
    }
  };





}
