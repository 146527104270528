var imgSelected = true;
var inpFileOne1 = document.getElementById("inpBusinessImg");
var img1flag;
var BusinessImage;
var otp;
var newEditProductImages = [];
var inpFileOne1 = document.getElementById("inpBusinessImg");
var inpFileOne = document.getElementById("inpBusinessImgPreview1");
var vendorDocumentArray = [];
var businessImageArray = [];


import s3Util from "../helpers/s3Util";
var s3Object = new s3Util();

export default class vendorServicing_profile {
  constructor() {
    this.basicDetailsAndAddressForm = $("#basicDetails-AddressForm1");
    this.inputBusinessName = $("#inputBusinessName");
    this.inputContactPersonName = $("#inputContactPersonName");
    this.inputPhoneNumber = $("#inputPhoneNumber");
    this.inputEmail1 = $("#inputEmail1");
    this.submitBtn = $("#editNamesubmitBtn");
    this.saveChangesBtn = $("#saveChangesBtn");
    this.businessNameError = $("#businessNameError");
    this.contactPersonNameError = $("#contactPersonNameError");
    this.phoneNumberError = $("#phoneNumberError");
    this.emailError = $("#emailError");
    this.BusinessImageError = $(".BusinessImageError");
    this.venId = $("#venId");
    this.verifyOtpModal = $("#verifyOtpModal");
    this.OTPInputVerify = $("#otp-input-verify");
    this.OTPSuccess = $("#otpError");
    this.verifyOTPDetails = $("#verify-edit-OTP-details");
    this.verifyOTPBtn = $("#verify-OTP-btn");
    this.resendOTPBtn = $("#resend-OTP-btn");
    this.stem2submitBtn = $("#stem2submitBtn");
    this.Products_Services_page4 = $(".Products-Services-page4");
    this.otpInputVerify = $("#otpInputVerify");
    this.otpError = $("#otpError");
    this.verifyOtpModalClose = $("#verifyOtpModalClose");
    this.upload_form = $("#upload_form");

    //new Variables
    this.profileImage1 = $("#inpBusinessImg");
    this.profileImage2 = $("#inpBusinessImg2");
    this.profileImage3 = $("#inpBusinessImg3");

    this.vendorProfileImg = $(".vendorProfileImg");

    this.formProfile = $("#basicDetails-AddressForm1");

    this.bindEvents();
  }

  bindEvents = () => {
    this.get_Vendor_Summary();
    console.log("inside basic details page");
    globalThis.phnoEmailIsUnique = false;
    globalThis.userCreated = false;
    globalThis.prevPhoneNo = 0;
    globalThis.prevEmail = "old";

    this.submitBtn.on("click", this.doFormAction);
    this.verifyOTPBtn.on("click", this.doVerifyOTP);
    this.resendOTPBtn.on("click", this.doResendOTPAction);

    this.saveChangesBtn.on("click", this.closeModal);
    this.verifyOtpModalClose.on("click", this.closeOTPModal);

    console.log("checking if image is image");
    // inpFileOne1.addEventListener("change", this.ImageValidate);
    this.vendorProfileImg.on("change", this.imageChangefn);

    // this.inputPhoneNumber.on("focus", this.prevScroll);//individual input

    this.formProfile.on("focus", "input[type=number]", function (e) {
      $(this).on("wheel.disableScroll", function (e) {
        // console.log("inhere");
        e.preventDefault();
      });
    });

    $(".vendorProfileImg").on("click", cropperMain.doCroppingAction());
  };

  //for individual input
  // prevScroll = (e) => {
  //    this.inputPhoneNumber.on('wheel.disableScroll', function (e) {
  //       console.log("inhere");
  //       e.preventDefault()
  //     })

  //   // $('formProfile').on('blur', 'input[type=number]', function (e) {
  //   //     console.log("inhere");

  //   //   $(this).off('wheel.disableScroll')
  //   // })
  //  };

  imageChangefn = (e) => {
    console.log("NEW ON CHANGE IMAGE FN START");

    console.log(e);
    var imgInput = e.target.id;
    var imgPrev = e.target.defaultValue;
    var imgNumber = e.target.name;
    var imgError = "BusinessImageError" + imgNumber;
    console.log(imgInput);
    console.log(imgPrev);
    console.log(imgNumber);
    console.log(imgError);

    const imgInputEle = document.getElementById(imgInput);
    const imgPrevEle = document.getElementById(imgPrev);
    const imgErrorEle = document.getElementById(imgError);

    console.log(imgInputEle);
    console.log(imgPrevEle);
    console.log(imgErrorEle);

    console.log(imgInputEle.files[0]);

    var imgExpression = /.*\.(jpg|jpeg|png|JPG|JPEG|PNG)$/;
    if (!imgInputEle.files[0]) {
      console.log("INSIDE !inpFileOne1.files[0] IF CONDITION");
      imgPrevEle.src = "";
      console.log(imgInputEle);
      console.log(imgInputEle.files[0]);
      // imgSelected = false;
    } else {
      if (!imgInputEle.files[0]["name"].match(imgExpression)) {
        console.log("inside image validate if");
        // img1flag = false;
        // imgSelected = false;

        imgPrevEle.src = "";

        console.log(imgErrorEle);
        imgInputEle.value = "";
        imgErrorEle.innerHTML = "Please select an image file";
        imgErrorEle.value = 1;
      } else {
        imgErrorEle.innerHTML = "";
        imgErrorEle.value = 0;
      }
    }
    console.log("NEW ON CHANGE IMAGE FN END");
  };

  ImageValidate = () => {
    console.log("INSIDE VALIDATION ImgError[i].value");

    const ImgError = document.getElementsByClassName("ImgError");
    for (var i = 0; i < ImgError.length; i++) {
      console.log(ImgError[i].value);
      if (ImgError[i].value == 1) {
        alert("Current immages are not valid");
        return false;
      }
    }
    return true;
  };

  get_Vendor_Summary = () => {
    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/getVendorDetails",
      headers: { Authorization: X_API_KEY },
      success: this.show_vendor_details,
    });
  };

  show_vendor_details = async (res) => {

    var vendorImgURLs = ""
    // Loading Business Images
    for (var i = 1; i <= 3; i++) {
      var fullUrl = "Vendor/" + res.Image_URL + "/Document Images/Business Image/" + i + ".png";
      // var vendorImg = await s3Object.getObjectURL(fullUrl); //element.Image_URL
      // vendorImgURLs = res.Image_URL + i;
      // vendorImgURLs = res.Image_URL;

      businessImageArray[i - 1] = await s3Object.getObjectURL(fullUrl); //element.Image_URL

      document.getElementById("inpBusinessImgPreview" + i).src =
        businessImageArray[i - 1];
    }
    console.log(businessImageArray);

    // document.getElementById("inpBusinessImgPreview").src =
    //   "https://goancart-data-pro.s3.ap-south-1.amazonaws.com/Vendor/252_gdfg+dfsg+dfs/Document+Images/Business+Image.png";

    document.getElementById("inputBusinessName").value = res.Business_Name;
    document.getElementById("inputContactPersonName").value =
      res.Contact_Person;
    document.getElementById("inputPhoneNumber").value = res.Phone_Number;
    document.getElementById("inputEmail1").value = res.Email_Address;

    prevPhoneNo = this.inputPhoneNumber.val();
    prevEmail = this.inputEmail1.val();
  };

  doFormAction = (e) => {
    phnoEmailIsUnique = false;
    e.preventDefault();
    console.log("in doFormAction Basic Details");

    console.log("Moving to part 2 ");

    const inpFile = document.getElementById("inpBusinessImg");

    console.log(inpFile.files[0]);
    //let inputFileOneValue = inpFile.files[0];

    console.log(inpFile.files[0] === undefined);
    if (inpFile.files[0] === undefined) {
      // this.BusinessImageError.html("Please select an image");
      imgSelected = true;
      img1flag = 1;
    } else {
      this.ImageValidate();
    }

    // this.ImageValidate();
    if (imgSelected) {
      console.log("image select VALID");
      this.BusinessImageError.html("");
    } else {
      this.BusinessImageError.html("Please select an image.");
    }
    console.log("imgSelected");

    console.log(imgSelected);

    let isBusinessNameValidated = this.BusinessName_validate();
    if (isBusinessNameValidated) {
      console.log("BusinessNameValidated VALID");
      this.businessNameError.html("");
    }

    let isContactPersonNameValidated = this.ContactPersonName_validate();
    if (isContactPersonNameValidated) {
      console.log("ContactPersonNameValidated VALID");
      this.contactPersonNameError.html("");
    }

    let isPhValidated = this.Phno_validate();
    if (isPhValidated) {
      console.log(" ph VALID");
      this.phoneNumberError.html("");
    } else {
      console.log("ERROR");
    }

    let isEmailValidated = this.Email_validate();
    if (isEmailValidated) {
      console.log("EmailValidated VALID");
      this.emailError.html("");
    }

    console.log("phnoEmailIsUnique Validation Here");
    console.log(phnoEmailIsUnique);
    console.log(prevPhoneNo);
    console.log(prevEmail);

    if (true) {
      this.phnoEmailIsUnique_validate();
      console.log("isPhNoEmailValidated VALID");
      console.log(phnoEmailIsUnique);

      if (phnoEmailIsUnique) {
        this.emailError.html("");
        this.phoneNumberError.html("");
      }
    }

    if (
      isBusinessNameValidated &&
      isContactPersonNameValidated &&
      isPhValidated &&
      isEmailValidated &&
      phnoEmailIsUnique &&
      imgSelected &&
      this.ImageValidate()
    ) {
      $.ajax({
        type: "POST",
        url: baseURL + "api/Vendor/SendOTPSignUp",

        headers: { Authorization: X_API_KEY },

        data: this.basicDetailsAndAddressForm.serialize(),
        success: (data) => {
          console.log(data);
          otp = data["otp"];
          setTimeout(() => {
            console.log("Timeout");
            this.OTPSuccess.addClass("text-danger");

            this.OTPSuccess.html("OTP has expired, Try again.");
            otp = null;
            console.log(otp);
          }, 600000);
        },
        error: (err) => {
          console.log(err);
        },
      });
      this.OTPSuccess.html(" ");
      // this.otpInputVerify.val(" ");
      // this.verifyOtpModal.show();
      window.$("#verifyOtpModal").modal("show");
      // this.Submit_Form();
      console.log("ALL FIELDS VALID...VALIDATION OVER");
    }
  };

  closeModal = () => {
    this.autoDetectModal.hide();
  };

  closeOTPModal = () => {
    // this.verifyOtpModal.hide();
    window.$("#verifyOtpModal").modal("hide");

    // this.otpInputVerify.val(" ");
  };

  phnoEmailIsUnique_validate = () => {
    if (
      prevPhoneNo == this.inputPhoneNumber.val() &&
      prevEmail == this.inputEmail1.val()
    ) {
      phnoEmailIsUnique = true;
    } else {
      $.ajax({
        type: "POST",
        url: baseURL + "api/Vendor/CheckPhNoEmailExists",
        async: false,

        headers: { Authorization: X_API_KEY },

        data: this.basicDetailsAndAddressForm.serialize(),
        statusCode: {
          400: function () {
            alert("400 status code! user error");
          },
          500: function () {
            alert("500 status code! server error");
          },
        },
        success: this.CheckPhNoEmailExistsSuccess,
      });
      console.log("Outside Ajax");

      console.log(phnoEmailIsUnique);
    }
  };

  CheckPhNoEmailExistsSuccess = (res) => {
    let retCode = res.Code;
    console.log(retCode);

    if (retCode == 6) {
      console.log("Inside phoneExists Fn Error 6");

      this.phoneNumberError.html("Phone Number already exists");
      if (prevPhoneNo == this.inputPhoneNumber.val()) {
        phnoEmailIsUnique = true;
      } else {
        phnoEmailIsUnique = false;
      }
    }
    if (retCode == 7) {
      this.emailError.html("Email already exists");
      console.log("ret code7");

      if (prevEmail == this.inputEmail1.val()) {
        phnoEmailIsUnique = true;
      } else {
        phnoEmailIsUnique = false;
      }
    }
    if (retCode == 8) {
      // this.emailError.html("Email and PhoneNo already exists");
      if (prevPhoneNo != this.inputPhoneNumber.val()) {
        this.phoneNumberError.html("Phone Number already exists");
      }
      if (prevEmail != this.inputEmail1.val()) {
        this.emailError.html("Email already exists");
        console.log("prev email");
      }
      if (
        prevPhoneNo == this.inputPhoneNumber.val() &&
        prevEmail == this.inputEmail1.val()
      ) {
        phnoEmailIsUnique = true;
      } else {
        phnoEmailIsUnique = false;
      }
    }
    if (retCode == 0) {
      // this.emailError.html("Email already exists");
      console.log("unique ph no email");

      phnoEmailIsUnique = true;
    }
    console.log("Inside Ajax Success Ajax");

    console.log(phnoEmailIsUnique);
  };

  //validate function

  BusinessName_validate = () => {
    console.log("in Business Name validate");
    let flag = true;

    if (
      !(
        this.inputBusinessName.val().length >= 2 &&
        this.inputBusinessName.val().length <= 100
      )
    ) {
      this.businessNameError.html(
        "Business Name must be min 2 and max 100 characters"
      );

      flag = false;
    }

    return flag;
  };

  ContactPersonName_validate = () => {
    console.log("in ContactPerson Name validate");
    let flag = true;
    var contactname = /^[a-z ,.'-]+$/i;
    if (
      !(
        this.inputContactPersonName.val().length >= 2 &&
        this.inputContactPersonName.val().length <= 100
      ) ||
      !this.inputContactPersonName.val().match(contactname)
    ) {
      this.contactPersonNameError.html(
        "Contact Person Name must min 2 and max 100 characters"
      );

      flag = false;
    }

    return flag;
  };

  Phno_validate = () => {
    console.log("in Phone number validate");
    let flag = true;
    // var phoneno = /^\d{10}$/;
    var phoneno = /^[1-9]{1}[0-9]{9}$/;
    if (!this.inputPhoneNumber.val().match(phoneno)) {
      this.phoneNumberError.html(
        "Please enter only 10 digits for mobile number"
      );

      flag = false;
    }

    return flag;
  };

  Email_validate = () => {
    console.log("in Email validate");
    let flag = true;
    var emailexample =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!this.inputEmail1.val().match(emailexample)) {
      this.emailError.html("Please enter a valid email");

      flag = false;
    }

    return flag;
  };

  doVerifyOTP = (e) => {
    e.preventDefault();

    console.log("in doVerifyOTP");
    let isOTPValidated = this.verifyOTPFunction();
    if (isOTPValidated) {
      this.OTPSuccess.addClass("text-success");
      this.OTPSuccess.removeClass("text-danger");
      this.OTPSuccess.html("OTP sent ");
      this.OTPInputVerify.prop("disabled", true);
      this.verifyOTPBtn.addClass("d-none");
      this.verifyOTPDetails.addClass("d-none");
      this.submitBtn.removeClass("d-none");
      // this.verifyOtpModal.hide();
      window.$("#verifyOtpModal").modal("hide");

      // setTimeout(demo, 5000);
      this.Submit_Form();
    } else {
      this.OTPSuccess.addClass("text-danger");
      //this.OTPSuccess.removeClass("text-success");
      this.OTPSuccess.html("Invalid OTP");
    }
  };

  verifyOTPFunction = () => {
    console.log("inside verifyOTPFunction ");
    let flag = true;
    let OTPExample = /^\d{4}$/;

    console.log(otp);
    if (!this.otpInputVerify.val().match(OTPExample)) {
      flag = false;
    } else {
      console.log("otp sent:");
      console.log(otp);
      console.log("otp entered:");
      console.log(this.otpInputVerify.val());
      if (otp == this.otpInputVerify.val()) {
        flag = true;
      } else {
        flag = false;
      }
    }

    console.log(flag);

    return flag;
  };

  Submit_Form = () => {
    console.log("Inside submit flow");

    this.phoneNumberError.html("");
    this.emailError.html("");
    prevPhoneNo = this.inputPhoneNumber.val();
    prevEmail = this.inputEmail1.val();
    $.ajax({
      type: "POST",
      url: baseURL + "api/Vendor/VerifyOTPUpdateDetails",
      // data : {
      //     "name" : phoneNumberInput,
      //     "category" : passwordInput
      //       },
      headers: { Authorization: X_API_KEY },

      data: this.basicDetailsAndAddressForm.serialize(),
      statusCode: {
        400: function () {
          alert("400 status code! user error");
        },
        500: function () {
          alert("500 status code! server error");
        },
      },
      success: this.Validate_Email,
      // error: function (res) {
      //   console.log(res);
      //   this.phoneExists;
      // },
    });

    console.log("inpFileOne");
    console.log(inpFileOne);
    console.log("inpFileOne.src");
    console.log(inpFileOne.src);
    console.log("inpFileOne.src.length");
    console.log(inpFileOne.src.length);

    this.UploadBusinessImages();
    // $.ajax({
    //   type: "POST",
    //   url: baseURL + "api/Vendor/documentUpload",
    //   data: {
    //     vendorDocumentArray: vendorDocumentArray,
    //   },
    //   success: function (res) {
    //     // console.log(res);
    //   },
    // });
    this.otpInputVerify.val("");
    this.otpError.html("");
    this.verifyOTPBtn.removeClass("d-none");
  };

  Validate_Email = () => {
    let userEmail = this.inputEmail1.val();
    $.ajax({
      type: "POST",
      url: baseURL + "api/ZeroBounce/update",
      data: {
        basicEmail: document.getElementById("inputEmail1").value,
      },
      headers: { Authorization: X_API_KEY },

      success: function (res) {
        console.log(res);
      },
    });
  };

  UploadBusinessImages = async () => {
    console.log("Vendor Business Images UPLOAD");

    var key = "";
    //Get Vendor Details
    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/getVendorDetails",
      headers: { Authorization: X_API_KEY },
      async: false,
      success: (result) => {
        key = `Vendor/${result.Image_URL}/Document Images/Business Image/`; // Specify the desired key or path
      },
    });

    for (var i = 1; i <= 3; i++) {
      const imgPrevEle = document.getElementsByClassName("Businessimage-preview");

      const CurrentimgPrevEle = imgPrevEle[i - 1];
      const imageData = CurrentimgPrevEle.src;
      const regex = /^data:/;

      console.log(regex.test(imageData)); // Output: true

      if (regex.test(imageData)) {
        var Currentkey = key + i + ".png";
        await s3Object.uploadCropperImageToS3(imgPrevEle[i - 1].src, Currentkey);
      }
    }
  };

  doResendOTPAction = (e) => {
    e.preventDefault();
    this.doFormAction(e);
    $("#otpResendMsg").html("New OTP has been sent to you.");
    setTimeout(
      () => {
        $("#otpResendMsg").html("");
      }, 4000);
  }
}
