export default class vendor_servicing_payment {
  constructor() {
    this.viewDetailsButton = $(".viewDetailsButton");

    this.bindEvents();
  }

  bindEvents = () => {
    this.LoadPayment(0);
    this.LoadSponsoredPayment(0);

  };

  LoadPayment = (Offset) => {
    console.log("inside LoadPayment");

    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/showVendorPayments",
      headers: { Authorization: X_API_KEY },
      data: {
        Offset: Offset,
      },

      success: this.showPaymentsOrEmpty,
    });
  };
  showPaymentsOrEmpty = (res) => {
    if (res != "NO PAYMENTS") {
      this.showPayments(res);
    } else {
      this.showPaymentsEmpty(res);
    }
  };

  showPayments = (res) => {
    console.log("showPayments");

    console.log(res);

    res.forEach((element) => {
      console.log(element.payment_status);

      if (element.payment_status == "PAID" || element.Wallet_Amount_Added < 0) {
        console.log("paid");
        var paid = `<span class=" d-flex justify-content-end" style="color:#50C878">Successful</span>`;
      } else {
        console.log("unsuccessful");
        var paid = `<span class="productListing-Truncate2 d-flex justify-content-end" style="color:red">Failed</span>`;
      }

      if (element.Order_No != null) {
        var orderNumInfo = `<span class="productListing-Truncate2 mt-2">Order Number: ${element.Order_No}</span>`;
      } else {
        var orderNumInfo = ``;
      }


      var item = `<div class="row border mt-1 mb-1 ml-1 mr-1">
        
                
                    <div class="col-md-2 col-2 mt-3 mb-3 d-flex justify-content-center">
                        <img src="assets/src/images/pancake.jpeg" class="img-load img-fluid my-auto rounded-circle" width="70%">
                    </div>

                    
                    <div class="col-md-7 col-7 mt-3 ">
                        <h6 class="productListing-Truncate2">${element.Vendor_Name}</h6>
                        <span class="productListing-Truncate2">${element.Date_Time}</span>
                        ${orderNumInfo}
                    </div>
                    
                    <div class="col-md-3 col-3 mt-3">
                        <h6 class="productListing-Truncate2 d-flex justify-content-end">&#8377;${element.Wallet_Amount_Added}</h6>
                        <div>${paid}</div>
                        <h6 class="d-flex justify-content-end mt-1">&#8377;${element.Total_Points}</h6>
                    </div>
                </div>
      
      `;
      $("#paymentRows").append(item);
    });
  };

  showPaymentsEmpty = (res) => {
    console.log("showPayments  Empty");
    console.log(res);
    $("#paymentRows").empty(item);

    var item = `<div class="card text-center ml-3 mr-3 align-middle" id="noActiveOrder">
                      <!-- DEFAULT UI MESSAGE WHEN NO ACTIVE ORDER -->
                      &nbsp;
                      &nbsp;
                      &nbsp;


                      <div class="row">
                      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4v1.06Z"/>
                      </svg>
                      </div>
                      &nbsp;

                      <div class="row">

                          <h5 style="color:black">No Payments Made To Goancart</h5>
                      </div>
                      &nbsp;

                      <div class="row">

                          <p>Your payments to Goancart will appear here.</p>
                      </div>
                  </div>
                 `;
    $("#paymentRows").append(item);
  };

  LoadSponsoredPayment = (Offset) => {
    console.log("inside LoadSponsoredPayment");

    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/showVendorSponsoredPayments",
      headers: { Authorization: X_API_KEY },
      data: {
        Offset: Offset,
      },

      success: this.showSponsoredPaymentsOrEmpty,
    });
  };

  showSponsoredPaymentsOrEmpty = (res) => {
    if (res != "NO PAYMENTS") {
      this.showSponsoredPayments(res);
    } else {
      this.showSponsoredPaymentsEmpty(res);
    }
  }

  showSponsoredPayments = (res) => {
    res.forEach((element) => {
      console.log(element.payment_status);

      if (element.Payment_Status == "PAID") {
        var paid = `<span class=" d-flex justify-content-end" style="color:#50C878">Successful</span>`;
      } else {
        var paid = `<span class="productListing-Truncate2 d-flex justify-content-end" style="color:red">Failed</span>`;
      }

      var item = `<div class="row border mt-1 mb-1 ml-1 mr-1">  
                    <div class="col-md-2 col-2 mt-3 mb-3 d-flex justify-content-center">
                        <img src="assets/src/images/pancake.jpeg" class="img-load img-fluid my-auto rounded-circle" width="70%">
                    </div>

                    
                    <div class="col-md-7 col-7 mt-3 ">
                        <h6 class="productListing-Truncate2">${element.Vendor_Name}</h6>
                        <span class="productListing-Truncate2">Start Date:${element.Subscription_details.Start_date}</span>
                        <span class="productListing-Truncate2 mt-2">Subscription:${element.Subscription_details.Picklist_sub_type}</span>
                    </div>
                    
                    <div class="col-md-3 col-3 mt-3">
                        <h6 class="productListing-Truncate2 d-flex justify-content-end">&#8377;${element.Payment_Amount}</h6>
                        <div>${paid}</div>
                        <h6 class="d-flex justify-content-end mt-1">Duration:${element.Subscription_details.Picklist_save_value}Months</h6>
                    </div>
                </div>
      
      `;
      $("#sponsorshippaymentRows").append(item);
    });
  };

  showSponsoredPaymentsEmpty = (res) => {
    console.log("showSponsoredPayments  Empty");
    console.log(res);
    $("#sponsorshippaymentRows").empty(item);

    var item = `<div class="card text-center ml-3 mr-3 align-middle" id="noActiveOrder">
                      <!-- DEFAULT UI MESSAGE WHEN NO ACTIVE ORDER -->
                      &nbsp;
                      &nbsp;
                      &nbsp;


                      <div class="row">
                      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4v1.06Z"/>
                      </svg>
                      </div>
                      &nbsp;

                      <div class="row">

                          <h5 style="color:black">No Payments Made To Goancart</h5>
                      </div>
                      &nbsp;

                      <div class="row">

                          <p>Your payments to Goancart will appear here.</p>
                      </div>
                  </div>
                 `;
    $("#sponsorshippaymentRows").append(item);
  };

}
