import "table2csv";
export default class vendor_email_report {
  constructor() {

    this.emailSalesReportBtn = $(".emailSalesReportBtn");
    this.salesReportTable = $("#salesReportTable");
    this.startdate = $("#startdate");
    this.enddate = $("#enddate");


    this.bindEvents();
  }

  bindEvents = () => {
    this.emailSalesReportBtn.on("click", this.sendEmailReport);

  };

  sendEmailReport = () => {
    let startdateVal = this.startdate.val();
    let enddateVal = this.enddate.val();


    console.log('sales report');
    var options = {
      "separator": ",",
      "filename": "Sales Report"
    }
    $.ajax({
      url: baseURL + "api/Vendor/salesReport",
      headers: { Authorization: X_API_KEY },

      type: "POST",
      async: false,
      data: {
        // Vendor_ID: this.Vendor_ID,
        // Start:"2000-01-01",
        // End:"2023-01-01",

        Start: startdateVal,
        End: enddateVal,
      },

      success: this.loadTableData,

    });


    $("#salesReportTable").table2csv(options);
    $("#salesReportTable").empty();



  };

  loadTableData = (res) => {
    console.log(res);
    var items = ` <tr>
                      <th>Order No.</th>
                      <th>Date and Time of Order</th>
                      <th>Vendor Name</th>
                      <th>Customer Name</th>

                      <th>GST Number</th>
                      <th>GST Amount</th>
                      <th>Total Amount</th>
                      <th>Status of Order</th>
                  </tr>`;

    res.forEach((element) => {
      items = `${items} <tr>

        <td>=TRIM(${element.Order_No})</td>
        <td>${element.Order_booked_date_time}</td>
        <td>${element.Business_Name}</td>
        <td>${element.Customer_Name}</td>
      
        <td>${element.GST_Number}</td>
        <td>${element.GST}</td>
        <td>${element.Final_Bill_Amount}</td>
        <td>${element.Order_Status}</td>
    </tr>`;
    });
    $("#salesReportTable").append(items);
  }
}