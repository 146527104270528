var otp;
export default class Vendor_login {
  constructor() {
    console.log("in constructor");

    this.loginForm = $("#loginForm");
    this.phNoTextField = $("#phNoTextField");
    this.passwordTextField = $("#passwordTextField");
    this.submitBtn = $("#submitBtn");
    this.phnoError = $("#phnoError");
    this.passwordError = $("#passwordError");

    this.forgotPswdMobileNumberInput = $("#forgot-pswd-mobile-number-input");
    this.phoneNotRegisteredError = $("#phone-not-registered");
    this.sendOTPButton = $("#send-otp-button");
    this.OTPInput = $("#otp-input");
    this.saveAndProceedBtn = $("#save-and-proceed");
    this.saveNewPasswordBtn = $("#save-new-password-btn");
    this.saveNewPasswordInput = $("#save-new-password-input");

    this.successNewPassword = $("#success-New-Password");
    this.failureNewPassword = $("#failure-New-Password");
    this.modalTwo = $(".myModalsetpswd");
    //this.OTPError = $("#OTP-error");

    this.loginFailed = $("#loginFailed");
    this.verifyOTPBtn = $("#verify-otp-btn");
    this.OTPSuccess = $("#OTP-success");

    this.forgotPasswordHeader = $("#forgot-pswd-header");
    this.setPasswordHeader = $("#set-pswd-header");
    this.mobileLabel = $("#mobile-label");

    this.OTPLabel = $("#otp-label");
    this.newPasswordLabel = $("#new-password-label");

    // Registering Events
    this.bindEvents();
  }

  bindEvents = () => {
    console.log("in bind");
    this.submitBtn.on("click", this.doFormAction);
    this.sendOTPButton.on("click", this.doPhoneNumberCheck);
    this.saveNewPasswordBtn.on("click", this.doSaveNewPassword);
    this.verifyOTPBtn.on("click", this.doVerifyOTP);
  };

  doVerifyOTP = () => {
    //e.preventDefault();
    console.log("in doVerifyOTP");
    let isOTPValidated = this.verifyOTPFunction();
    if (isOTPValidated) {
      this.OTPSuccess.addClass("text-success");
      this.OTPSuccess.removeClass("text-danger");
      //this.OTPSuccess.html("OTP is verified ");
      //this.OTPInput.prop("disabled", true);
      //this.verifyOTPBtn.addClass("d-none");
      this.saveAndProceedBtn.prop("disabled", false);
      this.forgotPasswordHeader.addClass("d-none");
      this.OTPLabel.addClass("d-none");
      this.OTPInput.addClass("d-none");
      this.verifyOTPBtn.addClass("d-none");
      this.OTPSuccess.addClass("d-none");
      this.setPasswordHeader.removeClass("d-none");
      this.newPasswordLabel.removeClass("d-none");
      this.saveNewPasswordInput.removeClass("d-none");
      this.saveNewPasswordBtn.removeClass("d-none");
    } else {
      this.OTPSuccess.addClass("text-danger");
      this.OTPSuccess.removeClass("text-success");
      this.OTPSuccess.html("Invalid OTP");
    }
  };

  verifyOTPFunction = () => {
    console.log("inside verifyOTPFunction ");
    let flag = true;
    let OTPExample = /^\d{4}$/;

    console.log(otp);
    if (!this.OTPInput.val().match(OTPExample)) {
      flag = false;
    } else {
      console.log("otp sent:");
      console.log(otp);
      console.log("otp entered:");
      console.log(this.OTPInput.val());
      if (otp == this.OTPInput.val()) {
        flag = true;
      } else {
        flag = false;
      }
    }

    console.log(flag);

    return flag;
  };

  doFormAction = (e) => {
    e.preventDefault();
    console.log("in doFormAction");
    let isPhValidated = this.Phno_validate();
    if (isPhValidated) {
      console.log(" ph VALID");
      this.phnoError.html("");
    }

    let isPasswordValidated = this.Password_validate();
    if (isPasswordValidated) {
      console.log(" pass VALID");
      this.passwordError.html("");
    } else {
      console.log("ERROR");
    }

    if (isPhValidated && isPasswordValidated) {
      this.Submit_Form();
      console.log("BOTH FIELDS VALID...VALIDATION OVER");
    }
  };

  doPhoneNumberCheck = (e) => {
    e.preventDefault();
    console.log("inside dophonecheck"); //

    let isForgotPhValidated = this.Forgot_Phno_validate();
    if (isForgotPhValidated) {
      console.log(" forgot ph VALID");
      this.phoneNotRegisteredError.html("");
    }

    let forgotPhoneNumber = this.forgotPswdMobileNumberInput.val();
    console.log(forgotPhoneNumber);
    $.ajax({
      type: "POST",
      url: baseURL + "api/ForgotPasswordApi/phoneNumber", //phoneNumber is the api
      data: {
        Phone_Number: forgotPhoneNumber, //Phone_Number column name
      },
      headers: { Authorization: X_API_KEY }, //not used

      success: this.checkPhone,
    });
    $.ajax({
      type: "POST",
      url: baseURL + "api/Vendor/SendOTPForgotPassword",

      headers: { Authorization: X_API_KEY },

      data: {
        Phone_Number: forgotPhoneNumber, //Phone_Number column name
      },
      success: (data) => {
        otp = data["otp"];
        setTimeout(() => {
          console.log("Timeout");
          this.OTPSuccess.addClass("text-danger");

          this.OTPSuccess.html("OTP has expired, Try again.");
          otp = null;
          console.log(otp);
        }, 600000);
      },
      error: (err) => {
        console.log(err);
      },
    });
  };

  Forgot_Phno_validate = () => {
    console.log("in forgot ph validate");
    let flag = true;
    var forgotphoneno = /^\d{10}$/;
    if (!this.forgotPswdMobileNumberInput.val().match(forgotphoneno)) {
      //this.phoneNotRegisteredError.addClass("d-none");

      flag = false;
    }

    return flag;
  };

  doSaveNewPassword = () => {
    let newPassword = this.saveNewPasswordInput.val();
    let newPassValidation = this.passwordvalidate1();

    if (newPassValidation) {
      let forgotPhoneNumber = this.forgotPswdMobileNumberInput.val();
      $.ajax({
        type: "POST",
        // url: "http://localhost/goancart/api/ForgotPasswordApi/forgotPassword",
        url: baseURL + "api/vendor/UpdatePassword",

        data: {
          Password: newPassword, //Password column name
          Phone_Number: forgotPhoneNumber,
        },

        headers: { Authorization: X_API_KEY },

        success: function (res) {
          console.log(res);
        },
      });

      this.successNewPassword.addClass("text-success");
      this.successNewPassword.removeClass("text-danger");

      this.successNewPassword.html(
        "Your password has been successfully updated"
      );
      //this.saveNewPasswordBtn.prop("disabled", true);
      this.saveNewPasswordBtn.addClass("d-none");
      this.saveNewPasswordInput.prop("disabled", true);
      //this.modalTwo.addClass("d-none");
      //this.modalTwo.hide();
      //this.modalTwo.close();

      //let OTPNum = this.OTPInput.val();
    }
  };

  passwordvalidate1 = () => {
    console.log("in Password validate");
    let flag = true;
    // var PasswordExample =
    //   /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    if (
      !(
        this.saveNewPasswordInput.val().length >= 6 &&
        this.saveNewPasswordInput.val().length <= 15
      )
      // ||
      // !this.saveNewPasswordInput.val().match(PasswordExample)
    ) {
      this.successNewPassword.addClass("text-danger");
      this.successNewPassword.removeClass("text-success");
      this.successNewPassword.html("Please use min 6 and max 15 characters");

      flag = false;
    }
    return flag;
  };

  checkPhone = (res) => {
    console.log("inside checkphone");
    let vendorDetails = res.length;
    console.log(res);
    console.log(vendorDetails);

    if (vendorDetails == 0) {
      //alert("This phone number is not registered");
      this.phoneNotRegisteredError.html("This phone number is not registered");
    } else if (vendorDetails == 1) {
      this.phoneNotRegisteredError.addClass("d-none");
      //this.OTPInput.prop("disabled", false);
      this.verifyOTPBtn.prop("disabled", false);

      //this.forgotPswdMobileNumberInput.prop("disabled", true);
      //this.sendOTPButton.prop("disabled", true);

      this.mobileLabel.addClass("d-none");
      this.forgotPswdMobileNumberInput.addClass("d-none");
      this.sendOTPButton.addClass("d-none");

      this.OTPLabel.removeClass("d-none");
      this.OTPInput.removeClass("d-none");
      this.verifyOTPBtn.removeClass("d-none");

      //alert('This phone number is registered');

      // $(document).ready(function(){
      //     sendOTPButton.click(function(){
      //       modalTwo.modal();
      //     });
      //   });
    }
  };

  //validate function
  Phno_validate = () => {
    console.log("in ph validate");
    let flag = true;
    var phoneno = /^\d{10}$/;
    if (!this.phNoTextField.val().match(phoneno)) {
      this.phnoError.html("Please enter only 10 digits for mobile number!");

      flag = false;
    }

    return flag;
  };

  Password_validate = () => {
    console.log("in pass validate");
    let flag = true;

    if (
      !(
        this.passwordTextField.val().length >= 6 &&
        this.passwordTextField.val().length <= 15
      )
    ) {
      this.passwordError.html("Password must be min 6 and max 15 characters");

      flag = false;
    }

    return flag;
  };

  Submit_Form = () => {
    console.log("Inside submit flow");
    //code for submit form(api and ajax)
    let phoneNumberInput = this.phNoTextField.val();
    let passwordInput = this.passwordTextField.val();
    $.ajax({
      type: "POST",
      url: baseURL + "api/Vendor/login",
      data: this.loginForm.serialize(),
      headers: { Authorization: X_API_KEY },
      success: this.Login_result,
    });
  };
  Login_result = (res) => {
    console.log(res);
    console.log(res.Code);

    if (res.Code == 1) {
      this.loginFailed.html("");
      $.ajax({
        type: "POST",
        url: baseURL + "api/Vendor/setVendorSession",
        data: {
          Phone_Number: this.phNoTextField.val(),
        },
        async: false,
        headers: { Authorization: X_API_KEY },
        success: function (res) {
          console.log(res[0]["Vendor_ID"]);
          var currentVendor_ID = res[0]["Vendor_ID"];
          window.location = "Vendor_servicing_dashboard";
          // window.location.href = baseURL + "Vendor_servicing_dashboard?userid=" + currentVendor_ID;
        },
      });
    } else if (res.Code == 2) {
      this.loginFailed.html("Wrong Password or Phone number!");
    } else if (res.Code == 3) {
      // this.loginFailed.html("Signup Pending");
      console.log("Signup Pending");
      $.ajax({
        type: "POST",
        url: baseURL + "api/Vendor/setVendorSession",
        data: {
          Phone_Number: this.phNoTextField.val(),
        },
        async: false,
        headers: { Authorization: X_API_KEY },
        success: function (res) {
          console.log(res[0]["Vendor_ID"]);
          var currentVendor_ID = res[0]["Vendor_ID"];
          window.location = "Vendor_details?status=Pending";
          // window.location.href = baseURL + "Vendor_servicing_dashboard?userid=" + currentVendor_ID;
        },
      });
    }
  };
}

// success: function(data) {
//   if(data.status == 'success'){
//       alert("Thank you for subscribing!");
//   }else if(data.status == 'error'){
//       alert("Error on query!");
//   }
// },
