export default class vendorPaymentsInstamojo {
  constructor() {
    console.log("Instamojo payments javascripts works");
    $(".instamojo").on("click", this.generateInstamojoAccessToken);
  }

  generateInstamojoAccessToken = () => {
    $.ajax({
      url: baseURL + "api/InstamojoPayments/generateAccessToken",
      type: "GET",
      async: false,
      success: (result) => {
        result = JSON.parse(result);
        console.log(result);
        this.createPaymentRequest(result.access_token);
      },
      error: (result) => {
        result = JSON.parse(result);
        console.log(result);
      },
    });
  };

  createPaymentRequest = (access_token) => {
    $.ajax({
      url: baseURL + "api/InstamojoPayments/createPaymentRequest",
      type: "POST",
      async: false,
      data: {
        Access_Token: `Bearer ${access_token}`,
        Vendor_ID: "67ccb451-4497-11e9-b548-778fd1e3dff0",
        Amount: "100",
        Payment_Type: "Sponsored Payment",
      },
      success: (result) => {
        result = JSON.parse(result);
        console.log(result);
        // Simulate an HTTP redirect:
        window.location.replace(result.longurl);
        //const paymentSessionId = result["payment_session_id"];
        //const cf = new Cashfree(paymentSessionId, dropConfig);
        //cf.redirect();
      },
      error: (result) => {
        result = JSON.parse(result);
        console.log(result);
      },
    });
  };
}
