import Util from "../helpers/util";
var UtilObject = new Util();

var OTP;
export default class CustomerLogInPage {
  constructor() {
    //macville user login
    this.Phone_Number = $("#userPhoneNumberLogIn");
    this.Password = $("#userPasswordLogIn");
    this.submitUserLogIn = $("#submitUserLogIn");
    this.loginForm = $("#customerLoginForm");
    this.phnoError = $("#phnoError");
    this.passwordError = $("#passwordError");
    //this.submitPhoneUserLogIn = $(".submitPhoneUserLogIn");

    //steven login
    this.forgotPswdMobileNumberInput = $("#forgot-pswd-mobile-number-input");
    this.phoneNotRegisteredError = $("#phone-not-registered");
    this.sendOTPButton = $("#send-otp-button");
    this.OTPInput = $("#otp-input");
    this.saveAndProceedBtn = $("#save-and-proceed");
    this.saveNewPasswordBtn = $("#save-new-password-btn");
    this.saveNewPasswordInput = $("#save-new-password-input");

    this.successNewPassword = $("#success-New-Password");
    this.failureNewPassword = $("#failure-New-Password");
    this.modalTwo = $(".myModalsetpswd");
    //this.OTPError = $("#OTP-error");

    this.loginFailed = $("#loginFailed");
    this.verifyOTPBtn = $("#verify-otp-btn");
    this.OTPSuccess = $("#OTP-success");

    this.forgotPasswordHeader = $("#forgot-pswd-header");
    this.setPasswordHeader = $("#set-pswd-header");
    this.mobileLabel = $("#mobile-label");

    this.OTPLabel = $("#otp-label");
    this.newPasswordLabel = $("#new-password-label");

    //Registering Events
    this.bindEvents();
  }

  bindEvents = () => {
    this.submitUserLogIn.on("click", this.doFormAction);
    //proceed via phone number
    //this.submitPhoneUserLogIn.on("click", this.Submit_UserPhoneLogInForm);

    //
    $("#resend-otp-button").on("click", this.doPhoneNumberCheck);
    $("#resend-otp-button").on("click", () => {
      $("#OTP-success").addClass("text-success");
      $("#OTP-success").html("OTP Re-Sent");
      setTimeout(() => {
        $("#OTP-success").removeClass("text-success");
        $("#OTP-success").html("");
      }, 5000);
    });
    this.sendOTPButton.on("click", this.doPhoneNumberCheck);
    this.saveNewPasswordBtn.on("click", this.doSaveNewPassword);
    this.verifyOTPBtn.on("click", this.doVerifyOTP);
  };

  //user login with phone number and password
  Submit_UserLogInForm = () => {
    console.log("Inside submit flow");
    //code for submit form(api and ajax)

    $.ajax({
      type: "POST",
      url: baseURL + "api/customer/Account/login",
      async: false,
      data: {
        Phone_Number: this.Phone_Number,
        Password: this.Password
      },
      data: this.loginForm.serialize(),
      headers: { Authorization: X_API_KEY },
      success: this.Login_result,
      error: (err) => {
        console.log(err);
      }
    });
  };

  //login result
  Login_result = (res) => {
    console.log(res);
    if (res.Code == 2) {
      this.loginFailed.html("Incorrect Password or Phone number!");
    } else if (res.Code != 2) {
      this.loginFailed.html("");
      customerID = res.user.Customer_ID;
      this.setCustomerDetails();
      var allCookies = UtilObject.getAllCookies();
      console.log(allCookies);
      window.location = allCookies.PREVIOUS_PAGE;
    }
  };

  //Users wants to login/proceed via phone number
  // Submit_UserPhoneLogInForm = () => {
  //   //make check if phonr number exists
  //   $.ajax({
  //     type: "POST",
  //     url: baseURL + "api/customer/Account/logInPhone",
  //     data: {
  //       Phone_Number: this.Phone_Number,
  //     },
  //     data: this.loginForm.serialize(),

  //     headers: { Authorization: X_API_KEY },

  //     //success: this.Login_result,
  //   });
  // };

  //validtaions
  doVerifyOTP = () => {
    //e.preventDefault();
    console.log("in doVerifyOTP");
    let isOTPValidated = this.verifyOTPFunction();
    if (isOTPValidated) {
      this.OTPSuccess.addClass("text-success");
      this.OTPSuccess.removeClass("text-danger");
      //this.OTPSuccess.html("OTP is verified ");
      //this.OTPInput.prop("disabled", true);
      //this.verifyOTPBtn.addClass("d-none");
      this.saveAndProceedBtn.prop("disabled", false);
      this.forgotPasswordHeader.addClass("d-none");
      this.OTPLabel.addClass("d-none");
      this.OTPInput.addClass("d-none");
      this.verifyOTPBtn.addClass("d-none");
      this.OTPSuccess.addClass("d-none");
      this.setPasswordHeader.removeClass("d-none");
      this.newPasswordLabel.removeClass("d-none");
      this.saveNewPasswordInput.removeClass("d-none");
      this.saveNewPasswordBtn.removeClass("d-none");
    }
  };

  verifyOTPFunction = () => {
    console.log("inside verifyOTPFunction ");
    let flag = true;
    let OTPExample = /^\d{4}$/;
    if (!this.OTPInput.val().match(OTPExample)) {
      flag = false;
      this.OTPSuccess.addClass("text-danger");
      this.OTPSuccess.removeClass("text-success");
      this.OTPSuccess.html("Enter 4 digit number only ");
    } else {
      if (OTP == this.OTPInput.val()) {
        flag = true;
      } else {
        flag = false;
        this.OTPSuccess.addClass("text-danger");
        this.OTPSuccess.removeClass("text-success");
        this.OTPSuccess.html("Enter the Correct OTP");
      }
    }
    return flag;
  };

  //login form password and number validation
  doFormAction = (e) => {
    e.preventDefault();
    console.log("in doFormAction");
    let isPhValidated = this.Phno_validate();
    if (isPhValidated) {
      console.log(" ph VALID");
      this.phnoError.html("");
    }

    let isPasswordValidated = this.Password_validate();
    if (isPasswordValidated) {
      console.log(" pass VALID");
      this.passwordError.html("");
    } else {
      console.log("ERROR");
    }

    if (isPhValidated && isPasswordValidated) {
      this.Submit_UserLogInForm();
      console.log("BOTH FIELDS VALID...VALIDATION OVER");
    }
  };

  doPhoneNumberCheck = (e) => {
    e.preventDefault();
    console.log("inside dophonecheck"); //

    let isForgotPhValidated = this.Forgot_Phno_validate();
    if (isForgotPhValidated) {
      console.log(" forgot ph VALID");
      this.phoneNotRegisteredError.html("");

      let forgotPhoneNumber = this.forgotPswdMobileNumberInput.val();
      console.log(forgotPhoneNumber);
      $.ajax({
        type: "POST",
        url: baseURL + "api/customer/Account/SendOTPForgotPassword",
        async: false,
        headers: { Authorization: X_API_KEY },
        data: {
          Phone_Number: forgotPhoneNumber
        },
        success: (data) => {
          console.log(data);
          this.phoneNotRegisteredError.html("OTP Sent Succesfully");
          OTP = data.OTP;

          //removinf input for mobile number
          this.mobileLabel.addClass("d-none");
          this.forgotPswdMobileNumberInput.addClass("d-none");
          this.phoneNotRegisteredError.addClass("d-none");
          this.sendOTPButton.addClass("d-none");

          //adding input for otp
          this.OTPLabel.removeClass("d-none");
          this.OTPInput.removeClass("d-none");
          this.OTPSuccess.removeClass("d-none");
          this.verifyOTPBtn.removeClass("d-none");

          //showing resend OTP button
          $("#resend-otp-button").removeClass("d-none");
        },
        error: (err) => {
          console.log(err);
          var errMessage = err.responseJSON.Msg;
          this.phoneNotRegisteredError.html(errMessage);
        }
      });
      // $.ajax({
      //   type: "POST",
      //   url: baseURL + "api/ForgotPasswordApi/phoneNumber", //phoneNumber is the api
      //   data: {
      //     Phone_Number: forgotPhoneNumber, //Phone_Number column name
      //   },
      //   headers: { Authorization: X_API_KEY }, //not used

      //   success: this.checkPhone,
      // });
    } else {
      this.phoneNotRegisteredError.html("Enter a Valid Phone Number");
    }
  };

  Forgot_Phno_validate = () => {
    console.log("in forgot ph validate");
    let flag = true;
    var forgotphoneno = /^\d{10}$/;
    if (!this.forgotPswdMobileNumberInput.val().match(forgotphoneno)) {
      //this.phoneNotRegisteredError.addClass("d-none");

      flag = false;
    }

    return flag;
  };

  doSaveNewPassword = () => {
    let newPassword = this.saveNewPasswordInput.val();
    let newPassValidation = this.passwordvalidate1();

    if (newPassValidation) {
      let forgotPhoneNumber = this.forgotPswdMobileNumberInput.val();
      $.ajax({
        type: "POST",
        url: baseURL + "api/customer/Account/setNewPasswordOnForgotPassword",
        async: false,
        data: {
          Password: newPassword, //Password column name
          Phone_Number: forgotPhoneNumber
        },

        headers: { Authorization: X_API_KEY },

        success: (res) => {
          console.log(res);
          this.successNewPassword.addClass("text-success");
          this.successNewPassword.removeClass("text-danger");

          this.successNewPassword.html(
            "Your password has been successfully updated"
          );
          this.saveNewPasswordBtn.addClass("d-none");
          this.saveNewPasswordInput.prop("disabled", true);

          customerID = res.user.Customer_ID;
          this.setCustomerDetails();

          setTimeout(() => {
            window.location = baseURL + "customer";
          }, 1500);
        }
      });

      //this.saveNewPasswordBtn.prop("disabled", true);

      //this.modalTwo.addClass("d-none");
      //this.modalTwo.hide();
      //this.modalTwo.close();

      //let OTPNum = this.OTPInput.val();
    }
  };

  //password validation
  passwordvalidate1 = () => {
    console.log("in Password validate");
    let flag = true;
    // var PasswordExample =
    //   /^(?=.\d)(?=.[!@#$%^&])(?=.[a-z])(?=.*[A-Z]).{6,}$/;
    if (
      !(
        this.saveNewPasswordInput.val().length >= 6 &&
        this.saveNewPasswordInput.val().length <= 15
      )
      // ||
      // !this.saveNewPasswordInput.val().match(PasswordExample)
    ) {
      this.successNewPassword.addClass("text-danger");
      this.successNewPassword.removeClass("text-success");
      this.successNewPassword.html("Please use min 6 and max 15 characters");

      flag = false;
    }
    return flag;
  };

  checkPhone = (res) => {
    console.log("inside checkphone");
    let vendorDetails = res.length;
    console.log(res);
    console.log(vendorDetails);

    if (vendorDetails == 0) {
      //alert("This phone number is not registered");
      this.phoneNotRegisteredError.html("This phone number is not registered");
    } else if (vendorDetails == 1) {
      this.phoneNotRegisteredError.addClass("d-none");
      //this.OTPInput.prop("disabled", false);
      this.verifyOTPBtn.prop("disabled", false);

      //this.forgotPswdMobileNumberInput.prop("disabled", true);
      //this.sendOTPButton.prop("disabled", true);

      this.mobileLabel.addClass("d-none");
      this.forgotPswdMobileNumberInput.addClass("d-none");
      this.sendOTPButton.addClass("d-none");

      this.OTPLabel.removeClass("d-none");
      this.OTPInput.removeClass("d-none");
      this.verifyOTPBtn.removeClass("d-none");

      //alert('This phone number is registered');

      // $(document).ready(function(){
      //     sendOTPButton.click(function(){
      //       modalTwo.modal();
      //     });
      //   });
    }
  };

  //phone validation login
  Phno_validate = () => {
    console.log("in ph validate");
    let flag = true;
    var phoneno = /^\d{10}$/;
    if (!this.Phone_Number.val().match(phoneno)) {
      this.phnoError.html("Please enter only 10 digits for mobile number!");

      flag = false;
    }

    return flag;
  };

  //password validation login
  Password_validate = () => {
    console.log("in pass validate");
    let flag = true;

    if (
      !(this.Password.val().length >= 6 && this.Password.val().length <= 15)
    ) {
      this.passwordError.html("Password must be min 6 and max 15 characters");

      flag = false;
    }

    return flag;
  };

  setCustomerDetails = () => {
    console.log(customerID);

    //storing it in cookies - for JS
    UtilObject.createCookie("CUSTOMER_ID", customerID, 30);

    //Storing it in PHP
    $.ajax({
      url: baseURL + "api/customer/CustomerCookies/setSessionId",
      type: "POST",
      async: false,
      data: {
        data: {
          CUSTOMER_ID: customerID
        }
      },

      success: (result) => {
        console.log(result);
        console.log(UtilObject.getAllCookies());
      },
      error: (err) => {
        console.log(err);
      }
    });
  };
}
