//import '@googlemaps/js-api-loader';
// import "google-maps";

import Cropper from "cropperjs";

export default class customer_image_cropper {
  constructor() {
    // Registering Events
    this.bindEvents();
    //this.geocodePosition();
    console.log("inside cropper");
  }

  bindEvents = () => {
    this.doCustomerCroppingAction();
    console.log("Image cropper js file loaded");
  };

  doCustomerCroppingAction = () => {
    var bs_modal = $("#modalCropperCustomer");
    var image = document.getElementById("image");
    var reader, file;
    var imagePreviewBoxId;

    // $("body").on("change", ".imageCropInput", (e) => {
    $(".imageCropInputCustomer").on("change", (e) => {
      console.log(e);
      imagePreviewBoxId = e.target.defaultValue;
      console.log(e.target.files);
      console.log(e.target.files.length);
      if (e.target.files.length == 0) {
        return;
      }

      //Check if the file is image fill. if not then stop executing the function
      var imgExpression = /.*\.(jpg|jpeg|png|JPG|JPEG|PNG)$/;
      if (!e.target.files[0]["name"].match(imgExpression)) {
        console.log("File to be cropped is not an image");
        // $(".profileImageErrorCustomer").html(`File uploaded is not a image`);
        return;
      }

      var files = e.target.files;
      var done = (url) => {
        image.src = url;
        window.$("#modalCropperCustomer").modal("show");
      };

      if (files && files.length > 0) {
        file = files[0];
        console.log(file);
        if (file === undefined) {
          console.log("undefined file is tried to crop");
        }

        if (URL) {
          done(URL.createObjectURL(file));
        } else if (FileReader) {
          reader = new FileReader();
          reader.onload = (e) => {
            done(reader.result);
          };
          reader.readAsDataURL(file);
        }
      }
      //bs_modal.on("show",....)
      console.log("Displaying Content in cropper modal");
      $(".profileImageErrorCustomer").html(``);
      cropperC = new Cropper(image, {
        aspectRatio: 1,
        viewMode: 2,
        preview: $(".CropPreviewCustomer"),
        center: true,
      });
    });

    $("#cropSubmitbtnCustomer").on("click", () => {
      if (!imagePreviewBoxId) {
        console.log("No imagePreviewBoxId detected");
        cropperC.destroy();
        // window.bs_modal.modal("hide");
        window.$("#modalCropperCustomer").modal("hide");

        return;
      }

      console.log(imagePreviewBoxId.length);
      if (imagePreviewBoxId.length > 2) {
        console.log("Performing crop button");
        console.log(cropperC);
        var canvas = cropperC.getCroppedCanvas({
          width: 426,
          height: 426,
        });
        console.log(canvas);

        canvas.toBlob((blob) => {
          var url = URL.createObjectURL(blob);
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            var base64data = reader.result;
            console.log(base64data);

            // $.ajax({
            //   type: "POST",
            //   url: baseURL + "api/Vendor/UploadVendorImage",
            //   data: {
            //     image: base64data,
            //   },
            //   success: (data) => {
            //     bs_modal.hide();
            //     // alert("success upload image");
            //     console.log(data);
            //     $.ajax({
            //       type: "POST",
            //       url: baseURL + "Vendor_details/upload",
            //       async: false,
            //       headers: { Authorization: X_API_KEY },
            //       data: { image: data },
            //       statusCode: {
            //         400: function () {
            //           alert("400 status code! user error");
            //         },
            //         500: function () {
            //           alert("500 status code! server error");
            //         },
            //       },
            //     });
            //   },
            // });
            cropperC.destroy();
            // bs_modal.hide();
            // window.bs_modal.modal("hide");
            window.$("#modalCropperCustomer").modal("hide");
            // comment if above api is used
            // cropper = null;
            console.log(imagePreviewBoxId);
            document.getElementById(imagePreviewBoxId).src = base64data;
            document.getElementById(imagePreviewBoxId).value = 1;
            imagePreviewBoxId = "";
            // $(".image-preview-three").setAttribute("src", "base64data");
          };
        });
      }
    });
  };
}
