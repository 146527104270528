import Util from "../helpers/util";

var UtilObject = new Util();

export default class admin_control {
  constructor() {
    var id = $(".Admin_Control_Id")[0].value;
    var side = $(".Admin_Control_Side")[0].value;

    if (side == "customer") {
      this.setCustomerDetails(id);
    } else {
      this.setVendorDetails(id);
    }
  }

  setCustomerDetails = (customerID) => {
    console.log(customerID);

    //storing it in cookies - for JS
    UtilObject.createCookie("CUSTOMER_ID", customerID, 30);

    //Storing it in PHP
    $.ajax({
      url: baseURL + "api/customer/CustomerCookies/setSessionId",
      type: "POST",
      async: false,
      data: {
        data: {
          CUSTOMER_ID: customerID
        }
      },
      success: (result) => {
        console.log(result);
        console.log(UtilObject.getAllCookies());
      },
      error: (err) => {
        console.log(err);
      }
    });
  };

  setVendorDetails = (vendorID) => {
    console.log(vendorID);

    //storing it in cookies - for JS
    UtilObject.createCookie("Vendor_ID", vendorID, 30);

    //Storing it in PHP
    $.ajax({
      url: baseURL + "api/customer/CustomerCookies/setSessionId",
      type: "POST",
      async: false,
      data: {
        data: {
          Vendor_ID: vendorID
        }
      },
      success: (result) => {
        console.log(result);
        console.log(UtilObject.getAllCookies());
        window.location.replace(baseURL + "Vendor_servicing_dashboard");
      },
      error: (err) => {
        console.log(err);
      }
    });
  };
}
