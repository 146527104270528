var map, marker, returnData;

export default class GoogleMapsApi {
  initModal = async (id) => {
    //this.autoDetectModal.show();

    // var lat1 = marker.latLng.lat();
    // var lng1 = marker.latLng.lng();

    // console.log(lat1);
    // console.log(lng1);

    var location = new google.maps.LatLng(0, 0);
    console.log(location);

    var mapProperty = {
      center: location,
      zoom: 18,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    console.log(mapProperty);

    console.log(id);

    var map = new google.maps.Map(
      document.getElementById(`${id}`),
      mapProperty
    );

    //Places API starts
    var places;
    var searchBox = new google.maps.places.SearchBox(
      document.getElementById(`${id}-input`)
    );
    // map.controls[google.maps.ControlPosition.TOP_CENTER].push(
    //   document.getElementById("pac-input")
    // );

    console.log(searchBox);

    google.maps.event.addListener(searchBox, "places_changed", () => {
      searchBox.set("map", null);

      places = searchBox.getPlaces();
      console.log(places);
      console.log(places[0].geometry.location);
      console.log(places[0].geometry.location.lat());
      console.log(places[0].geometry.location.lng());

      marker.setMap(null);
      marker = new google.maps.Marker({
        //position: { lat: 45245, lng: 452435 },
        map: map,
        draggable: true,
        animation: google.maps.Animation.DROP,
        position: places[0].geometry.location,
      });

      console.log(marker.getPosition());

      this.geocodePosition(marker.getPosition());

      google.maps.event.addListener(marker, "dragend", () => {
        map.setCenter(marker.getPosition());
        this.geocodePosition(marker.getPosition());
        // $("#latitude").val(marker.getPosition().lat());
        // $("#longitude").val(marker.getPosition().lng());
      });

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          let pos = {
            lat: places[0].geometry.location.lat(),
            lng: places[0].geometry.location.lng(),
          };

          // $("#inputLat").val(pos.lat);
          // $("#inputlong").val(pos.lng);

          //console.log(marker);

          marker.setPosition(pos);

          console.log("geolocation");
          console.log(pos);
          console.log(pos.lat);

          map.setCenter(marker.getPosition());
          this.geocodePosition(marker.getPosition());
        });
      }
    });

    //Places API ends

    console.log(location);
    marker = new google.maps.Marker({
      //position: { lat: 45245, lng: 452435 },
      map: map,
      draggable: true,
      animation: google.maps.Animation.DROP,
      position: location,
    });
    marker.setMap(map);

    console.log(marker);
    console.log(marker.getPosition());

    this.geocodePosition(marker.getPosition());

    google.maps.event.addListener(marker, "dragend", () => {
      map.setCenter(marker.getPosition());
      this.geocodePosition(marker.getPosition());
    });

    returnData = await this.navigatorGeolocation(map);
    return returnData;
  };

  editAddress = async (id, lat, lon) => {
    console.log(lat);
    console.log(lon);
    var location = new google.maps.LatLng(lat, lon);
    console.log(location);
    console.log("INSIDE EDIT GOOGLE");

    var mapProperty = {
      center: location,
      zoom: 18,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    console.log(mapProperty);

    console.log(id);

    var map = new google.maps.Map(
      document.getElementById(`${id}`),
      mapProperty
    );

    //Places API starts
    var places;
    var searchBox = new google.maps.places.SearchBox(
      document.getElementById(`${id}-input`)
    );
    // map.controls[google.maps.ControlPosition.TOP_CENTER].push(
    //   document.getElementById("pac-input")
    // );

    console.log(searchBox);

    google.maps.event.addListener(searchBox, "places_changed", () => {
      searchBox.set("map", null);

      places = searchBox.getPlaces();
      console.log(places);
      console.log(places[0].geometry.location);
      console.log(places[0].geometry.location.lat());
      console.log(places[0].geometry.location.lng());

      marker.setMap(null);
      marker = new google.maps.Marker({
        //position: { lat: 45245, lng: 452435 },
        map: map,
        draggable: true,
        animation: google.maps.Animation.DROP,
        position: places[0].geometry.location,
      });

      console.log(marker.getPosition());

      //this.geocodePosition(marker.getPosition());

      google.maps.event.addListener(marker, "dragend", () => {
        map.setCenter(marker.getPosition());
        this.geocodePosition(marker.getPosition());
        // $("#latitude").val(marker.getPosition().lat());
        // $("#longitude").val(marker.getPosition().lng());
      });

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          let pos = {
            lat: places[0].geometry.location.lat(),
            lng: places[0].geometry.location.lng(),
          };

          // $("#inputLat").val(pos.lat);
          // $("#inputlong").val(pos.lng);

          //console.log(marker);

          marker.setPosition(pos);

          console.log("geolocation");
          console.log(pos);
          console.log(pos.lat);

          map.setCenter(marker.getPosition());
          this.geocodePosition(marker.getPosition());
        });
      }
    });

    //Places API ends

    console.log(location);
    marker = new google.maps.Marker({
      //position: { lat: 45245, lng: 452435 },
      map: map,
      draggable: true,
      animation: google.maps.Animation.DROP,
      position: location,
    });
    marker.setMap(map);

    console.log(marker);
    console.log(marker.getPosition());

    this.geocodePosition(marker.getPosition());

    google.maps.event.addListener(marker, "dragend", () => {
      map.setCenter(marker.getPosition());
      this.geocodePosition(marker.getPosition());
    });

    // returnData = await this.navigatorGeolocation(map);
    // return returnData;
  };

  navigatorGeolocation = (map) => {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        var markerPosition;
        navigator.geolocation.getCurrentPosition(async (position) => {
          let pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // $("#latitude").val(pos.lat);
          // $("#longitude").val(pos.lng);

          //console.log(marker);

          marker.setPosition(pos);

          console.log("geolocation");
          console.log(pos);

          markerPosition = marker.getPosition();
          console.log(markerPosition);

          map.setCenter(marker.getPosition());
          resolve(await this.geocodePosition(marker.getPosition()));
        });
      }
    });
  };

  geocodePosition = (pos) => {
    console.log(pos);
    return new Promise((resolve, reject) => {
      var addressDetails = { ...pos };
      console.log(pos);
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          latLng: pos,
        },
        (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            console.log(results);
            results.forEach((element) => {
              if (
                element.geometry.location_type == "ROOFTOP" &&
                !Object.hasOwn(addressDetails, "ROOFTOP")
              ) {
                addressDetails.ROOFTOP = element;
              }

              if (
                element.geometry.location_type == "GEOMETRIC_CENTER" &&
                !Object.hasOwn(addressDetails, "GEOMETRIC_CENTER")
              ) {
                addressDetails.GEOMETRIC_CENTER = element;
              }

              if (
                element.geometry.location_type == "APPROXIMATE" &&
                !Object.hasOwn(addressDetails, "APPROXIMATE")
              ) {
                addressDetails.APPROXIMATE = element;
              }
            });
            addressDetails.msgCode = 1;
            addressDetails.msgStatus = "SUCCESS";
            console.log(addressDetails);

            //Assigning of details to autofill
            if (Object.hasOwn(addressDetails, "ROOFTOP")) {
              var roofTopAddressDetails =
                addressDetails.ROOFTOP.address_components;
              var roofTopAddress = addressDetails.ROOFTOP;
              console.log(roofTopAddressDetails);
            } else if (Object.hasOwn(addressDetails, "GEOMETRIC_CENTER")) {
              var roofTopAddressDetails =
                addressDetails.GEOMETRIC_CENTER.address_components;
              var roofTopAddress = addressDetails.GEOMETRIC_CENTER;
              console.log(roofTopAddressDetails);
            } else {
              var roofTopAddressDetails =
                addressDetails.APPROXIMATE.address_components;
              var roofTopAddress = addressDetails.APPROXIMATE;
              console.log(roofTopAddressDetails);
            }

            //Adding Data to the input fields
            $(".inputAddress").val(roofTopAddress.formatted_address);
            $(".inputLat").val(pos.lat);
            $(".inputLon").val(pos.lng);

            roofTopAddressDetails.forEach((item) => {
              if (item.types.includes("plus_code")) {
                $(".inputAddressshort").val(item.long_name);
              }

              if (item.types.includes("locality")) {
                $(".inputCity").val(item.long_name);
              }

              if (item.types.includes("administrative_area_level_1")) {
                $(".inputState").val(item.long_name);
              }

              if (item.types.includes("postal_code")) {
                $(".inputPincode").val(item.long_name);
              }
            });

            //return addressDetails;
            resolve(addressDetails);
          } else {
            addressDetails.msgCode = 0;
            addressDetails.msgStatus = "FAILURE";
            //return addressDetails;
            reject(addressDetails);
          }
        }
      );
    });
  };

  navigatorGeolocationForCurrentLocation = () => {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            let pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            // $("#latitude").val(pos.lat);
            // $("#longitude").val(pos.lng);

            //console.log(marker);

            console.log(pos);

            //markerPosition = marker.getPosition();
            resolve(await this.geocodeCurrentPosition(pos));
          },
          (error) => {
            resolve({
              lat: 15.494295,
              lng: 73.827209,
              msgCode: 0,
              msgStatus: "FAIL",
            });
          }
        );
      }
    });
  };

  geocodeCurrentPosition = (pos) => {
    console.log(pos);
    return new Promise((resolve, reject) => {
      var addressDetails = { ...pos };
      console.log(pos);
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          latLng: pos,
        },
        function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            console.log(results);
            results.forEach((element) => {
              if (
                element.geometry.location_type == "ROOFTOP" &&
                !Object.hasOwn(addressDetails, "ROOFTOP")
              ) {
                addressDetails.ROOFTOP = element;
              }

              if (
                element.geometry.location_type == "GEOMETRIC_CENTER" &&
                !Object.hasOwn(addressDetails, "GEOMETRIC_CENTER")
              ) {
                addressDetails.GEOMETRIC_CENTER = element;
              }

              if (
                element.geometry.location_type == "APPROXIMATE" &&
                !Object.hasOwn(addressDetails, "APPROXIMATE")
              ) {
                addressDetails.APPROXIMATE = element;
              }
            });
            addressDetails.msgCode = 1;
            addressDetails.msgStatus = "SUCCESS";
            console.log(addressDetails);

            //Assigning of details to autofill
            if (Object.hasOwn(addressDetails, "ROOFTOP")) {
              var roofTopAddressDetails =
                addressDetails.ROOFTOP.address_components;
              console.log(roofTopAddressDetails);
            } else if (Object.hasOwn(addressDetails, "GEOMETRIC_CENTER")) {
              var roofTopAddressDetails =
                addressDetails.GEOMETRIC_CENTER.address_components;
              console.log(roofTopAddressDetails);
            } else {
              var roofTopAddressDetails =
                addressDetails.APPROXIMATE.address_components;
              console.log(roofTopAddressDetails);
            }

            //return addressDetails;
            resolve(addressDetails);
          } else {
            addressDetails.msgCode = 0;
            addressDetails.msgStatus = "FAILURE";
            //return addressDetails;
            reject(addressDetails);
          }
        }
      );
    });
  };

  geocodeFindPosition = (latitude, longitude) => {
    var findPostiton = {
      lat: latitude,
      lng: longitude,
    };
    return new Promise((resolve, reject) => {
      var addressDetails = { ...findPostiton };
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          latLng: findPostiton,
        },
        function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            results.forEach((element) => {
              if (
                element.geometry.location_type == "ROOFTOP" &&
                !Object.hasOwn(addressDetails, "ROOFTOP")
              ) {
                addressDetails.ROOFTOP = element;
              }

              if (
                element.geometry.location_type == "GEOMETRIC_CENTER" &&
                !Object.hasOwn(addressDetails, "GEOMETRIC_CENTER")
              ) {
                addressDetails.GEOMETRIC_CENTER = element;
              }

              if (
                element.geometry.location_type == "APPROXIMATE" &&
                !Object.hasOwn(addressDetails, "APPROXIMATE")
              ) {
                addressDetails.APPROXIMATE = element;
              }
            });
            addressDetails.msgCode = 1;
            addressDetails.msgStatus = "SUCCESS";
            console.log(addressDetails);

            //Assigning of details to autofill
            if (Object.hasOwn(addressDetails, "ROOFTOP")) {
              var roofTopAddressDetails =
                addressDetails.ROOFTOP.address_components;
              console.log(roofTopAddressDetails);
            } else if (Object.hasOwn(addressDetails, "GEOMETRIC_CENTER")) {
              var roofTopAddressDetails =
                addressDetails.GEOMETRIC_CENTER.address_components;
              console.log(roofTopAddressDetails);
            } else {
              var roofTopAddressDetails =
                addressDetails.APPROXIMATE.address_components;
              console.log(roofTopAddressDetails);
            }

            //return addressDetails;
            resolve(addressDetails);
          } else {
            addressDetails.msgCode = 0;
            addressDetails.msgStatus = "FAILURE";
            //return addressDetails;
            reject(addressDetails);
          }
        }
      );
    });
  };


  initMarketMap = async (mapDivID, vendorData) => {
    // The location of Uluru
    const positionGoa = { lat: 15.2993, lng: 74.1240 };
    // Request needed libraries.
    //@ts-ignore
    const { Map } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

    // The map, centered at Goa
    let marketMap = new Map(document.getElementById(`${mapDivID}`), {
      zoom: 9,
      center: positionGoa,
      mapId: "Market_MAP_ID",
    });
    let vendorsMarkersArray = [{ lat: 15.2993, lng: 74.1240, title: "Test Vendor 1" }, { lat: 15.1993, lng: 74.0240, title: "Test Vendor 2" }];

    vendorsMarkersArray = vendorData ? vendorData : vendorsMarkersArray;

    // Create the markers.
    vendorsMarkersArray.forEach(({ title, lat, lng }, i) => {
      lat = parseFloat(lat);
      lng = parseFloat(lng);

      const pin = new PinElement({
        // glyph: `${i + 1}`,
      });
      const markerAdv = new AdvancedMarkerElement({
        position: { lat, lng },
        map: marketMap,
        title: `${title}`,
        content: pin.element,
      });
      marketMap.setCenter({ lat, lng });
      if (vendorData.length == 1) {
        marketMap.setZoom(12);
      } else {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            marketMap.setCenter({ lat, lng });
            marketMap.setZoom(12);
          });
        }
      }
    });

  }

  getVendorMarketMap = (mapID, vendor_ID, vendor_Data) => {
    if (!mapID) { return false }
    if (vendor_Data) {
      this.initMarketMap(mapID, vendor_Data);
    } else {
      $.ajax({
        url: baseURL + "api/customer/Homepage/getVendorMarker",
        type: "GET",
        async: false,
        headers: { Authorization: X_API_KEY },
        data: { vendor_ID: vendor_ID, },
        success: (result) => {
          this.initMarketMap(mapID, result);
        },
        error: (result) => {
          console.error(result);
        },
      });
    }

  };

  openGMapModal = (e, categtryVendorsData) => {
    e.preventDefault();
    var vendor_ID = e.currentTarget.dataset.vendorid;
    var vendor_Data = e.currentTarget.dataset.vendorsdata;
    if (vendor_Data) {
      vendor_Data = vendor_Data.split(", ");
      // Convert string to JSON object array
      vendor_Data = JSON.parse("[" + vendor_Data + "]");
    }
    if (!vendor_ID && !vendor_Data) { return; }

    window.$("#vendorLocationMapModal").modal("show");
    this.getVendorMarketMap("marketMapContainer", vendor_ID, vendor_Data);
    $("#vendorLocationMapModalCloseBtn").on("click", () => {
      window.$("#vendorLocationMapModal").modal("hide");
    });
  };

}
