import "owl.carousel";
var vendorProducts;
var vendor;
var CartDetails = {};
var vendorFromOrder;
var flagForVendorCheck = false;
var pID;

import s3Util from "../helpers/s3Util";
var s3Object = new s3Util();

export default class productListing {
  constructor() {
    //calculating width and putting the appropriate code
    var screenWidth = $(window).width();
    console.log(screenWidth);
    if (screenWidth < 426) {
      // $(".displayDuringDesktopView").addClass("d-none");
      $(".displayDuringMobViews").removeClass("d-none");
    } else {
      // $(".displayDuringMobView").addClass("d-none");
      $(".displayDuringDesktopViews").removeClass("d-none");
    }
    if (screenWidth > 821) {
      // $(".displayDuringDesktopView").addClass("d-none");
      $(".FOOTER-VENDOR").removeClass("d-none");
    } else {
      // $(".displayDuringMobView").addClass("d-none");
      $(".FOOTER-VENDOR").addClass("d-none");
    }

    //ProductListingPage Part

    //desktop view class
    this.displayDuringDesktopView = $(".displayDuringDesktopView");

    //mobile view class
    this.displayDuringMobView = $(".displayDuringMobView");

    //add
    // this.addBtnForItem1 = $("#addBtnForItem1");

    //+
    // this.plusBtnId = $("#plusBtnId");

    //-
    // this.minusBtnId = $("#minusBtnId");

    //cartEmpty
    this.cartEmpty = $("#cartEmpty");

    //ItemsInCart
    this.ItemsAddedInCart = $("#ItemsAddedInCart");

    //text-field
    this.qntyTextField = $(".qntyTextField");

    //max error msg
    // this.maxErrorMsg = $("#maxErrorMsg");

    //Vendor_ID
    this.Vendor_ID = $(".VendorSummaryPage").attr("class").split(" ")[1];

    //div for description
    this.writtenPart = $(".writtenPart");

    //for integration
    this.grid = $(".ProductListingPage");

    //Registering Events
    this.bindEvents();
  }

  bindEvents = () => {
    //to adjust content according to different screen sizes
    var w = $(window).width();
    console.log(w);

    if (w < 710) {
      this.writtenPart.removeClass("col");
    } else {
      this.writtenPart.addClass("col");
    }

    // this.addToCartBtn(this.addItemInCart)
    // this.addItemInCart();
    // this.addBtnForItem1.on("click", this.addItemInCart);
    // this.minusBtnId.on("click", this.minusItemFunction);
    // this.plusBtnId.on("click", this.plusItemFunction);

    //Calling vendor details on load
    //$(window).on("load", $.ready.then(this.getVendorDetails));
    $(window).on("load", $.ready.then(this.calOwlCarousel));
    $(window).on(
      "load",
      $.ready.then(this.getVendorDetailsWithMultipleCategories)
    );
    $(window).on("load", $.ready.then(this.getVendorImages));
    //calling commomon buttons
    // this.addToCartBtn.on("click", this.addItemInCart);
  };

  calOwlCarousel = () => {
    console.log("inside");
    $(".carousel_se_07_carousel").owlCarousel({
      nav: false,
      dots: false,
      mouseDrag: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 3,
        },
        480: {
          items: 3,
        },
        767: {
          items: 5,
        },
        992: {
          items: 6,
        },
        1200: {
          items: 8,
        },
      },
    });
  };

  showVendorDetails = (res) => {
    console.log(res);

    $(".vendorName").html(`${res.vendor.Business_Name}`);

    vendorProducts = res.products;
    vendor = res;

    //for delivery option
    if (res.delivery.length > 0) {
      var deliveryName = res.delivery[0].Picklist_display_value;

      //if kms is less than 1 hide kms
      if (res.delivery[0].Distance_kms > 0) {
        var distanceKms = res.delivery[0].Distance_kms + " kms";
      } else {
        var distanceKms = "";
      }
      //if Price is less than 1 hide price
      if (res.delivery[0].Price > 0) {
        var deliveryPrice = "₹" + res.delivery[0].Price;
      } else {
        var deliveryPrice = "";
      }

      $(".deliveryOptionBtn").show();
    }

    //for vendor review stars
    let vendorRating = res.vendor.Vendor_Rating;
    vendorRating = Math.round(vendorRating * 2) / 2;
    let vendorOutput = [];

    // Append all the filled whole stars
    for (var stars = vendorRating; stars >= 1; stars--)
      vendorOutput.push(
        `<i class="fa fa-star" aria-hidden="true" style="color: white;"></i>`
      );
    // If there is a half a star, append it
    if (stars == 0.5)
      vendorOutput.push(
        `<i class="fa fa-star-half-o" aria-hidden="true" style="color: white;"></i>`
      );
    // Fill the empty stars
    for (var stars = 5 - vendorRating; stars >= 1; stars--)
      vendorOutput.push(
        `<i class="fa fa-star-o" aria-hidden="true" style="color: white;"></i>`
      );

    vendorOutput = vendorOutput.join("");
    console.log(vendorOutput);

    //New Integration code
    $(".vendorBusinessName").html(res.vendor.Business_Name);
    $(".vendorShortAddress").html(
      `${res.vendor.Address.Address_Line_1 + ", " + res.vendor.Address.State}`
    );
    $(".vendorDescription").html(res.vendor.Specialities);
    $(".vendorRating").html(
      `<i class="fa-regular fa-star"  style="color: white;"></i><i class="fa-regular fa-star"  style="color: white;"></i><i class="fa-regular fa-star"  style="color: white;"></i><i class="fa-regular fa-star"  style="color: white;"></i><i class="fa-regular fa-star"  style="color: white;"></i>`
    );
    $(".vendorMinOrder").html(res.vendor.Minimum_Order);
    $(".vendorDeliveryOptions").html(
      `${deliveryName} ${distanceKms} ${deliveryPrice}`
    );
    $(".vendorRatingMobile").html(``);

    //vendorimages
    var businessImageURL =
      "Vendor/" +
      res.vendor.Image_URL +
      "/Document Images/Business Image/1.png";
    // businessImageURL = "Test/no image.jpg"

    // s3Object.getS3Image(productImageURL, productImageTagID);

    // var vendorImg = await s3Object.getObjectURL(businessImageURL);

    var businessImageTagClassName = "";
    if (res.vendorImages.length == 1) {
      // $(".vendorMainImage").attr(
      //   "src",
      //   `${vendorImg}`
      // );
      // $(".vendorSecondImage").attr(
      //   "src",
      //   `${vendorImg}`
      // );
      // $(".vendorThirdImage").attr(
      //   "src",
      //   `${vendorImg}`
      // );
      $(".imagesCount").hide();
      businessImageTagClassName = "vendorMainImage";
      s3Object.getS3Image(businessImageURL, null, businessImageTagClassName);
      businessImageTagClassName = "vendorSecondImage";
      s3Object.getS3Image(businessImageURL, null, businessImageTagClassName);
      businessImageTagClassName = "vendorThirdImage";
      s3Object.getS3Image(businessImageURL, null, businessImageTagClassName);
    } else if (res.vendorImages.length == 2) {
      // $(".vendorMainImage").attr(
      //   "src",
      //   `${vendorImg}`
      // );
      // $(".vendorSecondImage").attr(
      //   "src",
      //   `${vendorImg}`
      // );
      // $(".vendorThirdImage").attr(
      //   "src",
      //   `${vendorImg}`
      // );
      $(".imagesCount").hide();
      businessImageTagClassName = "vendorMainImage";
      s3Object.getS3Image(businessImageURL, null, businessImageTagClassName);
      businessImageTagClassName = "vendorSecondImage";
      s3Object.getS3Image(businessImageURL, null, businessImageTagClassName);
      businessImageTagClassName = "vendorThirdImage";
      s3Object.getS3Image(businessImageURL, null, businessImageTagClassName);
    } else {
      if (res.vendorImages.length == 3) {
        $(".imagesCount").hide();
      } else {
        // $(".vendorMainImage").attr(
        //   "src",
        //   `${vendorImg}`
        // );
        // $(".vendorSecondImage").attr(
        //   "src",
        //   `${vendorImg}`
        // );
        // $(".vendorThirdImage").attr(
        //   "src",
        //   `${vendorImg}`
        // );
        //image count
        $(".imagesCount").html(`+${res.vendorImages.length - 3}`);
        businessImageTagClassName = "vendorMainImage";
        s3Object.getS3Image(businessImageURL, null, businessImageTagClassName);
        businessImageTagClassName = "vendorSecondImage";
        s3Object.getS3Image(businessImageURL, null, businessImageTagClassName);
        businessImageTagClassName = "vendorThirdImage";
        s3Object.getS3Image(businessImageURL, null, businessImageTagClassName);
      }
    }

    //for vendor image carousel
    //for first active image
    var activeitem = `<div class="carousel-item active ">
            <img id="carouselActiveItemImage" src="" class="d-block " alt="" width="100%">
            </div>`;

    $(".vendorImageCarouselActiveItem").append(activeitem);
    var carouselActiveItemImageID = "carouselActiveItemImage";
    s3Object.getS3Image(businessImageURL, carouselActiveItemImageID);

    //for remaining images
    res.vendorImages.forEach((res) => {
      console.log(res);
      var item = `
            <div class="carousel-item">
                <img src="${S3BaseURL}${res.Image_url}" class="d-block " alt="" width="100%">
            </div>`;
      $(".vendorImageCarousel").append(item);
    });

    var vendorCategories = res.categories;
    let i = 0;
    let status;
    let selecteditem;

    let finalCategoryMenu, menuStart, recommendedSection, menuSection, menuEnd;
    let itemListRecommended = [];
    let itemListMenu = [];
    var firstActiveItem;

    //hide categories if there is only 1
    if (vendorCategories.length == 1) {
      $(".categories-product-listing").hide();
    }

    vendorCategories.forEach((category) => {
      //Making them Empty
      itemListRecommended = [];
      itemListMenu = [];
      firstActiveItem = "";
      selecteditem = "";

      console.log(category);

      if (i < 1) {
        //status = "checked";
        status = "active";
        firstActiveItem = "currentActiveCategory";
        selecteditem = "selected-item";
      }
      // var categoryItem = `
      //   <input type="radio" class="btn-check" name="btnradio" autocomplete="off" ${status}>
      //   <label class="btn btn-outline-warning" id="${category.Picklist_ID}" for="btnradio1">${category.Picklist_display_value}</label>`;

      //     var categoryItem = ` <li class="nav-item item">
      //     <a id="${category.Picklist_ID}" class="nav-link ${status} ${firstActiveItem}" aria-current="page" data-bs-toggle="button" autocomplete="off" aria-pressed="true" style="font-size:12px;">${category.Picklist_display_value}</a>
      // </li>`;

      //     //$(".vendorCategoriesButtons").append(categoryItem);

      //     $(".owl-carousel.testCase")
      //       .owlCarousel("add", categoryItem)
      //       .owlCarousel("update");
      var categoryImageTagID = category.Picklist_ID + "image";
      var categoryImgURL = category.Icon_Url;

      var categoryItem = `<div class="item" >
        <div class="col-md-12 wow fadeInUp delay-2 d-flex justify-content-center">
          <div id="${category.Picklist_ID}" class="vendorCategoriesButtons ${selecteditem} text-center zoom ${status} ${firstActiveItem}">
            <a class="btn-light rounded-circle imageSizeResponsiveness" rel="noopener noreferrer" style="margin-top:0.5rem;">
              <img id="${categoryImageTagID}" class="img-fluid  rounded-circle" alt="${category.Picklist_display_value}-icon" onerror="this.src='assets/src/images/pancake.jpeg'" loading="lazy">
            </a>
            <p class="truncate picklistValueSizeResponsiveness">${category.Picklist_display_value}</p>
          </div>
        </div>
      </div>`;

      $(".owl-carousel.vendor-categories-item")
        .owlCarousel("add", categoryItem)
        .owlCarousel("update");

      s3Object.getS3Image(categoryImgURL, categoryImageTagID);

      //Entire Menu Section
      menuStart = `<!--Menu Start for Category--> <span class="vendor-category-products ${category.Picklist_ID}">`;

      res.products.forEach((product) => {
        console.log(product);
        //var button = `<button class="btn btn-xl text-white px-5 addToCartBtn ${product.Product_ID}" style="background: rgba(244, 148, 49);" type="button">Add</button>`;
        //var button = `<button type="button" class="btn btn-sm text-white w-100 addToCartBtn" style="background: rgba(244, 148, 49);" value="${product.Product_ID}">ADD</button>`;

        if (
          product.Picklist_ID == category.Picklist_ID ||
          product.Picklist_parent_ID == category.Picklist_ID
        ) {
          var color = "text-primary";

          if (product.hasOwnProperty("Veg_NonVeg_flag")) {
            if (product.Veg_NonVeg_flag == "Non-Veg") {
              color = "text-danger";
            } else {
              color = "text-success";
            }
          }

          var productImageTagID = product.Product_ID;
          var productImageTagClassName = product.Product_ID + "RecomendedImg";

          var item = `<div class="card">
                        <div class="row">
                            <a class="col-4 col-lg-2 col-md-2 d-flex align-items-around vItemProduct" >
                                <img src="" class="rounded-3 ${productImageTagClassName}" ></img>
                            </a>
                            <div class="col-8 col-lg-10 col-md-10">
                                <div class="card-body vItemProduct">
                                    <div class="row">
                                        <div class="col-8 d-flex justify-content-start pr-0">
                                            <h5 class="card-title truncate-one-cart">${product.Product_Service_Name}</h5>
                                        </div>
                                        <div class="col-4 d-flex justify-content-end pl-0">
                                        <h6 class="card-title ${color}">${product.Veg_NonVeg_flag}</h6>
                                                        </div>
                                    </div>
                                    <p class="card-text truncate-cart">${product.Description}</p>

                                    <div class="row pb-2">
                                        
                                        <div class="col-md-3 col-6 d-flex flex-column-reverse">
                                            <h5>₹${product.Price}</h5>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                      </div><br>`;
          //If it's a recomended Product
          if (product.Recommended_flag == 1) {
            itemListRecommended.push(item);
          }
          itemListMenu.push(item);
          var productImageURL = product.Image_URL;
          // productImageURL = "Test/no image.jpg"

          s3Object.getS3Image(productImageURL, null, productImageTagClassName);

          // s3Object.getS3Image(productImageURL, productImageTagID);
        }
      });

      if (itemListRecommended.length > 0) {
        //         recommendedSection = `<!-- recommended -->
        // <div class="card ${category.Picklist_ID}-recommended">
        //     <h5 class="card-header">Best Sellers</h5>
        //     <h6 class="card-text ml-3 mt-1" style="text-align:left; color:grey;"><span class="recommendedtotal">${itemListRecommended.length}</span> items</h6>
        //     <!-- item1 -->
        //     <!-- <div class=""></div> -->
        //     <div class="${category.Picklist_ID}-recommended-Products">`;

        // recommendedSection = `<!-- recommended -->
        // <div class="card mb-4 ml-5 mt-3 ${category.Picklist_ID}-recommended">
        //     <h5 class="card-header">Best Sellers</h5>
        //     <h6 class="card-text mt-1 ml-3" style="color:grey;"><span class="recommendedtotal">${itemListRecommended.length}</span> items</h6>`;

        recommendedSection = `<div class="row">
            <div class="card pb-1 ${category.Picklist_ID}-recommended">
                <h5 class="card-header">Best Sellers</h5>
                <h6 class="card-text mt-1 ml-3" style="color:grey;"><span class="recommendedtotal">${itemListRecommended.length}</span> items</h6>`;

        //   recommendedSection +=
        //     itemListRecommended.join(" ") +
        //     `</div>
        // </div>
        // <br>`;

        recommendedSection +=
          itemListRecommended.join(" ") + `</div></div><br>`;
      } else {
        recommendedSection = "";
      }

      if (itemListMenu.length > 0) {
        //         menuSection = `<!-- recommended -->
        // <div class="card ${category.Picklist_ID}-menu">
        //     <h5 class="card-header">Menu</h5>
        //     <h6 class="card-text ml-3 mt-1" style="text-align:left; color:grey;"><span class="menutotal">${itemListMenu.length}</span> items</h6>
        //     <!-- item1 -->
        //     <!-- <div class=""></div> -->
        //     <div class="${category.Picklist_ID}-menu-Products">`;

        // menuSection = `<!-- Menu -->
        // <div class="card mb-4 ml-5 mt-3 ${category.Picklist_ID}-menu">
        //     <h5 class="card-header">Menu</h5>
        //     <h6 class="card-text mt-1 ml-3" style="color:grey;"><span class="menutotal">${itemListMenu.length}</span> items</h6>`;

        menuSection = `<div class="row">
            <div class="card pb-1 ${category.Picklist_ID}-menu">
                <h5 class="card-header">Menu</h5>
                <h6 class="card-text mt-1 ml-3" style="color:grey;"><span class="menutotal">${itemListMenu.length}</span> items</h6>`;

        //   menuSection +=
        //     itemListMenu.join(" ") +
        //     `</div>
        // </div>`;

        menuSection += itemListMenu.join(" ") + `</div></div>`;
      } else {
        menuSection = "";
      }

      menuEnd = `</span> <!--Menu End for Category-->`;

      //final Menu for category with recomended and menu
      finalCategoryMenu =
        menuStart + recommendedSection + menuSection + menuEnd;

      $(".all-vendor-category-products").append(finalCategoryMenu);

      //Shows the initial category and hides the rest
      if (i >= 1) {
        //$(`span.vendor-category-products.${category.Picklist_ID}`).hide();
        $(`span.vendor-category-products.${category.Picklist_ID}`).addClass(
          "d-none"
        );
      }

      i += 1;
      status = "";
    });

    //Onclick Event For Vendor Categories Buttons
    $(".vendorCategoriesButtons").on("click", this.showVendorCategoriesDetails);

    //if there is no delivery option hide buttons
    if (res.delivery.length == 0) {
      $(".deliveryOptionBtn").hide();
    }
  };

  showVendorCategoriesDetails = (event) => {
    console.log(event);
    // var prevCategoryID = $("input[type=radio]:checked").next().attr("id");
    // $("input[type=radio]:checked").prop("checked", false);
    // $(event.target).prev().prop("checked", true);
    // var newCategoryID = $(event.target).attr("id");

    var prevCategoryID = $(".currentActiveCategory").attr("id");
    var newCategoryID = $(event.currentTarget).attr("id");

    console.log(prevCategoryID);
    console.log(newCategoryID);

    $(`span.${prevCategoryID}`).addClass("d-none");
    $(`span.${newCategoryID}`).removeClass("d-none");

    // //show and hide
    // $("span." + prevCategoryID).hide();
    // $("span." + newCategoryID).show();

    $(`div#${prevCategoryID}`).removeClass("currentActiveCategory");
    $(`div#${newCategoryID}`).addClass("currentActiveCategory");
    //Remove active class
    $(`div#${prevCategoryID}`).removeClass("active");
    $(`div#${newCategoryID}`).addClass("active");

    //show selected items
    $(`div#${prevCategoryID}`).removeClass("selected-item");
    $(`div#${newCategoryID}`).addClass("selected-item");
  };

  getVendorDetailsWithMultipleCategories = () => {
    console.log(this.Vendor_ID);
    $.ajax({
      url:
        baseURL +
        "api/customer/ProductSummary/getVendorDetailsWithMultipleCategoriesForVendor",
      type: "POST",
      async: false,
      data: {
        Vendor_ID: this.Vendor_ID,
      },
      headers: { Authorization: X_API_KEY },
      success: (result) => {
        console.log(result);
        this.showVendorDetails(result);
      },
      error: (result) => {
        console.log(result.message);
      },
    });
  };
}
