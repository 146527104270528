var currentVendorCategoriesId = [];
var category_id;
var speciality_id;
var categoryDeletableFlag = true;
var specialityDeletableFlag = true;

var currentCategoryClicked;
var currentSpecialityClicked;

import s3Util from "../helpers/s3Util";
var s3Object = new s3Util();


export default class Vendor_category_speciality_details {
  constructor() {
    console.log("in constructor of Vendor_category_speciality_details");
    this.formstep1 = $("#formstep1");
    this.formstep2 = $("#formstep2");
    this.formstep3 = $("#formstep3");
    this.formstep4 = $("#formstep4");
    this.formstep5 = $("#formstep5");

    this.categorySubmitBtn = $("#categorySubmitBtn");
    this.categoryPrevBtn = $("#categoryPrevBtn");
    this.specialitySubmitBtn = $("#specialitySubmitBtn");
    this.specialityPrevBtn = $("#specialityPrevBtn");

    this.vendorSpecialitiesBox = $(".vendorSpecialitiesBox");
    this.categoryError = $("#categoryError");
    this.specialityError = $("#specialityError");
    this.GSTDetails_page = $(".GSTDetails-page");
    this.vendorCategoriesBox = $(".vendorCategoriesBox");
    this.basicDetails_page = $(".basicDetails-page");

    // Registering Events
    this.bindEvents();
  }

  bindEvents = () => {
    console.log("in vendor category This is the js");
    // $.ajax({
    //   type: "GET",
    //   url: baseURL + "api/Vendor/getCategories",
    //   headers: { Authorization: X_API_KEY },

    //   success: this.showCategoryOptions,
    // });
    this.categorySubmitBtn.on("click", this.vendorCategoriesFormSubmit);
    this.specialitySubmitBtn.on("click", this.vendorSpecialitiesFormSubmit);
    this.categoryPrevBtn.on("click", this.categoryPrevBtnAction);
    this.specialityPrevBtn.on("click", this.specialityPrevBtnAction);

    // $(window).on("load", this.getCategoryOptions);

    $(window).on("load", $.ready.then(this.getCategoryOptions));
    // this.verifyOTPBtn.on("click", this.doVerifyOTP);
  };
  categoryCheckBoxclicked = (e) => {
    console.log("categoryCheckBoxclicked", e);
    currentCategoryClicked = e;
    console.log(e.target.checked);
    category_id = e.target.attributes[3]["nodeValue"];
    console.log(category_id);

    if (!e.target.checked) {
      categoryDeletableFlag = false;
      console.log("unchecked");
      $.ajax({
        type: "GET",
        url: baseURL + "api/Vendor/GetProductsByCategoryForServicing",
        headers: { Authorization: X_API_KEY },
        async: false,
        data: {
          category_id: category_id,
          // Offset: offsetCount,
        },
        success: this.doCategoryCheckOrUncheck,
      });
    }
  };

  doCategoryCheckOrUncheck = (res) => {
    console.log(res);
    categoryDeletableFlag = true;

    if (res == true) {
      window.$("#categoriesErrorModal").modal("show");

      currentCategoryClicked.target.checked = true;
    }
  };
  //To submit the categories of the vendor
  vendorCategoriesFormSubmit = (e) => {
    e.preventDefault();
    if (categoryDeletableFlag == false) {
      alert("please try after some time");
      return;
    }
    console.log($(".categoryCheckBox:checked"));
    // console.log($("input[type='checkbox']:checked").length);
    console.log($(".categoryCheckBox:checked").length);
    if ($(".categoryCheckBox:checked").length < 1) {
      this.categoryError.html("Please select atleast one product or service");
    } else {
      var category_ids_array = [];

      //to create array of category IDs
      for (let i = 0; i < $(".categoryCheckBox:checked").length; i++) {
        console.log($(".categoryCheckBox:checked")[i].defaultValue);
        category_ids_array[i] = $(".categoryCheckBox:checked")[i].defaultValue;
      }

      //To save categories of the vendor in the db
      $.ajax({
        type: "POST",

        url: baseURL + "api/Vendor/AddUpdateCategories",

        async: false,

        headers: { Authorization: X_API_KEY },

        data: {
          category_ids_array: category_ids_array,
        },

        success: console.log("Successfully"),
      });

      //To get Specialities of the selected categories
      $.ajax({
        type: "GET",
        url: baseURL + "api/Vendor/GetAllSpecialities",
        headers: { Authorization: X_API_KEY },
        success: this.doDisplaySpec,
      });

      this.categoryError.html("");
      this.vendorCategoriesBox.addClass("d-none");
      this.vendorSpecialitiesBox.removeClass("d-none");
    }

    //To get all specialities of selected categories
    // $.ajax({
    //   type: "GET",
    //   url: baseURL + "api/Vendor/GetAllSpecialities",
    //   headers: { Authorization: X_API_KEY },
    // });
  };

  //To submit the specialities of the vendor
  vendorSpecialitiesFormSubmit = (e) => {
    e.preventDefault();
    console.log($(".specialityCheckBox:checked"));
    // console.log($("input[type='checkbox']:checked").length);
    console.log($(".specialityCheckBox:checked").length);
    if ($(".specialityCheckBox:checked").length < 1) {
      this.specialityError.html("Please select atleast one speciality");
    } else {
      var speciality_ids_array = [];

      //to create array of speciality IDs
      for (let i = 0; i < $(".specialityCheckBox:checked").length; i++) {
        console.log($(".specialityCheckBox:checked")[i].defaultValue);
        speciality_ids_array[i] = $(".specialityCheckBox:checked")[
          i
        ].defaultValue;
      }

      console.log(speciality_ids_array);

      //To save specialities of the vendor in the db
      $.ajax({
        type: "POST",

        url: baseURL + "api/Vendor/AddUpdateSpecialities",

        async: false,

        headers: { Authorization: X_API_KEY },

        data: {
          speciality_ids_array: speciality_ids_array,
        },

        success: (resp) => {
          if (resp == true) {
            resp = "Specialities Updated Successfully";
          } else {
            resp = "Something went wrong";
          }
          $("#servicingCategorySpecialityFlowEndModalMsg").html(resp);

          window.$("#servicingCategorySpecialityFlowEndModal").modal("show");
          $("#servicingCategorySpecialityFlowEndModalCloseBtn").on("click", this.claoseServicingCategorySpecialityFlowEndModal);
        },
      });

      var currentPath = window.location.pathname;

      console.log(currentPath);
      var lastTenCharacters = currentPath.slice(-10);
      console.log(lastTenCharacters);

      if (lastTenCharacters != "_dashboard") {
        this.GSTDetails_page.removeClass("d-none");
        this.vendorSpecialitiesBox.addClass("d-none");
        this.formstep2.removeClass("current-item");
        this.formstep3.addClass("current-item");
      }

      // this.reloadSpecialities();
      this.specialityError.html("");


    }
  };

  claoseServicingCategorySpecialityFlowEndModal = (e) => {
    // e.preventDefault();
    // console.log("claoseServicingCategorySpecialityFlowEndModal");
    // $("#servicingCategorySpecialityFlowEndModal").css('display', 'none')
    window.$("#servicingCategorySpecialityFlowEndModal").modal("hide");
  };

  // reloadSpecialities = () => {
  //   location.reload(true);
  //  };

  //To go back to Category Selection
  specialityPrevBtnAction = (e) => {
    e.preventDefault();
    this.vendorCategoriesBox.removeClass("d-none");
    this.vendorSpecialitiesBox.addClass("d-none");
  };

  //To go back to basic details
  categoryPrevBtnAction = (e) => {
    e.preventDefault();
    this.basicDetails_page.removeClass("d-none");
    this.vendorCategoriesBox.addClass("d-none");
    this.formstep2.removeClass("current-item");
    this.formstep1.addClass("current-item");
  };

  // To load the list of Specialities the vendor can choose
  getCategoryOptions = () => {
    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/getCategories",
      headers: { Authorization: X_API_KEY },

      success: this.showCategoryOptions,
    });
  };

  showCategoryOptions = (res) => {
    this.getCurrentVendorCategories();

    console.log(res);
    console.log(res.Product);
    var categoryCount = 20;
    var categoryCheckedFlag = ``;

    res.Product.forEach((element) => {
      console.log(currentVendorCategoriesId.length);

      categoryCheckedFlag = ``;

      for (var i = 0; i < currentVendorCategoriesId.length; i++) {
        console.log("hello");
        if (currentVendorCategoriesId[i] == element.Picklist_ID) {
          categoryCheckedFlag = `checked`;
        }
      }

      console.log(element.Picklist_display_value);
      // console.log(element.Picklist_display_value);
      // var item = `<option value="${element.Picklist_display_value}">${element.Picklist_display_value}</option>`;
      var item = `<div class="col-4 col-md-2 d-flex justify-content-center my-2 ">
                      <div class="custom-control custom-checkbox image-checkbox">
                          <input type="checkbox" class="custom-control-input categoryCheckBox justify-content-center" id="categoryck${categoryCount}" value="${element.Picklist_ID}" ${categoryCheckedFlag}>
                          <label class="custom-control-label d-flex justify-content-center" for="categoryck${categoryCount}">
                              <img class="img-fluid rounded-circle categoryImage categoryImg${categoryCount} " onerror="this.src='assets/src/images/pancake.jpeg'" alt="Image" loading="lazy">
                          </label>
                          <label class="d-flex justify-content-center">
                          <span class="fs-12 text-lowercase text-center text-capitalize">${element.Picklist_display_value}</span>
                          </label>
                      </div>
                  </div>`;
      $("#allProductCategoryDisplay").append(item);

      //Product category Image insert
      var prodCategoryImageURL = element.Icon_Url;
      var prodCategoryImageTagClassName = `categoryImg${categoryCount}`
      s3Object.getS3Image(prodCategoryImageURL, null, prodCategoryImageTagClassName);

      categoryCount++;
    });
    res.Service.forEach((element) => {
      console.log(element.Picklist_display_value);
      console.log(currentVendorCategoriesId.length);

      categoryCheckedFlag = ``;

      for (var i = 0; i < currentVendorCategoriesId.length; i++) {
        console.log("hello");
        if (currentVendorCategoriesId[i] == element.Picklist_ID) {
          categoryCheckedFlag = `checked`;
        }
      }
      // console.log(element.Picklist_display_value);
      // var item = `<option value="${element.Picklist_display_value}">${element.Picklist_display_value}</option>`;
      var item = `<div class="col-4 col-md-2 d-flex justify-content-center my-2 ">
                      <div class="custom-control custom-checkbox image-checkbox">
                          <input type="checkbox" class="custom-control-input categoryCheckBox justify-content-center" id="categoryck${categoryCount}" value="${element.Picklist_ID}" ${categoryCheckedFlag}>
                          <label class="custom-control-label d-flex justify-content-center" for="categoryck${categoryCount}">
                              <img  class="img-fluid rounded-circle categoryImage categoryImg${categoryCount}" onerror="this.src='assets/src/images/pancake.jpeg'" alt="Image" loading="lazy">
                          </label>
                          <label class="d-flex justify-content-center">
                          <span class="fs-12 text-lowercase text-center text-capitalize">${element.Picklist_display_value}</span>
                          </label>
                      </div>
                  </div>`;
      $("#allServiceCategoryDisplay").append(item);

      //Service category Image insert
      var serviceCategoryImageURL = element.Icon_Url;
      var serviceCategoryImageTagClassName = `categoryImg${categoryCount}`
      s3Object.getS3Image(serviceCategoryImageURL, null, serviceCategoryImageTagClassName);

      categoryCount++;
    });
    $(".categoryCheckBox").on("click", this.categoryCheckBoxclicked);
  };

  //To Display Specialities of the selected categories (ARRAYS)
  doDisplaySpec = (res) => {


    console.log("To load each specialities Box doDisplaySpec");

    //To make the collapsable boxes function
    var collapsableBoxCounter = 221;
    //To gie unique ids to the speciality checkboxes
    var SpecialityCount = 1;

    //to avoid duplicates
    //try using on change function to empty
    $("#SpecialityBox").empty();

    //If none of the selected categories have specialities
    if (!res.Categories) {
      console.log("Empty now");
      console.log("To load each specialities Box IF ");
      var item = `<h5 my-auto>Select a Category having specialities</h5>`;
      $("#SpecialityBox").append(item);
    } else {
      //To load each specialities Box
      console.log("To load each specialities Box");
      res.Categories.forEach((element) => {
        console.log(element);
        console.log(res[element]);

        //End current iteration if No Specialities for the current Category exists
        if (res[element] == null) {
          return;
        }

        //To load specialities of current Category
        var itemBody = ``;
        var checkedFlag = null;

        //to append specialities
        res[element].forEach((element) => {
          console.log(element);

          //to check the checkboxes
          if (element.Selected == true) {
            checkedFlag = "checked";
          } else {
            checkedFlag = null;
          }

          itemBody = `${itemBody}
                      <div class="col-4 col-md-2 d-flex justify-content-center my-2 ">
                        <div class="custom-control custom-checkbox image-checkbox">
                         <input type="checkbox" class="custom-control-input specialityCheckBox justify-content-center" id="specialityck${SpecialityCount}" value="${element.Picklist_ID}" ${checkedFlag}>
                          <label class="custom-control-label d-flex justify-content-center" for="specialityck${SpecialityCount}">
                              <img src="" id=${element.Picklist_ID} class="img-fluid rounded-circle specialityImage" onerror="this.src='assets/src/images/pancake.jpeg'" alt="Image" loading="lazy">
                          </label>
                          <label class="d-flex justify-content-center">
                              <span class="fs-12 text-lowercase text-center text-capitalize">${element.Picklist_display_value}</span>
                          </label>
                        </div>
                      </div>`;

          SpecialityCount++;
        });

        var item1 = `<div id="accordion-${collapsableBoxCounter}" role="tablist" aria-multiselectable="true" class="o-accordion">
        <div class="card multi">
          <div class="card-header" role="tab" id="headingOne-${collapsableBoxCounter}">
              <h5 class="mb-0">
                  <div class="row row justify-content-between">
                      <div class="col-11">
                          &nbsp;
                          <p>${element}</p>
                      </div>
                      <div class="col-1 d-flex justify-content-end align-items-center">
                          <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne-${collapsableBoxCounter}" aria-expanded="true" aria-controls="collapseOne-${collapsableBoxCounter}">
                              <img src="assets/src/images/arrow-down-circle.svg" width="20" height="20" class="mx-auto d-block" alt="^">
                          </a>
                      </div>
                  </div>
              </h5>
          </div>

          <!-- Products COLLAPSABLE CARD -->
          <div id="collapseOne-${collapsableBoxCounter}" class="collapse show" role="tabpanel" aria-labelledby="headingOne-${collapsableBoxCounter}">
              <div class="card-block">
                  <div class="container">
                      &nbsp;


                      <!-- Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop -->
                      <div class="row " id="SpecialitiesDisplay">${itemBody}</div></div></div></div></div></div>`;

        $("#SpecialityBox").append(item1);
        collapsableBoxCounter++;
      });
      //Call to Function to load the S3 Category images in the respective image tags
      this.loadSpecialityImages(res);
    }
    $(".specialityCheckBox").on("click", this.specialityCheckBoxclicked);
  };

  //To load the S3 Category images in the respective image tags
  loadSpecialityImages = (res) => {
    //For each Category ABC
    res.Categories.forEach(async (element) => {
      //End current iteration if No Specialities for the current Category exists
      if (res[element] == null) {
        return;
      }
      //For each Speciality in Category ABC
      res[element].forEach(async (elementS) => {
        var specialityS3Image = await s3Object.getObjectURL(elementS.Icon_Url);
        document.getElementById(elementS.Picklist_ID).src = specialityS3Image;
      });
    });
  }

  specialityCheckBoxclicked = (e) => {
    console.log("specialityCheckBoxclicked", e);
    currentSpecialityClicked = e;
    console.log(e.target.checked);
    speciality_id = e.target.attributes[3]["nodeValue"];
    console.log(speciality_id);

    if (!e.target.checked) {
      console.log("unchecked");
      $.ajax({
        type: "GET",
        url: baseURL + "api/Vendor/GetProductsBySpecialityForServicing",
        headers: { Authorization: X_API_KEY },
        async: false,

        data: {
          speciality_id: speciality_id,
          // Offset: offsetCount,
        },
        success: this.doSpecialityCheckOrUncheck,
      });
    }
  };

  doSpecialityCheckOrUncheck = (res) => {
    console.log(res);
    //Array of Products are returned if they exist for the speciality, Else it returns false.
    if (res != false) {
      $(".modal-body-product-list").empty();

      window.$("#SpecialityErrorModal").modal("show");

      var count = 1;
      var productList = `Products/Services:<br>`;
      res.forEach((element) => {
        productList = `${productList}
                      <span>${count} - ${element.Product_Service_Name}</span>
                      <br>`
        count++;
      });

      $(".modal-body-product-list").append(productList);

      currentSpecialityClicked.target.checked = true;
    }
  };

  getCurrentVendorCategories = () => {
    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/GetVendorCategories",
      headers: { Authorization: X_API_KEY },
      async: false,
      success: this.GetCurrentCategoriesId,
    });
  };

  GetCurrentCategoriesId = (res) => {
    res.forEach((element) => {
      console.log(element);
      currentVendorCategoriesId.push(element.Picklist_ID);

      // console.log(element.Picklist_display_value);
      // var item = `<option value="${element.Picklist_display_value}">${element.Picklist_display_value}</option>`;
      //     var item = `<div class="col-4 col-md-2 d-flex justify-content-center my-2 ">
      //     <div class="custom-control custom-checkbox image-checkbox">
      //         <input type="checkbox" class="custom-control-input categoryCheckBox justify-content-center" id="categoryck${categoryCount}" value="${element.Picklist_ID}">
      //         <label class="custom-control-label d-flex justify-content-center" for="categoryck${categoryCount}">
      //             <img src="assets/src/images/pancake.jpeg" alt="#" class="img-fluid rounded-circle categoryImage">
      //         </label>
      //         <label class="d-flex justify-content-center">
      //         <span class="fs-12 text-lowercase text-center">${element.Picklist_display_value}</span>
      //         </label>
      //     </div>
      // </div>`;
      //     $("#allProductCategoryDisplay").append(item);
      //     categoryCount++;
    });
    console.log(currentVendorCategoriesId);
  };
}
