import s3Util from "../helpers/s3Util";
var s3Object = new s3Util();
var deviceType;
export default class customer_all_offers {
  constructor() {
    var screenWidthOrders = $(window).width();
    console.log(screenWidthOrders);
    if (screenWidthOrders < 768) {
      //show mobile view
      console.log("mobile width....");
      deviceType = "mobile";
    } else {
      deviceType = "desktop";
    }
    this.bindEvents();
  }

  bindEvents = () => {
    this.getAllOffersOnHomepage();
  };

  //GET all the coupons
  getAllOffersOnHomepage = () => {
    console.log("inside get coupons");
    $.ajax({
      url: baseURL + "api/customer/Cart/GetAllCoupons",
      type: "GET",
      async: false,
      headers: { Authorization: X_API_KEY },
      success: (result) => {
        console.log(result);

        //Emptying
        $(".recentCouponsServDesktop").empty();
        $(".recentCouponsServMobile").empty();

        result.forEach(async (element) => {
          var couponImg = await s3Object.getObjectURL(
            element.Offer_Details.Image_Url
          );

          var offerType = element.Offer_Details.Go_To_Page;
          var page = `${baseURL}customer/allOffers`;
          if (offerType == "vendor") {
            page = `${baseURL}customer/productListing/${element.Offer_Details.data}`;
          } else if (offerType == "category") {
            page = `${baseURL}customer/vendorListingPage/${element.Offer_Details.data}`;
          }

          if (element.Vendor_Details != null) {
            var sellerDetails = `<div class="row">
                <p class="h-100 mb-2 pt-1"><a href="${baseURL}customer/productListing/${element.Vendor_Details.Vendor_URL}"><u>Seller: ${element.Vendor_Details.Business_Name}</u></a></p>
            </div>`;
          } else {
            var sellerDetails = `<div class="row">
              <p class="h-100 mb-2 pt-1"><a href="${baseURL}customer/vendorListingPage/${element.Picklist_Details.Picklist_display_value}"><u>Category: ${element.Picklist_Details.Picklist_display_value}</u></a></p>
          </div>`;
          }
          console.log(element);
          console.log(deviceType);
          var date = element.End_Date;
          date = date.split(" ");

          if (deviceType == "desktop") {
            var item = `
            <div class="col-3 p-1">
                <div class="card border border-3 ml-2 h-100">
                    <div class="offersImage">
                        <a href="${page}" rel="noopener " style="margin-top:0.5rem;">
                        <img class="d-block zoom shadow" src="${couponImg}" alt="${element.Offer_Details.Offer_Name}-Image" style="width: 100%;">
                        </a>
                    </div>
                    <div class="card-body mb-1">
                        <div class="row" style="background: rgba(244, 148, 49); ">
                            <div class="col">
                                <h6 class="card-title text-white">${element.Coupon_Code}</h6>
                            </div>
                            <div class="col d-flex justify-content-end">
                                <button class="btn p-0 text-white copyCouponCode">
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0,0,256,256" style="fill:#000000;">
                                        <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
                                            <g transform="scale(10.66667,10.66667)">
                                                <path d="M4,2c-1.105,0 -2,0.895 -2,2v12c0,1.105 0.895,2 2,2h12c1.105,0 2,-0.895 2,-2v-12c0,-1.105 -0.895,-2 -2,-2zM13.58594,6l1.41406,1.41406l-6,6l-4,-4l1.41406,-1.41406l2.58594,2.58594zM20,6v14h-14v2h14c1.105,0 2,-0.895 2,-2v-14z"></path>
                                            </g>
                                        </g>
                                    </svg>
                                    <input type="hidden" name="couponCodeId" value="${element.Coupon_Code}" /></button>
                            </div>
                        </div>
            
                        ${sellerDetails}
            
                        <div class="row">
                            <p class="card-text mb-1" style="color: grey; ">${element.Coupon_Description}</p>
                        </div>
            
                        <div class="row">
                            <div class="col-8 pr-0">
                                <p class="card-text" style="font-size:0.9rem;"> MinCart Amount
                                </p>
                                <!-- <p class="card-text" style="color: grey; ">150</p> -->
                            </div>
                            <div class="col-4 p-0">
                                <p class="card-text d-flex justify-content-end" style="font-size:0.9rem;">Valid Till</p>
                                <!-- <p class="card-text d-flex justify-content-end" style="color: grey; ">31-12-2022</p> -->
                            </div>
                        </div>
            
                        <div class="row" style="font-size:1rem;">
                            <div class="col-6">
                                <p class="card-text" style="color: grey;  font-size:1rem;">Rs ${element.Minimum_Cart_Amount}</p>
                            </div>
                            <div class="col-6 p-0">
                                <p class="card-text d-flex justify-content-end" style="color: grey; font-size:1rem;">${date[0]}</p>
                            </div>
                        </div>
            
                    </div>
                </div>
            </div>`;
            $(".recentCouponsServDesktop").append(item);
          } else {
            //mobile view for Coupons
            var item = `<div class="card mb-2 border border-3 ml-3 mr-3 mt-2">
            <div class="h-100 p-3 offersImage">
                <a href="${page}" rel="noopener " style="margin-top:0.5rem;">
                    <img class="d-block zoom shadow" src="${couponImg}" alt="${element.Offer_Details.Offer_Name}-Image" style="width: 100%;">
                </a>
            </div>
            <div class="card-body pt-0 mb-1">
                <div class="row" style="background: rgba(244, 148, 49); ">
                    <div class="col">
                        <h6 class="card-title text-white py-1 px-4 mt-2">${element.Coupon_Code}</h6>
                    </div>
                    <div class="col d-flex justify-content-end">
                        <button class="btn p-0 copyCouponCode">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0,0,256,256" style="fill:#000000;">
                                <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
                                    <g transform="scale(10.66667,10.66667)">
                                        <path d="M4,2c-1.105,0 -2,0.895 -2,2v12c0,1.105 0.895,2 2,2h12c1.105,0 2,-0.895 2,-2v-12c0,-1.105 -0.895,-2 -2,-2zM13.58594,6l1.41406,1.41406l-6,6l-4,-4l1.41406,-1.41406l2.58594,2.58594zM20,6v14h-14v2h14c1.105,0 2,-0.895 2,-2v-14z"></path>
                                    </g>
                                </g>
                            </svg>
                            <input type="hidden" name="couponCodeId" value="${element.Coupon_Code}" /></button>
                    </div>
                </div>
        
                ${sellerDetails}
        
                <div class="row">
                    <p class="card-text mb-3" style="color: grey; ">${element.Coupon_Description}</p>
                </div>
        
                <div class="row" style="font-size:12px;">
                    <div class="col-7">
                        <p class="card-text">MIN CART AMOUNT </p>
                        <!-- <p class="card-text" style="color: grey; ">Rs ${element.Minimum_Cart_Amount}</p> -->
                    </div>
                    <div class="col">
                        <p class="card-text d-flex justify-content-end">VALID TILL</p>
                        <!-- <p class="card-text d-flex justify-content-end" style="color: grey; ">31-12-2022</p> -->
                    </div>
                </div>
        
                <div class="row" style="font-size:12px;">
                    <div class="col-7">
        
                        <p class="card-text" style="color: grey; ">150</p>
                    </div>
                    <div class="col">
                        <p class="card-text d-flex justify-content-end" style="color: grey; ">${date[0]}</p>
                    </div>
                </div>
            </div>
        </div>
          `;
            $(".recentCouponsServMobile").append(item);
          }

          $(".recentCouponsServDesktop .copyCouponCode").off("click");
          $(".recentCouponsServDesktop .copyCouponCode").on(
            "click",
            this.copyCodeOnHomepage
          );
          $(".recentCouponsServMobile .copyCouponCode").off("click");

          $(".recentCouponsServMobile .copyCouponCode").on(
            "click",
            this.copyCodeOnHomepage
          );
        });
      },
      error: (result) => {
        console.log(result);
      }
    });
  };

  //copy coupon code
  copyCodeOnHomepage = (event) => {
    console.log(event);
    var code = $(event.currentTarget).find("input[name='couponCodeId']").val();
    console.log(code);
    // navigator.clipboard.writeText(code).then(() => {
    //   alert("Copied Coupon!");
    // });

    navigator.clipboard.writeText(code);
  };
}
