export const productListingPageNestHubMaxView = `<!-- for bigger screens ( NestHubMax ) > 1024 -->
<div class="productListingNestHubMaxView22 pt-4 mb-2 mt-2">

    <div class="row " style="background: rgba(244, 148, 49);">

        <div class="container">
            <div class="row ">
                <div class="col-4 ml-5">
                <span class="vendorMainImage">
                    <img src="http://localhost/goancart/assets/src/images/waffle1Cropped.jpg" class="img-load " style="margin-left:12rem; margin-top:1rem; margin-bottom:1rem;" width="100%">
                    </span>
                </div>
                <div class="col">
                    <div class="container">
                        <div class="row d-flex justify-content-start " style="font-size: 18px; margin-left:-5rem;">
                            <div class="col text-white">
                                <p class=" mb-2" style="font-size: 18px; margin-top:1rem; "><b><span class="vendorBusinessName"></span></b></p>
                                <p class="mb-3" style="font-size: 16px; margin-top:1rem; "><span class="vendorShortAddress"></span></p>


                            </div>

                            <div class="col ml-5 " style="margin-left:-8rem;">
                                <div class="col-3  d-md-block ml-5">
                                    <button type="button" class="btn btn-light btn-sm px-3 mb-2 mt-5 ml-5" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style="color: rgba(244, 148, 49); font-size: 16px; margin-top:1rem; ">View photos</button>
                                </div>
                            </div>

                            <div class="col " style="margin-left:-2rem;">
                                <div class="col  d-md-block ">
                                    <a href="#menu_id"><button type="button" class="btn btn-light btn-sm px-3 mb-2 ml-2 mt-5" style="color: rgba(244, 148, 49);">View reviews</button></a>
                                </div>
                            </div>


                        </div>

                        <div class="row d-flex justify-content-start " style="font-size: 16px; margin-left:-5rem; margin-top:0rem;">
                            <p style="color:white; "><span class="vendorDescription"></span></p>
                        </div>

                        <div class="row d-flex justify-content-start " style="font-size: 16px; margin-left:-5rem;">
                            <div class="col">
                                <p class="fw-normal text-white ratingsLineMobView"><span class="vendorRating"></span></p>
                            </div>

                            <div class="col ">
                                <p class="fw-normal text-white ratingsLineMobView" style="margin-left:-11rem;"><b>₹ <span class="vendorMinOrder"></span> Min Order</b></p>
                            </div>

                            <div class="col ">
                                <p class="fw-normal text-white ratingsLineMobView" style="margin-left:-19rem;"><img src="http://localhost/goancart/assets/src/images/motorbike.png"></img><b> <span class="vendorDeliveryOptions"></span></b></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <section class="carousel_se_06 pt-1 mt-2">
        <div class="container-fluid">
            <div class="row">
                <div class="col  pt-0 p-0">
                    <!-- <div class="owl-carousel carousel_se_06_carousel owl-theme testCase"> -->

                    <div class="nav nav-tabs owl-carousel carousel_se_06_carousel owl-theme testCase vendorCategoriesButtons">

                        <!-- <li class="nav-item item">
                            <a class="nav-link active " aria-current="page" href="#" data-bs-toggle="button" autocomplete="off" aria-pressed="true" style="font-size:12px;">CATEGORY 1</a>
                        </li> -->
                        <!-- <li class="nav-item item">
                            <a class="nav-link" href="#" style="font-size:12px;">CATEGORY 2</a>
                        </li>
                        <li class="nav-item item">
                            <a class="nav-link" href="#" style="font-size:12px;">CATEGORY 3</a>
                        </li> -->
                    </div>



                    <!-- </div> -->
                </div>
            </div>
        </div>
    </section>





    <div class="container ">

        <!-- cards on left and right -->
        <div class="ml-3 mr-1 ">
            <div class="row">

                <!-- recommended and stuff -->
                <div class="col-md-8 all-vendor-category-products">

                    

                </div>

                <!-- cart col -->
                <div class="col-sm-4 mt-3">

                    <div class="row mobViewDisableIt cartEmptyDisableForMob" id="cartEmpty">
                        <div class="col">
                            <div class="card mr-5">
                                <div class="card-body">
                                    <h5 class="fw-normal" style="color:grey; text-align:center;">Your Cart is Empty !</h5>
                                    <h5 class="fw-normal" style="color:grey; text-align:center;">So hurry up and place an enquiry !</h5>
                                    <img src="http://localhost/goancart/assets/src/images/greyCart.png" class="rounded float-start d-block mt-3 ml-5" width="50%">

                                    <!-- <img src="assets\src\images\greyCart.png" class="rounded float-start d-block mt-3 ml-5" width="50%"> -->
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- cart -->
                    <div class="row d-none cartEmptyDisableForMob" id="ItemsAddedInCart">
                        <div class="card border-warning mb-3 mr-5 mt-3">

                            <div class="card-body2">
                                <h5 class="card-title">Cart</h5>
                                <h6 class="card-title">from <span class="vendorName"></span></h6>
                                <hr>


                                <div class="container text-left cart-items">
                                    <!-- items -->
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
                <br>

            </div>
        </div>

    </div>

</div>`;

export const productListingPageNestHubView = `<!-- NEW PRODUCT LISTING NEST HUB VIEW -->
<!-- nesthub view -->
<div class="productListingNestHubView22 pt-4 mb-2">

    <div class="row " style="background: rgba(244, 148, 49);">

        <div class="container">
            <div class="row ">
                <div class="col-4 ml-5">
                    <span class="vendorMainImage"><img src="http://localhost/goancart/assets/src/images/waffle1Cropped.jpg" class="img-load " style="margin-left:5rem; margin-top:1rem; margin-bottom:1rem;" width="100%"></span>
                </div>
                <!-- <div class="col text-white  " style="margin-left:-7rem;">
-->


                <div class="col">
                    <div class="container">
                        <div class="row d-flex justify-content-start " style="font-size: 14px; margin-left:-9rem;">
                            <div class="col text-white">
                                <p class=" mb-1" style="font-size: 16px; margin-top:1rem; "><b><span class="vendorBusinessName"></span></b></p>
                                <p style="font-size: 16px;  "><span class="vendorShortAddress"></span></p>


                            </div>

                            <div class="col ml-5 " style="margin-left:-9rem;">
                                <div class="col-3  d-md-block ml-5">
                                    <button type="button" class="btn btn-light btn-sm px-3 mb-2 mt-5 ml-5" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style="color: rgba(244, 148, 49);  margin-top:1rem; ">View photos</button>
                                </div>
                            </div>

                            <div class="col " style="margin-left:-2rem;">
                                <div class="col  d-md-block ">
                                    <a href="#menu_id"><button type="button" class="btn btn-light btn-sm px-3 mb-2 ml-2 mt-5" style="color: rgba(244, 148, 49);">View reviews</button></a>
                                </div>
                            </div>


                        </div>

                        <div class="row d-flex justify-content-start " style="font-size: 14px; margin-left:-9rem; margin-top:-1rem;">
                            <p style="color:white; "><span class="vendorDescription"></span></p>
                        </div>

                        <div class="row d-flex justify-content-start " style="font-size: 14px; margin-left:-9rem;">
                            <div class="col">
                                <p class="fw-normal text-white ratingsLineMobView"><span class="vendorRating"></span></p>
                            </div>

                            <div class="col ">
                                <p class="fw-normal text-white ratingsLineMobView" style="margin-left:-8rem;"><b>₹ <span class="vendorMinOrder"></span> Min Order</b></p>
                            </div>

                            <div class="col ">
                                <p class="fw-normal text-white ratingsLineMobView" style="margin-left:-15rem;"><img src="http://localhost/goancart/assets/src/images/motorbike.png"></img><b> <span class="vendorDeliveryOptions"></span></b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->

            </div>
        </div>

    </div>




    <section class="carousel_se_06 pt-1 mt-2">
        <div class="container-fluid">
            <div class="row">
                <div class="col  pt-0 p-0">
                    <!-- <div class="owl-carousel carousel_se_06_carousel owl-theme testCase"> -->

                    <div class="nav nav-tabs owl-carousel carousel_se_06_carousel owl-theme testCase vendorCategoriesButtons">

                    </div>



                    <!-- </div> -->
                </div>
            </div>
        </div>
    </section>


    <div class="container ">

        <!-- cards on left and right -->
        <div class="ml-3 mr-1 ">
            <div class="row">

                <!-- recommended and stuff -->
                <div class="col-md-8 all-vendor-category-products">

                    <!-- recommended -->
                    <!-- <div class="card mb-4 ml-5 mt-3">
                <h5 class="card-header">Recommended</h5>
                <h6 class="card-text  mt-1 ml-3" style="color:grey;">4 items</h6> -->
                    <!-- item1 -->
                    <!-- <div class="card m-2">
                    <div class="card-body">
                        <div class="container">

                            <div class="row">

                                <div class="col-3">
                                    <img src="<?php echo site_url('assets\src\images\waffle1Cropped.jpg'); ?>" width="100%">
                                    <button type="button" class="btn btn-sm text-white px-4 mt-2 w-100" style="background: rgba(244, 148, 49); margin-left: 0rem;">ADD</button>
                                </div>

                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <p class="truncate-newHomePage"><b>Dum Biryani Dum Biryani Dum Biryani </b></p>
                                            <p class="truncate-newHomePage">Zakis special chicken dum Biryani with raita</p>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col">
                                            <img src="https://img.icons8.com/color/24/FD7E14/vegetarian-food-symbol.png" />
                                        </div>
                                        <div class="col d-flex justify-content-end mr-2">
                                            Rs 250
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div> -->

                    <!-- item2 -->
                    <!-- <div class="card m-2">
                    <div class="card-body">
                        <div class="container">
                            <div class="row">
                                <div class="col-3">
                                    <img src="<?php echo site_url('assets\src\images\waffle1Cropped.jpg'); ?>" width="100%">
                                    <button type="button" class="btn btn-sm text-white px-4 mt-2 w-100" style="background: rgba(244, 148, 49); margin-left: 0rem;">ADD</button>
                                </div>
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <p class="truncate-newHomePage"><b>Dum Biryani Dum Biryani Dum Biryani </b></p>
                                            <p class="truncate-newHomePage">Zakis special chicken dum Biryani with raita</p>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col">
                                            <img src="https://img.icons8.com/color/24/FD7E14/vegetarian-food-symbol.png" />
                                        </div>
                                        <div class="col d-flex justify-content-end mr-2">
                                            Rs 250
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                    <!-- </div> -->

                </div>

                <!-- cart col -->
                <div class="col-sm-4 mt-3">

                    <div class="row mobViewDisableIt cartEmptyDisableForMob" id="cartEmpty">
                        <div class="col">
                            <div class="card mr-5">
                                <div class="card-body">
                                    <h6 class="fw-normal ml-2 mr-2" style="color:grey; text-align:center;">Your Cart is Empty !</h6>
                                    <h6 class="fw-normal ml-2 mr-2" style="color:grey; text-align:center;">So hurry up and place an enquiry !</h6>
                                    <img src="http://localhost/goancart/assets/src/images/greyCart.png" class="rounded float-start d-block mt-3 ml-5" width="50%">

                                    <!-- <img src="assets\src\images\greyCart.png" class="rounded float-start d-block mt-3 ml-5" width="50%"> -->
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- cart -->
                    <div class="row d-none cartEmptyDisableForMob" id="ItemsAddedInCart">
                        <div class="card border-warning mb-3 mr-5 mt-3">

                            <div class="card-body2">
                                <h5 class="card-title">Cart</h5>
                                <h6 class="card-title">from <span class="vendorName"></span></h6>
                                <hr>


                                <div class="container text-left cart-items">
                                    <!-- items -->
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
                <br>

            </div>
        </div>

    </div>

</div>`;

export const productListingPageTabView = `<div class="productListingTabView22 pt-4 mb-2">

<div class="row " style="background: rgba(244, 148, 49);">

    <div class="container">
        <div class="row ">
            <div class="col-5 ml-5">
                <span class="vendorMainImage">
                    <img src="http://localhost/goancart/assets/src/images/waffle1Cropped.jpg" class="img-load " style="margin-left:6rem; margin-top:1rem; margin-bottom:1rem;" width="100%">
                </span>
            </div>
            <!-- <div class="col text-white  " style="margin-left:-7rem;">-->


            <div class="col">
                <div class="container">
                    <div class="row d-flex justify-content-start " style="font-size: 14px; margin-left:-9rem;">
                        <div class="row text-white">
                            <p class=" mb-1" style="font-size: 14px; margin-top:1rem; "><b><span class="vendorBusinessName"></span></b></p>
                        </div>

                        <div class="col text-white">
                            <p class="mb-1" style="font-size: 14px; "><span class="vendorShortAddress"></span></p>
                        </div>

                        <div class="col ml-5 " style="margin-left:-4rem;">
                            <div class="col-3  d-md-block ml-1">
                                <button type="button" class="btn btn-light btn-sm px-3 mb-1  ml-5" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style="color: rgba(244, 148, 49); font-size: 14px; margin-top:-1rem; ">View photos</button>
                            </div>
                        </div>

                        <div class="col " style="margin-left:-3rem;">
                            <div class="col  d-md-block ">
                                <a href="#menu_id"><button type="button" class="btn btn-light btn-sm px-3 mb-1 ml-2 " style="color: rgba(244, 148, 49); font-size: 14px; margin-top:-1rem; ">View reviews</button></a>
                            </div>
                        </div>


                    </div>

                    <div class="row d-flex justify-content-start  mt-1 " style="font-size: 14px; margin-left:-9rem; margin-top: -1rem;">
                        <p style="color:white; "><span class="vendorDescription"></span></p>
                    </div>

                    <div class="row d-flex justify-content-start " style="font-size: 9px; margin-left:-9rem; margin-top: 0rem;">
                        <div class="col">
                            <p class="fw-normal text-white ratingsLineMobView"><span class="vendorRating"></span></p>
                        </div>

                        <div class="col ">
                            <p class="fw-normal text-white ratingsLineMobView" style="margin-left:-6rem; font-size:12px;"><b>₹ <span class="vendorMinOrder"></span> Min Order</b></p>
                        </div>

                        <div class="col ">
                            <p class="fw-normal text-white ratingsLineMobView" style="margin-left:-11rem; font-size:12px;"><img src="http://localhost/goancart/assets/src/images/motorbike.png"></img><b> <span class="vendorDeliveryOptions"></span></b></p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- </div> -->

        </div>
    </div>

</div>



<!-- new row for categories -->


<!-- <div class="container ml-5" style="margin-left:10rem;"> -->
<section class="carousel_se_06 pt-1 mt-2  " style="margin-left:8rem; margin-right:4rem;">
    <div class="container-fluid">
        <div class="row">
            <div class="col  pt-0 p-0">
                <!-- <div class="owl-carousel carousel_se_06_carousel owl-theme testCase"> -->

                <div class="nav nav-tabs owl-carousel carousel_se_06_carousel owl-theme testCase vendorCategoriesButtons">

                    <!-- <li class="nav-item item">
                        <a class="nav-link active " aria-current="page" href="#" data-bs-toggle="button" autocomplete="off" aria-pressed="true" style="font-size:12px;">CATEGORY 1</a>
                    </li> -->
                    <!-- <li class="nav-item item">
                        <a class="nav-link" href="#" style="font-size:12px;">CATEGORY 2</a>
                    </li>
                    <li class="nav-item item">
                        <a class="nav-link" href="#" style="font-size:12px;">CATEGORY 3</a>
                    </li> -->
                </div>



                <!-- </div> -->
            </div>
        </div>
    </div>
</section>
<!-- </div> -->



<div class="container ">

    <!-- cards on left and right -->
    <div class="ml-3 mr-1 ">
        <div class="row">

            <!-- recommended and stuff -->
            <div class="col all-vendor-category-products">

            </div>



        </div>
    </div>

</div>

</div>`;

export const productListingPageMobileView = `<!-- mobile view of product listing page -->
<div class="mb-5 mt-4">
    <div class="row" style="background: rgba(244, 148, 49);">
        <div class="m-1">

            <div class="row">

                <div class="col-4">
                <span class="vendorMainImage">
                    <img src="http://localhost/goancart/assets/src/images/waffle1Cropped.jpg" class="img-load m-1" width="100%">
                    </span>
                </div>

                <div class="col">
                    <div class="row">
                        <div class="col text-white">
                            <p class="mt-2 truncate-newHomePage font-size: 16px; "><b><span class="vendorBusinessName"></span></b></p>
                            <p class="truncate-newHomePage font-size: 16px; "><span class="vendorShortAddress"></span></p>
                            <p class="truncate-newHomePage font-size: 16px; "><span class="vendorDescription"></span></p>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">


                <div class="col-4">
                    <p class="d-flex justify-content-center"><span class="vendorRating"></span></p>
                </div>


                <div class="col text-white">

                    <div class="row">
                        <div class="col-7">
                            <p class="font-size: 3px; "><b>₹ <span class="vendorMinOrder"></span> Min Order</b></p>
                        </div>
                        <div class="col-5">
                            <p style="margin-left:-1rem;"><img src="http://localhost/goancart/assets/src/images/motorbike.png" class="font-size: 3px; "></img><b> <span class="vendorDeliveryOptions"></span></b></p>

                        </div>
                    </div>

                </div>

            </div>



            <div class="row">


                <div class="col-4">
                    <!-- <p class="mr-3 text-white d-flex justify-content-center" style="font-size: 16px; margin-left:1rem;">(Ratings)</p> -->
                </div>


                <div class="col text-white">

                    <div class="row">
                        <div class="col-6">
                            <button type="button" class="btn btn-light btn-sm  mb-2  " data-bs-toggle="modal" data-bs-target="#staticBackdrop" style="color:rgba(244, 148, 49); ">View photos</button>

                        </div>
                        <div class="col-6">
                            <a href="#menu_id"><button type="button" class="btn btn-light btn-sm mb-2 mr-1" style="color:rgba(244, 148, 49); ">View reviews</button></a>

                        </div>
                    </div>

                </div>

            </div>

            <!-- <div class="row text-white " >
                <div class="col-4">
                    <p><img src="https://img.icons8.com/material-rounded/16/FFFFFF/hand-drawn-star.png" class="mb-1 " /><img src="https://img.icons8.com/material-rounded/16/FFFFFF/hand-drawn-star.png" class="mb-1 " /><img src="https://img.icons8.com/material-rounded/16/FFFFFF/hand-drawn-star.png" class="mb-1 " /><img src="https://img.icons8.com/material-rounded/16/FFFFFF/hand-drawn-star.png" class="mb-1 " /><img src="https://img.icons8.com/material-rounded/16/FFFFFF/hand-drawn-star.png" class="mb-1  " /></p>
                </div>
                <div class="col-4">
                    <p class="font-size: 6px; ">₹ 200 Min Order</p>
                </div>
                <div class="col-4">
                    <p><img src="<?php echo site_url('assets\src\images\motorbike.png'); ?>" class="font-size: 6px; "></img>Free 5 km</p>
                </div>
            </div> -->

            <!-- <div class="row d-flex">
                <div class="col d-md-block ">
                    <div class="container ml-5 mr-4">
                        <button type="button" class="btn btn-light btn-sm  mr-5 mb-2  " data-bs-toggle="modal" data-bs-target="#staticBackdrop" style="color:rgba(244, 148, 49); ">View photos</button>
                        <a href="#menu_id"><button type="button" class="btn btn-light btn-sm mb-2 ml-2 " style="color:rgba(244, 148, 49); ">View reviews</button></a>
                    </div>
                </div>
            </div> -->



        </div>
    </div>

    <!-- new row for categories -->
    <div class="mt-2 mb-3">
        <!-- use sliding effect -->

        <!-- <div class="container  mt-2 mb-1">
            <div class="row d-flex justify-content-center">
                <div class="col">

                    <ul class="nav nav-tabs ">
                        <li class="nav-item">
                            <a class="nav-link active " aria-current="page" href="#" data-bs-toggle="button" autocomplete="off" aria-pressed="true" style="font-size:12px;">CATEGORY 1</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#" style="font-size:12px;">CATEGORY 2</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#" style="font-size:12px;">CATEGORY 3</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->


        <section class="carousel_se_06 pt-1 ml-2">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12  pt-0 p-0">
                        <!-- <div class="owl-carousel carousel_se_06_carousel owl-theme testCase"> -->

                        <ul class="nav nav-tabs owl-carousel carousel_se_06_carousel owl-theme testCase vendorCategoriesButtons">

                            <!-- <li class="nav-item item">
                                    <a class="nav-link active " aria-current="page" href="#" data-bs-toggle="button" autocomplete="off" aria-pressed="true" style="font-size:12px;">CATEGORY 1</a>
                                </li> -->
                            <!-- <li class="nav-item item">
                                    <a class="nav-link" href="#" style="font-size:12px;">CATEGORY 2</a>
                                </li>
                                <li class="nav-item item">
                                    <a class="nav-link" href="#" style="font-size:12px;">CATEGORY 3</a>
                                </li> -->
                        </ul>



                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </section>

    </div>

    <!-- products in the page -->

    <!-- recommended -->
    <span class="all-vendor-category-products">
        
    </span>



</div>`;

export const productSummaryPageNestHubMaxView = `<div class="mt-4 pt-5">
<div class="row " style="background: rgba(244, 148, 49);">


    <div class="col-4 ml-2">
    <img src="" class="img-load landingPageProductImage" style="margin-left:10rem; margin-top:1rem; margin-bottom:1rem;" width="100%">
    </div>

    <div class="col">
        <div class="container">
            <div class="row d-flex justify-content-start " style="margin-left:-6rem;">
                <div class="col text-white">
                    <p class=" " style="font-size: 20px; margin-top:1rem; "><b><span class="productName"></span></b></p>
                    <p style="font-size: 16px; "><span class="soldByVendorName"></span></p>
                    <p style="font-size: 16px; "><span class="soldByVendorAddress"></span></p>


                </div>

                <div class="col ml-5 " style="margin-left:-8rem;">
                    <div class="col-3  d-md-block ml-5">
                        <button type="button" class="btn btn-light btn-sm px-3 mb-2 mt-5 ml-5" data-bs-toggle="modal" data-bs-target="#viewProductPhotos" style="color: rgba(244, 148, 49); font-size: 16px; margin-top:1rem; ">View photos</button>
                    </div>
                </div>

                <div class="col " style="margin-left:-2rem;">
                    <div class="col  d-md-block ">
                        <a href="#review"><button type="button" class="btn btn-light btn-sm px-3 mb-2 ml-2 mt-5" style="color: rgba(244, 148, 49); font-size: 16px; ">View reviews</button></a>
                    </div>
                </div>


            </div>


            <div class="row d-flex justify-content-start text-white " style="font-size: 16px; margin-left:-6rem;">

                <div class="col">
                    <p class="fw-normal text-white ratingsLineMobView" style="margin-left: 0rem;"><span class="productRating"></span></p>
                </div>

                <div class="col ">
                    <p class="fw-normal text-white ratingsLineMobView" style="margin-left:-10rem;"><b>₹ <span class="productPrice"></span></b></p>
                </div>

                <div class="col ">
                    <!-- <p class="m-1 px-2 text-white fw-normal" style="background-color:green; margin-left:-4rem;">Veg</p> -->
                    <!-- <button type="button" class="btn btn-sm px-4 text-white" style="background-color:green; margin-left:-22rem; margin-bottom:1rem;">Veg</button> -->
                    <!-- <p class="d-flex justify-content-center  text-white" style="background-color:green; margin-left:-22rem; margin-bottom:1rem; margin-right:34rem;">Veg</p> -->
                    <span class="vegOrNonVegSymbol"></span>

                </div>

            </div>

        </div>

    </div>

</div>

<div class="container ">
    <div class="row">

        <div class="col ml-5">

            <div class="row">
                <div class="card  shadow mt-4 ml-5 mr-2">
                    <div class="card-header mt-2 ">
                        <h4><strong>Product Description</strong></h4>
                        <!-- <div class="card-body text-dark productDescription">
                    </div> -->
                    </div>
                    <div class="card-body">
                        <span class="productDescription"></span>
                    </div>
                </div>
            </div>


            <!-- sold by card -->
            <div class="row">
                <div class="card shadow mt-4 ml-5 mr-2 mb-4 ">
                    <div class="card-header mt-2 ">
                        <h4><strong>Sold By</strong></h4>

                    </div>
                    <!-- item1 -->
                    <div class="card m-2">
                        <div class="card-body">
                            <div class="container">

                                <div class="row">

                                    <div class="col-4">
                                        <img src="" width="100%" class="mb-1 soldByVendorImage">
                                        <p class="d-flex justify-content-center mt-2"><span class="soldByVendorRatingStar"></span></p>

                                    </div>

                                    <div class="col">
                                        <div class="row">
                                            <p class="truncate2-newHomePage" style="margin-right:1rem;"><b><span class="soldByVendorName"></span></b>
                                                <!-- <p>
                                            <details>
                                                <summary style="margin-top:-2rem; margin-left:0rem; margin-right:1rem;">
                                                    <span id="open">view more</span>
                                                    <span id="close" style="margin-top:-2rem; margin-left:1rem; ">view less</span>
                                                </summary>
                                                <p class="" style="margin-top:-2rem; margin-left:0rem; margin-right:1rem;"><b>special dum biryani served with special raita</b></p>
                                            </details>-->
                                            <p class="truncate-newHomePage"><span class="soldByVendorAddress"></span></p>
                                            <p class="">Minimum Enquiry : <span class="soldByMinimumOrder"></span></p>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <!-- reviews card -->
            <div class="row">


                <div class="card shadow p-3 bg-white rounded  ml-5 mr-2 mb-4" id="review">
                    <div class="card-header mt-2 ">
                        <!-- data-target="#reviewModal" -->
                        <h4><strong>Reviews</strong> <button type="button" class="btn btn-sm text-white float-right commentReviewUserCheck" data-toggle="modal" style="background: rgba(244, 148, 49);">Leave a Comment</button></h4>
                    </div>
                    <div class="card-body">
                        <div class="row d-flex justify-content-center">
                            <!-- displaying reviews from database -->
                            <div class="productReviews">


                            </div>

                            <div class="collapse mb-3" id="productReviewsMore">
                            </div>


                            <div class="text-center">
                                <button class="btn reviewViewMoreBtn  text-white" id="readbtn" type="button" data-bs-toggle="collapse" data-bs-target="#productReviewsMore" aria-expanded="false" aria-controls="collapseExample" style="background: rgba(244, 148, 49);">View More</button>
                                <span class="reviewError"></span>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>

        <div class="col-5  ml-2 mr-2">

            <!-- cart -->
            <div class="card shadow p-3 mb-5 mt-4  ml-5 mr-2 bg-white rounded cartSticky">
                <div class="card-header">
                    <h4><strong>Book Now</strong></h4>
                </div>
                <div class="card-body text-dark">
                    <h4 class="card-title">Quantity :</h4>
                    <div class="d-flex justify-content-center mb-3 mr-4">
                        <button class="btn btn-link px-2 minusButton" id="minusBtnId" onclick="this.parentNode.querySelector('input[type=number]').stepDown()">
                            <svg xmlns="http://www.w3.org/2000/svg" style="color: rgba(244, 148, 49);margin-left : 2rem;" width=30 height=30 fill=" currentColor" class="bi bi-dash-square-fill" viewBox="0 0 16 16">
                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1z" />
                            </svg>
                        </button>

                        <input min="0" max="6" name="quantity" value="1" type="number" class="form-control form-control-sm quantityButton qntyTextField" style="width: 45px; height:45px" disabled />

                        <button class="btn btn-link px-2 plusButton " id="plusBtnId" onclick="this.parentNode.querySelector('input[type=number]').stepUp()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="color: rgba(244, 148, 49);" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                            </svg>
                        </button>
                    </div>
                    <div class=" text-center">
                        <p id="maxErrorMsg" class="text-danger center"></p>
                        <button type="button" class="btn btn-primary btn-lg btn-block addToCartProductSummary" style="width:70%">Add To Cart</button>
                        <button type="button" class="btn btn-lg btn-block text-white buyNow" style="background: rgba(244, 148, 49);width:70%">Buy Now</button>
                    </div>
                </div>
            </div>


        </div>

    </div>
</div>
</div>`;

export const productSummaryPageNestHubView = `<div class="pt-5 mt-4">
<div class="row " style="background: rgba(244, 148, 49);">


    <div class="col-5">
        <img src="" class="img-load landingPageProductImage" style="margin-left:10rem; margin-top:1rem; margin-bottom:1rem;" width="100%">
    </div>

    <div class="col">
        <div class="container">
            <div class="row d-flex justify-content-start " style="margin-left:-8rem;">
                <div class="col text-white">
                    <p class=" " style="font-size: 20px; margin-top:1rem; "><b><span class="productName"></span></b></p>
                    <p style="font-size: 16px; "><span class="soldByVendorName"></span></p>
                    <p style="font-size: 16px; "><span class="soldByVendorAddress"></span></p>


                </div>

                <div class="col ml-5 " style="margin-left:-8rem;">
                    <div class="col-3  d-md-block ml-5">
                        <button type="button" class="btn btn-light btn-sm px-3 mb-2 mt-5 ml-5" data-bs-toggle="modal" data-bs-target="#viewProductPhotos" style="color: rgba(244, 148, 49); font-size: 16px; margin-top:1rem; ">View photos</button>
                    </div>
                </div>

                <div class="col " style="margin-left:-2rem;">
                    <div class="col  d-md-block ">
                        <a href="#review"><button type="button" class="btn btn-light btn-sm px-3 mb-2 ml-2 mt-5" style="color: rgba(244, 148, 49); font-size: 16px; ">View reviews</button></a>
                    </div>
                </div>


            </div>


            <div class="row d-flex justify-content-start text-white " style="font-size: 16px; margin-left:-8rem;">

                <div class="col">
                    <p class="fw-normal text-white ratingsLineMobView"><span class="productRating"></span></p>
                </div>

                <div class="col ">
                    <p class="fw-normal text-white ratingsLineMobView" style="margin-left:-6rem;"><b>₹ <span class="productPrice"></span></b></p>
                </div>

                <div class="col ">
                    <!-- <p class="m-1 px-2 text-white fw-normal" style="background-color:green; margin-left:-4rem;">Veg</p> -->
                    <!-- <button type="button" class="btn btn-sm px-4 text-white" style="background-color:green; margin-left:-14rem; margin-bottom:1rem;">Veg</button> -->
                    <!-- <p class="d-flex justify-content-center  text-white" style="background-color:green; margin-left:-14rem; margin-bottom:1rem; margin-right:20rem;">Veg</p> -->
                    <span class="vegOrNonVegSymbol"></span>

                </div>

            </div>

        </div>

    </div>

</div>

<div class="container ">
    <div class="row">

        <div class="col ml-5">

            <div class="row">
                <div class="card  shadow mt-4 ml-5 mr-2">
                    <div class="card-header mt-2 ">
                        <h4><strong>Product Description</strong></h4>
                        <!-- <div class="card-body text-dark productDescription">
                </div> -->
                    </div>
                    <div class="card-body">
                        <span class="productDescription"></span>
                    </div>
                </div>
            </div>


            <!-- sold by card -->
            <div class="row">


                <div class="card shadow mt-4 ml-5 mr-2 mb-4 ">
                    <div class="card-header mt-2 ">
                        <h4><strong>Sold By</strong></h4>

                    </div>
                    <!-- item1 -->
                    <div class="card m-2">
                        <div class="card-body">
                            <div class="container">

                                <div class="row">

                                    <div class="col-4">
                                        <img src="" width="100%" class="mb-1 soldByVendorImage">
                                        <p class="d-flex justify-content-center "><span class="soldByVendorRatingStar"></span></p>

                                    </div>

                                    <div class="col">
                                        <div class="row">


                                            <p class="truncate2-newHomePage" style="margin-right:1rem;"><b><span class="soldByVendorName"></span></b>
                                                <!-- <p>
                                        <details>
                                            <summary style="margin-top:-2rem; margin-left:0rem; margin-right:1rem;">
                                                <span id="open">view more</span>
                                                <span id="close" style="margin-top:-2rem; margin-left:1rem; ">view less</span>
                                            </summary>
                                            <p class="" style="margin-top:-2rem; margin-left:0rem; margin-right:1rem;"><b>special dum biryani served with special raita</b></p>
                                        </details> -->





                                            <p class="truncate-newHomePage"><span class="soldByVendorAddress"></span></p>
                                            <p class="">Minimum Enquiry : <span class="soldByMinimumOrder"></span></p>


                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <!-- reviews card -->
            <div class="row">


                <div class="card shadow p-3 bg-white rounded  ml-5 mr-2 mb-4  " id="review">
                    <div class="card-header mt-2 ">
                        <!-- data-target="#reviewModal" -->
                        <h4><strong>Reviews</strong> <button type="button" class="btn btn-sm text-white float-right commentReviewUserCheck" data-toggle="modal" style="background: rgba(244, 148, 49);">Leave a Comment</button></h4>
                    </div>
                    <div class="card-body">
                        <!-- displaying reviews from database -->
                        <div class="productReviews">


                        </div>

                        <div class="collapse mb-3" id="productReviewsMore">
                        </div>


                        <div class="text-center">
                            <button class="btn reviewViewMoreBtn  text-white" id="readbtn" type="button" data-bs-toggle="collapse" data-bs-target="#productReviewsMore" aria-expanded="false" aria-controls="collapseExample" style="background: rgba(244, 148, 49);">View More</button>
                            <span class="reviewError"></span>
                        </div>
                    </div>

                </div>


            </div>
        </div>

        <div class="col-5  ml-2 mr-2">

            <!-- cart -->
            <div class="card shadow p-3 mb-5 mt-4  ml-5 mr-2 bg-white rounded cartSticky">
                <div class="card-header">
                    <h4><strong>Book Now</strong></h4>
                </div>
                <div class="card-body text-dark">
                    <h5 class="card-title">Quantity :</h5>
                    <div class="d-flex justify-content-center mb-3 mr-4">
                        <button class="btn btn-link px-2 minusButton" id="minusBtnId" onclick="this.parentNode.querySelector('input[type=number]').stepDown()">
                            <svg xmlns="http://www.w3.org/2000/svg" style="color: rgba(244, 148, 49); margin-left : 2rem;" width=30 height=30 fill=" currentColor" class="bi bi-dash-square-fill" viewBox="0 0 16 16">
                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1z" />
                            </svg>
                        </button>

                        <input min="0" max="6" name="quantity" value="1" type="number" class="form-control form-control-sm quantityButton qntyTextField" style="width: 45px; height:45px" disabled />

                        <button class="btn btn-link px-2 plusButton " id="plusBtnId" onclick="this.parentNode.querySelector('input[type=number]').stepUp()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="color: rgba(244, 148, 49);" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                            </svg>
                        </button>

                    </div>


                    <div class=" text-center">
                        <p id="maxErrorMsg" class="text-danger center"></p>
                        <button type="button" class="btn btn-primary btn-lg btn-block addToCartProductSummary" style="width:70%">Add To Cart</button>
                    <button type="button" class="btn btn-lg btn-block text-white buyNow" style="background: rgba(244, 148, 49);width:70%">Buy Now</button>
                    <!-- <button type="button" class="btn btn-sm btn-block addToCartProductSummary px-3 py-2 text-white" style="background: rgba(244, 148, 49);">Added</button> -->
                    </div>
                </div>
            </div>


        </div>

    </div>
</div>
</div>`;

export const productSummaryPageTabView = `<div class="pt-5 mt-4">
<div class="row " style="background: rgba(244, 148, 49);">
    <div class="col-5">
        <img src="" class="img-load landingPageProductImage" style="margin-left:8rem; margin-top:1rem; margin-bottom:1rem;" width="100%">
    </div>

    <div class="col">
        <div class="container">
            <div class="row d-flex justify-content-start " style="margin-left:-8rem;">
                <div class="col text-white">
                    <p class=" " style="font-size: 12px; margin-top:1rem; "><b><span class="productName"></span></b></p>
                    <p style="font-size: 12px; "><span class="soldByVendorName"></span></p>
                    <p style="font-size: 12px; "><span class="soldByVendorAddress"></span></p>
                </div>

                <div class="col ml-1 " style="margin-left:-5rem;">
                    <div class="col d-md-block ml-5">
                        <button type="button" class="btn btn-light btn-sm px-3 mb-2 mt-5 ml-5" data-bs-toggle="modal" data-bs-target="#viewProductPhotos" style="color: rgba(244, 148, 49); font-size: 16px; margin-top:1rem; ">View photos</button>
                    </div>
                </div>

                <div class="col " style="margin-left:-2rem;">
                    <div class="col  d-md-block ">
                        <a href="##review"><button type="button" class="btn btn-light btn-sm px-3 mb-2 ml-2 mt-5" style="color: rgba(244, 148, 49); font-size: 16px; ">View reviews</button></a>
                    </div>
                </div>
            </div>

            <div class="row d-flex justify-content-start text-white " style="font-size: 12px; margin-left:-8rem;">

                <div class="col">
                    <p class="fw-normal text-white ratingsLineMobView"><span class="productRating"></span></p>
                </div>

                <div class="col ">
                    <p class="fw-normal text-white ratingsLineMobView" style="margin-left:-5rem;"><b>₹ <span class="productPrice"></span></b></p>
                </div>

                <div class="col">
                    <!-- <p class="m-1 px-2 text-white fw-normal" style="background-color:green; margin-left:-4rem;">Veg</p> -->
                    <!-- <button type="button" class="btn btn-sm px-4 text-white" style="background-color:green; margin-left:-14rem; margin-bottom:1rem;">Veg</button> -->
                    <!-- <p class="d-flex justify-content-center  text-white" style="background-color:green; margin-left:-14rem; margin-bottom:1rem; margin-right:20rem;">Veg</p> -->
                    <span class="vegOrNonVegSymbol"></span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container ">
    <div class="row">

        <div class="col ml-5">

            <div class="row">
                <div class="card  shadow mt-4 ml-5 mr-2">
                    <div class="card-header mt-2 ">
                        <h4><strong>Product Description</strong></h4>
                        <!-- <div class="card-body text-dark productDescription">
                </div> -->
                    </div>
                    <div class="card-body">
                        <span class="productDescription"></span>
                    </div>
                </div>
            </div>


            <!-- sold by card -->
            <div class="row">
                <div class="card shadow mt-4 ml-5 mr-2 mb-4 ">
                    <div class="card-header mt-2 ">
                        <h4><strong>Sold By</strong></h4>

                    </div>
                    <!-- item1 -->
                    <div class="card m-2">
                        <div class="card-body">
                            <div class="container">
                                <div class="row">
                                    <div class="col-4">
                                        <img src="" width="100%" class="mb-1 soldByVendorImage">
                                        <p class="d-flex justify-content-center "><span class="soldByVendorRatingStar"></span></p>
                                    </div>

                                    <div class="col">
                                        <div class="row">
                                            <p class="truncate2-newHomePage" style="margin-right:1rem;"><b><span class="soldByVendorName"></span></b>
                                                <!-- <p>
                                        <details>
                                            <summary style="margin-top:-2rem; margin-left:0rem; margin-right:1rem;">
                                                <span id="open">view more</span>
                                                <span id="close" style="margin-top:-2rem; margin-left:1rem; ">view less</span>
                                            </summary>
                                            <p class="" style="margin-top:-2rem; margin-left:0rem; margin-right:1rem;"><b>special dum biryani served with special raita</b></p>
                                        </details> -->
                                            <p class="truncate-newHomePage"><span class="soldByVendorAddress"></span></p>
                                            <p class="">Minimum Enquiry : <span class="soldByMinimumOrder"></span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- reviews card -->
            <div class="row">
                <div class="card shadow p-3 bg-white rounded  ml-5 mr-2 mb-4  " id="review">
                    <div class="card-header mt-2 ">
                        <!-- data-target="#reviewModal" -->
                        <h4><strong>Reviews</strong> <button type="button" class="btn btn-sm text-white float-right commentReviewUserCheck" data-toggle="modal" style="background: rgba(244, 148, 49);">Leave a Comment</button></h4>
                    </div>
                    <div class="card-body">
                        <div class="row d-flex justify-content-center">
                            <!-- displaying reviews from database -->
                            <div class="productReviews">
                            </div>
                            <div class="collapse mb-3" id="productReviewsMore">
                            </div>
                            <div class="text-center">
                                <button class="btn reviewViewMoreBtn  text-white" id="readbtn" type="button" data-bs-toggle="collapse" data-bs-target="#productReviewsMore" aria-expanded="false" aria-controls="collapseExample" style="background: rgba(244, 148, 49);">View More</button>
                                <span class="reviewError"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-5  ml-2 mr-2">
            <!-- cart -->
            <div class="card shadow p-3 mb-5 mt-4  ml-5 mr-2 bg-white rounded cartSticky">
                <div class="card-header">
                    <h4><strong>Book Now</strong></h4>
                </div>
                <div class="card-body text-dark">
                    <h5 class="card-title">Quantity :</h5>
                    <div class="d-flex justify-content-center mb-3 mr-4">
                        <button class="btn btn-link px-2 minusButton" id="minusBtnId" onclick="this.parentNode.querySelector('input[type=number]').stepDown()">
                            <svg xmlns="http://www.w3.org/2000/svg" style="color: rgba(244, 148, 49); margin-left : 2rem;" width=30 height=30 fill=" currentColor" class="bi bi-dash-square-fill" viewBox="0 0 16 16">
                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1z" />
                            </svg>
                        </button>

                        <input min="0" max="6" name="quantity" value="1" type="number" class="form-control form-control-sm quantityButton qntyTextField" style="width: 45px; height:45px" disabled />

                        <button class="btn btn-link px-2 plusButton " id="plusBtnId" onclick="this.parentNode.querySelector('input[type=number]').stepUp()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="color: rgba(244, 148, 49);" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                            </svg>
                        </button>

                    </div>
                    <div class=" text-center">
                        <p id="maxErrorMsg" class="text-danger center"></p>
                        <button type="button" class="btn btn-primary btn-lg btn-block addToCartProductSummary" style="width:70%">Add To Cart</button>
                        <button type="button" class="btn btn-lg btn-block text-white buyNow" style="background: rgba(244, 148, 49);width:70%">Buy Now</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>`;

export const productSummaryPageMobileView = `<div class="pt-4 mb-5 mt-5">
<div class="row" style="background: rgba(244, 148, 49);">
    <div class="m-1">

        <div class="row">

            <div class="col-4">
                <img src="" class="img-load m-1 landingPageProductImage" width="100%">
            </div>

            <div class="col">
                <div class="row">
                    <div class="col text-white">
                        <p class="truncate-newHomePage mt-2 font-size: 16px;  "><b><span class="productName"></span></b></p>
                        <p class="truncate-newHomePage font-size: 16px; "><span class="soldByVendorName"></span></p>
                        <p class="truncate-newHomePage font-size: 16px; "><span class="soldByVendorAddress"></span></p>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">


            <div class="col-4">
                <p class="d-flex justify-content-center"><span class="productRating"></span></p>
            </div>


            <div class="col text-white">

                <div class="row">
                    <div class="col-7">
                        <p class="font-size: 4px; "><b>₹ <span class="productPrice"></span></b></p>
                    </div>
                    <div class="col-5">
                        <!-- <p class="d-flex justify-content-center  text-white" style="background-color:green; margin-left:-4rem; margin-right:4rem;">Veg</p> -->
                        <span class="vegOrNonVegSymbol"></span>
                    </div>


                </div>

            </div>

        </div>



        <div class="row">


            <div class="col-4">
                <!-- <p class="d-flex justify-content-center text-white" style="font-size: 14px;   ">(Ratings)</p> -->
            </div>


            <div class="col text-white">

                <div class="row">
                    <div class="col-6">
                        <button type="button" class="btn btn-light btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#viewProductPhotos" style="color:rgba(244, 148, 49); ">View photos</button>

                    </div>
                    <div class="col-6">
                        <a href="#review"><button type="button" class="btn btn-light btn-sm mb-2 mr-1" style="color:rgba(244, 148, 49); ">View reviews</button></a>

                    </div>
                </div>

            </div>

        </div>



    </div>
</div>


<div class="ml-2 mr-4">
    <div class="row">
        <div class="card shadow mt-4 ml-3 mr-3">
            <div class="card-header mt-2 ">
                <h4><strong>Product Description</strong></h4>
                <!-- <div class="card-body text-dark productDescription">
                </div> -->
            </div>
            <div class="card-body">
                <span class="productDescription"></span>
            </div>
        </div>
    </div>

    <!-- cart -->
    <div class="row">
        <div class="card shadow p-3 mb-2 mt-4  ml-3 mr-3 bg-white rounded cartSticky">
            <div class="card-header">
                <h4><strong>Book Now</strong></h4>
            </div>
            <div class="card-body text-dark">
                <h5 class="card-title">Quantity :</h5>
                <div class="d-flex justify-content-center mb-3 mr-4">
                    <button class="btn btn-link px-2 minusButton" id="minusBtnId" onclick="this.parentNode.querySelector('input[type=number]').stepDown()">
                        <svg xmlns="http://www.w3.org/2000/svg" style="color: rgba(244, 148, 49); margin-left : 2rem;" width=30 height=30 fill=" currentColor" class="bi bi-dash-square-fill" viewBox="0 0 16 16">
                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1z" />
                        </svg>
                    </button>

                    <input min="0" max="6" name="quantity" value="1" type="number" class="form-control form-control-sm quantityButton qntyTextField" style="width: 45px; height:45px" disabled />

                    <button class="btn btn-link px-2 plusButton " id="plusBtnId" onclick="this.parentNode.querySelector('input[type=number]').stepUp()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="color: rgba(244, 148, 49);" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                        </svg>
                    </button>

                </div>


                <div class=" text-center">
                    <p id="maxErrorMsg" class="text-danger center"></p>
                    <button type="button" class="btn btn-primary btn-lg btn-block addToCartProductSummary" style="width:70%">Add To Cart</button>
                    <button type="button" class="btn btn-lg btn-block text-white buyNow" style="background: rgba(244, 148, 49);width:70%">Buy Now</button>
                    <!-- <button type="button" class="btn btn-sm btn-block addToCartProductSummary px-3 py-2 text-white" style="background: rgba(244, 148, 49);">Added</button> -->
                </div>
            </div>
        </div>
    </div>


    <!-- sold by card -->
    <div class="row">

        <div class="card shadow mt-2 ml-3 mr-1 mb-2 ">
            <div class="card-header mt-2 ">
                <h4><strong>Sold By</strong></h4>

            </div>
            <!-- item1 -->
            <div class="card m-1">
                <div class="card-body">
                    <div class="">

                        <div class="row">

                            <div class="col-4">
                                <img class="soldByVendorImage" src="" width="100%">
                                <p class="d-flex justify-content-center mt-2 mb-1"><span class="soldByVendorRatingStar"></span></p>

                            </div>

                            <div class="col">
                                <div class="row">

                                    <p class="truncate2-newHomePage" style="font-size:14px;"><b><span class="soldByVendorName"></span></b></p>

                                    <p class="truncate-newHomePage" style="font-size:14px;"><span class="soldByVendorAddress"></span></p>
                                    <p class="" style="font-size:14px;">Minimum Enquiry : <span class="soldByMinimumOrder"></span></p>

                                </div>




                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>


    <!-- reviews card -->
    <div class="row">


        <div class="card shadow p-3 bg-white rounded ml-3 mr-3 mb-4 mt-2 " id="review">
            <div class="card-header mt-2 ">
                <!-- data-target="#reviewModal" -->
                <h4><strong>Reviews</strong> <button type="button" class="btn btn-sm text-white float-right commentReviewUserCheck" data-toggle="modal" style="background: rgba(244, 148, 49);">Leave a Comment</button></h4>
            </div>
            <div class="card-body">
                <div class="row d-flex justify-content-center">
                    <!-- displaying reviews from database -->
                    <div class="productReviews">


                    </div>

                    <div class="collapse mb-3" id="productReviewsMore">
                    </div>


                    <div class="text-center">
                        <button class="btn reviewViewMoreBtn  text-white" id="readbtn" type="button" data-bs-toggle="collapse" data-bs-target="#productReviewsMore" aria-expanded="false" aria-controls="collapseExample" style="background: rgba(244, 148, 49);">View More</button>
                        <span class="reviewError"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>`;
