const {
  S3Client,
  GetObjectCommand,
  PutObjectCommand,
  HeadObjectCommand,
  CopyObjectCommand,
  DeleteObjectCommand,
  ListObjectsV2Command,
} = require("@aws-sdk/client-s3");
const { getSignedUrl } = require("@aws-sdk/s3-request-presigner");
import { Buffer } from "buffer";
// import S3Client from "@aws-sdk/client-s3"
// import GetObjectCommand from "@aws-sdk/client-s3"
// import getSignedUrl from "@aws-sdk/s3-request-presigner"
//ENVIROMENT VARIABLES
// `process.env` is the one defined in the webpack's DefinePlugin
const envVariables = process.env;

// Read vars from envVariables object
const {
  AWS_S3_KEY,
  AWS_S3_SECRET,
  AWS_S3_REGION,
  AWS_S3_PRIVATE_BUCKET,
  AWS_S3_PUBLIC_BUCKET,
} = envVariables;

const s3Client = new S3Client({
  region: "ap-south-1",
  credentials: {
    accessKeyId: AWS_S3_KEY,
    secretAccessKey: AWS_S3_SECRET,
  },
});

export default class s3Util {
  getObjectURL = async (key) => {
    var url;
    var urlValid = false;
    console.log("executing getObjectURL", key);
    const command = new GetObjectCommand({
      Bucket: AWS_S3_PRIVATE_BUCKET,
      Key: key,
    });
    url = new URL(await getSignedUrl(s3Client, command));
    console.log("URL for " + key, url);

    //checks if the image url actually exists
    await fetch(url)
      .then((response) => {
        if (response.ok) {
          console.log("Image URL is valid");
          urlValid = true;
        } else {
          console.log("Image URL is invalid");
        }
      })
      .catch((error) => {
        console.error("Error validating image URL:", error);
      });

    if (urlValid === true) {
      var image = new URL(url);
      return image;
    } else {
      return await this.getObjectURL(`Test/no image.jpg`);
    }
    //return url;
  };

  getS3Image = async (
    imgObjectURL,
    imgTagId = null,
    imgTagClassName = null
  ) => {
    var s3Img = await this.getObjectURL(imgObjectURL);
    // $("#my_image").attr("src","second.jpg");
    console.log(
      "xxxxxxximgObjectURL",
      imgObjectURL,
      "xxxxxxximgTagId",
      imgTagId,
      "xxxxxxximgTagClassName",
      imgTagClassName
    );

    console.log("s3Img", s3Img);

    if (imgTagId) {
      document.getElementById(imgTagId).src = s3Img;
    } else if (imgTagClassName) {
      var imgTagClassNameJQUERY = "." + imgTagClassName;
      var noOfImgTags = $(imgTagClassNameJQUERY).length;
      for (var i = 0; i < noOfImgTags; i++)
        document.getElementsByClassName(imgTagClassName)[i].src = s3Img;
    } else {
      console.error("Image Tag Class or Id not found");
    }
  };

  uploadImageToS3 = async (file, key) => {
    // Read the file content
    const fileContent = await file.arrayBuffer();

    // Create a command to upload the file to S3
    const uploadParams = {
      Bucket: AWS_S3_PRIVATE_BUCKET,
      Key: key,
      Body: fileContent,
      ContentType: file.type, // Specify the content type based on the file type
    };

    const uploadCommand = new PutObjectCommand(uploadParams);

    console.log("uploadImageToS3");

    try {
      // Execute the command to upload the file
      const uploadResult = await s3Client.send(uploadCommand, {
        customUserAgent: "putObject",
      });
      console.log("File uploaded successfully:", uploadResult);
      return uploadResult;
    } catch (error) {
      console.error("Error uploading file to S3:", error);
      throw error;
    }
  };

  uploadCropperImageToS3 = async (base64, key) => {
    // Ensure that you POST a base64 data to your server.
    // Let's assume the variable "base64" is one.
    const base64Data = new Buffer.from(
      base64.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );

    // Getting the file type, ie: jpeg, png or gif
    const type = base64.split(";")[0].split("/")[1];

    const uploadParams = {
      Bucket: AWS_S3_PRIVATE_BUCKET,
      Key: key,
      Body: base64Data,
      ContentEncoding: "base64", // required
      ContentType: `image/${type}`, //required
    };

    const uploadCommand = new PutObjectCommand(uploadParams);

    console.log("uploadCroppedImageToS3");

    try {
      // Execute the command to upload the file
      const uploadResult = await s3Client.send(uploadCommand, {
        customUserAgent: "putObject",
      });
      console.log("File uploaded successfully:", uploadResult);
      //return uploadResult;
    } catch (error) {
      console.error("Error uploading file to S3:", error);
      throw error;
    }

    //return location;
  };

  // async function getMainCategoryImage() {
  //     console.log('URL for Restaurant.jpg', await getObjectURL("Main Categories/Restaurant.jpg"));
  // }
  // async function getSubCategoryImage() {
  //     console.log('URL for Restaurant.jpg', await getObjectURL("Sub Categories/Restaurant.jpg"));
  // }
  // async function getSpecialtyImage() {
  //     console.log('URL for Restaurant.jpg', await getObjectURL("Specialties for each Category/Restaurant.jpg"));
  // }

  // async function init() {
  //     console.log('URL for Restaurant.jpg', await getObjectURL("Main Categories/Restaurant.jpg"));
  // }
  // init()

  renameFolder = async (oldFolderName, newFolderName) => {
    // const s3Client = new S3Client();
    const sourceBucket = AWS_S3_PRIVATE_BUCKET;
    const sourceFolder = `${oldFolderName}/`;
    const destinationFolder = `${newFolderName}/`;

    try {
      // List objects in the source folder
      const listObjectsResponse = await s3Client.send(
        new ListObjectsV2Command({
          Bucket: sourceBucket,
          Prefix: sourceFolder,
        })
      );

      // Move each object to the destination folder
      for (const object of listObjectsResponse.Contents) {
        const copyObjectParams = {
          Bucket: sourceBucket,
          CopySource: `/${sourceBucket}/${object.Key}`,
          Key: `${destinationFolder}${object.Key.substring(
            sourceFolder.length
          )}`,
        };

        await s3Client.send(new CopyObjectCommand(copyObjectParams));

        const deleteObjectParams = {
          Bucket: sourceBucket,
          Key: object.Key,
        };

        await s3Client.send(new DeleteObjectCommand(deleteObjectParams));
      }

      console.log("All objects moved successfully.");
    } catch (error) {
      console.error("Error moving objects:", error);
    }
  };
}
