var paymentDetails;
export default class vendorServicing_paymentStatus {
  constructor() {
    this.bindEvents();
  }

  bindEvents = () => {
    console.log("in status bind");

    //New Functions for cashFree
    this.checkCashfreePayment();
    $(".paymentInvoice").on("click", this.generatePaymentInvoice);
    $(".paymentReceipt").on("click", this.generatePaymentReceipt);
  };

  checkCashfreePayment = () => {
    console.log("Calling api");

    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    var payment_order_id = urlParams.get("payment_order_id");
    console.log(payment_order_id);

    $.ajax({
      url: baseURL + "api/CashfreePayments/getOrder",
      type: "POST",
      data: {
        Payment_ID: payment_order_id,
      },
      success: (result) => {
        this.paymentStatusSuccess(result);
      },
      error: (result) => {
        result = JSON.parse(result);
        console.log(result);
        console.log(result.order_status);
      },
    });
    $.ajax({
      url: baseURL + "api/Vendor/getOrderDetails",
      type: "POST",
      data: {
        Payment_ID: payment_order_id,
      },
      success: (result) => {
        paymentDetails = result[0];
        document.getElementById("PaymentNo").innerHTML =
          result[0].Cashfree_Reference_ID;
        let Date = result[0].Payment_Date_time.substring(0, 10);
        document.getElementById("PaymentDate").innerHTML = Date;
        document.getElementById("PaymentType").innerHTML =
          result[0].Payment_Mode;
      },
      error: (result) => {
        console.log(result);
      },
    });
  };

  paymentStatusSuccess = (result) => {
    result = JSON.parse(result);
    console.log(result);
    console.log(result.order_status);
    var status = result.order_status;
    // var status = "PENDING";

    var item = ``;
    if (status == "PAID") {
      console.log("Paid Flow");
      item = `
      <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16" style="color:green">
        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
      </svg>
  
      <h1 class="card-title">Payment Complete</h1>
      &nbsp;
  
      <h5 class="card-text">Thank you. Your payment has been successfull.</h5>
    `;
    } else {
      console.log("Pending Flow");
      item = `
      <svg xmlns="http://www.w3.org/2000/svg" width="180" height="180" fill="currentColor" class="bi bi-check2-circle py-3" viewBox="0 0 16 16" style="color:orange">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
    </svg>
    &nbsp;
    &nbsp;

    <h1 class="card-title">Transaction Pending</h1>
    &nbsp;

    <h5 class="card-text">Do try checking the status of the transaction again</h5>
    `;
    }
    var statusBox = document.getElementsByClassName("statusBox")[0];
    console.log(statusBox);

    statusBox.innerHTML = item;
  };

  generatePaymentInvoice = () => {
    console.log(paymentDetails);
    var vendorDetails, vendorAddressDetails;
    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/getVendorDetails",
      async: false,
      headers: { Authorization: X_API_KEY },
      success: (details) => {
        vendorDetails = details;
      },
    });

    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/GetAddress",
      async: false,
      headers: { Authorization: X_API_KEY },
      success: (details) => {
        vendorAddressDetails = details;
      },
    });

    console.log(vendorDetails);
    console.log(vendorAddressDetails);

    $.ajax({
      url: baseURL + "api/GeneratePdf/sponsoredPaymentInvoice",
      type: "POST",
      async: false,
      data: {
        Customer_Name: vendorDetails.Business_Name,
        Customer_Email: vendorDetails.Email_Address,
        Customer_Phone: vendorDetails.Phone_Number,
        Customer_State: vendorAddressDetails.State,
        Customer_Pincode: vendorAddressDetails.Pincode,
        Customer_Country: "India",
        Invoice_Number: paymentDetails.Cashfree_Reference_ID, //Change this later to invoice number
        Order_Number: paymentDetails.Payment_ID, //Change in table to add this column
        Date_of_Booking: paymentDetails.createdAt,
        SAC_Code: vendorDetails.Phone_Number,
        Price: paymentDetails.Payment_Amount,
        Discount: 0, //Change in table to add this column
        CGST: 0, //Change in table to add this column
        SGST: 0, //Change in table to add this column
        Net_Amount: paymentDetails.Payment_Amount,
      },
      success: () => {
        console.log("success");
        window.open(baseURL + "CreatePdf", "_blank");
      },
      error: () => {
        console.log("Invoice could not be generated");
      },
    });
  };

  generatePaymentReceipt = () => {
    console.log(paymentDetails);
    var vendorDetails, vendorAddressDetails;
    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/getVendorDetails",
      async: false,
      headers: { Authorization: X_API_KEY },
      success: (details) => {
        vendorDetails = details;
      },
    });

    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/GetAddress",
      async: false,
      headers: { Authorization: X_API_KEY },
      success: (details) => {
        vendorAddressDetails = details;
      },
    });

    console.log(vendorDetails);
    console.log(vendorAddressDetails);

    $.ajax({
      url: baseURL + "api/GeneratePdf/sponsoredPaymentReceipt",
      type: "POST",
      async: false,
      data: {
        Customer_Name: vendorDetails.Business_Name,
        Customer_Email: vendorDetails.Email_Address,
        Customer_Phone: vendorDetails.Phone_Number,
        Customer_State: vendorAddressDetails.State,
        Customer_Pincode: vendorAddressDetails.Pincode,
        Customer_Country: "India",
        Invoice_Number: paymentDetails.Payment_ID, //Change this later to invoice number
        Order_Number: paymentDetails.Payment_ID, //Change in table to add this column
        Date_of_Booking: paymentDetails.createdAt,
        SAC_Code: vendorDetails.Phone_Number,
        Price: paymentDetails.Payment_Amount,
        Discount: paymentDetails.Payment_Amount, //Change in table to add this column
        CGST: paymentDetails.Payment_Amount, //Change in table to add this column
        SGST: paymentDetails.Payment_Amount, //Change in table to add this column
        Net_Amount: paymentDetails.Payment_Amount,
      },
      success: () => {
        console.log("success");
        window.open(baseURL + "CreatePdf", "_blank");
      },
      error: () => {
        console.log("Invoice could not be generated");
      },
    });
  };
}
