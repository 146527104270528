import { type } from "jquery";
import Util from "../helpers/util";
import cartUtil from "../helpers/cartUtil";
import {
  productListingPageNestHubMaxView,
  productListingPageNestHubView,
  productListingPageTabView,
  productListingPageMobileView
} from "./pageVariables";

var CartObject = new cartUtil();
var UtilObject = new Util();

var vendorProducts;
var vendor;
var CartDetails = {};
var vendorFromOrder;
var flagForVendorCheck = false;
var pID;
var businessImageArray = [];
var screenWidth;

import s3Util from "../helpers/s3Util";
var s3Object = new s3Util();
export default class productListing {
  constructor() {
    //calculating width and putting the appropriate code
    screenWidth = $(window).width();
    console.log(screenWidth);
    if (screenWidth < 576) {
      //$(".displayDuringDesktopView").addClass("d-none");
      $(".displayDuringMobView").removeClass("d-none");
    } else {
      //$(".displayDuringMobView").addClass("d-none");
      $(".displayDuringDesktopView").removeClass("d-none");
    }

    //ProductListingPage Part

    //desktop view class
    this.displayDuringDesktopView = $(".displayDuringDesktopView");

    //mobile view class
    this.displayDuringMobView = $(".displayDuringMobView");

    //add
    // this.addBtnForItem1 = $("#addBtnForItem1");

    //+
    // this.plusBtnId = $("#plusBtnId");

    //-
    // this.minusBtnId = $("#minusBtnId");

    //cartEmpty
    this.cartEmpty = $("#cartEmpty");

    //ItemsInCart
    this.ItemsAddedInCart = $("#ItemsAddedInCart");

    //text-field
    this.qntyTextField = $(".qntyTextField");

    //max error msg
    // this.maxErrorMsg = $("#maxErrorMsg");

    //Vendor_ID
    this.Vendor_ID = $(".ProductListingPage").attr("value");
    console.log(this.Vendor_ID);

    //div for description
    this.writtenPart = $(".writtenPart");

    //for integration
    this.grid = $(".ProductListingPage");

    $("a.continueShoppingBtn").on("click", this.maintainFlow);

    this.noOfProductsInCart = CartObject.cartCheck();
    console.log(this.noOfProductsInCart);
    vendorFromOrder = this.noOfProductsInCart.vendor;
    $("span.cartCount").html(`${this.noOfProductsInCart.Products.length}`);

    var allCookies = UtilObject.getAllCookies();
    console.log(allCookies);

    //Get Cookies from Util Helper Class
    CartDetails = UtilObject.getAllCookies();

    //Registering Events
    if (this.Vendor_ID == "") {
      $(".emptyProductListingPage").removeClass("d-none");
    } else {
      $(".ProductListingPage").removeClass("d-none");
      this.bindEvents();
    }
  }

  maintainFlow = () => {
    var allCookies = UtilObject.getAllCookies();
    console.log(allCookies);
    //window.location = allCookies.PREVIOUS_PAGE;
    history.go(-1);
  };

  bindEvents = () => {
    //to adjust content according to different screen sizes
    var w = $(window).width();
    console.log(w);

    if (w < 710) {
      this.writtenPart.removeClass("col");
    } else {
      this.writtenPart.addClass("col");
    }

    // this.addToCartBtn(this.addItemInCart)
    // this.addItemInCart();
    // this.addBtnForItem1.on("click", this.addItemInCart);
    // this.minusBtnId.on("click", this.minusItemFunction);
    // this.plusBtnId.on("click", this.plusItemFunction);

    //Calling vendor details on load
    //$(window).on("load", $.ready.then(this.getVendorDetails));
    $(window).on("load", $.ready.then(this.calOwlCarousel));
    $(window).on(
      "load",
      $.ready.then(this.getVendorDetailsWithMultipleCategories)
    );
    //$(window).on("load", $.ready.then(this.getVendorImages));
    //calling commomon buttons
    // this.addToCartBtn.on("click", this.addItemInCart);

    $("button.btn.keepOldCart").on("click", () => {
      window.$("#showCartModal").modal("hide");
      console.log("Keeping Old cart");
    });

    $("button.btn.startNewCart").on("click", () => {
      window.$("#showCartModal").modal("hide");
      this.deleteEntireCart();
      flagForVendorCheck = true;
      this.addItemtoCartAfterVendorCheck();
      // setTimeout(() => {
      //   window.location.reload();
      // }, 50);
    });

    $(".deleteCart").on("click", () => {
      window.$("#deleteCartModal").modal("show");
    });

    $("button.btn.emptyCart").on("click", () => {
      window.$("#deleteCartModal").modal("hide");
      this.deleteEntireCart();
      // setTimeout(() => {
      //   window.location.reload();
      // }, 50);
    });
  };

  calOwlCarousel = () => {
    console.log("inside");
    $(".carousel_se_06_carousel").owlCarousel({
      nav: false,
      dots: false,
      mouseDrag: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 3
        },
        480: {
          items: 3
        },
        767: {
          items: 5
        },
        992: {
          items: 6
        },
        1200: {
          items: 8
        }
      }
    });
  };

  //refreshes the count on header and footer
  refreshCartCount = () => {
    console.log("Refreshing Cart Count");
    this.noOfProductsInCart = CartObject.cartCheck();
    console.log(this.noOfProductsInCart);
    console.log(this.noOfProductsInCart.Products.length);
    $("span.cartCount").html(`${this.noOfProductsInCart.Products.length}`);

    var productsNotAvailable = this.noOfProductsInCart.Products_Not_Available;
    if (productsNotAvailable.length > 0) {
      productsNotAvailable.forEach((product) => {
        var mainProductDetail = product.Product_Details;
        if (mainProductDetail.Quantity == 0) {
          $(`.addToCartBtn[value="${product.Product_ID}"]`).replaceWith(
            `<button type="button" class="btn btn-sm btn-secondary text-white w-100" value="${product.Product_ID}">NOT AVAILABLE</button>`
          );
        }
      });
    }

    //Refreshing Cart On UI
    if (this.noOfProductsInCart.Products.length > 0) {
      $(".vendorName").text(this.noOfProductsInCart.vendor.Business_Name);
      $(".cart-items").empty();
      var productsInCart = this.noOfProductsInCart.Products;
      productsInCart.forEach((product) => {
        var cartItem = `<div class="row d-flex align-items-center pb-1 ${product.Product_ID}" value="${product.Product_ID}">
      <div class="col-4 pt-1">
      ${product.ProductName}
      </div>
      <div class="col-4 p-0 d-flex justify-content-center itemPrice">
          <ul class="pagination m-0">
              <li class="page-item"><button class="page-link minusButton" value="${product.Product_ID}">-
                  </button>
              </li>

              <li class="page-item"><button min="0" max="${product.Product_Details.Quantity}" name="${product.Product_ID}" value="1" type="number" class="page-link quantityButton qntyTextField" disabled>${product.Quantity}</button>
              </li>

              <li class="page-item"> <button class="page-link plusButton" value="${product.Product_ID}">+

                  </button>
              </li>
          </ul>
      </div>
      <div class="col-4 pt-1 d-flex justify-content-center">
          ₹ ${product.Price}
      </div>
      <span class="text-danger maxErrorMsg" value="${product.Product_ID}"></span>
  </div>`;

        $(".cart-items").append(cartItem);

        //var button = `<button type="button" class="btn btn-sm text-white px-4 mt-2 w-100 ${product.Product_ID}" style="background: rgba(244, 148, 49); margin-left: 0rem;">ADDED</button>`;

        // //Vendor Availability Button check
        // if (checkIfVendorAvailable == false) {
        //   var button = `<button class="btn text-white px-5" id="${product.Product_ID}" style="background: rgba(192,192,192); margin-left:-5rem;" type="button">N/A</button>`;
        // } else {
        //   var button = `<button class="btn btn-xl text-white px-5 addToCartBtn ${product.Product_ID}" style="background: rgba(244, 148, 49);" type="button">Add</button>`;
        // }
        // //Vendor Availability Button check ends
        // var button = `<button type="button" class="btn btn-sm text-white w-100 addedToCart" style="background: rgba(244, 148, 49);" value="${product.Product_ID}">ADDED</button>`;
        // $(`.addToCartBtn[value="${product.Product_ID}"]`).replaceWith(button);
      });
      $(".plusButton").off("click");
      $(".plusButton").on("click", this.plusItemFunction);

      $(".minusButton").off("click");
      $(".minusButton").on("click", this.minusItemFunction);
    } else {
      if (this.noOfProductsInCart.vendor != null) {
        this.deleteEntireCart();
      }
    }
  };

  // getCookie = (name) => {
  //   let matches = document.cookie.match(
  //     new RegExp(
  //       "(?:^|; )" +
  //         name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
  //         "=([^;]*)"
  //     )
  //   );
  //   return matches ? decodeURIComponent(matches[1]) : undefined;
  // };

  // cartCheck = () => {
  //   console.log("Cart Check");

  //   let orderIdFromPHP;

  //   var customerId = UtilObject.getCookie("CUSTOMER_ID");
  //   var orderId = UtilObject.getCookie("ORDER_ID");

  //   // var customerId = (document.cookie.match(
  //   //   /^(?:.;)?\s*customerID\s=\s*([^;]+)(?:.*)?$/
  //   // ) || [, null])[1];
  //   // console.log(customerId);

  //   // var orderid = (document.cookie.match(
  //   //   /^(?:.;)?\s*orderID\s=\s*([^;]+)(?:.*)?$/
  //   // ) || [, null])[1];

  //   if (orderId == null) {
  //     // $.ajax({
  //     //   url: baseURL + "api/customer/Orders/setRandomSessionId",
  //     //   type: "POST",
  //     //   async: false,
  //     //   data: {
  //     //     name: "ORDER_ID",
  //     //   },
  //     //   success: (result) => {
  //     //     console.log(result);
  //     //     UtilObject.createCookie("ORDER_ID", result.ORDER_ID, 1);
  //     //     orderIdFromPHP = result.ORDER_ID;
  //     //   },
  //     //   error: (err) => {
  //     //     console.log(err);
  //     //   },
  //     // });

  //     $.ajax({
  //       url: baseURL + "api/customer/Orders/setSessionId",
  //       type: "POST",
  //       async: false,
  //       data: {
  //         data: {
  //           ORDER_ID: "0063bb49-4caf-4de4-a424-cb03c5be2dbe",
  //         },
  //       },
  //       success: (result) => {
  //         console.log(result);
  //         UtilObject.createCookie("ORDER_ID", result.ORDER_ID, 30);
  //         orderIdFromPHP = result.ORDER_ID;
  //       },
  //       error: (err) => {
  //         console.log(err);
  //       },
  //     });
  //   }

  //   console.log(orderId);

  //   var allCookies = UtilObject.getAllCookies();
  //   console.log(allCookies);

  //   $.ajax({
  //     url: baseURL + "api/customer/Orders/getAllSessionValues",
  //     type: "GET",
  //     async: false,
  //     success: (result) => {
  //       console.log(result);
  //       orderIdFromPHP = result.ORDER_ID;
  //     },
  //     error: (err) => {
  //       console.log(err);
  //     },
  //   });

  //   CartDetails["ORDER_ID"] = UtilObject.getCookie("CUSTOMER_ID");
  //   CartDetails["CUSTOMER_ID"] = UtilObject.getCookie("ORDER_ID");

  //   this.cartCheck();
  // };

  // cartCheck = () => {
  //   $.ajax({
  //     url: baseURL + "api/customer/Cart/CartDetails",
  //     type: "GET",
  //     async: false,
  //     success: (result) => {
  //       console.log(result);
  //       if (result.code != 18) {
  //         this.showProductsInCart(result.Products);
  //       }
  //     },
  //     error: (err) => {
  //       console.log(err.message);
  //     },
  //   });
  // };

  deleteEntireCart = () => {
    CartDetails = UtilObject.getAllCookies();
    console.log(CartDetails);
    console.log(CartDetails["ORDER_ID"]);
    console.log("Deleting Entire Cart");
    $.ajax({
      url: baseURL + "api/customer/Cart/DeleteCart",
      type: "POST",
      async: false,
      data: {
        Order_ID: CartDetails["ORDER_ID"]
      },
      headers: { Authorization: X_API_KEY },
      success: (result) => {
        console.log(result);
        this.refreshCartCount();
        this.ItemsAddedInCart.addClass("d-none");
        $("#checkOutBtn").addClass("d-none");
        this.cartEmpty.removeClass("d-none");
        this.refreshCart();
      },
      error: (err) => {
        console.log(err);
      }
    });
  };

  refreshCart = () => {
    var itemsInCart = $("div.cart-items").children();
    console.log(itemsInCart);
    if (itemsInCart.length > 0) {
      for (let index = 0; index < itemsInCart.length; index++) {
        var item = itemsInCart[index];
        var itemId = $(item).attr("value");
        console.log(itemId);
        //Replacing Added with Add
        $(`.addedToCart[value='${itemId}']`).replaceWith(
          `<button type="button" class="btn btn-sm text-white w-100 addToCartBtn" style="background: rgba(244, 148, 49);" value="${itemId}">ADD</button>`
        );
      }
      $(".addToCartBtn").off("click");
      $(".addToCartBtn").on("click", this.addItemInCart);
    }
    $(".cart-items").empty();
  };

  //this is to add/update cart in db
  addtoCart = (product, type) => {
    console.log(product);
    console.log(this.Vendor_ID);
    console.log(vendor);
    console.log(vendor.vendor.Vendor_ID);

    CartObject.cartCheck();

    console.log("adding to Cart");

    UtilObject.getPhpCookies();

    $.ajax({
      url: baseURL + "api/customer/Cart/AddtoCart",
      type: "POST",
      async: false,
      data: {
        Vendor_ID: vendor.vendor.Vendor_ID,
        Product_ID: product.Product_ID,
        Quantity: product.Quantity,
        Type: type
      },
      headers: { Authorization: X_API_KEY },
      success: (result) => {
        console.log(result);
        if (type == "ADD") {
          this.addItemToCartUI(product);
        }
        this.refreshCartCount();
      },
      error: (err) => {
        var errorData = err.responseJSON;
        console.log(errorData);
        $(".addToCartErrorMessage").html(errorData.Msg);
        window.$("#addToCartError").modal("show");
        if (errorData.code == "12") {
          if (type == "ADD") {
            $(`.addToCartBtn[value="${product.Product_ID}"]`).replaceWith(
              `<button type="button" class="btn btn-sm btn-secondary text-white w-100" value="${product.Product_ID}">NOT AVAILABLE</button>`
            );
          }
        }
        this.refreshCartCount();
      }
    });
  };

  showProductsInCart = (products) => {
    this.cartEmpty.addClass("d-none");
    this.ItemsAddedInCart.removeClass("d-none");
    $("#checkOutBtn").removeClass("d-none");

    console.log("Showing Products in cart");

    $(".vendorName").html(`${vendorFromOrder.Business_Name}`);

    console.log(products);
    $(".cart-items").empty();
    products.forEach((product) => {
      console.log(product);
      //   var cartItem = `<div class="row ${product.Product_ID}">
      //     <div class="col">
      //         ${product.ProductName}
      //     </div>
      //     <div class="col text-right itemPrice">
      //         <div class="d-flex flex-row">
      //             <button class="btn btn-link px-2 minusButton" onclick="this.parentNode.querySelector('input[type=number]').stepDown()">
      //                 <svg xmlns="http://www.w3.org/2000/svg" style="margin-left : 2rem;" width=30 height=30 fill="currentColor" class="bi bi-dash-square-fill" viewBox="0 0 16 16">
      //                     <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1z" />
      //                 </svg>
      //             </button>

      //             <!-- need to disable text field -->
      //             <input min="0" max="5" name="quantity" value="${product.Quantity}" type="number" class="form-control form-control-sm quantityButton qntyTextField" style="width: 45px; height:45px" disabled />

      //             <button class="btn btn-link px-2 plusButton" onclick="this.parentNode.querySelector('input[type=number]').stepUp()">
      //                 <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
      //                     <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
      //                 </svg>
      //             </button>

      //         </div>
      //     </div>
      //     <div class="col">
      //         Rs ${product.Price}
      //     </div>
      //     <span class="text-danger maxErrorMsg"></span>

      // </div>`;

      var cartItem = `<div class="row align-items-center pb-1 ${product.Product_ID}" value="${product.Product_ID}">
      <div class="col-4 pt-1">
      ${product.ProductName}
      </div>
      <div class="col-4 p-0 d-flex justify-content-center itemPrice">
          <ul class="pagination m-0">
              <li class="page-item"><button class="page-link minusButton" value="${product.Product_ID}">-
                  </button>
              </li>

              <li class="page-item"><button min="0" max="${product.Product_Details.Quantity}" name="${product.Product_ID}" value="1" type="number" class="page-link quantityButton qntyTextField" disabled>${product.Quantity}</button>
              </li>

              <li class="page-item"> <button class="page-link plusButton" value="${product.Product_ID}">+

                  </button>
              </li>
          </ul>
      </div>
      <div class="col-4 pt-1 d-flex justify-content-center">
          ₹ ${product.Price}
      </div>
      <span class="text-danger maxErrorMsg" value="${product.Product_ID}"></span>
  </div>`;

      $(".cart-items").append(cartItem);

      //var button = `<button type="button" class="btn btn-sm text-white px-4 mt-2 w-100 ${product.Product_ID}" style="background: rgba(244, 148, 49); margin-left: 0rem;">ADDED</button>`;
      var button = `<button type="button" class="btn btn-sm text-white w-100 addedToCart" style="background: rgba(244, 148, 49);" value="${product.Product_ID}">ADDED</button>`;
      // //Vendor Availability Button check
      // if (checkIfVendorAvailable == false) {
      //   var button = `<button class="btn text-white px-5" id="${product.Product_ID}" style="background: rgba(192,192,192); margin-left:-5rem;" type="button">N/A</button>`;
      // } else {
      //   var button = `<button class="btn btn-xl text-white px-5 addToCartBtn ${product.Product_ID}" style="background: rgba(244, 148, 49);" type="button">Add</button>`;
      // }
      // //Vendor Availability Button check ends

      $(`.addToCartBtn[value="${product.Product_ID}"]`).replaceWith(button);
    });
    $(".plusButton").off("click");
    $(".plusButton").on("click", this.plusItemFunction);

    $(".minusButton").off("click");
    $(".minusButton").on("click", this.minusItemFunction);

    // var itemsInCartCount = $("div.cart-items").children().length;
    // $("span.cartCount").html(`${itemsInCartCount}`);

    //Updating Number of Items in the Cart
    this.refreshCartCount();
  };

  //this is to show added item to UI
  addItemInCart = (event) => {
    console.log(CartDetails);

    console.log(event);
    console.log("try for adding item.");
    if (event != undefined) {
      pID = event.currentTarget.value;
    }
    console.log(pID);
    // console.log(this.addToCartBtn);

    console.log(vendorFromOrder);
    console.log(this.Vendor_ID);

    if (vendorFromOrder == null) {
      this.addItemtoCartAfterVendorCheck();
    } else {
      if (vendorFromOrder.Vendor_ID != this.Vendor_ID) {
        window.$("#showCartModal").modal("show");
      } else {
        this.addItemtoCartAfterVendorCheck();
      }
    }
    this.refreshCartCount();
  };

  //plusItemFunction
  plusItemFunction = (event) => {
    console.log("try for + item");

    console.log(event);

    var productID = event.currentTarget.value;
    console.log(productID);

    //console.log($(event.currentTarget).siblings(".qntyTextField").val());

    var itemQuantityField = $(`.qntyTextField[name="${productID}"]`);
    console.log(itemQuantityField);

    var itemCount = parseInt(itemQuantityField[0].innerHTML);
    console.log(itemCount);

    var maxItemQuantity = parseInt($(itemQuantityField[0]).attr("max"));
    console.log(maxItemQuantity);

    var itemsInCartCount = $("div.cart-items").children().length;
    console.log(itemsInCartCount);

    var p = {};

    // this.qntyTextField.val().disabled = true;
    p.Product_ID = productID;
    p.Quantity = itemCount + 1;
    p.Total_Items = this.noOfProductsInCart.Products.length;
    console.log(p);

    if (p.Total_Items == 0) {
      this.addtoCart(p, "ADD");
    } else {
      this.addtoCart(p, "UPDATE_QTY");
    }
  };

  //miuusItemFunction
  minusItemFunction = (event) => {
    console.log("reducing quantity of item");
    console.log(event);

    var productID = event.currentTarget.value;
    console.log(productID);

    //console.log($(event.currentTarget).siblings(".qntyTextField").val());

    var itemQuantityField = $(`.qntyTextField[name="${productID}"]`);
    console.log(itemQuantityField);

    var itemCount = parseInt(itemQuantityField[0].innerHTML);
    console.log(itemCount);

    var itemsInCartCount = $("div.cart-items").children().length;
    console.log(itemsInCartCount);

    var p = {};

    //validation for - btn
    if (itemCount - 1 == 0) {
      //Removing Item from Cart
      console.log("removing item from cart and changing added to add");

      $(`div.row.${productID}`).remove();

      //Replacing Added with Add
      $(`.addedToCart[value='${productID}']`).replaceWith(
        `<button type="button" class="btn btn-sm text-white w-100 addToCartBtn" style="background: rgba(244, 148, 49);" value="${productID}">ADD</button>`
      );
      $(".addToCartBtn").off("click");
      $(".addToCartBtn").on("click", this.addItemInCart);

      //If Cart is Empty
      itemsInCartCount = $("div.cart-items").children().length;
      console.log(itemsInCartCount);
      if (itemsInCartCount == 0) {
        console.log("Cart Empty");
        this.cartEmpty.removeClass("d-none");
        this.ItemsAddedInCart.addClass("d-none");
        $("#checkOutBtn").addClass("d-none");
        this.deleteEntireCart();
        //this.refreshCartCount();
      } else {
        p.Product_ID = productID;
        p.Quantity = itemCount - 1;
        p.Total_Items = itemsInCartCount;
        console.log(p);
        this.addtoCart(p, "UPDATE_QTY");
        //this.refreshCartCount();
      }
      console.log(itemsInCartCount);
    } else {
      $(`.maxErrorMsg[value='${productID}']`).html("");
      p.Product_ID = productID;
      p.Quantity = itemCount - 1;
      p.Total_Items = itemsInCartCount;
      console.log(p);
      this.addtoCart(p, "UPDATE_QTY");
      //this.refreshCartCount();
      //$(itemQuantityField[0]).html(itemCount - 1);
    }

    this.refreshCartCount();
  };

  //api

  // allow products/servicies to be added to the cart
  // getVendorDetails = () => {
  //   console.log(this.Vendor_ID);
  //   $.ajax({
  //     url: baseURL + "api/customer/ProductSummary/getVendorImages",
  //     type: "POST",
  //     data: {
  //       Vendor_ID: this.Vendor_ID,
  //     },
  //     success: this.showVendorDetails,
  //     error: this.showVendorDetails,
  //   });
  // };

  showVendorDetails = async (res) => {
    screenWidth = $(window).width();
    console.log(res);

    $(".vendorName").html(`${res.vendor.Business_Name}`);

    vendorProducts = res.products;
    vendor = res;

    //for delivery option
    if (res.delivery.length > 0) {
      var deliveryName = res.delivery[0].Picklist_display_value;

      //if kms is less than 1 hide kms
      if (res.delivery[0].Distance_kms > 0) {
        var distanceKms = res.delivery[0].Distance_kms + " kms";
      } else {
        var distanceKms = "";
      }
      //if Price is less than 1 hide price
      if (res.delivery[0].Price > 0) {
        var deliveryPrice = "₹" + res.delivery[0].Price;
      } else {
        var deliveryPrice = "";
      }

      $(".deliveryOptionBtn").show();
    }

    if (res.vendor.Vendor_Rating != null) {
      //for vendor review stars
      let vendorRating = res.vendor.Vendor_Rating;
      vendorRating = Math.round(vendorRating * 2) / 2;
      let vendorOutput = [];

      // Append all the filled whole stars
      for (var stars = vendorRating; stars >= 1; stars--) {
        vendorOutput.push(
          `<i class="fa fa-star" aria-hidden="true" style="color: white;"></i>`
        );
      }
      // If there is a half a star, append it
      if (stars == 0.5) {
        vendorOutput.push(
          `<i class="fa fa-star-half-o" aria-hidden="true" style="color: white;"></i>`
        );
      }
      // Fill the empty stars
      for (var stars = 5 - vendorRating; stars >= 1; stars--) {
        vendorOutput.push(
          `<i class="fa fa-star-o" aria-hidden="true" style="color: white;"></i>`
        );
      }
      vendorOutput = vendorOutput.join("");
      console.log("VENDOR STARS", screenWidth, vendorOutput);

      if (screenWidth < 576) {
        $(".vendorRatingMobile").html(
          res.vendor.Vendor_Rating +
            '<i class="fa fa-star" aria-hidden="true" style="color: white;"></i>'
        );
      } else {
        $(".vendorRating").html(vendorOutput);
      }
    } else {
      let vendorNoOutput = [];
      for (var stars = 5; stars >= 1; stars--) {
        vendorNoOutput.push(
          `<i class="fa-regular fa-star" aria-hidden="true" style="color: white;"></i>`
        );
      }
      vendorNoOutput = vendorNoOutput.join("");
      console.log("NO VENDOR STARS", screenWidth, vendorNoOutput);
      if (screenWidth < 576) {
        $(".vendorRatingMobile").html(vendorNoOutput);
      } else {
        $(".vendorRating").html(vendorNoOutput);
      }
    }

    //for availability
    var availability = res.vendor.Availability;
    var screenWidth = $(window).width();
    console.log(screenWidth);
    if (res.vendor.Vendor_Availability === true) {
      var venEndTime = availability.End_time;
      const timeString12hr = new Date(
        "1970-01-01T" + venEndTime + "Z"
      ).toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric"
      });
      if (screenWidth < 576) {
        $(".vendorAvailability").html(
          `<button type="button" class="btn btn-sm btn-success" disabled>OPEN</button>`
        );
        $(".vendorAvailabilityExtraMessage").html(
          `Closes at ${timeString12hr}`
        );
      } else {
        $(".vendorAvailability").html(
          `<button type="button" class="btn btn-success" disabled>OPEN</button>`
        );
        $(".vendorAvailabilityExtraMessage").html(
          `Closes at ${timeString12hr}`
        );
      }
    } else {
      var venStartTime = availability.Tomorrow.Start_time;
      const timeString12hr = new Date(
        "1970-01-01T" + venStartTime + "Z"
      ).toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric"
      });
      if (screenWidth < 576) {
        $(".vendorAvailability").html(
          `<button type="button" class="btn btn-sm btn-danger" disabled>CLOSED</button>`
        );
        $(".vendorAvailabilityExtraMessage").html(`Opens at ${timeString12hr}`);
      } else {
        $(".vendorAvailability").html(
          `<button type="button" class="btn btn-danger" disabled>CLOSED</button>`
        );
        $(".vendorAvailabilityExtraMessage").html(`Opens at ${timeString12hr}`);
      }
    }

    //New Integration code
    $(".vendorBusinessName").html(res.vendor.Business_Name);
    $(".vendorShortAddress").html(
      `${res.vendor.Address.City + ", " + res.vendor.Address.State}`
    );
    $(".vendorDescription").html(res.vendor.Specialities);
    $(".vendorMinOrder").html(res.vendor.Minimum_Order);
    $(".vendorDeliveryOptions").html(
      `${deliveryName} ${distanceKms} ${deliveryPrice}`
    );

    // if (res.vendor.Vendor_Rating > 0) {
    //   $(".vendorRatingMobile").html(
    //     res.vendor.Vendor_Rating +
    //       '<i class="fa fa-star" aria-hidden="true" style="color: white;"></i>'
    //   );
    // }

    var businessS3Img = await s3Object.getObjectURL(
      "Vendor/" + res.vendor.Image_URL + "/Document Images/Business Image/1.png"
    ); //element.Image_URL
    $(".vendorMainImage").attr("src", businessS3Img);

    var vendorCategories = res.categories;
    let i = 0;
    let status;
    let selecteditem;

    let finalCategoryMenu, menuStart, recommendedSection, menuSection, menuEnd;
    let itemListRecommended = [];
    let itemListMenu = [];
    var firstActiveItem;

    //hide categories if there is only 1
    if (vendorCategories.length == 0) {
      $(".noCategoriesForVendor").removeClass("d-none");
    } else {
      if (vendorCategories.length == 1) {
        $(".categories-product-listing").hide();
      }
      $(".categoriesForVendorsExists").removeClass("d-none");
      vendorCategories.forEach((category) => {
        //Making them Empty
        itemListRecommended = [];
        itemListMenu = [];
        firstActiveItem = "";
        selecteditem = "";

        console.log(category);

        if (i < 1) {
          //status = "checked";
          status = "active";
          firstActiveItem = "currentActiveCategory";
          selecteditem = "selected-item";
        }
        // var categoryItem = `
        //   <input type="radio" class="btn-check" name="btnradio" autocomplete="off" ${status}>
        //   <label class="btn btn-outline-warning" id="${category.Picklist_ID}" for="btnradio1">${category.Picklist_display_value}</label>`;

        //     var categoryItem = ` <li class="nav-item item">
        //     <a id="${category.Picklist_ID}" class="nav-link ${status} ${firstActiveItem}" aria-current="page" data-bs-toggle="button" autocomplete="off" aria-pressed="true" style="font-size:12px;">${category.Picklist_display_value}</a>
        // </li>`;

        //     //$(".vendorCategoriesButtons").append(categoryItem);

        //     $(".owl-carousel.testCase")
        //       .owlCarousel("add", categoryItem)
        //       .owlCarousel("update");

        //var categoryImg = await s3Object.getObjectURL(category.Icon_Url); //category.Icon_Url

        var categoryItem = `<div class="item" >
          <div class="col-md-12 wow fadeInUp delay-2 d-flex justify-content-center">
            <div id="${
              category.Picklist_ID
            }" class="vendorCategoriesButtons ${selecteditem} text-center zoom ${status} ${firstActiveItem}">
              <a class="btn-light rounded-circle" rel="noopener " style="margin-top:0.5rem;">
                <img src="" id="${
                  category.Picklist_ID + "forImg"
                }" class="img-fluid rounded-circle" alt="${
          category.Picklist_display_value
        }-icon">
              </a>
              <p class="truncate">${category.Picklist_display_value}</p>
            </div>
          </div>
        </div>`;

        $(".owl-carousel.vendor-categories-item")
          .owlCarousel("add", categoryItem)
          .owlCarousel("update");

        //Entire Menu Section
        menuStart = `<!--Menu Start for Category--> <span class="vendor-category-products ${category.Picklist_ID}">`;

        res.products.forEach((product) => {
          console.log(product);
          //var button = `<button class="btn btn-xl text-white px-5 addToCartBtn ${product.Product_ID}" style="background: rgba(244, 148, 49);" type="button">Add</button>`;
          //var button = `<button type="button" class="btn btn-sm text-white w-100 addToCartBtn" style="background: rgba(244, 148, 49);" value="${product.Product_ID}">ADD</button>`;

          if (
            product.Picklist_ID == category.Picklist_ID ||
            product.Picklist_parent_ID == category.Picklist_ID
          ) {
            //   var item = `<div class="card m-3">
            // <div class="card-body ">
            //     <div class="">

            //         <div class="row">

            //             <div class="col-4 ml-2">
            //             <a href="${baseURL}customer/productSummary/${product.Product_ID}">
            //               <img src="${baseURL}/assets/src/images/${product.Image_URL}" class="rounded float-start img-load" >
            //               </a>
            //             </div>

            //             <div class="col">

            //                 <div class="row">
            //                 <a href="${baseURL}customer/productSummary/${product.Product_ID}">
            //                     <div class="col ml-1">
            //                         <h5 class="card-title truncate3-productListing">${product.Product_Service_Name}</h5>
            //                         <p class="card-text truncate4-productListing" id="hideDescriptionForMobView">${product.Description}</p>
            //                     </div>
            //                     </a>
            //                 </div>
            //                 <div class="row ">
            //                     <div class="col ml-1">
            //                         <p class="card-text "><b>Rs ${product.Price}</b></p>
            //                     </div>
            //                     <div class="col">
            //                         ${button}
            //                     </div>
            //                 </div>

            //             </div>

            //         </div>

            //     </div>
            // </div>
            // </div>`;

            //           var item = `<div class="card m-2">
            //           <div class="card-body">
            //               <div class="container">

            //                   <div class="row">

            //                       <div class="col-3">
            //                       <a href="${baseURL}customer/productSummary/${product.Product_ID}">
            //                           <img src="${baseURL}/assets/src/images/${product.Image_URL}" width="100%">
            //                           </a>
            //                           ${button}

            //                       </div>

            //                       <div class="col">
            //                           <div class="row">
            //                           <a href="${baseURL}customer/productSummary/${product.Product_ID}">
            //                               <div class="col">
            //                                   <p class="truncate-newHomePage"><b>${product.Product_Service_Name}</b></p>
            //                                   <p class="truncate-newHomePage">${product.Description}</p>
            //                               </div>
            //                               </a>
            //                           </div>

            //                           <div class="row">
            //                               <div class="col">
            //                                   <img src="https://img.icons8.com/color/24/FD7E14/vegetarian-food-symbol.png" />
            //                               </div>
            //                               <div class="col d-flex justify-content-end mr-2">
            //                                   Rs ${product.Price}
            //                               </div>
            //                           </div>
            //                       </div>

            //                   </div>

            //               </div>
            //           </div>
            //       </div>
            // `;

            var color = "text-primary";
            var IsVegOrNonVeg = "";
            if (product.Veg_NonVeg_flag != false) {
              if (product.Veg_NonVeg_flag == "Non-Veg") {
                IsVegOrNonVeg = `<div class="col-4 d-flex justify-content-end pl-0">
                <h6 class="card-title text-danger">Non-Veg</h6>
                </div>`;
              } else {
                IsVegOrNonVeg = `
                <div class="col-4 d-flex justify-content-end pl-0">
                <h6 class="card-title text-success">Veg</h6>
                </div>`;
              }
            }

            // var businessImageArrayy = [];
            // var fullUrl = "";
            // for (var o = 1; o <= 3; o++) {
            //   fullUrl = product.Image_URL.replaceAll(
            //     "01.png",
            //     "0" + o + ".png?"
            //   );
            //   var businessS3Img = await s3Object.getObjectURL(fullUrl); //element.Image_URL
            //   businessImageArrayy[o - 1] = businessS3Img;
            // }
            // console.log(businessImageArrayy);
            // for (var o = 1; o <= 3; o++) {
            //   businessImageArrayy[o - 1] =
            //     S3BaseURLProd + "/" + product.Image_URL + Math.random();
            //   businessImageArrayy[o - 1] = businessImageArrayy[
            //     o - 1
            //   ].replaceAll("01.png", "0" + o + ".png?");
            // }

            if (product.Quantity == 0) {
              var productQuantityButton = `<button type="button" class="btn btn-sm btn-secondary text-white w-100" value="${pID}">NOT AVAILABLE</button>`;
            } else {
              var productQuantityButton = `<button type="button" class="btn btn-sm text-white w-100 addToCartBtn" style="background: rgba(244, 148, 49);" value="${product.Product_ID}">ADD</button>`;
            }

            var item = `<div class="card">
  <div class="row d-flex justify-content-start">
      <div class="col-3 vItemProduct">
      <a href="${baseURL}customer/productSummary/${product.product_URL}">
          <img src="" class="${product.Product_ID + "forImg"} rounded-3"></img>
          </a>
      </div>
      <div class="col">
          <div class="card-body vItemProduct">
              <div class="row d-flex align-items-end">
                  <div class="col d-flex justify-content-start pr-0">
                      <h5 class="card-title truncate-one-cart">${
                        product.Product_Service_Name
                      }</h5>
                  </div>
                  ${IsVegOrNonVeg}
              </div>
              <span class="my-2 card-text truncate-cart h-100">
              ${product.Description}
              </span>
              <div class="row pb-2">
                  <div class="col-md-3 col-6 d-flex justify-content-start pr-0">
                      ${productQuantityButton}
                  </div>
                  <div class="col-md-3 col-6 d-flex flex-column-reverse">
                      <h5>₹${product.Price}</h5>
                  </div>
              </div>
  
          </div>
      </div>
  </div>
            </div><br>`;
            //If it's a recomended Product
            console.log(item);
            if (product.Recommended_flag == 1) {
              itemListRecommended.push(item);
            }
            itemListMenu.push(item);
          }
        });

        if (itemListRecommended.length > 0) {
          //         recommendedSection = `<!-- recommended -->
          // <div class="card ${category.Picklist_ID}-recommended">
          //     <h5 class="card-header">Best Sellers</h5>
          //     <h6 class="card-text ml-3 mt-1" style="text-align:left; color:grey;"><span class="recommendedtotal">${itemListRecommended.length}</span> items</h6>
          //     <!-- item1 -->
          //     <!-- <div class=""></div> -->
          //     <div class="${category.Picklist_ID}-recommended-Products">`;

          // recommendedSection = `<!-- recommended -->
          // <div class="card mb-4 ml-5 mt-3 ${category.Picklist_ID}-recommended">
          //     <h5 class="card-header">Best Sellers</h5>
          //     <h6 class="card-text mt-1 ml-3" style="color:grey;"><span class="recommendedtotal">${itemListRecommended.length}</span> items</h6>`;

          // recommendedSection = `<div class="row">
          //     <div class="card pb-1 ${category.Picklist_ID}-recommended">
          //         <h5 class="card-header">Best Sellers</h5>
          //         <h6 class="card-text mt-1 ml-3" style="color:grey;"><span class="recommendedtotal">${itemListRecommended.length}</span> items</h6>`;

          //New Design
          recommendedSection = `<div class="row">
              <div class="${category.Picklist_ID}-recommended">
                  <h4 class="p-2">Best Sellers</h4>`;

          //   recommendedSection +=
          //     itemListRecommended.join(" ") +
          //     `</div>
          // </div>
          // <br>`;

          recommendedSection += itemListRecommended.join(" ") + `</div></div>`;
        } else {
          recommendedSection = "";
        }

        if (itemListMenu.length > 0) {
          //         menuSection = `<!-- recommended -->
          // <div class="card ${category.Picklist_ID}-menu">
          //     <h5 class="card-header">Menu</h5>
          //     <h6 class="card-text ml-3 mt-1" style="text-align:left; color:grey;"><span class="menutotal">${itemListMenu.length}</span> items</h6>
          //     <!-- item1 -->
          //     <!-- <div class=""></div> -->
          //     <div class="${category.Picklist_ID}-menu-Products">`;

          // menuSection = `<!-- Menu -->
          // <div class="card mb-4 ml-5 mt-3 ${category.Picklist_ID}-menu">
          //     <h5 class="card-header">Menu</h5>
          //     <h6 class="card-text mt-1 ml-3" style="color:grey;"><span class="menutotal">${itemListMenu.length}</span> items</h6>`;

          // menuSection = `<div class="row">
          //     <div class="card pb-1 ${category.Picklist_ID}-menu">
          //         <h5 class="card-header">Menu</h5>
          //         <h6 class="card-text mt-1 ml-3" style="color:grey;"><span class="menutotal">${itemListMenu.length}</span> items</h6>`;

          //new menu design
          menuSection = `<div class="row">
              <div class="pb-1 ${category.Picklist_ID}-menu">
                  <h4 class="p-2">Menu</h4>`;

          //   menuSection +=
          //     itemListMenu.join(" ") +
          //     `</div>
          // </div>`;

          menuSection += itemListMenu.join(" ") + `</div></div>`;
        } else {
          menuSection = "";
        }

        menuEnd = `</span> <!--Menu End for Category-->`;

        //final Menu for category with recomended and menu
        finalCategoryMenu =
          menuStart + recommendedSection + menuSection + menuEnd;

        console.log(finalCategoryMenu);

        $(".all-vendor-category-products").append(finalCategoryMenu);

        //Shows the initial category and hides the rest
        if (i >= 1) {
          //$(`span.vendor-category-products.${category.Picklist_ID}`).hide();
          $(`span.vendor-category-products.${category.Picklist_ID}`).addClass(
            "d-none"
          );
        }

        i += 1;
        status = "";
      });
    }

    console.log(itemListRecommended);
    console.log(itemListMenu);

    //Onclick Event For Vendor Categories Buttons
    $(".vendorCategoriesButtons").on("click", this.showVendorCategoriesDetails);

    //if there is no delivery option hide buttons
    if (res.delivery.length == 0) {
      $(".deliveryOptionBtn").hide();
    }

    $(".addToCartBtn").off("click");
    $(".addToCartBtn").on("click", this.addItemInCart);

    this.refreshCartCount();

    //Loading Products in Cart After All the menu is loaded to show the added button
    if (this.noOfProductsInCart.Products.length > 0) {
      this.showProductsInCart(this.noOfProductsInCart.Products);
    }
    //Loads S3 images for categories and products
    this.loadCategoryImages(res.categories);
    this.loadProductImages(res.products);
    this.vendorImageCarousel(res.vendor.Image_URL);
  };

  vendorImageCarousel = (ImageUrl) => {
    //getting carousel of images of vendor
    console.log(ImageUrl);
    var countUrl = "Vendor/" + ImageUrl + "/Document Images/Business Image/";
    $.ajax({
      url: baseURL + "api/customer/Account/imageCount",
      type: "POST",
      async: false,
      data: {
        Image_URL: countUrl
      },
      success: async (result) => {
        console.log("getting count");
        console.log(result);
        var imageCount = parseInt(result);
        var screenWidth = $(window).width();
        if (screenWidth < 576) {
          $(".moreImagesMobile").removeClass("d-none");
        } else {
          $(".moreImagesDesktop").removeClass("d-none");
        }
        for (var image = 1; image <= result; image++) {
          var active = "";
          var businessS3Img = await s3Object.getObjectURL(
            countUrl + `${image}.png`
          );
          if (image == 1) {
            active = "active";
          }
          $(".vendorImagesCarousel").append(
            `<div class="carousel-item ${active}" data-bs-interval="5000">
              <img src="${businessS3Img}" class="d-block w-100" alt="Vendor Images">
          </div>`
          );
        }

        // //vendor images s3 bucket
        // for (var j = 1; j <= result; j++) {
        //   businessImageArray[j - 1] =
        //     S3BaseURL +
        //     res.vendor.Image_URL +
        //     "/Document+Images/Business+Image" +
        //     j +
        //     ".png?" +
        //     Math.random();
        // }
        // console.log(businessImageArray);

        // //vendorimages
        // if (result == 1) {
        //   $(".vendorMainImage").attr("src", `${businessImageArray[0]}`);
        //   $(".vendorSecondImage").attr(
        //     "src",
        //     `${S3BaseURLProd + "/" + res.products[0].Image_URL}`
        //   );
        //   $(".vendorThirdImage").attr(
        //     "src",
        //     `${S3BaseURLProd + "/" + res.products[1].Image_URL}`
        //   );
        //   $(".imagesCount").hide();
        // } else if (result == 2) {
        //   $(".vendorMainImage").attr("src", `${businessImageArray[0]}`);
        //   $(".vendorSecondImage").attr("src", `${businessImageArray[1]}`);
        //   $(".vendorThirdImage").attr(
        //     "src",
        //     `${S3BaseURLProd + "/" + res.products[0].Image_URL}`
        //   );
        //   $(".imagesCount").hide();
        // } else {
        //   $(".vendorMainImage").attr("src", `${businessImageArray[0]}`);
        //   $(".vendorSecondImage").attr("src", `${businessImageArray[1]}`);
        //   $(".vendorThirdImage").attr("src", `${businessImageArray[2]}`);
        //   //image count
        //   // $(".imagesCount").html(`+${res.vendorImages.length - 3}`);
        // }

        // // //for vendor image carousel
        // // //for first active image
        // var activeitem = `<div class="carousel-item active ">
        //   <img src="${businessImageArray[1]}" class="d-block " alt="" width="100%">
        //   </div>`;

        // $(".vendorImageCarouselActiveItem").append(activeitem);

        // //for remaining images
        // businessImageArray.forEach((businessImageArray) => {
        //   var item = `
        //   <div class="carousel-item">
        //       <img src="${businessImageArray}" class="d-block " alt="" width="100%">
        //   </div>`;
        //   $(".vendorImageCarousel").append(item);
        // });

        // //image end
      },
      error: (result) => {
        console.log(result.message);
      }
    });

    //ending carousel of images of vendor
  };

  showVendorCategoriesDetails = (event) => {
    console.log(event);
    // var prevCategoryID = $("input[type=radio]:checked").next().attr("id");
    // $("input[type=radio]:checked").prop("checked", false);
    // $(event.target).prev().prop("checked", true);
    // var newCategoryID = $(event.target).attr("id");

    var prevCategoryID = $(".currentActiveCategory").attr("id");
    var newCategoryID = $(event.currentTarget).attr("id");

    console.log(prevCategoryID);
    console.log(newCategoryID);

    $(`span.${prevCategoryID}`).addClass("d-none");
    $(`span.${newCategoryID}`).removeClass("d-none");

    // //show and hide
    // $("span." + prevCategoryID).hide();
    // $("span." + newCategoryID).show();

    $(`div#${prevCategoryID}`).removeClass("currentActiveCategory");
    $(`div#${newCategoryID}`).addClass("currentActiveCategory");
    //Remove active class
    $(`div#${prevCategoryID}`).removeClass("active");
    $(`div#${newCategoryID}`).addClass("active");

    //show selected items
    $(`div#${prevCategoryID}`).removeClass("selected-item");
    $(`div#${newCategoryID}`).addClass("selected-item");
  };

  getVendorDetailsWithMultipleCategories = () => {
    console.log(this.Vendor_ID);
    $.ajax({
      url:
        baseURL +
        "api/customer/ProductSummary/getVendorDetailsWithMultipleCategories",
      type: "POST",
      async: false,
      data: {
        Vendor_ID: this.Vendor_ID
      },
      headers: { Authorization: X_API_KEY },
      success: (result) => {
        console.log(result);
        this.showVendorDetails(result);
      },
      error: (result) => {
        console.log(result.message);
      }
    });
  };

  addItemtoCartAfterVendorCheck = () => {
    if (flagForVendorCheck) {
      console.log("New Cart After Vendor Check");
    } else {
      console.log("Kept Old Cart After Vendor Check");
    }

    console.log(vendorProducts);
    //console.log(event);
    //var pID = event.currentTarget.getAttribute("id");
    console.log(pID);

    var itemsInCartCount = $("div.cart-items").children().length;

    vendorProducts.forEach((p) => {
      if (p.Product_ID == pID) {
        // Add to cart first and if added then show item in cart otherwise show error modal
        var productDetailsToSend = p;
        productDetailsToSend.Quantity = 1;
        itemsInCartCount = $("div.cart-items").children().length;
        productDetailsToSend.Total_Items = itemsInCartCount;
        console.log(productDetailsToSend);
        this.addtoCart(productDetailsToSend, "ADD");

        //   var cartItem = `<div class="row ${p.Product_ID}">
        //   <div class="col">
        //       ${p.Product_Service_Name}
        //   </div>
        //   <div class="col text-right itemPrice">
        //       <div class="d-flex flex-row">
        //           <button class="btn btn-link px-2 minusButton" onclick="this.parentNode.querySelector('input[type=number]').stepDown()">
        //               <svg xmlns="http://www.w3.org/2000/svg" style="margin-left : 2rem;" width=30 height=30 fill="currentColor" class="bi bi-dash-square-fill" viewBox="0 0 16 16">
        //                   <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1z" />
        //               </svg>
        //           </button>

        //           <!-- need to disable text field -->
        //           <input min="0" max="5" name="quantity" value="1" type="number" class="form-control form-control-sm quantityButton qntyTextField" style="width: 45px; height:45px" disabled />

        //           <button class="btn btn-link px-2 plusButton" onclick="this.parentNode.querySelector('input[type=number]').stepUp()">
        //               <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
        //                   <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
        //               </svg>
        //           </button>

        //       </div>
        //   </div>
        //   <div class="col">
        //       Rs ${p.Price}
        //   </div>
        //   <span class="text-danger maxErrorMsg"></span>

        // </div>`;

        // $.ajax({
        //   url: baseURL + "api/customer/Orders/AddtoCart",
        //   type: "POST",
        //   async: false,
        //   data: {
        //     data: {
        //       Order_ID:CartDetails.ORDER_ID,
        //       Vendor_ID:p.Vendor_ID,
        //       Customer_ID:CartDetails.CUSTOMER_ID,
        //       Product_ID:p.Product_ID,
        //       Quantity:1,
        //       Packaging_charge:,
        //       Flow:
        //     }
        //   }
        //   success: (result) => {
        //     console.log(result);
        //   },
        //   error: (err) => {
        //     console.log(err);
        //   },
        // });
      }
    });
  };

  addItemToCartUI = (product) => {
    console.log(product);

    //add cart and remove empty cart ui
    this.cartEmpty.addClass("d-none");
    this.ItemsAddedInCart.removeClass("d-none");
    $("#checkOutBtn").removeClass("d-none");

    var cartItem = `<div class="row align-items-center pb-1 ${product.Product_ID}" value="${product.Product_ID}">
      <div class="col-4 pt-1">
      ${product.Product_Service_Name}
      </div>
      <div class="col-4 p-0 d-flex justify-content-center itemPrice">
          <ul class="pagination m-0">
              <li class="page-item"><button class="page-link minusButton" value="${product.Product_ID}">-
                  </button>
              </li>

              <li class="page-item"><button min="0" max="${product.Quantity}" name="${product.Product_ID}" value="1" type="number" class="page-link quantityButton qntyTextField" disabled>1</button>
              </li>

              <li class="page-item"> <button class="page-link plusButton" value="${product.Product_ID}">+

                  </button>
              </li>
          </ul>
      </div>
      <div class="col-4 pt-1 d-flex justify-content-center">
          ₹ ${product.Price}
      </div>
      <span class="text-danger maxErrorMsg" value="${product.Product_ID}"></span>
  </div>`;

    $(".cart-items").append(cartItem);

    //Click events for plus and minus buttons
    $(".plusButton").off("click");
    $(".plusButton").on("click", this.plusItemFunction);

    $(".minusButton").off("click");
    $(".minusButton").on("click", this.minusItemFunction);

    //if text field =!0 then add->replace addressDetails
    console.log($(`.${product.Product_ID} .qntyTextField`).attr("value"));
    var quantityValue = $(`.${product.Product_ID} .qntyTextField`).attr(
      "value"
    );
    if (quantityValue != 0) {
      // $(`button.${pID}`).replaceWith(
      //   `<button type="button" class="btn btn-sm text-white px-4 mt-2 w-100 ${pID}" style="background: rgba(244, 148, 49); margin-left: 0rem;">ADDED</button>`
      // );
      $(`.addToCartBtn[value="${product.Product_ID}"]`).replaceWith(
        `<button type="button" class="btn btn-sm text-white w-100 addedToCart" style="background: rgba(244, 148, 49);" value="${product.Product_ID}">ADDED</button>`
      );
    }

    this.noOfProductsInCart = CartObject.cartCheck();
    vendorFromOrder = this.noOfProductsInCart.vendor;

    this.refreshCartCount();
  };

  loadProductImages = (res) => {
    //For each Product in the category
    console.log(res);
    res.forEach(async (elements) => {
      var customSpecID = elements.Product_ID + "forImg";
      var specialityS3Image = await s3Object.getObjectURL(elements.Image_URL);
      var elements = document.getElementsByClassName(customSpecID);
      [...elements].forEach((product) => {
        product.src = specialityS3Image;
      });
    });
  };

  loadCategoryImages = (res) => {
    //For each Category the VENDOR has
    console.log(res);
    res.forEach(async (elements) => {
      var customSpecID = elements.Picklist_ID + "forImg";
      var specialityS3Image = await s3Object.getObjectURL(elements.Icon_Url);
      document.getElementById(customSpecID).src = specialityS3Image;
    });
  };
}
