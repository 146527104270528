export default class custSerOffers {
  constructor() {
    //Registering Events
    this.bindEvents();
  }

  bindEvents = () => {
    console.log("inside new offers page bind ");

    //nesthub view
    // var nestHubPl = $(window).width();
    // console.log(nestHubPl);

    // if (nestHubPl < 1200) {
    //   console.log("Hub View2");
    // this.productListingNestHubView.removeClass("d-none");
    //   $(".productListingNestHubMaxView22").addClass("d-none");
    // } else if (nestHubPl > 1199) {
    //   console.log("Hub Max View2");
    // this.productListingNestHubMaxView.removeClass("d-none");
    //       $(".productListingNestHubView22").addClass("d-none");
    //     }
  };

  order_list = () => {
    $.ajax({
      url: baseURL + "api/customer/Cart/OrderList",
      type: "GET",
      async: false,
      headers: { Authorization: X_API_KEY },
      success: this.showPreviousOrdersOnServicingPage,
      error: (result) => {
        console.log(result);
      },
    });
  };
}
