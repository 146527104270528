export default class vendor_gst {
  constructor() {
    this.inputGSTRegistered = $("#inputGSTRegistered");
    this.inputCompositGSTRegistered = $("#inputCompositGSTRegistered");
    this.inputGSTNumber = $("#inputGSTNumber");
    this.inputPANNumber = $("#inputPANNumber");
    this.vendorHasGST = $(".vendorHasGST");
    this.vendorHasGSTdiv = $(".vendorHasGSTdiv");
    this.showGSTReg = $(".showGSTReg");
    this.vendorSpecialitiesBox = $(".vendorSpecialitiesBox");
    this.gstCertificateDiv = $("#gstCertificateDiv"); //required?
    this.vendorGSTPage = $("#gst_Deliery_OPHrsForm2");
    this.continueBtn = $(".vendorGSTChangesBtn");
    $("#inputGSTRegistered").on("click", this.GST_checkbox_chkd);

    this.bindEvents();
  }

  bindEvents = () => {
    this.inputCompositGSTRegistered.on("click", this.CompositGST_checkbox_chkd);
    console.log("in Vendor gst js");
    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/GetGST",
      headers: { Authorization: X_API_KEY },
      success: this.showGST,
    });

    this.continueBtn.on("click", this.fillGST);
  };
  // Validation of GST Number with a regex
  GSTNumber_validate = () => {
    console.log("in GSTNumber_validate validate");
    let flag = true;
    var GSTNumber = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (
      !(
        this.inputGSTNumber.val().length >= 14 &&
        this.inputGSTNumber.val().length <= 16
      ) ||
      !this.inputGSTNumber.val().match(GSTNumber)
    ) {
      this.inputGSTNumberError.html("Please enter correct GST Number.");

      flag = false;
    } else {
      this.inputGSTNumberError.html("");
    }

    return flag;
  };

  // Validation of PAN Number with a regex
  PANNumber_validate = () => {
    console.log("in PANNumber_validate validate");
    let flag = true;
    var PANNumber = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    //PAN number is a 10dig number
    if (
      !(
        this.inputPANNumber.val().length >= 5 &&
        this.inputPANNumber.val().length <= 15
      ) ||
      !this.inputPANNumber.val().match(PANNumber)
    ) {
      this.inputPANNumberError.html("Please enter correct PAN Number.");

      flag = false;
    } else {
      this.inputPANNumberError.html("");
    }

    return flag;
  };

  GST_checkbox_chkd = () => {
    if (this.inputGSTRegistered.is(":checked")) {
      // this.inputGSTRegisteredError.html("Gst is Checked ");
      this.inputGSTRegistered.val(function (val) {
        return 1;
      });
      // this.vendorHasGST.prop("disabled", true);
      this.vendorHasGSTdiv.removeClass("d-none");
      this.showGSTReg.removeClass("d-none");
      this.gstCertificateDiv.removeClass("d-none");
    } else {
      // this.inputGSTRegisteredError.html("Gst is UnChecked ");
      this.inputGSTRegistered.val(function (val) {
        return 0;
      });

      //Resetting the values of CompositGST, GSTNumber, PANNumber
      this.inputCompositGSTRegistered.val(function (val) {
        return null;
      });
      this.inputGSTNumber.val(function (val) {
        return null;
      });
      this.inputPANNumber.val(function (val) {
        return null;
      });
      // this.vendorHasGST.prop("disabled", false);
      this.vendorHasGSTdiv.addClass("d-none");
      this.showGSTReg.addClass("d-none");
      this.gstCertificateDiv.addClass("d-none");
    }
  };

  //Function to fill new gst data
  fillGST = () => {
    this.inputGSTRegisteredError = $("#inputGSTRegisteredError");
    this.inputCompositGSTRegisteredError = $(
      "#inputCompositGSTRegisteredError"
    );
    this.inputGSTRegistered = $("#inputGSTRegistered");
    this.inputCompositGSTRegistered = $("#inputCompositGSTRegistered");
    this.inputGSTNumberError = $("#inputGSTNumberError");
    this.inputPANNumberError = $("#inputPANNumberError");
    this.inputGSTNumber = $("#inputGSTNumber");
    this.inputPANNumber = $("#inputPANNumber");
    this.inputGSTNumberError = $("#inputGSTNumberError");
    this.inputPANNumberError = $("#inputPANNumberError");

    let isGSTNumberValidated = this.GSTNumber_validate();
    console.log(isGSTNumberValidated);

    if (isGSTNumberValidated) {
      console.log("GST Number is VALID");
      this.inputGSTNumberError.html("");
    }

    // Checking if Vendor,s  PAN Number is valid with a regex
    let isPANNumberValidated = this.PANNumber_validate();
    console.log(isPANNumberValidated);
    if (isPANNumberValidated) {
      console.log("PAN Number is VALID");
      this.inputPANNumberError.html("");
    }
    console.log(this.vendorGSTPage.serialize());
    if (isGSTNumberValidated && isPANNumberValidated) {
      //ajax call to update gst details fro the vendor
      $.ajax({
        type: "POST",
        url: baseURL + "api/Vendor/AddUpdateGST",
        headers: { Authorization: X_API_KEY },
        data: this.vendorGSTPage.serialize(),
      });
    }
  };
  CompositGST_checkbox_chkd = () => {
    if (this.inputCompositGSTRegistered.is(":checked")) {
      // this.inputCompositGSTRegisteredError.html("Composit Gst is Checked ");
      this.inputCompositGSTRegistered.val(function (val) {
        return 1;
      });
    } else {
      // this.inputCompositGSTRegisteredError.html("Composit Gst is UnChecked ");
      this.inputCompositGSTRegistered.val(function (val) {
        return 0;
      });
    }
  };
  // To Display GST Details of a Vendor
  showGST = (res) => {
    // $(".vendorHasGSTdiv").empty();
    console.log(res);
    var vendorGst = ``;
    if (res.GST_applicable == true) {
      document.getElementById("inputGSTRegistered").checked = true;
      this.GST_checkbox_chkd();
    }
    if (res.Composit_GST_applicable == "1") {
      document.getElementById("inputCompositGSTRegistered").checked = true;
    }
    $("#inputGSTNumber").val(res.GST_Number);
    $("#inputPANNumber").val(res.PAN_number);
  };
}
