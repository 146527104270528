export default class vendorServicing_dashboard {
  constructor() {
    this.bindEvents();
  }

  bindEvents = () => {
    console.log("Vendor Dashboard Bind");
    this.isVendorLoggedIn();

    $("#vendorOrderStatusBox").empty();
    this.get_days_weeks_data("Today");
    this.get_days_weeks_data("Week");
    this.get_days_weeks_data("TillDate");
    this.get_Vendor_Summary_For_VendorURL();

    $(".vendorDashToOrdersBtn").on("click", this.showVenderOrders);
  };

  isVendorLoggedIn = () => {
    {
      $.ajax({
        type: "GET",
        url: baseURL + "api/vendor/isVendorLoggedIn",

        headers: { Authorization: X_API_KEY },

        success: (res) => {
          if (res == false) {
            window.location = baseURL + "Vendor_login";
            alert("Please Login First");
          }
        }
      });
    }
  };

  get_days_weeks_data = (Filter_para) => {
    // Filter_para can take the values Today/Week/Month
    $.ajax({
      type: "POST",
      url: baseURL + "api/vendor/Dashboard",

      data: {
        Filter: Filter_para
      },

      headers: { Authorization: X_API_KEY },

      success: this.display_days_weeks_data
    });
  };
  display_days_weeks_data = (res) => {
    if (res.Filter == "TillDate") {
      this.display_orders_data(res);
    } else {
      var statusTable = `<div class="col-12 col-md-6 col-sm-12 ">
                            <div class="card mb-4" id="${
                              res.Filter
                            }OrderStatus">
                                <div class="card-header">
                                    <b>${
                                      res.Filter == "Today"
                                        ? "Todays"
                                        : "This Week"
                                    }</b>
                                </div>
                                <ul class="list-group list-group-flush">
                                    <div class="row">
                                        <div class="col-6 ">
                                            <li class="list-group-item">Total Enquiries</li>
                                        </div>
                                        <div class="col-6 ">
                                            <p class="list-group-item">${
                                              res.Total_order
                                            }</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 ">
                                            <li class="list-group-item">Enquiries Received</li>
                                        </div>
                                        <div class="col-6 ">
                                            <p class="list-group-item">${
                                              res.OrderReceived
                                            }</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 ">
                                            <li class="list-group-item">Enquiries Delivered</li>
                                        </div>
                                        <div class="col-6 ">
                                            <p class="list-group-item">${
                                              res.OrderDelivered
                                            }</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 ">
                                            <li class="list-group-item">Pending Enquiries</li>
                                        </div>
                                        <div class="col-6 ">
                                            <p class="list-group-item">${
                                              res.OrderPending
                                            }</p>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>`;
      $("#vendorOrderStatusBox").append(statusTable);
    }
  };

  display_orders_data = (res) => {
    $("#overallOrderStatusBox").empty();
    var statusTable = ` <div class="col-12  col-xs-6 col-sm-6 col-md-6 col-lg-3">
                            <div class="card text-center mt-4" id="totalOrders">
                                <div class="card-body ">
                                    <p class="card-title">Total <br> Enquiries</p>
                                    <p class=" card-text">${res.Total_order}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-xs-6 col-sm-6 col-md-6 col-lg-3">
                            <div class="card text-center mt-4" id="ordersReceived">
                                <div class="card-body">
                                    <p class="card-title">Enquiries <br> Received</p>
                                    <p class="card-text">${res.OrderReceived}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-xs-6 col-sm-6 col-md-6 col-lg-3">
                            <div class="card text-center mt-4" id="ordersDelivered">
                                <div class="card-body">
                                    <p class="card-title">Enquiries <br> Delivered</p>
                                    <p class="card-text">${res.OrderDelivered}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-xs-6 col-sm-6 col-md-6 col-lg-3">
                            <div class="card text-center mt-4" id="pendingOrders">
                                <div class="card-body">
                                    <p class="card-title">Pending <br> Enquiries</p>
                                    <p class="card-text">${res.OrderPending}</p>
                                </div>
                            </div>
                        </div>`;
    $("#overallOrderStatusBox").append(statusTable);
  };

  showVenderOrders = () => {
    $(".vendororders").addClass("active");
    $(".currentlyActive").addClass("d-none");
    $(".currentlyActive").removeClass("currentlyActive");

    $(".vendororders").addClass("currentlyActive");
    $(".vendororders").removeClass("d-none");
  };

  get_Vendor_Summary_For_VendorURL = () => {
    $.ajax({
      type: "GET",
      url: baseURL + "api/vendor/getVendorDetails",
      headers: { Authorization: X_API_KEY },
      success: this.getVenderURL
    });
  };
  getVenderURL = (res) => {
    var fullVendorURL = baseURL + "customer/productListing/" + res.Vendor_URL;
    console.log(fullVendorURL);
    $("#vendorUrlInput").val(fullVendorURL);
    $("#vendorUrlCopyButton").on("click", this.doCopyVendorURL);
  };

  doCopyVendorURL = () => {
    console.log("doCopyVendorURL");

    const urlInput = document.getElementById("vendorUrlInput");

    // Create a range and select the text
    const range = document.createRange();
    range.selectNode(urlInput);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);

    try {
      // Use the Clipboard API to copy the text
      document.execCommand("copy");
      // alert("URL copied to clipboard!");
    } catch (err) {
      // alert("Oops, unable to copy to clipboard. Manually copy the URL.");
    } finally {
      // Clear the selection
      window.getSelection().removeAllRanges();
    }
  };
}
