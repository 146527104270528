//VENDOR IMPORTS
import Vendor_login from "./components/Vendor_login";
import Vendor_details from "./components/vendor_details";
import vendor_gst_delivery_details from "./components/vendor_gst_delivery_details";
import Vendor_category_speciality_details_page from "./components/vendor_category_speciality_details";
import Vendor_product_service_listing from "./components/vendor_product_service_listing";
import Vendor_upload_documents from "./components/vendor_upload_documents";
import vendor_image_cropperjs from "./components/vendor_image_cropperjs";
import vendor_summary from "./components/vendor_summary";
import vendorServicing_dashboard from "./components/vendorServicing_dashboard";
import vendorServicing_orders from "./components/vendorServicing_orders";
import Vendor_security from "./components/Vendor_security";
import vendorServicing_profile from "./components/vendorServicing_profile";
import vendor_manage_address from "./components/vendor_manage_address";

import vendorServicing_order_details from "./components/vendorServicing_order_details";
import vendorServicing_order_details_email from "./components/vendorServicing_order_details_email";

import vendor_servicing_docs from "./components/vendor_servicing_docs";
import vendor_email_report from "./components/vendor_email_report";
import vendorServicing_billing from "./components/vendorServicing_billing";
import vendorServicing_help from "./components/vendorServicing_help";
import vendor_calender from "./components/vendor_calender";
import vendor_delivery from "./components/vendor_delivery";
// import vendorServicing_navbar from "./components/vendorServicing_navbar";
import vendor_servicing_product_listing from "./components/vendor_servicing_product_listing";
import vendor_servicing_product_details from "./components/vendor_servicing_product_details";
import vendorServicing_navbar from "./components/vendorServicing_navbar";
import vendorServicing_points from "./components/vendorServicing_points";
import vendorServicing_paymentStatus from "./components/vendorServicing_paymentStatus";
import vendor_servicing_pricing from "./components/vendor_servicing_pricing";
import vendor_gst from "./components/vendor_gst";
import vendor_servicing_payment from "./components/vendor_servicing_payment";

//Instamojo payments Testing
import vendorPaymentsInstamojo from "./components/vendorPaymentsInstamojo";
// import vendorServicing_navbar from "./components/vendorServicing_navbar";

// import vendorServicing_navbar from "./components/vendorServicing_navbar";

//CUSTOMER IMPORTS
// import vendorListingPage from "./components/vendorListingPage";
// import cartPage from "./components/cartPage";
//import 'bootstrap'
//import loginPagePhno from "./components/loginPagePhno";
import CustomerSignUpPage from "./components/CustomerSignUpPage";
import CustomerSearch from "./components/Customersearch";
import HomePage from "./components/HomePage";
import VendorListing from "./components/VendorListing";
import ProductSummary from "./components/ProductSummary";
import CustomerLogInPage from "./components/CustomerLogInPage";
import customerCartPage from "./components/customerCartPage";
import customerConfirmationPage from "./components/customerConfirmationPage";
import accountPageServicing from "./components/accountPageServicing";
import headerServicing from "./components/serviicngHeader";
//import newHomePage from "./components/newHomePage";
// import storyPage from "./components/storyPage";
import custSerRefferalPage from "./components/custSerRefferalPage";
import custSerOffers from "./components/custSerOffers";
import customer_image_cropperjs from "./components/customer_image_cropper";

import vendorPaymentsPage from "./components/vendorPaymentsPage";

// import vendorListingPage from "./components/vendorListingPage";
// import cartPage from "./components/cartPage";
import header from "./components/headerpart";

import GoogleMapsApi from "./helpers/GoogleMapsApi";

//productListing
import productListing from "./components/productListing";
//import customerLogin from "./components/customerLogin";
import customer_all_offers from "./components/customer_all_offers";

import cartUtil from "./helpers/cartUtil";

import Util from "./helpers/util";

//Admin Control Panels
import admin_control from "./components/admin_control";

//To import Trix(text editor for product discription) and customise the toolBox
import trixUtil from "./helpers/trixUtil";
var trixObject = new trixUtil();
trixObject.trixCustomToolBar();

//ENVIROMENT VARIABLES
// `process.env` is the one defined in the webpack's DefinePlugin
const envVariables = process.env;

// Read vars from envVariables object
const { AWS_S3_REGION } = envVariables;

globalThis.baseURL = document.getElementsByClassName("baseURL")[0].value;

export const BASEURL = baseURL;

//globalThis.LAT = 15.36435;
//globalThis.LNG = 73.926269;
globalThis.BUFFER = 10;
globalThis.addressDetails = {};
globalThis.customerID = null;
globalThis.orderID = null;
globalThis.cropperMain = null;
globalThis.orderDetalisMain = false;
globalThis.cropperC = "";

var headerFunctions;
var homepageFunctions;
var cartFunctions;
var vendorListingPageFunctions;
var CartObject = new cartUtil();
var UtilObject = new Util();
var GoogleMapsObject = new GoogleMapsApi();

// Main Entry point
class app {
  constructor() {
    this.init();
    this.visitorCount();
  }

  // Initialisation function
  init = async () => {
    ///////////////////////////////VENDOR CODE IN INIT///////////////////////////////
    globalThis.X_API_KEY = "KFvDq77oq74WjKXKon4AIzaSyBzKejuO2tJjzKJ";
    globalThis.baseURL = document.getElementsByClassName("baseURL")[0].value;
    globalThis.imageS3baseURL =
      "https://goancart-data-pro.s3.ap-south-1.amazonaws.com/";
    globalThis.S3BaseURL =
      "https://goancart-data-pro.s3.ap-south-1.amazonaws.com/Vendor/";
    globalThis.S3BaseURLProd =
      "https://goancart-data-pro.s3.ap-south-1.amazonaws.com";
    globalThis.BusinessImageURL = "/Document+Images/Business+Image1.png?";
    globalThis.DocImageURL = "/Document+Images/";
    globalThis.firstProductAdded = false;
    globalThis.currentProductId = " ";
    globalThis.allVendorCategories = [];
    globalThis.allVendorSpecialities = [];
    globalThis.currentProductId = " ";
    globalThis.cropper = "";

    if ($(".adminControl").length > 0) {
      new admin_control();
    }

    //Vendor code from app.js
    if ($(".vendorPage").length > 0) {
      new vendor_image_cropperjs();
    }

    if ($(".vendor-login").length > 0) {
      new Vendor_login();
    }

    if ($(".basicDetails-page").length > 0) {
      new Vendor_details();
    }

    if ($(".Vendor_category_speciality_details_page").length > 0) {
      new Vendor_category_speciality_details_page();
    }
    if ($(".product-services-listing-page").length > 0) {
      new Vendor_product_service_listing();
    }

    if ($(".vendorUploadDocuments").length > 0) {
      new Vendor_upload_documents();
    }
    if ($(".Products-Services-page4").length > 0) {
      // new vendor_image_cropperjs();
    }
    if ($(".VendorSummaryPage").length > 0) {
      new vendor_summary();
    }

    //Remove later
    if ($(".testingpagebody").length > 0) {
      new Vendor_login();
    }

    if ($(".vendorServicingNav").length > 0) {
      new vendorServicing_navbar();
    }

    if ($(".VendorDashboardPage").length > 0) {
      new vendorServicing_dashboard();
    }

    if ($(".vendororders").length > 0) {
      new vendorServicing_orders();
    }

    if ($(".vendororderdetails").length > 0) {
      //loaded in order details js
      // new vendorServicing_order_details();
      // new vendorServicing_order_details_email();
    }
    if ($(".vendor_name_edit_page").length > 0) {
      new vendorServicing_profile();
    }
    if ($(".vendor_name_edit_page").length > 0) {
      // new vendor_image_cropperjs();
    }
    if ($(".vendorServicingDocuments").length > 0) {
      new vendor_servicing_docs();
    }
    if ($(".VendorAccountSecurity").length > 0) {
      new Vendor_security();
    }
    if ($("#VendorAddressDiv").length > 0) {
      new vendor_manage_address();
    }
    if ($(".vendor_email_report").length > 0) {
      new vendor_email_report();
    }
    if ($(".vendorbilling").length > 0) {
      new vendorServicing_billing();
    }
    if ($(".vendorConfirmationHelpPageDesktop").length > 0) {
      new vendorServicing_help();
    }
    if ($(".vendorCalenderPage").length > 0) {
      new vendor_calender();
    }
    if ($(".deliveryDetailsPage").length > 0) {
      new vendor_delivery();
    }
    if ($(".vendor_servicing_product_listing").length > 0) {
      new vendor_servicing_product_listing();
    }
    if ($(".vendor_servicing_product_details").length > 0) {
      new vendor_servicing_product_details();
    }
    if ($(".vendorPoints").length > 0) {
      new vendorServicing_points();
    }
    if ($(".vendor_servicing_pricing").length > 0) {
      new vendor_servicing_pricing();
    }
    if ($(".vendorHasGSTdiv").length > 0) {
      new vendor_gst();
    }
    if ($(".vendor_servicing_payment").length > 0) {
      new vendor_servicing_payment();
    }
    if ($(".paymentStatusPage").length > 0) {
      new vendorServicing_paymentStatus();
    }
    if ($(".vendorHasGSTdiv").length > 0) {
      new vendor_gst();
    }

    //Vendor side Instamojo payments
    if ($(".vendor_servicing_payment_Instamojo").length > 0) {
      new vendorPaymentsInstamojo();
    }

    //Customer code from app.js
    //Get current Location on start
    await this.getCurrentAddress();
    if ($(".header").length > 0) {
      console.log("inside the header");
      headerFunctions = new header();
    }

    if ($(".headerServicing").length > 0) {
      new headerServicing();
    }

    if ($(".homePage").length > 0) {
      homepageFunctions = new HomePage();
    }
    if ($(".search").length > 0) {
      new CustomerSearch();
    }

    if ($(".CustomerSignUpPage").length > 0) {
      new CustomerSignUpPage();
    }
    if ($(".CustomerLogInPage").length > 0) {
      new CustomerLogInPage();
    }
    if ($(".vendorListingPage").length > 0) {
      vendorListingPageFunctions = new VendorListing();
    }
    if ($(".ProductListingPage").length > 0) {
      new productListing();
    }
    if ($(".productSummaryPage").length > 0) {
      new ProductSummary();
    }
    if ($(".cartPage").length > 0) {
      cartFunctions = new customerCartPage();
    }
    if ($(".customerConfirmationPage").length > 0) {
      new customerConfirmationPage();
    }

    // // stories
    // if ($(".storyPage").length > 0) {
    //   new storyPage();

    //   //cropper js
    //   new customer_image_cropperjs();
    // }

    //account profile part

    if ($(".customerServicingAccountPage").length > 0) {
      new accountPageServicing();

      //cropper js
      new customer_image_cropperjs();
    }

    if ($(".newHomePage").length > 0) {
      new newHomePage();
    }

    if ($(".vendorPaymentsPage").length > 0) {
      new vendorPaymentsPage();
    }

    if ($(".allOffers").length > 0) {
      new customer_all_offers();
    }

    //this.refreshFrames();

    this.customerHeaderAddressShow();
    // $(".submitBtnNavTop").on("click", this.customerHeaderAddressShow);
    // $(".deleteAddressAccountTop").on("click", this.customerHeaderAddressShow);
    // $(".accountAdressEditButtonTop").on(
    //   "click",
    //   this.customerHeaderAddressShow
    // );

    //Refreshing adress section on cart page if address is added
    $(".submitBtnNavTop").on("click", () => {
      console.log("Running app.js submit functions");
      this.runCartFunctions();
      this.customerHeaderAddressShow();
    });
    //Refreshing adress section on cart page if address is edit
    $(".accountAdressEditButtonTop").on("click", () => {
      console.log("Running app.js edit account address functions");
      this.runCartFunctions();
      this.customerHeaderAddressShow();
    });
    //Refreshing adress section on cart page if address is deleted
    $(".deleteAddressAccountTop").on("click", () => {
      console.log("Running app.js account delete functions");
      this.customerHeaderAddressShow();
      this.runCartFunctions();
    });
    //Cart page on add address refresh the dropdown in the header
    $(".cartAdressSubmitButton").on("click", () => {
      //cartFunctions.getAddressDetailsOnload();
      this.customerHeaderAddressShow();
    });

    //Get full URL of the Page
    //this.maintainCustomerFlow(window.location.href);
    this.maintainCustomerFlow();
  };

  runCartFunctions = (event) => {
    console.log("Running Cart Functions app.js");
    cartFunctions.getAddressDetailsOnload();
  };
  maintainCustomerFlow = () => {
    var location = window.location.href;

    console.log(`Previously visited page URL: ${location}`);
    var containsLogin = location.includes("login");
    var containsSignup = location.includes("signup");
    var containsCart = location.includes("/cart");

    if (!(containsLogin || containsSignup)) {
      UtilObject.createCookie("PREVIOUS_PAGE", location);
    }

    if (!(containsLogin || containsSignup || containsCart)) {
      UtilObject.createCookie("BEFORE_CART_PAGE", location);
    }
    // UtilObject.createCookie("PREVIOUS_PAGE", previousPageUrl);
    // UtilObject.createCookie("BEFORE_CART_PAGE", previousPageUrl);
  };

  customerHeaderAddressShow = () => {
    var allCookies = UtilObject.getAllCookies();
    var customerID = allCookies.CUSTOMER_ID;
    if (customerID != null) {
      //Get Addresses
      $.ajax({
        url: baseURL + "api/customer/Account/getAddress",
        type: "POST",
        async: false,
        data: {
          Customer_ID: customerID
        },
        headers: { Authorization: X_API_KEY },
        success: (result) => {
          $(".saved-address-container").empty(); //this empties the address on desktop
          $(".saved-addresses").empty(); //this empties the mobile
          console.log("inside success");
          console.log(result);
          $(".saved-address-container")
            .prepend(`<div class="row saved-addresses">
                          <div class="col text-left"><b>SAVED ADDRESS</b><hr class="dropdown-divider"></div>
                        </div>`);
          result.forEach((element) => {
            var item = `<div class="row navTopAddress">
            <span class="updateAddress">
            <input type="hidden" name="customerAddressId" value="${element.Customer_Address_ID}" />
            <input type="hidden" name="customerAddressLatTop" value="${element.lat}" />
            <input type="hidden" name="customerAddressLngTop" value="${element.lon}" />
            <div class="col pl-0 text-left"><b>${element.Address_Name}</b></div>
            <p style="text-align: left;">${element.Address_Line_1}, ${element.Landmark}, ${element.City}, ${element.State}</p></span>
            <div class="row">
            <div class="col d-flex justify-content-start"> <button class="btn btn-sm customerAccountAddressEditTop locationModalAccountEditTop" type="button" style="color: #f49431;" data-bs-toggle="modal" data-bs-target="#locationModalAccountCallEditTop">Edit
                    <input type="hidden" name="customerAddressIdTop" value="${element.Customer_Address_ID}" />
                </button>
            </div>
            <div class="col  d-flex justify-content-end"> <button class="btn btn-sm customerAddressDeleteTop" type="button" style="color: #f49431;">Delete
            <input type="hidden" name="customerAddressIdTop" value="${element.Customer_Address_ID}" />
            </button>
            </div>
        </div>
            <hr class="dropdown-divider">
          </div>`;
            $(".saved-addresses").append(item);
          });
          //unbinds any previous event handlers
          $(".navTopAddress .customerAccountAddressEditTop").off("click");
          $(".navTopAddress .customerAccountAddressEditTop").on(
            "click",
            this.editAddressDetailsAccountApp
          );
          $(".navTopAddress .customerAddressDeleteTop").off("click");
          $(".navTopAddress .customerAddressDeleteTop").on(
            "click",
            this.confirmDeleteAddressApp
          );
          //unbinds any previous event handlers
          $(".updateAddress").off("click");
          $(".updateAddress").on("click", this.updateLocation);
        },
        error: (res) => {
          console.log("inside error");
          console.log(res);
        }
      });
    }
  };

  editAddressDetailsAccountApp = (event) => {
    headerFunctions.editAddressDetailsAccount(event);
  };

  confirmDeleteAddressApp = (event) => {
    headerFunctions.confirmDeleteAddress(event);
  };

  updateLocation = async (event) => {
    console.log(event);
    var lat = $(event.currentTarget).children(
      'input[name="customerAddressLatTop"]'
    );
    lat = parseFloat(lat[0].value);
    var lng = $(event.currentTarget).children(
      'input[name="customerAddressLngTop"]'
    );
    lng = parseFloat(lng[0].value);
    var customerAddressId = $(event.currentTarget).children(
      'input[name="customerAddressId"]'
    );
    customerAddressId = customerAddressId[0].value;

    console.log(lat);
    console.log(lng);

    //updating cookies
    UtilObject.createCookie("LAT", lat);
    UtilObject.createCookie("LNG", lng);
    UtilObject.createCookie("CUSTOMER_ADDRESS_ID", customerAddressId);

    globalThis.LAT = lat;
    globalThis.LNG = lng;

    var selectedAddress = await GoogleMapsObject.geocodeFindPosition(lat, lng);
    console.log(selectedAddress);

    if (selectedAddress.msgCode == 1) {
      $(".currentAddress").text(
        `${selectedAddress.APPROXIMATE.formatted_address}`
      );
    } else {
      $(".currentAddress").text("Unable to Get Your Location");
    }

    this.refreshFrames();
    $("#exampleModalToggle .btn-close").trigger("click");
  };

  refreshFrames = () => {
    console.log("Refreshing Data");
    try {
      if ($(".homePage").length > 0) {
        homepageFunctions.getOffers();
        homepageFunctions.getProductByRating();
        homepageFunctions.nearYouCategoriesVendor();
      }

      if ($(".vendorListingPage").length > 0) {
        //Calling functions from vendor listing page;
        vendorListingPageFunctions.getCategoryDetails();
      }
    } catch (error) {
      console.log("some error occured");
    }
  };

  getCurrentAddress = async () => {
    var addressSet = UtilObject.getAllCookies();
    console.log(addressSet);
    //check if address has already been selected
    if (!addressSet.hasOwnProperty("LAT")) {
      console.log("getting current location");
      var currentAddressDetails =
        await GoogleMapsObject.navigatorGeolocationForCurrentLocation();
      console.log(currentAddressDetails);

      globalThis.LAT = currentAddressDetails.lat;
      globalThis.LNG = currentAddressDetails.lng;

      if (currentAddressDetails.msgCode == 1) {
        $(".currentAddress").text(
          `${currentAddressDetails.APPROXIMATE.formatted_address}`
        );
      } else {
        $(".currentAddress").text("Unable to Get Your Location");
      }

      //Setting currently used address as cookies
      //storing it in cookies
      //UtilObject.createCookie("LAT", currentAddressDetails.lat);
      //UtilObject.createCookie("LNG", currentAddressDetails.lng);
    } else {
      console.log("getting selected location");
      var lat = addressSet.LAT;
      lat = parseFloat(lat);
      var lng = addressSet.LNG;
      lng = parseFloat(lng);

      console.log(lat);
      console.log(lng);

      globalThis.LAT = lat;
      globalThis.LNG = lng;

      var currentAddressDetails = await GoogleMapsObject.geocodeFindPosition(
        lat,
        lng
      );
      console.log(currentAddressDetails);
      if (currentAddressDetails.msgCode == 1) {
        $(".currentAddress").text(
          `${currentAddressDetails.APPROXIMATE.formatted_address}`
        );
      } else {
        $(".currentAddress").text("Unable to Get Your Selected Location");
      }
    }
  };

  visitorCount = () => {
    var page = window.location.href;
    var section = "CUSTOMER";
    if (page.includes("/Vendor_")) {
      section = "VENDOR";
    }
    console.log("SECTION : ", section);
    console.log("VISIT SECTION : ", sessionStorage.getItem("visit_section"));

    try {
      var typrData = {};
      if (sessionStorage.getItem("visit") === null) {
        //new visit and pageview
        typrData = { type: "visit", section };
      } else {
        //pageview
        if (sessionStorage.getItem("visit_section") != section) {
          //new visit and pageview
          console.log("SECTION CHANGED");
          typrData = { type: "pageview", update_section: true, section };
        }
      }
      sessionStorage.setItem("visit", "x");
      sessionStorage.setItem("visit_section", section);
      if (Object.keys(typrData).length !== 0) {
        $.ajax({
          type: "POST",
          url: baseURL + "api/visitor/updateVisitorCount",
          data: typrData,
          headers: { Authorization: X_API_KEY },
          success: function (res) {
            console.log(res);
          }
        });
      }
    } catch (error) {
      console.log("some error occured in visitorCount");
    }
  };
}

//Instantiation of the object on dom ready cycle
$(document).ready(() => {
  new app();
});
