export default class Util {
  getCookie = (name) => {
    var returnResult;
    console.log(name);
    $.ajax({
      url: baseURL + "api/customer/CustomerCookies/getSessionValue",
      type: "POST",
      async: false,
      data: {
        name: name,
      },
      success: (result) => {
        console.log(result);
        returnResult = result;
      },
      error: (err) => {
        console.log(err);
        returnResult = null;
      },
    });
    return returnResult;
  };

  getAllCookies = () => {
    var returnResult;
    $.ajax({
      url: baseURL + "api/customer/CustomerCookies/getAllSessionValues",
      type: "GET",
      async: false,
      success: (result) => {
        console.log(result);
        returnResult = result;
      },
      error: (err) => {
        console.log(err);
        returnResult = result;
      },
    });
    return returnResult;
  };

  createCookie = (name, value, days) => {
    var returnResult;
    var data = {};
    data[name] = value;

    console.log(data);

    $.ajax({
      url: baseURL + "api/customer/CustomerCookies/setSessionId",
      type: "POST",
      async: false,
      data: {
        data: data,
      },
      success: (result) => {
        console.log(result);
        returnResult = result;
      },
      error: (err) => {
        console.log(err);
        returnResult = result;
      },
    });
    return returnResult;
  };

  eraseCookie = (name) => {
    var returnResult;
    $.ajax({
      url: baseURL + "api/customer/CustomerCookies/unsetSessionValues",
      type: "POST",
      async: false,
      data: {
        unset: name,
      },
      success: (result) => {
        console.log(result);
        returnResult = result;
      },
      error: (err) => {
        console.log(err);
        returnResult = result;
      },
    });
    return returnResult;
  };

  getPhpCookies = () => {
    var returnResult;
    $.ajax({
      url: baseURL + "api/customer/CustomerCookies/getAllSessionValues",
      type: "GET",
      async: false,
      success: (result) => {
        console.log(result);
        returnResult = result;
      },
      error: (err) => {
        console.log(err);
        returnResult = result;
      },
    });
    return returnResult;
  };

  distance = (lat1, lon1, lat2, lon2, unit) => {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;
      return dist;
    }
  };

  checkLocalStorage = (key) => {
    if (localStorage.getItem(key) == null) {
      return null;
    } else {
      return localStorage.getItem(key);
    }
  };
}
