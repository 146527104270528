import Util from "./util";
var UtilObject = new Util();

export default class cartUtil {
  cartCheck = () => {
    var allCookies = UtilObject.getAllCookies();
    console.log(allCookies);

    console.log("INSIDE CART CHECK");

    if (allCookies.hasOwnProperty("CUSTOMER_ID")) {
      //check if local storage has order_id
      if (localStorage.getItem("ORDER_ID") != null) {
        //local storage also has id
        // in such case, the local Storage Cart takes precedence, so we need to update the order with the customer id
        var cartValidStatus = this.cart_exists_check();
        console.log(cartValidStatus);

        if (cartValidStatus == true) {
          //This means the order id present in the session has a valid cart in the db and is not deleted
          this.update_order_with_customer_id();
        } else {
          //The order id in the session is wrong and needs to be update
          $.ajax({
            url: baseURL + "api/customer/Cart/CartDetailsLatest",
            type: "GET",
            async: false,
            headers: { Authorization: X_API_KEY },
            success: (result) => {
              console.log(result);
              if (result.code != 18) {
                //Cart Exists in DB
                console.log("Cart Exists in DB");
                localStorage.setItem("ORDER_ID", result.Order_ID);
                var returnCookies = UtilObject.createCookie(
                  "ORDER_ID",
                  result.Order_ID,
                  30
                );
                console.log(returnCookies);
              } else {
                //First Time User, so no cart
                console.log("First Time User, so no cart");
                $.ajax({
                  url:
                    baseURL + "api/customer/CustomerCookies/setRandomSessionId",
                  type: "POST",
                  async: false,
                  data: {
                    name: "ORDER_ID"
                  },
                  success: (result) => {
                    console.log(result);
                    //Set the ORDER_ID in localStorage
                    //localStorage.setItem("ORDER_ID", result);
                    this.cart_exists_check();
                  },
                  error: (err) => {
                    console.log(err);
                  }
                });
              }
            },
            error: (err) => {
              returnThis = err;
            }
          });
        }
      } else {
        //no local storage ORDER_ID so pull latest cart from DB
        $.ajax({
          url: baseURL + "api/customer/Cart/CartDetailsLatest",
          type: "GET",
          async: false,
          headers: { Authorization: X_API_KEY },
          success: (result) => {
            console.log(result);
            if (result.code != 18) {
              //Cart Exists in DB
              console.log("Cart Exists in DB");
              localStorage.setItem("ORDER_ID", result.Order_ID);
              var returnCookies = UtilObject.createCookie(
                "ORDER_ID",
                result.Order_ID,
                30
              );
              console.log(returnCookies);
            } else {
              //First Time User, so no cart
              console.log("First Time User, so no cart");
              $.ajax({
                url:
                  baseURL + "api/customer/CustomerCookies/setRandomSessionId",
                type: "POST",
                async: false,
                data: {
                  name: "ORDER_ID"
                },
                success: (result) => {
                  console.log(result);
                  //Set the ORDER_ID in localStorage
                  //localStorage.setItem("ORDER_ID", result);
                  this.cart_exists_check();
                },
                error: (err) => {
                  console.log(err);
                }
              });
            }
          },
          error: (err) => {
            returnThis = err;
          }
        });
      }
    } else {
      //User has not logged in
      //Check for ORDER_ID in cookies
      if (allCookies.hasOwnProperty("ORDER_ID")) {
        //ORDER_ID exists in cookies
        //Set the ORDER_ID in localStorage
        console.log("ORDER_ID exists in session");
        //localStorage.setItem("ORDER_ID", allCookies["ORDER_ID"]);
        var cartValidStatus = this.cart_exists_check();
        console.log(cartValidStatus);

        if (cartValidStatus == false) {
          $.ajax({
            url: baseURL + "api/customer/CustomerCookies/setRandomSessionId",
            type: "POST",
            async: false,
            data: {
              name: "ORDER_ID"
            },
            success: (result) => {
              console.log(result);
            },
            error: (err) => {
              console.log(err);
            }
          });
        }
      } else {
        console.log("ORDER_ID does not exist in session");
        // 1. Check local Storage for ORDER_ID
        if (localStorage.getItem("ORDER_ID") == null) {
          //If localStorage does not have ORDER_ID
          console.log("LocalStorage does not have ORDER_ID");
          $.ajax({
            url: baseURL + "api/customer/CustomerCookies/setRandomSessionId",
            type: "POST",
            async: false,
            data: {
              name: "ORDER_ID"
            },
            success: (result) => {
              console.log(result);
            },
            error: (err) => {
              console.log(err);
            }
          });
        } else {
          console.log("LocalStorage has ORDER_ID");
          var localStorageOrderID = localStorage.getItem("ORDER_ID");
          var returnCookies = UtilObject.createCookie(
            "ORDER_ID",
            localStorageOrderID,
            30
          );
          console.log(returnCookies);
          var cartValidStatus = this.cart_exists_check();
          console.log(cartValidStatus);

          if (cartValidStatus == false) {
            $.ajax({
              url: baseURL + "api/customer/CustomerCookies/setRandomSessionId",
              type: "POST",
              async: false,
              data: {
                name: "ORDER_ID"
              },
              success: (result) => {
                console.log(result);
              },
              error: (err) => {
                console.log(err);
              }
            });
          }
        }
      }
    }

    return this.get_cart();
  };

  update_order_with_customer_id = () => {
    var localStorageOrderID = localStorage.getItem("ORDER_ID");
    $.ajax({
      url: baseURL + "api/customer/Cart/SetCustomerIDToOrder",
      type: "POST",
      async: false,
      headers: { Authorization: X_API_KEY },
      data: {
        LocalStorageOrderID: localStorageOrderID
      },
      success: (result) => {
        console.log(result);
        // if (result.code == 200) {
        //   UtilObject.createCookie("ORDER_ID", localStorageOrderID, 30);
        // }
      },
      error: (err) => {
        console.log(error);
      }
    });
  };

  get_cart = () => {
    let returnThis;
    $.ajax({
      url: baseURL + "api/customer/Cart/CartDetails",
      type: "GET",
      async: false,
      headers: { Authorization: X_API_KEY },
      success: (result) => {
        console.log(result);
        if (result.code != 18) {
          returnThis = result;
        } else {
          returnThis = {
            Products: [],
            Products_Not_Available: [],
            vendor: null
          };
        }
      },
      error: (err) => {
        returnThis = err;
      }
    });
    console.log(returnThis);
    return returnThis;
  };

  cart_exists_check = () => {
    var CartExists = false;
    $.ajax({
      url: baseURL + "api/customer/Cart/CartExistsCheck",
      type: "GET",
      async: false,
      headers: { Authorization: X_API_KEY },
      success: (result) => {
        console.log(result);
        if (result.code != 18) {
          localStorage.setItem("ORDER_ID", result.Order_ID);
          CartExists = true;
        }
      },
      error: (err) => {
        console.log(error);
      }
    });
    return CartExists;
  };
}
