import Util from "../helpers/util";
var UtilObject = new Util();

// var phnoEmailIsUnique;

//var map, marker;
var OTP;

export default class CustomerSignUpPage {
  constructor() {
    this.formstep1 = $("#formstep1");
    this.formstep2 = $("#formstep2");
    this.formstep3 = $("#formstep3");

    this.customerDetailsForm = $("#customerDetailsForm");
    this.customerFullName = $("#customerFullName");
    this.customerPhone = $("#customerPhone");
    this.customerEmail = $("#customerEmail");
    this.customerPassword = $("#customerPassword");
    //refferralcode
    this.referralCode = $("#refferalCodeDisplay");

    this.submitBtn = $(".customerSignUpsubmitBtn");

    this.saveChangesBtn = $("#saveChangesBtn");

    this.customerFullNameError = $("#customerFullNameError");
    this.customerPhoneNumberError = $("#customerPhoneNumberError");
    this.customerEmailError = $("#customerEmailError");
    this.customerPasswordError = $("#customerPasswordError");

    //zero bounce validation
    // this.customerZeroBounceEmailError = $("#customerZeroBounceEmailError");

    this.verifyOtpModal = $("#verifyOtpModal");
    // this.OTPInputVerify = $("#otp-input-verify");
    // this.OTPSuccess = $("#otpError");

    this.verifyOTPDetails = $("#verify-OTP-details");
    this.verifyOTPBtn = $("#verify-OTP-btn");

    this.basicDetails_page = $(".customerSignUpDetails-page");

    this.otpInputVerify = $("#otpInputVerify");
    this.otpError = $("#otpError");
    this.verifyOtpModalClose = $("#verifyOtpModalClose");

    // this.loginSuccess = 0;

    // Registering Events
    this.bindEvents();
    //this.geocodePosition();
  }

  bindEvents = () => {
    console.log(window.history);
    console.log(history);
    console.log(document.referrer);
    globalThis.phnoEmailIsUnique = false;
    globalThis.userCreated = false;
    globalThis.prevPhoneNo = 0;
    globalThis.prevEmail = "old";

    this.submitBtn.on("click", this.doFormAction);
    $("#resend-OTP-btn").on("click", this.reSendOTP);
    this.verifyOTPBtn.on("click", this.verifyOtp);

    //refferral code
    //this.referralCode.on("click", this.showrefferalBox);

    this.saveChangesBtn.on("click", this.closeModal);
    this.verifyOtpModalClose.on("click", this.closeOTPModal);

    $(".refferalCodeDisplay").hide();
  };

  closeModal = () => {
    this.autoDetectModal.hide();
  };

  closeOTPModal = () => {
    this.verifyOtpModal.hide();
  };

  // showrefferalBox = () => {
  //   //to show refferral code text box
  //   $(".refferalCodeDisplay").show();
  //   //to hide refferral code button
  //   $("#refferalCodeDisplay").hide();
  //   //code for refferral
  // };

  //form validation
  doFormAction = (e) => {
    phnoEmailIsUnique = false;
    e.preventDefault();
    console.log("in doFormAction Basic Details");

    let iscustomerFullNameValidated = this.customerFullName_validate();
    if (iscustomerFullNameValidated) {
      console.log("FullNameValidated VALID");
      this.customerFullNameError.html("");
    }

    let iscustomerPhoneValidated = this.Phno_validate();
    if (iscustomerPhoneValidated) {
      console.log(" ph VALID");
      this.customerPhoneNumberError.html("");
    } else {
      console.log("ERROR");
    }

    let iscustomerEmailValidated = this.Email_validate();
    if (iscustomerEmailValidated) {
      console.log("EmailValidated VALID");
      this.customerEmailError.html("");
    }

    let iscustomerPasswordValidated = this.Password_validate();
    if (iscustomerPasswordValidated) {
      console.log("PasswordValidated VALID");
      this.customerPasswordError.html("");
    }
    console.log("phnoEmailIsUnique Validation Here");
    console.log(phnoEmailIsUnique);
    console.log(prevPhoneNo);
    console.log(prevEmail);

    if (
      iscustomerFullNameValidated &&
      iscustomerPhoneValidated &&
      iscustomerEmailValidated &&
      iscustomerPasswordValidated
    ) {
      phnoEmailIsUnique = this.phnoEmailIsUnique_validate();
      console.log("isPhNoEmailValidated VALID");
      console.log(phnoEmailIsUnique);

      if (phnoEmailIsUnique) {
        this.customerEmailError.html("");
        this.customerPhoneNumberError.html("");
        this.verifyOtpModal.show();
        // this.Submit_Form();
        this.sendOtp();
        console.log("ALL FIELDS VALID...VALIDATION OVER");
      }
    }
  };

  reSendOTP = () => {
    this.sendOtp();
    $("#otpError").html("OTP Re-Sent");
    setTimeout(() => {
      $("#otpError").html("");
    }, 5000);
  };

  sendOtp = () => {
    console.log(this.customerPhone.val());
    $.ajax({
      type: "POST",
      url: baseURL + "api/customer/Account/sendOTPSignUp",
      async: false,
      headers: { Authorization: X_API_KEY },
      data: {
        Phone_Number: this.customerPhone.val()
      },
      success: (data) => {
        console.log(data);
        OTP = data.OTP;
      },
      error: (err) => {
        console.log(err);
      }
    });
  };

  verifyOtp = () => {
    //OTP = 1234;
    var verifyOtp = /^\d{4}$/;
    if (!this.otpInputVerify.val().match(verifyOtp)) {
      console.log("OTP format error");
      this.otpError.html("Enter the Correct OTP format");
    } else {
      if (OTP == this.otpInputVerify.val()) {
        console.log("It works");
        this.otpError.html("OTP Verified");
        setTimeout(() => {
          this.verifyOtpModal.hide();
        }, 1500);

        console.log(this.customerEmail.val());
        console.log($("#customerRefferalCode").val());

        //zero bounce check for email
        var emailValid = "invalid";
        $.ajax({
          url: baseURL + "api/customer/CustomerZeroBounce/zeroBounceCheck",
          type: "POST",
          async: false,
          data: {
            basicEmail: this.customerEmail.val().toLowerCase()
          },
          success: () => {
            console.log("Email is valid");
            emailValid = "valid";
          },
          error: () => {
            console.log("Email is not valid");
          }
        });
        //zero bounce check is over

        //Store Customer details in the database
        $.ajax({
          type: "POST",
          url: baseURL + "api/customer/Account/verifyOTPSignUp",
          async: false,
          headers: { Authorization: X_API_KEY },
          data: {
            Phone_Number: this.customerPhone.val(),
            Password: this.customerPassword.val(),
            Customer_Name: this.customerFullName.val(),
            Email_Address: this.customerEmail.val().toLowerCase(),
            Referral_code: $("#customerRefferalCode").val(),
            Email_Status: emailValid
          },
          success: (data) => {
            //inserting into user access table
            console.log(data);
            $.ajax({
              url: baseURL + "api/customer/Account/userAccessSignUp",
              type: "POST",
              async: false,
              data: {
                Role_Type: "Customer",
                Group_Type: "Customer",
                Phone_Number: this.customerPhone.val(),
                Password: this.customerPassword.val(),
                Customer_Name: this.customerFullName.val(),
                Email_Address: this.customerEmail.val().toLowerCase(),
                Serial_Number: data.Serial_Number
              },
              success: () => {
                console.log("Inserted into User Access Table");
              },
              error: () => {
                console.log("Inserting into User Access Table Failed");
              }
            });
            console.log(data);
            customerID = data.Customer_ID;
            this.setCustomerDetails();

            var allCookies = UtilObject.getAllCookies();
            console.log(allCookies);

            setTimeout(() => {
              window.location = allCookies.PREVIOUS_PAGE;
            }, 1500);
          },
          error: (err) => {
            console.log(err);
          }
        });
      } else {
        this.otpError.html("Enter the Correct OTP");
      }
    }
  };

  phnoEmailIsUnique_validate = () => {
    var phone = false,
      email = false;
    console.log(this.customerDetailsForm.serialize());
    // if (
    //   prevPhoneNo == this.customerPhone.val() &&
    //   prevEmail == this.customerEmail.val()
    // ) {
    //   phnoEmailIsUnique = true;
    // } else {\

    //Check if phone is unique
    $.ajax({
      type: "POST",
      //check if phone number exists
      url: baseURL + "api/customer/Account/logInPhoneCustomer",
      async: false,

      headers: { Authorization: X_API_KEY },

      data: this.customerDetailsForm.serialize(),
      success: () => {
        console.log("Phone is unique");
        phone = true;
      },
      error: () => {
        console.log("Phone is not unique");
        this.customerPhoneNumberError.html("Phone Number already Exists");
      }
    });

    //check if email is unique
    $.ajax({
      type: "POST",
      //check if phone number exists
      url: baseURL + "api/customer/Account/emailIsUnique",
      async: false,

      headers: { Authorization: X_API_KEY },

      data: this.customerDetailsForm.serialize(),
      success: () => {
        console.log("Email is unique");
        email = true;
      },
      error: () => {
        console.log("Email is not unique");
        if ($("#customerEmail").val() == "") {
          this.customerEmailError.html("Email Address is mandatory");
        } else {
          this.customerEmailError.html("Email Address already exists");
        }
      }
    });
    console.log("Outside Ajax");

    if (phone && email) {
      return true;
    } else {
      return false;
    }
    //}
  };

  // CheckPhNoEmailExistsSuccess = (res) => {
  //   let retCode = res.Code;
  //   console.log(retCode);

  //   if (retCode == 6) {
  //     console.log("Inside phoneExists Fn Error 6");

  //     this.customerPhoneNumberError.html("Phone Number already exists");
  //     if (prevPhoneNo == this.customerPhone.val()) {
  //       phnoEmailIsUnique = true;
  //     } else {
  //       phnoEmailIsUnique = false;
  //     }
  //   }
  //   if (retCode == 7) {
  //     this.customerEmailError.html("Email already exists");

  //     if (prevEmail == this.customerEmail.val()) {
  //       phnoEmailIsUnique = true;
  //     } else {
  //       phnoEmailIsUnique = false;
  //     }
  //   }
  //   if (retCode == 8) {
  //     // this.emailError.html("Email and PhoneNo already exists");
  //     if (prevPhoneNo != this.customerPhone.val()) {
  //       this.phoneNumberError.html("Phone Number already exists");
  //     }
  //     if (prevEmail != this.customerEmail.val()) {
  //       this.emailError.html("Email already exists");
  //     }
  //     if (
  //       prevPhoneNo == this.customerPhone.val() &&
  //       prevEmail == this.customerEmail.val()
  //     ) {
  //       phnoEmailIsUnique = true;
  //     } else {
  //       phnoEmailIsUnique = false;
  //     }
  //   }
  //   if (retCode == 0) {
  //     // this.emailError.html("Email already exists");
  //     console.log("unique ph no email");

  //     phnoEmailIsUnique = true;
  //   }
  //   console.log("Inside Ajax Success Ajax");

  //   console.log(phnoEmailIsUnique);
  // };

  //validate functions

  customerFullName_validate = () => {
    console.log("in Name validate");
    let flag = true;
    var name = /^([a-zA-Z ]){3,100}$/;

    if (
      !(
        this.customerFullName.val().match(name) &&
        this.customerFullName.val().length >= 3 &&
        this.customerFullName.val().length <= 100
      )
    ) {
      this.customerFullNameError.html("Enter valid name");

      flag = false;
    }

    return flag;
  };

  //phoneno validation
  Phno_validate = () => {
    console.log("in Phone number validate");
    let flag = true;
    // var phoneno = /^\d{10}$/;
    var phoneno = /^[1-9]{1}[0-9]{9}$/;
    if (!this.customerPhone.val().match(phoneno)) {
      this.customerPhoneNumberError.html(
        "Please enter only 10 digits for mobile number"
      );

      flag = false;
    }

    return flag;
  };

  //email validation
  Email_validate = () => {
    console.log("in Email validate");
    let flag = true;
    var emailexample =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!this.customerEmail.val().match(emailexample)) {
      this.customerEmailError.html("Please enter a valid email");

      flag = false;
    }

    return flag;
  };

  //password validation
  Password_validate = () => {
    // console.log("in Password validate");
    // let flag = true;
    // var PasswordExample =
    //   /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

    // if (!this.inputPassword.val().match(PasswordExample)) {
    //   this.passwordError.html(
    //     "Please use min 6 max 15 characters, at least one letter, one small alphabet, one number and one special character"
    //   );

    //   flag = false;
    // }

    // return flag;

    console.log("in pass validate");
    let flag = true;

    if (
      !(
        this.customerPassword.val().length >= 6 &&
        this.customerPassword.val().length <= 15
      )
    ) {
      this.customerPasswordError.html(
        "Password must be min 6 and max 15 characters"
      );

      flag = false;
    }

    return flag;
  };

  Submit_Form = () => {
    console.log("Inside submit flow");
    console.log(this.customerDetailsForm.serialize());

    this.phoneNumberError.html("");
    this.emailError.html("");

    if (!userCreated) {
      $.ajax({
        type: "POST",
        url: baseURL + "api/customer/Account/VerifyOTPSignUp",
        async: false,
        // data : {
        //     "name" : customerPhone,
        //     "password" : customerPassword
        //       },
        headers: { Authorization: X_API_KEY },
        data: this.customerDetailsForm.serialize(),
        statusCode: {
          400: function () {
            alert("400 status code! user error");
          },
          500: function () {
            alert("500 status code! server error");
          }
        },
        success: this.phoneExists
        //  function (res) {
        //   console.log(res);
        //   console.log("Hello its a success");
        //   if (res.code == 6) {
        //     this.phoneExists();
        //   }
        // },
        // error: function (res) {
        //   console.log(res);
        //   this.phoneExists;
        // },
      });
    } else {
      prevPhoneNo = this.customerPhone.val();
      prevEmail = this.customerEmail.val();
      $.ajax({
        type: "POST",
        url: baseURL + "api/customer/Account/VerifyOTPUpdateDetails",
        async: false,
        // data : {
        //     "name" : phoneNumberInput,
        //     "category" : passwordInput
        //       },
        headers: { Authorization: X_API_KEY },
        data: this.customerDetailsForm.serialize(),
        statusCode: {
          400: function () {
            alert("400 status code! user error");
          },
          500: function () {
            alert("500 status code! server error");
          }
        }
        // success: this.afterDetailsUpdated,
        //  function (res) {
        //   console.log(res);
        //   console.log("Hello its a success");
        //   if (res.code == 6) {
        //     this.phoneExists();
        //   }
        // },
        // error: function (res) {
        //   console.log(res);
        //   this.phoneExists;
        // },
      });
    }
    this.otpInputVerify.val("");
    this.otpError.html("");
    this.verifyOTPBtn.removeClass("d-none");
  };

  // //zero bounce email
  // Validate_Email = () => {
  //   let userEmail = this.customerEmail.val();
  //   $.ajax({
  //     type: "POST",
  //     url: baseURL + "api/customer/CustomerZeroBounce/update",
  //     async: false,
  //     data: {
  //       basicEmail: userEmail,
  //     },
  //     //headers: { Authorization: X_API_KEY },
  //     success: function (res) {
  //       console.log(res);
  //     },
  //   });
  // };
  // //

  phoneExists = (res) => {
    prevPhoneNo = this.customerPhone.val();
    prevEmail = this.customerEmail.val();

    let cust_id = res.Customer_ID;

    let retCode = res.Code;
    console.log("Inside phoneExists Fn");
    console.log(res.Code);

    // console.log(res.code);

    // if (retCode == 6) {
    //   // this.phoneExists();
    //   console.log("Inside phoneExists Fn Error 6");

    //   this.phoneNumberError.html("Phone Number already exists");
    // } else if (retCode == 7) {
    //   // this.phoneExists();
    //   this.emailError.html("Email already exists");
    // } else {
    // console.log("Inside submit flow");
    console.log("Inside phoneExists ELSE Fn");

    //this.Validate_Email();

    this.customerPhoneNumberError.html("");
    this.customerEmailError.html("");

    // document.location = "vendor_login";
    console.log("calling the api to store address details");
    console.log(cust_id);
    console.log(userCreated);
    console.log("Changing userCreated value to true");
    userCreated = true;
    console.log(userCreated);

    //this.addressPost(cust_id);
    //this.venId.html(cust_id);//
    // this.GSTDetails_page.removeClass("d-none");
    // this.basicDetails_page.addClass("d-none");
    // this.formstep1.removeClass("current-item");
    // this.formstep3.addClass("current-item");

    // document.location = "vendor_gst_delivery_details";
    // }
  };

  setCustomerDetails = () => {
    console.log(customerID);

    //storing it in cookies - for JS
    UtilObject.createCookie("CUSTOMER_ID", customerID, 30);

    //Storing it in PHP
    $.ajax({
      url: baseURL + "api/customer/CustomerCookies/setSessionId",
      type: "POST",
      async: false,
      data: {
        data: {
          CUSTOMER_ID: customerID
        }
      },
      success: (result) => {
        console.log(result);
        console.log(UtilObject.getAllCookies());
      },
      error: (err) => {
        console.log(err);
      }
    });
  };
}
