import "owl.carousel";
import Util from "../helpers/util";
import favUtil from "../helpers/favutil";
var UtilObject = new Util();
var favObject = new favUtil();

let category;
var spacing = "mb-3";
let vendors, picklistID;
var deviceTypeHomePage;
var customerFavList = 0;

import s3Util from "../helpers/s3Util";
var s3Object = new s3Util();

//Loading and using Google maps helper functions
import GoogleMapsApi from "../helpers/GoogleMapsApi";
var GoogleMapsApiObjectVendor = new GoogleMapsApi();
export default class VendorListing {
  constructor() {
    //Vendor Listing Page
    this.grid = $(".vendorListingPage");
    category = $("div.vendorListingPage").attr("name");
    // this.clearFilter = $(".clearFilterBtn");
    this.specialityItems = $(".top-specialities-items");

    var screenWidthHp = $(window).width();
    if (screenWidthHp < 768) {
      //show mobile view
      console.log("mobile width....");
      deviceTypeHomePage = "mobile";
    } else {
      deviceTypeHomePage = "desktop";
    }
    console.log(globalThis.LAT);
    console.log(globalThis.LNG);
    console.log(BUFFER);
    //Registering Events
    this.bindEvents();
  }

  bindEvents = () => {
    console.log(baseURL);
    //console.log(LAT);
    //console.log(LNG);
    $(window).on("load", $.ready.then(this.calOwlCarousel));
    $(window).on("load", $.ready.then(this.getCategoryDetails));
    this.checkFavourites();

    //clear and select filter for desktop
    $(".clearPriceFilterBtn").on("click", this.clearPriceFilterDesktop);
    $(".clearRatingFilterBtn").on("click", this.clearRatingFilterDesktop);
    $(".clearDistanceFilterBtn").on("click", this.clearDistanceFilterDesktop);
    $(".clearDeliverytypeFilterBtn").on(
      "click",
      this.clearDeliverytypeFilterDesktop
    );
    $(".clearMinorderFilterBtn").on("click", this.clearMinorderFilterDesktop);
    $(".clearSpecialityFilterBtn").on(
      "click",
      this.clearSpecialityFilterDesktop
    );

    $(".selectPriceFilterBtn").on("click", this.selectPriceFilterDesktop);
    $(".selectRatingFilterBtn").on("click", this.selectRatingFilterDesktop);
    $(".selectDistanceFilterBtn").on("click", this.selectDistanceFilterDesktop);
    $(".selectDeliverytypeFilterBtn").on(
      "click",
      this.selectDeliverytypeFilterDesktop
    );
    $(".selectMinorderFilterBtn").on("click", this.selectMinorderFilterDesktop);
    $(".selectSpecialityFilterBtn").on(
      "click",
      this.selectSpecialityFilterDesktop
    );

    //clear and select filter for mobile
    $(".clearPriceFilterBtn").on("click", this.clearPriceFilterMobile);
    $(".clearRatingFilterBtn").on("click", this.clearRatingFilterMobile);
    $(".clearDistanceFilterBtn").on("click", this.clearDistanceFilterMobile);
    $(".clearDeliverytypeFilterBtn").on(
      "click",
      this.clearDeliverytypeFilterMobile
    );
    $(".clearMinorderFilterBtn").on("click", this.clearMinorderFilterMobile);
    $(".clearSpecialityFilterBtn").on(
      "click",
      this.clearSpecialityFilterMobile
    );

    $(".selectPriceFilterBtn").on("click", this.selectPriceFilterMobile);
    $(".selectRatingFilterBtn").on("click", this.selectRatingFilterMobile);
    $(".selectDistanceFilterBtn").on("click", this.selectDistanceFilterMobile);
    $(".selectDeliverytypeFilterBtn").on(
      "click",
      this.selectDeliverytypeFilterMobile
    );
    $(".selectMinorderFilterBtn").on("click", this.selectMinorderFilterMobile);
    $(".selectSpecialityFilterBtn").on(
      "click",
      this.selectSpecialityFilterMobile
    );

    $(".clearAllFilterBtn").on("click", this.clearAllFilter);
    // $(".selectAllFilterBtn").on("click", this.selectAllFilter);
    $(".applyFilterBtn").on("click", this.applyFilter);
  };

  calOwlCarousel = () => {
    $(".carousel_se_1_carousel").owlCarousel({
      nav: true,
      dots: false,
      mouseDrag: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 3
        },
        480: {
          items: 3
        },
        767: {
          items: 4
        },
        992: {
          items: 5
        },
        1200: {
          items: 6
        }
      }
    });

    $(".carousel_se_2_carousel").owlCarousel({
      nav: true,
      mouseDrag: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1
        },
        480: {
          items: 1
        },
        767: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 4
        }
      }
    });
  };

  getCategoryDetails = () => {
    console.log(category);
    $.ajax({
      url: baseURL + "api/customer/VendorsList/getCategoryDetails",
      type: "POST",
      async: false,
      data: {
        category: category
      },
      headers: { Authorization: X_API_KEY },
      success: this.showOnVendorListingPage,
      error: function (error) {
        console.log(error);
      }
    });
  };

  showOnVendorListingPage = (result) => {
    picklistID = result[0].Picklist_ID;
    console.log(result);
    if (result?.code == undefined || result?.code != 0) {
      //top specialities
      $.ajax({
        url: baseURL + "api/customer/VendorsList/getSpecialities",
        type: "POST",
        async: false,
        data: {
          Picklist_parent_ID: result[0].Picklist_ID
        },
        headers: { Authorization: X_API_KEY },
        success: (res) => {
          //removing previous specialities
          var len = $(".owl-carousel.top-specialities-items").find(
            ".owl-item"
          ).length;
          console.log(len);
          for (var i = 0; i < len; i++) {
            $(".owl-carousel.top-specialities-items").owlCarousel("remove", 0);
          }
          console.log(res);
          console.log(res?.code == undefined);
          //clearing specialty items in the filter options
          $(".v-pills-speciality-items").empty();
          $(".mobile-filter-speciality-items").empty();

          if (res?.code == undefined || res?.code != 0) {
            res.forEach((element) => {
              var item = `<div class="item ${element.Picklist_ID}" role="button" value="${element.Picklist_ID}">
            <div class="col-md-12 wow fadeInUp delay-2 d-flex justify-content-center">
                <div class="text-center zoom">
                    <a class="btn-light rounded-circle" style="margin-top:0.5rem; ">
                        <img class="rounded-4 ${element.Picklist_ID}-specialityImage" src="" alt="${element.Picklist_display_value}-Image" width="20px">
                    </a>
                    <h6 class="truncate pt-2">${element.Picklist_display_value}</h6>
                </div>
            </div>
             </div>`;

              $(".owl-carousel.top-specialities-items")
                .owlCarousel("add", item)
                .owlCarousel("update");

              var speciality = `<div class="form-check">
              <input class="form-check-input speciality" type="checkbox" value="${element.Picklist_display_value}" id="flexCheckDefault" />
              <label class="form-check-label" for="flexCheckDefault">${element.Picklist_display_value}</label>
          </div>`;
              //Adding speciality accoridng to the category
              $(".v-pills-speciality-items").append(speciality);
              $(".mobile-filter-speciality-items").append(speciality);
            });
            console.log([
              ...document.querySelectorAll(".top-specialities-items .item")
            ]);
            var items = [
              ...document.querySelectorAll(".top-specialities-items .item")
            ];
            items.forEach((item) => {
              item.addEventListener("click", this.getSpeciality);
            });
            // $(".top-specialities-items")
            //   .find(".item")
            //   .on("click", this.getSpeciality);
          } else {
            $(".top-specialities").hide();
          }

          //Getting Min Max for Category
          console.log(result[0].Picklist_ID);
          $.ajax({
            url: baseURL + "api/customer/VendorsList/getMinMaxAmountForVendor",
            type: "POST",
            async: false,
            data: {
              Category_ID: result[0].Picklist_ID
            },
            headers: { Authorization: X_API_KEY },
            success: (res) => {
              //clearing earlier
              $(".v-pills-minorder-items").empty();
              $(".mobile-filter-minorder-items").empty();

              console.log(res);
              var min = parseInt(res[0].minimum);
              var max = parseInt(res[0].maximum);

              console.log(min);
              console.log(max);

              var interval = parseInt((max - min) / 5);
              console.log(interval);
              while (min < max) {
                var minItems = `<div class="form-check">
              <input class="form-check-input minorder" type="checkbox" value="${min}-${
                  min + interval
                }" id="flexCheckDefault" />
              <label class="form-check-label" for="flexCheckDefault">Rs ${min} - Rs ${
                  min + interval
                } </label>
          </div>`;

                $(".v-pills-minorder-items").append(minItems);
                $(".mobile-filter-minorder-items").append(minItems);
                min += interval;
              }
            },
            error: (res) => {
              console.log(res);
            }
          });

          // //Get vendor by Category which has near you and away from you
          // $.ajax({
          //   url: baseURL + "api/customer/VendorsList/getVendorByCategory",
          //   type: "POST",
          //   async: false,
          //   data: {
          //     buffer: BUFFER,
          //     lat: LAT,
          //     lon: LNG,
          //     Picklist_ID: result[0].Picklist_ID
          //   },
          //   headers: { Authorization: X_API_KEY },
          //   success: (res) => {
          //     console.log(res);
          //     if (res.NearMeCount + res.AwayfromMeCount === 0) {
          //       $(".noVendors").removeClass("d-none");
          //     } else {
          //       $(".vendorListingPage").removeClass("d-none");
          //       $(".total").html(res.NearMeCount + res.AwayfromMeCount);

          //       if (res.NearMeCount == 0) {
          //         $(".near-you").hide();
          //       } else {
          //         //Near You
          //         $(".near-you").show();
          //         //Clearing previous items
          //         console.log(deviceTypeHomePage);
          //         if (deviceTypeHomePage == "desktop") {
          //           var len = $(`.owl-carousel.near-you-items`).find(
          //             ".owl-item"
          //           ).length;
          //           for (var i = 0; i < len; i++) {
          //             $(`.owl-carousel.near-you-items`).owlCarousel(
          //               "remove",
          //               0
          //             );
          //           }
          //         } else {
          //           $(`.near-you-items`).empty();
          //         }

          //         var vendorIDs = [];
          //         var tempObj = {};
          //         res.NearMe.forEach((element) => {
          //           tempObj = {
          //             lat: element.lat,
          //             lng: element.lon,
          //             title: element.Business_Name
          //           };
          //           vendorIDs.push(tempObj);
          //         });

          //         // Convert JSON object array to string
          //         var vendorIDsString = vendorIDs
          //           .map((obj) => {
          //             return JSON.stringify(obj);
          //           })
          //           .join(",");
          //         $(".mapMarkerBtnClassCategory").attr(
          //           "data-vendorsdata",
          //           `${vendorIDsString}`
          //         );
          //         $(`.mapMarkerBtnClassCategory`).on("click", (e) => {
          //           GoogleMapsApiObjectVendor.openGMapModal(e);
          //         });
          //         //Showing new items
          //         res.NearMe.forEach((element) => {
          //           var sponsoredContent = "";
          //           var favItem = this.checkFavourites(
          //             "check",
          //             element.Vendor_ID
          //           );
          //           if (element.Business_Name.length > 28) {
          //             spacing = "mb-0";
          //           } else {
          //             spacing = "mb-3";
          //           }

          //           //adding rating stars
          //           if (element.Vendor_Rating > 0) {
          //             var color = "background: rgba(244, 148, 49);";
          //           }

          //           var vendRatingScore = "";
          //           if (element.Vendor_Rating == null) {
          //             vendRatingScore = 0;
          //           } else {
          //             vendRatingScore = element.Vendor_Rating;
          //           }

          //           //New change from homepage
          //           if (deviceTypeHomePage == "desktop") {
          //             console.log("desktop near you items");
          //             if (element.isSponsored > 0) {
          //               sponsoredContent = `<div class="mt-4 pt-1 fixed-top">
          //                                       <h5 class="text-white ml-3 w-50 shadow rounded" style="background: rgba(244, 148, 49);">Sponsored</h5>
          //                                   </div>`;
          //             }
          //             //Adding near you items
          //             //vendorsDesktop
          //             var item = `<div class="item zoom d-flex justify-content-around h-100">
          //                         <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
          //                         <div class=" wow fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;">
          //                           <div class="w-100 rounded text-center favourites-container">
          //                           <img src="" class="${element.Vendor_ID}-vendImg rounded shadow-lg" alt="${element.Business_Name}-Image">
          //                           </a>
          //                           ${sponsoredContent}
          //                           ${favItem}
          //                           </div>
          //                           <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
          //                           <div class="stats mt-2">

          //                             <div class="row d-flex justify-content-between ${spacing}">
          //                               <div class="col-sm-12 truncate">
          //                               <div class="row">
          //                                                       <div class="col-11"><h6><strong>${element.Business_Name}</strong></h6></div>
          //                                                       <div class="col-1 p-0">
          //                                                         <button type="button" class="mapMarkerBtnClass-${element.Vendor_ID} btn p-0 m-0 h-100 w-100 " data-vendorid="${element.Vendor_ID}">
          //                                                           <i class="fas fa-map-marker-alt ml-1"></i></button>
          //                                                       </div>
          //                                                     </div>
          //                               <h6>${element.City}</h6>
          //                               </div>
          //                             </div>
          //                             <div class="row d-flex justify-content-between">
          //                               <div class="col-sm-12 truncate-one">
          //                               ${element.Specialities}
          //                               </div>
          //                             </div>
          //                             <div class="row ">
          //                             <div class="col pr-0 d-flex justify-content-start">
          //                                     <p class="mb-0 pt-2">Min Order:₹${element.Minimum_Order}</p>
          //                                     </div>
          //                                     <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
          //                                       <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${vendRatingScore} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
          //                                     </div>
          //                             </div>
          //                           </div>
          //                         </div>
          //                         </a>
          //                       </div>`;

          //             $(`.owl-carousel.near-you-items`)
          //               .owlCarousel("add", item)
          //               .owlCarousel("update");
          //             $(`.mapMarkerBtnClass-${element.Vendor_ID}`).on(
          //               "click",
          //               (e) => {
          //                 GoogleMapsApiObjectVendor.openGMapModal(e);
          //               }
          //             );
          //           } else {
          //             //mobile view
          //             if (element.isSponsored > 0) {
          //               sponsoredContent = `<p class="carousel-caption text-white shadow rounded p-0 m-0" style="background: rgba(244, 148, 49);">Sponsored</p>`;
          //             }

          //             //vendorsMobile
          //             var item = `<div class="card my-2">
          //                           <div class="row">
          //                               <div class="col-4 py-2 ml-2 pr-0 favourites-container" style="width:30%;">
          //                                   ${favItem}
          //                                   <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
          //                                       <img src="" class="${element.Vendor_ID}-vendImg rounded-3 card-img-top"></img>
          //                                       ${sponsoredContent}
          //                                   </a>
          //                               </div>
          //                               <div class="col pl-0">
          //                                   <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
          //                                       <div class="card-body h-100">
          //                                           <div class="row h-100 d-flex justify-content-between">
          //                                                 <div class="col-11 pr-0">
          //                                                  <h5 class="card-title truncate-newHomePage">${element.Business_Name}</h5>
          //                                                 </div>
          //                                                 <div class="col-1 p-0">
          //                                                   <button type="button" class="mapMarkerBtnClass-${element.Vendor_ID} btn p-0 m-0 h-100 w-100 " data-vendorid="${element.Vendor_ID}">
          //                                                     <i class="fas fa-map-marker-alt ml-1"></i>
          //                                                   </button>
          //                                                 </div>
          //                                               <div class="col-12">
          //                                                   <p class="truncate-newHomePage">${element.City}</p>
          //                                               </div>
          //                                               <div class="col-12">
          //                                                   <p class="truncate-newHomePage">${element.Specialities}</p>
          //                                               </div>
          //                                               <div class="col-6 align-items-end mb-0">Min Order:₹${element.Minimum_Order}</div>
          //                                               <div class="col-6">
          //                                                   <div class="row">
          //                                                       <div class="col d-flex justify-content-end">
          //                                                           <div class="ratingBorder align-items-center d-inline-flex text-white mb-0 pl-2" style="${color}">
          //                                                               <p class="mb-0">${vendRatingScore}</p>
          //                                                               <i class="fa fa-star ml-1" aria-hidden="true" style="color: white;"></i>
          //                                                           </div>
          //                                                       </div>
          //                                                   </div>
          //                                               </div>
          //                                           </div>
          //                                       </div>
          //                                   </a>
          //                               </div>
          //                           </div>
          //                       </div>`;

          //             $(`.near-you-items`).append(item);
          //             $(`.mapMarkerBtnClass-${element.Vendor_ID}`).on(
          //               "click",
          //               (e) => {
          //                 GoogleMapsApiObjectVendor.openGMapModal(e);
          //               }
          //             );
          //           }
          //         });

          //         //unbinds any previous event handlers
          //         $(".likeBtnHomepage").off("click");
          //         $(".likeBtnHomepage").on("click", this.setFavourites);

          //         //for images
          //         res.NearMe.forEach(async (element) => {
          //           var vendorImg = await s3Object.getObjectURL(
          //             "Vendor/" +
          //               element.Image_URL +
          //               "/Document Images/Business Image/1.png"
          //           );

          //           $(`.${element.Vendor_ID}-vendImg`).attr("src", vendorImg);
          //         });
          //       }
          //       if (res.AwayfromMe == 0) {
          //         $(".away-from-you").hide();
          //       } else {
          //         //Away From You
          //         $(".away-from-you").show();
          //         //Clearing earlier items
          //         if (deviceTypeHomePage == "desktop") {
          //           var len = $(`.owl-carousel.away-from-you-items`).find(
          //             ".owl-item"
          //           ).length;
          //           for (var i = 0; i < len; i++) {
          //             $(`.owl-carousel.away-from-you-items`).owlCarousel(
          //               "remove",
          //               0
          //             );
          //           }
          //         } else {
          //           $(`.away-from-you-items`).empty();
          //         }

          //         var vendorIDs = [];
          //         var tempObj = {};
          //         res.AwayfromMe.forEach((element) => {
          //           tempObj = {
          //             lat: element.lat,
          //             lng: element.lon,
          //             title: element.Business_Name
          //           };
          //           vendorIDs.push(tempObj);
          //         });

          //         // Convert JSON object array to string
          //         var vendorIDsString = vendorIDs
          //           .map((obj) => {
          //             return JSON.stringify(obj);
          //           })
          //           .join(",");
          //         $(".mapMarkerBtnClassCategory").attr(
          //           "data-vendorsdata",
          //           `${vendorIDsString}`
          //         );
          //         $(`.mapMarkerBtnClassCategory`).on("click", (e) => {
          //           GoogleMapsApiObjectVendor.openGMapModal(e);
          //         });

          //         //showing away from you
          //         res.AwayfromMe.forEach(async (element) => {
          //           var sponsoredContent = "";
          //           var favItem = this.checkFavourites(
          //             "check",
          //             element.Vendor_ID
          //           );
          //           if (element.Business_Name.length > 28) {
          //             spacing = "mb-0";
          //           } else {
          //             spacing = "mb-3";
          //           }
          //           //for vendor review stars
          //           //adding rating stars
          //           if (element.Vendor_Rating > 0) {
          //             var color = "background: rgba(244, 148, 49);";
          //           }

          //           var vendRatingScore = "";
          //           if (element.Vendor_Rating == null) {
          //             vendRatingScore = 0;
          //           } else {
          //             vendRatingScore = element.Vendor_Rating;
          //           }

          //           //New change from homepage
          //           if (deviceTypeHomePage == "desktop") {
          //             if (element.isSponsored > 0) {
          //               sponsoredContent = `<div class="mt-4 pt-1 fixed-top">
          //           <h5 class="text-white ml-3 w-50 shadow rounded" style="background: rgba(244, 148, 49);">Sponsored</h5>
          //       </div>`;
          //             }
          //             //vendorsDesktop

          //             var item = `<div class="item zoom d-flex justify-content-around h-100">
          //                         <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
          //                         <div class=" wow fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;">
          //                           <div class="w-100 rounded text-center favourites-container">
          //                           <img src="" class="${element.Vendor_ID}-vendImg rounded shadow-lg" alt="${element.Business_Name}-Image">
          //                           </a>
          //                           ${sponsoredContent}
          //                           ${favItem}
          //                           </div>
          //                           <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
          //                           <div class="stats mt-2">

          //                             <div class="row d-flex justify-content-between ${spacing}">
          //                               <div class="col-sm-12 truncate">
          //                                 <div class="row">
          //                                   <div class="col-11"><h6><strong>${element.Business_Name}</strong></h6></div>
          //                                   <div class="col-1 p-0">
          //                                     <button type="button" class="mapMarkerBtnClass-${element.Vendor_ID} btn p-0 m-0 h-100 w-100 " data-vendorid="${element.Vendor_ID}">
          //                                       <i class="fas fa-map-marker-alt ml-1"></i></button>
          //                                   </div>
          //                                 </div>
          //                               <h6>${element.City}</h6>
          //                               </div>
          //                             </div>
          //                             <div class="row d-flex justify-content-between">
          //                               <div class="col-sm-12 truncate-one">
          //                               ${element.Specialities}
          //                               </div>
          //                             </div>
          //                             <div class="row ">
          //                             <div class="col pr-0 d-flex justify-content-start">
          //                                     <p class="mb-0 pt-2">Min Order:₹${element.Minimum_Order}</p>
          //                                     </div>
          //                                     <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
          //                                       <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${vendRatingScore} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
          //                                     </div>
          //                             </div>
          //                           </div>
          //                         </div>
          //                         </a>
          //                       </div>`;

          //             $(".owl-carousel.away-from-you-items")
          //               .owlCarousel("add", item)
          //               .owlCarousel("update");
          //             $(`.mapMarkerBtnClass-${element.Vendor_ID}`).on(
          //               "click",
          //               (e) => {
          //                 GoogleMapsApiObjectVendor.openGMapModal(e);
          //               }
          //             );
          //           } else {
          //             //mobile view vendorsMobile
          //             if (element.isSponsored > 0) {
          //               sponsoredContent = `<p class="carousel-caption text-white shadow rounded p-0 m-0" style="background: rgba(244, 148, 49);">Sponsored</p>`;
          //             }

          //             var item = `<div class="card my-2">
          //                           <div class="row">
          //                               <div class="col-4 py-2 ml-2 pr-0 favourites-container" style="width:30%;">
          //                                   ${favItem}
          //                                   <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
          //                                       <img src="" class="${element.Vendor_ID}-vendImg rounded-3 card-img-top"></img>
          //                                       ${sponsoredContent}
          //                                   </a>
          //                               </div>
          //                               <div class="col pl-0">
          //                                   <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
          //                                       <div class="card-body h-100">
          //                                           <div class="row h-100 d-flex justify-content-between">
          //                                               <div class="col-11 pr-0">
          //                                                   <h5 class="card-title truncate-newHomePage">${element.Business_Name}</h5>
          //                                               </div>
          //                                               <div class="col-1 p-0">
          //                                                 <button type="button" class="mapMarkerBtnClass-${element.Vendor_ID} btn p-0 m-0 h-100 w-100 " data-vendorid="${element.Vendor_ID}">
          //                                                   <i class="fas fa-map-marker-alt ml-1"></i>
          //                                                 </button>
          //                                               </div>
          //                                               <div class="col-12">
          //                                                   <p class="truncate-newHomePage">${element.City}</p>
          //                                               </div>
          //                                               <div class="col-12">
          //                                                   <p class="truncate-newHomePage">${element.Specialities}</p>
          //                                               </div>
          //                                               <div class="col-6 align-items-end mb-0">Min Order:₹${element.Minimum_Order}</div>
          //                                               <div class="col-6">
          //                                                   <div class="row">
          //                                                       <div class="col d-flex justify-content-end">
          //                                                           <div class="ratingBorder align-items-center d-inline-flex text-white mb-0 pl-2" style="${color}">
          //                                                               <p class="mb-0">${vendRatingScore}</p>
          //                                                               <i class="fa fa-star ml-1" aria-hidden="true" style="color: white;"></i>
          //                                                           </div>
          //                                                       </div>
          //                                                   </div>
          //                                               </div>
          //                                           </div>
          //                                       </div>
          //                                   </a>
          //                               </div>
          //                           </div>
          //                       </div>`;

          //             $(`.away-from-you-items`).append(item);
          //             $(`.mapMarkerBtnClass-${element.Vendor_ID}`).on(
          //               "click",
          //               (e) => {
          //                 GoogleMapsApiObjectVendor.openGMapModal(e);
          //               }
          //             );
          //           }
          //         });
          //         //unbinds any previous event handlers
          //         $(".likeBtnHomepage").off("click");
          //         $(".likeBtnHomepage").on("click", this.setFavourites);

          //         //for images
          //         res.AwayfromMe.forEach(async (element) => {
          //           var vendorImg = await s3Object.getObjectURL(
          //             "Vendor/" +
          //               element.Image_URL +
          //               "/Document Images/Business Image/1.png"
          //           );

          //           $(`.${element.Vendor_ID}-vendImg`).attr("src", vendorImg);
          //         });
          //       }
          //     }
          //   },
          //   error: (res) => {
          //     console.log(res);
          //   }
          // });

          //Get all vendor by Category sorted by distance
          $.ajax({
            url: baseURL + "api/customer/VendorsList/getVendorByCategory",
            type: "POST",
            async: false,
            data: {
              buffer: BUFFER,
              lat: LAT,
              lon: LNG,
              Picklist_ID: result[0].Picklist_ID
            },
            headers: { Authorization: X_API_KEY },
            success: (res) => {
              console.log(res);
              if (res.ResultCount === 0) {
                $(".noVendors").removeClass("d-none");
              } else {
                $(".vendorListingPage").removeClass("d-none");
                $(".total").html(res.ResultCount);

                //Clearing previous items
                console.log(deviceTypeHomePage);
                $(`.all-result-items`).empty();

                $(`.allResults`).show();

                var vendorIDs = [];
                var tempObj = {};
                res.Result.forEach((element) => {
                  tempObj = {
                    lat: element.lat,
                    lng: element.lon,
                    title: element.Business_Name
                  };
                  vendorIDs.push(tempObj);
                });

                // Convert JSON object array to string
                var vendorIDsString = vendorIDs
                  .map((obj) => {
                    return JSON.stringify(obj);
                  })
                  .join(",");
                $(".mapMarkerBtnClassCategory").attr(
                  "data-vendorsdata",
                  `${vendorIDsString}`
                );
                $(`.mapMarkerBtnClassCategory`).on("click", (e) => {
                  GoogleMapsApiObjectVendor.openGMapModal(e);
                });
                //Showing new items
                res.Result.forEach((element) => {
                  var sponsoredContent = "";
                  var favItem = this.checkFavourites(
                    "check",
                    element.Vendor_ID
                  );
                  if (element.Business_Name.length > 28) {
                    spacing = "mb-0";
                  } else {
                    spacing = "mb-3";
                  }

                  //adding rating stars
                  if (element.Vendor_Rating > 0) {
                    var color = "background: rgba(244, 148, 49);";
                  }

                  var vendRatingScore = "";
                  if (element.Vendor_Rating == null) {
                    vendRatingScore = 0;
                  } else {
                    vendRatingScore = element.Vendor_Rating;
                  }

                  //New change from homepage
                  if (deviceTypeHomePage == "desktop") {
                    console.log("desktop near you items");
                    if (element.isSponsored > 0) {
                      sponsoredContent = `<div class="mt-4 pt-1 fixed-top">
                                                <h5 class="text-white ml-3 w-50 shadow rounded" style="background: rgba(244, 148, 49);">Sponsored</h5>
                                            </div>`;
                    }
                    //Adding near you items
                    //vendorsDesktop
                    var item = `<div class="item zoom d-flex justify-content-around h-100 col-12 col-sm-4 col-md-3">
                                  <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
                                  <div class=" wow fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;">
                                    <div class="w-100 rounded text-center favourites-container">
                                    <img src="" class="${element.Vendor_ID}-vendImg rounded shadow-lg" alt="${element.Business_Name}-Image">
                                    </a>
                                    ${sponsoredContent}
                                    ${favItem}
                                    </div>
                                    <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
                                    <div class="stats mt-2">

                                      <div class="row d-flex justify-content-between ${spacing}">
                                        <div class="col-sm-12 truncate">
                                        <div class="row">
                                                                <div class="col-11"><h6><strong>${element.Business_Name}</strong></h6></div>
                                                                <div class="col-1 p-0">
                                                                  <button type="button" class="mapMarkerBtnClass-${element.Vendor_ID} btn p-0 m-0 h-100 w-100 " data-vendorid="${element.Vendor_ID}">
                                                                    <i class="fas fa-map-marker-alt ml-1"></i></button>
                                                                </div>
                                                              </div>                                 
                                        <h6>${element.City}</h6>
                                        </div>
                                      </div>
                                      <div class="row d-flex justify-content-between">
                                        <div class="col-sm-12 truncate-one">
                                        ${element.Specialities}
                                        </div>
                                      </div>
                                      <div class="row ">
                                      <div class="col pr-0 d-flex justify-content-start">
                                              <p class="mb-0 pt-2">Min Order:₹${element.Minimum_Order}</p>
                                              </div>
                                              <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                                                <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${vendRatingScore} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                                              </div>
                                      </div>
                                    </div>
                                  </div>
                                  </a>
                                </div>`;

                    $(`.all-result-items`).append(item);
                    $(`.mapMarkerBtnClass-${element.Vendor_ID}`).on(
                      "click",
                      (e) => {
                        GoogleMapsApiObjectVendor.openGMapModal(e);
                      }
                    );
                  } else {
                    //mobile view
                    if (element.isSponsored > 0) {
                      sponsoredContent = `<p class="carousel-caption text-white shadow rounded p-0 m-0" style="background: rgba(244, 148, 49);">Sponsored</p>`;
                    }

                    //vendorsMobile
                    var item = `<div class="card my-2">
                                    <div class="row">
                                        <div class="col-4 py-2 ml-2 pr-0 favourites-container" style="width:30%;">
                                            ${favItem}
                                            <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
                                                <img src="" class="${element.Vendor_ID}-vendImg rounded-3 card-img-top"></img>
                                                ${sponsoredContent}
                                            </a>
                                        </div>
                                        <div class="col pl-0">
                                            <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
                                                <div class="card-body h-100">
                                                    <div class="row h-100 d-flex justify-content-between">                                         
                                                          <div class="col-11 pr-0">
                                                           <h5 class="card-title truncate-newHomePage">${element.Business_Name}</h5>
                                                          </div>
                                                          <div class="col-1 p-0">
                                                            <button type="button" class="mapMarkerBtnClass-${element.Vendor_ID} btn p-0 m-0 h-100 w-100 " data-vendorid="${element.Vendor_ID}">
                                                              <i class="fas fa-map-marker-alt ml-1"></i>
                                                            </button>
                                                          </div>
                                                        <div class="col-12">
                                                            <p class="truncate-newHomePage">${element.City}</p>
                                                        </div>
                                                        <div class="col-12">
                                                            <p class="truncate-newHomePage">${element.Specialities}</p>
                                                        </div>
                                                        <div class="col-6 align-items-end mb-0">Min Order:₹${element.Minimum_Order}</div>
                                                        <div class="col-6">
                                                            <div class="row">
                                                                <div class="col d-flex justify-content-end">
                                                                    <div class="ratingBorder align-items-center d-inline-flex text-white mb-0 pl-2" style="${color}">
                                                                        <p class="mb-0">${vendRatingScore}</p> 
                                                                        <i class="fa fa-star ml-1" aria-hidden="true" style="color: white;"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>`;

                    $(`.all-result-items`).append(item);
                    $(`.mapMarkerBtnClass-${element.Vendor_ID}`).on(
                      "click",
                      (e) => {
                        GoogleMapsApiObjectVendor.openGMapModal(e);
                      }
                    );
                  }
                });

                //unbinds any previous event handlers
                $(".likeBtnHomepage").off("click");
                $(".likeBtnHomepage").on("click", this.setFavourites);

                //for images
                res.Result.forEach(async (element) => {
                  var vendorImg = await s3Object.getObjectURL(
                    "Vendor/" +
                      element.Image_URL +
                      "/Document Images/Business Image/1.png"
                  );

                  $(`.${element.Vendor_ID}-vendImg`).attr("src", vendorImg);
                });
              }
            },
            error: (res) => {
              console.log(res);
            }
          });

          if (res?.code == undefined || res?.code != 0) {
            res.forEach(async (element) => {
              var categoryImg = await s3Object.getObjectURL(element.Icon_Url); //element.Image_URL
              $(`.${element.Picklist_ID}-specialityImage`).attr(
                "src",
                categoryImg
              );
            });
          }
        },
        error: (res) => {
          console.log(res);
        }
      });
      $(".vendorListingPage").removeClass("d-none");
    } else {
      $(".noVendors").removeClass("d-none");
    }
  };
  //clear  filter for specific tab  for desktop
  clearPriceFilterDesktop = () => {
    $("#v-pills-price input[type=checkbox]").prop("checked", false);
  };
  clearRatingFilterDesktop = () => {
    $("#v-pills-rating input[type=checkbox]").prop("checked", false);
  };
  clearDistanceFilterDesktop = () => {
    $("#v-pills-distance input[type=checkbox]").prop("checked", false);
  };
  clearDeliverytypeFilterDesktop = () => {
    $("#v-pills-deliverytype input[type=checkbox]").prop("checked", false);
  };
  clearMinorderFilterDesktop = () => {
    $("#v-pills-minorder input[type=checkbox]").prop("checked", false);
  };
  clearSpecialityFilterDesktop = () => {
    $("#v-pills-speciality input[type=checkbox]").prop("checked", false);
  };

  //select  filter for specific tab for desktop
  selectPriceFilterDesktop = () => {
    $("#v-pills-price input[type=checkbox]").prop("checked", true);
  };
  selectRatingFilterDesktop = () => {
    $("#v-pills-rating input[type=checkbox]").prop("checked", true);
  };
  selectDistanceFilterDesktop = () => {
    $("#v-pills-distance input[type=checkbox]").prop("checked", true);
  };
  selectDeliverytypeFilterDesktop = () => {
    $("#v-pills-deliverytype input[type=checkbox]").prop("checked", true);
  };
  selectMinorderFilterDesktop = () => {
    $("#v-pills-minorder input[type=checkbox]").prop("checked", true);
  };
  selectSpecialityFilterDesktop = () => {
    $("#v-pills-speciality input[type=checkbox]").prop("checked", true);
  };

  //clear  filter for specific tab  for mobile
  clearPriceFilterMobile = () => {
    $("#flush-collapseOne input[type=checkbox]").prop("checked", false);
  };
  clearRatingFilterMobile = () => {
    $("#flush-collapseTwo input[type=checkbox]").prop("checked", false);
  };
  clearDistanceFilterMobile = () => {
    $("#flush-collapseThree input[type=checkbox]").prop("checked", false);
  };
  clearDeliverytypeFilterMobile = () => {
    $("#flush-collapseFour input[type=checkbox]").prop("checked", false);
  };
  clearMinorderFilterMobile = () => {
    $("#flush-collapseFive input[type=checkbox]").prop("checked", false);
  };
  clearSpecialityFilterMobile = () => {
    $("#flush-collapseSix input[type=checkbox]").prop("checked", false);
  };

  //select  filter for specific tab for mobile
  selectPriceFilterMobile = () => {
    $("#flush-collapseOne input[type=checkbox]").prop("checked", true);
  };
  selectRatingFilterMobile = () => {
    $("#flush-collapseTwo input[type=checkbox]").prop("checked", true);
  };
  selectDistanceFilterMobile = () => {
    $("#flush-collapseThree input[type=checkbox]").prop("checked", true);
  };
  selectDeliverytypeFilterMobile = () => {
    $("#flush-collapseFour input[type=checkbox]").prop("checked", true);
  };
  selectMinorderFilterMobile = () => {
    $("#flush-collapseFive input[type=checkbox]").prop("checked", true);
  };
  selectSpecialityFilterMobile = () => {
    $("#flush-collapseSix input[type=checkbox]").prop("checked", true);
  };

  //Clear All filter for desktop and mobile
  clearAllFilter = () => {
    $("input[type=checkbox]").prop("checked", false);

    //refreshing the page to original
    $(".main-filter-display-items").empty();
    $(".top-specialities").show();
    $(".near-you").show();
    //$(".near-you-items").empty();
    $(".away-from-you").show();
    //$(".away-from-you-items").empty();
    this.getCategoryDetails();
  };

  //   //select All filter for desktop and mobile
  // selectAllFilter = () => {
  //   $('input[type=checkbox]').prop('checked', true);

  // };
  //apply filter for desktop and mobile

  //Apply filter for desktop and mobile
  applyFilter = (event) => {
    console.log(event);
    var filterOptions = [];

    var filter = {};

    [...$("#v-pills-tabContent").children()].forEach((item) => {
      var filterItem = item.getAttribute("id").split("-")[2];
      filter[filterItem] = [];
      filterOptions = filterOptions.concat(filterItem);
    });
    console.log(filterOptions);
    console.log(filter);

    //No of checked items for Desktop
    var checkedDesktop = $(
      ".desktop-filter-options .form-check :checkbox:checked"
    ).length;
    console.log(checkedDesktop);

    //No of checked items for Mobile
    var checkedMobile = $(
      ".mobile-filter-options .form-check :checkbox:checked"
    ).length;
    console.log(checkedMobile);

    if (checkedDesktop > 0 || checkedMobile > 0) {
      console.log("Filter applied");
      $(".filterError").html("Filter Applied");

      //Remove Error Message after 5 seconds
      setTimeout(() => {
        $(".filterError").html("");
      }, 10000);

      //Getting list of values which are checked
      var checkedValuesDesktop = $(
        ".desktop-filter-options .form-check :checkbox:checked"
      );
      console.log([...checkedValuesDesktop]);
      checkedValuesDesktop = [...checkedValuesDesktop];

      var checkedValuesMobile = $(
        ".mobile-filter-options .form-check :checkbox:checked"
      );
      console.log([...checkedValuesMobile]);
      checkedValuesMobile = [...checkedValuesMobile];

      var viewType = event.currentTarget
        .getAttribute("class")
        .split(" ")
        .pop()
        .split("-")[0];
      console.log(viewType);

      //Creating different conditions for the filter option
      if (viewType == "desktop") {
        checkedValuesDesktop.forEach((checkbox) => {
          var checkboxClass = checkbox.getAttribute("class").split(" ")[1];
          if (filterOptions.includes(checkboxClass)) {
            filter[checkboxClass].push(
              checkbox.getAttribute("value").split("-")
            );
          }
        });
        //closing modal
        $(".desktop-main-filter").attr("data-dismiss", "modal");
      } else if (viewType == "mobile") {
        checkedValuesMobile.forEach((checkbox) => {
          var checkboxClass = checkbox.getAttribute("class").split(" ")[1];
          if (filterOptions.includes(checkboxClass)) {
            filter[checkboxClass].push(
              checkbox.getAttribute("value").split("-")
            );
          }
        });
        //closing modal
        $(".mobile-main-filter").attr("data-dismiss", "modal");
      }

      console.log(filter);

      this.getVendorByFilter(filter);
    } else {
      console.log("Cant Apply filter please select a filter");
      $(".filterError").html("Cant Apply Filter Please Select A Filter");

      if (deviceTypeHomePage == "desktop") {
        $(".desktop-main-filter").removeAttr("data-dismiss");
      } else {
        $(".mobile-main-filter").removeAttr("data-dismiss");
      }

      //Remove Error Message after 5 seconds
      setTimeout(() => {
        $(".filterError").html("");
      }, 10000);
    }
  };

  //Get Vendor by RIGHT Filter
  getVendorByFilter = (filter) => {
    console.log(filter);

    var rating = "";
    var distance = "";
    var deliverytype = "";
    var minorder = "";
    var speciality = "";

    var finalCondition = "";

    for (const key in filter) {
      if (filter.hasOwnProperty.call(filter, key)) {
        const element = filter[key];
        console.log(element);

        if (key == "rating") {
          element.forEach((el) => {
            if (el[1] == "above") {
              rating = rating + `(Vendor_Rating >= ${el[0]}) OR `;
            } else if (el[1] == "below") {
              rating = rating + `(Vendor_Rating <= ${el[0]}) OR `;
            } else {
              rating =
                rating + `(Vendor_Rating BETWEEN ${el[0]} AND ${el[1]}) OR `;
            }
          });
        } else if (key == "distance") {
          element.forEach((el) => {
            if (el[1] == "above") {
              distance = distance + `(distance >= ${el[0]}) OR `;
            } else if (el[1] == "below") {
              distance = distance + `(distance <= ${el[0]}) OR `;
            } else {
              distance =
                distance + `(distance BETWEEN ${el[0]} AND ${el[1]}) OR `;
            }
          });
        } else if (key == "minorder") {
          element.forEach((el) => {
            if (el[1] == "above") {
              minorder = minorder + `(Minimum_Order >= ${el[0]}) OR `;
            } else if (el[1] == "below") {
              minorder = minorder + `(Minimum_Order <= ${el[0]}) OR `;
            } else {
              minorder =
                minorder + `(Minimum_Order BETWEEN ${el[0]} AND ${el[1]}) OR `;
            }
          });
        } else if (key == "deliverytype") {
          element.forEach((el) => {
            deliverytype = deliverytype + `(Delivery_Type = '${el[0]}') OR `;
          });
        } else if (key == "speciality") {
          element.forEach((el) => {
            speciality =
              speciality + `(Picklist_display_value = '${el[0]}') OR `;
          });
        }
      }
    }

    if (rating != "") {
      rating = "AND (" + rating.slice(0, -3) + ") ";
      finalCondition = finalCondition + rating;
    }

    if (minorder != "") {
      minorder = "AND (" + minorder.slice(0, -3) + ") ";
      finalCondition = finalCondition + minorder;
    }

    if (deliverytype != "") {
      deliverytype = " AND (" + deliverytype;
      deliverytype = deliverytype.slice(0, -3);
      deliverytype = deliverytype + ")";
    }

    if (speciality != "") {
      speciality = " AND (" + speciality;
      speciality = speciality.slice(0, -3);
      speciality = speciality + ")";
    }

    if (distance != "") {
      distance = "HAVING " + distance;
      distance = distance.slice(0, -3);
      finalCondition = finalCondition + distance;
    }
    // else if (key == "deliverytype") {
    //   element.forEach((el) => {
    //     finalCondition =
    //       finalCondition + `AND (D BETWEEN ${el[0]} AND ${el[1]})`;
    //   });
    // }

    // else if (key == "speciality") {
    //   element.forEach((el) => {
    //     finalCondition =
    //       finalCondition + `(Vendor_Rating BETWEEN ${el[0]} AND ${el[1]}) OR`;
    //   });
    // }
    console.log(rating);
    console.log(distance);
    console.log(deliverytype);
    console.log(minorder);
    console.log(speciality);

    console.log(finalCondition);

    //Get all vendors by category without buffer and with Main filters
    $.ajax({
      url: baseURL + "api/customer/VendorsList/getVendorWithMainFilter",
      type: "POST",
      async: false,
      data: {
        lat: LAT,
        lon: LNG,
        buffer: BUFFER,
        Picklist_ID: picklistID,
        Condition_1: finalCondition,
        DeliveryCondition: deliverytype,
        SpecialityCondition: speciality
      },
      headers: { Authorization: X_API_KEY },
      success: (res) => {
        if (res.length == undefined) {
          res = Object.keys(res).map((key) => {
            return res[key];
          });
        }

        console.log(res);
        console.log(res.length);

        $(".total").html(res.length);

        $(".top-specialities").hide();
        $(".near-you").hide();
        $(".away-from-you").hide();
        $(".allResults").hide();

        $(".main-filter-display-items").empty();

        res.forEach((element) => {
          var sponsoredContent = "";
          var favItem = this.checkFavourites("check", element.Vendor_ID);
          if (element.Business_Name.length > 28) {
            spacing = "mb-0";
          } else {
            spacing = "mb-3";
          }
          //adding rating stars
          if (element.Vendor_Rating > 0) {
            var color = "background: rgba(244, 148, 49);";
          }

          var vendRatingScore = "";
          if (element.Vendor_Rating == null) {
            vendRatingScore = 0;
          } else {
            vendRatingScore = element.Vendor_Rating;
          }

          //New change from homepage
          if (deviceTypeHomePage == "desktop") {
            if (element.isSponsored > 0) {
              sponsoredContent = `<div class="rounded" style="position: absolute; top: 3px;left:-15px; z-index: 3;background: rgba(244, 148, 49);">
          <h5 class="text-white pt-1 pl-3 pr-2">Sponsored</h5>
      </div>`;
            }
            //Adding near you items
            //vendorsDesktop

            var item = `<div class="item col-3 zoom d-flex justify-content-around h-100">
        <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
        <div class=" wow fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;">
          <div class="w-100 rounded text-center favourites-container">
          <img src="" class="${element.Vendor_ID}-vendImg rounded shadow-lg" alt="${element.Business_Name}-Image">
          </a>
          ${sponsoredContent}
          ${favItem}
          </div>
          <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
          <div class="stats mt-2">

            <div class="row d-flex justify-content-between ${spacing}">
              <div class="col-sm-12 truncate">
              <h6><strong>${element.Business_Name}</strong></h6>
              <h6>${element.City}</h6>
              </div>
            </div>
            <div class="row d-flex justify-content-between">
              <div class="col-sm-12 truncate-one">
              ${element.Specialities}
              </div>
            </div>
            <div class="row ">
            <div class="col pr-0 d-flex justify-content-start">
                    <p class="mb-0 pt-2">Min Order:₹${element.Minimum_Order}</p>
                    </div>
                    <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                      <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${vendRatingScore} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                    </div>
            </div>
          </div>
        </div>
        </a>
      </div>`;

            // $(`.owl-carousel.main-filter-display-items`)
            //   .owlCarousel("add", item)
            //   .owlCarousel("update");
            $(".main-filter-display-items").append(item);
          } else {
            //mobile view vendorsMobile
            if (element.isSponsored > 0) {
              sponsoredContent = `<p class="carousel-caption text-white shadow rounded p-0 m-0" style="background: rgba(244, 148, 49);">Sponsored</p>`;
            }

            var item = `<div class="card my-2">
            <div class="row">
                <div class="col-4 py-2 ml-2 pr-0 favourites-container" style="width:30%;">
                    ${favItem}
                    <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
                        <img src="" class="${element.Vendor_ID}-vendImg rounded-3 card-img-top"></img>
                        ${sponsoredContent}
                    </a>
                </div>
                <div class="col pl-0">
                    <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
                        <div class="card-body h-100">
                            <div class="row h-100 d-flex justify-content-between">
                                <div class="col-12">
                                    <h5 class="card-title truncate-newHomePage">${element.Business_Name}</h5>
                                </div>
                                <div class="col-12">
                                    <p class="truncate-newHomePage">${element.City}</p>
                                </div>
                                <div class="col-12">
                                    <p class="truncate-newHomePage">${element.Specialities}</p>
                                </div>
                                <div class="col-6 align-items-end mb-0">Min Order:₹${element.Minimum_Order}</div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col d-flex justify-content-end">
                                            <div class="ratingBorder align-items-center d-inline-flex text-white mb-0 pl-2" style="${color}">
                                                <p class="mb-0">${vendRatingScore}</p> 
                                                <i class="fa fa-star ml-1" aria-hidden="true" style="color: white;"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>`;

            $(`.main-filter-display-items`).append(item);
          }
        });
        //unbinds any previous event handlers
        $(".likeBtnHomepage").off("click");
        $(".likeBtnHomepage").on("click", this.setFavourites);

        //for images
        res.forEach(async (element) => {
          var vendorImg = await s3Object.getObjectURL(
            "Vendor/" +
              element.Image_URL +
              "/Document Images/Business Image/1.png"
          );

          $(`.${element.Vendor_ID}-vendImg`).attr("src", vendorImg);
        });
        $(".vendorListingPage").removeClass("d-none");
      },
      error: (res) => {
        console.log(res);
        $(".noVendors").removeClass("d-none");
      }
    });
  };

  //Get Vendors Near you by speciality
  getSpeciality = (event) => {
    console.log(event.currentTarget);
    var specialityID = event.currentTarget.getAttribute("value");

    //toggle round circle around clicked speciality
    $(".selected-ring").toggleClass("selected-ring");
    $(`.${specialityID}-specialityImage`).toggleClass("selected-ring");

    $(".top-specialities").show();

    var Picklist_ID = $(event.target)
      .parents(".item")
      .attr("class")
      .split(" ")[1];

    $.ajax({
      url: baseURL + "api/customer/VendorsList/getVendorBySpecialities",
      type: "POST",
      async: false,
      data: {
        buffer: BUFFER,
        lat: LAT,
        lon: LNG,
        Picklist_ID: Picklist_ID
      },
      headers: { Authorization: X_API_KEY },
      success: (res) => {
        console.log(res);
        if (res.NearMeCount + res.AwayfromMeCount === 0) {
          $(".noVendors").removeClass("d-none");
        } else {
          $(".vendorListingPage").removeClass("d-none");

          $(".total").html(res.NearMeCount + res.AwayfromMeCount);

          if (res.NearMeCount == 0) {
            $(".near-you").hide();
          } else {
            $(".near-you").show();
            //Near You

            //removing old sellers for desktop
            var len = $(`.owl-carousel.near-you-items`).find(
              ".owl-item"
            ).length;
            for (var i = 0; i < len; i++) {
              $(`.owl-carousel.near-you-items`).owlCarousel("remove", 0);
            }

            //removing old sellers for mobile
            if (deviceTypeHomePage == "mobile") {
              $(".near-you-items").empty();
            }

            res.NearMe.forEach((element) => {
              var sponsoredContent = "";
              var favItem = this.checkFavourites("check", element.Vendor_ID);
              if (element.Business_Name.length > 28) {
                spacing = "mb-0";
              } else {
                spacing = "mb-3";
              }
              //for vendor review stars
              //adding rating stars
              if (element.Vendor_Rating > 0) {
                var color = "background: rgba(244, 148, 49);";
              }

              //New change from homepage
              if (deviceTypeHomePage == "desktop") {
                //Adding near you items vendorsDesktop
                if (element.isSponsored > 0) {
                  sponsoredContent = `<div class="mt-4 pt-1 fixed-top">
              <h5 class="text-white ml-3 w-50 shadow rounded" style="background: rgba(244, 148, 49);">Sponsored</h5>
          </div>`;
                }
                var item = `<div class="item zoom d-flex justify-content-around h-100">
        <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
        <div class=" wow fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;">
          <div class="w-100 rounded text-center favourites-container">
          <img src="" class="${element.Vendor_ID}-vendImg rounded shadow-lg" alt="${element.Business_Name}-Image">
          </a>
          ${sponsoredContent}
          ${favItem}
          </div>
          <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
          <div class="stats mt-2">

            <div class="row d-flex justify-content-between ${spacing}">
              <div class="col-sm-12 truncate">
              <h6><strong>${element.Business_Name}</strong></h6>
              <h6>${element.City}</h6>
              </div>
            </div>
            <div class="row d-flex justify-content-between">
              <div class="col-sm-12 truncate-one">
              ${element.Specialities}
              </div>
            </div>
            <div class="row ">
            <div class="col pr-0 d-flex justify-content-start">
                    <p class="mb-0 pt-2">Min Order:₹${element.Minimum_Order}</p>
                    </div>
                    <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                      <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${element.Vendor_Rating} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                    </div>
            </div>
          </div>
        </div>
        </a>
      </div>`;

                $(`.owl-carousel.near-you-items`)
                  .owlCarousel("add", item)
                  .owlCarousel("update");
              } else {
                //mobile view vendorsMobile
                if (element.isSponsored > 0) {
                  sponsoredContent = `<p class="carousel-caption text-white shadow rounded p-0 m-0" style="background: rgba(244, 148, 49);">Sponsored</p>`;
                }
                var item = `<div class="card my-2">
                <div class="row">
                    <div class="col-4 py-2 ml-2 pr-0 favourites-container" style="width:30%;">
                        ${favItem}
                        <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
                            <img src="" class="${element.Vendor_ID}-vendImg rounded-3 card-img-top"></img>
                            ${sponsoredContent}
                        </a>
                    </div>
                    <div class="col pl-0">
                        <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
                            <div class="card-body h-100">
                                <div class="row h-100 d-flex justify-content-between">
                                    <div class="col-12">
                                        <h5 class="card-title truncate-newHomePage">${element.Business_Name}</h5>
                                    </div>
                                    <div class="col-12">
                                        <p class="truncate-newHomePage">${element.City}</p>
                                    </div>
                                    <div class="col-12">
                                        <p class="truncate-newHomePage">${element.Specialities}</p>
                                    </div>
                                    <div class="col-6 align-items-end mb-0">Min Order:₹${element.Minimum_Order}</div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col d-flex justify-content-end">
                                                <div class="ratingBorder align-items-center d-inline-flex text-white mb-0 pl-2" style="${color}">
                                                    <p class="mb-0">${vendRatingScore}</p> 
                                                    <i class="fa fa-star ml-1" aria-hidden="true" style="color: white;"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>`;
                $(`.near-you-items`).append(item);
              }
            });
            //unbinds any previous event handlers
            $(".likeBtnHomepage").off("click");
            $(".likeBtnHomepage").on("click", this.setFavourites);
            //for images
            res.NearMe.forEach(async (element) => {
              var vendorImg = await s3Object.getObjectURL(
                "Vendor/" +
                  element.Image_URL +
                  "/Document Images/Business Image/1.png"
              );

              $(`.${element.Vendor_ID}-vendImg`).attr("src", vendorImg);
            });
          }
          if (res.AwayfromMe == 0) {
            $(".away-from-you").hide();
          } else {
            $(".away-from-you").show();
            //Away From You
            //removing old sellers, away from you desktop
            var len = $(`.owl-carousel.away-from-you-items`).find(
              ".owl-item"
            ).length;
            for (var i = 0; i < len; i++) {
              $(`.owl-carousel.away-from-you-items`).owlCarousel("remove", 0);
            }

            //removing old sellers for mobile
            if (deviceTypeHomePage == "mobile") {
              $(".away-from-you-items").empty();
            }
            res.AwayfromMe.forEach(async (element) => {
              var sponsoredContent = "";
              var favItem = this.checkFavourites("check", element.Vendor_ID);
              if (element.Business_Name.length > 28) {
                spacing = "mb-0";
              } else {
                spacing = "mb-3";
              }

              //for vendor review stars
              if (element.Vendor_Rating > 0) {
                var color = "background: rgba(244, 148, 49);";
              }

              var vendRatingScore = "";
              if (element.Vendor_Rating == null) {
                vendRatingScore = 0;
              } else {
                vendRatingScore = element.Vendor_Rating;
              }

              //New change from homepage
              if (deviceTypeHomePage == "desktop") {
                //Adding near you items vendorsDesktop
                if (element.isSponsored > 0) {
                  sponsoredContent = `<div class="mt-4 pt-1 fixed-top">
              <h5 class="text-white ml-3 w-50 shadow rounded" style="background: rgba(244, 148, 49);">Sponsored</h5>
          </div>`;
                }
                var item = `<div class="item zoom d-flex justify-content-around h-100">
        <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
        <div class=" wow fadeInUp p-3 mb-5 bg-white border-0 shadow rounded-3" style=" width: 95%;">
          <div class="w-100 rounded text-center favourites-container">
          <img src="" class="${element.Vendor_ID}-vendImg rounded shadow-lg" alt="${element.Business_Name}-Image">
          </a>
          ${sponsoredContent}
          ${favItem}
          </div>
          <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener " style="margin-top:0.5rem;">
          <div class="stats mt-2">

            <div class="row d-flex justify-content-between ${spacing}">
              <div class="col-sm-12 truncate">
              <h6><strong>${element.Business_Name}</strong></h6>
              <h6>${element.City}</h6>
              </div>
            </div>
            <div class="row d-flex justify-content-between">
              <div class="col-sm-12 truncate-one">
              ${element.Specialities}
              </div>
            </div>
            <div class="row ">
            <div class="col pr-0 d-flex justify-content-start">
                    <p class="mb-0 pt-2">Min Order:₹${element.Minimum_Order}</p>
                    </div>
                    <div class="col-4 pl-0 pt-1 d-flex justify-content-end" >
                      <p class="ratingBorder text-white mb-0 pl-1" style="${color}">${vendRatingScore} <i class="fa fa-star mt-1" aria-hidden="true" style="color: white;"></i></p>
                    </div>
            </div>
          </div>
        </div>
        </a>
      </div>`;

                $(".owl-carousel.away-from-you-items")
                  .owlCarousel("add", item)
                  .owlCarousel("update");
              } else {
                //mobile view vendorsMobile
                if (element.isSponsored > 0) {
                  sponsoredContent = `<p class="carousel-caption text-white shadow rounded p-0 m-0" style="background: rgba(244, 148, 49);">Sponsored</p>`;
                }

                var item = `<div class="card my-2">
                <div class="row">
                    <div class="col-4 py-2 ml-2 pr-0 favourites-container" style="width:30%;">
                        ${favItem}
                        <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
                            <img src="" class="${element.Vendor_ID}-vendImg rounded-3 card-img-top"></img>
                            ${sponsoredContent}
                        </a>
                    </div>
                    <div class="col pl-0">
                        <a href="${baseURL}customer/productListing/${element.Vendor_URL}" rel="noopener">
                            <div class="card-body h-100">
                                <div class="row h-100 d-flex justify-content-between">
                                    <div class="col-12">
                                        <h5 class="card-title truncate-newHomePage">${element.Business_Name}</h5>
                                    </div>
                                    <div class="col-12">
                                        <p class="truncate-newHomePage">${element.City}</p>
                                    </div>
                                    <div class="col-12">
                                        <p class="truncate-newHomePage">${element.Specialities}</p>
                                    </div>
                                    <div class="col-6 align-items-end mb-0">Min Order:₹${element.Minimum_Order}</div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col d-flex justify-content-end">
                                                <div class="ratingBorder align-items-center d-inline-flex text-white mb-0 pl-2" style="${color}">
                                                    <p class="mb-0">${vendRatingScore}</p> 
                                                    <i class="fa fa-star ml-1" aria-hidden="true" style="color: white;"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>`;

                $(`.away-from-you-items`).append(item);
              }
            });
            //unbinds any previous event handlers
            $(".likeBtnHomepage").off("click");
            $(".likeBtnHomepage").on("click", this.setFavourites);

            //for images
            res.AwayfromMe.forEach(async (element) => {
              var vendorImg = await s3Object.getObjectURL(
                "Vendor/" +
                  element.Image_URL +
                  "/Document Images/Business Image/1.png"
              );

              $(`.${element.Vendor_ID}-vendImg`).attr("src", vendorImg);
            });
          }
        }
      },
      error: function (res) {
        console.log(res);
      }
    });
  };

  setFavourites = (event) => {
    console.log(event);
    var vendorID = event.currentTarget.value;
    var result, type;
    if (event.currentTarget.classList.contains("liked")) {
      console.log("liked and changing to not liked");
      result = favObject.favouritesToggle(vendorID, "false");
      type = 0;
    } else {
      console.log("not liked and changing to like");
      result = favObject.favouritesToggle(vendorID, "true");
      type = 1;
    }

    if (result == "YOU NEED TO LOG IN") {
      console.log("You need to log in");
      window.$("#favouritesError").modal("show");
    } else if (result == "ERROR OCCURED") {
      console.log("Error Occured");
    } else {
      console.log("Everything was good");
      if (type == 0) {
        $(`button.like-button[value='${vendorID}']`).toggleClass("liked");
        $(`button.like-button[value='${vendorID}']`).html(
          `<img src="https://img.icons8.com/material-outlined/48/null/filled-like.png"/>`
        );
      } else if (type == 1) {
        $(`button.like-button[value='${vendorID}']`).toggleClass("liked");
        $(`button.like-button[value='${vendorID}']`).html(
          `<img src="https://img.icons8.com/emoji/48/null/heart-suit.png"/>`
        );
      }
      this.checkFavourites();
    }
  };

  checkFavourites = (type = "get", id = "") => {
    if (type == "get") {
      // customerFavList = favObject.getCustomerFavouriteList();
      // console.log(customerFavList);
      var cookies = UtilObject.getAllCookies();
      if (cookies.hasOwnProperty("CUSTOMER_ID")) {
        $.ajax({
          url: baseURL + "api/customer/Account/getFavorites",
          type: "GET",
          async: false,
          headers: { Authorization: X_API_KEY },
          success: (result) => {
            console.log(result);
            customerFavList = result;
          },
          error: (result) => {
            console.log(result);
            customerFavList = 0;
          }
        });
      } else {
        customerFavList = 0;
      }
      // if (favList == "YOU NEED TO LOG IN") {
      // }
      // else if (favList == "ERROR OCCURED") {

      // }
      // else {

      // }
    } else {
      if (customerFavList != 0) {
        var ret = 0;
        customerFavList.forEach((favitem) => {
          if (favitem.Vendor_ID == id) {
            ret = 1;
          }
        });
        if (ret == 1) {
          ret = `<button class="like-button likeBtnHomepage liked zindex-popover" aria-label="Favourite" value="${id}">
          <img src="https://img.icons8.com/emoji/48/null/heart-suit.png"/>
</button>`;
          return ret;
        } else {
          ret = `<button class="like-button likeBtnHomepage zindex-popover" aria-label="Favourite" value="${id}">
          <img src="https://img.icons8.com/material-outlined/48/null/filled-like.png"/>
</button>`;
          return ret;
        }
      } else {
        ret = `<button class="like-button likeBtnHomepage zindex-popover" aria-label="Favourite" value="${id}">
          <img src="https://img.icons8.com/material-outlined/48/null/filled-like.png"/>
</button>`;
        return ret;
      }
    }
  };
}
