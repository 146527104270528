var selected_value = 0;
var orderId, orderNo;
let vendorId;
export default class customerConfirmationPage {
  constructor() {
    this.ratingbtn = $(".ratingBtnConfirmPage");
    this.inputReviewStarRatingError = $(".vendorRatingErrorMsg");
    this.submitVendorRating = $(".submitVendorRatingBtn");
    this.reloadOrderFlowBtn = $(".reloadOrderFlowBtn");

    $(".confirmationPageOrderRatingSection").hide();

    orderNo = $(".Order_ID").val();
    console.log(orderNo);
    //this.getOrderId()
    //add " <?php echo ($data) ?> " to php file

    //Registering Events
    this.bindEvents();
  }

  bindEvents = () => {
    this.ratingbtn.on("click", this.btnrating);
    this.submitVendorRating.on("click", this.submitreviewCheck);

    //Calling order details on load
    $(window).on("load", $.ready.then(this.getOrderDetails));
    //Automatic refresh orderFlow
    //setInterval(this.showOrderFlow, 120000);

    // setInterval(this.showOrderFlow, 20000);
    this.reloadOrderFlowBtn.on("click", this.showOrderFlow);

    //close buttons
    $(".reviewConfirmationpageClose").on("click", () => {
      $("#reviewConfirmationpage").hide();
    });
  };

  //getting order details
  getOrderDetails = () => {
    $.ajax({
      url: baseURL + "api/customer/Cart/OrderDetailsWithOrderNo",
      type: "POST",
      async: false,
      data: {
        Order_No: orderNo,
      },
      headers: { Authorization: X_API_KEY },
      success: (res) => {
        console.log(res);

        //setting orderID
        orderId = res.Order.Order_ID;
        console.log(orderId);
        this.showOrderFlow();
        //setting vendorID
        vendorId = res.Vendor_details.Vendor_ID;
        var additionalVendorDetails = "";

        if (res.Vendor_details.Business_Name.length > 30) {
          var vname = res.Vendor_details.Business_Name.substring(0, 28) + "...";
        } else {
          var vname = res.Vendor_details.Business_Name;
        }

        var bookedtime = res.Order.Order_booked_date_time.split(" ")[1];
        bookedtime = bookedtime.substring(0, 5);

        var currentOrderStatus = res.Order.Order_Status;
        currentOrderStatus = currentOrderStatus.toLowerCase();
        console.log(currentOrderStatus);
        if (
          currentOrderStatus == "enquiry cancelled" ||
          currentOrderStatus == "enquiry received"
        ) {
          console.log("cannot show vendor details");
        } else {
          additionalVendorDetails = `
          <p class="text-muted ml-1 mb-1"><strong>Phone</strong> : ${res.Vendor_details.Phone_Number}</p>
          <p class="text-muted ml-1 line-clamp-4 mb-0 readmoreVendorAddress"><strong>Seller Address</strong> : ${res.Order.Vendor_Address_Details}</p>
          <p class="text-muted ml-1 mb-1 readmoreVendorAddressButton"><u>Read more...</u></p>
          `;
        }

        //self pickup redirection button
        //res.Delivery.Delivery_Name.toLowerCase() == "self pickup" &&
        if (
          res.Order.Order_Status.toLowerCase() != "enquiry received" &&
          res.Order.Order_Status.toLowerCase() != "enquiry cancelled"
        ) {
          $(".getDirections").removeClass("d-none");
          $(".getDirections").on("click", () => {
            window.location = `https://www.google.com/maps/dir/?api=1&destination=${parseFloat(
              res.Order.Vendor_Address_Lat
            )},${parseFloat(res.Order.Vendor_Address_Lon)}`;
          });
        }

        //vendor details
        $(".vendorDetailsConfirmationPage").empty();
        $(".vendorDetailsConfirmationPage").append(
          `<div class="row">
          <div class="card-body pb-0">
              <div class="row">
                  <div class="col-8 ml-2 d-flex justify-content-start">
                      <p class="text-muted">#ENQUIRY ${res.Order.Order_No}</p>
                  </div>
                  <div class="col d-flex justify-content-end">
                      <h6 class="btn card-title orderHelp">HELP</h6>
                  </div>
              </div>
              <h5 class=" ml-1 card-text truncate-cart pl-1">${vname}</h5>
              ${additionalVendorDetails}
              <hr>
              <p class="text-muted ml-1 line-clamp-4 mb-0 readmoreCustomerAddress"><strong>Customer Address</strong> : ${res.Order.Customer_Address_Details}</p>
          <p class="text-muted ml-1 mb-1 readmoreCustomerAddressButton"><u>Read more...</u></p>
              <div class="row">
                  <div class="col ml-2 pr-0 d-flex justify-content-start pt-1">
                      <p class="text-muted">${bookedtime} | ${res.Order_products.length}item | ₹${res.Order.Final_Bill_Amount}</p>
                  </div>
                  <div class="col pl-0 d-flex justify-content-end">

                      <span class="cancelOrderBtnWrapper">
                        <h6 class="btn cancelOrderBtn">CANCEL ENQUIRY</h6>
                      </span>

                  </div>
              </div>
          </div>
      </div>`
        );
        $(".cancelOrderBtn").on("click", this.cancelOrder);
        $(".orderHelp").on("click", this.orderHelp);

        $(".readmoreCustomerAddressButton").on("click", () => {
          $(".readmoreCustomerAddress").removeClass("line-clamp-4");
          $(".readmoreCustomerAddressButton").addClass("d-none");
        });

        if (additionalVendorDetails != "") {
          $(".readmoreVendorAddressButton").on("click", () => {
            $(".readmoreVendorAddress").removeClass("line-clamp-4");
            $(".readmoreVendorAddressButton").addClass("d-none");
          });
        }

        this.showMap(res);
      },
      error: (err) => {
        console.log(err);
      },
    });
  };

  //cancel order
  cancelOrder = () => {
    console.log("cancel order");
    $.ajax({
      url: baseURL + "api/customer/Cart/OrderCancel",
      type: "POST",
      async: false,
      data: {
        Order_ID: orderId,
      },
      headers: { Authorization: X_API_KEY },
      success: (res) => {
        console.log(res);
        this.getOrderDetails();
        this.showOrderFlow();
      },
      error: (err) => {
        console.log(err);
      },
    });
  };

  //help with order
  orderHelp = () => {
    console.log(orderId);
    //setting session cookies
    $.ajax({
      url: baseURL + "api/customer/CustomerCookies/setSessionId",
      type: "POST",
      async: false,
      data: {
        data: { HELP_REQUESTED: orderId },
      },
      success: (res) => {
        console.log(res);
        window.location = baseURL + `customer/account`;
      },
      error: (err) => {
        console.log(err);
      },
    });
  };

  //submit button for review
  vendorRatingSubmit = () => {
    console.log(vendorId);
    $.ajax({
      url: baseURL + "api/customer/Cart/VendorRating",
      type: "POST",
      async: false,
      data: {
        Order_ID: orderId,
        Vendor_ID: vendorId,
        ReviewScore: selected_value,
      },
      headers: { Authorization: X_API_KEY },
      success: (res) => {
        console.log(res);
        $("#reviewConfirmationpage").show();
      },
      error: (err) => {
        console.log(err);
      },
    });
  };

  submitreviewCheck = (e) => {
    e.preventDefault();
    let isReviewStarRatingValidated = this.ReviewRating_validate();
    if (isReviewStarRatingValidated) {
      this.inputReviewStarRatingError.html("");
      this.vendorRatingSubmit();
    }
  };

  btnrating = (event) => {
    var previous_value = $("#vRatingConfirmPage").val();

    // console.log(previous_value);

    selected_value = $(event.currentTarget).attr("data-attrr");

    console.log(selected_value);

    $("#vRatingConfirmPage").val(selected_value);

    $(".vRatingConfirmPage").empty();
    $(".vRatingConfirmPage").html(selected_value);

    for (var i = 1; i <= selected_value; ++i) {
      $("#rating-" + i).toggleClass("text-default");
      $("#rating-" + i).toggleClass("text-warning");
    }

    for (var ix = 1; ix <= previous_value; ++ix) {
      $("#rating-" + ix).toggleClass("text-default");
      $("#rating-" + ix).toggleClass("text-warning");
    }
  };

  ReviewRating_validate = () => {
    let flag = true;
    if (selected_value == 0) {
      this.inputReviewStarRatingError.html("Select rating");
      flag = false;
    }
    return flag;
  };

  showMap = (order) => {
    var map;
    console.log(order);
    var vendorAddress = order.Vendor_address_details;
    var deliveryDetails = order.Delivery;

    var pos1;
    var LatLngBounds = google.maps.LatLngBounds;
    var LatLng = google.maps.LatLng;

    //customer marker
    var iconForCustomer = {
      url: "https://img.icons8.com/color/48/null/order-delivered.png",
    };
    //vendor marker
    var iconForVendor = {
      url: "https://img.icons8.com/color/48/null/small-business.png",
    };

    // Current Location Marker for self pickup - TODO LATER
    if (deliveryDetails.Delivery_Type == "None") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          console.log(parseFloat(position.coords.latitude));
          console.log(typeof position.coords.latitude);

          pos1 = new google.maps.LatLng(
            parseFloat(position.coords.latitude),
            parseFloat(position.coords.longitude)
          );

          //Between Two Vendors where lat and long is known

          var pos2 = new LatLng(
            parseFloat(vendorAddress.lat),
            parseFloat(vendorAddress.lon)
          );

          var bounds = new LatLngBounds();
          bounds.extend(pos1);
          bounds.extend(pos2);

          const options = {
            zoom: 12,
            scaleControl: true,
            center: bounds.getCenter(),
          };

          console.log("Loading map");

          map = new google.maps.Map(
            document.getElementById("mapAddressForOrderConfirmationPage"),
            options
          );

          // Locations of landmarks
          const customer = {
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude),
          };

          const vendor = {
            lat: parseFloat(vendorAddress.lat),
            lng: parseFloat(vendorAddress.lon),
          };

          // The markers
          //customer marker
          var mk1 = new google.maps.Marker({
            position: customer,
            map: map,
            icon: iconForCustomer,
          });
          //vendor marker
          var mk2 = new google.maps.Marker({
            position: vendor,
            map: map,
            icon: iconForVendor,
          });

          // Define a symbol using SVG path notation, with an opacity of 1.
          var lineSymbol = {
            path: "M 0,-1 0,1",
            strokeOpacity: 1,
            scale: 4,
          };

          // Draw a line showing the straight distance between the markers
          //var line = new google.maps.Polyline({ path: [customer, vendor], map: map });
          var line = new google.maps.Polyline({
            path: [customer, vendor],
            strokeOpacity: 0,
            icons: [
              {
                icon: lineSymbol,
                offset: "0",
                repeat: "20px",
              },
            ],
            map: map,
          });
          map.fitBounds(bounds);
        });
      }
    } else {
      var customerAddress = order.Order;
      pos1 = new LatLng(
        parseFloat(customerAddress.Customer_Address_Lat),
        parseFloat(customerAddress.Customer_Address_Lon)
      );

      //Between Two Vendors where lat and long is known

      var pos2 = new LatLng(
        parseFloat(vendorAddress.lat),
        parseFloat(vendorAddress.lon)
      );

      var bounds = new LatLngBounds();
      bounds.extend(pos1);
      bounds.extend(pos2);

      const options = {
        zoom: 12,
        scaleControl: true,
        center: bounds.getCenter(),
      };

      console.log("Loading map");

      map = new google.maps.Map(
        document.getElementById("mapAddressForOrderConfirmationPage"),
        options
      );

      // Locations of landmarks
      const customer = {
        lat: parseFloat(customerAddress.Customer_Address_Lat),
        lng: parseFloat(customerAddress.Customer_Address_Lon),
      };
      const vendor = {
        lat: parseFloat(vendorAddress.lat),
        lng: parseFloat(vendorAddress.lon),
      };
      // The markers for The Dakota and The Frick Collection
      var mk1 = new google.maps.Marker({
        position: customer,
        map: map,
        icon: iconForCustomer,
      });
      var mk2 = new google.maps.Marker({
        position: vendor,
        map: map,
        icon: iconForVendor,
      });

      // Define a symbol using SVG path notation, with an opacity of 1.
      var lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 4,
      };

      // Draw a line showing the straight distance between the markers
      //var line = new google.maps.Polyline({ path: [customer, vendor], map: map });
      var line = new google.maps.Polyline({
        path: [customer, vendor],
        strokeOpacity: 0,
        icons: [
          {
            icon: lineSymbol,
            offset: "0",
            repeat: "20px",
          },
        ],
        map: map,
      });

      map.fitBounds(bounds);
    }
  };

  showOrderFlow = () => {
    $(".orderFlowScrollSection").empty();
    $.ajax({
      url: baseURL + "api/customer/Cart/OrderDetails",
      type: "POST",
      async: false,
      data: {
        Order_ID: orderId,
      },
      headers: { Authorization: X_API_KEY },
      success: (res) => {
        var order = res;
        console.log("inside showOrderFlow");
        console.log(order);

        var orderStatusDetails = order.Order_Status_Details;
        console.log(orderStatusDetails);

        var orderDeliveryDetails = order.Delivery;
        var styleHeight = 31;

        orderStatusDetails.forEach((status) => {
          if (status.Order_Status == "ORDER DELIVERED") {
            styleHeight = 23;
          } else if (status.Order_Status == "ORDER PICKED UP") {
            styleHeight = 23;
          }
        });

        var orderFlowContent = `<div id="here" class="overflow-auto cpScroll orderStatusFlowScrollSection" style="height: ${styleHeight}rem;">
    <div class="card shadow-sm mb-5 orderStatusFlowItems">
        <div class="row ">
            <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center">
                <i class="fa-solid fa-circle-check fa-3x pl-4"></i>
            </div>
            <div class="col-8 col-lg-9">
                <div class="card-body pl-0">
                    <div class="row">
                        <div class="d-flex justify-content-start">
                            <h5 class="card-title">ENQUIRY RECEIVED :</h5>
                        </div>
                    </div>
                    <p class="card-text truncate-cart">AWAITING THE SELLER TO ACCEPT ENQUIRY.</p>
                </div>
            </div>
        </div>
    </div>
</div>`;

        $(".orderFlowScrollSection").append(orderFlowContent);

        var orderFlowRSKM = [
          "ENQUIRY RECEIVED",
          "ENQUIRY ACCEPTED",
          "ORDER READY",
          "ORDER OUT FOR DELIVERY",
          "ORDER DELIVERED",
        ];
        var orderFlowSPU = [
          "ENQUIRY RECEIVED",
          "ENQUIRY ACCEPTED",
          "ORDER READY",
          "ORDER PICKED UP",
        ];

        var lastStatus = "";
        var cancelled = 0;
        orderStatusDetails.forEach((status) => {
          var description = "";
          var orderStatusItem = "";
          if (status.Order_Status == "ENQUIRY ACCEPTED") {
            description = "THE SELLER HAS ACCEPTED THE ENQUIRY.";
            orderStatusItem = `<div class="hr-sect m-0">GC</div>
        <div class="row">
        <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center">
            <i class="fa-solid fa-circle-check fa-3x pl-4"></i>
        </div>
        <div class="col-8 col-lg-9">
            <div class="card-body pl-0">
                <div class="row">
                    <div class="d-flex justify-content-start">
                        <h5 class="card-title">${status.Order_Status} : </h5>
                    </div>
                </div>
                <p class="card-text truncate-cart">${description}</p>
            </div>
        </div>
    </div>`;
            $(".confirmationPageOrderRatingSection").hide();
            $(".cancelOrderBtnWrapper").html(
              `<h6 class="btn text-secondary">CANCEL ENQUIRY</h6>`
            );
          } else if (status.Order_Status == "ORDER READY") {
            description = "YOUR ORDER IS READY.";
            orderStatusItem = `<div class="hr-sect m-0">GC</div>
        <div class="row">
        <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center">
            <i class="fa-solid fa-circle-check fa-3x pl-4"></i>
        </div>
        <div class="col-8 col-lg-9">
            <div class="card-body pl-0">
                <div class="row">
                    <div class="d-flex justify-content-start">
                        <h5 class="card-title">${status.Order_Status} : </h5>
                    </div>
                </div>
                <p class="card-text truncate-cart">${description}</p>
            </div>
        </div>
    </div>`;
            $(".confirmationPageOrderRatingSection").hide();
          } else if (status.Order_Status == "ORDER OUT FOR DELIVERY") {
            description = "YOUR ORDER IS OUT FOR DELIVERY.";
            orderStatusItem = `<div class="hr-sect m-0">GC</div>
        <div class="row">
        <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center">
            <i class="fa-solid fa-circle-check fa-3x pl-4"></i>
        </div>
        <div class="col-8 col-lg-9">
            <div class="card-body pl-0">
                <div class="row">
                    <div class="d-flex justify-content-start">
                        <h5 class="card-title">${status.Order_Status} : </h5>
                    </div>
                </div>
                <p class="card-text truncate-cart">${description}</p>
            </div>
        </div>
    </div>`;
            $(".confirmationPageOrderRatingSection").hide();
          } else if (status.Order_Status == "ORDER DELIVERED") {
            description = "YOUR ORDER HAS BEEN DELIVERED.";
            orderStatusItem = `<div class="hr-sect m-0">GC</div>
        <div class="row">
        <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center">
            <i class="fa-solid fa-circle-check fa-3x pl-4"></i>
        </div>
        <div class="col-8 col-lg-9">
            <div class="card-body pl-0">
                <div class="row">
                    <div class="d-flex justify-content-start">
                        <h5 class="card-title">${status.Order_Status} : </h5>
                    </div>
                </div>
                <p class="card-text truncate-cart">${description}</p>
            </div>
        </div>
    </div>`;
            $(".confirmationPageOrderRatingSection").show();
          } else if (status.Order_Status == "ORDER PICKED UP") {
            description = "YOUR ORDER WAS PICKED UP.";
            orderStatusItem = `<div class="hr-sect m-0">GC</div>
        <div class="row">
        <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center">
            <i class="fa-solid fa-circle-check fa-3x pl-4"></i>
        </div>
        <div class="col-8 col-lg-9">
            <div class="card-body pl-0">
                <div class="row">
                    <div class="d-flex justify-content-start">
                        <h5 class="card-title">${status.Order_Status} : </h5>
                    </div>
                </div>
                <p class="card-text truncate-cart">${description}</p>
            </div>
        </div>
    </div>`;

            $(".confirmationPageOrderRatingSection").show();
          } else if (status.Order_Status == "ENQUIRY RECEIVED") {
            description = "AWAITING THE SELLER TO ACCEPT ENQUIRY.";
          }

          $(".confirmationPageOrderConfirmationMsg").html(
            `${status.Order_Status} : ${description}`
          );
          $(".orderStatusFlowItems").append(orderStatusItem);

          lastStatus = status.Order_Status;
        });

        console.log(lastStatus);

        if (lastStatus == "ENQUIRY CANCELLED BY CUSTOMER") {
          $(".cancelOrderBtnWrapper").html(
            `<h6 class="btn text-secondary">CANCEL ENQUIRY</h6>`
          );
          var description = "YOU HAVE CANCELLED THIS ENQUIRY.";
          var orderStatusItem = `<div class="hr-sect m-0">GC</div>
        <div class="row">
        <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center">
            <i class="fa-solid fa-circle-check fa-3x pl-4"></i>
        </div>
        <div class="col-8 col-lg-9">
            <div class="card-body pl-0">
                <div class="row">
                    <div class="d-flex justify-content-start">
                        <h5 class="card-title">ENQUIRY CANCELLED</h5>
                    </div>
                </div>
                <p class="card-text truncate-cart">${description}</p>
            </div>
        </div>
    </div>`;

          $(".confirmationPageOrderConfirmationMsg").html(
            `ENQUIRY CANCELLED : ${description}`
          );
          $(".orderStatusFlowItems").append(orderStatusItem);
          $(".confirmationPageOrderRatingSection").hide();
        } else if (lastStatus == "ENQUIRY CANCELLED BY SELLER") {
          $(".cancelOrderBtnWrapper").html(
            `<h6 class="btn text-secondary">CANCEL ENQUIRY</h6>`
          );
          description = "THE SELLER HAS CANCELLED THE ENQUIRY.";
          orderStatusItem = `<div class="hr-sect m-0">GC</div>
        <div class="row">
        <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center">
            <i class="fa-solid fa-circle-check fa-3x pl-4"></i>
        </div>
        <div class="col-8 col-lg-9">
            <div class="card-body pl-0">
                <div class="row">
                    <div class="d-flex justify-content-start">
                        <h5 class="card-title">ENQUIRY CANCELLED</h5>
                    </div>
                </div>
                <p class="card-text truncate-cart">${description}</p>
            </div>
        </div>
    </div>`;

          $(".confirmationPageOrderConfirmationMsg").html(
            `ENQUIRY CANCELLED : ${description}`
          );
          $(".orderStatusFlowItems").append(orderStatusItem);
          $(".confirmationPageOrderRatingSection").hide();
        } else {
          if (
            orderDeliveryDetails.Delivery_Type == "RSKM" ||
            orderDeliveryDetails.Delivery_Type == "KM"
          ) {
            var index = orderFlowRSKM.indexOf(lastStatus);
            console.log(index);
            for (var i = index + 1; i < orderFlowRSKM.length; i++) {
              var description = "";
              var orderStatusItem = "";
              if (orderFlowRSKM[i] == "ENQUIRY ACCEPTED") {
                description = "THE SELLER HAS ACCEPTED THE ENQUIRY.";
                orderStatusItem = `<div class="hr-sect m-0">GC</div>
            <div class="row">
            <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center">
            <i class="fa-regular fa-hourglass fa-2x pl-4"></i>
            </div>
            <div class="col-8 col-lg-9">
                <div class="card-body pl-0">
                    <div class="row">
                        <div class="d-flex justify-content-start">
                            <h5 class="card-title">${orderFlowRSKM[i]} : </h5>
                        </div>
                    </div>
                    <p class="card-text truncate-cart">${description}</p>
                </div>
            </div>
        </div>`;
              } else if (orderFlowRSKM[i] == "ORDER READY") {
                description = "YOUR ORDER IS READY.";
                orderStatusItem = `<div class="hr-sect m-0">GC</div>
            <div class="row">
            <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center">
            <i class="fa-regular fa-hourglass fa-2x pl-4"></i>
            </div>
            <div class="col-8 col-lg-9">
                <div class="card-body pl-0">
                    <div class="row">
                        <div class="d-flex justify-content-start">
                            <h5 class="card-title">${orderFlowRSKM[i]} : </h5>
                        </div>
                    </div>
                    <p class="card-text truncate-cart">${description}</p>
                </div>
            </div>
        </div>`;
              } else if (orderFlowRSKM[i] == "ORDER OUT FOR DELIVERY") {
                description = "YOUR ORDER IS OUT FOR DELIVERY.";
                orderStatusItem = `<div class="hr-sect m-0">GC</div>
            <div class="row">
            <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center">
            <i class="fa-regular fa-hourglass fa-2x pl-4"></i>
            </div>
            <div class="col-8 col-lg-9">
                <div class="card-body pl-0">
                    <div class="row">
                        <div class="d-flex justify-content-start">
                            <h5 class="card-title">${orderFlowRSKM[i]} : </h5>
                        </div>
                    </div>
                    <p class="card-text truncate-cart">${description}</p>
                </div>
            </div>
        </div>`;
              } else if (orderFlowRSKM[i] == "ORDER DELIVERED") {
                description = "YOUR ORDER HAS BEEN DELIVERED.";
                orderStatusItem = `<div class="hr-sect m-0">GC</div>
            <div class="row">
            <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center">
            <i class="fa-regular fa-hourglass fa-2x pl-4"></i>
            </div>
            <div class="col-8 col-lg-9">
                <div class="card-body pl-0">
                    <div class="row">
                        <div class="d-flex justify-content-start">
                            <h5 class="card-title">${orderFlowRSKM[i]} : </h5>
                        </div>
                    </div>
                    <p class="card-text truncate-cart">${description}</p>
                </div>
            </div>
        </div>`;
              }
              $(".orderStatusFlowItems").append(orderStatusItem);
            }
          } else if (orderDeliveryDetails.Delivery_Type == "None") {
            var index = orderFlowSPU.indexOf(lastStatus);
            console.log(index);
            for (var i = index + 1; i < orderFlowSPU.length; i++) {
              var description = "";
              var orderStatusItem = "";
              if (orderFlowSPU[i] == "ENQUIRY ACCEPTED") {
                description = "THE SELLER HAS ACCEPTED THE ENQUIRY.";
                orderStatusItem = `<div class="hr-sect m-0">GC</div>
            <div class="row">
            <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center">
            <i class="fa-regular fa-hourglass fa-2x pl-4"></i>
            </div>
            <div class="col-8 col-lg-9">
                <div class="card-body pl-0">
                    <div class="row">
                        <div class="d-flex justify-content-start">
                            <h5 class="card-title">${orderFlowSPU[i]} : </h5>
                        </div>
                    </div>
                    <p class="card-text truncate-cart">${description}</p>
                </div>
            </div>
        </div>`;
              } else if (orderFlowSPU[i] == "ORDER READY") {
                description = "YOUR ORDER IS READY.";
                orderStatusItem = `<div class="hr-sect m-0">GC</div>
            <div class="row">
            <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center">
            <i class="fa-regular fa-hourglass fa-2x pl-4"></i>
            </div>
            <div class="col-8 col-lg-9">
                <div class="card-body pl-0">
                    <div class="row">
                        <div class="d-flex justify-content-start">
                            <h5 class="card-title">${orderFlowSPU[i]} : </h5>
                        </div>
                    </div>
                    <p class="card-text truncate-cart">${description}</p>
                </div>
            </div>
        </div>`;
              } else if (orderFlowSPU[i] == "ORDER PICKED UP") {
                description = "YOUR ORDER HAS BEEN PICKED UP";
                orderStatusItem = `<div class="hr-sect m-0">GC</div>
            <div class="row">
            <div class="col-4 col-lg-3 d-flex justify-content-center align-items-center">
            <i class="fa-regular fa-hourglass fa-2x pl-4"></i>
            </div>
            <div class="col-8 col-lg-9">
                <div class="card-body pl-0">
                    <div class="row">
                        <div class="d-flex justify-content-start">
                            <h5 class="card-title">${orderFlowSPU[i]} : </h5>
                        </div>
                    </div>
                    <p class="card-text truncate-cart">${description}</p>
                </div>
            </div>
        </div>`;
              }
              $(".orderStatusFlowItems").append(orderStatusItem);
            }
          }
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  };
}
