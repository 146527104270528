var minOrderValue;
var delivery_data = [];
var minOrderFlag = true;
var minOrderStatus = false;
var counter = 1;

export default class vendor_delivery {
  constructor() {
    this.inputFreeDeliveryKms = $("#inputFreeDeliveryKms");
    this.FreeDeliveryCheckBox = $("#FreeDeliveryCheckBox");
    this.selfPickUpCheckBox = $("#selfPickUpCheckBox");

    this.inputPaidDeliveryKms = $(".inputPaidKm");
    this.errorPaidDeliveryKms = $(".paidKmError");
    this.inputPaidDeliveryRs = $(".inputPaidRs");
    this.errorPaidDeliveryRs = $(".paidRsError");
    this.paidDeliveryCheckBox = $(".PaidCheckBox");

    this.inputPaidDeliveryKms1 = $("#inputPaidDeliveryKms1");
    this.inputPaidDeliveryRs1 = $("#inputPaidDeliveryRs1");
    this.paidDeliveryCheckBox1 = $("#paidDeliveryCheckBox1");

    this.inputPaidDeliveryKms2 = $("#inputPaidDeliveryKms2");
    this.inputPaidDeliveryRs2 = $("#inputPaidDeliveryRs2");
    this.paidDeliveryCheckBox2 = $("#paidDeliveryCheckBox2");

    this.inputPaidDeliveryKms3 = $("#inputPaidDeliveryKms3");
    this.inputPaidDeliveryRs3 = $("#inputPaidDeliveryRs3");
    this.paidDeliveryCheckBox3 = $("#paidDeliveryCheckBox3");

    this.inputPaidDeliveryKms4 = $("#inputPaidDeliveryKms4");
    this.inputPaidDeliveryRs4 = $("#inputPaidDeliveryRs4");
    this.paidDeliveryCheckBox4 = $("#paidDeliveryCheckBox4");

    this.inputPaidDeliveryKms5 = $("#inputPaidDeliveryKms5");
    this.inputPaidDeliveryRs5 = $("#inputPaidDeliveryRs5");
    this.paidDeliveryCheckBox5 = $("#paidDeliveryCheckBox5");

    this.inputPaidKm = $(".inputPaidKm");

    this.paid2 = $("#paid2");
    this.paid3 = $("#paid3");
    this.paid4 = $("#paid4");
    this.paid5 = $("#paid5");

    this.inputPackagingCharge = $("#inputPackagingCharge");
    this.inputMinOrder = $("#inputMinOrder");
    this.inputFreeDeliveryKmsError = $("#inputFreeDeliveryKmsError");

    this.inputPaidDeliveryKmsError1 = $("#inputPaidDeliveryKmsError1");
    this.inputPaidDeliveryRsError1 = $("#inputPaidDeliveryRsError1");

    this.inputPaidDeliveryKmsError2 = $("#inputPaidDeliveryKmsError2");
    this.inputPaidDeliveryRsError2 = $("#inputPaidDeliveryRsError2");

    this.inputPaidDeliveryKmsError3 = $("#inputPaidDeliveryKmsError3");
    this.inputPaidDeliveryRsError3 = $("#inputPaidDeliveryRsError3");

    this.inputPaidDeliveryKmsError4 = $("#inputPaidDeliveryKmsError4");
    this.inputPaidDeliveryRsError4 = $("#inputPaidDeliveryRsError4");

    this.inputPaidDeliveryKmsError5 = $("#inputPaidDeliveryKmsError5");
    this.inputPaidDeliveryRsError5 = $("#inputPaidDeliveryRsError5");

    this.inputPackagingChargeError = $("#inputPackagingChargeError");
    this.inputMinOrderError = $("#inputMinOrderError");

    this.addPaidDeliveryBtn = $("#add-Paid-Delivery-Btn");
    this.paidDeliveryDiv = $("#paid-Delivery-Div");
    this.MoreThan5Error = $("#MoreThan5Error");
    this.noDeliverySelectedError = $("#noDeliverySelectedError");
    this.duplicatePaidDeliveryError = $("#duplicatePaidDeliveryError");
    this.PaidCheckBox = $(".PaidCheckBox");
    this.paidKmError = $(".paidKmError");
    this.paidRsError = $(".paidRsError");
    this.stem2submitBtn = $("#stem2submitBtn");

    this.deliveryFormID = $("#gst_Deliery_OPHrsForm");

    this.bindEvents();
  }

  bindEvents = () => {
    var currentPath = window.location.pathname;
    // if (currentPath == "/goancart/Vendor_servicing_delivery_details") {
    this.displayDeliveryOptions();
    // }
    console.log("vendor_delivery Bind");

    this.stem2submitBtn.on("click", this.doFormAction);
    this.Min_order_Validate();
    this.addPaidDeliveryBtn.on("click", this.doAddDelivery);

    this.inputFreeDeliveryKms.on("input", this.checkInputPaidArrays);
    this.inputPaidDeliveryKms.on("input", this.checkInputPaidArrays);
    this.inputPaidDeliveryRs.on("input", this.checkInputPaidArrays);

    this.deliveryFormID.on("focus", "input[type=number]", function (e) {
      $(this).on("wheel.disableScroll", function (e) {
        console.log("inhere");
        e.preventDefault();
      });
    });
  };

  doAddDelivery = () => {
    counter++;
    console.log("inside add");
    console.log(counter);

    if (counter == 2) {
      this.paid2.removeClass("d-none");
    }
    if (counter == 3) {
      this.paid3.removeClass("d-none");
    }
    if (counter == 4) {
      this.paid4.removeClass("d-none");
    }
    if (counter == 5) {
      this.paid5.removeClass("d-none");
      this.addPaidDeliveryBtn.addClass("d-none");
    }
  };
  doFormAction = (e) => {
    e.preventDefault();
    var isPaidDelivValid = this.checkInputPaidArraysValidation();
    console.log("isPaidDelivValid: " + isPaidDelivValid);

    console.log("in doFormAction");
    console.log(this.PaidCheckBox);
    console.log(this.paidKmError);
    console.log(this.paidRsError);
    let isDeliveryValidated = true;
    if (
      !this.FreeDeliveryCheckBox.is(":checked") &&
      !this.selfPickUpCheckBox.is(":checked") &&
      !this.paidDeliveryCheckBox1.is(":checked") &&
      !this.paidDeliveryCheckBox2.is(":checked") &&
      !this.paidDeliveryCheckBox3.is(":checked") &&
      !this.paidDeliveryCheckBox4.is(":checked") &&
      !this.paidDeliveryCheckBox5.is(":checked")
    ) {
      this.noDeliverySelectedError.html(
        "Please select atleast one delivery option."
      );
      isDeliveryValidated = false;
    } else {
      this.noDeliverySelectedError.html(" ");
      console.log("Delivery is selected");
    }

    //checking if free delivery is selected
    let isFreeDeliveryValidated = true;

    if (this.FreeDeliveryCheckBox.is(":checked")) {
      isFreeDeliveryValidated = this.Free_del_Validate();
      console.log("free checked");

      if (isFreeDeliveryValidated) {
        this.inputFreeDeliveryKmsError.html("");
      }
    } else {
      this.inputFreeDeliveryKmsError.html("");
    }

    //checking if paid delivery is selected
    let isPaidDeliveryValidated1 = true;
    let isPaidDeliveryValidated2 = true;
    let isPaidDeliveryValidated3 = true;
    let isPaidDeliveryValidated4 = true;
    let isPaidDeliveryValidated5 = true;

    if (this.paidDeliveryCheckBox1.is(":checked")) {
      console.log("inside paid 1 validation");
      isPaidDeliveryValidated1 = this.Paid_delivery_Validate1();
      if (isPaidDeliveryValidated1) {
        console.log("Paid Delivery 1 is VALID");
        this.inputPaidDeliveryKmsError1.html("");
      }
    } else {
      // this.inputPaidDeliveryKmsError1.html("");
      // this.inputPaidDeliveryRsError1.html("");
    }

    if (this.paidDeliveryCheckBox2.is(":checked")) {
      isPaidDeliveryValidated2 = this.Paid_delivery_Validate2();
      if (isPaidDeliveryValidated2) {
        console.log("Paid Delivery 2 is VALID");
        this.inputPaidDeliveryKmsError2.html("");
      }
    } else {
      // this.inputPaidDeliveryKmsError2.html("");
      // this.inputPaidDeliveryRsError2.html("");
    }

    if (this.paidDeliveryCheckBox3.is(":checked")) {
      isPaidDeliveryValidated3 = this.Paid_delivery_Validate3();
      if (isPaidDeliveryValidated3) {
        console.log("Paid Delivery 3 is VALID");
        this.inputPaidDeliveryKmsError3.html("");
      }
    } else {
      // this.inputPaidDeliveryKmsError3.html("");
      // this.inputPaidDeliveryRsError3.html("");
    }

    if (this.paidDeliveryCheckBox4.is(":checked")) {
      isPaidDeliveryValidated4 = this.Paid_delivery_Validate4();
      if (isPaidDeliveryValidated4) {
        console.log("Paid Delivery 4 is VALID");
        this.inputPaidDeliveryKmsError4.html("");
      }
    } else {
      // this.inputPaidDeliveryKmsError4.html("");
      // this.inputPaidDeliveryRsError4.html("");
    }

    if (this.paidDeliveryCheckBox5.is(":checked")) {
      isPaidDeliveryValidated5 = this.Paid_delivery_Validate5();
      if (isPaidDeliveryValidated5) {
        console.log("Paid Delivery 5 is VALID");
        this.inputPaidDeliveryKmsError5.html("");
      }
    } else {
      // this.inputPaidDeliveryKmsError5.html("");
      // this.inputPaidDeliveryRsError5.html("");
    }

    //checking if packaging charge is validated

    let isPackagingChargeValidated = this.Packaging_charge_Validate();
    if (isPackagingChargeValidated) {
      console.log("Packaging charge is VALID");
      this.inputPackagingChargeError.html("");
    }

    //checking if min order is validated
    let isMinOrderValidated = this.Validate_min_order();
    if (isMinOrderValidated) {
      console.log("min enquiry is VALID");
      this.inputMinOrderError.html("");
    }
    // if (
    //   isFreeDeliveryValidated &&
    //   isPaidDeliveryValidated1 &&
    //   isPaidDeliveryValidated2 &&
    //   isPaidDeliveryValidated3 &&
    //   isPaidDeliveryValidated4 &&
    //   isPaidDeliveryValidated5 &&
    //   isPackagingChargeValidated &&
    //   isMinOrderValidated &&
    //   isDeliveryValidated &&
    //   isPaidDeliveryValidated &&
    //   isPaidDelivValid
    // )
    if (
      isFreeDeliveryValidated &&
      isPaidDeliveryValidated1 &&
      isPaidDeliveryValidated2 &&
      isPaidDeliveryValidated3 &&
      isPaidDeliveryValidated4 &&
      isPaidDeliveryValidated5 &&
      isPackagingChargeValidated &&
      isMinOrderValidated &&
      isDeliveryValidated &&
      isPaidDelivValid
    ) {
      this.Submit_Form();
    }
  };

  //to check if free delivery KMs is valid
  Free_del_Validate = () => {
    let flag = true;
    console.log("in free del validate");

    if (
      !this.inputFreeDeliveryKms.val() ||
      this.inputFreeDeliveryKms.val() <= 0 ||
      this.inputFreeDeliveryKms.val() > 100
    ) {
      this.inputFreeDeliveryKmsError.html("Kms must be min 1 and max 100.");

      flag = false;
    }

    return flag;
  };

  //to check if paid delivery 1 is valid
  Paid_delivery_Validate1 = () => {
    let flag = true;
    console.log("in paid del validate 1");
    if (
      !this.inputPaidDeliveryKms1.val() ||
      this.inputPaidDeliveryKms1.val() <= 0
    ) {
      this.inputPaidDeliveryKmsError1.html("Kms must be min 1.");

      flag = false;
    }

    if (this.FreeDeliveryCheckBox.is(":checked")) {
      var diff =
        this.inputPaidDeliveryKms1.val() - this.inputFreeDeliveryKms.val();
      console.log(diff);
      if (diff <= 0) {
        console.log("inside paid 1");
        console.log(this.inputFreeDeliveryKms.val());
        console.log(this.inputPaidDeliveryKms1.val());
        this.inputPaidDeliveryKmsError1.html(
          "Paid Kms must be more than Free Kms"
        );

        flag = false;
      }
    }

    if (
      !this.inputPaidDeliveryRs1.val() ||
      this.inputPaidDeliveryRs1.val() <= 0 ||
      this.inputPaidDeliveryRs1.val() > 5000
    ) {
      this.inputPaidDeliveryRsError1.html("Rs must be min 1 max 5000.");

      flag = false;
    } else {
      this.inputPaidDeliveryRsError1.html("");
    }
    return flag;
  };

  //to check if paid delivery is valid
  Paid_delivery_Validate2 = () => {
    let flag = true;
    console.log("in paid del validate");
    if (
      !this.inputPaidDeliveryKms2.val() ||
      this.inputPaidDeliveryKms2.val() <= 0
    ) {
      this.inputPaidDeliveryKmsError2.html("Kms must be min 1.");

      flag = false;
    }

    if (this.FreeDeliveryCheckBox.is(":checked")) {
      var diff =
        this.inputPaidDeliveryKms2.val() - this.inputFreeDeliveryKms.val();
      console.log(diff);
      if (diff <= 0) {
        console.log("inside paid 2");
        console.log(this.inputFreeDeliveryKms.val());
        console.log(this.inputPaidDeliveryKms2.val());
        this.inputPaidDeliveryKmsError2.html(
          "Paid Kms must be more than Free Kms"
        );

        flag = false;
      }
    }

    if (
      !this.inputPaidDeliveryRs2.val() ||
      this.inputPaidDeliveryRs2.val() <= 0 ||
      this.inputPaidDeliveryRs2.val() > 5000
    ) {
      this.inputPaidDeliveryRsError2.html("Rs must be min 1 max 5000.");

      flag = false;
    } else {
      this.inputPaidDeliveryRsError2.html("");
    }

    return flag;
  };

  Paid_delivery_Validate3 = () => {
    let flag = true;
    console.log("in paid del validate");
    if (
      !this.inputPaidDeliveryKms3.val() ||
      this.inputPaidDeliveryKms3.val() <= 0
    ) {
      this.inputPaidDeliveryKmsError3.html("Kms must be min 1.");

      flag = false;
    }

    if (this.FreeDeliveryCheckBox.is(":checked")) {
      var diff =
        this.inputPaidDeliveryKms3.val() - this.inputFreeDeliveryKms.val();
      console.log(diff);
      if (diff <= 0) {
        console.log("inside paid 3");
        console.log(this.inputFreeDeliveryKms.val());
        console.log(this.inputPaidDeliveryKms3.val());
        this.inputPaidDeliveryKmsError3.html(
          "Paid Kms must be more than Free Kms"
        );

        flag = false;
      }
    }

    if (
      !this.inputPaidDeliveryRs3.val() ||
      this.inputPaidDeliveryRs3.val() <= 0 ||
      this.inputPaidDeliveryRs3.val() > 5000
    ) {
      this.inputPaidDeliveryRsError3.html("Rs must be min 1 max 5000.");

      flag = false;
    } else {
      this.inputPaidDeliveryRsError3.html("");
    }

    return flag;
  };

  Paid_delivery_Validate4 = () => {
    let flag = true;
    console.log("in paid del validate");
    if (
      !this.inputPaidDeliveryKms4.val() ||
      this.inputPaidDeliveryKms4.val() <= 0
    ) {
      this.inputPaidDeliveryKmsError4.html("Kms must be min 1.");

      flag = false;
    }

    if (this.FreeDeliveryCheckBox.is(":checked")) {
      var diff =
        this.inputPaidDeliveryKms4.val() - this.inputFreeDeliveryKms.val();
      console.log(diff);
      if (diff <= 0) {
        console.log("inside paid 4");
        console.log(this.inputFreeDeliveryKms.val());
        console.log(this.inputPaidDeliveryKms4.val());
        this.inputPaidDeliveryKmsError4.html(
          "Paid Kms must be more than Free Kms"
        );

        flag = false;
      }
    }
    if (
      !this.inputPaidDeliveryRs4.val() ||
      this.inputPaidDeliveryRs4.val() <= 0 ||
      this.inputPaidDeliveryRs4.val() > 5000
    ) {
      this.inputPaidDeliveryRsError4.html("Rs must be min 1 max 5000.");

      flag = false;
    } else {
      this.inputPaidDeliveryRsError4.html("");
    }

    return flag;
  };

  Paid_delivery_Validate5 = () => {
    let flag = true;
    console.log("in paid del validate");
    if (
      !this.inputPaidDeliveryKms5.val() ||
      this.inputPaidDeliveryKms5.val() <= 0
    ) {
      this.inputPaidDeliveryKmsError5.html("Kms must be min 1.");

      flag = false;
    }

    if (this.FreeDeliveryCheckBox.is(":checked")) {
      var diff =
        this.inputPaidDeliveryKms5.val() - this.inputFreeDeliveryKms.val();
      console.log(diff);
      if (diff <= 0) {
        console.log("inside paid 5");
        console.log(this.inputFreeDeliveryKms.val());
        console.log(this.inputPaidDeliveryKms5.val());
        this.inputPaidDeliveryKmsError5.html(
          "Paid Kms must be more than Free Kms"
        );

        flag = false;
      }
    }

    if (
      !this.inputPaidDeliveryRs5.val() ||
      this.inputPaidDeliveryRs5.val() <= 0 ||
      this.inputPaidDeliveryRs5.val() > 5000
    ) {
      this.inputPaidDeliveryRsError5.html("Rs must be min 1 max 5000.");

      flag = false;
    } else {
      this.inputPaidDeliveryRsError5.html("");
    }

    return flag;
  };

  Packaging_charge_Validate = () => {
    let flag = true;
    console.log("in packaging charge validate");

    if (
      this.inputPackagingCharge.val() > 5000 ||
      this.inputPackagingCharge.val() < 0
    ) {
      this.inputPackagingChargeError.html(
        "Packaging charge must be min 0 and max 5000."
      );

      flag = false;
    }

    return flag;
  };

  Min_order_Validate = () => {
    minOrderFlag = true;
    console.log("in Min_order_Validate ");
    minOrderStatus = false;

    if (true) {
      $.ajax({
        type: "GET",
        url: baseURL + "api/Vendor/GetMinOrderValue",
        success: this.Validate_Min,
      });
    }
    console.log("returning from Min_order_Validate");
    return true;
  };

  Validate_Min = (res) => {
    res.forEach((element) => {
      minOrderValue = element.Value;
    });

    return true;
  };

  Validate_min_order = () => {
    let flag = true;
    console.log("in packaging charge validate");
    console.log(minOrderValue);
    var minOrderdiff = this.inputMinOrder.val() - minOrderValue;
    if (
      this.inputMinOrder.length < 1 ||
      minOrderdiff < 0 ||
      this.inputMinOrder.val() > 5000
    ) {
      this.inputMinOrderError.html(
        `Minimum enquiry charge must be min ${minOrderValue} and max 5000.`
      );

      flag = false;
    }

    return flag;
  };

  fillDeliveryData = () => {
    delivery_data = [];

    if (this.FreeDeliveryCheckBox.is(":checked")) {
      var free_delivery_data = {
        Picklist_ID: "67bf6dfa-4497-11e9-b548-778fd1e3dff0",
        Distance_kms: this.inputFreeDeliveryKms.val(),
        Delivery_Type: "KM",
        Delivery_Name: "Free Delivery",
        Price: "0",
        IsAvailable: "1",
      };
      delivery_data.push(free_delivery_data);
      console.log("delivery_data checked:");
      console.log(delivery_data);
    }
    if (!this.FreeDeliveryCheckBox.is(":checked")) {
      var free_delivery_data = {
        Picklist_ID: "67bf6dfa-4497-11e9-b548-778fd1e3dff0",
        Distance_kms: 0,
        Delivery_Type: "KM",
        Delivery_Name: "Free Delivery",
        Price: "0",
        IsAvailable: null,
      };
      delivery_data.push(free_delivery_data);
    }

    if (this.selfPickUpCheckBox.is(":checked")) {
      var self_delivery_data = {
        Picklist_ID: "67bf6dff-4497-11e9-b548-778fd1e3dff0",
        Distance_kms: 0,
        Delivery_Type: "None",
        Delivery_Name: "Self Pick-up",
        Price: 0,
        IsAvailable: "1",
      };
      delivery_data.push(self_delivery_data);
      console.log("delivery_data:");
      console.log(delivery_data);
    }
    if (!this.selfPickUpCheckBox.is(":checked")) {
      var self_delivery_data = {
        Picklist_ID: "67bf6dff-4497-11e9-b548-778fd1e3dff0",
        Distance_kms: 0,
        Delivery_Type: "None",
        Delivery_Name: "Self Pick-up",
        Price: 0,
        IsAvailable: null,
      };
      delivery_data.push(self_delivery_data);
    }

    if (this.paidDeliveryCheckBox1.is(":checked")) {
      var Paid1_delivery_data = {
        Picklist_ID: "67bf6dfe-4497-11e9-b548-778fd1e3dff0",
        Distance_kms: this.inputPaidDeliveryKms1.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery1",
        Price: this.inputPaidDeliveryRs1.val(),
        IsAvailable: "1",
      };
      delivery_data.push(Paid1_delivery_data);
    }
    if (!this.paidDeliveryCheckBox1.is(":checked")) {
      var Paid1_delivery_data = {
        Picklist_ID: "67bf6dfe-4497-11e9-b548-778fd1e3dff0",
        Distance_kms: this.inputPaidDeliveryKms1.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery1",
        Price: this.inputPaidDeliveryRs1.val(),
        IsAvailable: null,
      };
      delivery_data.push(Paid1_delivery_data);
    }

    if (this.paidDeliveryCheckBox2.is(":checked")) {
      var Paid2_delivery_data = {
        Picklist_ID: "4927024c-49f2-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms2.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery2",
        Price: this.inputPaidDeliveryRs2.val(),
        IsAvailable: "1",
      };
      delivery_data.push(Paid2_delivery_data);
    }
    if (!this.paidDeliveryCheckBox2.is(":checked")) {
      var Paid2_delivery_data = {
        Picklist_ID: "4927024c-49f2-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms2.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery2",
        Price: this.inputPaidDeliveryRs2.val(),
        IsAvailable: null,
      };
      delivery_data.push(Paid2_delivery_data);
    }
    if (this.paidDeliveryCheckBox3.is(":checked")) {
      var Paid3_delivery_data = {
        Picklist_ID: "532f81a8-49f3-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms3.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery",
        Price: this.inputPaidDeliveryRs3.val(),
        IsAvailable: "1",
      };
      delivery_data.push(Paid3_delivery_data);
    }
    if (!this.paidDeliveryCheckBox3.is(":checked")) {
      var Paid3_delivery_data = {
        Picklist_ID: "532f81a8-49f3-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms3.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery",
        Price: this.inputPaidDeliveryRs3.val(),
        IsAvailable: null,
      };
      delivery_data.push(Paid3_delivery_data);
    }
    if (this.paidDeliveryCheckBox4.is(":checked")) {
      var Paid4_delivery_data = {
        Picklist_ID: "b2c9bc3f-49f3-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms4.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery",
        Price: this.inputPaidDeliveryRs4.val(),
        IsAvailable: "1",
      };
      delivery_data.push(Paid4_delivery_data);
    }

    if (!this.paidDeliveryCheckBox4.is(":checked")) {
      var Paid4_delivery_data = {
        Picklist_ID: "b2c9bc3f-49f3-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms4.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery",
        Price: this.inputPaidDeliveryRs4.val(),
        IsAvailable: null,
      };
      delivery_data.push(Paid4_delivery_data);
    }
    if (this.paidDeliveryCheckBox5.is(":checked")) {
      var Paid5_delivery_data = {
        Picklist_ID: "f7b18514-49f3-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms5.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery",
        Price: this.inputPaidDeliveryRs5.val(),
        IsAvailable: "1",
      };
      delivery_data.push(Paid5_delivery_data);
    }
    if (!this.paidDeliveryCheckBox5.is(":checked")) {
      var Paid5_delivery_data = {
        Picklist_ID: "f7b18514-49f3-11ed-8191-489ebd474281",
        Distance_kms: this.inputPaidDeliveryKms5.val(),
        Delivery_Type: "RSKM",
        Delivery_Name: "Paid Delivery",
        Price: this.inputPaidDeliveryRs5.val(),
        IsAvailable: null,
      };
      delivery_data.push(Paid5_delivery_data);
    }
  };
  // Form Submit Flow
  Submit_Form = () => {
    console.log("Inside submit flow");

    this.fillDeliveryData();

    $.ajax({
      type: "POST",

      url: baseURL + "api/Vendor/AddUpdateDeliveryOptions",
      headers: { Authorization: X_API_KEY },

      data: { delivery_data: delivery_data },

      success: function (res) {
        console.log(res);
      },
    });

    $.ajax({
      type: "POST",

      url: baseURL + "api/Vendor/PackagingMinValue",
      headers: { Authorization: X_API_KEY },

      data: {
        // 'Picklist_ID' :"67bf6dfe-4497-11e9-b548-778fd1e3dff0",
        Packaging_charge: this.inputPackagingCharge.val(),
        Minimum_Order: this.inputMinOrder.val(),
      },

      success: function (res) {
        console.log(res);
        location.reload();
      },
    });
    // this.formstep3.removeClass("current-item");
    // this.formstep4.addClass("current-item");
  };

  displayDeliveryOptions = () => {
    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/GetDeliveryOptions",
      headers: { Authorization: X_API_KEY },
      async: false,
      success: this.integrateDeliveryOptions,
    });

    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/getVendorDetails",
      headers: { Authorization: X_API_KEY },
      async: false,
      success: (result) => {
        document.getElementById("inputPackagingCharge").value =
          result.Packaging_charge;
        document.getElementById("inputMinOrder").value = result.Minimum_Order;
      },
    });
  };

  integrateDeliveryOptions = (res) => {
    console.log("inside integrateDeliveryOptions");
    console.log(res);
    // for(var i=0;i<7;i++){
    //     if (res["Delivery_Type"] == "KM") {
    //             document.getElementById("inputFreeDeliveryKms").value =
    //               res["Distance_kms"];
    //           }
    // }
    res.forEach((element) => {
      //FREE DELIVERY
      if (element.Delivery_Type == "KM") {
        if (element.IsAvailable == true) {
          document.getElementById("FreeDeliveryCheckBox").checked = true;
        }
        document.getElementById("inputFreeDeliveryKms").value =
          element.Distance_kms;
      }

      //SELF PICKUP
      if (element.Delivery_Type == "None" && element.IsAvailable == true) {
        document.getElementById("selfPickUpCheckBox").checked = true;
      }
      //PAID DELIVERY
      if (element.Delivery_Type == "RSKM") {
        var paidNo = element.Delivery_Name.substring(13, 15);
        if (element.IsAvailable == true) {
          document.getElementById("paidDeliveryCheckBox1").checked = true;
        }

        console.log(paidNo);
        var item = `<div class="" id="paid${paidNo}">
        <div class="d-flex bd-highlight">
            <div class="p-2 flex-grow-1 bd-highlight">
                <input type="number" class="form-control inputPaidKm" id="inputPaidDeliveryKms${paidNo}" name="inputPaidDeliveryKms" placeholder="KMs">
                <span class='text-danger paidKmError' id="inputPaidDeliveryKmsError${paidNo}"></span>
            </div>
            <div class="p-2 flex-grow-1 bd-highlight">
                <input type="number" class="form-control" id="inputPaidDeliveryRs${paidNo}" name="inputPaidDeliveryRs" placeholder="Rs">
                <span class='text-danger paidRsError' id="inputPaidDeliveryRsError${paidNo}"></span>
            </div>
            <div class="p-2 pl-4 pt-3 bd-highlight d-flex justify-content-end ">
                <input class="form-check-input align-content-center checkbox-size PaidCheckBox" type="checkbox" value="" id="paidDeliveryCheckBox${paidNo}">
            </div>
        </div>
    </div>`;
        // $("#paid-Delivery-Div").append(item);
        if (paidNo == "1") {
          document.getElementById("inputPaidDeliveryKms1").value =
            element.Distance_kms;
          document.getElementById("inputPaidDeliveryRs1").value = element.Price;
        }
        if (paidNo == "2") {
          if (element.IsAvailable == "1") {
            document.getElementById("paidDeliveryCheckBox2").checked = true;
          }

          document.getElementById("inputPaidDeliveryKms2").value =
            element.Distance_kms == "0" ? null : element.Distance_kms;
          document.getElementById("inputPaidDeliveryRs2").value =
            element.Distance_kms == "0" ? null : element.Price;
        }
        if (paidNo == "3") {
          if (element.IsAvailable == "1") {
            document.getElementById("paidDeliveryCheckBox3").checked = true;
          }
          document.getElementById("inputPaidDeliveryKms3").value =
            element.Distance_kms == "0" ? null : element.Distance_kms;
          document.getElementById("inputPaidDeliveryRs3").value =
            element.Distance_kms == "0" ? null : element.Price;
        }
        if (paidNo == "4") {
          if (element.IsAvailable == "1") {
            document.getElementById("paidDeliveryCheckBox4").checked = true;
          }
          document.getElementById("inputPaidDeliveryKms4").value =
            element.Distance_kms == "0" ? null : element.Distance_kms;
          document.getElementById("inputPaidDeliveryRs4").value =
            element.Distance_kms == "0" ? null : element.Price;
        }
        if (paidNo == "5") {
          if (element.IsAvailable == "1") {
            document.getElementById("paidDeliveryCheckBox5").checked = true;
          }
          document.getElementById("inputPaidDeliveryKms5").value =
            element.Distance_kms == "0" ? null : element.Distance_kms;
          document.getElementById("inputPaidDeliveryRs5").value =
            element.Distance_kms == "0" ? null : element.Price;
        }

        if (element.Distance_kms != "0" && paidNo != "1") {
          this.doAddDelivery();
        }
      }
    });
  };

  validateInputPaidKm = (e) => {
    var inputNumber = e.target.id.charAt(e.target.id.length - 1);
    var otherInput = "";
    var currentVal = parseInt(e.target.value);
    console.log(e.target.value);
    console.log(currentVal);
    console.log(e.target.value == "");

    for (var i = 1; i <= this.inputPaidDeliveryKms.length; i++) {
      otherInput = document.getElementById("inputPaidDeliveryKms" + i);
      var otherValue = parseInt(otherInput.value);

      if (inputNumber == i) {
        console.log("Break");
        break;
      }

      if (inputNumber == i || isNaN(otherValue)) {
        console.log("Continue");
        continue;
      }
      const InputError = document.getElementById(
        "inputPaidDeliveryKmsError" + inputNumber
      );
      if (otherValue < currentVal) {
        console.log("Input Is Valid");
        InputError.innerHTML = "";
        InputError.value = 0;
      } else {
        console.error("Input Is Not Valid");
        InputError.value = 1;
        InputError.innerHTML = "Km should be greater than " + otherValue;
      }
    }

    for (var i = this.inputPaidDeliveryKms.length; i > 0; i--) {
      otherInput = document.getElementById("inputPaidDeliveryKms" + i);
      var otherValue = parseInt(otherInput.value);
      if (inputNumber == i) {
        break;
      }
      if (inputNumber == i || isNaN(otherValue)) {
        continue;
      }
      const InputError = document.getElementById(
        "inputPaidDeliveryKmsError" + inputNumber
      );
      if (otherValue > currentVal) {
        if (inputNumber == 1) {
          InputError.innerHTML = "";
          InputError.value = 0;
        }
      } else {
        console.error("Input Is Not Valid");
        InputError.value = 1;
        InputError.innerHTML = "Km should be lesser than " + otherValue;
      }
    }

    for (var i = 1; i <= this.inputPaidDeliveryKms.length; i++) {
      console.log(this.errorPaidDeliveryRs[i - 1].value);
    }
  };

  validateInputPaidRs = (e) => {
    var inputNumber = e.target.id.charAt(e.target.id.length - 1);
    var otherInput = "";
    const kmRsInput = document.getElementById(
      "inputPaidDeliveryRs" + inputNumber
    );
    var currentVal = parseInt(kmRsInput.value);
    console.log(e.target.value);
    console.log(currentVal);
    console.log(e.target.value == "");

    for (var i = 1; i <= this.inputPaidDeliveryRs.length; i++) {
      otherInput = document.getElementById("inputPaidDeliveryRs" + i);
      var otherValue = parseInt(otherInput.value);

      if (inputNumber == i) {
        console.log("Break");
        break;
      }

      if (inputNumber == i || isNaN(otherValue)) {
        console.log("Continue");
        continue;
      }
      const InputError = document.getElementById(
        "inputPaidDeliveryRsError" + inputNumber
      );
      if (otherValue < currentVal) {
        console.log("Input Is Valid");
        InputError.innerHTML = "";
        InputError.value = 0;
      } else {
        console.error("Input Is Not Valid");
        InputError.value = 1;
        InputError.innerHTML = "Km should be greater than " + otherValue;
      }
    }

    for (var i = this.inputPaidDeliveryRs.length; i > 0; i--) {
      otherInput = document.getElementById("inputPaidDeliveryRs" + i);
      var otherValue = parseInt(otherInput.value);
      if (inputNumber == i) {
        break;
      }
      if (inputNumber == i || isNaN(otherValue)) {
        continue;
      }
      const InputError = document.getElementById(
        "inputPaidDeliveryRsError" + inputNumber
      );
      if (otherValue > currentVal) {
        if (inputNumber == 1) {
          InputError.innerHTML = "";
          InputError.value = 0;
        }
      } else {
        console.error("Input Is Not Valid");
        InputError.value = 1;
        InputError.innerHTML = "Km should be lesser than " + otherValue;
      }
    }

    for (var i = 1; i <= this.inputPaidDeliveryRs.length; i++) {
      console.log(this.errorPaidDeliveryRs[i - 1].value);
    }
  };

  checkInputPaidArrays = () => {
    //To Create Paid Delivery Km Array
    var PaidKmArray = [];
    for (let i = 0; i < this.inputPaidDeliveryKms.length; i++) {
      var elementKm = this.inputPaidDeliveryKms[i].value;
      PaidKmArray.push(elementKm);
    }
    console.log(PaidKmArray);
    PaidKmArray = this.removeEmptyArrayEle(PaidKmArray);

    //To Create Paid Delivery Rs Array
    var PaidRsArray = [];
    for (let i = 0; i < this.inputPaidDeliveryRs.length; i++) {
      var elementRs = this.inputPaidDeliveryRs[i].value;
      PaidRsArray.push(elementRs);
    }
    console.log(PaidRsArray);
    PaidRsArray = this.removeEmptyArrayEle(PaidRsArray);

    //To fix Error if Any of the array in empty
    var PaidKmArrayLength = 0;
    var PaidRsArrayLength = 0;
    if (PaidKmArray) {
      console.log("NO LENGTH");
      PaidKmArrayLength = PaidKmArray.length;
    }
    if (PaidRsArray) {
      console.log("NO LENGTH");
      PaidRsArrayLength = PaidRsArray.length;
    }

    //To get Number of paid delivery input used
    var ArrLength =
      PaidKmArrayLength > PaidRsArrayLength
        ? PaidKmArrayLength
        : PaidRsArrayLength;
    console.log("ArrLength " + ArrLength);

    var counter = 0;
    var km = "";
    var kmNext = "";
    var kmError = "";
    var kmNextError = "";
    var Rs = "";
    var RsNext = "";
    var RsError = "";
    var RsNextError = "";
    for (let i = 0; i < this.inputPaidDeliveryKms.length; i++) {
      km = parseInt(this.inputPaidDeliveryKms[i].value);
      // kmNext = this.inputPaidDeliveryKms[i + 1].value;
      kmError = this.errorPaidDeliveryKms[i];
      // kmNextError = this.errorPaidDeliveryKms[i + 1];
      Rs = parseInt(this.inputPaidDeliveryRs[i].value);
      // RsNext = this.inputPaidDeliveryRs[i + 1].value;
      RsError = this.errorPaidDeliveryRs[i];
      // RsNextError = this.errorPaidDeliveryRs[i + 1];

      //For first paid Deliery input
      if (i == 0) {
        console.log(km + "  & " + Rs);
        if (!(km > 0)) {
          console.log("Empty");
          kmError.value = 1;
          kmError.innerHTML = "Enter value greater than 0";
        } else {
          kmError.value = 0;
          kmError.innerHTML = "";
        }
        if (!(Rs > 0)) {
          console.log("Empty");

          RsError.value = 1;
          RsError.innerHTML = "Enter value greater than 0";
        } else {
          RsError.value = 0;
          RsError.innerHTML = "";
        }
        var freeKm = parseInt(this.inputFreeDeliveryKms.val());
        console.log("inputFreeDeliveryKms");
        console.log("km: " + km + "inputFreeDeliveryKms: " + freeKm);
        if (km <= freeKm) {
          console.log("Empty");
          kmError.value = 1;
          kmError.innerHTML = "Enter value greater Free Delivery Km " + freeKm;
        }
        if (ArrLength == 0) {
          kmError.value = 0;
          kmError.innerHTML = "";
          RsError.value = 0;
          RsError.innerHTML = "";
        }
      }
      // For remaining Used paid Delivery inputs
      else if (i > 0 && i < ArrLength) {
        console.log("Setting Pre Val");
        var kmPrev =
          this.inputPaidDeliveryKms[i - 1].value == ""
            ? 0
            : parseInt(this.inputPaidDeliveryKms[i - 1].value);
        var RsPrev =
          this.inputPaidDeliveryRs[i - 1].value == ""
            ? 0
            : parseInt(this.inputPaidDeliveryRs[i - 1].value);
        console.log("Done Setting Pre Val");
        if (!(km > 0) && !(Rs > 0)) {
          console.log("Empty");
          kmError.value = 1;
          kmError.innerHTML = "Enter value greater than " + kmPrev;
          RsError.value = 1;
          RsError.innerHTML = "Enter value greater than " + RsPrev;
        }
        if (Rs <= RsPrev || isNaN(Rs)) {
          console.log("Empty Rs");
          RsError.value = 1;
          RsError.innerHTML = "Enter value greater than " + RsPrev;
        } else if (Rs > RsPrev) {
          RsError.value = 0;
          RsError.innerHTML = "";
        }
        if (km <= kmPrev || isNaN(km)) {
          console.log("Empty km");
          kmError.value = 1;
          kmError.innerHTML = "Enter value greater than " + kmPrev;
        } else if (km > kmPrev) {
          kmError.value = 0;
          kmError.innerHTML = "";
        }
        if (km > 0 && Rs > 0 && km > kmPrev && Rs > RsPrev) {
          kmError.value = 0;
          kmError.innerHTML = "";
          RsError.value = 0;
          RsError.innerHTML = "";
          counter++;
        }
      }
      //To clear Unused Paid delivery inputs
      else if (i >= ArrLength) {
        console.log("CLEARING ERRORS");

        console.log("Empty");
        kmError.value = 0;
        kmError.innerHTML = "";
        RsError.value = 0;
        RsError.innerHTML = "";
      }
    }
  };

  removeEmptyArrayEle = (myArray) => {
    // let myArray = [1, 2, 3, 4, 5];
    console.log(myArray.length);
    for (let i = myArray.length - 1; i >= 0; i--) {
      if (myArray[i] == "") {
        myArray.splice(i, 1); // removes 1 element starting from index i
      } else if (myArray[i] != "") {
        console.log("After Deleting");
        console.log(myArray);
        return myArray;
      }
    }
    console.log(myArray);
  };

  checkInputPaidArraysValidation = () => {
    for (var i = 0; i < this.inputPaidDeliveryKms.length; i++) {
      var isKmsError = this.errorPaidDeliveryKms[i].value;
      console.log(isKmsError);
      if (isKmsError == 1) {
        return false;
      }
      var isRsError = this.errorPaidDeliveryRs[i].value;
      console.log(isRsError);
      if (isRsError == 1) {
        return false;
      }
    }
    return true;
  };
}
