//Favourites Utilities file

import Util from "./util";
var UtilObject = new Util();

export default class favUtil {
  getCustomerFavouriteList = () => {
    var cookies = UtilObject.getAllCookies();
    if (cookies.hasOwnProperty("CUSTOMER_ID")) {
      $.ajax({
        url: baseURL + "api/customer/Account/getFavorites",
        type: "GET",
        async: false,
        headers: { Authorization: X_API_KEY },
        success: (result) => {
          console.log(result);
          return result;
        },
        error: (result) => {
          console.log(result);
          return "ERROR OCCURED";
        },
      });
    } else {
      return "YOU NEED TO LOG IN";
    }
  };

  favouritesToggle = (vendorID, status) => {
    var cookies = UtilObject.getAllCookies();
    if (cookies.hasOwnProperty("CUSTOMER_ID")) {
      $.ajax({
        url: baseURL + "api/customer/Account/AddRemoveFavorite",
        type: "POST",
        async: false,
        data: {
          Vendor_ID: vendorID,
          Status: status,
        },
        headers: { Authorization: X_API_KEY },
        success: (result) => {
          console.log(result);
          return result;
        },
        error: (error) => {
          console.log(error);
          return "ERROR OCCURED";
        },
      });
    } else {
      return "YOU NEED TO LOG IN";
    }
  };
}
