var ProductImages = [];
//To do validation of uploaded image
var imageIsImage;
var imageIsImageTwo;
var imageIsImageThree;
var ImageURL;
var SpecialitiesArray = [];
var SpecialitiesArray = [];
var vendorProductNames = [];
var productKeyBaseURLDetailpg = "";

import s3Util from "../helpers/s3Util";
var s3Object = new s3Util();

// import Cropper from "cropperjs";
// import vendor_image_cropperjs from "./vendor_image_cropperjs";

export default class vendor_details {
  constructor() {
    this.formstep1 = $("#formstep1");
    this.formstep2 = $("#formstep2");
    this.formstep3 = $("#formstep3");
    this.formstep4 = $("#formstep4");
    this.formstep5 = $("#formstep5");

    this.Products_Services_Form4 = $(".Products-Services-Form4");
    this.inputProductServiceName = $("#inputProductServiceName");
    this.inputCategory = $("#inputCategory");
    this.showGSTPercent = $("#showGSTPercent");
    this.inputGSTPercent = $("#inputGSTPercent");
    this.inputPrice = $("#inputPrice");
    this.inputQuantity = $("#inputQuantity");

    this.GSTDetails_page = $(".GSTDetails-page");
    this.Products_Services_page4 = $(".Products-Services-page4");

    this.inputProductServiceNameError = $(".inputProductServiceNameError");
    this.discriptionError = $("#discriptionError");
    this.categoryError1 = $("#categoryError1");
    this.GSTPercentError = $("#GSTPercentError");
    this.priceError = $("#priceError");
    this.quantityError = $("#quantityError");
    this.page4PrevBtn = $("#page4PrevBtn");
    this.page4SubmitBtn = $("#page4SubmitBtn");

    this.inputGSTRegistered = $("#inputGSTRegistered");

    this.stem2submitBtn = $("#stem2submitBtn");

    this.imageOneError = $(".image-1-error");
    this.imageTwoError = $(".image-2-error");
    this.imageThreeError = $(".image-3-error");

    this.specicalityError = $("#speciality-error");
    this.checkNonVeg = $("#checkNonVeg");
    this.NonVegDiv = $("#NonVegDiv");
    this.checkreco = $("#checkreco");
    this.S3ImageSubmissionForm = $("#S3ImageSubmissionForm");

    this.productServicesListingPage = $(".product-services-listing-page");
    this.inpFile = $("#inpFile");
    this.gstContinueBtn = $("#stem2submitBtn");

    // Registering Events
    this.bindEvents();
    //this.geocodePosition();
  }

  bindEvents = () => {
    this.page4PrevBtn.on("click", this.doPrev4Action);
    this.page4SubmitBtn.on("click", this.doForm4Action);
    this.gstContinueBtn.on("click", this.getCategoryOptions);

    this.inputCategory.on("change", this.vegNonVegShow);
    this.stem2submitBtn.on("click", this.isVendorGSTReg);

    console.log("in bind");

    this.checkImageFile();
    this.inputCategory.on("change", this.showSpecialityByCategory);
  };

  checkImageFile = () => {
    const inpFile = document.getElementById("inpFile");
    console.log(inpFile);
    const previewContainer = document.getElementById("imagePreview");
    const previewImage = previewContainer.querySelector(".image-preview");

    const inpFileTwo = document.getElementById("inpFileTwo");
    const previewContainerTwo = document.getElementById("imagePreviewTwo");
    const previewImageTwo =
      previewContainerTwo.querySelector(".image-preview-two");

    const inpFileThree = document.getElementById("inpFileThree");
    const previewContainerThree = document.getElementById("imagePreviewThree");
    const previewImageThree = previewContainerThree.querySelector(
      ".image-preview-three"
    );

    // var imageIsImage;

    inpFileThree.addEventListener("change", function (e) {
      //const file = this.files[0];
      // console.log(file);
      if (inpFileThree.files[0] === undefined) {
        // alert("file undefined");
        console.log("inside else");
        previewImageThree.style.display = null;
        previewImageThree.setAttribute("src", "");
      } else {
        const file = this.files[0];
        // console.log(file);
        var fileName = file.name;
        console.log(fileName);
        console.log(typeof fileName);

        var idxDot = fileName.lastIndexOf(".") + 1;
        console.log(typeof idxDot);
        console.log(idxDot);

        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        console.log(typeof extFile);
        console.log(extFile);

        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
          console.log("in file if");
          // alert("good");

          //const fileName = this.file[0];

          // console.log(file);
          if (file) {
            const reader = new FileReader();

            previewImageThree.style.display = "block";

            reader.addEventListener("load", function () {
              console.log(this);

              var image3 = {
                imageFile: this.result,
                imageNo: "03"
              };
              ProductImages.push(image3);
              previewImageThree.setAttribute("src", this.result);
              // $.ajax({
              //   type: "POST",
              //   url: baseURL + "api/Vendor/ImageUploadApi",
              //   //base_url() = http://localhost/tutorial/codeigniter
              //   // method: "POST",
              //   data: {
              //     imageFile: this.result,
              //     imageNo: "03",
              //   },
              //   // contentType: false,
              //   // cache: false,
              //   // processData: false,
              //   success: function (data) {
              //     $("#uploaded_image").html(data);
              //   },
              // });
            });

            reader.readAsDataURL(file);
          } else {
            previewImageThree.style.display = null;
            previewImageThree.setAttribute("src", "");
          }
        } else {
          // alert("Only jpg/jpeg and png files are allowed!");
          var flag = false;
        }
        if (flag == false) {
          imageIsImageThree = false;
          console.log("image 3 is false");
          previewImageThree.style.display = null;
          previewImageThree.setAttribute("src", "");
          // this.imageOneError.html("Please select an image");
        } else {
          imageIsImageThree = true;
          console.log("image 3 is true");
        }
      }
    });

    inpFileTwo.addEventListener("change", function () {
      //const file = this.files[0];

      // console.log(file);

      if (inpFileTwo.files[0] === undefined) {
        // alert("file undefined");
        //console.log("inside else");
        previewImageTwo.style.display = null;
        previewImageTwo.setAttribute("src", "");
      } else {
        const file = this.files[0];
        // console.log(file);
        var fileName = file.name;
        console.log(fileName);
        console.log(typeof fileName);

        var idxDot = fileName.lastIndexOf(".") + 1;
        console.log(typeof idxDot);
        console.log(idxDot);

        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        console.log(typeof extFile);
        console.log(extFile);

        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
          console.log("in file if");
          //alert("good");

          //const fileName = this.file[0];

          // console.log(file);
          if (file) {
            const reader = new FileReader();

            previewImageTwo.style.display = "block";
            reader.addEventListener("load", function () {
              console.log(this);
              var image2 = {
                imageFile: this.result,
                imageNo: "02"
              };
              ProductImages.push(image2);
              previewImageTwo.setAttribute("src", this.result);
              // $.ajax({
              //   type: "POST",
              //   url: baseURL + "api/Vendor/ImageUploadApi",
              //   //base_url() = http://localhost/tutorial/codeigniter
              //   // method: "POST",
              //   data: {
              //     imageFile: this.result,
              //     imageNo: "02",
              //   },
              //   // contentType: false,
              //   // cache: false,
              //   // processData: false,
              //   success: function (data) {
              //     $("#uploaded_image").html(data);
              //   },
              // });
            });

            reader.readAsDataURL(file);
          } else {
            previewImageTwo.style.display = null;
            previewImageTwo.setAttribute("src", "");
          }
        } else {
          // alert("Only jpg/jpeg and png files are allowed!");
          var flag = false;
        }

        if (flag == false) {
          imageIsImageTwo = false;
          console.log("image 2 is false");
          previewImageTwo.style.display = null;
          previewImageTwo.setAttribute("src", "");
          // this.imageOneError.html("Please select an image");
        } else {
          imageIsImageTwo = true;
          console.log("image 2 is true");
        }
      }
    });

    inpFile.addEventListener("change", function () {
      //const flag=true;
      console.log(typeof inpFile);
      console.log(inpFile);
      //console.log(file.name);

      if (inpFile.files[0] === undefined) {
        // alert("file undefined");
        console.log("inside else");
        previewImage.style.display = null;
        previewImage.setAttribute("src", "");
      } else {
        const file = this.files[0];
        // console.log(file);
        var fileName = file.name;
        console.log(fileName);
        console.log(typeof fileName);

        var idxDot = fileName.lastIndexOf(".") + 1;
        console.log(typeof idxDot);
        console.log(idxDot);

        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        console.log(typeof extFile);
        console.log(extFile);

        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
          console.log("in file if");
          //alert("good");

          //const fileName = this.file[0];

          // console.log(file);
          if (file) {
            console.log("inside if");
            const reader = new FileReader();
            previewImage.style.display = "block";
            reader.addEventListener("load", function () {
              console.log(this.result);
              var image1 = {
                imageFile: this.result,
                imageNo: "01"
              };
              ProductImages.push(image1);
              previewImage.setAttribute("src", this.result);
              // $.ajax({
              //   type: "POST",
              //   url: baseURL + "api/Vendor/ImageUploadApi",
              //   //base_url() = http://localhost/tutorial/codeigniter
              //   // method: "POST",
              //   data: {
              //     imageFile: this.result,
              //     imageNo: "01",
              //   },
              //   // contentType: false,
              //   // cache: false,
              //   // processData: false,
              //   success: function (data) {
              //     $("#uploaded_image").html(data);
              //   },
              // });
            });

            reader.readAsDataURL(file);
          } else {
            console.log("inside else");
            previewImage.style.display = null;
            previewImage.setAttribute("src", "");
          }
        } else {
          // alert("Only jpg/jpeg and png files are allowed!");
          var flag = false;
        }

        if (flag == false) {
          imageIsImage = false;
          console.log("image is false");
          previewImage.style.display = null;
          previewImage.setAttribute("src", "");
          // this.imageOneError.html("Please select an image");
        } else {
          imageIsImage = true;
          console.log("image is true");
        }
      }
    });
  };

  doPrev4Action = () => {
    if (!firstProductAdded) {
      console.log("Product details prev btn clicked");
      this.Products_Services_page4.addClass("d-none");
      this.GSTDetails_page.removeClass("d-none");
      // this.formstep1.addClass("current-item");
      // this.formstep2.addClass("current-item");
      this.formstep3.addClass("current-item");
      this.formstep4.removeClass("current-item");
    } else {
      this.Products_Services_page4.addClass("d-none");
      this.productServicesListingPage.removeClass("d-none");
    }
  };

  getCategoryOptions = () => {
    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/GetVendorCategories",
      async: false,
      headers: { Authorization: X_API_KEY },

      success: this.showCategoryOptions
    });
    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/GetVendorSpecialities",
      async: false,
      headers: { Authorization: X_API_KEY },

      success: this.showSpecialityOptions
    });

    //use query parameter to pass to php controller
  };

  showCategoryOptions = (res) => {
    allVendorCategories = res;

    this.inputCategory.empty();
    console.log(res);
    $("#inputCategory").append(
      `<option class="categoryOptions" id="NoCategorySelected"></option>`
    );
    res.forEach((element) => {
      console.log(element.Picklist_display_value);
      console.log(element.Picklist_display_value);
      // var item = `<option value="${element.Picklist_display_value}">${element.Picklist_display_value}</option>`;
      //line of code to check if its a food item to display veg nonveg checkbox.
      var item = ` <option class="categoryOptions" value='{"picklistId":"${element.Picklist_ID}","ExtraValue":"${element.extra_value}"}'>${element.Picklist_display_value}</option>`;

      $("#inputCategory").append(item);
    });
  };

  showSpecialityOptions = (res) => {
    var SpecialityCount = 50;
    console.log(res);
    // console.log(res);
    allVendorSpecialities = res;
    $("#ProductSpecialityBox").empty();
    res.forEach((element) => {
      console.log("Adding the spec");
      console.log(element);
      var customSpecID = element.Picklist_ID + "s3";

      // console.log(element.Picklist_display_value);
      // var item = `<option value="${element.Picklist_display_value}">${element.Picklist_display_value}</option>`;
      var item = `<div class="col-4 col-md-2 d-flex justify-content-center my-2 "><div class="custom-control custom-checkbox image-checkbox">
        <input type="checkbox" name="speciality-check" class="specialityCheckBox1 custom-control-input specialityCheckBox justify-content-center onnew" id="specialityck${SpecialityCount}" value="${element.Picklist_ID}">
        <label class="custom-control-label d-flex justify-content-center" for="specialityck${SpecialityCount}">
            <img src="" id=${customSpecID} class="img-fluid rounded-circle specialityImage" onerror="this.src='assets/src/images/pancake.jpeg'" alt="Image" loading="lazy">
        </label>
        <label class="d-flex justify-content-center">
            <span class="fs-12 text-lowercase text-center">${element.Picklist_display_value}</span>
        </label>
        </div></div>`;
      SpecialityCount++;

      //$("#ProductSpecialityBox").append(item);
      // categoryCount++;
      this.specialityCheckBox1 = $(".specialityCheckBox1");
    });
    //Call to Function to load the S3 Category images in the respective image tags
    this.loadSpecialityImages(res);
  };

  vegNonVegShow = () => {
    console.log("In veg non veg");

    var extraValue = this.inputCategory.val();
    const extraValueArray = JSON.parse(extraValue);
    console.log(extraValueArray);
    console.log(extraValueArray.ExtraValue);
    this.checkNonVeg.prop("checked", false);

    if (extraValueArray.ExtraValue == "FoodSeller") {
      this.NonVegDiv.removeClass("d-none");
    } else {
      this.NonVegDiv.addClass("d-none");
    }
    this.showSpecialityByCategory();
  };
  doPrev4Action = () => {
    console.log("Product details prev btn clicked");
    this.Products_Services_page4.addClass("d-none");
    this.GSTDetails_page.removeClass("d-none");
    // this.formstep1.addClass("current-item");
    // this.formstep2.addClass("current-item");
    this.formstep3.addClass("current-item");
    this.formstep4.removeClass("current-item");
  };

  doForm4Action = (e) => {
    e.preventDefault();

    const fileInput2_File = document.getElementById("inpFileTwo").files[0];
    const fileInput3_File = document.getElementById("inpFileThree").files[0];

    if (fileInput2_File === undefined) {
      this.imageTwoError.html("");
    }
    if (fileInput3_File === undefined) {
      this.imageThreeError.html("");
    }

    console.log("in doFormAction Basic Details");

    this.getAllVendorProductsNames();

    let isProductServiceNameValidated = this.ProductServiceName_validate();
    if (isProductServiceNameValidated) {
      console.log("ProductServiceName VALID");
      this.inputProductServiceNameError.html("");
    }

    let isDiscriptionValidated = this.Discription_validate();
    if (isDiscriptionValidated) {
      console.log("Discription VALID");
      this.discriptionError.html("");
    }

    let isCategoryValidated = this.Category_validate();
    if (isCategoryValidated) {
      console.log("Category VALID");
      this.categoryError1.html("");
    }

    let isPriceValidated = this.Price_validate();
    if (isPriceValidated) {
      console.log("Price VALID");
      this.priceError.html("");
    }

    let isQuantityValidated = this.Quantity_validate();
    if (isQuantityValidated) {
      console.log("Quantity VALID");
      this.quantityError.html("");
    }

    let isGSTPercentValidated = this.GSTPercent_validate();
    if (isGSTPercentValidated) {
      console.log("GSTPercent VALID");
      this.GSTPercentError.html("");
    }

    let imageSelected = this.imageSelected_validate();
    if (imageSelected) {
      console.log("image select VALID");
      this.imageOneError.html("");
    }

    if (imageIsImage == false) {
      this.imageOneError.html("Please select an image file");
    }

    if (imageIsImageTwo == false) {
      this.imageTwoError.html("Please select an image file");
    }

    if (imageIsImageThree == false) {
      this.imageThreeError.html("Please select an image file");
    }

    let specialitySelected = this.specialitySelected_validate();
    if (specialitySelected) {
      console.log("speciality select VALID");
      this.specicalityError.html("");
    }

    //1////////////////////////////////////////////////////////
    //   FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS REQUIRED  //
    //////////////////////////////////////////////////////////
    // imageIsImageTwo = true; imageIsImageThree = true;
    //////////////////////////////////////////////////////////

    //2//////////////////////////////////////////////////////////////////////////
    //   FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS MANDATORY. CAN ENTER ALL 3  //
    ////////////////////////////////////////////////////////////////////////////
    // const fileInput2_File = document.getElementById("inpFileTwo").files[0];
    // const fileInput3_File = document.getElementById("inpFileThree").files[0];
    if (fileInput2_File === undefined) {
      imageIsImageTwo = true;
    }
    if (fileInput3_File === undefined) {
      imageIsImageThree = true;
    }
    ////////////////////////////////////////////////////////////////////////////

    if (
      isProductServiceNameValidated &&
      isDiscriptionValidated &&
      isCategoryValidated &&
      isPriceValidated &&
      isQuantityValidated &&
      isGSTPercentValidated &&
      imageSelected &&
      specialitySelected &&
      imageIsImage &&
      imageIsImageTwo &&
      imageIsImageThree
    ) {
      this.Submit_Form();
      console.log("ALL FIELDS VALID...VALIDATION OVER");
    }
  };

  specialitySelected_validate = () => {
    var checkboxs = document.getElementsByName("speciality-check");
    var flag = false;
    for (var i = 0, l = checkboxs.length; i < l; i++) {
      if (checkboxs[i].checked) {
        flag = true;
        break;
      }
    }
    if (flag == false) {
      $("#collapseOne-3").addClass("show");

      this.specicalityError.html("Please select a speciality");
    } else {
      this.specicalityError.html("");
    }
    return flag;
  };

  // imageSelected_validate = () => {
  //   const inpFile = document.getElementById("inpFile");
  //   const inpFileTwo = document.getElementById("inpFileTwo");
  //   const inpFileThree = document.getElementById("inpFileThree");

  //   let flag = true;
  //   console.log(inpFile.files[0]);
  //   //let inputFileOneValue = inpFile.files[0];

  //   console.log(inpFile.files[0] === undefined);
  //   if (inpFile.files[0] === undefined) {
  //     this.imageOneError.html("Please select an image");
  //     flag = false;
  //   } else {
  //     this.imageOneError.html("");
  //   }

  //   if (inpFileTwo.files[0] === undefined) {
  //     this.imageTwoError.html("Please select an image");
  //     flag = false;
  //   } else {
  //     this.imageTwoError.html("");
  //   }

  //   if (inpFileThree.files[0] === undefined) {
  //     this.imageThreeError.html("Please select an image");
  //     flag = false;
  //   } else {
  //     this.imageThreeError.html("");
  //   }

  //   return flag;
  // };

  //upload image validation start

  imageSelected_validate = () => {
    const inpFile = document.getElementById("inpFile");
    const inpFileTwo = document.getElementById("inpFileTwo");
    const inpFileThree = document.getElementById("inpFileThree");

    let flag = true;
    console.log(inpFile.files[0]);
    //let inputFileOneValue = inpFile.files[0];

    console.log(inpFile.files[0] === undefined);
    if (inpFile.files[0] === undefined) {
      $("#collapseOne-3").addClass("show");

      this.imageOneError.html("Please select an image");
      flag = false;
    } else {
      this.imageOneError.html("");
    }
    //////////////////////////////////////////////////////////
    //   FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS REQUIRED  //
    //////////////////////////////////////////////////////////
    // if (inpFileTwo.files[0] === undefined) {
    //   $("#collapseOne-3").addClass("show");

    //   this.imageTwoError.html("Please select an image");
    //   flag = false;
    // } else {
    //   this.imageTwoError.html("");
    // }

    // if (inpFileThree.files[0] === undefined) {
    //   $("#collapseOne-3").addClass("show");

    //   this.imageThreeError.html("Please select an image");
    //   flag = false;
    // } else {
    //   this.imageThreeError.html("");
    // }
    //////////////////////////////////////////////////////////

    return flag;
  };

  //upload image validation end

  GSTPercent_validate = () => {
    if (this.inputGSTRegistered.is(":checked")) {
      let flag = true;
      if (this.inputGSTPercent.val() < 1) {
        $("#collapseOne-3").addClass("show");

        this.GSTPercentError.html("cannot be empty");

        flag = false;
      }

      return flag;
    } else {
      // this.inputGSTPercent.value = null;
      return true;
    }
  };

  //getting all the Vendor product names to validate unique product names
  getAllVendorProductsNames = () => {
    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/GetVendorProductsNames",
      async: false,
      headers: { Authorization: X_API_KEY },

      success: this.useVendorProducts
    });
  };
  useVendorProducts = (res) => {
    console.log("inside useVendorProducts");
    vendorProductNames = [];
    if (!res.code) {
      console.log(res);
      res.forEach((element) => {
        console.log(element.Product_Service_Name);
        vendorProductNames.push(element.Product_Service_Name);
      });
      console.log(vendorProductNames);
    }
  };
  //validate function
  ProductServiceName_validate = () => {
    let flag = true;

    if (
      this.inputProductServiceName.val().length < 2 ||
      this.inputProductServiceName.val().length > 50
    ) {
      $("#collapseOne-3").addClass("show");

      this.inputProductServiceNameError.html(
        "name should be min 2 and max 50 characters long"
      );

      flag = false;
    }

    return flag;
  };

  Discription_validate = () => {
    console.log("in Business Name validate");
    let flag = true;
    var element = document.querySelector("trix-editor");
    var trixdocument = element.editor.getDocument();
    var trixdocumentstr = trixdocument.toString(); // is a JavaScript string

    if (trixdocumentstr.length > 5000 || trixdocumentstr.length < 2) {
      $("#collapseOne-3").addClass("show");

      this.discriptionError.html("description must be max 5000 characters");

      flag = false;
    }

    return flag;
  };

  Category_validate = () => {
    let flag = true;

    if (!this.inputCategory.val()) {
      console.log("Category not selected");
      this.categoryError1.html("Select a Category");

      flag = false;
    }

    return flag;
  };

  Price_validate = () => {
    let flag = true;
    if (this.inputPrice.val() > 1000000 || this.inputPrice.val() < 1) {
      $("#collapseOne-3").addClass("show");

      this.priceError.html("max 10,00,000");

      flag = false;
    }

    return flag;
  };

  Quantity_validate = () => {
    let flag = true;
    if (this.inputQuantity.val() < 1) {
      $("#collapseOne-3").addClass("show");

      this.quantityError.html("min 1");

      flag = false;
    }

    return flag;
  };

  Submit_Form = () => {
    console.log("Submitting the form");
    console.log(this.Products_Services_Form4.serialize());
    var recoFlag;
    if (this.checkreco.is(":checked")) {
      recoFlag = 1;
    } else {
      recoFlag = 0;
    }
    var NonVegFlag;
    if (this.checkNonVeg.is(":checked")) {
      NonVegFlag = 1;
    } else {
      NonVegFlag = 0;
    }

    //creating array of cropped images using class name "prodImgPreview"....
    var firstProductImages = [];

    var fCounter = 0;
    var ProdimagePosition = [];

    //////////////////////////////////////////////////////////
    //   FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS REQUIRED  //
    //////////////////////////////////////////////////////////
    // for (var i = 0; i < 3; i++)
    //2//////////////////////////////////////////////////////////////////////////
    //   FOR ONBOARDING ONLY ONE PRODUCT IMAGE IS MANDATORY. CAN ENTER ALL 3  //
    ////////////////////////////////////////////////////////////////////////////
    const fileInput1_File = document.getElementById("inpFile").files[0];
    const fileInput2_File = document.getElementById("inpFileTwo").files[0];
    const fileInput3_File = document.getElementById("inpFileThree").files[0];
    if (fileInput1_File !== undefined) {
      fCounter++;
      ProdimagePosition.push(0);
    }
    if (fileInput2_File !== undefined) {
      fCounter++;
      ProdimagePosition.push(1);
    }
    if (fileInput3_File !== undefined) {
      fCounter++;
      ProdimagePosition.push(2);
    }
    ////////////////////////////////////////////////////////////////////////////

    console.log("fCounter", fCounter);
    for (var i = 0; i < fCounter; i++) {
      var j = ProdimagePosition[i];
      var image = {
        imageFile: $(".prodImgPreview")[j]["currentSrc"],
        imageNo: i + 1
      };
      firstProductImages.push(image);

      var prodImgFile = $(".prodImgPreview")[j]["currentSrc"];
      var prodImgFileNo = i + 1;
      var prodImgFileName = this.inputProductServiceName.val();

      ImageURL = this.UploadProductImages(
        prodImgFile,
        prodImgFileNo,
        prodImgFileName
      );
    }
    console.log(firstProductImages);
    console.log(ProductImages);

    // $.ajax({
    //   type: "POST",
    //   url: baseURL + "api/Vendor/ImageUploadApi",
    //   async: false,

    //   data: {
    //     ProductImages: firstProductImages,
    //     productServiceName: this.inputProductServiceName.val(),
    //   },
    //   success: function (res) {
    //     console.log(res);
    //     ImageURL = res;
    //   },
    // });

    const selectedPicklistId = JSON.parse(this.inputCategory.val());
    console.log(selectedPicklistId);
    console.log(selectedPicklistId.picklistId);
    var product_service_data = [];
    //to create array of Product Specialities
    console.log(this.specialityCheckBox1.length);
    console.log($(".specialityCheckBox1:checked"));
    for (let i = 0; i < $(".specialityCheckBox1:checked").length; i++) {
      console.log($(".specialityCheckBox1:checked")[i].defaultValue);
      SpecialitiesArray[i] = $(".specialityCheckBox1:checked")[i].defaultValue;
    }

    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/getVendorDetails",
      headers: { Authorization: X_API_KEY },
      async: false,
      success: (result) => {
        productKeyBaseURLDetailpg = `Vendor/${result.Image_URL}/Product Images/`;
      }
    });

    var product_detail = {
      Product_Service_Name: this.inputProductServiceName.val(),
      Picklist_ID: selectedPicklistId.picklistId,
      Description: $("trix-editor").val(),
      GST: this.inputGSTPercent.val(),
      Quantity: this.inputQuantity.val(),
      Price: this.inputPrice.val(),
      Specialities: SpecialitiesArray,
      Image_URL:
        productKeyBaseURLDetailpg +
        this.inputProductServiceName.val() +
        "/01.png",
      // Image_URL: ImageURL,

      Recommended_flag: recoFlag,

      Veg_NonVeg_flag: NonVegFlag,
      ImagesUpdated: true,
      Product_Availability_Flag: 1
    };
    product_service_data.push(product_detail);
    console.log("add procuts ajax");
    $.ajax({
      type: "POST",

      url: baseURL + "api/Vendor/AddUpdateProducts",
      async: false,

      headers: { Authorization: X_API_KEY },

      // data: this.Products_Services_Form4.serialize(),
      data: {
        product_service_data: product_service_data
      },

      success: console.log("Successfully")
    });

    let base64data = this.inpFile.val();
    console.log(base64data);

    // $.ajax({
    //   type: "POST",
    //   url: baseURL + "Vendor_details/upload",
    //   async: false,

    //   headers: { Authorization: X_API_KEY },

    //   data: {
    //     image: base64data,
    //   },
    //   statusCode: {
    //     400: function () {
    //       alert("400 status code! user error");
    //     },
    //     500: function () {
    //       alert("500 status code! server error");
    //     },
    //   },
    // });
    // var fd = new FormData();
    // fd.append("file", input.files[0]);
    // var NewproductServiceName = this.inputProductServiceName.val();

    console.log("Printing Immage array");
    console.log(ProductImages);
    this.Products_Services_page4.addClass("d-none");
    this.productServicesListingPage.removeClass("d-none");
    firstProductAdded = true;
  };

  ImageUploadsuccessFunction = (res) => {
    $("#uploaded_image").html(res);
    ImageURL = res;
    console.log("image url:");
    console.log(res);
  };

  showSpecialityByCategory = (e) => {
    const categoryOptions = document.getElementsByClassName("categoryOptions");
    console.log(categoryOptions);
    for (var i = 0; i < categoryOptions.length; i++) {
      console.log(categoryOptions[i]);
      console.log(categoryOptions[i].selected);
      if (categoryOptions[i].selected == true) {
        var picklistId = $("#inputCategory").val();
        const picklistIdArray = JSON.parse(picklistId);
        console.log(picklistIdArray);
        console.log(picklistIdArray.picklistId);

        $.ajax({
          type: "GET",
          url: baseURL + "api/Vendor/getSpecialitiesForCategory",
          async: false,
          data: { Picklist_ID: picklistIdArray.picklistId },
          headers: { Authorization: X_API_KEY },

          success: this.showSpecialities
        });
      }
    }
  };

  showSpecialities = (res) => {
    console.log("in showSpecialities");
    var SpecialityCount = 50;
    var newProductSpecility = ``;
    var checked = ``;

    res.forEach((element) => {
      console.log(element);
      var customSpecID = element.Picklist_ID + "s3";
      // newProductSpecility = `${newProductSpecility}<div><label class="mx-1 "><input type="checkbox" name="speciality-check" value="${element.Picklist_ID}" class="NewspecialityCheckBox"><span>${element.Picklist_display_value}</span></label></div>`;
      checked = element.Selected ? "checked" : "";
      newProductSpecility = `${newProductSpecility}<div class="col-4 col-md-2 d-flex justify-content-center my-2 "><div class="custom-control custom-checkbox image-checkbox">
        <input type="checkbox" class="custom-control-input specialityCheckBox1 NewspecialityCheckBox EditspecialityCheckBox justify-content-center" id="specialityck${SpecialityCount}" name="speciality-check" value="${element.Picklist_ID}" ${checked}>
        <label class="detailsjs custom-control-label d-flex justify-content-center" for="specialityck${SpecialityCount}">
            <img src="" id=${customSpecID} class="img-fluid rounded-circle specialityImage" onerror="this.src='assets/src/images/pancake.jpeg'" alt="Image" loading="lazy">
        </label>
        <label class="d-flex justify-content-center">
            <span class="fs-12 text-lowercase text-center">${element.Picklist_display_value}</span>
        </label>
        </div></div>`;
      SpecialityCount++;
    });
    $("#ProductSpecialityBox").empty();
    $("#ProductSpecialityBox").append(newProductSpecility);
    //Call to Function to load the S3 Category images in the respective image tags
    this.loadSpecialityImages(res);
  };

  loadSpecialityImages = (res) => {
    //For each Speciality in Category ABC
    res.forEach(async (elementS) => {
      var customSpecID = elementS.Picklist_ID + "s3";
      console.log("customSpecID", customSpecID);
      var specialityS3Image = await s3Object.getObjectURL(elementS.Icon_Url);
      if (document.getElementById(customSpecID) != null) {
        document.getElementById(customSpecID).src = specialityS3Image;
      }
    });
  };

  UploadProductImages = (prodImgFile, prodImgFileNo, prodImgFileName) => {
    console.log("Vendor Product Images UPLOAD");

    var key = "";
    var keyOne = "";
    //Get Vendor Details
    $.ajax({
      type: "GET",
      url: baseURL + "api/Vendor/getVendorDetails",
      headers: { Authorization: X_API_KEY },
      async: false,
      success: (result) => {
        key = `Vendor/${result.Image_URL}/Product Images/${prodImgFileName}/0${prodImgFileNo}.png`; // Specify the desired key or path
        keyOne = `Vendor/${result.Image_URL}/Product Images/${prodImgFileName}/01.png`;
      }
    });

    var Currentkey = key;
    s3Object.uploadCropperImageToS3(prodImgFile, Currentkey);

    return keyOne;
  };
}
