import cartUtil from "../helpers/cartUtil";
import Util from "../helpers/util";
import GoogleMapsApi from "../helpers/GoogleMapsApi";

var CartObject = new cartUtil();
var UtilObject = new Util();

//Loading Google maps
var GoogleMapsApiObject = new GoogleMapsApi();

var map, marker, customerAddressIdTop, oldLat, oldLong;

import s3Util from "../helpers/s3Util";
var s3Object = new s3Util();
export default class header {
  constructor() {
    this.inputAddressName = $(".inputAddressNameNavTop");
    this.inputAddress = $(".inputAddressNavTop");
    this.inputAddressshort = $(".inputAddressshortNavTop");
    this.inputLandmark = $(".inputLandmarkNavTop");
    this.inputCity = $(".inputCityNavTop");
    this.inputState = $(".inputStateNavTop");
    this.inputPincode = $(".inputPincodeNavTop");

    this.inputAddressNameError = $(".inputAddressNameError");
    this.inputAddressError = $(".inputAddressError");
    this.inputAddressshortError = $(".inputAddressshortError");
    this.inputLandmarkError = $(".inputLandmarkError");
    this.inputCityError = $(".inputCityError");
    this.inputStateError = $(".inputStateError");
    this.inputpincodeError = $(".inputpincodeError");

    //Edit adress validation
    this.inputAddressNameEdit = $(".inputAddressNameChangeAccountEditTop");
    this.inputAddressEdit = $(".inputAddressChangeAccountEditTop");
    this.inputAddressshortEdit = $(".inputAddressshortChangeAccountEditTop");
    this.inputCityEdit = $(".inputCityChangeAccountEditTop");
    this.inputStateEdit = $(".inputStateChangeAccountEditTop");
    this.inputPincodeEdit = $(".inputPincodeChangeAccountEditTop");

    this.inputAddressNameErrorEdit = $(".inputAddressNameErrorAccountEditTop");
    this.inputAddressErrorEdit = $(".inputAddressErrorAccountEditTop");
    this.inputAddressshortErrorEdit = $(
      ".inputAddressshortErrorAccountEditTop"
    );
    this.inputCityErrorEdit = $(".inputCityErrorAccountEditTop");
    this.inputStateErrorEdit = $(".inputStateErrorAccountEditTop");
    this.inputpincodeErrorEdit = $(".inputpincodeErrorAccountEditTop");

    //Calculating the Number of products in the cart
    this.noOfProductsInCart = CartObject.cartCheck();
    //console.log(this.noOfProductsInCart.Products.length);
    //$("span.cartCount").html(`${this.noOfProductsInCart.Products.length}`);

    var allCookies = UtilObject.getAllCookies();
    console.log(allCookies);
    // //when typing text disappear
    // this.onTypeText = $(".onTypeText");
    // Registering Events
    console.log("IN MAIN DEV BRANCH");
    this.bindEvents();
  }

  bindEvents = () => {
    console.log("in bind");

    //address
    $(".accountAdressEditButtonTop").on(
      "click",
      this.editAdressValidationCheckTop
    );

    $(".categoriesModal").on("click", this.getAllCategories);

    $(".submitBtnNavTop").on("click", this.doFormAction);

    $(".deleteAddressAccountTop").on(
      "click",
      this.deleteAddressDeleteAccountTop
    );
    // //call recent search function
    // $(".searchBarBtn").on("click", this.SearchOptions);

    // //for desktop
    // $(".textareaDesktop").on("input", () => {
    //   if (!$(".textareaDesktop").val()) {
    //     $(".recentSearchesDesktop").show();
    //   } else {
    //     $(".recentSearchesDesktop").hide();
    //   }
    // });
    // //for mobile
    // $(".textareaMobile").on("input", () => {
    //   if (!$(".textareaMobile").val()) {
    //     $(".recentSearchesMobile").show();
    //   } else {
    //     $(".recentSearchesMobile").hide();
    //   }
    // });

    //$(window).on("load", $.ready.then(this.initModal()));
    //Toggles the map for that particular div
    $(".toggleMap").on("click", this.toggleGoogleMaps);
    $(".addAdressBtnNavTop").on("click", this.showAddressBtn);
    $(window).on("load", $.ready.then(this.customerHeaderDetails));
    console.log(window.location.href);
    $(window).on("load", $.ready.then(this.refreshCartCount));
  };

  refreshCartCount = () => {
    this.noOfProductsInCart = CartObject.cartCheck();
    console.log(this.noOfProductsInCart.Products.length);
    $("span.cartCount").html(`${this.noOfProductsInCart.Products.length}`);
  };

  toggleGoogleMaps = async (event) => {
    var classList = event.target.className.split(" ");
    var idOfGoogleMapsDiv = classList[classList.length - 1];

    console.log(idOfGoogleMapsDiv);

    addressDetails = await GoogleMapsApiObject.initModal(idOfGoogleMapsDiv);
  };

  doFormAction = (e) => {
    e.preventDefault();

    console.log("Basic Details");

    let isAddressNameValidated = this.AddressName_validate();
    if (isAddressNameValidated) {
      this.inputAddressNameError.html("");
    }

    let isAddressValidated = this.Address_validate();
    if (isAddressValidated) {
      console.log("address VALID");
      this.inputAddressError.html("");
    }

    let isAddressshortValidated = this.Addressshort_validate();
    if (isAddressshortValidated) {
      console.log("address short VALID");
      this.inputAddressshortError.html("");
    }

    // let isLandmarkValidated = this.Landmark_validate();
    // if (isLandmarkValidated) {
    //   console.log("Landmark VALID");
    //   this.inputLandmarkError.html("");
    // }

    let isCityValidated = this.City_validate();
    if (isCityValidated) {
      console.log("city VALID");
      this.inputCityError.html("");
    }

    let isStateValidated = this.State_validate();
    if (isStateValidated) {
      console.log("state VALID");
      this.inputStateError.html("");
    }

    let isPincodeValidated = this.Pincode_validate();
    if (isPincodeValidated) {
      console.log("pincode VALID");
      this.inputpincodeError.html("");
    }

    if (
      isAddressNameValidated &&
      isAddressValidated &&
      isAddressshortValidated &&
      isCityValidated &&
      isStateValidated &&
      isPincodeValidated
    ) {
      this.submitAddress_Form();
      console.log("ALL FIELDS VALID...VALIDATION OVER");
    }
  };

  //validate function
  AddressName_validate = () => {
    console.log("in address validate");
    let flag = true;

    if (this.inputAddressName.val().length < 4) {
      this.inputAddressNameError.html(
        "Address Name should be minimum 4 characters long!"
      );

      flag = false;
    }

    return flag;
  };

  Address_validate = () => {
    console.log("in address validate");
    let flag = true;

    if (this.inputAddress.val().length < 5) {
      this.inputAddressError.html(
        "Address should be minimum 5 characters long!"
      );

      flag = false;
    }

    return flag;
  };

  Addressshort_validate = () => {
    console.log("in address short validate");
    let flag = true;

    console.log(this.inputAddressshort.val().length);

    if (this.inputAddressshort.val().length < 5) {
      this.inputAddressshortError.html(
        "Address should be minimum 5 characters long!"
      );

      flag = false;
    }

    return flag;
  };

  // Landmark_validate = () => {
  //   console.log("in landmark validate");
  //   let flag = true;

  //   if (this.inputLandmark.val().length < 5) {
  //     this.inputLandmarkError.html(
  //       "landmark should be minimum 5 characters long!"
  //     );

  //     flag = false;
  //   }

  //   return flag;
  // };

  City_validate = () => {
    console.log("in city validate");
    let flag = true;

    var cityExample = /^[A-Za-z0-9 ]+$/;

    if (
      !this.inputCity.val().match(cityExample) ||
      this.inputCity.val().length < 3
    ) {
      this.inputCityError.html(
        "City should be minimum 3 characters long and should not contain special characters!"
      );

      flag = false;
    }

    return flag;
  };

  State_validate = () => {
    console.log("in state validate");
    let flag = true;

    var stateExample = /^[A-Za-z0-9 ]+$/;

    if (
      !this.inputState.val().match(stateExample) ||
      this.inputState.val().length < 3
    ) {
      this.inputStateError.html(
        "State should be minimum 3 characters long and should not contain special characters!"
      );

      flag = false;
    }

    return flag;
  };

  Pincode_validate = () => {
    console.log("in pincode validate");
    let flag = true;

    var pincodeExample = /^[0-9]*$/;

    if (
      !this.inputPincode.val().match(pincodeExample) ||
      this.inputPincode.val().length < 6
    ) {
      this.inputpincodeError.html(
        "Pincode should be minimum 6 characters long and should contain only numbers!"
      );

      flag = false;
    }

    return flag;
  };

  submitAddress_Form = () => {
    console.log("Inside submit flow");
    var customer = UtilObject.getAllCookies();
    $.ajax({
      url: baseURL + "api/customer/Account/AddAddress",
      type: "POST",
      async: false,
      data: {
        Customer_ID: customer.CUSTOMER_ID,
        Address_Line_1: this.inputAddress.val(),
        Landmark: this.inputAddressshort.val(),
        City: this.inputCity.val(),
        State: this.inputState.val(),
        Pincode: this.inputPincode.val(),
        Address_Name: this.inputAddressName.val(),
        lat: $(".inputLat").val(),
        lon: $(".inputLon").val()
      },
      headers: { Authorization: X_API_KEY },
      success: (res) => {
        window.$("#locationCallNavTop").modal("hide");
      },
      error: (err) => {
        console.log(err);
      }
    });
  };

  //getting all categories by clicking on hamburger menu
  getAllCategories = () => {
    $.ajax({
      url: baseURL + "api/customer/Homepage/getCategoryType",
      type: "GET",
      async: false,
      headers: { Authorization: X_API_KEY },
      success: (result) => {
        console.log(result);
        $(".categories-items").children().remove();
        result.forEach(async (element) => {
          // if (element.Picklist_display_value.length > 16) {
          //   var name = element.Picklist_display_value.substring(0, 16) + "....";
          // } else {
          //   var name = element.Picklist_display_value;
          // }
          var categoryImg = await s3Object.getObjectURL(element.Icon_Url); //${S3BaseURLProd}/${element.Icon_Url}

          var item = `
          <div class="col-4" >
            <div class="item mt-2" >
            <div class="row d-flex justify-content-between">
              <div class="col-sm-12 wow fadeInUp delay-2 justShow">
                <div class="rounded-4 text-center zoom">
                <a class="btn-light" href="${baseURL}customer/vendorListingPage/${element.Picklist_display_value}" rel="noopener "  style="margin-top:0.5rem;">
                    <img src="${categoryImg}" class="rounded-4 shadow-lg" style="width:6rem;" alt="${element.Picklist_display_value}-icon">
                    <p class="mt-2 truncate text-capitalize"><strong>${element.Picklist_display_value}</strong></p>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>`;

          $(".categories-items").append(item);
        });
      },
      error: (result) => {
        console.log(result);
      }
    });
  };

  customerHeaderDetails = async () => {
    var customerID = await UtilObject.getCookie("CUSTOMER_ID");
    console.log("Headerpart customer session check", customerID);
    var localStorageCustomerID = await UtilObject.checkLocalStorage(
      "CUSTOMER_ID"
    );

    if (customerID == null && localStorageCustomerID != null) {
      UtilObject.createCookie("CUSTOMER_ID", localStorageCustomerID, 30);
      this.getCustomerDetails(localStorageCustomerID);
    } else if (customerID != null) {
      this.getCustomerDetails(customerID);
      localStorage.setItem("CUSTOMER_ID", customerID);
    }
  };

  getCustomerDetails = (customerID) => {
    //Get Customer Details
    $.ajax({
      url: baseURL + "api/customer/Account/GetUserDetails",
      type: "POST",
      async: false,
      data: {
        Customer_ID: customerID
      },
      headers: { Authorization: X_API_KEY },
      success: async (result) => {
        console.log(result);
        var desktopName = result.Customer_Name;
        if (desktopName.length > 10) {
          desktopName = desktopName.substring(0, 8) + "..";
        }
        //For Desktop Name Change
        $(".accountNameDesktop").html(
          `<a href="${baseURL}customer/account">${desktopName}</a>`
        );

        //For mobile Change
        $(".accountNameMobile").html(
          `<p class="truncate-account-name">${result.Customer_Name}</p>`
        );

        $(".mobileAccountLink").attr("href", `${baseURL}customer/account`);

        // $(".customerProfileImage").html(
        //   `<img src="https://img.icons8.com/color/44/null/circled-user-male-skin-type-7--v1.png" class="mb-2"/>`
        // );

        if (result.Image_URL == null || result.Image_URL == "") {
          $(".customerProfileImage").html(
            `<img src="https://img.icons8.com/color/32/null/circled-user-male-skin-type-7--v1.png"/>`
          );
        } else {
          var customerHomeHeaderImg = await s3Object.getObjectURL(
            result.Image_URL
          );
          console.log(customerHomeHeaderImg);
          $(".customerProfileImage").html(
            `<img src="${customerHomeHeaderImg}" class="mt-n1"/>`
          );
        }

        //For Mobile Name Change
        // $(".accountNameMobile").html(
        //   `<a href="${baseURL}customer/account">${result.Customer_Name}</a>`
        // );
        // $(".customerProfileImageMobile").html(
        //   `<img src="https://img.icons8.com/color/32/000000/circled-user-male-skin-type-5--v1.png"/>`
        // );
      },
      error: (result) => {
        //User does not exist so remove Customer ID from session
        $.ajax({
          url: baseURL + "api/customer/CustomerCookies/unsetSessionValues",
          type: "POST",
          async: false,
          data: {
            unset: "CUSTOMER_ID"
          },
          success: (result) => {
            console.log(result);
          }
        });
        console.log(result);
      }
    });
  };

  // currentAddressShow = () => {
  //   var allCookies = UtilObject.getAllCookies();
  //   var customerID = allCookies.CUSTOMER_ID;
  //   //Get Addresses
  //   $.ajax({
  //     url: baseURL + "api/customer/Account/getAddress",
  //     type: "POST",
  //     async: false,
  //     data: {
  //       Customer_ID: customerID,
  //     },
  //     headers: { Authorization: X_API_KEY },
  //     success: (result) => {
  //       $(".saved-address-container").empty();
  //       console.log("inside success");
  //       console.log(result);
  //       $(".saved-address-container").prepend(`<div class="row saved-addresses">
  //                         <div class="col text-left"><b>SAVED ADDRESS</b><hr class="dropdown-divider"></div>
  //                       </div>`);
  //       result.forEach((element) => {
  //         var item = `<div class="row navTopAddress">
  //           <input type="hidden" name="customerAddressIdTop" value="${element.Customer_Address_ID}" />
  //           <input type="hidden" name="customerAddressLatTop" value="${element.lat}" />
  //           <input type="hidden" name="customerAddressLngTop" value="${element.lon}" />
  //           <div class="col text-left"><b>${element.Address_Name}</b></div>
  //           <p style="text-align: left;">${element.Address_Line_1}, ${element.Landmark}, ${element.City}, ${element.State}</p>
  //           <div class="col  d-flex justify-content-end"> <button class="btn btn-sm customerAddressDeleteTop" type="button" style="color: #f49431;">Delete
  //           </button>
  //           </div>
  //           <hr class="dropdown-divider">
  //         </div>`;
  //         $(".saved-addresses").append(item);
  //       });
  //       $(".navTopAddress .customerAddressDeleteTop").on(
  //         "click",
  //         this.confirmDeleteAddress
  //       );
  //     },
  //     error: (res) => {
  //       console.log("inside error");
  //       console.log(res);
  //     },
  //   });
  // };

  //confirm call to delete address
  confirmDeleteAddress = (event) => {
    console.log("delete account");
    window.$("#exampleModalToggle").modal("hide");
    console.log(event);
    customerAddressIdTop = $(event.currentTarget)
      .find("input[name='customerAddressIdTop']")
      .val();
    console.log(customerAddressIdTop);
    window.$(".deleteAddressConfirmationAccountTop").modal("show");
  };

  //delete customer address
  deleteAddressDeleteAccountTop = (event) => {
    // //confirm delete modal
    // console.log("delete account");
    // window.$("#exampleModalToggle").modal("hide");
    // console.log(event);
    // customerAddressIdTop = $(event.currentTarget)
    //   .find("input[name='customerAddressIdTop']")
    //   .val();
    // //window.$(".deleteAddressConfirmationAccountTop").modal("show");

    //Deleting Address
    window.$(".deleteAddressConfirmationAccountTop").modal("hide");
    console.log(customerAddressIdTop);

    $.ajax({
      url: baseURL + "api/customer/Account/DeleteAddress",
      type: "POST",
      async: false,
      data: {
        Customer_Address_ID: customerAddressIdTop
      },
      headers: { Authorization: X_API_KEY },
      success: (result) => {
        $(".saved-address-container").empty();
      },
      error: (result) => {
        console.log(result);
      }
    });
  };

  showAddressBtn = () => {
    var allCookies = UtilObject.getAllCookies();
    var customerID = allCookies.CUSTOMER_ID;

    //Get Customers details
    if (customerID != null) {
      window.$("#locationCallNavTop").modal("show");
      window.$("#exampleModalToggle").modal("hide");
    } else {
      window.$("#customerNotLoggedInNavTop").modal("show");
      window.$("#exampleModalToggle").modal("hide");
    }
  };

  // SearchOptions = () => {
  //   var input = $(".searchBar").val();

  //   console.log(input);

  //   // var customerId = prompt("Add Customer ID");
  //   // document.cookie = `CUSTOMER_ID = ${customerId}`;
  //   // sessionStorage.setItem("CUSTOMER_ID", customerId);

  //   $.ajax({
  //     url: baseURL + "api/customer/Search/SearchOptions",
  //     type: "GET",
  //     success: (result) => {
  //       console.log(result);
  //       $(".recentSearchesDesktop").empty();
  //       $(".recentSearchesMobile").empty();

  //       result.forEach((element) => {
  //         var item = ` <a class="dropdown-item" href="#">${element.Search_Name}</a>`;
  //         $(".recentSearchesDesktop").append(item);
  //         $(".recentSearchesMobile").append(item);
  //       });
  //     },
  //     error: (result) => {
  //       console.log(result);
  //     },
  //   });
  // };

  //for autocomplete search bar

  // setSessionValues = () => {
  //   var customerId = prompt("Add Customer ID");
  //   if (customerId === "") {
  //     // user pressed OK, but the input field was empty
  //     customerId = "null";
  //   }
  //   document.cookie = `CUSTOMER_ID = ${customerId}`;
  //   sessionStorage.setItem("CUSTOMER_ID", customerId);

  //   $.ajax({
  //     url: baseURL + "api/customer/Orders/setSessionValues",
  //     type: "POST",
  //     data: {
  //       Customer_ID: customerId,
  //     },
  //     success: function (result) {
  //       console.log(result);
  //     },
  //     error: function (result) {
  //       console.log(result);
  //     },
  //   });
  // };

  //edit address
  //validations for editing address
  editAdressValidationCheckTop = (e) => {
    e.preventDefault();
    console.log("Adress Validation");

    let isAddressNameValidated = this.editAddressName_validate();
    if (isAddressNameValidated) {
      this.inputAddressNameErrorEdit.html("");
    }

    let isAddressValidated = this.editAddress_validate();
    if (isAddressValidated) {
      this.inputAddressErrorEdit.html("");
    }

    let isAddressshortValidated = this.editAddressshort_validate();
    if (isAddressshortValidated) {
      this.inputAddressshortErrorEdit.html("");
    }

    // let isLandmarkValidated = this.Landmark_validate();
    // if (isLandmarkValidated) {
    //   console.log("Landmark VALID");
    //   this.inputLandmarkError.html("");
    // }

    let isCityValidated = this.editCity_validate();
    if (isCityValidated) {
      this.inputCityErrorEdit.html("");
    }

    let isStateValidated = this.editState_validate();
    if (isStateValidated) {
      this.inputStateErrorEdit.html("");
    }

    let isPincodeValidated = this.editPincode_validate();
    if (isPincodeValidated) {
      this.inputpincodeErrorEdit.html("");
    }

    if (
      isAddressNameValidated &&
      isAddressValidated &&
      isAddressshortValidated &&
      isCityValidated &&
      isStateValidated &&
      isPincodeValidated
    ) {
      this.editAddressDetailsAccountTop();
      console.log("ALL FIELDS VALID...VALIDATION OVER");
    }
  };

  editAddressName_validate = () => {
    console.log("in address validate");
    let flag = true;

    if (this.inputAddressNameEdit.val().length < 4) {
      this.inputAddressNameErrorEdit.html(
        "Address Name should be minimum 4 characters long!"
      );

      flag = false;
    }

    return flag;
  };

  editAddress_validate = () => {
    console.log("in address validate");
    let flag = true;

    if (this.inputAddressEdit.val().length < 5) {
      this.inputAddressErrorEdit.html(
        "Address should be minimum 5 characters long!"
      );

      flag = false;
    }

    return flag;
  };

  editAddressshort_validate = () => {
    let flag = true;
    if (this.inputAddressshortEdit.val().length < 5) {
      this.inputAddressshortErrorEdit.html(
        "Landmark should be minimum 5 characters long!"
      );

      flag = false;
    }

    return flag;
  };

  editCity_validate = () => {
    let flag = true;

    var cityExample = /^[A-Za-z0-9 ]+$/;

    if (
      !this.inputCityEdit.val().match(cityExample) ||
      this.inputCityEdit.val().length < 3
    ) {
      this.inputCityErrorEdit.html(
        "City should be minimum 3 characters long and should not contain special characters!"
      );

      flag = false;
    }

    return flag;
  };

  editState_validate = () => {
    let flag = true;

    var stateExample = /^[A-Za-z0-9 ]+$/;

    if (
      !this.inputStateEdit.val().match(stateExample) ||
      this.inputStateEdit.val().length < 3
    ) {
      this.inputStateErrorEdit.html(
        "State should be minimum 3 characters long and should not contain special characters!"
      );

      flag = false;
    }

    return flag;
  };

  editPincode_validate = () => {
    let flag = true;

    var pincodeExample = /^[0-9]*$/;

    if (
      !this.inputPincodeEdit.val().match(pincodeExample) ||
      this.inputPincodeEdit.val().length < 6
    ) {
      this.inputpincodeErrorEdit.html(
        "Pincode should be minimum 6 characters long and should contain only numbers!"
      );

      flag = false;
    }

    return flag;
  };

  //confirmation before editing customer address
  editAddressDetailsAccount = async (event) => {
    console.log("edit account");
    console.log(event);
    customerAddressIdTop = $(event.currentTarget)
      .find("input[name='customerAddressIdTop']")
      .val();

    $.ajax({
      url: baseURL + "api/customer/Account/getAddressViaCustomerAddressId",
      type: "POST",
      async: false,
      data: {
        Customer_Address_ID: customerAddressIdTop
      },
      headers: { Authorization: X_API_KEY },
      success: (result) => {
        console.log(result);
        $(".inputAddressNameChangeAccountEditTop").val(result.Address_Name);
        $(".inputAddressChangeAccountEditTop").val(result.Address_Line_1);
        $(".inputAddressshortChangeAccountEditTop").val(result.Landmark);
        $(".inputCityChangeAccountEditTop").val(result.City);
        $(".inputStateChangeAccountEditTop").val(result.State);
        $(".inputPincodeChangeAccountEditTop").val(result.Pincode);
        oldLat = result.lat;
        oldLong = result.lon;
      },
      error: (result) => {
        console.log(result);
      }
    });
    console.log(oldLat);

    //googles maps
    var classList = event.target.className.split(" ");
    var idOfGoogleMapsDiv = classList[classList.length - 1];

    console.log(idOfGoogleMapsDiv);

    addressDetails = await GoogleMapsApiObject.editAddress(
      idOfGoogleMapsDiv,
      oldLat,
      oldLong
    );
    //google maps
  };

  //editing customer address
  editAddressDetailsAccountTop = () => {
    console.log("edit account");
    var allCookies = UtilObject.getAllCookies();
    var customerID = allCookies.CUSTOMER_ID;
    $.ajax({
      url: baseURL + "api/customer/Account/AddUpdateAddress",
      type: "POST",
      async: false,
      data: {
        // Customer_ID: 'b27e453c-c54d-404f-b377-d3e67c90fca0',
        Customer_ID: customerID,
        Customer_Address_ID: customerAddressIdTop,
        Address_Line_1: this.inputAddressEdit.val(),
        Landmark: this.inputAddressshortEdit.val(),
        City: this.inputCityEdit.val(),
        State: this.inputStateEdit.val(),
        Pincode: this.inputPincodeEdit.val(),
        Address_Name: this.inputAddressNameEdit.val(),
        lat: $(".inputLat").val(),
        lon: $(".inputLon").val()
      },
      headers: { Authorization: X_API_KEY },
      success: (result) => {
        console.log(result);
        window.$("#locationModalAccountCallEditTop").modal("hide");
      },
      error: (result) => {
        console.log(result);
      }
    });
  };
}
